import { Pagination } from 'antd';
import { get } from 'jquery';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import User from '../api/User';
import { SuccessMessage, ErrorMessage } from '../core/Message';
import { useTranslate } from '../core/translate';
import useLockedBody from '../core/useLockedBody';
import TimerTrades from '../page/TimerTrades';
import { port } from '../port';

import { toggleShadow } from '../redux/action/toggleShadowActive';
import { createBuyadlistuser, createBuyadlistuserpendding, createHistorySwap, createSelladlistuser, createSelladlistuserpendding } from '../redux/action/userAction';

import ShadowLayer from './ShadowLayer';



function Trades() {
    let { t } = useTranslate()
    useEffect(() => {
        let tab = t("History p2p")
        document.title = tab
        return () => {
            document.title = "Swap tobe"
        }
    }, [t]);
    const [menu, setMenu] = useState("wait")
    let token = JSON.parse(localStorage.getItem('user'))
    let { selladlistuser, buyadlistuser, buyadlistuserpendding, selladlistuserpendding, historyswap } = useSelector(state => state.user)

    const dispatch = useDispatch()
    const shadowLayer = useSelector(state => state.shadowLayer)

    const { flag } = shadowLayer

    useLockedBody(flag)
    useEffect(() => {
        window.scrollTo(0, 0)
        if (token) {
            dispatch(createSelladlistuser(token?.axiologin?.token, 1))
            dispatch(createBuyadlistuser(token?.axiologin?.token, 1))
            dispatch(createSelladlistuserpendding(token?.axiologin?.token, 1))
            dispatch(createBuyadlistuserpendding(token?.axiologin?.token, 1))
            dispatch(createHistorySwap(token?.axiologin?.token, 1))

        }

    }, [])
















    function truncate(value, precision) {
        var step = Math.pow(10, precision || 0);
        var temp = Math.trunc(step * value);

        var a = temp / step;
        return a.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    async function DisableSell(idP2P) {

        try {
            let res = await User.createApiToken(`${port}api/p2p/disableSellUser`, token?.axiologin?.token, { idP2P })

            SuccessMessage(res?.data?.message, t)
            dispatch(createSelladlistuser(token?.axiologin?.token, 1))


        } catch (error) {

            ErrorMessage(error?.response?.data?.message, t)

        }
    }
    async function ActiveSell(idP2P) {

        try {
            let res = await User.createApiToken(`${port}api/p2p/activeSellUser`, token?.axiologin?.token, { idP2P })
            SuccessMessage(res?.data?.message, t)
            dispatch(createSelladlistuser(token?.axiologin?.token, 1))

        } catch (error) {
            ErrorMessage(error?.response?.data?.message, t)


        }
    }
    async function DeleteSell(idP2P) {
        try {

            let res = await User.createApiToken(`${port}api/p2p/deleteSellUser`, token?.axiologin?.token, { idP2P })
            SuccessMessage(res.data.message, t)

            dispatch(createSelladlistuser(token?.axiologin?.token, 1))
        } catch (error) {
            ErrorMessage(error?.response?.data?.message, t)


        }
    }
    //lịch sử buy
    async function DisableBuy(idP2P) {

        try {
            let res = await User.createApiToken(`${port}api/p2p/disableBuyUser`, token?.axiologin?.token, { idP2P })

            SuccessMessage(res?.data?.message, t)
            dispatch(createBuyadlistuser(token?.axiologin?.token, 1))


        } catch (error) {

            ErrorMessage(error?.response?.data?.message, t)

        }
    }
    async function ActiveBuy(idP2P) {

        try {
            let res = await User.createApiToken(`${port}api/p2p/activeBuyUser`, token?.axiologin?.token, { idP2P })
            SuccessMessage(res?.data?.message, t)
            dispatch(createBuyadlistuser(token?.axiologin?.token, 1))

        } catch (error) {
            ErrorMessage(error?.response?.data?.message, t)


        }
    }
    async function DeleteBuy(idP2P) {
        try {

            let res = await User.createApiToken(`${port}api/p2p/deleteBuyUser`, token?.axiologin?.token, { idP2P })
            SuccessMessage(res.data.message, t)

            dispatch(createBuyadlistuser(token?.axiologin?.token, 1))
        } catch (error) {
            ErrorMessage(error?.response?.data?.message, t)


        }
    }
    function handlePageSell(page) {

        dispatch(createSelladlistuser(token?.axiologin?.token, page))
    }
    function handlePageBuy(page) {

        dispatch(createBuyadlistuser(token?.axiologin?.token, page))
    }
    function handlePageSwap(page) {

        dispatch(createHistorySwap(token?.axiologin?.token, page))
    }

    return (
        <>
            {flag && <>
                <ShadowLayer />
                <TimerTrades />
            </>}


            <div className="trades" style={{ color: "#000" }}>
                <div className="trades-container">
                    <div className="trades-container__menu">
                        <div className={`${menu === "wait" && "active"}`} onClick={() => setMenu("wait")}>
                            <i className="far fa-clock"></i>
                            <span>{t("Pending Trades")}</span>
                        </div>
                        <div className={`${menu === "closed" && "active"}`} onClick={() => setMenu("closed")}>
                            <i className="fas fa-times"></i>
                            <span>{t("Cancelled Trades")}</span>
                        </div>
                        <div className={`${menu === "completed" && "active"}`} onClick={() => setMenu("completed")}>
                            <i className="far fa-check-square"></i>
                            <span>{t("Completed Trades")}</span>
                        </div>
                        <div className={`${menu === "offers" && "active"}`} onClick={() => setMenu("offers")}>
                            <i className="fas fa-bullhorn"></i>
                            <span>{t("Your Advertisements")}</span>
                        </div>
                        {/* <div className={`${menu === "following" && "active"}`} onClick={() => setMenu("following")}>
                            <i className="far fa-bookmark"></i>
                            <span>{t("Swap")}</span>
                        </div> */}
                    </div>
                    <div className="trades-container__content">
                        {menu === "wait" &&
                            <div>
                                {/* <div className="title">Giao dịch đang chờ xử lý</div> */}


                                <div>
                                    <div className="title">{t("Your buying ads")}</div>

                                    {
                                        selladlistuserpendding?.array?.length == 0 && <div className="none">{t("No advertisements found")}</div>
                                    }
                                    {

                                        selladlistuserpendding?.array?.map((e, i) => {
                                            return (
                                                <div className="sell-offers-item">
                                                    <div className="sell-offers-item__left">
                                                        <div className="amount-offers">
                                                            <p><span>{truncate(e.amount_exchange_usd * e.amount_exchange_vnd + (e.amount_exchange_usd * e.amount_exchange_vnd) / 100 * e.percent)}</span> VND/{e.type}</p>
                                                        </div>
                                                        <div className="value-offers">
                                                            <p>Tối đã: {e.amount_maximum} {e.type}</p>
                                                        </div>
                                                        <div className="receive-offers">
                                                            <p>Giá bán thực nhận: <span>{truncate(e.amount_exchange_usd * e.amount_exchange_vnd)} VND/<span>{e.type}</span></span></p>
                                                        </div>
                                                        <div className="status-offers">
                                                            <p>Trạng thái: <span>{e.status}</span></p>
                                                        </div>
                                                    </div>

                                                    <div className="sell-offers-item__right">
                                                        <div className="info-offers">
                                                            <div>
                                                                <div className="name-offers">Ví VND SwapToBe <i className="fas fa-info-circle"></i></div>
                                                                {/* <div className="time-offers">Thời gian hoàn thành: ~1m 38s</div> */}
                                                            </div>
                                                            <div className="user-offers"><i className="fas fa-circle"></i> {e.username}</div>
                                                        </div>


                                                    </div>
                                                </div>

                                            )
                                        })}
                                    <Pagination
                                        defaultCurrent={1}
                                        defaultPageSize={5} //default size of page
                                        onChange={handlePageSell}
                                        total={selladlistuser?.total} //total number of card data available
                                    />


                                </div>
                                <div>
                                    <div className="title">{t("Your selling ads")}</div>
                                    {
                                        buyadlistuserpendding?.array?.length == 0 && <div className="none">{t("No advertisements found")}</div>
                                    }

                                    <div className="list-sell-offers">
                                        {buyadlistuserpendding?.array?.map((e) => {
                                            return (
                                                <div className="sell-offers-item">
                                                    <div className="sell-offers-item__left">
                                                        <div className="amount-offers">
                                                            <p><span>{truncate(e.amount_exchange_usd * e.amount_exchange_vnd - (e.amount_exchange_usd * e.amount_exchange_vnd) / 100 * e.percent)}</span> VND/{e.type}</p>
                                                        </div>
                                                        <div className="value-offers">
                                                            <p>Tối đã: {e.amount_maximum} {e.type}</p>
                                                        </div>
                                                        <div className="receive-offers">
                                                            <p>Giá bán thực nhận: <span>{truncate(e.amount_exchange_usd * e.amount_exchange_vnd)} VND/<span>{e.type}</span></span></p>
                                                        </div>
                                                        <div className="status-offers">
                                                            <p>Trạng thái: <span>Tạm dừng</span></p>
                                                        </div>
                                                    </div>

                                                    <div className="sell-offers-item__right">
                                                        <div className="info-offers">
                                                            <div>
                                                                <div className="name-offers">Ví VND SwapToBe <i className="fas fa-info-circle"></i></div>

                                                            </div>
                                                            <div className="user-offers"><i className="fas fa-circle"></i> {e.username}</div>
                                                        </div>

                                                        <div className="control-offers">
                                                            {
                                                                e?.active == 1 ? <div onClick={() => DisableBuy(e.id)} className="info" style={{ backgroundColor: "#fff", color: "#000", borderColor: '#000' }}><i className="fas fa-pause"></i>Vô hiệu hoá</div> : <div onClick={() => ActiveBuy(e.id)} className="info" ><i className="fas fa-pause"></i>Kích hoạt</div>

                                                            }
                                                            <div><i className="fas fa-pencil-alt"></i>Chỉnh sửa</div>
                                                            <div><i className="bigger fas fa-stopwatch"></i>Hẹn giờ: TẮT</div>
                                                            <div onClick={() => DeleteBuy(e.id)} className="purple"><i className="bigger fas fa-times"></i>Xóa</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}

                                        <Pagination
                                            defaultCurrent={1}
                                            defaultPageSize={5} //default size of page
                                            onChange={handlePageBuy}
                                            total={buyadlistuser?.total} //total number of card data available
                                        />


                                    </div>
                                </div>
                            </div>
                        }

                        {menu === "closed" &&
                            <div>
                                <div className="title">{t("Cancelled Trades")}</div>
                                <div className="none">{t("No trade found")}</div>
                                {/* <div className="list-closed-item">
                                <div className="closed-item">
                                    <div>
                                        <div className="item-block amount-btc">
                                            <p>Giá trị: <span>0.00009253</span> BTC</p>
                                        </div>
                                        <div className="item-block value-trade">
                                            <p>Giá trị giao dịch: <span className="info">100,000</span><span>VND [MUA]</span></p>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="item-block value-btc">
                                            <p>Giá: <span> 1,080,730,573 VND/BTC</span></p>
                                        </div>
                                        <div className="item-block code-trade">
                                            <p>Mã giao dịch: <span>1481664T92452742</span></p>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="item-block status">
                                            <p>Trạng thái: <span>Đã bị hủy</span></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="closed-item">
                                    <div>
                                        <div className="item-block amount-btc">
                                            <p>Giá trị: <span>0.00009253</span> BTC</p>
                                        </div>
                                        <div className="item-block value-trade">
                                            <p>Giá trị giao dịch: <span className="info">100,000</span><span>VND [MUA]</span></p>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="item-block value-btc">
                                            <p>Giá: <span> 1,080,730,573 VND/BTC</span></p>
                                        </div>
                                        <div className="item-block code-trade">
                                            <p>Mã giao dịch: <span>1481664T92452742</span></p>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="item-block status">
                                            <p>Trạng thái: <span className="highlight">Đã bị hủy tự động</span></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="closed-item alert">
                                    <div>
                                        <div className="item-block amount-btc">
                                            <p>Giá trị: <span>0.00009253</span> BTC</p>
                                        </div>
                                        <div className="item-block value-trade">
                                            <p>Giá trị giao dịch: <span className="alert">100,000</span><span>VND [MUA]</span></p>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="item-block value-btc">
                                            <p>Giá: <span> 1,080,730,573 VND/BTC</span></p>
                                        </div>
                                        <div className="item-block code-trade">
                                            <p>Mã giao dịch: <span>1481664T92452742</span></p>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="item-block status">
                                            <p>Trạng thái: <span className="highlight">Đã bị hủy tự động</span></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="closed-item alert">
                                    <div>
                                        <div className="item-block amount-btc">
                                            <p>Giá trị: <span>0.00009253</span> BTC</p>
                                        </div>
                                        <div className="item-block value-trade">
                                            <p>Giá trị giao dịch: <span className="alert">100,000</span><span>VND [MUA]</span></p>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="item-block value-btc">
                                            <p>Giá: <span> 1,080,730,573 VND/BTC</span></p>
                                        </div>
                                        <div className="item-block code-trade">
                                            <p>Mã giao dịch: <span>1481664T92452742</span></p>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="item-block status">
                                            <p>Trạng thái: <span className="highlight">Đã bị hủy</span></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="closed-item alert">
                                    <div>
                                        <div className="item-block amount-btc">
                                            <p>Giá trị: <span>0.00009253</span> BTC</p>
                                        </div>
                                        <div className="item-block value-trade">
                                            <p>Giá trị giao dịch: <span className="alert">100,000</span><span>VND [MUA]</span></p>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="item-block value-btc">
                                            <p>Giá: <span> 1,080,730,573 VND/BTC</span></p>
                                        </div>
                                        <div className="item-block code-trade">
                                            <p>Mã giao dịch: <span>1481664T92452742</span></p>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="item-block status">
                                            <p>Trạng thái: <span className="highlight">Đã bị hủy tự động</span></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="closed-item alert">
                                    <div>
                                        <div className="item-block amount-btc">
                                            <p>Giá trị: <span>0.00009253</span> BTC</p>
                                        </div>
                                        <div className="item-block value-trade">
                                            <p>Giá trị giao dịch: <span className="alert">100,000</span><span>VND [MUA]</span></p>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="item-block value-btc">
                                            <p>Giá: <span> 1,080,730,573 VND/BTC</span></p>
                                        </div>
                                        <div className="item-block code-trade">
                                            <p>Mã giao dịch: <span>1481664T92452742</span></p>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="item-block status">
                                            <p>Trạng thái: <span className="highlight">Đã bị hủy tự động</span></p>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            </div>
                        }
                        {menu === "completed" &&
                            <div>
                                <div className="title">{t("Completed Trades")}</div>
                                <div className="none">{t("No trade found")}</div>
                            </div>
                        }
                        {menu === "offers" &&
                            <>
                                <div>
                                    <div className="title">{t("Your buying ads")}</div>
                                    {
                                        selladlistuser?.array?.length == 0 && <div className="none">{t("No advertisements found")}</div>
                                    }
                                    {selladlistuser?.array?.map((e, i) => {
                                        return (
                                            <div className="sell-offers-item">
                                                <div className="sell-offers-item__left">
                                                    <div className="amount-offers">
                                                        <p><span>{truncate(e.amount_exchange_usd * e.amount_exchange_vnd + (e.amount_exchange_usd * e.amount_exchange_vnd) / 100 * e.percent)}</span> VND/{e.type}</p>
                                                    </div>
                                                    <div className="value-offers">
                                                        <p>{t("Max:")} {e.amount_maximum} {e.type}</p>
                                                    </div>
                                                    <div className="receive-offers">
                                                        <p>{t("Price that you receive: ")}<span>{truncate(e.amount_exchange_usd * e.amount_exchange_vnd)} VND/<span>{e.type}</span></span></p>
                                                    </div>
                                                    <div className="status-offers">
                                                        <p>{t("Status: ")} <span>{t(`${e.status}`)}</span></p>
                                                    </div>
                                                </div>

                                                <div className="sell-offers-item__right">
                                                    <div className="info-offers">
                                                        <div>
                                                            <div className="name-offers">Ví VND SwapToBe <i className="fas fa-info-circle"></i></div>
                                                            {/* <div className="time-offers">Thời gian hoàn thành: ~1m 38s</div> */}
                                                        </div>
                                                        <div className="user-offers"><i className="fas fa-circle"></i> {e.username}</div>
                                                    </div>

                                                    <div className="control-offers">
                                                        {
                                                            e?.active == 1 ? <div onClick={() => DisableSell(e.id)} className="info" style={{ backgroundColor: "#fff", color: "#000", borderColor: '#000' }}><i className="fas fa-pause"></i>{t("Disable")}</div> : <div onClick={() => ActiveSell(e.id)} className="info" ><i className="fas fa-pause"></i>{t("Activated")}</div>

                                                        }

                                                        <div><i className="fas fa-pencil-alt"></i>{t("Edit")}</div>
                                                        <div onClick={() => dispatch(toggleShadow(!flag))}><i className="bigger fas fa-stopwatch" ></i>{t("Timer Turn: Off")}</div>
                                                        <div onClick={() => DeleteSell(e.id)} className="purple"><i className="bigger fas fa-times"></i>{t("Delete")}</div>
                                                    </div>
                                                </div>
                                            </div>

                                        )
                                    })}
                                    <Pagination
                                        defaultCurrent={1}
                                        defaultPageSize={5} //default size of page
                                        onChange={handlePageSell}
                                        total={selladlistuser?.total} //total number of card data available
                                    />


                                </div>
                                <div>
                                    <div className="title">{t("Your selling ads")}</div>
                                    {buyadlistuser?.array?.length == 0 && <div className="none">{t("No advertisements found")}</div>}
                                    {/* <div className="none">Không tìm thấy quảng cáo nào</div> */}
                                    <div className="list-sell-offers">
                                        {buyadlistuser?.array?.map((e) => {
                                            return (
                                                <div className="sell-offers-item">
                                                    <div className="sell-offers-item__left">
                                                        <div className="amount-offers">
                                                            <p><span>{truncate(e.amount_exchange_usd * e.amount_exchange_vnd - (e.amount_exchange_usd * e.amount_exchange_vnd) / 100 * e.percent)}</span> VND/{e.type}</p>
                                                        </div>
                                                        <div className="value-offers">
                                                            <p>{t("Max:")} {e.amount_maximum} {e.type}</p>
                                                        </div>
                                                        <div className="receive-offers">
                                                            <p>{t("Price that you pay: ")} <span>{truncate(e.amount_exchange_usd * e.amount_exchange_vnd)} VND/<span>{e.type}</span></span></p>
                                                        </div>
                                                        <div className="status-offers">
                                                            <p>{t("Status: ")} <span>{t(`${e.status}`)}</span></p>
                                                        </div>
                                                    </div>

                                                    <div className="sell-offers-item__right">
                                                        <div className="info-offers">
                                                            <div>
                                                                <div className="name-offers">Ví VND SwapToBe <i className="fas fa-info-circle"></i></div>

                                                            </div>
                                                            <div className="user-offers"><i className="fas fa-circle"></i> {e.username}</div>
                                                        </div>

                                                        <div className="control-offers">
                                                            {
                                                                e?.active == 1 ? <div onClick={() => DisableBuy(e.id)} className="info" style={{ backgroundColor: "#fff", color: "#000", borderColor: '#000' }}><i className="fas fa-pause"></i>{t("Disable")}</div> : <div onClick={() => ActiveBuy(e.id)} className="info" ><i className="fas fa-pause"></i>{t("Activated")}</div>

                                                            }
                                                            <div><i className="fas fa-pencil-alt"></i>{t("Edit")}</div>
                                                            <div onClick={() => dispatch(toggleShadow(!flag))}><i className="bigger fas fa-stopwatch" ></i>{t("Timer Turn: Off")}</div>
                                                            <div onClick={() => DeleteBuy(e.id)} className="purple"><i className="bigger fas fa-times"></i>{t("Delete")}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}

                                        <Pagination
                                            defaultCurrent={1}
                                            defaultPageSize={5} //default size of page
                                            onChange={handlePageBuy}
                                            total={buyadlistuser?.total} //total number of card data available
                                        />


                                    </div>
                                </div>
                                <div className="offers-btn">
                                    <div><button>{t("Create Ad")}</button></div>
                                </div>
                            </>
                        }

                    </div>
                </div>
            </div>

        </>
    );
}

export default Trades;