export const OnlyNumber = (value) => {
    let ValuePriceyouset = ""
    // let dotIndex = value.indexOf(".")

    for (let index = 0; index < value.length; index++) {
        if (value[index].match(/[0-9]/))
            ValuePriceyouset += value[index]
        // if (index === dotIndex)
        //     ValuePriceyouset += "."
    }
    return ValuePriceyouset
}