import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useLockedBody from '../../core/useLockedBody';
import { toggleShadow } from '../../redux/action/toggleShadowActive';



function TimerTrades(props) {
    const dispatch = useDispatch()
    const shadowLayer = useSelector(state => state.shadowLayer)
    const { flag } = shadowLayer


    const hours = ["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11"]
    const minutes = ["00", "05", "10", "15", "20", "25", "30", "35", "40", "45", "50", "55"]
    const times = ["am", "pm"]

    const [onTimer1, setOnTimer1] = useState({ hour: "00", minute: "00", time: "am" })
    const [onTimer2, setOnTimer2] = useState({ hour: "00", minute: "00", time: "am" })
    const [offTimer1, setOffTimer1] = useState({ hour: "00", minute: "00", time: "am" })
    const [offTimer2, setOffTimer2] = useState({ hour: "00", minute: "00", time: "am" })

    useEffect(() => {
        const timerBlock = document.querySelectorAll(".timer-block")
        const timerBox = document.querySelectorAll(".timer-box")

        for (let index = 0; index < timerBlock.length; index++) {
            timerBlock[index].addEventListener("click", () => {
                for (let idx = 0; idx < timerBlock.length; idx++)
                    if (idx !== index) timerBox[idx].classList.remove("active")
                timerBox[index].classList.toggle("active")
            })
        }
    }, [])

    return (

        <div className="timer" >

            <div className="timer-container" style={{ color: '#000' }}>
                <div className="timer-container__title">
                    <span>Hẹn giờ quảng cáo</span>
                    <i className="fas fa-times" onClick={() => dispatch(toggleShadow(!flag))}></i>
                </div>
                <div className="timer-container__content">
                    <div className="time-zone">Múi giờ: +07:00</div>

                    <div className="timer-item">
                        <div className="label">Hẹn giờ</div>
                        <div className="content">
                            <label className="switch">
                                <input type="checkbox" />
                                <span className="slider"></span>
                            </label>
                        </div>
                    </div>
                    <div className="timer-item">
                        <div className="label">Bật lần 1</div>
                        <div className="content">
                            <div className="timer-block">
                                <span>{`${onTimer1.hour}:${onTimer1.minute} ${onTimer1.time}`}</span>
                            </div>
                            <div className="timer-box">
                                <div>
                                    {hours.map((hour, idx) => (
                                        <span
                                            key={idx}
                                            onClick={() => setOnTimer1({ ...onTimer1, hour: hour })}
                                            className={`${onTimer1.hour === hour && "active"}`}
                                        >{hour}</span>
                                    ))}
                                </div>
                                <div>
                                    {minutes.map((minute, idx) => (
                                        <span
                                            key={idx}
                                            onClick={() => setOnTimer1({ ...onTimer1, minute: minute })}
                                            className={`${onTimer1.minute === minute && "active"}`}
                                        >{minute}</span>
                                    ))}
                                </div>
                                <div>
                                    {times.map((time, idx) => (
                                        <span
                                            key={idx}
                                            onClick={() => setOnTimer1({ ...onTimer1, time: time })}
                                            className={`${onTimer1.time === time && "active"}`}
                                        >{time}</span>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="timer-item">
                        <div className="label">Tắt lần 1</div>
                        <div className="content">
                            <div className="timer-block">
                                <span>{`${offTimer1.hour}:${offTimer1.minute} ${offTimer1.time}`}</span>
                            </div>
                            <div className="timer-box">
                                <div>
                                    {hours.map((hour, idx) => (
                                        <span
                                            key={idx}
                                            onClick={() => setOffTimer1({ ...offTimer1, hour: hour })}
                                            className={`${offTimer1.hour === hour && "active"}`}
                                        >{hour}</span>
                                    ))}
                                </div>
                                <div>
                                    {minutes.map((minute, idx) => (
                                        <span
                                            key={idx}
                                            onClick={() => setOffTimer1({ ...offTimer1, minute: minute })}
                                            className={`${offTimer1.minute === minute && "active"}`}
                                        >{minute}</span>
                                    ))}
                                </div>
                                <div>
                                    {times.map((time, idx) => (
                                        <span
                                            key={idx}
                                            onClick={() => setOffTimer1({ ...offTimer1, time: time })}
                                            className={`${offTimer1.time === time && "active"}`}
                                        >{time}</span>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="timer-item">
                        <div className="label">Bật lần 2</div>
                        <div className="content">
                            <div className="timer-block">
                                <span>{`${onTimer2.hour}:${onTimer2.minute} ${onTimer2.time}`}</span>
                            </div>
                            <div className="timer-box">
                                <div>
                                    {hours.map((hour, idx) => (
                                        <span
                                            key={idx}
                                            onClick={() => setOnTimer2({ ...onTimer2, hour: hour })}
                                            className={`${onTimer2.hour === hour && "active"}`}
                                        >{hour}</span>
                                    ))}
                                </div>
                                <div>
                                    {minutes.map((minute, idx) => (
                                        <span
                                            key={idx}
                                            onClick={() => setOnTimer2({ ...onTimer2, minute: minute })}
                                            className={`${onTimer2.minute === minute && "active"}`}
                                        >{minute}</span>
                                    ))}
                                </div>
                                <div>
                                    {times.map((time, idx) => (
                                        <span
                                            key={idx}
                                            onClick={() => setOnTimer2({ ...onTimer2, time: time })}
                                            className={`${onTimer2.time === time && "active"}`}
                                        >{time}</span>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="timer-item">
                        <div className="label">Tắt lần 2</div>
                        <div className="content">
                            <div className="timer-block">
                                <span>{`${offTimer2.hour}:${offTimer2.minute} ${offTimer2.time}`}</span>
                            </div>
                            <div className="timer-box">
                                <div>
                                    {hours.map((hour, idx) => (
                                        <span
                                            key={idx}
                                            onClick={() => setOffTimer2({ ...offTimer2, hour: hour })}
                                            className={`${offTimer2.hour === hour && "active"}`}
                                        >{hour}</span>
                                    ))}
                                </div>
                                <div>
                                    {minutes.map((minute, idx) => (
                                        <span
                                            key={idx}
                                            onClick={() => setOffTimer2({ ...offTimer2, minute: minute })}
                                            className={`${offTimer2.minute === minute && "active"}`}
                                        >{minute}</span>
                                    ))}
                                </div>
                                <div>
                                    {times.map((time, idx) => (
                                        <span
                                            key={idx}
                                            onClick={() => setOffTimer2({ ...offTimer2, time: time })}
                                            className={`${offTimer2.time === time && "active"}`}
                                        >{time}</span>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="timer-btn">
                        <div onClick={() => dispatch(toggleShadow(!flag))}>Đóng</div>
                        <div className="purple">Cập nhật</div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TimerTrades;