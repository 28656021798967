import CreateApi from "api/CreateApi"
import reduxToolkit from "../../core/reduxToolkit"

const initialState = {
    getFeeWallet: '',

}



export const HandleGetFeeWallet = (name, data) => {
    return async (dispatch) => {
        if (!data) {

            try {
                let res = await CreateApi.CreateApiSS('api/admin/getfee', { name })
                dispatch(GetFeeWallet(res?.data?.data))
            } catch (error) {
            }
        }

    }
}




let { reducer, action, TYPE } = reduxToolkit({
    name: 'wallet',
    initialState,
    reducers: {

        GetFeeWallet: function (state, action) {

            return {
                ...state,
                getFeeWallet: action.payload
            }
        },

    }
})

export default reducer
export const WITHDRAW = TYPE
export const { GetFeeWallet } = action