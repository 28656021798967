import React, { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createGethistorywidthdraw, createGetlistbankinguser, createGetBaking, GetFee } from '../../../redux/action/userAction'
import Withdraw from './Withdraw';
import HistoryWithdrawVnd from './HistoryWithdrawVnd';

function WithdrawVND() {
    const token = JSON.parse(localStorage.getItem('user'))
    const dispatch = useDispatch()
    let [loadding, setLoadding] = useState(false)

    useEffect(() => {
        window.scrollTo(0, 0)
        dispatch(createGetlistbankinguser(token?.axiologin?.token, 1))
        dispatch(createGethistorywidthdraw(token?.axiologin?.token, 1, "VND", setLoadding))
        dispatch(createGetBaking())
        dispatch(GetFee('FEEVND'))

    }, [])
    return (
        <>
            <div className='depositvnd'>
                <div className="container">
                    <div className="row" style={{justifyContent:'center', gap:"50px"}}>
                        <div className="col-md-6 depositvnd-main">
                            <Withdraw />
                        </div>
                        <div className="col-md-6 dvndhistory">
                            <HistoryWithdrawVnd />
                        </div>

                    </div>
                </div>
            </div>
        </>

    );
}

export default WithdrawVND;