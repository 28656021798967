import User from 'api/User';
import { ErrorMessage } from 'core/Message';
import { useTranslate } from 'core/translate';
import { port } from 'port';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { createOther } from 'redux/action/orderAction';
import { createCheckTransactionDepositVnd } from 'redux/action/userAction';
import { TOKENRE } from 'redux/type';
import { VALUE2FA } from 'redux/type';
import { Spin } from 'antd';

function Submit({ amount, idBanking }) {
    const dispatch = useDispatch()
    const { profile } = useSelector(state => state.user)
    const twofa = profile?.data?.data?.enabled_twofa
    const token = JSON.parse(localStorage.getItem('user'))
    let [loadding, setLoadding] = useState(false)
    let { tokenRe, value2fa } = useSelector(state => state.other)
    let tokenRecaptcha = tokenRe
    let otp = value2fa

    let { t } = useTranslate()

    const submitDeposit = async () => {
        if (!tokenRe.length > 0 || !value2fa) {
            setLoadding(true)
            if (amount > 100000) {
                try {
                    let res = await User.createApiToken(`${port}api/user/createDepositVND`, token?.axiologin?.token, { idBanking, amount, tokenRecaptcha, otp })
                    dispatch(createCheckTransactionDepositVnd(token?.axiologin?.token, setLoadding))

                    if (tokenRe.length > 0) {
                        dispatch(createOther(TOKENRE, ''))
                        window.grecaptcha.reset();
                    } else {
                        dispatch(createOther(VALUE2FA, ''))
                    }
                } catch (error) {
                    ErrorMessage('Incorrect code! ', t)
                    if (tokenRe.length > 0) {
                        dispatch(createOther(TOKENRE, ''))
                        window.grecaptcha.reset();
                    } else {
                        dispatch(createOther(VALUE2FA, ''))
                    }
                }
            } else {
                ErrorMessage("Amount must be greater than or equal to 100,000", t)
            }

        } else {
            if (twofa == 0 && tokenRe == '') {
                ErrorMessage("Please enter ReCAPTCHA", t)

            } else {
                ErrorMessage("Please enter  2FA", t)
            }
        }
        setLoadding(false)
    }
    return (
        <>
            {loadding ? <div className="example" style={{ width: '100%' }}>
                <Spin />
            </div> :
                <button onClick={submitDeposit}>{t("Deposit")}</button>}
        </>
    );
}

export default Submit;