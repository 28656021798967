import { Spin } from 'antd';
import covertCoin from 'core/CovertCoin';
import createApiNap from 'core/createApiNap';
import { getwalletcoin } from 'core/GetWalletCoin';
import { fixedNumber } from 'core/hook/fixedNumber';
import { formatMoney } from 'core/hook/formarMoney';
import { useTranslate } from 'core/translate';
import { port } from 'port';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { createA } from 'redux/action/userAction';
import { Createwallettrc, HandleCreateApiDeposit } from 'redux/reducer/depositReducer';
import { ShowDeposit } from 'redux/reducer/depositReducer';
import { DataWithdrawCoin, TypeWithdrawCoin } from 'redux/reducer/withdrawReducer';
import { SymbolHistory } from 'redux/reducer/withdrawReducer';
import { ShowWithdraw } from 'redux/reducer/withdrawReducer';
import { SYMBOL } from 'redux/type';
import Deposit from '../Deposit';
import DepositVND from '../DepositVND';
import WithdrawCoin from '../WithdrawCoin';
import WithdrawVND from '../WithdrawVnd';

function ListCoin({ setDepositVND, depositVND, setShowWithdrawVND, showWithdrawVND }) {
    let token = JSON.parse(localStorage.getItem('user'))
    let { showDeposit } = useSelector(state => state.deposit)
    let { showWithdrawCoin } = useSelector(state => state.withdraw)
    let { pricecoin } = useSelector(state => state.coin)
    const { getwallet } = useSelector(state => state.user)
    let [loadding, setLoadding] = useState(false)
    let { t } = useTranslate()
    const dispatch = useDispatch()
    function clickDepositCoin(symbol, type, i) {
        dispatch(Createwallettrc(''))
        dispatch(ShowDeposit(true))
        setLoadding(i)
        dispatch(createA(SYMBOL, symbol))
        createApiNap(symbol, type, dispatch, token, t, setLoadding)
    }

    const clickDepositVnd = () => {
        window.scrollTo(0, 0)
        dispatch(ShowDeposit(false))
        setDepositVND(true)
    }
    const clickWithdrawVnd = () => {
        dispatch(ShowDeposit(false))
        setShowWithdrawVND(true)
    }

    const clickWithdrawCoin = (e) => {
        window.scrollTo(0, 0)
        dispatch(createA(SYMBOL, e?.name))
        dispatch(ShowDeposit(false))
        dispatch(ShowWithdraw(true))
        dispatch(DataWithdrawCoin(e))
        dispatch(TypeWithdrawCoin(e?.type))

        if (e?.name == "USDT") dispatch(SymbolHistory('USDT.TRC20'))
        else if (e?.name == "SHIB") dispatch(SymbolHistory('SHIB.BEP20'))
        else if (e?.name == "BNB") dispatch(SymbolHistory('BNB'))
        else if (e?.name == "WIN") dispatch(SymbolHistory('WIN.BEP20'))

    }
    const listItem = () => {
        const list =
            pricecoin?.map((e, i) => {
                if (e?.name != "DOT")
                    return (
                        <div className="wallet-content" key={i}>
                            <div className="wallet-content-item" >
                                <div className="wallet-coin">
                                    <div className='wallet-coin-name'>
                                        <img src={`${port}images/${e?.name}.png`} alt='' />
                                        <strong>{e?.name} </strong> <p>       {e?.token_key}</p>
                                    </div>
                                    <div className='wallet-coin-money' >
                                        <strong style={{ fontFamily: 'arial', fontWeight: '900' }}>
                                            {formatMoney(String(fixedNumber(getwalletcoin(covertCoin(e?.name, e?.type), getwallet), 6))) + " " + e?.name}
                                        </strong>
                                    </div>
                                </div>

                                <div className='wallet-input'>
                                    {e?.name != 'STF' &&
                                        <>
                                            {loadding === i ?
                                                <div div className="example" style={{ margin: '0', padding: '5px', background: '#fff', border: 'none' }}>
                                                    <Spin />
                                                </div> :
                                                <div onClick={() => clickDepositCoin(e?.name, e?.type, i)}>Deposit</div>}
                                        </>
                                    }
                                    <div onClick={() => clickWithdrawCoin(e)}>Withdraw</div>
                                    <div>Swap</div>

                                </div>
                            </div>
                        </div>
                    )
            })
        return list
    }
    return (
        <>
            {!showDeposit && !depositVND && !showWithdrawVND && !showWithdrawCoin ?
                <>
                    <div className="wallet-content">
                        <div className="wallet-content-item">
                            <div className="wallet-coin">
                                <div className='wallet-coin-name'>
                                    <img src='./img/iconvnd.png' alt='' />
                                    <strong>VND </strong> <p> Vietnamse Đồng</p>
                                </div>
                                <div className='wallet-coin-money'>
                                    <strong style={{ fontFamily: 'arial', fontWeight: '900' }}>0 VND</strong>
                                </div>
                            </div>

                            <div className='wallet-input'>
                                <div onClick={clickDepositVnd}>Deposit</div>
                                <div onClick={clickWithdrawVnd}>Withdraw</div>

                            </div>
                        </div>
                    </div>


                </> : ''}
            {showWithdrawCoin && <WithdrawCoin />}
            {showWithdrawVND && <WithdrawVND />}
            {depositVND && <DepositVND />}
            {!showDeposit && !depositVND && !showWithdrawVND && !showWithdrawCoin ? listItem() : ''}
            {showDeposit && <Deposit />}
        </>

    );
}

export default ListCoin;