


import { Route, Switch, useHistory } from "react-router"

import PrivateRouter from "./PrivateRouter";
import PrivateRouterUser from "./PrivateRouterUser";



export default function renderRouters(routers, pathParrent = '') {



    return <Switch>
        {
            routers.map((e, i) => {
                let { exact, path, component: Component, routers: childRouters, auth, admin, admin2 } = e
                if (!path) path = ''
                path = pathParrent + '/' + path
                path = path.replace(/\/+/g, '/')
                let children = null;
                if (childRouters) {
                    children = renderRouters(childRouters, path)
                }
                if (admin) {
                    return <PrivateRouter key={i} exact={exact} path={path} component={(prop) => <Component {...prop}>{children}</Component>} />
                }
                if (admin2) {
                    return <PrivateRouterUser key={i} exact={exact} path={path} component={(prop) => <Component {...prop}>{children}</Component>} />
                }
                return <Route key={i} exact={exact} path={path} component={(prop) => <Component {...prop}>{children}</Component>} />
            })
        }
    </Switch>

}