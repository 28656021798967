import Swal from "sweetalert2"

export const SuccessMessage = (title, t) => {
    Swal.fire({
        title: t(title),
        icon: 'success',
        timer: 1500
    })
}

export const SuccessMessageNoT = (title) => {
    Swal.fire({
        title: title,
        icon: 'success',
        timer: 1500
    })
}
export const ErrorMessage = (title, t) => {

    Swal.fire({
        title: t(`${title}`),
        icon: 'error',
        timer: 1500
    })
}
export const ErrorMessageNoT = (title) => {

    Swal.fire({
        title: title,
        icon: 'error',
        timer: 1500
    })
}

export const ErrorMessageSlug = (title, slug, t) => {


    Swal.fire({
        title: `${slug.toUpperCase()} ${t(title)}`,
        icon: 'error',
        timer: 1500
    })
}
export const ErrorMessageSlugAfter = (title, slug, t) => {


    Swal.fire({
        title: `${t(title)} ${slug.toUpperCase()}`,
        icon: 'error',
        timer: 1500
    })
}


// -------admin---------
export const AdminErrorMessage = (title, t) => {

    Swal.fire({
        title: t(`${title}`),
        icon: 'error',
        timer: 1500
    })
}