import React from 'react'
import { Redirect } from 'react-router-dom'
import { useTranslate } from '../../core/translate'
import Authenticator from './component/Authenticator'

export default function Setting() {
    const user = JSON.parse(localStorage.getItem('user'))
    let { t } = useTranslate()

    if (!user) return <Redirect to='/login' />
    return (
        <>
            <div className="setting" style={{ backgroundColor: '#fff' }}>
                <div className="container">
                    <Authenticator />
                </div>
            </div>

        </>
    )

}
