import React, { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createCheckTransactionDepositVnd, createHistorydepositvnd } from 'redux/action/userAction';
import { createGetBaking } from 'redux/action/userAction';
import Deposit from './Deposit';
import Check from './Check';
import { GetFee } from 'redux/action/userAction';
import CheckImg from './CheckImg';
import HistoryDepositVnd from './HistoryDepositVnd';

function DepositVND() {
    let [loadding, setLoadding] = useState()
    const { checktransactiondepositvnd } = useSelector(state => state.user)
    const token = JSON.parse(localStorage.getItem('user'))
    const [checkImg, setCheckImg] = useState(false)
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(createGetBaking())
        dispatch(createCheckTransactionDepositVnd(token?.axiologin?.token, setLoadding))
        dispatch(GetFee('DEPOSITVND'))
        dispatch(createHistorydepositvnd(token?.axiologin?.token, 1))

    }, [])
    return (
        <div className='depositvnd'>
            <div className="container">

                <div className="row" style={{justifyContent:'center', gap:"50px"}}>
                    <div className="col-md-6 depositvnd-main">
                        {!checktransactiondepositvnd && <Deposit />}
                        {checktransactiondepositvnd && <Check setCheckImg={setCheckImg} />}
                    </div>
                    <div className="col-md-6 dvndhistory">
                        {checkImg ? <CheckImg setCheckImg={setCheckImg} />
                            : <HistoryDepositVnd />}
                    </div>

                </div>
            </div>
        </div>
    );
}

export default DepositVND;