import { FormatGetwalletcoin } from 'core/GetWalletCoin';
import { formatMoney } from 'core/hook/formarMoney';
import { useTranslate } from 'core/translate';
import { RenderFeeGas } from 'page/Vi/component/MinimumWidthdraw';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import DepositCoin from './DepositCoin';

function Content({ symbol, handleChange, handleChangeCoin, handleComfirm, handleMaxCoin, valueCoin, coin }) {
    let { t } = useTranslate()
    let { pricecoin } = useSelector(state => state.coin)
    let { getwallet } = useSelector(state => state.user)




    return (
        <>
            <DepositCoin symbol={symbol} />

            <div className='depositvnd-input'>
                <p>Wallet {symbol} - <span>Note *</span></p>
                <input type="text" onChange={handleChange} />
            </div>
            <div className='depositvnd-input'>
                <p>{t("Amount of")} {symbol}</p>
                <div style={{ position: 'relative' }}>
                    <input type="text" onChange={handleChangeCoin} value={formatMoney(String(valueCoin))} />
                    <div className='withdrawcoin-max'>
                        <span>{symbol}</span>
                        <div className='withdrawcoin-max-item' onClick={handleMaxCoin}>MAX</div>
                    </div>
                </div>
            </div>
            <div className='depositvnd-input' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <p style={{ margin: '0' }}>{t("Max available:")} </p>

                <div style={{ fontSize: '16px' }}>{formatMoney(String(FormatGetwalletcoin(coin, getwallet)))} {symbol}</div>
            </div>
            <div style={{ fontSize: '16px' }}>
                {RenderFeeGas(pricecoin, symbol, getwallet?.data?.data, t)}
            </div>



            <div className='depositvnd-notify'>
                <img src="/img/!.png" alt="" />
                <p style={{ color: '#757575' }}>
                    {t("You must keep a minimum of 20 TRX in your wallet to secure enough gas fees for trading TRC20 tokens.")}
                </p>
            </div>
            <div className='depositvnd-notify'>
                <img src="/img/!.png" alt="" />
                <p style={{ color: '#757575' }}>
                    {t("You must keep a minimum of 20 TRX in your wallet to secure enough gas fees for trading TRC20 tokens.")}
                </p>
            </div>
            <div className='depositvnd-notify'>
                <img src="/img/!.png" alt="" />
                <p>{t("The overhead fees are not fixed, subject to change depending on the state of the blockchain networks")}</p>
            </div>
            <div className='depositvnd-notify'>
                <img src="/img/!.png" alt="" />
                <p>{t("Estimated completion time: 2 minutes")}</p>
            </div>
            {symbol == 'STF' && <div className="warning" style={{ color: 'red' }}>
                <i className="fas fa-info-circle"></i>
                <div>{t("For more information about STF please visit:")}
                    <a href="https://swaptobe.com/whitepaper.pdf" target='_blank' style={{ color: 'red' }}> https://swaptobe.com/whitepaper.pdf</a>
                </div>
            </div>}
            <div >
            </div>
            <div className="depositvnd-submit">
                <button onClick={handleComfirm}>Send</button>
            </div>
        </>
    );
}

export default Content;