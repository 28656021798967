import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Spin } from "antd";
import "./scss/style.scss";
import { getParent } from "../../redux/action/userAction";
import ParentF1 from "./ParentF1";
import { useTranslate } from "../../core/translate";
function DirectNetwork(props) {
  let { t } = useTranslate()
  useEffect(() => {
    let tab = t("Referral")
    document.title = tab
    return () => {
      document.title = "Swap tobe"
    }
  }, [t]);
  let element, flag;
  let { parent } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const [profile, setProfile] = useState({});
  const dispatch = useDispatch();

  function getParentFunc(idUser, email) {

    dispatch(getParent(idUser, (loading) => { if (loading) { setLoading(false); } }));
  }
  if (parent) {
    element = parent.children?.map((item, index) => {

      return (
        <li onClick={() => getParentFunc(item.id, item.username, item.email)}>
          <a className="text-dark">{`${item.username} `}</a>
          {/* <ul><ParentF1 func={getParent} array={item.children} /></ul> */}
          <ul><ParentF1 array={item.children} /></ul>

        </li>
      );
    });
  }
  flag = () => {
    return loading ? (
      <div className="example"><Spin /></div>
    ) : (

      <>
        <div className="text-dark"><b>{parent?.profile?.userName}</b></div>
        <ul>{element}</ul>
      </>
    );
  }
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      setLoading(true);
      setProfile(user?.axiologin);
      dispatch(getParent(user?.axiologin?.id, (loading) => { if (loading) { setLoading(false); } }));
    }
  }, []);
  return (
    <>
      <div style={{ background: "#fff", height: 'auto', fontFamily: "Arial" }}>
        <div className="container">
          <section className="section m-2">
            <div className="section-header">
              <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                  <div className="section-header-breadcrumb-content btn  mt-3 text-white" style={{ backgroundColor: "#087972" }} onClick={() => getParentFunc(profile?.id)}> Go back to see your system</div>
                </div>
              </div>
            </div>
          </section>
          <section className="content management-hierarchy m-2" style={{ background: "none" }} >
            <ul className="treee">
              <li>{flag()}</li>
            </ul>
          </section>
        </div>
      </div>
    </>
  );
}

export default DirectNetwork;
