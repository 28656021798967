

export const port = "https://tobeswap.net/"

export const portApp = "https://tobeswap.net/"
export const portSingup = "https://tobeswap.net/"
// export const portSingup = "https://swaptobe.com/"


// export const port = "http://10.0.0.5:4000/"

// export const portApp ="http://10.0.0.5:4000/"
// export const portSingup ="http://10.0.0.5:4000/"


// export const port = "http://10.0.0.1:4009/"

// export const portApp ="http://10.0.0.1:4009/"
// export const portSingup ="http://10.0.0.1:4009/"


// export const port = "https://swaptobe.org:4008/"

// export const portApp = "https://swaptobe.org:4008/"
// export const portSingup = "https://swaptobe.org:4008/"
