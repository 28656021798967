import React, { useEffect } from 'react'
import { Link, NavLink } from 'react-router-dom'
import { GoogleLogin } from 'react-google-login'
import { ErrorMessage } from 'core/Message'

import { useDispatch, } from 'react-redux'
import { axios } from '../../redux/action/authAction'

import { useTranslate } from "../../core/translate";
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { toggleShadow } from 'redux/action/toggleShadowActive'
import { SuccessMessage } from 'core/Message'
import User from 'api/User'

export default function Login() {
    const dispatch = useDispatch()

    // let { google } = useSelector(state => state.auth)

    const shadowLayer = useSelector(state => state.shadowLayer)

    let { flag } = shadowLayer
    function HandleLoginG(res) {
        dispatch(axios(res))
    }
    let { t } = useTranslate()
    useEffect(() => {
        let tab = t("Login / Register")
        document.title = tab
        return () => {
            document.title = t("Swap tobe")
        }

    }, [t]);
    let [email, setEmail] = useState(false)
    let [err, setErr] = useState('')
    function isEmail(val) {
        let regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!regEmail.test(val)) {
            return 'Invalid Email';
        }
    }
    const handleChange = (e) => {
        let value = e.target.value
        setEmail(value)
    }

    const handleSendEmail = async () => {
        let text = isEmail(email)
        if (text) {
            ErrorMessage(text, t)
        } else {
            try {

                let res = await User.sendmail(email)
                if (res) {
                    SuccessMessage('Successfully sent', t)
                }

            } catch (error) {
                ErrorMessage(error.response.data.message, t)
            }
        }
    }
    return (
        <>
            <main loading="lazy" className='homelogin'>
                <div className="container">
                    <div className="homelogin-title">
                        <h3>Login/Register</h3>
                    </div>
                    <div className="row homelogin-main">

                        <div className="col-lg-3 col-md-6 homelogin-card">
                            <div className='homelogin-card-content'>
                                <NavLink to="/SignUp">
                                    <img loading="lazy" src="img/registernew.png" alt="" />
                                    <p>Register</p>
                                </NavLink>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 homelogin-card">
                            <NavLink to="/form">
                                <img loading="lazy" src="img/email.png" alt="" />
                                <p>Sign In
                                    <br></br>
                                    with Account</p>
                            </NavLink>
                        </div>
                        <div className="col-lg-3 col-md-6 homelogin-card">
                            <NavLink to="/login-characters">
                                <img loading="lazy" src="img/12charatoers.png" alt="" />
                                <p>Login
                                    <br></br>
                                    with 12 charactoers</p>
                            </NavLink>
                        </div>
                    </div>
                    <div className='homelogin-bottom'>
                        <div className='homelogin-bottom-card'>
                            <span onClick={() => dispatch(toggleShadow(!flag))}>{t("Resend email activation ?")}</span>
                            <p>You agree to <NavLink to='/LegalPolicy'>SwapToBe's Terms of Service</NavLink> and <NavLink to='/LegalPolicy'>Privacy Policy.</NavLink></p>
                            {/* <div className='link'><a href="#">Forgot password?</a> or <a href="#">Don't have an account? Sign Up</a></div> */}
                        </div>
                    </div>

                </div>
            </main>
            {flag &&
                <div className="Notify" style={{ zIndex: 1001 }}>
                    <div className="Notify_top" style={{ backgroundColor: '#087972', color: "#fff" }}>
                        <span>{t("Resend email confirmation")}</span>
                        <div onClick={() => dispatch(toggleShadow(!flag))}>
                            <svg width={24} height={24} viewBox="0 0 15 32" fill="#fff"><path d="M14.464 20.992q.576.576.576 1.376t-.576 1.376q-.576.512-1.376.512t-1.376-.512L7.488 18.88l-4.224 4.864q-.576.512-1.376.512t-1.376-.512Q0 23.168 0 22.368t.512-1.376L4.928 16 .512 10.944Q0 10.368 0 9.568t.512-1.376q.576-.512 1.376-.512t1.376.512l4.224 4.864 4.224-4.864q.576-.512 1.376-.512t1.376.512q.576.576.576 1.376t-.576 1.376L10.048 16z" /></svg>
                        </div>
                    </div>
                    <div className="Notify_content">
                        <div className="Notify_content-item">
                            <div style={{ color: 'rgb(118, 116, 120)', fontFamily: 'Arial', marginBottom: '10px', marginLeft: '5px' }}>{t("Please resend the activation email ")}</div>
                            <input onChange={handleChange} type="text" placeholder="Email" style={{ border: '1px solid rgb(118, 116, 120)', color: '#000' }} />
                            <div onClick={handleSendEmail} style={{ textAlign: 'center', marginTop: '10px', maxWidth: '100%' }}>
                                <a style={{ color: '#fff', width: '100px', backgroundColor: '#087972', borderRadius: '4px', padding: '8px 25px' }}>{t("Send")}</a>

                            </div>
                        </div>
                    </div>
                </div>}

        </>

    )
}
