
import Search from "../../api/Search"
import { SEARCHMESSAGE, SEARCHMESSAGE2, SREACHBUYNOW, SREACHSELLNOW, VALUEBUY, VALUESELL } from "../type"

export function createSearch(type, payload) {
    return {
        type,
        payload
    }
}
export function createSearchBuyNow(value, type, slug, setLoadding) {
    return async (dispatch) => {
        try {

            let res = await Search.searchBuyNow(value, type, slug)
            if (res?.data?.data == '') {
                dispatch(createSearch(SEARCHMESSAGE, 'Không có dữ liệu'))
            } else {
                dispatch(createSearch(SEARCHMESSAGE, ''))
            }
            dispatch(createSearch(SREACHBUYNOW, res?.data?.data))
            dispatch(createSearch(VALUEBUY, value))
        } catch (error) {

        }
    }
}
export function createSearchSellNow(value2, type, slug, setLoadding) {
    return async (dispatch) => {
        try {
            let res = await Search.sreachSellNow(value2, type, slug)
            if (res?.data?.data == '') {
                dispatch(createSearch(SEARCHMESSAGE2, 'Không có dữ liệu'))
            } else {
                dispatch(createSearch(SEARCHMESSAGE2, ''))
            }
            dispatch(createSearch(SREACHSELLNOW, res?.data?.data))
            dispatch(createSearch(VALUESELL, value2))
        } catch (error) {

        }
    }
}