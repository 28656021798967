import React, { useEffect, useState } from 'react'

import QRCode from "react-qr-code";
import { SETQR, TURNON } from '../../../redux/type';
import { createA, getprofile, qrcode, turnOff, turnOn } from '../../../redux/action/userAction';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import User from '../../../api/User';
import Swal from 'sweetalert2';
import ChangePassword from './ChangePassword';
import UserProfile from './UserProfile';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useTranslate } from '../../../core/translate';
import CreateSecretPassphrase from './CreateSecretPassphrase';




export default function Authenticator() {
    let { t } = useTranslate()
    let [active, setACtive] = useState(0)

    useEffect(() => {
        let tab = t("Authenticator")
        document.title = tab + ' ' + "Authentication"
        return () => {
            document.title = t("Setting")
        }

    }, [t]);
    const dispatch = useDispatch()

    const { profile, setqr, qr } = useSelector(state => state.user)
    let { axiologin } = useSelector(state => state.auth)



    let token = JSON.parse(localStorage.getItem('user'))
    let test = JSON.parse(localStorage.getItem('authen'))

    useEffect(async () => {
        if (axiologin) {
            dispatch(createA(SETQR, profile?.data?.data?.enabled_twofa))
            dispatch(qrcode(token.axiologin.token))
        }
    }, [test])

    function authenticator(i) {
        setACtive(i)
        setNext(false)
        if (active == i) {
            setACtive(-1)
        }
    }
    function none() {
        setACtive(false)
    }
    let [next, setNext] = useState(false)
    let [otp, setOtp] = useState()
    function inputChange(e) {
        let value = e.target.value
        setOtp(value)
    }
    function Clickenable() {
        dispatch(turnOn(token.axiologin.token, otp, 1))
        setOtp('')

    }
    let history = useHistory()
    async function Clickdis() {
        try {
            let data = otp
            let res = await User.turnOff2fa(token.axiologin.token, data)
            if (res) {
                history.push("/")
            }
            localStorage.setItem('authen', 0)
            Swal.fire({
                title: res.data.message,
                icon: 'success',
                timer: 15000
            })
            dispatch(createA(SETQR, 0))

            dispatch(createA(TURNON, res))


        } catch (error) {
            Swal.fire({
                title: error.response.data.message,
                icon: 'error',
                timer: 15000
            })
        }
        // dispatch(turnOff(token.axiologin.token, otp, 0))
        setOtp('')
    }
    let [valueCopy, setValueCopy] = useState('')
    let [activeCopy, setActiveCopy] = useState(false)
    function handleCopy() {
        setActiveCopy(true)
        setTimeout(() => {
            setActiveCopy(false)
        }, 1500)
    }

    return (
        <>
            <div className="authenticators" >
                <div className={`${active == 0 ? "authenticator active" : "authenticator"}`} onClick={() => authenticator(0)} >
                    <div className="authenticator_svg">
                        {active == 0 ? <svg width={16} height={16} viewBox="0 0 30 32" fill="#FFFFFF"><path d="M13.728 17.152V24q0 1.44-1.024 2.432t-2.432.992H3.424q-1.408 0-2.432-.992T0 24V11.424q0-1.856.736-3.552T2.688 4.96 5.6 3.008t3.552-.736h1.12q.48 0 .832.352t.32.8v2.304q0 .448-.32.8t-.832.32h-1.12q-1.888 0-3.232 1.344t-1.344 3.232V12q0 .704.512 1.216t1.184.512h4q1.44 0 2.432.992t1.024 2.432zm16 0V24q0 1.44-1.024 2.432t-2.432.992h-6.848q-1.408 0-2.432-.992T16 24V11.424q0-1.856.736-3.552t1.952-2.912T21.6 3.008t3.552-.736h1.12q.48 0 .832.352t.32.8v2.304q0 .448-.32.8t-.832.32h-1.12q-1.888 0-3.232 1.344t-1.344 3.232V12q0 .704.512 1.216t1.184.512h4q1.44 0 2.432.992t1.024 2.432z" /></svg>
                            : <svg width={14} height={14} viewBox="0 0 25 32" fill="#B8B7BE"><path d="M25.152 13.152v3.424q0 .704-.512 1.216t-1.216.48H1.728q-.736 0-1.216-.48T0 16.576v-3.424q0-.736.512-1.216t1.216-.512h21.696q.736 0 1.216.512t.512 1.216z" /></svg>}
                    </div>
                    <div className="authenticator_title" >
                        <h4>{t("Authenticator")} Authentication</h4>
                    </div>
                </div>
                {active == 0 ?
                    <div className='authenticatormains' >
                        <div className="row" style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#fff' }}>
                            <div className="authenticatormain col-xl-6 col-md-12" >
                                <div style={{ border: '1px solid rgba(0, 0, 0, 0.125)', padding: '20px', borderRadius: '4px' }}>
                                    <div><h4 >{t("Scan QR Code")}</h4></div>
                                    {setqr == 0 ?
                                        <>
                                            <div className='qrcode' style={{ width: '150px', padding: '15px', border: '1px solid rgba(0, 0, 0, 0.125)' }}>
                                                <QRCode size="115" value={`${qr.data?.data?.otpAuth}`} />
                                            </div>

                                            <div className='secret'>
                                                <p>{t("Manual Setup Code :")}</p>
                                                <span style={{ fontWeight: '500' }}>{qr.data?.data?.secret}</span>
                                                <CopyToClipboard text={`${qr.data?.data?.secret}`}
                                                    onCopy={() => setValueCopy({ copied: true })}>
                                                    <button onClick={handleCopy} dir="auto" className={`css-901oao r-320f6p r-ubezar r-oxtfae r-135wba7 r-1jkjb r-rjfia`} style={{ backgroundColor: "rgb(235, 236, 240)", color: '#rgb(73, 71, 74)', padding: '5px', borderRadius: '4px', marginTop: '10px' }}>
                                                        {activeCopy ? <img src="/img/Copy.png" alt="" style={{ width: '24px' }} /> : "Copy"}
                                                    </button>
                                                </CopyToClipboard>
                                            </div>

                                        </> : ""}
                                    <div className="authenticatormain_title">
                                        <p>{t("Input Your Authenticator Code *")}</p>

                                    </div>
                                    <div className="authenticatormain_input">
                                        <input type="text" placeholder={`${t("Authenticator Code")}`} onChange={inputChange} value={otp} />
                                    </div>
                                    <div className="authenticatormain_button" >
                                        {setqr == 0 ? <button onClick={Clickenable}>{t("Enable 2FA")}</button> : ""}
                                        {setqr == 1 ? <button onClick={Clickdis}>{t("Disble 2FA")}</button> : ''}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> : ''}
            </div>


            <div className="authenticators changepassword" style={{ marginTop: '20px' }}>
                <div className={`${active == 1 ? "authenticator active" : "authenticator"}`} onClick={() => authenticator(1)} >
                    <div className="authenticator_svg">
                        {active == 1 ? <svg width={16} height={16} viewBox="0 0 30 32" fill="#FFFFFF"><path d="M13.728 17.152V24q0 1.44-1.024 2.432t-2.432.992H3.424q-1.408 0-2.432-.992T0 24V11.424q0-1.856.736-3.552T2.688 4.96 5.6 3.008t3.552-.736h1.12q.48 0 .832.352t.32.8v2.304q0 .448-.32.8t-.832.32h-1.12q-1.888 0-3.232 1.344t-1.344 3.232V12q0 .704.512 1.216t1.184.512h4q1.44 0 2.432.992t1.024 2.432zm16 0V24q0 1.44-1.024 2.432t-2.432.992h-6.848q-1.408 0-2.432-.992T16 24V11.424q0-1.856.736-3.552t1.952-2.912T21.6 3.008t3.552-.736h1.12q.48 0 .832.352t.32.8v2.304q0 .448-.32.8t-.832.32h-1.12q-1.888 0-3.232 1.344t-1.344 3.232V12q0 .704.512 1.216t1.184.512h4q1.44 0 2.432.992t1.024 2.432z" /></svg>
                            : <svg width={14} height={14} viewBox="0 0 25 32" fill="#B8B7BE"><path d="M25.152 13.152v3.424q0 .704-.512 1.216t-1.216.48H1.728q-.736 0-1.216-.48T0 16.576v-3.424q0-.736.512-1.216t1.216-.512h21.696q.736 0 1.216.512t.512 1.216z" /></svg>}
                    </div>
                    <div className="authenticator_title">
                        <h4>{t("Change Password")}</h4>
                    </div>
                </div>
                {active == 1 ?
                    <div className='authenticatormains'>
                        <ChangePassword />
                    </div> : ""}
            </div>
            <div className="authenticators changepassword" style={{ marginTop: '20px' }}>
                <div className={`${active == 2 ? "authenticator active" : "authenticator"}`} onClick={() => authenticator(2)} >
                    <div className="authenticator_svg">
                        {active == 2 ? <svg width={16} height={16} viewBox="0 0 30 32" fill="#FFFFFF"><path d="M13.728 17.152V24q0 1.44-1.024 2.432t-2.432.992H3.424q-1.408 0-2.432-.992T0 24V11.424q0-1.856.736-3.552T2.688 4.96 5.6 3.008t3.552-.736h1.12q.48 0 .832.352t.32.8v2.304q0 .448-.32.8t-.832.32h-1.12q-1.888 0-3.232 1.344t-1.344 3.232V12q0 .704.512 1.216t1.184.512h4q1.44 0 2.432.992t1.024 2.432zm16 0V24q0 1.44-1.024 2.432t-2.432.992h-6.848q-1.408 0-2.432-.992T16 24V11.424q0-1.856.736-3.552t1.952-2.912T21.6 3.008t3.552-.736h1.12q.48 0 .832.352t.32.8v2.304q0 .448-.32.8t-.832.32h-1.12q-1.888 0-3.232 1.344t-1.344 3.232V12q0 .704.512 1.216t1.184.512h4q1.44 0 2.432.992t1.024 2.432z" /></svg>
                            : <svg width={14} height={14} viewBox="0 0 25 32" fill="#B8B7BE"><path d="M25.152 13.152v3.424q0 .704-.512 1.216t-1.216.48H1.728q-.736 0-1.216-.48T0 16.576v-3.424q0-.736.512-1.216t1.216-.512h21.696q.736 0 1.216.512t.512 1.216z" /></svg>}
                    </div>
                    <div className="authenticator_title">
                        <h4>{t("Update profile")}</h4>
                    </div>
                </div>
                {active == 2 ?
                    <div className='authenticatormains'>
                        <UserProfile />
                    </div> : ""}
            </div>
            <div className="authenticators changepassword" style={{ marginTop: '20px' }}>
                <div className={`${active == 3 ? "authenticator active" : "authenticator"}`} onClick={() => authenticator(3)} >
                    <div className="authenticator_svg">
                        {active == 3 ? <svg width={16} height={16} viewBox="0 0 30 32" fill="#FFFFFF"><path d="M13.728 17.152V24q0 1.44-1.024 2.432t-2.432.992H3.424q-1.408 0-2.432-.992T0 24V11.424q0-1.856.736-3.552T2.688 4.96 5.6 3.008t3.552-.736h1.12q.48 0 .832.352t.32.8v2.304q0 .448-.32.8t-.832.32h-1.12q-1.888 0-3.232 1.344t-1.344 3.232V12q0 .704.512 1.216t1.184.512h4q1.44 0 2.432.992t1.024 2.432zm16 0V24q0 1.44-1.024 2.432t-2.432.992h-6.848q-1.408 0-2.432-.992T16 24V11.424q0-1.856.736-3.552t1.952-2.912T21.6 3.008t3.552-.736h1.12q.48 0 .832.352t.32.8v2.304q0 .448-.32.8t-.832.32h-1.12q-1.888 0-3.232 1.344t-1.344 3.232V12q0 .704.512 1.216t1.184.512h4q1.44 0 2.432.992t1.024 2.432z" /></svg>
                            : <svg width={14} height={14} viewBox="0 0 25 32" fill="#B8B7BE"><path d="M25.152 13.152v3.424q0 .704-.512 1.216t-1.216.48H1.728q-.736 0-1.216-.48T0 16.576v-3.424q0-.736.512-1.216t1.216-.512h21.696q.736 0 1.216.512t.512 1.216z" /></svg>}
                    </div>
                    <div className="authenticator_title">
                        <h4>{t("Create Secret Passphrase")}</h4>
                    </div>
                </div>
                {active == 3 ?
                    <div className='authenticatormains'>
                        <CreateSecretPassphrase />
                    </div> : ""}
            </div>
        </>
    )
}
