import { Pagination, Spin } from 'antd';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { GetHistory, HandleGetHistory } from 'redux/reducer/minningReducer';



export default function History() {
   const [page,setPage] = useState(1)
   const dispatch = useDispatch()
   const {getHistory} = useSelector(state => state.minning)
   const [loading, setLoading] = useState(false)
    useEffect(() => {
          dispatch(HandleGetHistory(page,setLoading))
    },[page])

    return (
        <>
        {getHistory?.total > 0 &&
            <div  className="minning-history">
                <div className="minning-history-title">
                <h3>MINING HISTORY</h3>
                </div>
                {loading ?  <div style={{height:'650px', display:'flex', justifyContent:'center', alignItem:'center'}}>
                    <Spin />
                </div> : 
                <div  style={{height:'650px'}}>
                {getHistory?.array?.map((e,i) => {
                    if(e?.type == 1){
                        return (
                            <div className="minning-history-table">
                            <div>
                            <strong>TBC MINING</strong>
                            <span> {(e?.create_at?.substring(0, 10))} {(e?.create_at?.substring(11, 19))}</span>
                            </div>
                            <div>
                            <div>+ {e?.TBC_Bonus} TBC</div>
                            <p>Processed</p>
                            </div>
                        </div>
                        )
                    }else{
                        return (
                            <div className="minning-history-table">
                            <div>
                            <strong>From {e?.message}</strong>
                            <span> {(e?.create_at?.substring(0, 10))} {(e?.create_at?.substring(11, 19))}</span>
                            </div>
                            <div>
                            <div>+ {e?.TBC_Bonus} TBC</div>
                            <p>Processed</p>
                            </div>
                        </div>
                        )
                    }
                   
                })}
                </div> }
            {getHistory?.total >= 10 && 
       <Pagination
                style={{ marginTop: '10px' }}
                defaultPageSize={10} //default size of page
                defaultCurrent={1}
                onChange={(page) => setPage(page)}
                total={getHistory?.total} //total number of card data available
            />}
   
     
</div>}
</>
    )
}
