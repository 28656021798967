import { useTranslate } from 'core/translate'
import React, { useRef, useState } from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { GetReq12Character } from 'redux/action/orderAction'
import SecretPassphrase from './SecretPassphrase'
import SubmitCharacterOff from './SubmitCharacterOff'

export default function CreateSecretPassphrase() {
    let { t } = useTranslate()
    let [value, setValue] = useState()
    const handleCheckBox = (e) => {
        setValue(e.target.value)
    }
    const [active, setActive] = useState(false)
    const handleNext = () => {
        if (value) {
            setActive(true)
        }
    }
    let { profile } = useSelector(state => state.user)
    return (
        <>
            {
                profile?.data?.data?.characters == 1 &&
                <SubmitCharacterOff />
            }
            {profile?.data?.data?.characters == 0 &&
                <>
                    {active ? "" :
                        <div className="CreateSecretPassphrase" style={{ padding: '20px' }}>
                            <div>
                                {t("In the next step you will see 12 words that will allow you to recover your account")}
                                <div className="CreateSecretPassphrase_img">
                                    <img src="img/sercurity.png" alt="" />
                                </div>
                                <div>
                                    <div className="CreateSecretPassphrase_checkbox">
                                        <div>
                                            <input type="checkbox" name="checkbox" value="Yes" onChange={handleCheckBox} style={{ marginRight: '10px', marginTop: '5px', height: '11px' }} />
                                            <span>{t("I understand if I lose my recovery phrase")} {t("I won't be able to access my account")}</span>

                                        </div>
                                    </div>
                                </div>
                                <div className="CreateSecretPassphrase_items">
                                    <a onClick={handleNext} className="CreateSecretPassphrase_item">{t("Next")}</a>
                                </div>

                            </div>
                        </div>}
                    {active && <SecretPassphrase />}
                </>
            }

        </>
    )
}
