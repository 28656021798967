import CreateApi from "api/CreateApi"
import reduxToolkit from "../../core/reduxToolkit"

const initialState = {
    showLoadding: false,

}





let { reducer, action, TYPE } = reduxToolkit({
    name: 'deposit',
    initialState,
    reducers: {

        ShowLoadding: function (state, action) {
            return {
                ...state,
                showLoadding: action.payload
            }
        },



    }
})

export default reducer
export const LOADDING = TYPE
export const { ShowLoadding } = action