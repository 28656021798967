import { Box } from '@mui/material'
import { Button, Form, Input } from 'antd'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { ValueListting } from 'redux/reducer/listtingReducer';







export default function Context({setActiveStep,activeStep}) {
  const dispatch = useDispatch()
  const {valueListting} = useSelector(state => state.listting)
  const onFinish = (value) => {
    const content = value?.contents
    dispatch(ValueListting({...valueListting, content}))
    setActiveStep(activeStep+1)
}
    return (
        <>
            <Form autoComplete='off' layout='vertical'  style={{ flex:'2', padding:'30px'}} onFinish={onFinish}>
            <h3 style={{color: '#235161', fontWeight:'700'}}>Information Form</h3>
            <Form.Item name="contents" label="Content of cooperation" rules={[{required:true}]} >
                    <Input.TextArea  defaultValue={valueListting?.content} value={ valueListting?.content}  style={{height:'250px'}}/>
                </Form.Item>
        
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Button
              color="inherit"
              onClick={() => setActiveStep(activeStep-1)}
              style={{width:'100px'}}
            >
              Back
            </Button>
            <Box sx={{ flex: "1 1 auto" }} />
            <Button htmlType='submit' style={{   width: "100px",background: "#4bbd99", color: "white"}}>
                Next
            </Button>
          </Box>
          </Form>
</>
       
    )
}
