import { Box, Button, Modal, Typography } from '@mui/material';
import { Spin } from 'antd';
import RecaptchaF2A from 'Components/RecaptchaF2A';
import { fixedNumber } from 'core/hook/fixedNumber';
import { formatMoney } from 'core/hook/formarMoney';
import { useTranslate } from 'core/translate';
// import { fixedNumber } from 'page/Admin/components/AdminP2P';
import React from 'react';
import { useSelector } from 'react-redux';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    color: '#757575',
    maxHeight: '600px',
    overflow: 'auto'
};

const box = {
    marginTop: '20px',
    display: 'flex',
    justifyContent: 'space-between'
};

const flex = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'start',
};
const text = {
    fontWeight: 'bold',
    marginTop: '20px'
};

const flexIMG = {
    display: 'flex',
    alignItems: 'baseline',
    marginTop: '20px'

};
const textIMG = {
    fontWeight: 'bold',
    marginLeft: '10px'
};

function DataWithdraw({ handleClose, open, amount, submit,loadding }) {
    const { dataWithdrawVnd } = useSelector(state => state.withdraw)
    const { getfee } = useSelector(state => state.user)
    const { t } = useTranslate()
    return (
        <>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                style={{zIndex:'10'}}
            >
                <Box sx={style}>
                    <Box >
                        <Typography id="modal-modal-title" variant="h6" component="h2" style={{ fontWeight: 'bold' }}>
                            {t('Check and verify your withdrawal information')}
                        </Typography>
                        <Box sx={flex}>
                            <Typography id="modal-modal-description" sx={text}>
                                {t("Withdrawal amount")}:
                            </Typography>
                            <Typography id="modal-modal-description" sx={text}>
                                {formatMoney(String(fixedNumber(amount - ((amount * getfee[0]?.raito) / 100), 10)))} VND
                            </Typography>
                        </Box>
                        <Box sx={flex}>
                            <Typography id="modal-modal-description" sx={text}>
                                {t('Withdrawal method')}
                            </Typography>
                            <Typography id="modal-modal-description" sx={text}>
                                {dataWithdrawVnd?.name_banking}
                            </Typography>
                        </Box>
                        <Box sx={flex}>
                            <Typography id="modal-modal-description" sx={text}>
                                {t("UserName")}:
                            </Typography>
                            <Typography id="modal-modal-description" sx={text}>
                                {dataWithdrawVnd?.owner_banking}
                            </Typography>
                        </Box>
                        <Box sx={flex}>
                            <Typography id="modal-modal-description" sx={text}>
                                {t("Account number")}:
                            </Typography>
                            <Typography id="modal-modal-description" sx={text}>
                                {dataWithdrawVnd?.number_banking}
                            </Typography>
                        </Box>

                        <RecaptchaF2A />

                        <Box sx={flexIMG}>
                            <i className="fas fa-info-circle" style={{ color: '#4BBD99' }}></i>
                            <Typography id="modal-modal-description" sx={textIMG} >
                                {t("Check to make sure the withdrawal information is correct. You can only withdraw money from your own account")}
                            </Typography>
                        </Box>
                        <Box sx={flexIMG}>
                            <i className="fas fa-info-circle" style={{ color: '#4BBD99' }}></i>
                            <Typography id="modal-modal-description" sx={textIMG} >
                                {t("Withdrawal fee is")} {getfee[0]?.raito}%
                            </Typography>
                        </Box>
                        <Box sx={flexIMG}>
                            <i className="fas fa-info-circle" style={{ color: '#4BBD99' }}></i>
                            <Typography id="modal-modal-description" sx={textIMG} >
                                {t("Expected completion time")}: 2 {t("minute")}
                            </Typography>
                        </Box>

                        <div className="depositvnd-submit" style={{ display: 'flex', justifyContent: 'space-between' }}>
                            {/* {showLoadding ? <div className="example" style={{ width: '100%' }}>
                    <Spin />
                </div> :
                    <button onClick={onSubmit}>Send</button>} */}
                    {loadding?    <button style={{ width: '40%' }} >     <Spin /></button> :    <button style={{ width: '40%' }} onClick={submit}>{t("Withdraw money")}</button> }
                        
                            <button style={{ background: '#D1D0D0', width: '40%' }} onClick={handleClose}>{t("Back")}</button>
                        </div>
                    </Box>
                </Box>
            </Modal>

        </>
    );
}

export default DataWithdraw;