import React, { useEffect, useState } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Spin } from 'antd';
import covertCoin from 'core/CovertCoin';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslate } from '../../../core/translate';
import { createHistorySwap } from '../../../redux/action/userAction';

export default function TableHistory({ typeF, typeT }) {
    let [loadding, setLoadding] = useState(false)
    let token = JSON.parse(localStorage.getItem('user'))
    let { symbolfrom, symbolto, totalhistoryswap } = useSelector(state => state.user)
    let { t } = useTranslate()
    let { pricecoin } = useSelector(state => state.coin)

    const formatDateTime = (str, char) => {
        let strSplit = str.split(char)
        let value1 = `${Number(strSplit[0]) < 10 ? `0${strSplit[0]}` : strSplit[0]}`
        let value2 = `${Number(strSplit[1]) < 10 ? `${char}0${strSplit[1]}` : `${char}${strSplit[1]}`}`
        let value3 = `${Number(strSplit[2]) < 10 ? `${char}0${strSplit[2]}` : `${char}${strSplit[2]}`}`

        return value1 + value2 + value3
    }
    const dispatch = useDispatch()
    let { historyswap } = useSelector(state => state.user)
    function truncate(value, precision) {
        var step = Math.pow(10, precision || 0);
        var temp = Math.trunc(step * value);

        var a = temp / step;
        return a.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    let test = (name1) => pricecoin?.data?.data.filter((e) => {
        let value
        if (e?.name == name1) {
            value = {
                price: e?.price,
                name: e?.name
            }
            return value
        }
    })


    let [limit, setLimit] = useState(100)
    useEffect(() => {
        if (historyswap == false) dispatch(createHistorySwap(token?.axiologin?.token, limit, 1, covertCoin(symbolfrom?.toUpperCase(), typeF), covertCoin(symbolto?.toUpperCase(), typeT)))
    }, [symbolfrom, symbolto])
    useEffect(() => {
        setLoadding(true)

        if (limit <= totalhistoryswap) {
            dispatch(createHistorySwap(token?.axiologin?.token, limit, 1, covertCoin(symbolfrom?.toUpperCase(), typeF), covertCoin(symbolto?.toUpperCase(), typeT)))
        }
        if (limit == totalhistoryswap) {
            setLoadding(false)
        }
        setLoadding(false)

    }, [limit])
    function handleScroll(e) {

        const { scrollTop, clientHeight, scrollHeight } = e.currentTarget
        if (scrollHeight - scrollTop === clientHeight) {
            setLimit(totalhistoryswap)
        }

    }
    return (
        <TableContainer component={Paper} onScroll={handleScroll} style={{ maxHeight: '500px', overflow: 'auto' }}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow >
                        <TableCell align="center" style={{ fontWeight: 'bold', color: '#757575' }}>{t('Command pair')}</TableCell>
                        <TableCell align="center" style={{ fontWeight: 'bold', color: '#757575' }}>{t("Amount:")}</TableCell>
                        <TableCell align="center" style={{ fontWeight: 'bold', color: '#757575' }}>{t('Receive')}</TableCell>
                        <TableCell align="center" style={{ fontWeight: 'bold', color: '#757575' }}>{t("Price change")}</TableCell>
                        <TableCell align="center" style={{ fontWeight: 'bold', color: '#757575' }}>{t("Status: ")}</TableCell>
                        <TableCell align="center" style={{ fontWeight: 'bold', color: '#757575' }}>{t('Time')}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {historyswap?.data?.data?.array?.map((e, i) => (
                        <TableRow
                            key={i}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell align="center" component="th" scope="row">
                                {symbolfrom} / {symbolto}
                            </TableCell>
                            <TableCell align="center">{e?.amount} {symbolfrom}</TableCell>
                            <TableCell align="center">{truncate(e?.wallet_amount, 6).replace(/,/g, '')} {symbolto}</TableCell>
                            <TableCell align="center">{truncate(e?.rate, 8).replace(/,/g, '')} {symbolto} / {symbolfrom}</TableCell>
                            <TableCell align="center"><div style={{ background: '#CDEBF2', borderRadius: '5px', padding: '3px', color: '#2A5872' }}>{t("Accomplished")}</div></TableCell>
                            <TableCell align="center">{formatDateTime(e?.created_at.substring(10, 17), ":")}</TableCell>

                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
