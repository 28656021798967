import { Box, Button, FormControl, NativeSelect } from '@mui/material';
import CreateApi from 'api/CreateApi';
import { ErrorMessage, SuccessMessage } from 'core/Message';
import { useTranslate } from 'core/translate';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Country } from '../api/Country';
import { getprofile } from "redux/action/userAction";
function UpdateCountry() {
    let { country, profile } = useSelector(state => state.user)
    let { t } = useTranslate()
    let token = JSON.parse(localStorage.getItem('user'))
    let [open, setOpen] = useState(false)
    let [dataCountry, setDataCountry] = useState('')
    let dispatch = useDispatch()
    useEffect(() => {
        if (profile?.data?.data?.changeCountry != 1) {
            setOpen(true)
        }
    }, [profile?.data?.data?.changeCountry])
    useEffect(() => {
        if (country == false) dispatch(Country())
    }, [])
    const handleChange = (event) => {
        setDataCountry(event.target.value);
    }
    function CloseChangeCountry() {
        setOpen(!open)
    }
    const submit = async () => {
        try {
            let res = await CreateApi.CreateApiS('api/user/changeCountry', {
                "country_id": dataCountry,
            }, true)
            SuccessMessage('Update success', t)
            setOpen(!open)
            dispatch(getprofile(token.axiologin.token))
        } catch (error) {
            ErrorMessage(error.response.data.message, t)
        }

    }
    return (
        <>
            {open ? <div className="Notify" >
                <div className="Notify_top" style={{ background: 'linear-gradient(90.17deg, #4BBD99 1.71%, #007C91 97.5%' }}>
                    <span style={{ color: "#fff" }}>{t("Update the country again")}</span>
                    <div onClick={CloseChangeCountry}>
                        <svg width={24} height={24} viewBox="0 0 15 32" fill="#fff"><path d="M14.464 20.992q.576.576.576 1.376t-.576 1.376q-.576.512-1.376.512t-1.376-.512L7.488 18.88l-4.224 4.864q-.576.512-1.376.512t-1.376-.512Q0 23.168 0 22.368t.512-1.376L4.928 16 .512 10.944Q0 10.368 0 9.568t.512-1.376q.576-.512 1.376-.512t1.376.512l4.224 4.864 4.224-4.864q.576-.512 1.376-.512t1.376.512q.576.576.576 1.376t-.576 1.376L10.048 16z" /></svg>
                    </div>
                </div>
                <div className="Notify_content">
                    <div className="Notify_content-item" style={{ borderLeft: '2px solid #4BBD99' }}>
                        <FormControl fullWidth >
                            <NativeSelect onChange={handleChange} id="select" sx={{
                                color: '#757575', fontWeight: 'bold'
                            }}>
                                {country?.map((element, index) => {
                                    return (
                                        <option value={element.id} style={{
                                            color: '#1C5C67'
                                        }} key={index}>{element.name}</option>
                                    );
                                })
                                }
                            </NativeSelect>
                            <Box sx={{ mt: '25px', textAlign: 'center' }}>
                                <Button onClick={submit} style={{ background: '#4BBD99', color: '#ffff', fontFamily: 'IBM Plex Sans' }}>{t("Update")}</Button>
                            </Box>
                        </FormControl>


                    </div>
                </div>
            </div> : ''
            }

            {open ? <div className="overlayNotify" onClick={CloseChangeCountry}></div> : ''}
        </>
    );
}

export default UpdateCountry;