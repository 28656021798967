import React, { useState } from 'react';
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Fab from "@material-ui/core/Fab";
import AddPhotoAlternateIcon from "@material-ui/icons/AddPhotoAlternate";
import blue from "@material-ui/core/colors/blue";
import { useTranslate } from 'core/translate';
import { makeStyles } from '@mui/styles';
import { ErrorMessage } from '../../../core/Message'
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { port } from 'port';
import { SuccessMessage } from 'core/Message';
import { createCheckTransactionDepositVnd } from 'redux/action/userAction';
import { Spin } from 'antd';
const useStyles = makeStyles({
    input: {
        display: "none"
    },
    button: {
        color: blue[900],
        margin: 10
    },

});
function CheckImg({ setCheckImg }) {
    const classes = useStyles();
    const { t } = useTranslate()
    const token = JSON.parse(localStorage.getItem('user'))
    const { checktransactiondepositvnd } = useSelector(state => state.user)
    const dispatch = useDispatch()
    let [activeImg, setActiveImg] = useState(false)
    let [image, setImage] = useState()
    let [loadding, setLoadding] = useState(false)

    function VerificationImg(e) {
        if (e.target.files[0].type === "image/jpeg" || e.target.files[0].type === "image/png") {
            setImage(e.target.files[0]);
            setActiveImg(true)
        } else {
            setTimeout(() => {
                window.location.reload()
            }, 1000);
            ErrorMessage("Only accept jpg,png,svg image files under 5m", t)
        }
    }
    const BackImg = () => {
        setCheckImg(false)
    }
    async function NapVND() {
        try {
            if (image) {
                const data = new FormData();
                data.append("photo", image);
                data.append("userid", token?.axiologin?.id);
                data.append("idTransaction", checktransactiondepositvnd?.data?.data?.id);
                let res = await axios({
                    url: `${port}api/uploadDepositVND`,
                    method: `POST`,
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                    data,
                });
                if (res) {
                    SuccessMessage("Post proof of success!", t)
                    setActiveImg(false)
                    setImage(null)
                    dispatch(createCheckTransactionDepositVnd(token?.axiologin?.token, setLoadding))
                }
            } else {
                ErrorMessage('Please send proof', t)

            }
        } catch (error) {
            ErrorMessage(error, t)
        }
    }
    const renderInitialState = () => {
        return (
            <>
                <CardContent>

                    <Grid container justify="center" alignItems="center">
                        {activeImg ? <div className='ImageVerification_img' style={{ textAlign: 'center' }}>
                            <img src={URL.createObjectURL(image)} />
                        </div> : <>
                            <input
                                accept="image/*"
                                className={classes.input}
                                id="contained-button-file"
                                multiple
                                type="file"
                                onChange={VerificationImg}
                            />
                            <label htmlFor="contained-button-file">
                                <img src="/img/upload.jpg" alt="" style={{ width: '80%' }} />
                            </label>
                        </>}

                    </Grid>
                </CardContent>
            </>
        )
    }
    return (
        <>
            <div className='depositvnd-title'>
                <p>{t('Send proof')}</p>
            </div>
            <div className='depositvnd-input checkvnd-content' style={{ justifyContent: 'center', boxShadow: '0px 1px 3px 0px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 2px 1px -1px rgb(0 0 0 / 12%);' }}>
                <div>
                    {renderInitialState()}
                </div>
            </div>
            <div className="depositvnd-submit checkvnd-submit">
                {loadding ? <div className="example" style={{ width: '100%' }} >
                    <Spin />
                </div> :
                    <button onClick={NapVND}>{t("Send")}</button>}
                <button onClick={BackImg}>{t("Cancel")}</button>
            </div>
        </>
    );
}

export default CheckImg;