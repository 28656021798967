import { SHADOW_LAYER_TOGGLE } from "../type";



let initState = {
    flag: false
}


export default function toggleShadowReducer(state = initState, action) {
    switch (action.type) {
        case SHADOW_LAYER_TOGGLE:


            return {
                ...state,
                flag: action.payload,
            }
        default:
            return state;
    }
}