import { ErrorMessage, Field } from 'formik'
import React from 'react'

export default function RadioButton(props) {
    const { lable, name, options, ...rest } = props
    return (
        <div className='form-group' style={{ marginBottom: '0' }}>
            <div>

                <label style={{ color: '#000' }}>{lable}</label>
                <Field name={name} {...rest}>
                    {
                        ({ field }) => {
                            return options.map(option => {

                                return (
                                    <React.Fragment key={option.key}>
                                        <div className="custom-input-content">
                                            <input type="radio"
                                                id={option.value}
                                                {...field}
                                                value={option.value}
                                                checked={field.value === option.value} />
                                            <label htmlFor={option.value}>{option.key}</label>
                                        </div>
                                    </React.Fragment>

                                )
                            })
                        }
                    }
                </Field>
                <ErrorMessage name={name}>
                    {(mes) => (
                        <p className="text-danger" style={{ color: 'red' }}>* {mes}</p>
                    )}
                </ErrorMessage>
            </div>
        </div>
    )
}
