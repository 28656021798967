import { Pagination, Spin } from 'antd';
import { formatDateTime } from 'core/hook/formatDateTime';
import { truncate } from 'core/hook/truncate';
import { useTranslate } from 'core/translate';
import React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createHistoryDepositCoin } from 'redux/action/userAction';

function HistoryDepositVnd() {
    let token = JSON.parse(localStorage.getItem('user'))
    let { symbol, historydepositvnd } = useSelector(state => state.user)
    let { showLoadding } = useSelector(state => state.loadding)
    let { t } = useTranslate()

    const dispatch = useDispatch()
    useEffect(() => {
        if (symbol == "USDT.TRC20") {
            dispatch(createHistoryDepositCoin(token?.axiologin?.token, 1, "USDT"))
        } else if (symbol == "USDT.ERC20") {
            dispatch(createHistoryDepositCoin(token?.axiologin?.token, 1, "USDT"))
        } else {
            dispatch(createHistoryDepositCoin(token?.axiologin?.token, 1, symbol))
        }
    }, [symbol])

    function handlehistorydepositcoin(page) {
        if (symbol == "USDT.TRC20") {
            dispatch(createHistoryDepositCoin(token?.axiologin?.token, page, "USDT"))
        } else if (symbol == "USDT.ERC20") {
            dispatch(createHistoryDepositCoin(token?.axiologin?.token, page, "USDT"))
        }
        else {
            dispatch(createHistoryDepositCoin(token?.axiologin?.token, page, symbol))
        }
    }
    const list = historydepositvnd?.data?.data?.array?.map((e, i) => {
        let stg
        if (e.type_user == 2 || e?.type_admin == 2) {
            stg = <>
                <a href="">{t("Canceled")}</a>

            </>
        }
        else if (e.type_admin == 0 || e?.type_user == 0) {
            stg = <>
                <a href="">{t("Pendding")}</a>

            </>
        }
        else if (e.type_admin == 1) {
            stg = <>
                <a className='text text-success'>{t("Success")}</a>
            </>
        } else {
            stg = ''
        }
        return (
            <div className="dhistory-content" key={i}>
                <div>
                    <p>{t("Reference code")} {e?.id}</p>
                    <p>{formatDateTime(e?.created_at.substring(0, 9), "/")} {formatDateTime(e?.created_at.substring(10, 17), ":")}</p>
                </div>
                <div>
                    <p> + {truncate(e?.amount + (e?.amount / 100 * e?.percent))} VND</p>
                    {stg}
                </div>

            </div>
        )
    })


    return (
        <>
            {showLoadding ? <div className="example" style={{ width: '100%' }}>
                <Spin />
            </div> :
                <>
                    <div className='dhistory-title'>
                        <p>History</p>
                    </div>
                    {historydepositvnd?.data?.data?.total == 0 ? <div className="content" style={{ background: '#fff', padding: '20px' }}>
                        <div className="interest-list">
                            <div style={{ color: "#000", fontSize: '18px', fontStyle: 'italic', textAlign: 'center' }}>
                                {t("No transactions yet")}
                            </div>
                        </div>
                    </div> :
                        <>
                            {list}
                        </>}
                </>
            }
            <Pagination
                style={{ marginTop: '20px' }}
                defaultPageSize={5} //default size of page
                onChange={handlehistorydepositcoin}
                defaultCurrent={1}
                total={historydepositvnd?.data?.data?.total} //total number of card data available
            />
        </>
    );
}

export default HistoryDepositVnd;