import { useCountDown } from 'core/hook/useCountdown'
import React, { useEffect } from 'react'
import { ProgressBar } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import BgBuybtc from 'assets/img/svg/bg-buybtc.svg'
import imageBuytbc from  'assets/img/svg/image-buytbc.svg'
import arrow from  'assets/img/svg/arrow-buy.svg'
import { NavLink } from 'react-router-dom/cjs/react-router-dom'
import top from 'assets/img/svg/top.svg'
import bottom from 'assets/img/svg/bottom.svg'
import { HandleGetInfoTBC } from 'redux/reducer/buytbcReducer'
import { useTranslate } from 'core/translate'







export default function BuyTBC() {
    const dispatch = useDispatch()
    const {getInfoTBC} = useSelector(state => state.buytbc)
    const {t} = useTranslate()
  
    // const [days, hours, minutes, seconds] = useCountDown(new Date('2022-09-08 00:00:00'))
    const [days, hours, minutes, seconds] = useCountDown(getInfoTBC?.end_at)
    let sold = getInfoTBC?.sold /( getInfoTBC?.total_token /100)
    useEffect(() => {
        dispatch(HandleGetInfoTBC())
    },[])
    return (

        <div className='buytbc' style={{background:`url(${BgBuybtc})`}} >
            <div className="container">
                <div className="buytbc-main">
                     <div className="buytbc-mains">
                        <div className='buytbc-main-title'>
                            <h3>{t("Reserve TBC Program")}</h3>
                            <p>{t("There are having")} <strong>{getInfoTBC?.total_buy} {t("users")}</strong> {t("holding TBC")}</p>
                        </div>
                        <div className='buytbc-main-progressBar'>
                            <div>
                                <ProgressBar variant='success' animated now={`${sold}`}   style={{minHeight:"20px",borderRadius:'5px', margin: 0}}/>
                            </div>
                            <div>
                                <div><strong>{getInfoTBC?.sold} </strong> {t("TBC has already held")} </div>
                                <div style={{color:'#fff'}}>{getInfoTBC?.total_token} TBC</div>
                            </div>
                        </div>
                    </div>
                    <div className="buytbc-countdown">
                        {getInfoTBC?.end_at > 0 && 
                        <div className='buytbc-countdown-title'>
                          <div>
                            <div>{days > 0 ? days : '00' }</div>
                            <div>{t("Days")}</div>
                          </div>
                             <div>
                            <div>{hours > 0 ? hours : '00'}</div>
                            <div>{t("Hours")}</div>
                          </div>
                          <div>
                            <div>{minutes>0 ? minutes: '00' }</div>
                            <div>{t("Minutes")}</div>
                          </div>
                          <div>
                            <div>{seconds > 0 ? seconds: '00'}</div>
                            <div>{t("Seconds")}</div>
                          </div>
                        </div>}
                        <div className='buytbc-countdown-button'>
                            <div>
                           
                                <div><NavLink to="/reserve-tobecoins">{t("Reserve Now")}</NavLink></div>
                            </div>
                        </div>
                    </div>
                    <img src={top} alt="" style={{position:"absolute", right:"-60px",top:"-70px"}}/>
                        <img src={bottom} alt="" style={{position:"absolute", left:"-65px",bottom:'-90px'}}/>
                </div>
                <div className='buytbc-text'>
                    <div>
                        <img src={arrow} alt="" />
                        <p>{t("The earlier you hold TBC, the more profits you will get.")}</p>
                    </div>
                    <div>
                        <img src={arrow} alt="" />
                        <p>{t("Expectedly listed on exchange on Q4/2022. There will be 20% amount of coins returned from the exchange date, the rest 80% will be returned within 16 months (5% each month).")}</p>
                    </div>
                </div>
            </div>
            <div className='buytbc-img'>
                <img src={imageBuytbc} alt="" />
            </div>
        </div>

    //    <div style={{color:"black"}}>
        
    //     {days}:
    //     {hours}:
    //     {minutes}:
    //     {seconds}
    //    </div>
    )
}
