import { Box } from '@mui/material'
import { Button, Form, DatePicker, Select, Input, Upload, message, Spin } from 'antd'
import CreateApi from 'api/CreateApi'
import { ErrorMessage, SuccessMessage } from 'core/Message'
import { useTranslate } from 'core/translate'
import React, { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ValueListting } from 'redux/reducer/listtingReducer'
import {UploadOutlined} from '@ant-design/icons'
import {UploadFile}  from 'antd/lib/upload/interface'

const style = {
    minHeight:'450px',
    "@media(max-width:991px)":{
        minHeight:'200px',
    }
}


const {Option} = Select;

export default function Time({setActiveStep,activeStep}) {
    const {RangePicker} = DatePicker
    const {t} = useTranslate()
    const dispatch = useDispatch()
    const {valueListting} = useSelector(state => state.listting)
    const [loading, setLoading] = useState(false)

    const handlePosition = (value) => {
        dispatch(ValueListting({...valueListting,position:value}))
    }


    // const valuedateFileType = (UploadFile, allowedTypes) => {
    //     const {type,name} = UploadFile
    //     if(!allowedTypes){
    //         return true;
    //     }
    //     if(type){
    //         return allowedTypes.includes(type);
    //     }

    // }

    // const UploadProps = useMemo (() => ({
    //     beforeUpload:(file) => {
    //         const isAllowedType = valuedateFileType(file,"application/vnd.openxmlformats-officedocument.wordprocessingml.document" )
    //         const issAllowedType = valuedateFileType(file,"image/png" )

    //         if(!isAllowedType && !issAllowedType){
    //             setFileList((state) => [...state]);
    //             message.error('Only receive pdf or word files')
    //             return false
    //         }
    //         setFileList((state) => [...state,{file}])
    //         return false
    //     },
    //     onRemove:(file)=>{
    //         if(fileList.some((item) => item.uid ===file.uid)){
    //             setFileList((fileList) => fileList.filter((item) => item.uid !== file.uid));
    //             return true
    //         }
    //         return false
    //     }
    // }))
        
    

    const onFinish = async (value) => {
        setLoading(true)
        const freetime = value?.time
        const customPosition = value?.customPosition
        try {
            let res = await CreateApi.CreateApiSS('api/contact/v1/add', { 
                "fullname": valueListting?.fullname,
                "phone":valueListting?.phone,
                "address":valueListting?.address,
                "email":valueListting?.email,
                "content":valueListting?.content,
                "freetime":freetime,
                "Position": customPosition ? customPosition : valueListting?.position,
                'type':'call_for_investors'

             })
             setActiveStep(activeStep+1)
             SuccessMessage(res?.data?.message, t)
        } catch (error) {
            ErrorMessage(error?.response?.data?.message, t)

        }
        setLoading(false)
    }
   
 
    return (
        <>
            <Form autoComplete='off' layout='vertical'  style={{ flex:'2', padding:'30px'}}onFinish={onFinish }>
            <Box sx={style}>
                <h3 style={{color: '#235161', fontWeight:'700'}}>Appointment Time</h3>
                <Form.Item label="Time" name='time' rules={[{required:true}]} >
                    <RangePicker />
                </Form.Item>

                <Form.Item label="Position " name='position' rules={[{required:true}]} >
                    <Select onChange={handlePosition}>
                        <Option value='CEO'>CEO</Option>
                        <Option value='Founder/Co-Founder'>Founder/Co-Founder</Option>
                        <Option value='Other'>Other</Option>
                    </Select>
                </Form.Item>


                <Form.Item noStyle 
                shouldUpdate={(pervValues, currentValues) => pervValues.position !== currentValues.position}
                rules={[{required:true}]} >
               {({getFieldValue}) => getFieldValue('position') === 'Other' ? (
                    <Form.Item  name='customPosition' rules={[{required:true}]} >
                        <Input />
                </Form.Item>
               ) : null}
                </Form.Item>

                
                {/* <Form.Item label="Upload " name='upload' >
                    <Upload name='upload' fileList={fileList?.file} {...UploadProps}  rules={[{required:true}]}>
                        <Button icon={<UploadOutlined />}>Click to upload</Button>
                  </Upload>
                </Form.Item> */}
            </Box>
        
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Button
              color="inherit"
              onClick={() => setActiveStep(activeStep-1)}
              sx={{ mr: 1 }}
              style={{width:'100px'}}
            >
              Back
            </Button>
            <Box sx={{ flex: "1 1 auto" }} />
                {loading ?    <Button htmlType='submit' style={{   width: "100px",background: "#4bbd99"}}>
              <Spin />
            </Button> : 
           
           <Button  htmlType='submit' style={{   width: "100px",background: "#4bbd99", color: "white"}}>
           Next
       </Button> }
          </Box>
          </Form>
</>
       
    )
}
