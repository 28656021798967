import { formatMoney } from 'core/hook/formarMoney';
import { useTranslate } from 'core/translate';
import React, { useState } from 'react';
import style from '../../../assets/style.scss'

function ContentUser({ symbol, handleChange, handleChangeCoin, handleComfirmUser, valueCoin, setNoteMessage }) {
    let { t } = useTranslate()

    function note(e) {
        let text = e.target.value
        setNoteMessage(text)
    }

    return (
        <>

            <div className='depositvnd-input'>
                <p>UserName</p>
                <input type="text" onChange={handleChange} />
            </div>
            <div className='depositvnd-input'>
                <p>{t("Amount of")} {symbol}</p>
                <div style={{ position: 'relative' }}>
                    <input type="text" onChange={handleChangeCoin} value={formatMoney(String(valueCoin))} />
                    <div className='withdrawcoin-max' style={{ right: '0' }}>
                        <span>{symbol}</span>
                    </div>
                </div>
            </div>


            <div className='depositvnd-input'>
                <p>{t("Message")} </p>
                <div style={{ position: 'relative' }}>
                    <textarea type="text" placeholder="I'm fine, tks. And u!" onChange={note}></textarea>
                </div>
            </div>
            <div className="depositvnd-submit">
                <button onClick={handleComfirmUser}>Send</button>
            </div>
        </>
    );
}

export default ContentUser;