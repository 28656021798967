import { Spin } from 'antd'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react'

import Swal from 'sweetalert2'
import User from '../../../api/User'
import { useTranslate } from '../../../core/translate'
import * as yup from 'yup';
import { useHistory } from 'react-router'

export default function ChangePassword() {

    let { t } = useTranslate()
    useEffect(() => {
        let tab = t("Change Password")
        document.title = tab
        return () => {

            document.title = t("Setting")
        }
    }, [t]);
    let [loadding, setLoadding] = useState(false)
    const schemaPassword = yup.object().shape({

        password: yup.string().required("Not be empty"),
        passwordNew: yup.string().required("Not be empty"),
        retypePassword: yup
            .string()
            .required("Not be empty")
            .oneOf([yup.ref("passwordNew"), null], "passwordNew do not match !"),

    });
    let history = useHistory()
    return (
        <>
            <div id="main-wrapper" className='main-dashboard' style={{ backgroundColor: '#fff', padding: '20px 0', width: "100%" }}>
                <div className="authincation section-padding">
                    <div className="container h-100 authincation_moblie">
                        <div className="row justify-content-center h-100 align-items-center">
                            <div className="col-xl-8 col-md-8">

                                <div className="auth-form card">
                                    <div className="card-header justify-content-center">
                                        <h4 className="card-title">{t("Change Password")}</h4>
                                    </div>
                                    <div className="card-body">
                                        <Formik
                                            onSubmit={async (value) => {


                                                try {

                                                    let token = JSON.parse(localStorage.getItem('user'))
                                                    const object = {

                                                        password: value.password,
                                                        passwordNew: value.passwordNew,
                                                    }


                                                    setLoadding(true)
                                                    let res = await User.updatepassword(token?.axiologin?.token, object)
                                                    if (res.data.status == true) {
                                                        history.push('/')
                                                        Swal.fire({
                                                            title: t(`${res.data.message}`),
                                                            icon: 'success',
                                                            timer: 1500
                                                        })
                                                    }
                                                    setLoadding(false)



                                                } catch (error) {


                                                    setLoadding(false)
                                                    Swal.fire({
                                                        title: t(error.response.data.message),
                                                        icon: 'error',
                                                        timer: 1500,

                                                    })
                                                    // setTimeout(() => {
                                                    //     window.location.reload()
                                                    // }, 2000)


                                                }
                                            }


                                            }
                                            validationSchema={schemaPassword}
                                            initialValues={{
                                                password: "",
                                                passwordNew: "",
                                                retypePassword: "",
                                            }}
                                            render={(formikProps, handleBlur) => {
                                                return (
                                                    <>
                                                        <Form className="signin_validate">



                                                            <div className="form-group">
                                                                <Field
                                                                    className="form-control"
                                                                    type="password"
                                                                    name="password"
                                                                    onChange={formikProps.handleChange}
                                                                    placeholder={t("Password")}
                                                                />
                                                            </div>
                                                            <ErrorMessage name="password">
                                                                {(mes) => (
                                                                    <p className="text-danger">* {mes}</p>
                                                                )}
                                                            </ErrorMessage>
                                                            <div className="form-group">
                                                                <Field
                                                                    className="form-control"
                                                                    type="password"
                                                                    name="passwordNew"
                                                                    onChange={formikProps.handleChange}
                                                                    placeholder={t("Password new")}
                                                                />
                                                            </div>
                                                            <ErrorMessage name="passwordNew">
                                                                {(mes) => (
                                                                    <p className="text-danger">* {mes}</p>
                                                                )}
                                                            </ErrorMessage>
                                                            <div className="form-group">
                                                                <Field
                                                                    className="form-control"
                                                                    type="password"
                                                                    name="retypePassword"
                                                                    onChange={formikProps.handleChange}
                                                                    placeholder={t("Retype Password")}
                                                                />
                                                            </div>
                                                            <ErrorMessage name="retypePassword">
                                                                {(mes) => (
                                                                    <p className="text-danger">* {mes}</p>
                                                                )}
                                                            </ErrorMessage>









                                                            {
                                                                loadding ? <div className="example">
                                                                    <Spin />
                                                                </div> :
                                                                    <button
                                                                        className="btn btn-success btn-block" style={{ color: '#fff', marginTop: '20px' }}
                                                                    >
                                                                        {t("Accept")}
                                                                    </button>
                                                            }
                                                        </Form>
                                                    </>
                                                );
                                            }}
                                        />


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
