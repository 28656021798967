export const REMOVE = 'REMOVE'
export const TEST = 'TEST'
export const TEST2 = 'TEST2'
export const LANG = 'LANG'
export const NAV = 'NAV'
export const SELECT = 'SELECT'
export const MESSENGER = 'MESSENGER'
export const POPUPBUY = 'POPUPBUY'
export const POPUPSELL = 'POPUPSELL'
export const LOGIN = "LOGIN"
export const VERIFYEMAIL = "VERIFYEMAIL"

export const AXIOSLOGIN = "AXIOSLOGIN"
export const AUTH_LOGIN_ERROR = "AUTH_LOGIN_ERROR"
export const AUTH_LOGIN_STATUS = "AUTH_LOGIN_STATUS"
export const GOOGLE = "GOOGLE"

export const COIN = 'COIN'
export const DEPOSIT = "DEPOSIT"
export const WITHDRAW = "WITHDRAW"
export const POPUPWITHDRAW = 'POPUPWITHDRAW'

export const REALTIME = 'REALTIME'
export const COUNTRY = 'COUNTRY'
export const QRCODE = "QRCODE"
export const TURNON = "TURNON"
export const ON = "ON"
export const OFF = "OFF"
//KYC
export const IMGTRUOC = "IMGTRUOC"
export const IMGSAU = "IMGSAU"
export const UPLOADKYC = "UPLOADKYC"
export const GETPROFILE = "GETPROFILE"
export const SUCCESS = "SUCCESS"

//User information
export const USERINFORMATION = "USERINFORMATION"

//
export const GETWALLET = "GETWALLET"
export const LENDING = "LENDING"
export const SETQR = "SETQR"
export const EXCHANGE = "EXCHANGE"
export const BUYSELL = "BUYSELL"
//hinh thuc giao dich
export const TRANSACTIONFORM = "TRANSACTIONFORM"
export const VNDUSD = "VNDUSD"

export const ADDBUYP2P = "ADDBUYP2P"
export const ADDSELLP2P = "ADDSELLP2P"
export const BUYADLIST = "BUYADLIST"
export const SELLADLIST = "SELLADLIST"

export const REDIRECT = "REDIRECT"
export const USERADLIST = "USERADLIST"
// export const BUYP2PADLIST = "BUYP2PADLIST"
// export const SELLP2PADLIST = "SELLP2PADLIST"
export const SREACHBUYNOW = "SREACHBUYNOW"
export const SEARCHMESSAGE = "SEARCHMESSAGE"
export const SREACHSELLNOW = "SREACHSELLNOW"
export const SEARCHMESSAGE2 = "SEARCHMESSAGE2"
export const VALUEBUY = "VALUEBUY"
export const VALUESELL = "VALUESELL"
export const INDEXBUY = "INDEXBUY"
export const INDEXSELL = "INDEXSELL"
export const TYPETOTAL = "TYPETOTAL"
export const CREATEWALLET = "CREATEWALLET"
export const CREATEWALLETERC = "CREATEWALLETERC"
export const CREATEWALLETTRC = "CREATEWALLETTRC"
export const CREATEWALLETCOIN = "CREATEWALLETCOIN"
export const CREATEWALLETCOINTRC = "CREATEWALLETCOINTRC"
//swap
export const SYMBOL = "SYMBOL"
export const SYMBOLTO = "SYMBOLTO"
export const IMAGESYMBOLTO = "IMAGESYMBOLTO"
export const INDEXSYMBOLTO = "INDEXSYMBOLTO"
export const SYMBOLFROM = "SYMBOLFROM"
export const IMAGESYMBOLFROM = "IMAGESYMBOLFROM"
export const INDEXSYMBOLFROM = "INDEXSYMBOLFROM"

export const SYMBOLTOBUSD = "SYMBOLTOBUSD"
export const IMAGESYMBOLTOBUSD = "IMAGESYMBOLTOBUSD"
export const SYMBOLFROMBUSD = "SYMBOLFROMBUSD"
export const IMAGESYMBOLFROMBUSD = "IMAGESYMBOLFROMBUSD"

//lịch sử tạo quảng cáo bán
export const SELLADLISTUSER = "SELLADLISTUSER"
export const BUYADLISTUSER = "BUYADLISTUSER"

export const BUYADLISTUSERPENDDING = "BUYADLISTUSERPENDDING"
export const SELLADLISTUSERPENDDING = "SELLADLISTUSERPENDDING"

//cover price

export const PRICECONVERT = "PRICECONVERT"
export const PRICECONVERTREALTIME = "PRICECONVERTREALTIME"
export const CONVERTCOIN = "CONVERTCOIN"
//News
export const DATANEWS = "DATANEWS"

//getBaking
export const GETBAKING = 'GETBAKING'

//checkTransactionDepositVnd
export const CHECKTRANSACTIONDEPOSITVND = 'CHECKTRANSACTIONDEPOSITVND'
//history nap

export const HISTORYDEPOSITVND = 'HISTORYDEPOSITVND'
// getlistbankinguser
export const GETLISTBANKINGUSER = "GETLISTBANKINGUSER"

//historyswap 
export const HISTORYSWAP = "HISTORYSWAP"
export const TOTALHISTORYSWAP = "TOTALHISTORYSWAP"
//history Lending
export const HISTORYLENDING = "HISTORYLENDING"
//history LendingTime
export const HISTORYLENDINGTIME = "HISTORYLENDINGTIME"
//history Staking
export const HISTORYSTAKING = "HISTORYSTAKING"
//Interest staking
export const INTERESTSTAKING = "INTERESTSTAKING"

export const COMMISSIONSTAKING = "COMMISSIONSTAKING"
//getExchangeBOS  
export const GETBOS = "GETBOS"
//GetExchangeBpay
export const GETBPAY = "GETBPAY"
//SHADOW_LAYER_TOGGLE
export const SHADOW_LAYER_TOGGLE = "SHADOW_LAYER_TOGGLE"

//gethistorywidthdraw  
export const GETHISTORYWIDTHDRAW = "GETHISTORYWIDTHDRAW"

export const HISTORYWIDTHDRAWADRESS = "HISTORYWIDTHDRAWADRESS"
//Get TRC20
export const GETTRC20 = "GETTRC20"



export const TYPECOIN = "TYPECOIN"

//f2a
export const F2A = "F2A"
//history lai lending
export const INTERESTLENDING = "INTERESTLENDING"

//history deposit coin
export const HISTORYDEPOSITCOIN = "HISTORYDEPOSITCOIN"

//history HISTORYTRANFER
export const HISTORYTRANFER = "HISTORYTRANFER "

//history HISTORYTRANFER
export const HISTORYTRANSACTION = "HISTORYTRANSACTION "
export const HISTORYTRANSACTIONTRC = "HISTORYTRANSACTIONTRC"
export const HISTORYTRANSACTIONVND = "HISTORYTRANSACTIONVND"
//getnotification 
export const GETNOTIFICATION = "GETNOTIFICATION"

//PARENT

export const PARENT = "PARENT"

//pricecoin
export const PRICECOIN = "PRICECOIN"

//home
export const HOMELIST = "HOMELIST"

//getFee
export const GETFEE = "GETFEE"
export const GETFEEDIFFERENCE = "GETFEEDIFFERENCE"
export const GETFEESTF = "GETFEESTF"


export const GETFEEERC20 = "GETFEEERC20"
//getref
export const GETREF = "GETREF"



//ref signup
export const REFERRAL = "REFERRAL"



export const LIST12CHARACTERS = "LIST12CHARACTERS"
export const LIST12CHARACTERSRAMDOM = "LIST12CHARACTERSRAMDOM"

export const CHARACTERSTURN = "CHARACTERSTURN"










// <--------admin----->
export const ADMINSEARCH = "ADMINSEARCH"
export const STATISTICAL = "STATISTICAL"

export const ADGETALLFEE = "ADGETALLFEE"
export const ADUPDATEFEE = "ADUPDATEFEE"
export const ADUPDATEPRICECOIN = "ADUPDATEPRICECOIN"

//list tạo quảng cao admin
export const GETBUYLISTP2PADMIN = "GETBUYLISTP2PADMIN"
export const GETSELLLISTP2PADMIN = "GETSELLLISTP2PADMIN"

//list tạo quảng cáo admin pendding 
export const GETBUYLISTP2PADMINPENDING = "GETBUYLISTP2PADMINPENDING"
export const GETSELLLISTP2PADMINPENDING = "GETSELLLISTP2PADMINPENDING"


// list nạp vnd
export const GETLISTDESPOSITVNDADMIN = "GETLISTDESPOSITVNDADMIN"

//list rút vnd 


export const GETLISTWIDTHDRAWVNDADMIN = "GETLISTWIDTHDRAWVNDADMIN"
//list rút vnd pending
export const GETLISTWIDTHDRAWVNDADMINPENDING = "GETLISTWIDTHDRAWVNDADMINPENDING"

//list rut coin

export const GETLISTWIDTHDRAWCOIN = "GETLISTWIDTHDRAWCOIN"

export const GETLISTWIDTHDRAWCOINPENDING = "GETLISTWIDTHDRAWCOINPENDING"


// --value pupopbuy
export const VALUEPUPOPBUY = "VALUEPUPOPBUY"
export const VALUEPUPOPSELL = "VALUEPUPOPSELL"



//kyc admin getKycUserPendding
export const GETKYCUSERPENDDING = "GETKYCUSERPENDDING"

//getalluser
export const GETALLUSER = "GETALLUSER"

//search user
export const SEARCHUSER = "SEARCHUSER"

//getListPackage staking

export const LISTPACKAGE = "LISTPACKAGE"

export const DATAKYC = "DATAKYC"
// recapcha


export const TOKENRE = "TOKENRE"

export const VALUE2FA = "VALUE2FA"

export const HANDLELOADDING = "HANDLELOADDING"

