import { Box } from '@mui/material'
import { Button, Form, Input } from 'antd'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ValueListting } from 'redux/reducer/listtingReducer'








export default function Info({setActiveStep,activeStep}) {
    const dispatch = useDispatch()
    
    const onFinish = (value) => {
        dispatch(ValueListting(value))
        setActiveStep(activeStep+1)
    }


    return (
        <>
           <Form autoComplete='off' layout='vertical'  style={{ flex:'2', padding:'30px'}} onFinish={onFinish }>
            <h3 style={{color: '#235161', fontWeight:'700'}}>Information Form</h3>
            <Form.Item name="fullname" label="Full Name"   rules={[{required:true}]} >
                    <Input  />
                </Form.Item>
                <Form.Item name="phone" label="Phone" rules={[{required:true}]}  >
                    <Input />
                </Form.Item>
                <Form.Item name="email" label="Email" rules={[{required:true}]}  >
                    <Input />
                </Form.Item>
                <Form.Item name="address" label="Address" rules={[{required:true}]}  >
                    <Input />
                </Form.Item>
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={() => setActiveStep(activeStep-1)}
              style={{   width: "100px"}}
            >
              Back
            </Button>
            <Box sx={{ flex: "1 1 auto" }} />
          
            <Button htmlType='submit' style={{   width: "100px",background: "#4bbd99", color: "white"}}>
                Next
            </Button>
          </Box>
          </Form>
</>
       
    )
}
