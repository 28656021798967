import { Spin } from 'antd'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslate } from '../../core/translate'
import { port } from '../../port'
import { createGetnotification } from '../../redux/action/userAction'

export default function Notification() {
    let { t } = useTranslate()
    const dispatch = useDispatch()
    let { getnotification } = useSelector(state => state.user)
    let [loadding, setLoadding] = useState(false)
    let token = JSON.parse(localStorage.getItem('user'))

    let [page, setPage] = useState(1)
    let [limit, setLimit] = useState(5)
    useEffect(() => {

        dispatch(createGetnotification(token?.axiologin?.token, limit, page, setLoadding))
    }, [])
    useEffect(() => {

        if (limit <= getnotification?.total) {

            dispatch(createGetnotification(token?.axiologin?.token, limit, page, setLoadding))
        }

    }, [limit])
    function handleScroll(e) {
        const { scrollTop, clientHeight, scrollHeight } = e.currentTarget
        if (scrollHeight - scrollTop === clientHeight) {
            setLimit(limit = limit + 1)
        }
    }

    return (

        <div className="notification" style={{ backgroundColor: '#f0f2f5', padding: '20px' }}>

            <>

                <div className="notification-container_main" onScroll={handleScroll}>
                    <div className="title">
                        <span>{t("Notify")} </span>
                        {/* <i className="fas fa-ellipsis-h"></i> */}
                    </div>
                    <div className="view-all">



                    </div>

                    <div className="contentNotification">
                        {getnotification?.total == 0 ? <div style={{ textAlign: 'center', padding: '20px' }}>{t("There are no announcements yet")}</div> :
                            getnotification?.array?.map((e, i) => {
                                let day = new Date()
                                let lastedNotifi = (e?.created_at * 1000)
                                let dateNow = day.getTime() - lastedNotifi

                                let ngay = Math.floor(dateNow / 1000 / 60 / 60 / 24)
                                let gio = Math.floor(dateNow / 1000 / 60 / 60 - ngay * 24)
                                let phut = Math.floor(dateNow / 1000 / 60 - ngay * 24 * 60 - gio * 60)
                                let giay = Math.floor(dateNow / 1000 - ngay * 24 * 60 * 60 - gio * 60 * 60 - phut * 60)
                                let validateGiay = 1000 * 60
                                let validatePhut = validateGiay * 60
                                let validateGio = validatePhut * 24
                                let validateNgay = validateGio * 30
                                let str;
                                if (dateNow < validateGiay) {
                                    str = `${giay} ${t("seconds prior to")}`
                                } else if (dateNow < validatePhut) {
                                    str = `${phut} ${t("minutes prior to")}`
                                } else if (dateNow < validateGio) {
                                    str = `${gio} ${t("hours ago")}`
                                } else if (dateNow < validateNgay) {
                                    str = `${ngay} ${t("yesterday")}`
                                }
                                return (
                                    <>

                                        <div className="notification-item" style={{ marginBottom: '10px' }}>
                                            <div className="avatar"><img src={`${port}img/logoswap.png`} alt="" /></div>
                                            <div className="noti">
                                                <div>[{e?.title}]</div>
                                                <div>{e?.detail}</div>
                                                <p>{str}</p>
                                            </div>
                                        </div>


                                    </>


                                )
                            })}
                        {loadding ? <div className="example">
                            <Spin />
                        </div> : ""}

                    </div>

                </div>




            </>

        </div>

    )
}
