import React, { useState } from 'react';
import { useTranslate } from 'core/translate';
import { useDispatch, useSelector } from 'react-redux';
import { truncate } from 'core/hook/truncate';
import CopyToClipboard from 'react-copy-to-clipboard';
import CreateApi from "../../../api/CreateApi"
import { createCheckTransactionDepositVnd } from 'redux/action/userAction';
import User from 'api/User';
import { port } from 'port';
import { Spin } from 'antd';

function Check({ setCheckImg }) {
    const { t } = useTranslate()
    const token = JSON.parse(localStorage.getItem('user'))
    const { checktransactiondepositvnd, getfee } = useSelector(state => state.user)
    const dispatch = useDispatch()

    let [active, setActive] = useState(false)
    let [valueCopy, setValueCopy] = useState('')
    let [loadding, setLoadding] = useState(false)


    function handleActiveCopy(i) {
        setActive(i)
        setTimeout(() => {
            setActive(false)
        }, 1500)
    }

    async function verifyTransactionDepositVnd() {
        setCheckImg(true)
        try {
            if (checktransactiondepositvnd.data.data.id) {
                let idTransaction = checktransactiondepositvnd?.data?.data?.id
                let res = await CreateApi.CreateApiS('api/user/verifyTransactionDepositVnd', { idTransaction }, true)
                if (res) {
                    dispatch(createCheckTransactionDepositVnd(token?.axiologin?.token, setLoadding))
                }
            }
        } catch (error) {
        }
    }

    async function cancelTransactionDepositVnd() {
        setLoadding(true)
        try {
            if (checktransactiondepositvnd.data.data.id) {
                let idTransaction = checktransactiondepositvnd?.data?.data?.id
                let res = await User.createApiToken(`${port}api/user/cancelTransactionDepositVnd`, token?.axiologin?.token, { idTransaction })
                if (res) {
                    setLoadding(false)
                    window.location.reload()
                }
            }
        } catch (error) {


        }
    }
    return (
        <>
            <div className='depositvnd-title'>
                <p>{t('Recharge information')}</p>
            </div>
            <div className='depositvnd-input checkvnd-content'>
                <p>{t('Deposit amount')}</p>
                <span>{truncate(checktransactiondepositvnd?.data?.data?.amount + getfee[0]?.raito)} VND</span>
            </div>
            <div className='depositvnd-input checkvnd-content'>
                <p>{t("Transfer Contents")}</p>
                <div className='checkvnd-content-item'>
                    <span>{token?.axiologin?.username.toUpperCase()} {checktransactiondepositvnd?.data?.data?.id}</span>
                    <CopyToClipboard text={`${token?.axiologin?.username.toUpperCase()} ${checktransactiondepositvnd?.data?.data?.id}`}
                        onCopy={() => setValueCopy({ copied: true })}>
                        <a onClick={() => handleActiveCopy(2)} >
                            {active == 2 ? "" : t("Copy")}
                            {active == 2 ? <img src="/img/Copy.png" alt="" style={{ width: '24px' }} /> : ""}
                        </a>
                    </CopyToClipboard>
                </div>
            </div>
            <div className='depositvnd-input checkvnd-content'>
                <p>{t("Card account:")}</p>
                <div className='checkvnd-content-item'>
                    <span>{checktransactiondepositvnd?.data?.data?.owner_banking_admin}</span>
                    <CopyToClipboard text={` ${checktransactiondepositvnd?.data?.data?.owner_banking_admin}`}
                        onCopy={() => setValueCopy({ copied: true })}>
                        <a onClick={() => handleActiveCopy(3)} >
                            {active == 3 ? "" : t("Copy")}
                            {active == 3 ? <img src="/img/Copy.png" alt="" style={{ width: '24px' }} /> : ""}
                        </a>
                    </CopyToClipboard>
                </div>
            </div>
            <div className='depositvnd-input checkvnd-content'>
                <p>{t("Account number:")}</p>
                <div className='checkvnd-content-item'>
                    <span>{checktransactiondepositvnd?.data?.data?.number_banking_admin}</span>
                    <CopyToClipboard text={` ${checktransactiondepositvnd?.data?.data?.number_banking_admin}`}
                        onCopy={() => setValueCopy({ copied: true })}>
                        <a onClick={() => handleActiveCopy(4)} >
                            {active == 4 ? "" : t("Copy")}
                            {active == 4 ? <img src="/img/Copy.png" alt="" style={{ width: '24px' }} /> : ""}
                        </a>
                    </CopyToClipboard>
                </div>
            </div>
            <div className='depositvnd-input checkvnd-content'>
                <p>{t("Bank name:")}</p>
                <span >{checktransactiondepositvnd?.data?.data?.name_banking_admin}</span>
            </div>

            <div className='depositvnd-notify'>
                <img src="/img/!.png" alt="" />
                <p style={{ color: '#757575' }}>
                    {t("SwapToBe receives thousands of deposit orders every day. You must send the correct amount as instructed, the correct transfer content as instructed so that we can credit you. Any mistake can lead to loss of money. SwapToBe will conduct additional verification if you transfer money from a bank account that is not your own. SwapToBe will proceed to refund unverified funds after 2 months After you submit payment, please confirm deposit and update payment proof so we can process.")}
                </p>
            </div>
            <div >
            </div>
            <div className="depositvnd-submit checkvnd-submit">
                {loadding ? <div className="example" style={{ width: '100%' }} >
                    <Spin />
                </div> :
                    <>
                        <button onClick={verifyTransactionDepositVnd}>{t("I sent money")}</button>
                        <button onClick={cancelTransactionDepositVnd}>{t("Cancel Command")}</button>
                    </>}
            </div>
        </>
    );
}

export default Check;