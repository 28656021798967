export const objMatrix = {
 
    "0": '01a34gefe7bdx.23a6hbjggfh3',
    "1": '1e8chcdcd6859x.2ga026bdg8he',
    "2": 'h9c23bci1f7ax.oigaddjaef39',
    "3": 'f5bjdadj6394x.1e8chcdcdcd6859',
    "4": 'oigaddjaef39x.2ga026bgd7he',
    "5": '79icj81e23d2x.bdhojhd7h0nbh',
    "6": 'j6h4aef93103x.j6h4aef0310132',
    "7": '5d87985a3ehhx.f5bjaddj5304',
    "8": 'j6h4ade93033x.j6h4aef930121',
    "9": '89sjiop7272ox.lkmnhiof02131',
    "10": '4212huwopfowx.72wweioqq3223',
    "11": 'p0wafwsw211wx.w1sd24rsdf21sd',
    "12": 'w1wersf211wex.pow21sff23s1s',
    "13": 'e2e21sfrt121x.rrt21fd23s241s',
    "14": 't24cy35bsaafex.y23cwtgytyyds',
    "15": 'y3osaplls2zxsx.y8soskq3s2fxa',
    "16": 'plkww345ssawwx.bhssq41swfaxa',
    "17": 'u4wxaxgrteeesx.iop02smdst22x',
    "18": '0f9wqrt2xswwex.f2wiolwqat3xe',
    "19": 'e2e21sfsssw21x.iop02smdst22x',
    "20": '0f9wqrt2xswwex.pow21sff23s1s',
    "21": '89sjiop7272ox.f2wiolwqat3xe',
    "22": 'e2e21sfsssw21x.iop02smdst22x',
    "23": 'h9c23bci1f7ax.iop02smdst22x',
    "24": '89sjiop7272ox.w1sd24rsdf21sd',
    "25": '79icj81e23d2x.bdhojhd7h0nbh',
    "26": '72wweioqq3223x.f5bjdadj6394g',
    "27": '0f9wqrt2xswwex.bhssq41swfaxa',
    "28": '4212huwopfowx.1e8chcdcdcd6859',
    "29": 'j6h4ade93033x.pow21sff23s1s',
    "30": 't24cy35bsaafex.bhssq41swfaxa',
    "31": '602sjiop42145x.oigaddjaef39',
    "32": 'j6h4ade93033x.y8soskq3s2fxa',
    "33": '79icj81e23d2x.rrt21fd23s241s',
    "34": 't24cy35bsaafex.y8soskq3s2fxa',
    "35": '79icj81e23d2x.oigaddjaef39',
    "36": 'j6h4ade93033x.bhssq41swfaxa',
    "37": '602sjiop42145x.j6h4aef930121',
    "38": 't24cy35bsaafex.bdhojhd7h0nbh',
    "39": 'plkww345ssawwx.oigaddjaef39',
    "40": '89sjiop7272ox.oigaddjaef39',
    "41": 'j6h4ade93033x.2ga026bgd7he',
    "42": '602sjiop42145x.pthfws77890',
    "43": 'f5bjdadj6394x.rrt21fd23s241s',
    "44": '79icj81e23d2x.bhssq41swfaxa',
    "45": '602sjiop42145x.oigaddjaef39',
    "46": 'f5bjdadj6394x.a291owpst221sx',
    "47": '01a34gefe7bdx.oigaddjaef39',
    "48": '602sjiop42145x.pthfws77890',
    "49": '1e8chcdcd6859x.yusio2120322',
    "50": '01a34gefe7bdx.qrssqrt1s22',
    "51": 'j6h4ade93033x.f5bjaddj5304',
    "52": 'h9c23bci1f7ax.pthfws77890',
    "53": 'oigaddjaef39x.1e8chcdcdcd6859',
    "54": 'trsw2s2qsfebnhx.yusio2120322',
    "55": '5d87985a3ehhx.lkmnhiof02131',
    "56": '602sjiop42145x.j6h4aef0310132',
    "57": '79icj81e23d2x.bdhojhd7h0nbh',
    "58": '1e8chcdcd6859x.2ga026bgd7he',
    "59": 'f5bjdadj6394x.23a6hbjggfh3',
    "60": '01a34gefe7bdx.oigaddjaef39',

}

export const  obj = {
    "01a34gefe7bdx.": '23a6hbjggfh3',
    "1e8chcdcd6859x.": '2ga026bdg8he',
    "h9c23bci1f7ax.": 'oigaddjaef39',
    "f5bjdadj6394x.": '1e8chcdcdcd6859',
    "oigaddjaef39x.": '2ga026bgd7he',
    "79icj81e23d2x.": 'bdhojhd7h0nbh',
    "j6h4aef93103x.": 'j6h4aef0310132',
    "5d87985a3ehhx.": 'f5bjaddj5304',
    "j6h4ade93033x.": 'j6h4aef930121',
    "89sjiop7272ox.": 'lkmnhiof02131',
    "602sjiop42145x.": 'yusio2120322',
    "oigaddjaef39x.": '2ga026bgd7he',
    "79icj81e23d2x.": 'bdhojhd7h0nbh',
    "j6h4aef93103x.": 'j6h4aef0310132',
    "5d87985a3ehhx.": 'f5bjaddj5304',
    "j6h4ade93033x.": 'j6h4aef930121',
    "89sjiop7272ox.": 'lkmnhiof02131',
    "602sjiop42145x.": 'yusio2120322',
    "a291owpst221sx.": 'ss2235xopwsx',
    "16wssiwopw4jjwx.": 'pthfws77890',
    "trsw2s2qsfebnhx.": 'qrssqrt1s22',
    "oigaddjaef39x.": '2ga026bgd7he',
    "79icj81e23d2x.": 'bdhojhd7h0nbh',
    "j6h4aef93103x.": 'j6h4aef0310132',
    "5d87985a3ehhx.": 'f5bjaddj5304',
    "j6h4ade93033x.": 'j6h4aef930121',
    "79icj81e23d2x.": 'bdhojhd7h0nbh',
    "j6h4aef93103x.": 'j6h4aef0310132',
    "5d87985a3ehhx.": 'f5bjaddj5304',
    "j6h4ade93033x.": 'j6h4aef930121',
    "89sjiop7272ox.": 'lkmnhiof02131',
    "602sjiop42145x.": 'yusio2120322',
    "a291owpst221sx.": 'ss2235xopwsx',
    "16wssiwopw4jjwx.": 'pthfws77890',

}