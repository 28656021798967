import { Spin } from 'antd'
import CreateApi from 'api/CreateApi'
import { SuccessMessage } from 'core/Message'
import { useTranslate } from 'core/translate'
import { ErrorMessage } from 'formik'
import { reaload } from 'func'

import React from 'react'

import { useState } from 'react'

import { useDispatch } from 'react-redux'


export default function Login12Character() {
    let { t } = useTranslate()
    let [loadding, setLoadding] = useState(false)
    const dispatch = useDispatch()
    let [array, setArray] = useState([])



    function handleChange(e) {
        let text = e.target.value
        setArray(text)
    }



    const handleSubmit = async () => {
        setLoadding(true)
        try {

            let data = await CreateApi.CreateApiSS("api/characters/login12Characters", { characters: array?.split(" ") })
            if (data) {
                // history.push('/p2p')
            }
            SuccessMessage('Thành công', t)
            reaload()

        } catch (error) {
            ErrorMessage("Invalid 12 characters", t)
        }
        setLoadding(false)
    }
    return (

        <div className="logincharacters" style={{ background: " url('/img/Background_signup.png') 0% 0% / 100% 100% no-repeat", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div className="container">
                <div className="row logincharacters_content" style={{ justifyContent: 'center' }}>
                    <div className="col-6 " style={{ background: '#FFFFFF', boxShadow: '0px 10px 28px rgba(0, 0, 0, 0.12)', borderRadius: '20px' }}>
                        <div><h5 style={{ color: '#4BBD99', fontWeight: 'bold' }}>{t('Enter 12 characters')}</h5></div>
                        <div className="SecretPassphrase_off">
                            <input onChange={handleChange} type="text" style={{ background: '#FFFFFF', border: "1px solid #4BBD99", boxSizing: 'border-box', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)', borderRadius: "7px" }} />
                        </div>
                        <div style={{ color: '#000', display: 'flex', alignItems: 'center' }}>
                            <div className="m-2"><i class="fas fa-exclamation-circle" style={{ color: '#4BBD99' }}></i></div>
                            <div style={{ color: '#1C5D67' }}>{t("There are 12 single words separated by space")}</div>
                        </div>
                        <div style={{ marginTop: '30px', display: 'flex', justifyContent: 'center' }}>
                            {loadding ? <div className="example" style={{ margin: '0' }}>
                                <Spin />
                            </div> :
                                <div onClick={() => handleSubmit()} className="SecretPassphrase_btn" style={{ color: "#fff", fontWeight: "initial", width: '100px', backgroundColor: '#4BBD99', fontSize: '16px', padding: '5px 10px', borderRadius: '4px', textAlign: 'center', cursor: 'pointer' }}>{t("Login")}</div>}

                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}
