import React, { useEffect, useState } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useLocation, useParams, Redirect } from 'react-router-dom'
import { ShowKyc } from 'redux/reducer/kycLevelReducer'
import { useTranslate } from '../../core/translate'
import { port, portSingup } from '../../port'
import { createSelladlistuser } from '../../redux/action/userAction'


export default function Profile() {
    let { t } = useTranslate()
    useEffect(() => {
        let tab = t("Profile")
        document.title = tab
        return () => {
            document.title = "Swap tobe"
        }
    }, [t]);
    const { profile, selladlistuser } = useSelector(state => state.user)
    let user = JSON.parse(localStorage.getItem('user'))

    let { slug } = useParams()
    let { slug2 } = useParams()

   




    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(createSelladlistuser(user?.axiologin?.token, 1, slug))
    }, [])
    let day = new Date()
    let lastedLogin = (profile?.data?.data?.lasted_login * 1000)
    let dateNow = day.getTime() - lastedLogin

    let ngay = Math.floor(dateNow / 1000 / 60 / 60 / 24)
    let gio = Math.floor(dateNow / 1000 / 60 / 60 - ngay * 24)
    let phut = Math.floor(dateNow / 1000 / 60 - ngay * 24 * 60 - gio * 60)
    let giay = Math.floor(dateNow / 1000 - ngay * 24 * 60 * 60 - gio * 60 * 60 - phut * 60)
    let validateGiay = 1000 * 60
    let validatePhut = validateGiay * 60
    let validateGio = validatePhut * 24
    let validateNgay = validateGio * 30
    let str;
    if (dateNow < validateGiay) {
        str = `${giay} ${t("seconds prior to")}`
    } else if (dateNow < validatePhut) {
        str = `${phut} ${t("minutes prior to")}`
    } else if (dateNow < validateGio) {
        str = `${gio} ${t("hours ago")}`
    } else if (dateNow < validateNgay) {
        str = `${ngay} ${t("yesterday")}`
    }

    let createdAc = (profile?.data?.data?.created_at * 1000)
    let dateNow2 = day.getTime() - createdAc
    let ngay2 = Math.floor(dateNow2 / 1000 / 60 / 60 / 24)
    let gio2 = Math.floor(dateNow2 / 1000 / 60 / 60 - ngay2 * 24)
    let phut2 = Math.floor(dateNow2 / 1000 / 60 - ngay2 * 24 * 60 - gio2 * 60)
    let giay2 = Math.floor(dateNow2 / 1000 - ngay2 * 24 * 60 * 60 - gio2 * 60 * 60 - phut2 * 60)
    let validateGiay2 = 1000 * 60
    let validatePhut2 = validateGiay2 * 60
    let validateGio2 = validatePhut2 * 24
    let validateNgay2 = validateGio2 * 30
    let str2;
    if (dateNow2 < validateGiay2) {
        str2 = `${giay2}${t("seconds prior to")}`
    } else if (dateNow2 < validatePhut2) {
        str2 = `${phut2} ${t("minutes prior to")}`
    } else if (dateNow2 < validateGio2) {
        str2 = `${gio2} ${t("hours ago")}`
    } else if (dateNow2 < validateNgay2) {
        str2 = `${ngay2} ${t("yesterday")}`
    }

    let unique_code = JSON.parse(localStorage.getItem('user'))

    let [valueCopy, setValueCopy] = useState('')
    let [active, setActive] = useState(false)
    function handleActiveCopy() {
        setActive(true)
        setTimeout(() => {
            setActive(false)
        }, 1500)
    }
    return (
        <>
            {slug2 == undefined || slug2 == "undefined" ? <Redirect to={`/profile/btc/${profile?.data?.data?.username}`} /> :


                <div id="scrollable-body" style={{ overflow: 'initial', color: '#000' }}>
                    <div className="css-1dbjc4n r-1jj8364 r-lchren r-l64lwh r-1qhn6m8 r-i023vh r-13qz1uu r-1g2xzmm" data-testid="alert-container">
                        <div className="css-1dbjc4n r-1g2xzmm" data-testid="flash-access-devices">
                            <div className="css-1dbjc4n r-eqz5dr r-912u2z r-1g2xzmm" />
                        </div>
                    </div>
                    <section className="page-heading remi-heading">
                        <div className="css-1dbjc4n r-adacv r-1yzf0co">
                            <div className="css-1dbjc4n r-1jj8364 r-lchren r-l64lwh r-1qhn6m8 r-i023vh r-13qz1uu r-1g2xzmm">
                                <div dir="auto" className="css-901oao r-jwli3a r-320f6p r-adyw6z r-vw2c0b r-oxtfae r-37tt59">
                                    {t("Profile")}
                                </div>
                            </div>
                        </div>
                    </section>
                    <div className="css-1dbjc4n r-11ict49" data-testid="main">
                        <div className="profile css-1dbjc4n r-1jj8364 r-lchren r-l64lwh r-1qhn6m8 r-i023vh r-13qz1uu r-1g2xzmm" data-testid="profile">
                            <br />
                            <div className="css-1dbjc4n r-18u37iz r-1w6e6rj r-6cv0rn r-1g2xzmm">
                                <div className="profile_left css-1dbjc4n r-1t2qqvi r-18kxxzh r-1q142lx r-r2y082 r-ymttw5 r-bnwqim r-1g2xzmm">
                                    <div className="css-1dbjc4n r-1g2xzmm">
                                        <div className="css-1dbjc4n r-eqz5dr r-v55p34 r-1g2xzmm">
                                            <div className="css-1dbjc4n r-xd6kpl r-1g2xzmm">
                                                <div className="card profile-details">
                                                    <div className="card-header" style={{ backgroundColor: 'rgb(235, 236, 240)' }}>
                                                        <span style={{ fontSize: '16px', fontStyle: 'Arial', fontWeight: '500' }}>{t("Summary of ")}{slug2}</span>
                                                    </div>
                                                    <table className="table">
                                                        <tbody>
                                                            <tr className="trading-volume">
                                                                <td>{t("Has traded")}</td>
                                                                <td>
                                                                    <span className="currency-amount"><span className="badge badge-warning btc">0.00000000 BTC</span> </span><span className="currency-amount"><span className="badge badge-warning eth">0.00000000 ETH</span> </span><span className="currency-amount"><span className="badge badge-warning usdt">0.00 USDT</span> </span><span className="currency-amount"><span className="badge badge-warning bch">0.00000000 BCH</span> </span><span className="currency-amount"><span className="badge badge-warning ltc">0.00000000 LTC</span> </span><span className="currency-amount"><span className="badge badge-warning xrp">0.000000 XRP</span>
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>{t("Number of successful trades")}</td>
                                                                <td><strong className="text-success">0</strong></td>
                                                            </tr>
                                                            <tr>
                                                                <td>{t("Number of different partners")}</td>
                                                                <td><strong className="text-success">0</strong></td>
                                                            </tr>
                                                            <tr>
                                                                <td>{t("Feedback score")}</td>
                                                                <td>
                                                                    <span><strong>-</strong></span>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>{t("Account created")}</td>
                                                                <td><strong>{str2}</strong></td>
                                                            </tr>
                                                            <tr>
                                                                <td>{t(" Last visit")}</td>
                                                                <td>
                                                                    <div className="css-1dbjc4n r-1awozwy r-18u37iz r-2eo2mk r-1g2xzmm">
                                                                        <div className="css-1dbjc4n r-1wbh5a2 r-1hvjb8t r-1g2xzmm">
                                                                            <div dir="auto" className="css-901oao r-homxoj r-320f6p r-7cikom r-vw2c0b r-1s2g6fe r-t9a87b">
                                                                                {str}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>{t("Identity and Residence Proof")}</td>
                                                                {profile?.data?.data?.verified == null ? <td><strong className="text-green">{t("Not submitted")}</strong> <NavLink to="kyc-basic" style={{ color: 'blue', marginLeft: '5px', fontWeight: 'bolder' }} > {t("Update")} </NavLink></td> : ''}
                                                                {profile?.data?.data?.verified == 1 ? <td><strong className="text-green" >{t("Verified")}</strong></td> : ''}
                                                                {profile?.data?.data?.verified == 2 ? <td><strong className="text-green">{t("Wait for Admin to approve")}</strong></td> : ''}
                                                                {profile?.data?.data?.verified == 3 ? <td><strong className="text-green" style={{ color: 'red' }}>{t('Was admin rejected')}</strong> <NavLink to="kyc-basic" style={{ color: 'blue', marginLeft: '5px', fontWeight: 'bolder' }} > {t("Update again")} </NavLink></td> : ''}
                                                            </tr>
                                                            {/* <tr className="peer-certification">
                                                        <td>{t("Peer certification")}</td>
                                                        <td>
                                                            <strong className="text-default">{t("Not certified yet")}</strong>
                                                        </td>
                                                    </tr> */}
                                                            <tr>
                                                                <td>{t("Kyc level 2")}</td>
                                                                <td className="bank-verification-status" style={{    cursor: 'pointer'}}>
                                                                    {profile?.data?.data?.verified == 1 && profile?.data?.data?.kyc_confirm != 2 &&  profile?.data?.data?.kyc2_status != 2 && <strong className="text-warning empty" onClick={() => dispatch(ShowKyc(true))}>{t("Update")}</strong>  }
                                                                    {profile?.data?.data?.kyc_confirm == 2 && <strong className="text-green ">{t("Verified")}</strong> }
                                                                {profile?.data?.data?.verified == null && <strong className="text-warning empty">{t("Not submitted")}</strong> }
                                                                {profile?.data?.data?.kyc2_status == 2 && <strong className="text-warning empty">{t("Pending")}</strong> }

                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>{t("phone number")}</td>
                                                                <td>
                                                                    <strong className="text-green">{t("Not submitted")}</strong>

                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Facebook</td>
                                                                <td><strong>{t("Not submitted")}</strong></td>
                                                            </tr>
                                                            <tr>
                                                                <td>Twitter</td>
                                                                <td><strong>{t("Not submitted")}</strong></td>
                                                            </tr>
                                                            {/* <tr>
                                                        <td className="text-center" colSpan={2}>
                                                            <em className="trust-badge badge badge-green">{t("new buyer")}</em><em className="speed-badge badge badge-green">người bán chậm</em>
                                                        </td>
                                                    </tr> */}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="profile_right css-1dbjc4n r-1t2qqvi r-18kxxzh r-1q142lx r-r2y082 r-ymttw5 r-bnwqim r-1g2xzmm">
                                    <div className="feedback-contents text-center">
                                        <div className="feedback-title title-accent">
                                            <h3><span>{t("Feedback about ")}{slug2}</span></h3>
                                        </div>
                                        <div className="css-1dbjc4n r-1g2xzmm">
                                            <div className="css-1dbjc4n r-eqz5dr r-v55p34 r-1g2xzmm">
                                                <div className="css-1dbjc4n r-xd6kpl r-1g2xzmm">
                                                    <div className="empty-feedbacks">
                                                        <i>{t("No feedbacks")}</i>
                                                        <p />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div className="css-1dbjc4n r-1wbh5a2 r-xd6kpl r-i023vh r-1g2xzmm" style={{ marginTop: '20px' }}>
                                    <div className="css-1dbjc4n" data-testid="referral-link">
                                        <div data-focusable="true" tabIndex={0} className="css-1dbjc4n r-1loqt21 r-1otgn73 r-1i6wzkk r-lrvibr" data-testid="https://SwapToBe.com/btc/vn/offers/381715-mua-bitcoin-gi%C3%A1-t%E1%BB%91t-v%E1%BB%9Bi-VND-t%E1%BA%A1i-Vi%E1%BB%87t-Nam-qua-fiat-wallet-t%E1%BB%AB-ryanhiga25?ref=3126657" style={{ transitionDuration: '0s' }}>
                                            <div className="css-1dbjc4n r-z2wwpe r-1udh08x">
                                                <div role="button" aria-busy="false" data-focusable="true" tabIndex={0} className="css-18t94o4 css-1dbjc4n r-1loqt21 r-1otgn73 r-1i6wzkk r-lrvibr" style={{ transitionDuration: '0s' }}>
                                                    <div className="css-1dbjc4n r-1awozwy r-k200y r-z2wwpe r-18u37iz r-1777fci r-5njf8e" style={{ backgroundColor: 'rgb(94, 88, 98)', borderWidth: 0, minHeight: 40, padding: '8px 16.1px' }}>
                                                        <div className="css-1dbjc4n" data-testid="icon-clone" style={{ height: 24, width: 24, marginRight: '10px' }}>
                                                            <svg width={24} height={24} viewBox="0 0 32 32" fill="#FFFFFF">
                                                                <path d="M29.728 29.152V9.728q0-.256-.192-.416t-.384-.16H9.728q-.256 0-.416.16t-.16.416v19.424q0 .224.16.384t.416.192h19.424q.224 0 .384-.192t.192-.384zM32 9.728v19.424q0 1.184-.832 2.016T29.152 32H9.728q-1.184 0-2.016-.832t-.864-2.016V9.728q0-1.184.864-2.048t2.016-.832h19.424q1.184 0 2.016.832T32 9.728zm-6.848-6.88v2.88h-2.304v-2.88q0-.224-.16-.384t-.416-.192H2.848q-.224 0-.384.192t-.192.384v19.424q0 .256.192.416t.384.16h2.88v2.304h-2.88q-1.152 0-2.016-.864T0 22.272V2.848Q0 1.664.832.832T2.848 0h19.424q1.184 0 2.048.832t.832 2.016z" />
                                                            </svg>
                                                        </div>
                                                        <CopyToClipboard text={`${portSingup}SignUp/${unique_code?.axiologin?.unique_code}`}
                                                            onCopy={() => setValueCopy({ copied: true })}>
                                                            <button onClick={handleActiveCopy} dir="auto" className="css-901oao r-jwli3a r-320f6p r-ubezar r-oxtfae r-135wba7 r-1jkjb r-rjfia" style={{ backgroundColor: "initial" }}>
                                                                {t("Copy referrer")}
                                                                {active ? <img src="/img/Copy.png" alt="" style={{ width: '24px', marginLeft: '10px' }} /> : ""}

                                                            </button>
                                                        </CopyToClipboard>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="css-1dbjc4n r-1wbh5a2 r-xd6kpl r-i023vh r-1g2xzmm" style={{ marginTop: '20px' }}>
                                    <div className="css-1dbjc4n" data-testid="referral-link">
                                        <div data-focusable="true" tabIndex={0} className="css-1dbjc4n r-1loqt21 r-1otgn73 r-1i6wzkk r-lrvibr" data-testid="https://SwapToBe.com/btc/vn/offers/381715-mua-bitcoin-gi%C3%A1-t%E1%BB%91t-v%E1%BB%9Bi-VND-t%E1%BA%A1i-Vi%E1%BB%87t-Nam-qua-fiat-wallet-t%E1%BB%AB-ryanhiga25?ref=3126657" style={{ transitionDuration: '0s' }}>
                                            <div className="css-1dbjc4n r-z2wwpe r-1udh08x">
                                                <div role="button" aria-busy="false" data-focusable="true" tabIndex={0} className="css-18t94o4 css-1dbjc4n r-1loqt21 r-1otgn73 r-1i6wzkk r-lrvibr" style={{ transitionDuration: '0s' }}>
                                                    <div className="css-1dbjc4n r-1awozwy r-k200y r-z2wwpe r-18u37iz r-1777fci r-5njf8e" style={{ backgroundColor: 'rgb(94, 88, 98)', borderWidth: 0, minHeight: 40, padding: '8px 16.1px' }}>
                                                        <div className="css-1dbjc4n" data-testid="icon-clone" style={{ height: 24, width: 24, marginRight: '10px' }}>
                                                            <svg width={24} height={24} viewBox="0 0 32 32" fill="#FFFFFF">
                                                                <path d="M29.728 29.152V9.728q0-.256-.192-.416t-.384-.16H9.728q-.256 0-.416.16t-.16.416v19.424q0 .224.16.384t.416.192h19.424q.224 0 .384-.192t.192-.384zM32 9.728v19.424q0 1.184-.832 2.016T29.152 32H9.728q-1.184 0-2.016-.832t-.864-2.016V9.728q0-1.184.864-2.048t2.016-.832h19.424q1.184 0 2.016.832T32 9.728zm-6.848-6.88v2.88h-2.304v-2.88q0-.224-.16-.384t-.416-.192H2.848q-.224 0-.384.192t-.192.384v19.424q0 .256.192.416t.384.16h2.88v2.304h-2.88q-1.152 0-2.016-.864T0 22.272V2.848Q0 1.664.832.832T2.848 0h19.424q1.184 0 2.048.832t.832 2.016z" />
                                                            </svg>
                                                        </div>
                                                        <CopyToClipboard text={`${unique_code?.axiologin?.unique_code}`}
                                                            onCopy={() => setValueCopy({ copied: true })}>
                                                            <button onClick={handleActiveCopy} dir="auto" className="css-901oao r-jwli3a r-320f6p r-ubezar r-oxtfae r-135wba7 r-1jkjb r-rjfia" style={{ backgroundColor: "initial" }}>
                                                                {t("Copy link referral code")}
                                                                {active ? <img src="/img/Copy.png" alt="" style={{ width: '24px', marginLeft: '10px' }} /> : ""}

                                                            </button>
                                                        </CopyToClipboard>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                            {/* <NavLink to={`/SignUp/${unique_code?.axiologin?.unique_code}`} store="[object Object]">
                        <div className="css-1dbjc4n r-z2wwpe r-1udh08x">
                            <div role="button" aria-busy="false" data-focusable="true" tabIndex={0} className="css-18t94o4 css-1dbjc4n r-1loqt21 r-1otgn73 r-1i6wzkk r-lrvibr" style={{ transitionDuration: '0s', margin: '20px 0' }}>
                                <div className="css-1dbjc4n r-1awozwy r-k200y r-z2wwpe r-18u37iz r-1777fci r-5njf8e" style={{ backgroundColor: '#087972', borderWidth: '0px', minHeight: '40px', padding: '8px 16.1px' }}>
                                    <div dir="auto" className="css-901oao r-jwli3a r-320f6p r-ubezar r-oxtfae r-135wba7" style={{ paddingTop: '0px', paddingBottom: '0px' }}>{t("Login to get referral link")}</div>
                                </div>
                            </div>
                        </div>
                    </NavLink> */}
                            <div className="profile-offers my-16" style={{ marginTop: '20px' }}>
                                <div className="css-1dbjc4n r-eqz5dr r-912u2z r-1g2xzmm" data-testid="sell-offers offer-list-section">
                                    <div className="css-1dbjc4n r-1l7z4oj r-1g2xzmm">
                                        <div className="css-1dbjc4n r-1awozwy r-18u37iz">
                                            <div className="css-1dbjc4n" data-testid="icon-flag-squared" style={{ height: 24, width: 24 }}>
                                                <svg id="flag-squared_svg__Layer_1" data-name="Layer 1" viewBox="0 0 24 24" fill="#5E5862" width={24} height={24}>
                                                    <defs>
                                                        <style dangerouslySetInnerHTML={{ __html: "\n                      .flag-squared_svg__cls-1 {\n                        fill: #5e5862;\n                      }\n                    " }} />
                                                    </defs>
                                                    <g id="flag-squared_svg__Page-1">
                                                        <g id="flag-squared_svg__Home-v5-amount-selected-login-Copy">
                                                            <g id="flag-squared_svg__flag-copy">
                                                                <g id="flag-squared_svg__Shape">
                                                                    <path className="flag-squared_svg__cls-1" d="M16.14 9L19 4.36a.34.34 0 0 0 0-.31.29.29 0 0 0-.25-.15H5.58v-.6a.29.29 0 1 0-.58 0v17.4a.29.29 0 1 0 .58 0v-6.6h13.13A.29.29 0 0 0 19 14a.34.34 0 0 0 0-.31zM5.58 13.5v-9h12.6l-2.64 4.34a.36.36 0 0 0 0 .32l2.64 4.34z" />
                                                                    <path className="flag-squared_svg__cls-1" d="M5.29 21.5a.8.8 0 0 1-.79-.8V3.3a.79.79 0 1 1 1.58 0v.1h12.63a.78.78 0 0 1 .69.42.79.79 0 0 1 0 .8L16.72 9l2.66 4.38a.79.79 0 0 1 0 .8.78.78 0 0 1-.69.42H6.08v6.1a.79.79 0 0 1-.79.8zm.79-8.5h11.21l-2.17-3.58a.79.79 0 0 1 0-.84L17.29 5H6.08z" />
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </svg>
                                            </div>
                                            <div className="css-1dbjc4n r-1wbh5a2 r-1777fci r-1jkjb" >
                                                <div dir="auto" className="css-901oao r-homxoj r-320f6p r-adyw6z r-oxtfae r-37tt59">
                                                    {t("Buy")}{t(` ${slug?.toUpperCase()} `)} {t("from")} {slug2}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="css-1dbjc4n r-1l7z4oj r-1g2xzmm">
                                        <div className="css-1dbjc4n r-1g2xzmm">
                                            <div className="css-1dbjc4n r-eqz5dr r-v55p34 r-1g2xzmm">
                                                <div className="css-1dbjc4n r-xd6kpl r-1g2xzmm">
                                                    <div className="css-1dbjc4n r-eqz5dr r-912u2z r-1g2xzmm">
                                                        <div className="css-1dbjc4n r-1l7z4oj r-1g2xzmm">
                                                            <div dir="auto" className="css-901oao r-homxoj r-320f6p r-7cikom r-36ujnk r-1s2g6fe r-t9a87b">
                                                                {t("No advertisements found")}
                                                            </div>
                                                        </div>
                                                        <div className="css-1dbjc4n r-1l7z4oj r-1g2xzmm">
                                                            <div className="css-1dbjc4n r-eqz5dr r-1ytwq0x r-1g2xzmm" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="css-1dbjc4n r-qklmqi r-r3u836 r-1phboty r-pw2am6" />
                                <div className="css-1dbjc4n r-eqz5dr r-912u2z r-1g2xzmm" data-testid="buy-offers offer-list-section">
                                    <div className="css-1dbjc4n r-1l7z4oj r-1g2xzmm">
                                        <div className="css-1dbjc4n r-1awozwy r-18u37iz">
                                            <div className="css-1dbjc4n" data-testid="icon-flag-squared" style={{ height: 24, width: 24 }}>
                                                <svg id="flag-squared_svg__Layer_1" data-name="Layer 1" viewBox="0 0 24 24" fill="#5E5862" width={24} height={24}>
                                                    <defs>
                                                        <style dangerouslySetInnerHTML={{ __html: "\n                      .flag-squared_svg__cls-1 {\n                        fill: #5e5862;\n                      }\n                    " }} />
                                                    </defs>
                                                    <g id="flag-squared_svg__Page-1">
                                                        <g id="flag-squared_svg__Home-v5-amount-selected-login-Copy">
                                                            <g id="flag-squared_svg__flag-copy">
                                                                <g id="flag-squared_svg__Shape">
                                                                    <path className="flag-squared_svg__cls-1" d="M16.14 9L19 4.36a.34.34 0 0 0 0-.31.29.29 0 0 0-.25-.15H5.58v-.6a.29.29 0 1 0-.58 0v17.4a.29.29 0 1 0 .58 0v-6.6h13.13A.29.29 0 0 0 19 14a.34.34 0 0 0 0-.31zM5.58 13.5v-9h12.6l-2.64 4.34a.36.36 0 0 0 0 .32l2.64 4.34z" />
                                                                    <path className="flag-squared_svg__cls-1" d="M5.29 21.5a.8.8 0 0 1-.79-.8V3.3a.79.79 0 1 1 1.58 0v.1h12.63a.78.78 0 0 1 .69.42.79.79 0 0 1 0 .8L16.72 9l2.66 4.38a.79.79 0 0 1 0 .8.78.78 0 0 1-.69.42H6.08v6.1a.79.79 0 0 1-.79.8zm.79-8.5h11.21l-2.17-3.58a.79.79 0 0 1 0-.84L17.29 5H6.08z" />
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </svg>
                                            </div>
                                            <div className="css-1dbjc4n r-1wbh5a2 r-1777fci r-1jkjb">
                                                <div dir="auto" className="css-901oao r-homxoj r-320f6p r-adyw6z r-oxtfae r-37tt59">
                                                    {t("Sell")} {t(`${slug?.toUpperCase()}`)} {t('to')} {slug2}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="css-1dbjc4n r-1l7z4oj r-1g2xzmm">
                                        <div className="css-1dbjc4n r-1g2xzmm">
                                            <div className="css-1dbjc4n r-eqz5dr r-v55p34 r-1g2xzmm">
                                                <div className="css-1dbjc4n r-xd6kpl r-1g2xzmm">
                                                    <div className="css-1dbjc4n r-eqz5dr r-912u2z r-1g2xzmm">
                                                        <div className="css-1dbjc4n r-1l7z4oj r-1g2xzmm">
                                                            <div dir="auto" className="css-901oao r-homxoj r-320f6p r-7cikom r-36ujnk r-1s2g6fe r-t9a87b">
                                                                {t("No advertisements found")}
                                                            </div>
                                                        </div>
                                                        <div className="css-1dbjc4n r-1l7z4oj r-1g2xzmm">
                                                            <div className="css-1dbjc4n r-eqz5dr r-1ytwq0x r-1g2xzmm" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>


    )
}
