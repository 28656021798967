import { ErrorMessage, Field } from 'formik'
import React from 'react'

export default function Input(props) {
    const { lable, name, ...rest } = props

    return (

        <div className='form-group'>
            <div className="custom_input" style={{ color: '#000' }}>
                <label htmlFor={name}>{lable}</label>
                <Field id={name} name={name} {...rest} />
                <ErrorMessage name={name}>
                    {(mes) => (
                        <p className="text-danger" style={{ color: 'red' }}>* {mes}</p>
                    )}
                </ErrorMessage>
            </div>
        </div>

    )
}
