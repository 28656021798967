import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useTranslate } from '../core/translate'
import { createNews } from '../redux/action/orderAction'



export default function Footer() {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(createNews())
    }, [])


    return (
        <>
            <footer id="footer" style={{ background: '#E5E5E5' }}>
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-12 col-lg-3">
                            <div className="row align-items-center">
                                <div className="col-12 col-md-6 col-lg-12" style={{ textAlign: 'center', marginBottom: '20px' }}>
                                    <a style={{ fontWeight: '700', fontSize: '22px', lineHeight: '40px', textAlign: 'center', marginBottom: '20px', color: '#757575' }}>Community</a>
                                    <div className="footerlinks">

                                        {/* <a href="https://testflight.apple.com/join/ysHX5JJP" target="_blank" className="download d-xl-none">
                                            <img src="https://vndt.com/assets/images/home/footerdownload_applestore.png" alt="" />
                                        </a>
                                        <a href="https://testflight.apple.com/join/ysHX5JJP" target="_blank" className="download d-none d-xl-inline-block">
                                            <img src="https://vndt.com/assets/images/home/footerdownload_applestore.png" alt="" />
                                        </a>
                                        <a href="https://play.google.com/store/apps/details?id=com.companyname.swaptobe" target="_blank" className="download">
                                            <img src="https://vndt.com/assets/images/layout/footerdownload_chplay.png" alt="" />
                                        </a> */}
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 col-lg-12">
                                    <ul className="footerlinks">
                                        <li>
                                            <div className="dropdown">
                                                <a href="https://t.me/swaptobeglobal" className=" btn-link p-0" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <img src="/img/mxh.png" alt="" />
                                                </a>
                                                <ul className="dropdown-menu p-0">
                                                    <li>
                                                        <a className="dropdown-item" href="#"> Global Telegram Channel </a>
                                                    </li>
                                                    <li>
                                                        <a className="dropdown-item" href="#"> Global Community Telegram </a>
                                                    </li>
                                                    <li>
                                                        <a className="dropdown-item" href="#"> Vietnam Telegram Channel </a>
                                                    </li>
                                                    <li>
                                                        <a className="dropdown-item" href="#"> Vietnam Community Telegram </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                        <li>
                                            <a href="https://twitter.com/swap_tobe" target="_blank">
                                                <img src="/img/mxh2.png" alt="" />
                                            </a>
                                        </li>

                                        <li>
                                            <a href="https://www.youtube.com/channel/UC9jBj0BDr7vQq6WzsJGBBSw" target="_blank">
                                                <img src="/img/mxh3.png" alt="" />
                                            </a>
                                        </li>
                                    </ul>
                                    <ul className="footerlinks">
                                        <li>
                                            <a href="https://www.facebook.com/swaptobe" target="_blank">
                                                <img src="/img/mxh4.png" alt="" />
                                            </a>
                                        </li>
                                        <li> <a href="https://www.youtube.com/channel/UC9jBj0BDr7vQq6WzsJGBBSw" target="_blank">
                                            <img src="/img/mxh5.png" alt="" />
                                        </a>
                                        </li>
                                        <li>
                                            <a href="#" target="_blank">
                                                <img src="/img/mxh6.png" alt="" />
                                            </a>
                                        </li>
                                        {/* <li>
                                            <a href="https://t.me/swaptobeglobal" target="_blank">
                                                <i className="fab fa-telegram" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" target="_blank">
                                                <i className="fab fa-discord" />
                                            </a>
                                        </li> */}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-12 col-lg-9">
                            <div className="row">
                                <div className="col-6 col-md-3">
                                    <ul className="footerlist">
                                        <li className="thetitle">
                                            <a href="#"> Application </a>
                                        </li>
                                        <li>
                                            <a href="/swap"> Swap </a>
                                        </li>
                                        <li>
                                            <a href="#"> Tobe Earn</a>
                                        </li>
                                        <li>
                                            <a href="#"> ToBe Credit </a>
                                        </li>

                                    </ul>
                                </div>
                                <div className="col-6 col-md-3">
                                    <ul className="footerlist">
                                        <li className="thetitle"> Listing</li>
                                        <li>
                                            <a href="#"> Listed Assets </a>
                                        </li>
                                        <li>
                                            <a href="/ListingApplication">  Listing Application </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-6 col-md-3">
                                    <ul className="footerlist">
                                        <li className="thetitle"> Resources </li>
                                        <li><a href="/whitepaper.pdf">Whitepaper</a></li>
                                    </ul>
                                </div> <div className="col-6 col-md-3">
                                    <ul className="footerlist">
                                        <li className="thetitle"> About Us </li>
                                        <li>
                                            <a href="https://swaptobe.com/LegalPolicy" target="_blank"> About  </a>
                                        </li>
                                        <li>
                                            <a href="https://swaptobe.com/LegalPolicy" target="_blank"> Tems  </a>
                                        </li>
                                        <li>
                                            <a href="#" target="_blank"> Privacy  </a>
                                        </li>
                                        <li>
                                            <a href="/contact" target="_blank"> Contact us </a>
                                        </li>
                                        {/* {newsF()} */}

                                        {/* <li>
                                            <a href="#"> About Us </a>
                                        </li>
                                        <li>
                                            <a href="#"> Our Team </a>
                                        </li>
                                        <li>
                                            <a href="#"> Partners </a>
                                        </li>
                                        <li>
                                            <a href="#"> Security </a>
                                        </li>
                                        <li>
                                            <a href="#"> Contact Us </a>
                                        </li> */}
                                    </ul>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </footer>

        </>
    )
}
