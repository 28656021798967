import { port } from 'port'
import React, { useRef, useState } from 'react'
import { useEffect } from 'react'

export default function Promotion() {


    return (
        <>
            <div>
                <div className="container" style={{ padding: '20px' }}>
                    <div style={{ textAlign: 'center' }}>
                        <div style={{ width: '100%', marginBottom: '40px', textAlign: 'center' }}>

                            <iframe width="100%" height="450px" style={{ maxWidth: ' 600px' }} src="https://www.youtube.com/embed/b8uoL1KoUzo?rel=0&autoplay=1:" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                        <div style={{ textAlign: 'center' }}>
                            <img src={`${port}imgs/promotion.png`} alt="" />
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
