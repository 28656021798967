import React, { useEffect } from 'react'
import reactDom from 'react-dom'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslate } from '../core/translate'
import { createAction } from '../redux/action/authAction'
import { POPUPBUY, USERADLIST } from '../redux/type'
import { Link, Redirect, useHistory, useParams } from 'react-router-dom'
import { createA } from '../redux/action/userAction'
import { useState } from 'react'


export default function PopupBuy({ slug }) {
    let { popupbuy } = useSelector(state => state.auth)
    let { axiologin } = useSelector(state => state.auth)
    let { valuepupopbuy } = useSelector(state => state.other)

    let { valuebuy, searchbuynow, indexbuy, typetotal } = useSelector(state => state.search)

    // let slug = localStorage.getItem('slug')


    const dispatch = useDispatch()
    function ClosePopup() {
        dispatch(createAction(POPUPBUY, false))
    }

    useEffect(() => {
        if (popupbuy) {
            document.body.style.overflow = 'hidden';
        }
        else {
            document.body.style.overflow = 'unset';

        }
    }, [popupbuy]);

    let token = JSON.parse(localStorage.getItem('user'))


    const { profile } = useSelector(state => state.user)

    const verified = profile?.data?.data?.verified

    function handlClick() {

        localStorage.removeItem('useradlist')
        dispatch(createA(USERADLIST, searchbuynow[indexbuy]))
        dispatch(createAction(POPUPBUY, false))
        // if (verified == null) {
        //     return history.push('/kyc-basic')
        // }
    }
    function truncate(value, precision) {
        var step = Math.pow(10, precision || 0);
        var temp = Math.trunc(step * value);

        var a = temp / step;
        return a.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    return reactDom.createPortal(
        <>


            <div className={`${popupbuy ? "active popupbuy css-1dbjc4n r-14lw9ot r-z2wwpe r-33ulu8" : 'popupbuy css-1dbjc4n r-14lw9ot r-z2wwpe r-33ulu8"'}`}  >
                <div className="greenBold css-1dbjc4n  r-kicko2 r-notknq r-13awgt0 r-18u37iz r-1wtj0ep r-puj83k r-i023vh r-1f1sjgu" style={{ padding: "20px 0 20px 25px" }}>
                    <div className="text-white css-901oao r-homxoj r-320f6p r-adyw6z r-oxtfae r-37tt59"> Xác nhận </div>
                    <div onClick={ClosePopup} className="css-1dbjc4n r-1loqt21" style={{ height: 24, width: 24 }}  >
                        <svg width={24} height={24} viewBox="0 0 15 32" fill="#ffff">
                            <path d="M14.464 20.992q.576.576.576 1.376t-.576 1.376q-.576.512-1.376.512t-1.376-.512L7.488 18.88l-4.224 4.864q-.576.512-1.376.512t-1.376-.512Q0 23.168 0 22.368t.512-1.376L4.928 16 .512 10.944Q0 10.368 0 9.568t.512-1.376q.576-.512 1.376-.512t1.376.512l4.224 4.864 4.224-4.864q.576-.512 1.376-.512t1.376.512q.576.576.576 1.376t-.576 1.376L10.048 16z" />
                        </svg>
                    </div>
                </div>
                <div className="css-1dbjc4n r-1mdbw0j r-1guathk r-1ygmrgt r-1g2xzmm" style={{ padding: '25px' }}>
                    <div className="css-901oao r-homxoj r-320f6p r-ubezar r-oxtfae r-135wba7" >
                        {typetotal == 0 ? `Mở giao dịch để mua ${valuebuy} ${slug?.toUpperCase()} từ ${searchbuynow[indexbuy]?.username} với tổng ${truncate(valuebuy * (searchbuynow[indexbuy]?.amount_exchange_usd * searchbuynow[indexbuy]?.amount_exchange_vnd + (searchbuynow[indexbuy]?.amount_exchange_usd * searchbuynow[indexbuy]?.amount_exchange_vnd) / 100 * searchbuynow[indexbuy]?.percent))} VND? ` : `Mở giao dịch để mua ${truncate(valuebuy)} VND từ ${searchbuynow[indexbuy]?.username} với tổng ${valuebuy / (searchbuynow[indexbuy]?.amount_exchange_usd * searchbuynow[indexbuy]?.amount_exchange_vnd + (searchbuynow[indexbuy]?.amount_exchange_usd * searchbuynow[indexbuy]?.amount_exchange_vnd) / 100 * searchbuynow[indexbuy]?.percent)}`}

                    </div>
                </div>
                <div className="css-1dbjc4n r-1guathk r-1h4fu65" style={{ padding: "25px" }}>
                    <div className="css-1dbjc4n r-18u37iz r-1w6e6rj r-6cv0rn r-1g2xzmm">
                        <div onClick={ClosePopup} className="css-1dbjc4n r-1iusvr4 r-16y2uox r-1q142lx r-dnmrzs r-ymttw5 r-bnwqim r-1g2xzmm" style={{ marginRight: '15px' }}>
                            <div className="css-1dbjc4n r-2eo2mk">
                                <div className="css-1dbjc4n r-z2wwpe r-1udh08x">
                                    <div className="css-18t94o4 css-1dbjc4n r-1loqt21 r-1otgn73 r-1i6wzkk r-lrvibr" style={{ transitionDuration: "0s" }}>
                                        <div className="css-1dbjc4n r-1awozwy r-14lw9ot r-adb5am r-z2wwpe r-rs99b7 r-18u37iz r-1777fci" style={{ minHeight: 40, padding: "7px 15.1px", borderRadius: "5px" }}>
                                            <div className="css-901oao r-z4f626 r-320f6p r-ubezar r-oxtfae r-135wba7" style={{ paddingTop: 0, paddingBottom: 0 }}>
                                                Không
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <Link to={`/buy/${slug}/${valuepupopbuy}`} className="css-1dbjc4n r-1iusvr4 r-16y2uox r-1q142lx r-dnmrzs r-ymttw5 r-bnwqim r-1g2xzmm" onClick={handlClick}>
                            <div className="css-1dbjc4n r-cnkkqs">
                                <div className="css-1dbjc4n r-z2wwpe r-1udh08x">
                                    <div className="css-18t94o4 css-1dbjc4n r-1loqt21 r-1otgn73 r-1i6wzkk r-lrvibr" style={{ transitionDuration: "0s" }}>
                                        <div
                                            className="greenBold css-1dbjc4n r-1awozwy r-z2wwpe r-18u37iz r-1777fci r-5njf8e"
                                            style={{

                                                borderWidth: 0,
                                                minHeight: 40,
                                                padding: "8px 16.1px",
                                                borderRadius: '5px'

                                            }}
                                        >
                                            <div className="css-901oao r-jwli3a r-320f6p r-ubezar r-oxtfae r-135wba7"
                                                style={{ paddingTop: 0, paddingBottom: 0 }} >
                                                Đồng ý
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
            <div className={`${popupbuy ? "overlaypopupbuy active" : "overlaypopupbuy"}`}></div>


        </>,
        document.getElementById('root2')
    )
}
