import { Formik, Field, Form, ErrorMessage } from 'formik';
import React, { useEffect, useRef } from 'react'
import * as yup from 'yup';
import Swal from 'sweetalert2';
import User from '../api/User';
import { useState } from 'react';
import { Spin } from 'antd';
import ReCAPTCHA from 'react-google-recaptcha'
import "antd/dist/antd.css";
import { Country } from '../api/Country';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslate } from '../core/translate';
import { NavLink, useParams } from 'react-router-dom';
import { Redirect } from 'react-router';
import { createRefSignUp } from '../redux/action/orderAction';





export default function SignUp() {
    let [loadding, setLoadding] = useState(false)
    let { slug } = useParams()
    let { referral } = useSelector(state => state.other)

    let [unique_code, setUnique_code] = useState(slug)


    useEffect(async () => {
        dispatch(createRefSignUp())
        dispatch(Country())

    }, [])

    let { t } = useTranslate()
    useEffect(() => {
        let tab = t("Register | swaptobe, swaptube")
        document.title = tab
        return () => {
            document.title = t("Swap tobe")
        }

    }, [t]);




    const schemaPassword = yup.object().shape({
        userName: yup
            .string()
            .trim("The contact name cannot include leading and trailing spaces")
            .required(t("Not be empty"))
            .min(2, "Enter at least 2 characters")
            .max(16, "Cannot enter more than 16 characters")
            .strict(true),

        Referral: yup
            .string()
            .required(t("Not be empty"))
            .min(2, "Enter at least 2 characters"),
        email: yup
            .string()
            .email()
            .required(t("Not be empty"))
            .min(2, "Enter at least 2 characters"),
        password: yup.string().required(t("Not be empty")),
        retypePassword: yup
            .string()
            .required(t("Not be empty"))
            .oneOf([yup.ref("password"), null], t("Passwords do not match !")),
        tandc: yup
            .boolean()
            .required(t("Not be empty"))
            .oneOf([true], t('You must accept the terms and conditions'))
    });

    const dispatch = useDispatch()


    let { country } = useSelector(state => state.user)
    let reRef = useRef()
    let reCaptcha = useRef()
    const [token, setStateToken] = useState('')
    let [errCaptcha, setErrCaptcha] = useState('')




    let [wait, setWait] = useState(false)


    return (
        <>
            {slug == undefined ? <Redirect to={`/signup/${referral}`} /> : <>


                {wait ? <div style={{ padding: "165px 0", backgroundColor: '#fff' }}>
                    <div className="qs-answer-section wait">

                        <h4 className="ng-binding">Vui Lòng Check Email</h4>
                        <span className="ng-binding" style={{ color: '#000' }}> Chúng tôi sẽ gửi cho bạn kết quả vào hộp thư đến của bạn</span>
                        <div className="btn-group">
                            <a target="_blank" href="https://mail.google.com/mail/u/0/?pli=1#inbox" onclick="window.location.href='/landing.html'" className="greenliner btn btn-default ng-binding">Check Email</a>
                        </div>
                    </div>
                </div>
                    :
                    <div id="main-wrapper" className='main-dashboard' style={{ background: " url('/img/Background_signup.png') 0% 0% / 100% 100% no-repeat", padding: '50px 0' }}>
                        <div className="authincation section-padding">
                            <div className="container h-100">
                                <div className="newlogins-logo" style={{ marginBottom: '50px', textAlign: 'center' }}>
                                    <img src="/img/logoswapcolorful.png" alt="" />
                                </div>
                                <div className="row justify-content-center h-100 align-items-center">
                                    <div className="col-xl-6 col-md-8">

                                        <div className="auth-form card newsignup-content">
                                            <div className=" newsignup-title">
                                                <h4 className="card-title">{t("Register")}</h4>
                                            </div>
                                            <div className="card-body">
                                                <Formik
                                                    onSubmit={async (value) => {
                                                        if (token == '') {
                                                            setErrCaptcha(' Vui lòng nhập recaptcha!')
                                                        } else {
                                                            setErrCaptcha('')
                                                            try {
                                                                const object = {
                                                                    userName: value.userName,
                                                                    password: value.password,
                                                                    country: "Afghanistan",
                                                                    Referral: value.Referral,
                                                                    email: value.email,
                                                                    tokenRecaptcha: true,
                                                                }
                                                                setLoadding(true)
                                                                let res = await User.signup(object)
                                                                if (res.data.status == true) {
                                                                    setWait(true)

                                                                    Swal.fire({
                                                                        title: t("Sign Up Success!"),
                                                                        icon: 'success',
                                                                        timer: 1500
                                                                    })
                                                                }
                                                                setLoadding(false)
                                                            } catch (error) {
                                                                setLoadding(false)
                                                                Swal.fire({
                                                                    title: t(error.response.data.message),
                                                                    icon: 'error',
                                                                    timer: 1500,
                                                                })
                                                                setTimeout(() => {
                                                                    window.location.reload()
                                                                }, 2000)
                                                            }
                                                        }
                                                    }
                                                    }
                                                    validationSchema={schemaPassword}
                                                    initialValues={{
                                                        userName: "",
                                                        password: "",
                                                        retypePassword: "",
                                                        country: "Afghanistan",
                                                        Referral: unique_code,
                                                        email: "",
                                                        tandc: false,
                                                        tokenRecaptcha: "",
                                                    }}
                                                    render={(formikProps, handleBlur) => {
                                                        return (
                                                            <>
                                                                <Form className="signin_validate newsignups">
                                                                    <Field
                                                                        name="__RequestVerificationToken"
                                                                        type="hidden"
                                                                        defaultValue="loQ9zVXv_QCwiVlfQmtsMxOnjHtFHi4BICn_O5ss8vjf9nRWTTa0q_X9BvC77TCZpoI5yQOq9C79NTso_YjaoVEgHxa71hTfq7qMQnxjQPc1"
                                                                    />
                                                                    <p />
                                                                    <div className="form-group newsignup">
                                                                        <Field
                                                                            className="form-control newsignup-input"
                                                                            type="text"
                                                                            name="userName"
                                                                            onChange={formikProps.handleChange}
                                                                            placeholder={`${t("UserName")}`}
                                                                        />
                                                                    </div>
                                                                    <ErrorMessage name="userName">
                                                                        {(mes) => (
                                                                            <p className="text-danger">* {t(`${mes}`)}</p>
                                                                        )}
                                                                    </ErrorMessage>


                                                                    <div className="form-group newsignup">
                                                                        <Field
                                                                            className="form-control newsignup-input"
                                                                            type="password"
                                                                            name="password"
                                                                            onChange={formikProps.handleChange}
                                                                            placeholder={t("Password")}
                                                                        />
                                                                    </div>
                                                                    <ErrorMessage name="password">
                                                                        {(mes) => (
                                                                            <p className="text-danger">* {t(`${mes}`)}</p>
                                                                        )}
                                                                    </ErrorMessage>
                                                                    <div className="form-group newsignup">
                                                                        <Field
                                                                            className="form-control newsignup-input"
                                                                            type="password"
                                                                            name="retypePassword"
                                                                            onChange={formikProps.handleChange}
                                                                            placeholder={t("Retype Password")}
                                                                        />
                                                                    </div>
                                                                    <ErrorMessage name="retypePassword">
                                                                        {(mes) => (
                                                                            <p className="text-danger">* {t(`${mes}`)}</p>
                                                                        )}
                                                                    </ErrorMessage>
                                                                    <div className="form-group newsignup">
                                                                        <Field
                                                                            className="form-control newsignup-input"
                                                                            type="text"
                                                                            component="select"
                                                                            name="country"
                                                                            onChange={formikProps.handleChange}
                                                                            placeholder={t("Country")}
                                                                        >
                                                                            {country?.map((element, index) => {

                                                                                return (
                                                                                    <option value={element.name} key={index}>
                                                                                        {element.name}
                                                                                    </option>
                                                                                );
                                                                            })}
                                                                        </Field>
                                                                    </div>
                                                                    <ErrorMessage name="country">
                                                                        {(mes) => (
                                                                            <p className="text-danger">* {t(`${mes}`)}</p>
                                                                        )}
                                                                    </ErrorMessage>

                                                                    <div className="form-group newsignup">
                                                                        <Field
                                                                            className="form-control newsignup-input"
                                                                            type="text"
                                                                            name="Referral"
                                                                            onChange={formikProps.handleChange}
                                                                            placeholder={t("Referral")}

                                                                        />
                                                                    </div>
                                                                    <ErrorMessage name="Referral">
                                                                        {(mes) => (
                                                                            <p className="text-danger">* {t(`${mes}`)}</p>
                                                                        )}
                                                                    </ErrorMessage>
                                                                    <div
                                                                        className="form-group newsignup"
                                                                        style={{
                                                                            display: "flex",
                                                                            alignItems: "center",
                                                                            justifyContent: "center",
                                                                        }}
                                                                    >
                                                                        <Field
                                                                            className="form-control newsignup-input"
                                                                            type="email"
                                                                            name="email"
                                                                            onChange={formikProps.handleChange}
                                                                            placeholder="Email"
                                                                        />

                                                                    </div>
                                                                    <ErrorMessage name="email">
                                                                        {(mes) => (
                                                                            <p className="text-danger">* {t(`${mes}`)}</p>
                                                                        )}
                                                                    </ErrorMessage>
                                                                    <div
                                                                        className="form-group"

                                                                    >


                                                                    </div>
                                                                    <ReCAPTCHA
                                                                        ref={reRef}
                                                                        sitekey="6LcSjv8cAAAAAFi5PvAn_V8i5BhzD9FML2V-upQZ"
                                                                        // ref={reCaptcha}
                                                                        onChange={async (token) => {

                                                                            setStateToken(token)
                                                                        }}
                                                                        onExpired={(e) => {

                                                                            setStateToken("");
                                                                        }}
                                                                    />
                                                                    {errCaptcha ? <p className='alert alert-danger btn-danger'>{errCaptcha}</p> : ''}
                                                                    <div className="css-1dbjc4n r-1g2xzmm" style={{ marginTop: '32px' }}>

                                                                        <div
                                                                            dir="auto"
                                                                            className="css-901oao r-1mdqdya r-320f6p r-1enofrn r-1wudzfh r-14yzgew r-q4m81j"
                                                                        >
                                                                            <Field type="checkbox" name="tandc" className="form-check-input" id="tandc" style={{ marginTop: '1.2px', marginLeft: '-16px' }} />
                                                                            <span style={{ color: "rgb(118, 116, 120)" }}>

                                                                                {t("You agree with")}{" "}
                                                                                <NavLink to='/LegalPolicy' className="text-base" style={{ color: "#4BBD99" }}>
                                                                                    {t("Terms of Service")}
                                                                                </NavLink>{" "}
                                                                                {t("and")}{" "}
                                                                                <NavLink to='/LegalPolicy' className="text-base" style={{ color: "#4BBD99" }}>
                                                                                    {t("Privacy Policy")}
                                                                                </NavLink>{" "}
                                                                                {t("by Swap tobe")}.
                                                                            </span>
                                                                        </div>
                                                                        <ErrorMessage name="tandc">
                                                                            {(mes) => (
                                                                                <p className="text-danger" style={{ textAlign: 'center' }}>* {mes}</p>
                                                                            )}
                                                                        </ErrorMessage>
                                                                    </div>
                                                                    {
                                                                        loadding ? <div className="example">
                                                                            <Spin />
                                                                        </div> :
                                                                            <button
                                                                                className="btn  btn-block" style={{ color: '#fff', marginTop: '20px' }}
                                                                            >
                                                                                {t("Register")}
                                                                            </button>
                                                                    }
                                                                </Form>
                                                            </>
                                                        );
                                                    }}
                                                />
                                                <div className="new-account mt-3" >
                                                    <a href="/form" style={{ color: '#208ea2', fontSize: '16px' }}>
                                                        {t("If has an account")}{" "} {t("Sign in")}
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </>}
        </>
    )
}
