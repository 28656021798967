import User from "../../api/User"
import { PRICECOIN, PRICESYMBOL, PRICESYMBOLTO } from "../type"


export function createSocket(type, payload) {
    return {
        type,
        payload
    }
}

export function createCoin(type, payload) {
    return {
        type,
        payload
    }
}

export function createGetPricecoin() {
    return async (dispatch) => {


        try {

            let res = await User.pricecoin()

            dispatch(createCoin(PRICECOIN, res))

        } catch (error) {

        }

    }
}
