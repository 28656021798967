import covertCoin from "core/CovertCoin"
import { GETFEEERC20 } from "redux/type"
import { GETFEESTF } from "redux/type"
import { HANDLELOADDING } from "redux/type"
import { TOKENRE } from "redux/type"
import { LISTPACKAGE } from "redux/type"
import Swal from "sweetalert2"
import User from "../../api/User"
import { ErrorMessage, ErrorMessageSlug, ErrorMessageSlugAfter, SuccessMessage } from "../../core/Message"
import { ADDBUYP2P, ADDSELLP2P, BUYADLIST, BUYADLISTUSER, BUYADLISTUSERPENDDING, BUYSELL, CHECKTRANSACTIONDEPOSITVND, COMMISSIONSTAKING, CREATEWALLET, CREATEWALLETCOIN, CREATEWALLETCOINTRC, CREATEWALLETERC, CREATEWALLETTRC, EXCHANGE, GETBAKING, GETBOS, GETBPAY, GETFEE, GETFEEDIFFERENCE, GETHISTORYWIDTHDRAW, GETLISTBANKINGUSER, GETNOTIFICATION, GETPROFILE, GETTRC20, GETWALLET, HISTORYDEPOSITCOIN, HISTORYDEPOSITVND, HISTORYLENDING, HISTORYLENDINGTIME, HISTORYSTAKING, HISTORYSWAP, HISTORYTRANFER, HISTORYWIDTHDRAWADRESS, INTERESTLENDING, INTERESTSTAKING, LENDING, OFF, ON, PARENT, QRCODE, REDIRECT, SELLADLIST, SELLADLISTUSER, SELLADLISTUSERPENDDING, SETQR, TOTALHISTORYSWAP, TRANSACTIONFORM, TURNON, VALUE2FA } from "../type"
import { createOther } from "./orderAction"
import { toggleShadow } from "./toggleShadowActive"
import { Createwallet } from '../reducer/depositReducer'
import { ShowDeposit } from "redux/reducer/depositReducer"
import { createwalleterc } from "redux/reducer/depositReducer"
import { Createwallettrc } from "redux/reducer/depositReducer"
import { Createwalleterc } from "redux/reducer/depositReducer"
import { ShowLoadding } from "redux/reducer/loaddingReducer"
import { gethistory } from "page/Wallet/Components/GetHistory"
export function createNap(type, payload) {
    return {
        type,
        payload
    }
}
export function createWithdraw(type, payload) {
    return {
        type,
        payload
    }
}
export function createA(type, payload) {
    return {
        type,
        payload
    }
}



export function qrcode(data) {
    return async (dispatch) => {
        try {
            let res = await User.generateOTPToken(data)
            dispatch(createA(QRCODE, res))

        } catch (error) {

        }

    }
}
export function turnOn(data, opt, value) {
    return async (dispatch) => {
        try {
            let res = await User.turnOn2fa(data, opt, value)
            Swal.fire({
                title: res.data.message,
                icon: 'success',
                timer: 15000
            })
            dispatch(createA(SETQR, value))
            localStorage.setItem('authen', value)
            dispatch(createA(TURNON, res))
            dispatch(getprofile(data))



        } catch (error) {
            Swal.fire({
                title: error.response.data.message,
                icon: 'error',
                timer: 15000
            })
        }

    }
}
export function turnOff(data, opt, value) {
    return async (dispatch) => {
        try {

            let res = await User.turnOff2fa(data, opt)

            localStorage.setItem('authen', value)
            Swal.fire({
                title: res.data.message,
                icon: 'success',
                timer: 15000
            })
            dispatch(getprofile(data))

            dispatch(createA(SETQR, value))
            dispatch(createA(TURNON, res))


        } catch (error) {
            Swal.fire({
                title: error.response.data.message,
                icon: 'error',
                timer: 15000
            })
        }

    }
}



export function getprofile(token) {

    return async (dispatch) => {
        try {
            let res = await User.getProfile(token)
            localStorage.setItem('authen', res?.data?.data?.enabled_twofa)
            localStorage.setItem('confirm', JSON.stringify({check: res?.data?.data?.kyc2_status,check1: res?.data?.data?.kyc_confirm, check2:res?.data?.data?.verified}))
            dispatch(createA(GETPROFILE, res))

        } catch (error) {

        }

    }
}

export function wallet(token) {

    return async (dispatch) => {
        try {
            let res = await User.getWallet(token)
            localStorage.setItem('wallet', JSON.stringify(res))
            dispatch(createA(GETWALLET, res))

        } catch (error) {

        }

    }
}

export function CreateLending(data, t, setLoadding) {
    return async (dispatch) => {
        setLoadding(true)
        try {
            let res = await User.leading(data)
            dispatch(createA(LENDING, res))

        } catch (error) {
            ErrorMessage(error.response.data.message, t)

        }
        setLoadding(false)

    }
}




export function createBuysell() {
    return async (dispatch) => {
        try {
            let res = await User.buysell()
            dispatch(createA(BUYSELL, res.data.data[0].raito))

        } catch (error) {

        }
    }
}



//hinh thuc giao dich

export function createTransactionForm() {
    return async (dispatch) => {
        try {
            let res = await User.getTransactionForm()
            localStorage.setItem('TransactionForm', JSON.stringify(res.data.data))
            dispatch(createA(TRANSACTIONFORM, res.data.data))

        } catch (error) {

        }
    }
}

export function createAddBuyP2P(token, name, amountExchange, amountCoin, amountMaximum, id, slug, t, setLoadding, tokenRe, value2fa) {
    return async (dispatch) => {
        setLoadding(true)
        try {

            let res = await User.addBuyP2P(token, name, amountExchange, amountCoin, amountMaximum, id, tokenRe, value2fa)


            SuccessMessage(res.data.message, t)
            dispatch(createA(ADDBUYP2P, res))
            dispatch(createA(REDIRECT, true))
            if (tokenRe.length > 0) {
                dispatch(createOther(TOKENRE, ''))
                window.grecaptcha.reset();
            } else {
                dispatch(createOther(VALUE2FA, ''))
            }

        } catch (error) {

            if (error.response.data.errors = 9) {

                ErrorMessage(error.response.data.message, t)
            }
            else if (error.response.data.errors = 2) {
                ErrorMessage(error.response.data.message, t)

            }
            else if (error.response.data.errors = 12) {
                ErrorMessageSlugAfter("User has not created a wallet", slug, t)

            }
            else {
                ErrorMessage(error.response.data.message, t)
            }

            if (tokenRe.length > 0) {
                dispatch(createOther(TOKENRE, ''))
                window.grecaptcha.reset();
            } else {
                dispatch(createOther(VALUE2FA, ''))
            }

        }
        setLoadding(false)
    }
}
export function createAddSellP2P(token, name, amountExchange, amountCoin, amountMaximum, id, slug, t, setLoadding, tokenRe, value2fa) {
    return async (dispatch) => {
        setLoadding(true)
        try {

            let res = await User.addSellP2P(token, name, amountExchange, amountCoin, amountMaximum, id, tokenRe, value2fa)
            SuccessMessage(res.data.message, t)


            dispatch(createA(ADDSELLP2P, res))
            dispatch(createA(REDIRECT, true))
            if (tokenRe.length > 0) {
                dispatch(createOther(TOKENRE, ''))
                window.grecaptcha.reset();
            } else {
                dispatch(createOther(VALUE2FA, ''))
            }

        } catch (error) {
            if (error.response.data.errors == 9) {
                ErrorMessageSlug("balance is not enough", slug, t)

            }
            else if (error.response.data.errors = 2) {
                ErrorMessage(error.response.data.message, t)

            }
            else if (error.response.data.errors = 12) {
                ErrorMessageSlugAfter("User has not created a wallet", slug, t)

            }
            else {
                ErrorMessage(error.response.data.message, t)

            }
            if (tokenRe.length > 0) {
                dispatch(createOther(TOKENRE, ''))
                window.grecaptcha.reset();
            } else {
                dispatch(createOther(VALUE2FA, ''))
            }
        }
        setLoadding(false)
    }
}



export function createBuyadlist(name, page) {
    return async (dispatch) => {
        try {
            let res = await User.buyadlist(name, page)


            dispatch(createA(BUYADLIST, res?.data?.data?.array))

        } catch (error) {

        }
    }
}
export function createSelladlist(name, page) {
    return async (dispatch) => {
        try {
            let res = await User.selladlist(name, page)
            dispatch(createA(SELLADLIST, res?.data?.data?.array))

        } catch (error) {

        }
    }
}
export function createBuyP2Padlist(token, amountCoin, id, symbol, typeExchange, tokenRe, value2fa, t, setLoadding) {
    return async (dispatch) => {
        setLoadding(true)
        try {
            let res = await User.buyP2Padlist(token, amountCoin, id, symbol, typeExchange, tokenRe, value2fa)
            dispatch(createA(REDIRECT, true))
            SuccessMessage(res.data.message, t)
            if (tokenRe.length > 0) {
                dispatch(createOther(TOKENRE, ''))
                window.grecaptcha.reset();
            } else {
                dispatch(createOther(VALUE2FA, ''))
            }

        } catch (error) {
            ErrorMessage(error?.response?.data?.message, t)
            if (tokenRe.length > 0) {
                dispatch(createOther(TOKENRE, ''))
                window.grecaptcha.reset();
            } else {
                dispatch(createOther(VALUE2FA, ''))
            }
        }
        setLoadding(false)

    }
}
export function createSellP2Padlist(token, amountCoin, id, symbol, typeExchange, tokenRe, value2fa, t, setLoadding) {
    return async (dispatch) => {
        setLoadding(true)
        try {
            let res = await User.sellP2Padlist(token, amountCoin, id, symbol, typeExchange, tokenRe, value2fa)
            dispatch(createA(REDIRECT, true))
            SuccessMessage(res.data.message, t)
            if (tokenRe.length > 0) {
                dispatch(createOther(TOKENRE, ''))
                window.grecaptcha.reset();
            } else {
                dispatch(createOther(VALUE2FA, ''))
            }

        } catch (error) {

            ErrorMessage(error?.response?.data?.message, t)
            if (tokenRe.length > 0) {
                dispatch(createOther(TOKENRE, ''))
                window.grecaptcha.reset();
            } else {
                dispatch(createOther(VALUE2FA, ''))
            }

        }
        setLoadding(false)
    }
}
export function createBuypackage(token, value, id, name, amount, setLoadding, t, tokenRe, value2fa) {
    return async (dispatch) => {

        setLoadding(true)

        try {
            let res = await User.buypackage(token, value, id, name, amount, tokenRe, value2fa)
            SuccessMessage("Package activated successfully !", t)
            dispatch(createHistorystaking(token, 1))
            if (tokenRe.length > 0) {
                dispatch(createOther(TOKENRE, ''))
                window.grecaptcha.reset();
            } else {
                dispatch(createOther(VALUE2FA, ''))
            }
        } catch (error) {

            ErrorMessage(error?.response?.data?.message, t)
            if (tokenRe.length > 0) {
                dispatch(createOther(TOKENRE, ''))
                window.grecaptcha.reset();
            } else {
                dispatch(createOther(VALUE2FA, ''))
            }

        }
        setLoadding(false)


    }
}
export function createLending(token, value, symbol, t, setLoadding, tokenRe, value2fa,) {
    return async (dispatch) => {
        setLoadding(true)
        try {
            let res = await User.ledding(token, value, symbol, tokenRe, value2fa,)
            dispatch(createA(REDIRECT, true))
            SuccessMessage(res?.data?.message, t)
            if (tokenRe.length > 0) {
                dispatch(createOther(TOKENRE, ''))
                window.grecaptcha.reset();
            } else {
                dispatch(createOther(VALUE2FA, ''))
            }

        } catch (error) {
            ErrorMessage(error?.response?.data?.message, t)
            if (tokenRe.length > 0) {
                dispatch(createOther(TOKENRE, ''))
                window.grecaptcha.reset();
            } else {
                dispatch(createOther(VALUE2FA, ''))
            }

        }
        setLoadding(false)
    }
}
export function createNapCoin(token, symbol, t, setLoadding) {

    return async (dispatch) => {
        try {
            let res = await User.createWallet(token, symbol)
            dispatch(Createwallet(res))
            // dispatch(ShowDeposit(true))
            dispatch(createOther(HANDLELOADDING, false))
        } catch (error) {
        }
        setLoadding(false)
    }
}
export function createNapCoinERC(token, symbol, t, setLoadding) {

    return async (dispatch) => {

        try {
            let res = await User.createWalletERC20(token, symbol)
            // dispatch(createNap(CREATEWALLETERC, res))
            dispatch(Createwalleterc(res))
            // dispatch(ShowDeposit(true))
            dispatch(createOther(HANDLELOADDING, false))
        } catch (error) {
            Swal.fire({
                title: error?.response?.data?.message,
                icon: 'error',
                timer: 15000
            })
        }
        setLoadding(false)

    }
}
export function createNapCoinTRC(token, symbol, t, setLoadding) {
    return async (dispatch) => {


        try {
            let res = await User.updateWalletDepositTRC20(token, symbol)
            dispatch(Createwallettrc(res))
            // dispatch(ShowDeposit(true))
            // dispatch(createNap(CREATEWALLETCOINTRC, true))
            dispatch(createOther(HANDLELOADDING, false))



        } catch (error) {

            Swal.fire({
                title: error?.response?.data?.message,
                icon: 'error',
                timer: 15000
            })
        }
        setLoadding(false)
    }
}
// Chuyển tiền user
export function createTransferToAddress(token, symbol, amount, to_address, slugHistory, createwallet, createwallettrc, tokenRecaptcha, value2fa, setLoadding, t, setMenuflag, typeWithdrawCoin) {
    return async (dispatch) => {
        setLoadding(true)

        try {
            let res = await User.transferToAddress(token, symbol, amount, to_address, tokenRecaptcha, value2fa, typeWithdrawCoin)

            Swal.fire({
                title: `${t('Successfully transferred')} ${amount} ${slugHistory}`,
                icon: 'success',
                timer: 15000
            })
            dispatch(wallet(token))
            if (slugHistory != "USDT" && slugHistory != 'SHIB' && slugHistory != 'BNB' && slugHistory != 'WIN') {
                gethistory(token, 1, slugHistory, typeWithdrawCoin, dispatch)
            }
            //else gọi ở DepositCoin

            if (tokenRecaptcha.length > 0) {
                dispatch(createOther(TOKENRE, ''))
                window.grecaptcha.reset();
            } else {
                dispatch(createOther(VALUE2FA, ''))
            }



        } catch (error) {

            if (error.response?.data?.message == 'Có lỗi trong quá trình xử lý') {
                ErrorMessage("This functionality is upgrading, please come back in a few minutes!", t)
            } else
                ErrorMessage(error?.response?.data?.message, t)

            if (tokenRecaptcha.length > 0) {
                dispatch(createOther(TOKENRE, ''))
                window.grecaptcha.reset();
            } else {
                dispatch(createOther(VALUE2FA, ''))
            }

        }
        setLoadding(false)
        setMenuflag(false)

    }
}
export function createTransferToUserName(token, symbol, amount, username, note, slugHistory, tokenRecaptcha, value2fa, setLoadding, t, setMenuflag) {
    return async (dispatch) => {

        setLoadding(true)
        try {
            let res = await User.transferToUserName(token, symbol, amount, username, note, tokenRecaptcha, value2fa)
            if (res) {
                Swal.fire({
                    title: `${t('Successfully transferred')} ${amount} ${slugHistory}`,
                    icon: 'success',
                    timer: 1500
                })
                dispatch(wallet(token))
                dispatch(toggleShadow(false))

                dispatch(createHistoryTranfer(token, 1, slugHistory))
                if (tokenRecaptcha.length > 0) {
                    dispatch(createOther(TOKENRE, ''))
                    window.grecaptcha.reset();
                } else {
                    dispatch(createOther(VALUE2FA, ''))
                }

            }


        } catch (error) {

            if (error.response?.data?.message == 'Có lỗi trong quá trình xử lý') {
                ErrorMessage("This functionality is upgrading, please come back in a few minutes!", t)
            } else {
                ErrorMessage(error?.response?.data?.message, t)
            }
            if (tokenRecaptcha.length > 0) {
                dispatch(createOther(TOKENRE, ''))
                window.grecaptcha.reset();
            } else {
                dispatch(createOther(VALUE2FA, ''))
            }

        }
        setLoadding(false)
        setMenuflag(false)
    }
}

// lịch sử tạo quảng cáo coin
export function createSelladlistuser(token, page) {
    return async (dispatch) => {
        try {
            let res = await User.selladlistuser(token, page)

            dispatch(createA(SELLADLISTUSER, res?.data?.data))


        } catch (error) {

        }
    }
}
export function createBuyadlistuser(token, page) {
    return async (dispatch) => {
        try {
            let res = await User.buyadlistuser(token, page)

            dispatch(createA(BUYADLISTUSER, res?.data?.data))

        } catch (error) {

        }
    }
}
export function createSelladlistuserpendding(token, page) {
    return async (dispatch) => {
        try {
            let res = await User.selladlistuserpendding(token, page)

            dispatch(createA(SELLADLISTUSERPENDDING, res?.data?.data))

        } catch (error) {

        }
    }
}
export function createBuyadlistuserpendding(token, page) {
    return async (dispatch) => {
        try {
            let res = await User.buyadlistuserpendding(token, page)

            dispatch(createA(BUYADLISTUSERPENDDING, res?.data?.data))

        } catch (error) {

        }
    }
}


//swap
export function createConvert(token, symbolForm, symbolTo, coin, setLoadding, t, symbolfrom, symbolto, typeF, typeT, tokenRe, value2fa, Type) {
    return async (dispatch) => {
        setLoadding(true)
        try {
            let res = await User.convertcoin(token, symbolForm, symbolTo, coin, tokenRe, value2fa, Type)
            Swal.fire({
                title: t("Coin conversion successful!"),
                icon: 'success',
                timer: 15000
            })
            if (tokenRe?.length > 0) {
                dispatch(createOther(TOKENRE, ''))
                window.grecaptcha.reset();
            } else {
                dispatch(createOther(VALUE2FA, ''))
            }
            dispatch(createHistorySwap(token, 50, 1, covertCoin(symbolfrom?.toUpperCase(), typeF), covertCoin(symbolto?.toUpperCase(), typeT)))
            dispatch(wallet(token))


        } catch (error) {
            if (error.response?.data?.message == 'Có lỗi trong quá trình xử lý') {
                ErrorMessage("This functionality is upgrading, please come back in a few minutes!", t)
            }
            else if (error?.response?.data?.errors == 6) {
                ErrorMessage("Invalid or insufficient number of coins", t)
            } else {
                ErrorMessage(error.response?.data?.message, t)
            }
            if (tokenRe?.length > 0) {
                dispatch(createOther(TOKENRE, ''))
                window.grecaptcha.reset();
            } else {
                dispatch(createOther(VALUE2FA, ''))
            }

        }
        setLoadding(false)
    }
}
export function createConvertcoinExchange(token, symbolForm, symbolTo, coin, amountExchange, setLoadding, t, symbolfrom, symbolto, typeF, typeT, tokenRe, value2fa, Type) {
    return async (dispatch) => {
        setLoadding(true)
        console.log(typeF);

        try {
            let res = await User.convertcoinExchange(token, symbolForm, symbolTo, coin, amountExchange, tokenRe, value2fa, Type)
            SuccessMessage("Coin conversion successful!", t)

            //symbolfrom, symbolto history swap
            dispatch(createHistorySwap(token, 50, 1, covertCoin(symbolfrom?.toUpperCase(), typeF), covertCoin(symbolto?.toUpperCase(), typeT)))
            dispatch(wallet(token))
            if (tokenRe?.length > 0) {
                dispatch(createOther(TOKENRE, ''))
                window.grecaptcha.reset();
            } else {
                dispatch(createOther(VALUE2FA, ''))
            }



        } catch (error) {
            if (error.response?.data?.message == 'Có lỗi trong quá trình xử lý') {
                ErrorMessage("This functionality is upgrading, please come back in a few minutes!", t)
            }
            else if (error?.response?.data?.errors == 6) {
                ErrorMessage("Invalid or insufficient number of coins", t)
            }
            else if (error?.response?.data?.errors == 11) {
                ErrorMessage("The price must not differ by 5% from the market", t)
            } else {
                ErrorMessage(error?.response?.data?.message, t)
            }
            if (tokenRe?.length > 0) {
                dispatch(createOther(TOKENRE, ''))
                window.grecaptcha.reset();
            } else {
                dispatch(createOther(VALUE2FA, ''))
            }
        }
        setLoadding(false)
    }
}
//sendmailforgetpassword
export function CreateSendmailforgetpassword(email, setLoadding, setWait) {
    return async (dispatch) => {
        setLoadding(true)
        try {
            let res = await User.sendmailforgetpassword(email)
            if (res?.data?.status == true) {
                setWait(true)
                Swal.fire({
                    title: res.data.message,
                    icon: 'success',
                    timer: 15000
                })


            }





        } catch (error) {
            Swal.fire({
                title: error?.response?.data?.message,
                icon: 'error',
                timer: 15000
            })
        }
        setLoadding(false)
    }
}

//swapBUSD USDT
export function createConvertbusd(token, symbolbusd, symbolusdt, coin, setLoadding, t) {
    return async (dispatch) => {
        setLoadding(true)
        try {
            let res = await User.convertbusd(token, symbolbusd, symbolusdt, coin,)
            Swal.fire({
                title: t("Coin conversion successful!"),
                icon: 'success',
                timer: 15000
            })
            dispatch(wallet(token))



        } catch (error) {

            if (error?.response?.data?.errors == 1) {
                ErrorMessage("Invalid or insufficient number of coins", t)
            }

        }
        setLoadding(false)
    }
}
//update nap coin
export function createDepositUSDTTRC20(token) {
    return async (dispatch) => {

        try {
            let res = await User.depositUSDTTRC20(token)
            Swal.fire({
                title: res.data.message,
                icon: 'success',
                timer: 15000
            })

        } catch (error) {
            Swal.fire({
                title: error?.response?.data?.message,
                icon: 'error',
                timer: 15000
            })
        }
    }
}

//getbaking
export function createGetBaking() {
    return async (dispatch) => {

        try {
            let res = await User.getbanking()
            dispatch(createA(GETBAKING, res))

        } catch (error) {

        }
    }
}
//CheckTransactionDepositVnd
export function createCheckTransactionDepositVnd(token, setLoadding) {
    return async (dispatch) => {
        try {
            let res = await User.checkTransactionDepositVnd(token)
            dispatch(createA(CHECKTRANSACTIONDEPOSITVND, res))

        } catch (error) {

        }
        setLoadding(false)
    }
}
//history nap
export function createHistorydepositvnd(token, page) {
    return async (dispatch) => {
        try {
            let res = await User.historydepositvnd(token, page)
            dispatch(createA(HISTORYDEPOSITVND, res))

        } catch (error) {

        }
    }
}
//get list banking
export function createGetlistbankinguser(token, page) {
    return async (dispatch) => {
        try {
            let res = await User.getlistbankinguser(token, page)
            dispatch(createA(GETLISTBANKINGUSER, res))

        } catch (error) {

        }
    }
}
//History Swap
export function createHistorySwap(token, limit, page, symbolfrom, symbolto) {
    return async (dispatch) => {

        try {

            let res = await User.historyswap(token, limit, page, symbolfrom, symbolto)
            dispatch(createA(TOTALHISTORYSWAP, res?.data?.data?.total))

            dispatch(createA(HISTORYSWAP, res))

        } catch (error) {

        }




    }
}
//History Lending
export function createHistoryLending(token, page) {
    return async (dispatch) => {
        try {
            let res = await User.historylendding(token, page)
            dispatch(createA(HISTORYLENDING, res))

        } catch (error) {

        }
    }
}
//History Lending
export function createHistoryLendingTime(token, page, limit) {
    return async (dispatch) => {
        try {
            let res = await User.historylenddingtime(token, page, limit)
            dispatch(createA(HISTORYLENDINGTIME, res))

        } catch (error) {

        }
    }
}
//History staking
export function createHistorystaking(token, page) {
    return async (dispatch) => {
        try {
            let res = await User.historystaking(token, page)
            dispatch(createA(HISTORYSTAKING, res))

        } catch (error) {

        }
    }
}

//staking
export function createIntereststaking(token, page) {
    return async (dispatch) => {
        try {
            let res = await User.historyinterestpackage(token, page)
            dispatch(createA(INTERESTSTAKING, res))

        } catch (error) {

        }
    }
}

export function createGetHistoryCommission(token, page) {
    return async (dispatch) => {
        try {
            let res = await User.getHistoryCommission(token, page)
            dispatch(createA(COMMISSIONSTAKING, res))

        } catch (error) {

        }
    }
}
//getExchangeBOS  
export function createGetExchangeBOS() {

    return async (dispatch) => {
        let res = await User.getExchangeBOS()
        if (res) {
            dispatch(createA(GETBOS, res))
        }
    }

}
//gethistorywidthdraw
export function createGethistorywidthdraw(token, page, symbol, setLoadding, t) {
    return async (dispatch) => {
        setLoadding(true)
        try {

            let res = await User.gethistorywidthdraw(token, page, symbol)
            dispatch(createA(GETHISTORYWIDTHDRAW, res))

        } catch (error) {

        }
        setLoadding(false)
    }
}
export function createHistorywidthdrawAdress(token, page, symbol, address) {

    return async (dispatch) => {
        dispatch(ShowLoadding(true))

        try {

            let res = await User.historywidthdraw(token, page, symbol, address)

            dispatch(createA(HISTORYWIDTHDRAWADRESS, res))

        } catch (error) {

        }
        dispatch(ShowLoadding(false))

    }
}


//getExchangeBPAY  
export function createGetExchangeBPAY() {

    return async (dispatch) => {
        let res = await User.getExchangeBPAY()
        if (res) {
            dispatch(createA(GETBPAY, res))
        }
    }

}

//gettrc20  
export function createGettrc20() {
    return async (dispatch) => {

        try {
            let res = await User.gettrc20()
            dispatch(createA(GETTRC20, res))

        } catch (error) {

        }
    }
}
//history lai lending
export function createInterestlending(token, symbol, page) {
    return async (dispatch) => {

        try {
            let res = await User.interestlending(token, symbol, page)
            dispatch(createA(INTERESTLENDING, res))

        } catch (error) {

        }
    }
}

//history deposit coin
export function createHistoryDepositCoin(token, symbol, page) {
    return async (dispatch) => {
        dispatch(ShowLoadding(true))

        try {
            let res = await User.historydepositcoin(token, symbol, page)
            dispatch(createA(HISTORYDEPOSITCOIN, res))

        } catch (error) {

        }
        dispatch(ShowLoadding(false))
    }
}

// history tranfer
export function createHistoryTranfer(token, symbol, page) {

    return async (dispatch) => {

        try {

            let res = await User.historytranfer(token, symbol, page)
            dispatch(createA(HISTORYTRANFER, res))


        } catch (error) {

        }
    }
}


//getnotification 
export function createGetnotification(token, limit, page, setLoadding) {
    return async (dispatch) => {
        setLoadding(true)

        try {

            let res = await User.getnotification(token, limit, page)
            dispatch(createA(GETNOTIFICATION, res?.data?.data))
            if (limit == res?.data?.data?.total) {
                setLoadding(false)
            }
        } catch (error) {

        }
        setLoadding(false)
    }
}


//getParent
export function getParent(token, callback) {


    return async (dispatch) => {
        try {
            let res = await User.getParent(token);

            dispatch(createA(PARENT, res.data?.data));
            callback(true)
        } catch (error) {

        }
    };
}


//getfee 
export function GetFee(name) {
    return async (dispatch) => {
        try {
            let res = await User.getfee(name);
            if (name == "DIFFERENCE") {
                dispatch(createA(GETFEEDIFFERENCE, res?.data?.data));
            } else if (name == "STF_Turn") {
                dispatch(createA(GETFEESTF, res?.data?.data));
            }
            else {
                dispatch(createA(GETFEE, res?.data?.data));
            }
        } catch (error) {
        }
    };
}


export function GetFeeERC20(name) {


    return async (dispatch) => {
        try {
            let res = await User.getfeeERC20(name);
            dispatch(createA(GETFEEERC20, res?.data?.data))

        } catch (error) {

        }
    };
}


//getListPackage staking
export function GetListPackage() {


    return async (dispatch) => {
        try {
            let res = await User.getListPackage();
            dispatch(createA(LISTPACKAGE, res))


        } catch (error) {

        }
    };
}

