import { TYPECOIN } from "redux/type"
import { PRICECOIN } from "../type"

let initState = {
    typecoin: '',
    pricecoin: JSON.parse(localStorage.getItem('ListCoin')) || []

}


export default function authReducer(state = initState, action) {
    switch (action.type) {

        case PRICECOIN:
            localStorage.setItem("ListCoin", JSON.stringify(action.payload))
            return {
                ...state,
                pricecoin: action?.payload,
            }
        case TYPECOIN:
            localStorage.setItem("ListCoin", JSON.stringify(action.payload))
            return {
                ...state,
                typecoin: action?.payload,
            }

        default:
            return state;
    }
}