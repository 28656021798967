import { Spin } from 'antd'
import RecaptchaF2A from 'Components/RecaptchaF2A'
import covertCoin from 'core/CovertCoin'
import { ErrorMessage } from 'core/Message'
import { filterPricecoin } from 'page/Vi/component/MinimumWidthdraw'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { GetFeeERC20 } from 'redux/action/userAction'
import { fixedNumber } from '../../../core/hook/fixedNumber'
import { formatMoney } from '../../../core/hook/formarMoney'
import { useTranslate } from '../../../core/translate'
import { createTransferToAddress, GetFee } from '../../../redux/action/userAction'

export default function Submit({ toAddress, valueCoin, slug, type, getwallet, setMenuflag }) {
    let { createwallettrc, createwallet } = useSelector(state => state.user)
    let { tokenRe, value2fa } = useSelector(state => state.other)
    let { pricecoin } = useSelector(state => state.coin)
    let { typeWithdrawCoin } = useSelector(state => state.withdraw)
    let { feeWidthdraw, name } = filterPricecoin(pricecoin, slug, getwallet)
    let [loadding, setLoadding] = useState(false)
    let { t } = useTranslate()

    const dispatch = useDispatch()
    let { getfee, getfeeErc20 } = useSelector(state => state.user)
    let symbol = JSON.parse(localStorage.getItem('symbol'))
    let user = JSON.parse(localStorage.getItem('user'))

    useEffect(() => {
        dispatch(GetFee('transfer'))
        dispatch(GetFeeERC20('WIDTHDRAWUSDTERC20'))
    }, [])


    let total = Number(valueCoin) + ((Number(valueCoin) / 100) * getfee[0]?.raito)
    let nameCoin = covertCoin(slug, type)
    let [err, setErr] = useState("")
    const { profile } = useSelector(state => state.user)
    const twofa = profile?.data?.data?.enabled_twofa
    function handleSubmit() {
        if (!tokenRe.length > 0 || !value2fa) {
           try {
            setErr('')
            setLoadding(true)
            if (slug == "USDT") {
                if (symbol == "USDT.ERC20") {
                    dispatch(createTransferToAddress(user?.axiologin?.token, `${slug}.ERC20`, Number(valueCoin), toAddress, slug, createwallet, createwallettrc, tokenRe, value2fa, setLoadding, t, setMenuflag, typeWithdrawCoin))
                } else {
                    dispatch(createTransferToAddress(user?.axiologin?.token, `${slug}.TRC20`, Number(valueCoin), toAddress, slug, createwallet, createwallettrc, tokenRe, value2fa, setLoadding, t, setMenuflag, typeWithdrawCoin))
                }
            }
            else {
                dispatch(createTransferToAddress(user?.axiologin?.token, nameCoin, Number(valueCoin), toAddress, nameCoin, createwallet, createwallettrc, tokenRe, value2fa, setLoadding, t, setMenuflag, typeWithdrawCoin))
            }
           } catch (error) {
            if (twofa == 0 && tokenRe == '') {
                setErr("Please enter ReCAPTCHA")

            } else {
                setErr("Please enter  2FA")
            }
           }
        } else{
            ErrorMessage("Please enter ReCAPTCHA")
        }
    }

    return (
        <>
            <div>
                <div className='depositvnd-title'>
                    <p>{t("Remittance confirmation")}</p>
                </div>
                <div className='depositvnd-input'>
                    <p>{t("Wallet")} {slug}</p>
                    <p>{toAddress}</p>
                </div>
                <div className='depositvnd-input'>
                    <p> {t("Amount")} {slug}</p>
                    <p>{formatMoney(String(valueCoin))}  </p>
                </div>
                <div className='depositvnd-input'>
                    <p>  {t("Withdrawal Fee")}</p>
                    <p>{getfee[0]?.raito}%  </p>
                </div>
                <div className='depositvnd-input'>
                    <p>  {t("Fee Gas")}</p>
                    {symbol == "USDT.ERC20" ? <p >{getfeeErc20[0]?.raito} $</p> : <p >{feeWidthdraw} {name}</p>}
                </div>
                <div className='depositvnd-input'>
                    <p>{t("Amount of money")}</p>
                    {valueCoin == 0.1 ? <p >{formatMoney(String(fixedNumber(total , 4)))} {slug}</p> : <p >{formatMoney(String(total , 4))} {slug}</p>}
                </div>
                <RecaptchaF2A />
                <div className="depositvnd-submit">
                    {loadding ? <div className="example" style={{ textAlign: 'center', width: '100%' }}>
                        <Spin />
                    </div> :
                        <>
                            <button onClick={handleSubmit}>Confirm</button>
                            <button onClick={() => setMenuflag(false)}>Back</button>
                        </>}
                </div>
            </div>
        </>
        // <div className="withdrawTW-details" style={{ background: '#087972', color: '#000', zIndex: "1001" }}>
        //     <div className="withdrawTW-details-container">
        //         <div className="withdrawTW-details-container__title">{t("Withdrawal Confirmation")}</div>

        //         <div className="withdrawTW-details-container__content">
        //             <div className="withdrawTW-details-container__content--title">


        //             </div>

        //             <div className="withdrawTW-details-container__content--info">
        //                 <div>
        //                     <div>
        //                         <div className="label" style={{ width: '150px' }}>{t("Wallet")} {slug}:</div>
        //                         <div className="value" style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{toAddress}</div>
        //                     </div>
        //                     <div>
        //                         <div className="label" style={{ width: '150px' }}><span>{t("Amount")} {slug}:</span></div>
        //                         <div className="value">{formatMoney(String(valueCoin))}    </div>
        //                     </div>
        //                     <div>
        //                         <div className="label" style={{ width: '150px' }}><span>{t("Withdrawal Fee")}:</span></div>
        //                         <div className="value">{getfee[0]?.raito}%</div>
        //                         {/* <div className="value">{getfee[0]?.raito}%</div> */}
        //                     </div>
        //                     <div>
        //                         <div className="label" style={{ width: '150px' }}><span>{t("Fee Gas")}:</span></div>
        //                         {symbol == "USDT.ERC20" ? <div className="value">{getfeeErc20[0]?.raito} $</div> : <div className="value">{feeWidthdraw} {name}</div>}

        //                     </div>

        //                     <div>
        //                         <div className="label" style={{ width: '150px' }}><span>{t("Amount of money")}:</span></div>
        //                         {valueCoin == 0.1 ? <div className="value">{formatMoney(String(fixedNumber(total, 4)))} {slug}</div> : <div className="value">{formatMoney(String(total, 4))} {slug}</div>}

        //                     </div>
        //                 </div>
        //             </div>

        //             <RecaptchaF2A />
        //             {err && <p className=" errorLogin" style={{ marginTop: '0' }}>{t(err)}</p>}

        //             {loadding ? <div className="example">
        //                 <Spin />
        //             </div> :
        //                 <div className="withdrawTW-details-container__button">


        //                     <button onClick={() => dispatch(toggleShadow(!flag))} style={{ backgroundColor: 'red', color: '#fff' }}>{t("Back")}</button>

        //                     <button onClick={handleSubmit} style={{ backgroundColor: '#087972', color: '#fff' }}>{t("Confirm")}</button>

        //                 </div>
        //             }

        //         </div>
        //     </div >
        // </div>
    )
}
