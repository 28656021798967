import { Spin } from 'antd';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory } from 'react-router';
import { useTranslate } from '../../core/translate';
import useFormValidate from '../../core/useFormValidate';
import { CreateSendmailforgetpassword, getprofile } from '../../redux/action/userAction';







export default function Sendmailforgetpassword() {
    const dispatch = useDispatch()
    let { t } = useTranslate()
    let history = useHistory()
    let { axiologin } = useSelector(state => state.auth)
    let { form, error, inputChange, check } = useFormValidate({

        email: ''
    }, {
        rule: {
            email: {
                required: true,
                pattern: 'email'

            },


        },
    })

    let token = JSON.parse(localStorage.getItem('user'))
    useEffect(async () => {
        if (axiologin) {
            dispatch(getprofile(token.axiologin.token))
        }
    }, [])

    const { profile } = useSelector(state => state.user)

    const verified = profile?.data?.data?.verified
    let [loadding, setLoadding] = useState(false)
    let [wait, setWait] = useState(false)
    async function _login() {

        let error = check();

        if (Object.keys(error).length === 0) {
            dispatch(CreateSendmailforgetpassword(form.email, setLoadding, setWait))
        }
    }
    return (
        <>
            {wait ? <div style={{ padding: "165px 0", backgroundColor: '#fff' }}>
                <div className="qs-answer-section wait">

                    <h4 className="ng-binding">{t("Please Check Email")}</h4>
                    <span className="ng-binding" style={{ color: '#000' }}> {t("We will send you the results to your inbox")}</span>
                    <div className="btn-group">
                        <a target="_blank" href="https://mail.google.com/mail/u/0/?pli=1#inbox" onclick="window.location.href='/landing.html'" className="greenliner btn btn-default ng-binding">Check Email</a>
                    </div>
                </div>
            </div> :
                <div className='Verification' style={{ backgroundColor: "#fff", color: '#000', width: '100%' }}>
                    <div id="main-wrapper" className='main-dashboard'>
                        <div className="authincation section-padding">
                            <div className="container h-100">
                                <div className="row justify-content-center h-100 align-items-center" style={{ margin: '40px 0' }} >
                                    <div className="col-xl-6 col-md-8" style={{ padding: '0' }}>

                                        <div className="auth-form card">
                                            <div className="card-header justify-content-center">
                                                <h4 className="card-title">{t("Forgot password")}</h4>
                                            </div>
                                            <div className="card-body">
                                                <form method="post" name="myform" className="signin_validate" action="https://demo.themefisher.com/tradio/otp-1.html">
                                                    <div className="form-group">
                                                        <label>{t("Email")}</label>
                                                        <input type='text' name='email' value={form.email} onChange={inputChange}
                                                            className="form-control"
                                                            placeholder={t("email")} />

                                                    </div>
                                                    {error.email && <p className=" errorLogin">{error.email}</p>}


                                                    {loadding ? <div className="example" style={{ margin: '0' }}>
                                                        <Spin />
                                                    </div> :
                                                        <div className="text-center">
                                                            <a className="btn btn-success btn-block" onClick={_login} style={{ color: '#fff' }}>
                                                                {t("Send")}
                                                            </a>
                                                        </div>
                                                    }
                                                </form>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}
        </>
    )
}
