import React, { useEffect } from 'react'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { port } from '../../port'
import { createNews } from '../../redux/action/orderAction'

export default function News() {
    const dispatch = useDispatch()
    let { datanews } = useSelector(state => state.other)

    useEffect(() => {
        dispatch(createNews())
    }, [])
    let [details, setDetails] = useState(false)
    function openDetails() {
        setDetails(true)
    }
    return (
        <div className="news">
            <div className="news-container">
                {
                    datanews?.data?.data.map((e, i) => {
                        let detail = e?.detail

                        return (
                            <>
                                {details ? "" : <div className="news-item">
                                    <div className="image">
                                        <img src={`${port}${e.image}`} alt="" />
                                        <div>{e.date}</div>
                                    </div>
                                    <div className="title"><a href="/">{e.title}</a></div>

                                    <div className="view-more" onClick={openDetails}><a >Xem chi tiết</a></div>
                                </div>}

                                {/* {details && <div dangerouslySetInnerHTML={{ __html: detail }} />} */}


                            </>
                        )
                    })
                }

            </div>
        </div>
    )
}
