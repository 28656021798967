import React, { useRef, useState } from 'react'

import Select from 'react-select'

import { useDispatch, useSelector } from 'react-redux'

import { useTranslate } from 'core/translate'
import { port } from 'port';
import createApiNap from 'core/createApiNap';
import { Spin } from 'antd';
import { ErrorMessage } from 'core/Message';
import { createBuypackage } from 'redux/action/userAction';
import { useHistory } from 'react-router';
import covertCoin from 'core/CovertCoin';
import RecaptchaF2A from 'Components/RecaptchaF2A';

function truncate(value, precision) {
    var step = Math.pow(10, precision || 0);
    var temp = Math.trunc(step * value);

    var a = temp / step;
    return a.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export default function Package() {

    let { t } = useTranslate()
    let { listpackage } = useSelector(state => state.user)
    let { pricecoin } = useSelector(state => state.coin)
    let { getfee } = useSelector(state => state.user)

    let [index, setIndex] = useState(0)
    let [test, setTest] = useState(null)
    let [loadding, setLoadding] = useState(false)
    let [CoinImage, setCoinImage] = useState('images/BTC.png')
    let [name, setName] = useState('BTC')
    let history = useHistory()
    let [value, setValue] = useState('1')
    let [test2, setTest2] = useState(null)
    let [id, setId] = useState()

    const dispatch = useDispatch()
    function PriceChange(e) {
        setPrice(e?.value)
        setValue(e?.interest)
        setTest(e)
        setTest2(null)
        setId(e?.id)
    }

    let [price, setPrice] = useState(500)
    let [month, setMonth] = useState('1%/Month')
    let [total, setTotal] = useState('3')
    function selectChange(e) {
        setMonth(e.month)
        setTotal(e.total)
        setTest2(e)

    }

    let [selectCoin, setSelectCoin] = useState(false)
    function handleSelectCoin() {
        setSelectCoin(!selectCoin)
        setTest(null)
        setTest2(null)
    }

    let optionsPrice = listpackage?.data?.data?.map((e, i) => {
        let price = e?.price_usd
        return { label: `${price}$ ~ ${truncate((price / pricecoin[index]?.price), 6).replace(/,/g, '')} ${name}`, value: price, interest: `${e?.interest}`, id: `${e?.id}` }

    })



    const options = [
        { label: `3 Months ${value}% / Month`, value: `3`, month: `3`, total: ` ${value * 3}` },
        { label: `6 Months ${value}% / Month`, value: `6`, month: `6`, total: ` ${value * 6}` },
        { label: `9 Months ${value}% / Month`, value: `9`, month: `9`, total: ` ${value * 9}` },
        { label: `12 Months ${value}% / Month`, value: `12`, month: `12`, total: ` ${value * 12}` },
        { label: `24 Months ${value}% / Month`, value: `24`, month: `24`, total: ` ${value * 24}` },
        { label: `36 Months ${value}% / Month`, value: `36`, month: `36`, total: ` ${value * 36}` },

    ]
    let token = JSON.parse(localStorage.getItem('user'))
    let [TypeCoin, setTypeCoin] = useState()
    function CoinChange(img, name, type, id) {
        createApiNap(name, type, dispatch, token, t, setLoadding)
        setTest(null)
        setIndex(id)
        setName(name)
        setCoinImage(img)
        setSelectCoin(false)
        setTypeCoin(type)
    }
    const { profile } = useSelector(state => state.user)

    let { axiologin } = useSelector(state => state.auth)
    const verified = profile?.data?.data?.verified
    const twofa = profile?.data?.data?.enabled_twofa
    let { tokenRe, value2fa } = useSelector(state => state.other)
    let [err, setErr] = useState()
    function hanleSubmit() {
        if (!axiologin) {
            history.push('/form')
        }
        else if (tokenRe.length > 0 || value2fa) {
            if (verified == null) {
                ErrorMessage("User has not verified KYC !", t)
            } else if (verified == 2) {
                ErrorMessage("KYC is pending, please wait!", t)
            }
            else if (verified == 3) {
                ErrorMessage("KYC has been declined, please check again!", t)

            } else {

                setErr('')
                dispatch(createBuypackage(token?.axiologin?.token, month, id, covertCoin(name?.toUpperCase(), TypeCoin?.toUpperCase()), truncate((price / pricecoin[index]?.price), 6).replace(/,/g, ''), setLoadding, t, tokenRe, value2fa))

            }
        } else {
            if (twofa == 0 && tokenRe == '') {
                setErr("Please enter ReCAPTCHA")

            } else {
                setErr("Please enter  2FA")

            }
        }

    }
    return (
        <>
            <div className='newStaking'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-12 newStaking-main">
                            <div className="staking_title" >
                                <div style={{ backgroundColor: "rgba(0, 124, 145, 0.54)", padding: '20px 20px', borderRadius: '10px' }}>
                                    <div className="staking_title-item">
                                        <h4 style={{ color: "#fff", fontWeight: 'bold' }}>{t("Choose coin")}</h4>
                                    </div>
                                    <div className="staking_title-select" style={{ color: '#000', position: 'relative' }}>

                                        <div onClick={handleSelectCoin} style={{ padding: '8px', backgroundColor: "rgba(27, 77, 97, 0.5)", borderRadius: "10px", display: 'flex', justifyContent: "space-between", height: '50px', alignItems: 'center' }}>
                                            <span style={{ color: "#fff" }}>      <img src={`${port}${CoinImage}`} alt="" style={{ width: '25px', marginRight: '10px' }} />{name} </span>
                                            <svg height={20} width={20} viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="css-tj5bde-Svg"><path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z" style={{ color: '#fff' }} /></svg>
                                        </div >
                                        {selectCoin ?
                                            <div style={{ backgroundColor: "#fff", borderRadius: '4px', position: 'absolute', width: '100%', zIndex: '100', boxShadow: "1px 1px 1px 1px #aaa" }} >
                                                <div style={{ maxHeight: '300px', overflow: 'auto', cursor: 'pointer' }}>
                                                    {pricecoin?.map((e, i) => {
                                                        if (e?.name != "STF" && e?.name != "QGC" && e?.name != "NBB" && e?.staking == 1)
                                                            return (
                                                                <div key={i} className="btc-item" style={{ display: "flex", alignItems: 'center', padding: '8px' }} onClick={() => CoinChange(`images/${e?.name}.png`, e?.name, e?.type, i)}>
                                                                    <div className="img" >
                                                                        <img src={`${port}images/${e?.name}.png`} alt="" style={{ width: '25px', marginRight: '10px' }} />
                                                                    </div>
                                                                    <div className="btc" style={{ color: '#000' }}>{e?.name}</div>
                                                                </div>
                                                            )


                                                    })}

                                                </div>

                                            </div > : ''}

                                    </div >
                                </div >
                            </div >


                            <div >
                                <div className="staking_title" >
                                    <div style={{ backgroundColor: "rgba(0, 124, 145, 0.54)", padding: '20px 20px', borderRadius: '10px' }}>
                                        <div className="staking_title-item">
                                            <h4 className='staking-h4' style={{ color: "#fff", fontWeight: 'bold' }}>{t("Select Earn Tobe package")}</h4>
                                        </div>
                                        <div className="staking_title-select" style={{ color: '#000' }}>
                                            <Select onChange={(e) => PriceChange(e)} options={optionsPrice} value={test} isSearchable={false}
                                                styles={{
                                                    control: styles => ({ ...styles, backgroundColor: 'rgba(27, 77, 97, 0.5)', borderRadius: '10px', height: '50px', border: 'none' }),
                                                    singleValue: (provided, state) => {
                                                        const color = '#fff';
                                                        const fontFamily = 'IBM Plex Sans';

                                                        return { ...provided, color, fontFamily }
                                                    },
                                                    placeholder: styles => ({
                                                        ...styles,
                                                        color: '#fff'
                                                    }),

                                                    option: (styles, { isFocused, isSelected }) => {
                                                        return {
                                                            ...styles,
                                                            backgroundColor: isSelected && "#fff",
                                                            color: "#000",
                                                            fontFamily: 'IBM Plex Sans',
                                                            ":hover": {
                                                                ...styles[":hover"],
                                                                backgroundColor: "rgba(27, 77, 97, 0.5)",
                                                                cursor: 'pointer',
                                                                fontWeight: '500',
                                                                color: '#fff'
                                                            }
                                                        }
                                                    }
                                                }} />

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8 col-md-12">
                            <div className="staking main-container newstaking-content">
                                <div className="header" style={{ color: '#fff', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <div className="header-container center">
                                        <div style={{ fontWeight: 'bold' }}>{t("Package")} ${price}</div>
                                    </div>
                                </div>
                                <div className="content" >
                                    <div className='content-text' style={{ width: '350px' }}>
                                        <div>
                                            <ul style={{ marginBottom: '1em' }}>
                                                <li>{t("Interest")}:</li>
                                                <li>{month}:</li>
                                            </ul>
                                            <ul style={{ marginBottom: '1em' }}>
                                                <li>{t("Total")}:</li>
                                                <li>{total}%</li>
                                            </ul>
                                            <ul style={{ marginBottom: '1em' }}>
                                                <li>USD:</li>
                                                <li>{truncate((price + price) * (total / 100))} $</li>
                                            </ul>
                                            <ul style={{ marginBottom: '1em' }}>
                                                <li>Fee:</li>
                                                <li>{getfee[0]?.raito} $</li>
                                            </ul>
                                        </div>
                                        <div style={{ color: "#000" }}>
                                            <Select
                                                onChange={(e) => selectChange(e)}
                                                options={options}
                                                isSearchable={false}
                                                value={test2}
                                                styles={{
                                                    control: styles => ({
                                                        ...styles, borderRadius: '7px', height: '50px', border: '1px solid #00C398',
                                                        boxSizing: ' border-box'
                                                    }),

                                                    option: (styles, { isFocused, isSelected }) => {
                                                        return {
                                                            ...styles,
                                                            backgroundColor: isSelected && "#fff",
                                                            color: "#000",
                                                            fontFamily: 'IBM Plex Sans',
                                                            ":hover": {
                                                                ...styles[":hover"],
                                                                backgroundColor: "rgba(27, 77, 97, 0.5)",
                                                                cursor: 'pointer',
                                                                color: '#fff',
                                                                fontWeight: '500',


                                                            }
                                                        }
                                                    }
                                                }}
                                            />
                                        </div>
                                        {axiologin && <RecaptchaF2A />}
                                        {err && <p className=" errorLogin" style={{ marginTop: '0' }}>{t(err)}</p>}
                                        {loadding ? <div className="example" style={{ margin: '0' }}>
                                            <Spin />
                                        </div> :
                                            <div className="center" onClick={hanleSubmit}>
                                                <button >{t("Register")}</button>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>







        </>
    )
}
