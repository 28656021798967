import axios from "axios"
import { port } from "port"

const API = axios.create({ baseURL: port })

API.interceptors.request.use(req => {
    if (localStorage.getItem("user")) {
        req.headers.authorization = `Bearer ${JSON.parse(localStorage.getItem("user")).axiologin.token}`
    }
    return req
})

export const req12Character = (data) => API.post("api/characters/get12Characters", data)
export const req12CharacterTurn = (data) => {

    API.post("api/characters/turn12Characters", data)

}

export default {


    CreateApiS: (url, data, token) => {
        if (token) {
            token = JSON.parse(localStorage.getItem("user"))

        }
        return axios(`${port}${url}`, {
            headers: {
                'Authorization': 'Bearer ' + token.axiologin.token
            },

            method: 'POST',
            data
        })
    },
    CreateApiSS: (url, data) => {
        return axios(`${port}${url}`, {
            method: 'POST',
            data
        })

    },


    CreateApiSSS: (url, token) => {
        if (token) {
            token = JSON.parse(localStorage.getItem("user"))

        }
        return axios(`${port}${url}`, {
            headers: {
                'Authorization': 'Bearer ' + token.axiologin.token
            },

            method: 'POST',

        })
    },

    CreateApiGET: (url) => {
     
        return axios(`${port}${url}`, {
       
            method: 'GET',
        })
    },

    CreateApiGETToken: (url,token) => {
        if (token) {
            token = JSON.parse(localStorage.getItem("user"))
        }
        console.log(token);
        return axios(`${port}${url}`, {
            headers: {
                'Authorization': 'Bearer ' + token.axiologin.token
            },
            method: 'GET',
        })
    },

}