import User from 'api/User';
import RecaptchaF2A from 'Components/RecaptchaF2A';
import { fixedNumber } from 'core/hook/fixedNumber';
import { formatMoney } from 'core/hook/formarMoney';
import { SuccessMessage } from 'core/Message';
import { ErrorMessage } from 'core/Message';
import { useTranslate } from 'core/translate';
import { port } from 'port';
import React from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createOther } from 'redux/action/orderAction';
import { wallet } from 'redux/action/userAction';
import { createGethistorywidthdraw } from 'redux/action/userAction';
import { VALUE2FA } from 'redux/type';
import { TOKENRE } from 'redux/type';
import DataWithdraw from './DataWithdraw';

function InputWithdraw({ setContent, setIdBanking, idBanking }) {
    const { t } = useTranslate()
    const token = JSON.parse(localStorage.getItem('user'))
    const dispatch = useDispatch()

    let { profile } = useSelector(state => state.user)
    let { tokenRe, value2fa } = useSelector(state => state.other)
    let [amount, setAmount] = useState('')
    let twofa = profile?.data?.data?.enabled_twofa
    let [loadding, setLoadding] = useState(false)
    let [open, setOpen] = React.useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleChange = (e) => {
        let text = e.target.value
        let value = ""

        for (let index = 0; index < text.length; index++) {
            if (text[index].match(/[0-9]/))
                value += text[index]
        }
        setAmount(value)
    }

    const submit = async () => {
        setLoadding(true)
        if (tokenRe.length > 0 || value2fa) {
           try {
            let res = await User.createApiToken(`${port}api/user/widthdrawVND`, token?.axiologin?.token, { amount, idBanking, tokenRecaptcha: tokenRe, otp: value2fa })
            if (res) {
                SuccessMessage(res.data.message, t)
                dispatch(createGethistorywidthdraw(token?.axiologin?.token, 1, "VND", setLoadding, t))
                dispatch(wallet(token?.axiologin?.token))
                setContent('banking')
                if (tokenRe.length > 0) {
                    dispatch(createOther(TOKENRE, ''))
                    window.grecaptcha.reset();
                } else dispatch(createOther(VALUE2FA, ''))
            }
           } catch (error) {
            ErrorMessage(error?.response?.data?.message,t)
            // if (twofa == 0 && tokenRe == '') ErrorMessage("Please enter ReCAPTCHA", t)
            // else ErrorMessage("Please enter  2FA", t)
           }
        }
        setLoadding(false)
    }
    return (
        <>
            <div className='depositvnd-title withdrawcoin-title'>
                <p>{t('Withdraw VND')}</p>
            </div>
            <div className='depositvnd-input'>
                <p>{t("Amount")} </p>
                <div style={{ position: 'relative' }}>
                    <input type="text" onChange={handleChange} value={formatMoney(String(amount))} />
                </div>
            </div>
            <RecaptchaF2A />

            <div className="depositvnd-submit" style={{ display: 'flex', justifyContent: 'space-between' }}>
                {/* {showLoadding ? <div className="example" style={{ width: '100%' }}>
                    <Spin />
                </div> :
                    <button onClick={onSubmit}>Send</button>} */}
                <button style={{ width: '40%' }} onClick={handleOpen}>{t("Confirm")}</button>
                <button style={{ background: '#D1D0D0', width: '40%' }} onClick={() => setContent('banking')}>{t("Back")}</button>
            </div>
            <DataWithdraw handleClose={handleClose} open={open} amount={amount} submit={submit} loadding={loadding}/>
        </>
    );
}

export default InputWithdraw;