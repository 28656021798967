

import { GETFEEERC20 } from "redux/type";
import { GETFEESTF } from "redux/type";
import { LISTPACKAGE } from "redux/type";
import { BUYADLISTUSER, REDIRECT, ADDBUYP2P, BUYADLIST, BUYSELL, COUNTRY, DEPOSIT, EXCHANGE, GETPROFILE, GETWALLET, IMGSAU, IMGTRUOC, LENDING, OFF, POPUPWITHDRAW, QRCODE, SETQR, SUCCESS, TRANSACTIONFORM, TURNON, UPLOADKYC, WITHDRAW, ADDSELLP2P, SELLADLIST, USERADLIST, CREATEWALLET, CREATEWALLETTRC, CREATEWALLETCOIN, CREATEWALLETCOINTRC, SYMBOL, SELLADLISTUSER, SHADOW_LAYER_TOGGLE, SYMBOLTO, SYMBOLFROM, IMAGESYMBOLTO, IMAGESYMBOLFROM, CONVERTCOIN, SELLADLISTUSERPENDDING, BUYADLISTUSERPENDDING, DATANEWS, CREATEWALLETERC, GETBAKING, CHECKTRANSACTIONDEPOSITVND, HISTORYDEPOSITVND, GETLISTBANKINGUSER, HISTORYSWAP, HISTORYLENDDING, HISTORYSTAKING, GETBOS, HISTORYLENDING, GETHISTORYWIDTHDRAW, GETBPAY, GETTRC20, F2A, INTERESTLENDING, HISTORYDEPOSITCOIN, HISTORYTRANFER, HISTORYTRANSACTION, HISTORYTRANSACTIONTRC, HISTORYTRANSACTIONVND, GETNOTIFICATION, PARENT, HISTORYLENDINGTIME, IMAGESYMBOLTOBUSD, IMAGESYMBOLFROMBUSD, SYMBOLFROMBUSD, SYMBOLTOBUSD, USERINFORMATION, INDEXSYMBOLFROM, INDEXSYMBOLTO, INTERESTSTAKING, HISTORYWIDTHDRAWADRESS, GETFEE, GETFEEDIFFERENCE, GETREF, TOTALHISTORYSWAP, COMMISSIONSTAKING } from "../type";


let initState = {
    deposit: JSON.parse(localStorage.getItem('deposit')) || false,
    withdraw: JSON.parse(localStorage.getItem('withdraw')) || false,
    popupwithdraw: false,
    country: [],
    qr: [],
    setqr: 0,
    turnon2fa: [],
    off: JSON.parse(localStorage.getItem('authen2fa')) || false,
    imgtruoc: [],
    imgsau: [],
    uploadkyc: [],
    userinformation: false,
    profile: [],
    success: false,
    getwallet: JSON.parse(localStorage.getItem('wallet')) || [],
    createwallet: [],
    createwalleterc: [],
    createwallettrc: [],
    createwalletcointrc: false,
    createwalletcoin: false,
    lending: [],

    buysell: 0,
    //hinh thuc giao dich
    transactionform: JSON.parse(localStorage.getItem('TransactionForm')) || [],

    addbuyp2p: [],
    addsellp2p: [],
    buyadlist: [],
    selladlist: [],
    redirect: false,

    useradlist: JSON.parse(localStorage.getItem('useradlist')) || [],
    buyp2padlist: [],
    //historywidthdraw
    gethistorywidthdraw: [],
    historywidthdrawadress: [],

    symbol: JSON.parse(localStorage.getItem('symbol')) || "",
    //swap
    symbolto: "ETH",
    imagesymbolto: 'images/ETH.png',
    indexsymbolto: 1,
    symbolfrom: "BTC",
    imagesymbolfrom: 'images/BTC.png',
    indexsymbolfrom: 0,
    //swapbusd
    symboltobusd: "USDT",
    imagesymboltobusd: 'images/USDT.png',
    symbolfrombusd: "BUSD",
    imagesymbolfrombusd: 'images/BOS.png',
    //-----
    shadowLayers: false,
    //lich sử quảng cáo
    selladlistuser: [],
    buyadlistuser: [],
    selladlistuserpendding: [],
    buyadlistuserpendding: [],
    //getbaking
    getbaking: [],
    checktransactiondepositvnd: '',
    historydepositvnd: [],
    getlistbankinguser: [],
    //historyswap
    historyswap: [],
    totalhistoryswap: [],
    historylending: [],
    historystaking: [],
    //intereststaking
    intereststaking: [],
    commissionstaking: [],



    getbos: [],
    getbpay: [],

    //Get trc20
    gettrc20: [],
    //F2A
    f2a: false,
    interestlending: [],

    historydepositcoin: [],

    historytranfer: [],
    //historytransaction
    historytransaction: false,
    historytransactiontrc: false,

    historytransactionvnd: false,

    getnotification: [],
    parent: [],
    historylendingtime: [],

    //getfee
    getfee: 0.25,
    getfeeErc20: [],
    getfeedifference: [],
    getref: [],
    getfeestf: JSON.parse(localStorage.getItem('BurnSTF')) || [],

    //list staking
    listpackage: []



}

export default function authReducer(state = initState, action) {
    switch (action.type) {
        case DEPOSIT:
            localStorage.setItem('deposit', JSON.stringify(action.payload))
            return {
                ...state,
                deposit: action.payload,
            }
        case WITHDRAW:
            localStorage.setItem('withdraw', JSON.stringify(action.payload))
            return {
                ...state,
                withdraw: action.payload,
            }
        case POPUPWITHDRAW:
            return {
                ...state,
                popupwithdraw: action.payload,
            }

        case COUNTRY:
            return {
                ...state,
                country: action.payload
            }
        case QRCODE:
            return {
                ...state,
                qr: action.payload
            }
        case SETQR:
            let authen = localStorage.getItem('authen')
            return {
                ...state,
                setqr: authen
            }
        case TURNON:
            return {
                ...state,
                turnon2fa: action.payload,

            }

        case OFF:

            return {
                ...state,
                off: action.payload,

            }
        case IMGTRUOC:

            return {
                ...state,
                imgtruoc: action.payload,

            }
        case IMGSAU:

            return {
                ...state,
                imgsau: action.payload,

            }
        case UPLOADKYC:

            return {
                ...state,
                uploadkyc: action.payload,

            }
        case SUCCESS:

            return {
                ...state,
                success: action.payload,

            }
        //USERINFORMATION
        case USERINFORMATION:

            return {
                ...state,
                userinformation: action.payload,

            }

        //Profile
        case GETPROFILE:

            return {
                ...state,
                profile: action.payload,

            }
        case GETWALLET:

            return {
                ...state,
                getwallet: action.payload,

            }
        case CREATEWALLET:

            return {
                ...state,
                createwallet: action.payload,

            }
        case CREATEWALLETERC:


            return {
                ...state,
                createwalleterc: action.payload,

            }
        case CREATEWALLETTRC:

            return {
                ...state,
                createwallettrc: action.payload,

            }
        case CREATEWALLETCOINTRC:

            return {
                ...state,
                createwalletcointrc: action.payload,

            }
        case CREATEWALLETCOIN:

            return {
                ...state,
                createwalletcoin: action.payload,

            }

        case LENDING:

            return {
                ...state,
                lending: action.payload,

            }

        case BUYSELL:
            return {
                ...state,
                buysell: action.payload,
            }
        case TRANSACTIONFORM:
            return {
                ...state,
                transactionform: action.payload,
            }

        case ADDBUYP2P:
            return {
                ...state,
                addbuyp2p: action.payload,
            }
        case ADDSELLP2P:
            return {
                ...state,
                addsellp2p: action.payload,
            }
        case BUYADLIST:
            return {
                ...state,
                buyadlist: action.payload,
            }
        case SELLADLIST:
            return {
                ...state,
                selladlist: action.payload,
            }

        case REDIRECT:
            return {
                ...state,
                redirect: action.payload,
            }
        case USERADLIST:
            localStorage.setItem('useradlist', JSON.stringify(action?.payload))
            return {
                ...state,
                useradlist: action.payload,
            }
        case SYMBOL:
            localStorage.setItem('symbol', JSON.stringify(action?.payload))
            return {
                ...state,
                symbol: action.payload,
            }
        //swap

        case SYMBOLTO:
            return {
                ...state,
                symbolto: action.payload,
            }
        case IMAGESYMBOLTO:
            return {
                ...state,
                imagesymbolto: action.payload,
            }
        case INDEXSYMBOLTO:
            return {
                ...state,
                indexsymbolto: action.payload,
            }


        case IMAGESYMBOLFROM:
            return {
                ...state,
                imagesymbolfrom: action.payload,
            }

        case SYMBOLFROM:
            return {
                ...state,
                symbolfrom: action.payload,
            }
        case INDEXSYMBOLFROM:
            return {
                ...state,
                indexsymbolfrom: action.payload,
            }

        case SYMBOLTOBUSD:
            return {
                ...state,
                symboltobusd: action.payload,
            }
        case IMAGESYMBOLTOBUSD:
            return {
                ...state,
                imagesymboltobusd: action.payload,
            }


        case IMAGESYMBOLFROMBUSD:
            return {
                ...state,
                imagesymbolfrombusd: action.payload,
            }

        case SYMBOLFROMBUSD:
            return {
                ...state,
                symbolfrombusd: action.payload,
            }



        // case BUYP2PADLIST:

        //     return {
        //         ...state,
        //         buyp2padlist: action.payload,
        //     }

        // chuyển tiền user

        //lịch sử tạo quảng cáo bán
        case SELLADLISTUSER:
            return {
                ...state,
                selladlistuser: action.payload,
            }
        case BUYADLISTUSER:
            return {
                ...state,
                buyadlistuser: action.payload,
            }
        case SELLADLISTUSERPENDDING:
            return {
                ...state,
                selladlistuserpendding: action.payload,
            }
        case BUYADLISTUSERPENDDING:
            return {
                ...state,
                buyadlistuserpendding: action.payload,
            }
        //popup chọn tiền trong chuyển tiền
        // case SHADOW_LAYER_TOGGLE:
        //     return {
        //         ...state,
        //         shadowLayers: action.payload,
        //     }

        //GETBAKING
        case GETBAKING:

            return {
                ...state,
                getbaking: action.payload,

            }
        //CHECKTRANSACTIONDEPOSITVND
        case CHECKTRANSACTIONDEPOSITVND:

            return {
                ...state,
                checktransactiondepositvnd: action.payload,

            }
        //HISTORYDEPOSITVND
        case HISTORYDEPOSITVND:

            return {
                ...state,
                historydepositvnd: action.payload,

            }
        //GETLISTBANKINGUSER
        case GETLISTBANKINGUSER:

            return {
                ...state,
                getlistbankinguser: action.payload,

            }
        //HISTORYSWAP
        case HISTORYSWAP:

            return {
                ...state,
                historyswap: action.payload,

            }
        case TOTALHISTORYSWAP:

            return {
                ...state,
                totalhistoryswap: action.payload,

            }
        //HISTORYLENDING
        case HISTORYLENDING:

            return {
                ...state,
                historylending: action.payload,

            }
        //HISTORYLENDING
        case HISTORYLENDINGTIME:

            return {
                ...state,
                historylendingtime: action.payload,

            }
        //historystaking
        case HISTORYSTAKING:

            return {
                ...state,
                historystaking: action.payload,

            }
        //INTERESTSTAKING
        case INTERESTSTAKING:

            return {
                ...state,
                intereststaking: action.payload,

            }
        case COMMISSIONSTAKING:

            return {
                ...state,
                commissionstaking: action.payload,

            }
        //GETBOS
        case GETBOS:

            return {
                ...state,
                getbos: action.payload,

            }
        //GETHISTORYWIDTHDRAW
        case GETHISTORYWIDTHDRAW:
            return {
                ...state,
                gethistorywidthdraw: action.payload,

            }

        case HISTORYWIDTHDRAWADRESS:
            return {
                ...state,
                historywidthdrawadress: action.payload,

            }
        //GETBPAY
        case GETBPAY:
            return {
                ...state,
                getbpay: action.payload,

            }
        //GETTRC20
        case GETTRC20:
            return {
                ...state,
                gettrc20: action.payload,

            }
        //F2A
        case F2A:

            return {
                ...state,
                f2a: action.payload,

            }

        case INTERESTLENDING:

            return {
                ...state,
                interestlending: action.payload,

            }

        //historydepositcoin
        case HISTORYDEPOSITCOIN:

            return {
                ...state,
                historydepositcoin: action.payload,

            }

        //historytranfer
        case HISTORYTRANFER:

            return {
                ...state,
                historytranfer: action.payload,

            }

        //HISTORYTRANSACTION
        case HISTORYTRANSACTION:
            localStorage.setItem("historytransaction", action.payload)

            return {
                ...state,
                historytransaction: action.payload,

            }

        case HISTORYTRANSACTIONTRC:
            localStorage.setItem("historytransaction", action.payload)

            return {
                ...state,
                historytransactiontrc: action.payload,

            }
        case HISTORYTRANSACTIONVND:
            localStorage.setItem("historytransaction", action.payload)

            return {
                ...state,
                historytransactionvnd: action.payload,

            }
        //GETNOTIFICATION
        case GETNOTIFICATION:
            return {
                ...state,
                getnotification: action.payload,

            }

        //PARENT
        case PARENT:
            return {
                ...state,
                parent: action.payload,

            }
        //GETREF
        case GETREF:

            return {
                ...state,
                getref: action.payload,

            }
        //GETFEE

        case GETFEE:

            return {
                ...state,
                getfee: action.payload,

            }
        case GETFEEERC20:

            return {
                ...state,
                getfeeErc20: action.payload,

            }
        case GETFEEDIFFERENCE:

            return {
                ...state,
                getfeedifference: action.payload,

            }
        case GETFEESTF:
            localStorage.setItem('BurnSTF', JSON.stringify(action?.payload))
            return {
                ...state,
                getfeestf: action.payload,

            }

        //LISTPACKAGE staking
        case LISTPACKAGE:

            return {
                ...state,
                listpackage: action.payload,

            }
        default:
            return state;
    }
}