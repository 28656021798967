import { createStore, combineReducers, applyMiddleware, compose } from 'redux'
import authReducer from "./authReducer";
import userReducer from "./userReducer";
import coinReducer from "./coinReducer";
import searchReducer from "./searchReducer";
import coinConverReducer from './coinConverReducer';
import otherReducer from './otherReducer';
import toggleShadowReducer from './toggleShadowReducer';
import adminReducer from './adminReducer';
import homeReducer from './homeReducer';
import depositReducer from './depositReducer';
import withdrawReducer from './withdrawReducer';
import loaddingReducer from './loaddingReducer';
import walletReducer from './walletReducer';
import minningReducer from './minningReducer';
import kycLevelReducer from './kycLevelReducer';
import notifyReducer from './notifyReducer';
import buytbcReducer from './buytbcReducer';
import listtingReducer from './listtingReducer';





let reducer = combineReducers({
    auth: authReducer,
    user: userReducer,
    coin: coinReducer,
    search: searchReducer,
    convert: coinConverReducer,
    other: otherReducer,
    shadowLayer: toggleShadowReducer,
    admin: adminReducer,
    home: homeReducer,
    deposit: depositReducer,
    withdraw: withdrawReducer,
    loadding: loaddingReducer,
    wallet: walletReducer,
    minning: minningReducer,
    kyc: kycLevelReducer,
    notify:notifyReducer,
    buytbc:buytbcReducer,
    listting:listtingReducer


})


const middleware = ({ dispatch }) => next => action => {
    if (typeof action === 'function') {
        return action(dispatch)

    } else {

        next(action)

    }

}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
let store = createStore(reducer, composeEnhancers(applyMiddleware(middleware)))
export default store