import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { useTranslate } from '../core/translate'




export default function ContactUs() {
    let { t } = useTranslate()
    const dispatch = useDispatch()


    return (
        <>
            <section id="contactus1" className="theoppening pb-5">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="thetitle smaller text-caseinitial"> {t("Welcome to Swap tobe")} <br />  </div>
                        </div>

                        <div className="col-12">
                            <nav aria-label="breadcrumbC">
                                <ol className="breadcrumbC  m-0">
                                    <li className="breadcrumbC-item">
                                        <a href="/p2p">Home /</a>
                                    </li>

                                    <li className="breadcrumbC-item active" aria-current="page" style={{ marginLeft: '10px' }}> {t("Contact Us")} </li>
                                </ol>
                            </nav>
                        </div>

                    </div>
                </div>
            </section>


            <section id="contactus2" className="py-5">
                <div className="container pb-5">
                    <div className="row justify-content-center">
                        <div className="col-12 col-lg-4 mb-3">
                            <div className="card h-100">
                                <div className="card-body text-center row align-content-center">
                                    <div className="contact_img"><img src="/img/EGTECHGROUPlicense.jpg" alt="" /></div>

                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-4 mb-3">
                            <div className="card h-100">
                                <div className="card-body text-center row align-content-center">

                                    <div className="col-12 card-text  mb-3" style={{ color: 'black', lineHeight: '2' }}>
                                        <p>
                                            <strong> Headquarters (US):</strong> <br /> EG TECH GROUP, LLC<br /> 1148 LOCHILOMAND DRIVE HARVEY, LA, 70058 <br /> Website: egtechgroup.us <br /> Email: info@swaptobe.com
                                        </p>
                                    </div>
                                    {/* <div className="col-12 card-text text-gray">
                                        <p>
                                            <strong> Representative Office (Vietnam):</strong> <br />294 Khuong Viet, Phu Trung Ward, <br /> Tan Phu Dict, HCM
                                        </p>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6 mb-3">
                            <form>
                                <div className="mb-3">
                                    <input type="text" className="form-control form-control-lg border-0" placeholder="Full name" />
                                </div>
                                <div className="mb-3">
                                    <input type="email" className="form-control form-control-lg border-0" placeholder="Email" />
                                </div>
                                <div className="mb-3">
                                    <textarea className="form-control form-control-lg border-0" rows={5} cols={80} placeholder="Note" defaultValue={""} />
                                </div>
                                <button type="submit" className="btn btn-vndt w-100"> Send </button>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
