import React from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { ShowLoadding } from 'redux/reducer/loaddingReducer';
import { SymbolHistory } from 'redux/reducer/withdrawReducer';
import { gethistoryWin } from '../Components/GetHistory';
import { gethistoryBnb } from '../Components/GetHistory';
import { gethistoryshib } from '../Components/GetHistory';
import { gethistoryusdt } from '../Components/GetHistory';

function DepositCoin({ symbol }) {
    const dispatch = useDispatch()
    const { createwallettrc } = useSelector(state => state.deposit)
    const { dataWithdrawCoin, symbolHistory } = useSelector(state => state.withdraw)
    const token = JSON.parse(localStorage.getItem('user'))
    useEffect(() => {
        if (symbolHistory) {
            if (dataWithdrawCoin?.name == "USDT") gethistoryusdt(token?.axiologin?.token, 1, symbolHistory, dispatch)
            else if (dataWithdrawCoin?.name == "SHIB") gethistoryshib(token?.axiologin?.token, 1, symbolHistory, dispatch)
            else if (dataWithdrawCoin?.name == "BNB") gethistoryBnb(token?.axiologin?.token, 1, symbolHistory, dispatch)
            else if (dataWithdrawCoin?.name == "WIN") gethistoryWin(token?.axiologin?.token, 1, symbolHistory, dispatch)
        }
    }, [symbolHistory])
    const HandleChange = (e) => {
        dispatch(ShowLoadding(true))
        dispatch(SymbolHistory(e))

    }
    return (
        <>
            <div className='depositvnd-input withdraw-type'>

                {createwallettrc && symbol != "USDT.TRC20" && symbol != "USDT" && symbol != "USDT.ERC20" && symbol != "BNB" && symbol != "SHIB" && symbol != "WIN" &&
                    <a className="active">TRC20</a>
                }
                {symbol === "USDT.TRC20" || symbol === "USDT" || symbol == "USDT.ERC20" ?
                    <>
                        <a className={`${symbolHistory === "USDT.TRC20" && "active"}`} onClick={() => HandleChange("USDT.TRC20")} >TRC20</a>
                        <a className={`${symbolHistory === "USDT.ERC20" && "active"}`} onClick={() => HandleChange("USDT.ERC20")}>ERC20</a>
                        <a className={`${symbolHistory === "USDT.BEP20" && "active"}`} onClick={() => HandleChange("USDT.BEP20")} >BEP20</a>
                    </> : ''}

                {symbol == 'BNB' ? <>
                    <a className={`${symbolHistory == "BNB" && "active"}`} onClick={() => HandleChange("BNB")} >BNB</a>
                    <a className={`${symbolHistory == "BNB.BSC" && "active"}`} onClick={() => HandleChange("BNB.BSC")} >BSC</a>
                    <a className={`${symbolHistory == "BNB.ERC20" && "active"}`} onClick={() => HandleChange("BNB.ERC20")} >ERC20</a>
                </> : ''}
                {symbol == 'SHIB' ? <>
                    <a className={`${symbolHistory == "SHIB.BEP20" && "active"}`} onClick={() => HandleChange("SHIB.BEP20")} >BEP20</a>
                    <a className={`${symbolHistory == "SHIB" && "active"}`} onClick={() => HandleChange("SHIB")} >ERC20</a>
                </> : ''}
                {symbol == 'WIN' ? <>
                    <a className={`${symbolHistory == "WIN.BEP20" && "active"}`} onClick={() => HandleChange("WIN.BEP20")} >BEP20</a>
                </> : ''}
            </div>
        </>
    );
}

export default DepositCoin;