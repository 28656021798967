import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Pagination, Spin } from 'antd';
import Select from 'react-select'
import { useTranslate } from '../../core/translate';
import { port } from '../../port';

import { createBuypackage, createGetHistoryCommission, createHistorystaking, createIntereststaking, createNapCoin, createNapCoinERC, createNapCoinTRC } from '../../redux/action/userAction';




import { GetListPackage } from 'redux/action/userAction';
import Package from './Components/Package';
import { GetFee } from 'redux/action/userAction';



function Staking() {
    let token = JSON.parse(localStorage.getItem('user'))
    let { t } = useTranslate()
    useEffect(() => {

        document.title = "Earn Tobe"
        return () => {
            document.title = "Swap tobe"
        }

    }, [t]);
    let { historystaking, intereststaking, commissionstaking } = useSelector(state => state.user)
    const [menu, setMenu] = useState("history")
    const formatDateTime = (str, char) => {
        let strSplit = str.split(char)
        let value1 = `${Number(strSplit[0]) < 10 ? `0${strSplit[0]}` : strSplit[0]}`
        let value2 = `${Number(strSplit[1]) < 10 ? `${char}0${strSplit[1]}` : `${char}${strSplit[1]}`}`
        let value3 = `${Number(strSplit[2]) < 10 ? `${char}0${strSplit[2]}` : `${char}${strSplit[2]}`}`

        return value1 + value2 + value3
    }
    useEffect(() => {
        window.scrollTo(0, 0)
        dispatch(createIntereststaking(token?.axiologin?.token, 1))
        dispatch(createHistorystaking(token?.axiologin?.token, 1))
        dispatch(createGetHistoryCommission(token?.axiologin?.token, 1))
        dispatch(GetListPackage())
        dispatch(GetFee('STAKING'))

    }, [])
    function truncate(value, precision) {
        var step = Math.pow(10, precision || 0);
        var temp = Math.trunc(step * value);

        var a = temp / step;
        return a.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    const dispatch = useDispatch()
    let [page, setPage] = useState(1)
    function handlePageStaking(page) {
        setPage(page)
        dispatch(createHistorystaking(token?.axiologin?.token, page))
    }

    function handlePageInterestStaking(page) {
        setPage(page)
        dispatch(createIntereststaking(token?.axiologin?.token, page))
    }

    function handlePageCommissionstaking(page) {
        setPage(page)
        dispatch(createGetHistoryCommission(token?.axiologin?.token, page))
    }

    return (
        <div style={{ background: " url('/img/Background_signup.png') 0% 0% / 100% 100% no-repeat", }}>
            <div style={{ paddingTop: "50px" }}>
                <Package />
                <div className="history-staking">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="history-staking-container">

                                    <div className="header" >
                                        <span className={`${menu === "interest" && "active"}`} style={{ textAlign: 'center' }} onClick={() => setMenu("interest")}>{t("Interest Earn Tobe")}</span>
                                        <span className={`${menu === "history" && "active"}`} style={{ textAlign: 'center' }} onClick={() => setMenu("history")}
                                        >{t("History Earn Tobe")}</span>

                                        <span className={`${menu === "commissions" && "active"}`} style={{ textAlign: 'center' }} onClick={() => setMenu("commissions")}
                                        >{t("Commissions Earn Tobe")}</span>
                                    </div>

                                    {menu == 'history' &&
                                        <div className="content">

                                            <div className="flex-container">
                                                <div className="title" style={{ textAlign: 'center' }}>
                                                    <div className="flex-1">ID</div>
                                                    <div className="flex-2">{t("Buy")}</div>
                                                    <div className="flex-2">{t("Package")}</div>
                                                    <div className="flex-2">{t("Time")}</div>
                                                </div>
                                                {historystaking?.data?.data?.total == 0 ?
                                                    <div className="content" style={{ background: '#fff', padding: '20px' }}>
                                                        <div className="interest-list">
                                                            <div style={{ color: "#000", fontSize: '18px', fontStyle: 'italic', textAlign: 'center' }}>
                                                                {t("No transactions yet")}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div style={{ textAlign: 'center' }}>
                                                        {historystaking?.data?.data?.array?.map((e, i) => {
                                                            return (
                                                                <div className="item">
                                                                    <div className="flex-1">{i + 1 + (page - 1) * 5}</div>
                                                                    <div className="flex-2 color-red" >- {truncate(e?.amountcoin, 6).replace(/,/g, '')} {e?.typecoin?.toUpperCase()} ({e?.price}$)</div>
                                                                    <div className="flex-2">{e?.month} {t("Months")}</div>
                                                                    <div className="flex-2">{formatDateTime(e?.createTime.substring(0, 9), "/")} {formatDateTime(e?.createTime.substring(10, 17), ":")}</div>
                                                                </div>
                                                            )
                                                        })}
                                                        <Pagination className="center"
                                                            style={{ marginTop: '20px', paddingBottom: '20px' }}
                                                            defaultCurrent={1}
                                                            defaultPageSize={5} //default size of page
                                                            onChange={handlePageStaking}
                                                            total={historystaking?.data?.data?.total} //total number of card data available
                                                        />
                                                    </div>}


                                            </div>


                                        </div>}
                                    {menu == "interest" &&
                                        <div className="content">

                                            <div className="flex-container">
                                                <div className="title" style={{ textAlign: 'center' }}>
                                                    <div className="flex-1">ID</div>
                                                    <div className="flex-2">{t("Package")}</div>
                                                    <div className="flex-2">{t("Interest")} (BUSD)</div>
                                                    <div className="flex-2">{t("Time")}</div>
                                                </div>
                                                {intereststaking?.data?.data?.total == 0 ?
                                                    <div className="content" style={{ background: '#fff', padding: '20px' }}>
                                                        <div className="interest-list">
                                                            <div style={{ color: "#000", fontSize: '18px', fontStyle: 'italic', textAlign: 'center' }}>
                                                                {t("Updating")}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div style={{ textAlign: 'center' }}>
                                                        {intereststaking?.data?.data?.array?.map((e, i) => {
                                                            return (
                                                                <div className="item">
                                                                    <div className="flex-1">{i + 1 + (page - 1) * 5}</div>
                                                                    <div className="flex-2" >{e?.month} {t("Months")}</div>
                                                                    <div className="flex-2 color-red">{e?.interest} </div>
                                                                    <div className="flex-2">{formatDateTime(e?.created_at.substring(0, 9), "/")} {formatDateTime(e?.created_at.substring(10, 17), ":")}</div>
                                                                </div>
                                                            )
                                                        })}
                                                        <Pagination className="center"
                                                            style={{ marginTop: '20px', paddingBottom: '20px' }}
                                                            defaultCurrent={1}
                                                            defaultPageSize={5} //default size of page
                                                            onChange={handlePageInterestStaking}
                                                            total={intereststaking?.data?.data?.total} //total number of card data available
                                                        />
                                                    </div>
                                                }


                                            </div>


                                        </div>
                                    }

                                    {menu == "commissions" &&
                                        <div className="content">

                                            <div className="flex-container">
                                                <div className="title" style={{ textAlign: 'center' }}>
                                                    <div className="flex-2">{t("Username")}</div>
                                                    <div className="flex-1">{t("Package")}</div>

                                                    <div className="flex-2">{t("Commissions")} </div>
                                                    <div className="flex-2">{t("Time")}</div>
                                                </div>
                                                {commissionstaking?.data?.data?.total == 0 ?
                                                    <div className="content" style={{ background: '#fff', padding: '20px' }}>
                                                        <div className="interest-list">
                                                            <div style={{ color: "#000", fontSize: '18px', fontStyle: 'italic', textAlign: 'center' }}>
                                                                {t("Updating")}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div style={{ textAlign: 'center' }}>
                                                        {commissionstaking?.data?.data?.array?.map((e, i) => {
                                                            return (
                                                                <div className="item">
                                                                    <div className="flex-2" >{e?.userName} </div>
                                                                    <div className="flex-1">{e?.price}$</div>

                                                                    <div className="flex-2 color-red">{e?.bonus} {e?.type}</div>
                                                                    <div className="flex-2">{formatDateTime(e?.createTime.substring(0, 9), "/")} {formatDateTime(e?.createTime.substring(10, 17), ":")}</div>
                                                                </div>
                                                            )
                                                        })}
                                                        <Pagination className="center"
                                                            style={{ marginTop: '20px', paddingBottom: '20px' }}
                                                            defaultCurrent={1}
                                                            defaultPageSize={5} //default size of page
                                                            onChange={handlePageCommissionstaking}
                                                            total={commissionstaking?.data?.data?.total} //total number of card data available
                                                        />
                                                    </div>
                                                }


                                            </div>


                                        </div>
                                    }


                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </div >





        </div>
    );
}

export default Staking;