import reduxToolkit from "../../core/reduxToolkit"

const initialState = {
    showWithdrawCoin: '',
    dataWithdrawCoin: '',
    typeWithdrawCoin: '',
    symbolHistory: '',
    dataWithdrawVnd: ''
}






let { reducer, action, TYPE } = reduxToolkit({
    name: 'withdraw',
    initialState,
    reducers: {

        ShowWithdraw: function (state, action) {
            return {
                ...state,
                showWithdrawCoin: action.payload
            }
        },
        DataWithdrawCoin: function (state, action) {
            return {
                ...state,
                dataWithdrawCoin: action.payload
            }
        },
        DataWithdrawVnd: function (state, action) {
            return {
                ...state,
                dataWithdrawVnd: action.payload
            }
        },
        TypeWithdrawCoin: function (state, action) {
            return {
                ...state,
                typeWithdrawCoin: action.payload
            }
        },
        SymbolHistory: function (state, action) {
            return {
                ...state,
                symbolHistory: action.payload
            }
        },




    }
})

export default reducer
export const WITHDRAW = TYPE
export const { ShowWithdraw, DataWithdrawCoin, TypeWithdrawCoin, SymbolHistory, DataWithdrawVnd } = action