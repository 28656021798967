import { Spin } from 'antd';
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, Redirect } from 'react-router-dom';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import Swal from 'sweetalert2';
import User from '../../api/User';

import { useTranslate } from '../../core/translate';
import { port } from '../../port';
import { createA, getprofile, kyc } from '../../redux/action/userAction'
import { UPLOADKYC } from '../../redux/type';





function Camera() {
    const videoRef = useRef(null);
    const photoRef = useRef(null);
    const [hasPhoto, setHasPhoto] = useState(false)
    let { imgtruoc, imgsau, } = useSelector(state => state.user)
    const { axiologin } = useSelector(state => state.auth)
    let { t } = useTranslate()
    useEffect(() => {
        let tab = t("KYC")
        document.title = tab
        return () => {
            document.title = "Swap tobe"
        }
    }, [t]);
    let token = JSON.parse(localStorage.getItem('user'))
    useEffect(async () => {
        if (axiologin) {
            dispatch(getprofile(token.axiologin.token))
        }
    }, [])

    const { profile } = useSelector(state => state.user)

    const verified = profile?.data?.data?.verified
    let { userinformation } = useSelector(state => state.user)





    function dataURLtoFile(dataurl, filename) {

        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, { type: mime });
    }

    let [snak, setSnak] = useState(true)

    const takePhoto = async () => {
        setSnak(true)

        const width = 200;
        const height = 200;
        let video = videoRef.current;
        let photo = photoRef.current
        photo.width = width
        photo.height = height
        let ctx = photo.getContext('2d');
        ctx.drawImage(video, 0, 0, width, height);
        setHasPhoto(true)
        var canvas = document.getElementById("thecanvas");
        var img = new Image;
        img.src = canvas.toDataURL()
        //Usage example:
        let time = new Date()
        var file = dataURLtoFile(img.src, `${time.getTime()}.png`);

    }






    const closePhoto = () => {
        setSnak(false)
        setHasPhoto(false)
    }

    let [startCamera, setStateCamera] = useState(false)

    let [none, setNone] = useState(true)

   

    let [upload, setUpload] = useState(false)

    function clickUpload() {
        setUpload(true)
        setNone(false)
    }

    let [image, setImage] = useState()
    let [active, setActive] = useState(false)
    function VerificationImg(e) {
        setImage((e.target.files[0]));
        setActive(true)
    }
    function back() {
        setNone(true)
        setStateCamera(false)
        setUpload(false)
    }
    let [wait, setWait] = useState(false)


    let history = useHistory()
    const dispatch = useDispatch()
    const [loadding, setLoadding] = useState(false)
    async function Clicknext() {
        setLoadding(true)
        if (active) {

            try {
                const data = new FormData();
                data.append("photo", imgtruoc);
                data.append("photo", imgsau);
                data.append("photo", image);
                data.append("userid", axiologin.id);
                data.append("phone", userinformation.phone);
                data.append("fullname", userinformation.fullname);
                data.append("address", userinformation.address);
                data.append("CCCD", userinformation.CCCD);
                data.append("type", 0);

                const res = await axios({
                    url: `${port}api/uploadKyc`,
                    method: `POST`,
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                    data,
                });
            
                if(res) {
                    dispatch(getprofile(token.axiologin.token))
                    history.push('/verifiedKyc')
                }  
           



            } catch (error) {

            }

            // dispatch(kyc(imgtruoc, imgsau, image, axiologin.id))
            setStateCamera(false)
            setUpload(false)
        }
        else {
            Swal.fire({
                title: t("please upload pictures"),
                icon: 'error',
                timer: 2000
            })
        }
        setLoadding(false)
    }
    function next() {
        setWait(true)
        setStateCamera(false)
        setUpload(false)
    }


    let { success } = useSelector(state => state.user)
    if (!success) {
        return <Redirect to='/kyc-image' />
    }
    if (!axiologin) {
        return <Redirect to='/form' />
    }
    if (verified == 1) {
        return <Redirect to='/verifiedKyc' />
    }

    //await
    if (verified == 2) {
        return <Redirect to='/verifiedKyc' />
    }

    return (
        <>
            {none ? <div className="faceverification" style={{ backgroundColor: "#fff" }}>
                <div className="container">
                    <div className='faceverifications greenliner'>
                        <div className='faceverification_title'>
                            <h4>Selfie Image</h4>
                        </div>
                        <div className='faceverification_main'>
                            {/* <div className='faceverification_main-item '>
                                <div className="faceverification_camera-title">
                                    {t("Directly taking pictures on the web")}
                                </div>
                                <button onClick={handlOpenCamera} className='camera'>App Carema</button>
                            </div> */}
                            <div className="faceverification_main-item ">
                                <div className="faceverification_input-title" >
                                    {t("Select an existing photo")}
                                </div>
                                <button onClick={clickUpload} className='input'>Upload</button>
                                {/* <NavLink to='/upload' className='input'>Upload</NavLink> */}

                            </div>
                        </div>
                    </div>
                </div>
            </div> : ''}


            {startCamera ? <div className="AppCamera" style={{ backgroundColor: "#fff" }}>
                <div className="container">
                    <div className="camera greenliner ">
                        <div className='camera_title'>
                            <h4>Take a self portrait</h4>
                        </div>
                        <div style={{ textAlign: 'center' }}>
                            <video ref={videoRef}></video>
                        </div>

                        <div className='camera_des'>
                            <div className='camera_des-item'>
                                <div className="css-rh6rhj">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" className="css-kddq4d">
                                        <path d="M9 18.83l-6-6.01 1.41-1.41L9 16 19.18 5.82l1.42 1.42L9 18.83z" fill="#087972" /></svg>
                                </div>
                                <div className="css-d4wfic">{t("Take a self-portrait with a normal expression")}</div>
                            </div>
                            <div className='camera_des-item'>
                                <div className="css-rh6rhj">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" className="css-kddq4d">
                                        <path d="M9 18.83l-6-6.01 1.41-1.41L9 16 19.18 5.82l1.42 1.42L9 18.83z" fill="#087972" /></svg>
                                </div>
                                <div className="css-d4wfic">{t("Make sure the whole face is clearly visible, centered and eyes open")}</div>
                            </div>
                            <div className='camera_des-item'>
                                <div className="css-rh6rhj">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" className="css-kddq4d">
                                        <path d="M9 18.83l-6-6.01 1.41-1.41L9 16 19.18 5.82l1.42 1.42L9 18.83z" fill="#087972" /></svg>
                                </div>
                                <div className="css-d4wfic">{t("Ensure adequate lighting")}</div>
                            </div>
                        </div>
                        <div className='camera_des left'>
                            <div className='camera_des-item'>
                                <div className="css-rh6rhj">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" className="css-10ls1ot"><path d="M10.586 12L4.293 5.707 5 5l.707-.707L12 10.586l6.293-6.293L19 5l.707.707L13.414 12l6.293 6.293-1.414 1.414L12 13.414l-6.293 6.293L5 19l-.707-.707L10.586 12z" fill="rgb(246, 70, 93)" /></svg>
                                </div>
                                <div className="css-d4wfic">{t("Do not crop the ID image or use the ID screenshot")}</div>
                            </div>
                            <div className='camera_des-item'>
                                <div className="css-rh6rhj">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" className="css-10ls1ot"><path d="M10.586 12L4.293 5.707 5 5l.707-.707L12 10.586l6.293-6.293L19 5l.707.707L13.414 12l6.293 6.293-1.414 1.414L12 13.414l-6.293 6.293L5 19l-.707-.707L10.586 12z" fill="rgb(246, 70, 93)" /></svg>
                                </div>
                                <div className="css-d4wfic">{t("Do not cover or change parts of the face (No hats/beauty pictures/filters/headwear)")}</div>
                            </div>
                        </div>
                        <div style={{ textAlign: 'center', marginTop: '25px' }}>
                            <a href='/camera' className='back' >BACK!</a>
                            <button className='snap' onClick={takePhoto}>SNAP!</button>
                        </div>
                    </div>
                    <div className={`result greenliner ` + (hasPhoto ? 'hasPhoto' : 'disbleHasphoto')}>
                        <div>
                            <canvas ref={photoRef} id="thecanvas"></canvas>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '30px' }}>
                            <button className='back' onClick={closePhoto}>CLOSE!</button>
                            <button className='snap' onClick={next}>NEXT!</button>
                        </div>
                    </div>



                </div>
            </div> : ''
            }

            {
                upload ? <div style={{ backgroundColor: "#fff" }}>
                    <div className="container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <div className="cameraUpload ">
                            <div className="container">
                                <div className='camera_title' style={{ marginBottom: '35px' }}>
                                    <h4 style={{ color: '#fff' }}>{t("Intermediate verification")}</h4>
                                </div>
                                <div className="ImageVerification_mains">
                                    <div className="ImageVerification_main">
                                        <div className="ImageVerification_main-text">
                                            <div>
                                                <svg width="16" height="16" viewBox="0 0 14 14" fill="#fff"><defs><path id="add_svg__a" d="M7.75 6.25v-6h-1.5v6h-6v1.5h6v6h1.5v-6h6v-1.5z"></path></defs><use fill="#fff" href="#add_svg__a" fill-rule="evenodd"></use></svg>
                                                <p>{t("Upload pictures")}</p>
                                            </div>
                                        </div>
                                        <div className='ImageVerification_input'>
                                            <input type="file" onChange={VerificationImg} />
                                        </div>
                                        {active ? <div className='ImageVerification_img'>
                                            <img src={URL.createObjectURL(image)} />
                                        </div> : ''
                                        }
                                    </div>

                                </div>
                                <div className="ImageVerification_des">
                                    <div>                                    <p>{t("Upload a .JPG/.JPEG/.PNG file no larger than 5M")}</p>
                                        <div className="camera_des-item"><div className="css-rh6rhj"> <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" className="css-kddq4d">
                                            <path d="M9 18.83l-6-6.01 1.41-1.41L9 16 19.18 5.82l1.42 1.42L9 18.83z" fill="#087972" /></svg></div><div className="css-d4wfic">Do chính phủ cấp</div></div>
                                        <div className="camera_des-item"><div className="css-rh6rhj"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" className="css-kddq4d">
                                            <path d="M9 18.83l-6-6.01 1.41-1.41L9 16 19.18 5.82l1.42 1.42L9 18.83z" fill="#087972" /></svg></div><div className="css-d4wfic">Giấy tờ nguyên gốc có kích thước đầy đủ, chưa qua chỉnh sửa</div></div>
                                        <div className="camera_des-item"><div className="css-rh6rhj"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" className="css-kddq4d">
                                            <path d="M9 18.83l-6-6.01 1.41-1.41L9 16 19.18 5.82l1.42 1.42L9 18.83z" fill="#087972" /></svg></div><div className="css-d4wfic">Đặt giấy tờ trên nền đơn sắc</div></div>
                                        <div className="camera_des-item"><div className="css-rh6rhj"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" className="css-kddq4d">
                                            <path d="M9 18.83l-6-6.01 1.41-1.41L9 16 19.18 5.82l1.42 1.42L9 18.83z" fill="#087972" /></svg></div><div className="css-d4wfic">Hình ảnh có màu, đủ ánh sáng, có thể đọc được</div></div>
                                        <div className="camera_des-item"><div className="css-rh6rhj"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" className="css-10ls1ot"><path d="M10.586 12L4.293 5.707 5 5l.707-.707L12 10.586l6.293-6.293L19 5l.707.707L13.414 12l6.293 6.293-1.414 1.414L12 13.414l-6.293 6.293L5 19l-.707-.707L10.586 12z" fill="rgb(246, 70, 93)" /></svg></div><div className="css-d4wfic">Không chấp nhận hình ảnh trắng đen</div></div>
                                        <div className="camera_des-item"><div className="css-rh6rhj"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" className="css-10ls1ot"><path d="M10.586 12L4.293 5.707 5 5l.707-.707L12 10.586l6.293-6.293L19 5l.707.707L13.414 12l6.293 6.293-1.414 1.414L12 13.414l-6.293 6.293L5 19l-.707-.707L10.586 12z" fill="rgb(246, 70, 93)" /></svg></div><div className="css-d4wfic">Không chấp nhận giấy tờ đã qua chỉnh sữa hoặc hết hạn</div></div>
                                    </div>

                                    <div className='ImageVerification_svg'>
                                        <img src="img/ps-1.png" alt="" />
                                    </div>
                                </div>
                                <div className="ImageVerification_bottom">
                                    <div className="ImageVerification_bottom-button">
                                        <div onClick={back} className="ImageVerification_button" style={{ backgroundColor: "#5ea192" }}>
                                            <a >{t("Cancel")}</a>
                                        </div>
                                        {loadding ? <div className="example" style={{ margin: '0' }}>
                                            <Spin />
                                        </div> :
                                            <div onClick={Clicknext} className="ImageVerification_button" style={{ backgroundColor: '#FBBC05' }}>
                                                <a >{t("Next")}</a>
                                            </div>}

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div> : ''
            }
            {wait ?
                <div style={{ height: '900px', backgroundColor: "#fff", display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                    <div className="qs-answer-section wait">
                        <h4 className="ng-binding">{t("Identity verification pending")}</h4>
                        <span className="ng-binding" style={{ color: '#000' }}>{t("We are checking your verified identity. We will send you the results to your inbox")}</span>
                        <div className="btn-group">
                            <a href="/" onclick="window.location.href='/landing.html'" className="greenliner btn btn-default ng-binding">{t("Go back to the main page")}</a>
                        </div>
                    </div>
                </div> : ''
            }
        </>


    )
}
export default React.memo(Camera)