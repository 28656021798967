
import { createA } from "../redux/action/userAction"
import { COUNTRY } from "../redux/type"

import User from "./User"

export const Country = (res) => {

    return async dispatch => {
        try {
            let res = await User.country()


            dispatch(createA(COUNTRY, res.data.data))

        } catch (error) {

        }

    }
}