import { SEARCHUSER } from "redux/type";
import { ADMINSEARCH } from "redux/type";
import { DATAKYC } from "redux/type";
import { GETKYCUSERPENDDING } from "redux/type";
import { ADGETALLFEE, ADUPDATEFEE, ADUPDATEPRICECOIN, GETBUYLISTP2PADMIN, GETBUYLISTP2PADMINPENDING, GETLISTDESPOSITVNDADMIN, GETLISTWIDTHDRAWCOIN, GETLISTWIDTHDRAWCOINPENDING, GETLISTWIDTHDRAWVNDADMIN, GETLISTWIDTHDRAWVNDADMINPENDING, GETSELLLISTP2PADMIN, GETSELLLISTP2PADMINPENDING, GETALLUSER, STATISTICAL } from "../type";


let initState = {
    adminUpdatefee: [],
    adminUpdatePriceCoin: [],
    adminGetallfee: [],
    //list tạo quảng cáo admin
    getbuylistp2padmin: [],
    getselllistp2padmin: [],
    //list tạo quảng cáo admin pending
    getbuylistp2padminpending: [],
    getselllistp2padminpending: [],
    //list nạp vnd
    getlistdespositvndadmin: [],
    //list rut vnd
    getlistwidthdrawvndadmin: [],
    //list rút vnd pending
    getlistwidthdrawvndadminpending: [],
    
    // list rut coin
    getlistwidthdrawcoin: [],
    //list rut coin pending
    getlistwidthdrawcoinpending: [],
    //Kyc admin
    getkycuserpendding: [],

    //getalluser
    getalluser: [],

    //search
    searchuser: [],

    //datakyc
    datakyc: [],
    adminsearch: false,
    statistical: [],

}


export default function adminReducer(state = initState, action) {
    switch (action.type) {
        case ADGETALLFEE:

            return {
                ...state,
                adminGetallfee: action?.payload,
            }
        case ADUPDATEFEE:

            return {
                ...state,
                adminUpdatefee: action?.payload,
            }
        case ADUPDATEPRICECOIN:

            return {
                ...state,
                adminUpdatePriceCoin: action?.payload,
            }

        //list tạo quảng cáo admin

        case GETBUYLISTP2PADMIN:

            return {
                ...state,
                getbuylistp2padmin: action?.payload,
            }
        case GETSELLLISTP2PADMIN:

            return {
                ...state,
                getselllistp2padmin: action?.payload,
            }
        //list tạo quảng cáo admin pending


        case GETBUYLISTP2PADMINPENDING:

            return {
                ...state,
                getbuylistp2padminpending: action?.payload,
            }
        case GETSELLLISTP2PADMINPENDING:

            return {
                ...state,
                getselllistp2padminpending: action?.payload,
            }

        //danh sach nap vnd
        case GETLISTDESPOSITVNDADMIN:

            return {
                ...state,
                getlistdespositvndadmin: action?.payload,
            }

        //danh sach rut vnd


        case GETLISTWIDTHDRAWVNDADMIN:

            return {
                ...state,
                getlistwidthdrawvndadmin: action?.payload,
            }

        //danh sách rút vnd pending
        case GETLISTWIDTHDRAWVNDADMINPENDING:

            return {
                ...state,
                getlistwidthdrawvndadminpending: action?.payload,
            }

        //danh sách rut coin

        case GETLISTWIDTHDRAWCOIN:

            return {
                ...state,
                getlistwidthdrawcoin: action?.payload,
            }
        //danh sách rút coin pending
        case GETLISTWIDTHDRAWCOINPENDING:

            return {
                ...state,
                getlistwidthdrawcoinpending: action?.payload,
            }




        //kyc admin
        case GETKYCUSERPENDDING:

            return {
                ...state,
                getkycuserpendding: action?.payload,
            }

        //getalluser
        case GETALLUSER:

            return {
                ...state,
                getalluser: action?.payload,
            }

        //search
        case SEARCHUSER:

            return {
                ...state,
                searchuser: action?.payload,
            }


        //datakyc
        case DATAKYC:

            return {
                ...state,
                datakyc: action?.payload,
            }

        case ADMINSEARCH:

            return {
                ...state,
                adminsearch: action?.payload,
            }

        case STATISTICAL:

            return {
                ...state,
                statistical: action?.payload,
            }


        default:
            return state;
    }
}