import React from 'react';
import { useState } from 'react';
import createApiNap from 'core/createApiNap';
import { createNapCoinERC } from 'redux/action/userAction';
import { createNapCoin } from 'redux/action/userAction';
import { useTranslate } from 'core/translate';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

function DepositCoin({ setLoadding }) {
    let { t } = useTranslate()
    let token = JSON.parse(localStorage.getItem('user'))
    let { symbol } = useSelector(state => state.user)
    let { createwallettrc } = useSelector(state => state.deposit)

    const dispatch = useDispatch()

    let [typeUSDT, setTypeUSDT] = useState("TRC20")
    const HandleTypeUSDT = (e) => {
        setLoadding(true)
        setTypeUSDT(e)
        if (e == "TRC20") {
            dispatch(createNapCoin(token?.axiologin?.token, `USDT.TRC20`, t, setLoadding))
        } else if (e == "BEP20") {
            dispatch(createNapCoin(token?.axiologin?.token, "USDT.BEP20", t, setLoadding))
        }
        else {
            dispatch(createNapCoinERC(token?.axiologin?.token, "USDT.ERC20", t, setLoadding))
        }
    }
    let [typeSHIB, setTypeSHIB] = useState("BEP20")
    const HandleTypeSHIB = (e) => {
        setLoadding(true)

        setTypeSHIB(e)
        if (e == "BEP20") {
            createApiNap('SHIB.BEP20', "PAYMENT", dispatch, token, t, setLoadding)
        }
        if (e == "ERC20") {
            dispatch(createNapCoin(token?.axiologin?.token, `SHIB`, t, setLoadding))
        }
    }
    let [typeBNB, setTypeBNB] = useState("BNB")
    const HandleTypeBNB = (e) => {
        setLoadding(true)

        setTypeBNB(e)
        if (e == "BNB") {
            createApiNap('BNB', "PAYMENT", dispatch, token, t, setLoadding)
        }
        else if (e == 'BSC') {
            createApiNap('BNB.BSC', "PAYMENT", dispatch, token, t, setLoadding)
        } else {
            createApiNap('BNB.ERC20', "PAYMENT", dispatch, token, t, setLoadding)
        }
    }
    let [typeWIN, setTypeWIN] = useState("BEP20")
    const HandleTypeWIN = (e) => {
        setLoadding(true)
        setTypeWIN(e)
        createApiNap('WIN.BEP20', "PAYMENT", dispatch, token, t, setLoadding)

    }
    return (
        <div className='deposit-type'>


            {createwallettrc
                && <a className="active">TRC20</a>}
            {symbol === "USDT.TRC20" || symbol === "USDT" || symbol == "USDT.ERC20" ?
                <>
                    <a className={`${typeUSDT === "TRC20" && "active"}`} onClick={() => HandleTypeUSDT("TRC20")} >TRC20</a>
                    <a className={`${typeUSDT === "ERC20" && "active"}`} onClick={() => HandleTypeUSDT("ERC20")}>ERC20</a>
                    <a className={`${typeUSDT === "BEP20" && "active"}`} onClick={() => HandleTypeUSDT("BEP20")} >BEP20</a>
                </> : ''}
            {symbol == 'BNB' ? <>
                <a className={`${typeBNB == "BNB" && "active"}`} onClick={() => HandleTypeBNB("BNB")} >BNB</a>
                <a className={`${typeBNB == "BSC" && "active"}`} onClick={() => HandleTypeBNB("BSC")} >BSC</a>
                <a className={`${typeBNB == "ERC20" && "active"}`} onClick={() => HandleTypeBNB("ERC20")} >ERC20</a>
            </> : ''}
            {symbol == 'SHIB' ? <>
                <a className={`${typeSHIB == "BEP20" && "active"}`} onClick={() => HandleTypeSHIB("BEP20")} >BEP20</a>
                <a className={`${typeSHIB == "ERC20" && "active"}`} onClick={() => HandleTypeSHIB("ERC20")} >ERC20</a>
            </> : ''}
            {symbol == 'WIN' ? <>
                <a className={`${typeWIN == "BEP20" && "active"}`} onClick={() => HandleTypeWIN("BEP20")} >BEP20</a>
            </> : ''}


        </div>
    );
}

export default DepositCoin;