import React, { useState, useRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createAction } from '../../redux/action/authAction'
import { IMAGESYMBOLFROM, IMAGESYMBOLTO, INDEXSYMBOLFROM, INDEXSYMBOLTO, PRICECOIN, SYMBOLFROM, SYMBOLTO, TEST, TEST2 } from '../../redux/type'
import { useTranslate } from '../../core/translate/index'

import { toggleShadow } from '../../redux/action/toggleShadowActive';
import Select from '@mui/material/Select';

import { useHistory } from 'react-router-dom'
import { createA, createConvert, createConvertcoinExchange, GetFee } from '../../redux/action/userAction'
import SubmitSwap from './component/SubmitSwap'
import { port } from '../../port'
import { Spin } from 'antd'
import Swal from 'sweetalert2'
import HistorySwap from './component/HistorySwap'
import { ErrorMessage } from '../../core/Message'
import { formatMoney } from '../../core/hook/formarMoney'
import { fixedNumber } from '../../core/hook/fixedNumber'
import createApiNap from '../../core/createApiNap'
import covertCoin from '../../core/CovertCoin'
import exponentialToDecimal from '../../core/hook/exponentialToDecimal'
import { getwalletcoin } from 'core/GetWalletCoin'
import RecaptchaF2A from 'Components/RecaptchaF2A'


import MenuItem from '@mui/material/MenuItem';
import { Box } from '@mui/system'
import { FormControl, InputLabel, TextField } from '@mui/material'
import { DataPriceCoin } from 'core/DataPriceCoin'
import useLockedBody from 'core/useLockedBody'
import TableHistory from './component/TableHistory'




function Swap() {
    let { t } = useTranslate();
    useEffect(() => {

        document.title = "Swap"
        return () => {
            document.title = "Swap tobe"
        }

    }, [t]);
    let history = useHistory()
    const { axiologin } = useSelector(state => state.auth)




    let { tokenRe, value2fa } = useSelector(state => state.other)
    let { indexsymbolfrom, indexsymbolto, symbolfrom, symbolto, imagesymbolto, imagesymbolfrom, getwallet } = useSelector(state => state.user)
    let { pricecoin } = useSelector(state => state.coin)
    let priceSwap = pricecoin[indexsymbolfrom]?.price / pricecoin[indexsymbolto]?.price
    const shadowLayer = useSelector(state => state.shadowLayer)
    let { flag } = shadowLayer
    //nhớ log lại getfee khi có api
    let { getfee, getfeedifference } = useSelector(state => state.user)

    const exchangeRate = (nameF, nameT, indexsymbolfrom, indexsymbolto) => {
        return (
            <>
                <span className="css-901oao  r-vw2c0b" style={{ color: '#087972' }}>1 </span>
                <span className="css-901oao ">
                    {nameF} =
                </span>
                <span className="css-901oao ">
                    <span>
                        <strong style={{ color: "#087972", marginLeft: '5px' }}>{exponentialToDecimal(formatMoney(String(fixedNumber(priceSwap, 10))))} </strong>
                        <span>{nameT}</span>
                    </span>
                </span>
            </>
        )

    }

    useEffect(() => {
        window.scrollTo(0, 0)
        if (getfeedifference == false) dispatch(GetFee('DIFFERENCE'))

    }, [])
    let [loadding, setLoadding] = useState(false)

    const dispatch = useDispatch()
    let [action, setAction] = useState();
    function handleClickModal() {
        dispatch(createAction(TEST, true))
        setAction(action === '' ? 'action' : "")
    }
    let [action2, setAction2] = useState();
    function handleClickModal2() {
        dispatch(createAction(TEST2, true))
        setAction2(action2 === '' ? 'action' : "")
    }
    function closeModal() {
        dispatch(createAction(TEST, false))
        dispatch(createAction(TEST2, false))
        setAction('')
        setAction2('')
    }
    let [show, setShow] = useState(false)
    let [showSvg, setShowSvg] = useState(true)
    let ref = useRef();
    let ref2 = useRef()
    function handleShow() {
        setValueyouset(null)
        setShow(true)
        setShowSvg(false)
        ref.current.style.color = '#4BBD99'
        ref.current.style.fontSize = "18px"
        ref.current.style.fontWeight = "700"
        ref2.current.style.color = '#757575'
        ref2.current.style.fontSize = '16px'
        ref2.current.style.fontWeight = "500"
    }
    function CloseShow() {
        setValueyouset(null)
        setShow(false)
        setShowSvg(true)
        ref2.current.style.color = '#4BBD99'
        ref2.current.style.fontSize = "18px"
        ref2.current.style.fontWeight = "700"
        ref.current.style.fontSize = "16px"
        ref.current.style.color = '#757575'
        ref.current.style.color = '500'

    }


    useEffect(() => {
        if (axiologin) {
            createApiNap("BTC", typeF, dispatch, token, t, setLoadding)
            createApiNap("ETH", typeF, dispatch, token, t, setLoadding)
        }
    }, [])


    let [valueyouset, setValueyouset] = useState()


    let [coin, setCoin] = useState(0)
    let [coin2, setCoin2] = useState(0)
    let [value, setValue] = useState()


    let [Type, setType] = useState(1)
    let [countCoin, setCountCoin] = React.useState('');
    let [countSTF, setCountSTF] = React.useState('');


    let [max, setMax] = useState()
    let [activeMax, setActiveMax] = useState(false)
    useEffect(() => {


        if (Type == 2) {
            setCountCoin(coin * 0.9)
            setCountSTF((coin * 0.1) * (pricecoin[indexsymbolfrom]?.price / DataPriceCoin(pricecoin, 'STF')?.price))

        } else {
            setCountCoin(coin)
        }


    }, [coin, symbolfrom, symbolto, Type])
    const handleChangeType = event => {
        setType(event.target.value)
    }
    const SelectType = () => {
        return (
            <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Type</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={Type}
                        label="Type"
                        onChange={handleChangeType}
                    >
                        <MenuItem value={1}>Default</MenuItem>
                        <MenuItem value={2}>Use SFT</MenuItem>
                    </Select>
                </FormControl>
            </Box>
        )

    }
    const CountAmount = () => {
        return (
            <>
                {countCoin > 0 && <Box sx={{ minWidth: 120 }}>
                    <FormControl fullWidth>
                        <TextField value={formatMoney(String(fixedNumber(countCoin, 6))) + ' ' + `${symbolfrom}`} id="filled-basic" label="" variant="filled" />
                        {Type == 2 &&
                            <TextField value={formatMoney(String(fixedNumber(countSTF, 6))) + ' STF'} id="filled-basic" label="" variant="filled" />
                        }

                    </FormControl>
                </Box>}
            </>

        )

    }
    function handleMaxCoin() {
        setActiveMax(true)
        setValue(null)
        setValue2(null)
        if (valueyouset) {
            setMax(getwallet?.data?.data[`${symbolfrom?.toLowerCase()}_balance`])
            setCoin(getwallet?.data?.data[`${symbolfrom?.toLowerCase()}_balance`])
            setCoin2((valueyouset) * (getwallet?.data?.data[`${symbolfrom?.toLowerCase()}_balance`] - (getwallet?.data?.data[`${symbolfrom?.toLowerCase()}_balance`] * getfee) / 100))
        } else {
            setMax(getwallet?.data?.data[`${symbolfrom?.toLowerCase()}_balance`])
            setCoin(getwallet?.data?.data[`${symbolfrom?.toLowerCase()}_balance`])
            setCoin2((priceSwap) * (getwallet?.data?.data[`${symbolfrom?.toLowerCase()}_balance`] - (getwallet?.data?.data[`${symbolfrom?.toLowerCase()}_balance`] * getfee) / 100))
        }

    }
    let [error, setError] = useState('')
    function handleChangeCoin(e) {

        let text = e.target.value
        let value = ""
        let dotIndex = text.indexOf(".")

        for (let index = 0; index < text.length; index++) {
            if (text[index].match(/[0-9]/))
                value += text[index]
            if (index === dotIndex)
                value += "."
        }
        setActiveMax(false)
        setCoin(value)
        setValue(value)
        setValue2(0)
        setCoin2(0)
        setCoin2((priceSwap) * (value - (value * getfee) / 100))
    }
    let [value2, setValue2] = useState()

    function handleChangeCoin2(e) {
        let text = e.target.value
        let value = ""
        let dotIndex = text.indexOf(".")
        setActiveMax(false)

        for (let index = 0; index < text.length; index++) {
            if (text[index].match(/[0-9]/))
                value += text[index]
            if (index === dotIndex)
                value += "."
        }

        setCoin2(value)
        setValue2(value)
        setValue(0)
        setCoin(0)
    }
    let [errorExchange, setErrorExchange] = useState('')
    let [errorExchangeMax, setErrorExchangeMax] = useState('')

    let ValueMin = priceSwap - ((priceSwap * getfeedifference[0]?.raito) / 100)
    let ValueMax = priceSwap + ((priceSwap * getfeedifference[0]?.raito) / 100)
    function Priceyouset(e) {
        let text = e.target.value
        let ValuePriceyouset = ""
        let dotIndex = text.indexOf(".")

        for (let index = 0; index < text.length; index++) {
            if (text[index].match(/[0-9]/))
                ValuePriceyouset += text[index]
            if (index === dotIndex)
                ValuePriceyouset += "."
        }

        setValueyouset(ValuePriceyouset)
        if (ValuePriceyouset < ValueMin) {
            setErrorExchangeMax('')
            setErrorExchange("Giá không được bé hơn")
        }
        else if (ValuePriceyouset > ValueMax) {
            setErrorExchange('')
            setErrorExchangeMax("Giá không được lớn hơn")
        } else {
            setErrorExchange('')
            setErrorExchangeMax('')
        }
        if (value > 0) {
            setCoin2((valueyouset) * (value - (value * getfee) / 100))
        }




    }

    useEffect(() => {

        if (activeMax) {
            if (valueyouset) {

                setMax(getwallet?.data?.data[`${symbolfrom?.toLowerCase()}_balance`])
                setCoin(getwallet?.data?.data[`${symbolfrom?.toLowerCase()}_balance`])
                setCoin2((valueyouset) * (getwallet?.data?.data[`${symbolfrom?.toLowerCase()}_balance`] - (getwallet?.data?.data[`${symbolfrom?.toLowerCase()}_balance`] * getfee) / 100))
            } else {
                setMax(getwallet?.data?.data[`${symbolfrom?.toLowerCase()}_balance`])
                setCoin(getwallet?.data?.data[`${symbolfrom?.toLowerCase()}_balance`])
                setCoin2((priceSwap) * (getwallet?.data?.data[`${symbolfrom?.toLowerCase()}_balance`] - (getwallet?.data?.data[`${symbolfrom?.toLowerCase()}_balance`] * getfee) / 100))
            }
        }

        if (value > 0) {
            if (valueyouset) {

                setCoin2((valueyouset) * (value - (value * getfee) / 100))

            } else {
                setCoin2((priceSwap) * (value - (value * getfee) / 100))
            }
        }
        if (valueyouset < ValueMin) {
            setErrorExchangeMax('')
            setErrorExchange("Giá không được bé hơn")
        }
        else if (valueyouset > ValueMax) {
            setErrorExchange('')
            setErrorExchangeMax("Giá không được lớn hơn")
        } else {
            setErrorExchange('')
            setErrorExchangeMax("")
        }
    }, [priceSwap, symbolfrom, symbolto, valueyouset])

 
    const { profile } = useSelector(state => state.user)
    const verified = profile?.data?.data?.verified
    const twofa = profile?.data?.data?.enabled_twofa
    let user = JSON.parse(localStorage.getItem('user'))

    let [err, setErr] = useState()
    let [ActiveSwap, setActiveSwap] = useState(false)

    useEffect(() => {
        if (flag == false) {
            setActiveSwap(false)
        }
    }, [flag])

    const activeSubmit = () => {
        setActiveSwap(true)
        dispatch(toggleShadow(!flag))

    }
    function handleSubmit() {
       
        if (!axiologin) {
            history.push('/form')
        }
        else if(symbolfrom?.toUpperCase() == 'STF'){
            ErrorMessage("STF is not supported yet", t)
        }
        else if (!tokenRe.length > 0 || !value2fa.length > 0) {
            if (verified == null) {
                ErrorMessage("User has not verified KYC !", t)
            } else if (verified == 2) {
                ErrorMessage("KYC is pending, please wait!", t)
            }
            else if (verified == 3) {
                ErrorMessage("KYC has been declined, please check again!", t)

            } else if (coin <= 0) {
                ErrorMessage('The number of coins must be greater than or equal to 1', t)

            }
            else {
                // if (Type == 2 && countSTF > getwalletcoin('stf_trc20', getwallet)) {
                //     ErrorMessage('The number of coins in the wallet is not enough', t)
                //     setErr('')


                // } else if (Type == 1 && countCoin > getwalletcoin(covertCoin(symbolfrom?.toUpperCase(), typeF), getwallet)) {
                //     ErrorMessage('The number of coins in the wallet is not enough', t)
                //     setErr('')

                // }
              
                 if (symbolfrom?.toUpperCase() == 'STF' && (getwallet?.data?.data.stf_bonus_balance < 20000 || getwallet?.data?.data.stf_bonus_balance == undefined)) {
                    ErrorMessage("STF bonus must have at least 20.000", t)
                }
                else {
                    if (error == '') {
                        dispatch(createConvert(user?.axiologin?.token, covertCoin(symbolfrom?.toUpperCase(), typeF), covertCoin(symbolto?.toUpperCase(), typeT), coin, setLoadding, t, symbolfrom, symbolto, typeF, typeT, tokenRe, value2fa, Type))
                        setErr('')
                    }
                }

            }
        } else {
            // if (twofa == 0 && tokenRe == '') {
            //     setErr("Please enter ReCAPTCHA")

            // } else {
            //     setErr("Please enter 2FA")

            // }
            ErrorMessage("Please enter ReCAPTCHA", t)
        }

    }
    let [typeF, setTypeF] = useState("PAYMENT")
    let [typeT, setTypeT] = useState("PAYMENT")

    function handleSubmitExchange() {

        if (!axiologin) {
            history.push('/form')
        }
        else if(symbolfrom?.toUpperCase() == 'STF' ){
            ErrorMessage("STF is not supported yet", t)
        }
        else if (tokenRe.length > 0 || value2fa) {
            if (verified == null) {
                ErrorMessage("User has not verified KYC !", t)

            } else if (verified == 2) {
                ErrorMessage("KYC is pending, please wait!", t)
            }
            else if (verified == 3) {
                ErrorMessage("KYC has been declined, please check again!", t)

            }
            else if (coin <= 0) {
                ErrorMessage("The number of coins must be greater than or equal to 1", t)

            }
            else {
                // if (Type == 2 && countSTF > getwalletcoin('stf_trc20', getwallet)) {
                //     ErrorMessage('The number of coins in the wallet is not enough', t)
                //     setErr('')


                // } else if (Type == 1 && countCoin > getwalletcoin(covertCoin(symbolfrom?.toUpperCase(), typeF), getwallet)) {
                //     ErrorMessage('The number of coins in the wallet is not enough', t)
                //     setErr('')

                // }
             
                 if (symbolfrom?.toUpperCase() == 'STF' && getwallet?.data?.data.stf_bonus_balance < 20000) {
                    ErrorMessage("STF must have at least 20.000", t)
                }
                else {
                    if (error == '') {
                        dispatch(createConvertcoinExchange(user?.axiologin?.token, covertCoin(symbolfrom?.toUpperCase(), typeF), covertCoin(symbolto?.toUpperCase(), typeT), coin, valueyouset, setLoadding, t, symbolfrom, symbolto, typeF, typeT, tokenRe, value2fa, Type))
                        setErr('')
                    }

                }
            }
        } else {
            if (twofa == 0 && tokenRe == '') {
                setErr("Please enter ReCAPTCHA")

            } else {
                setErr("Please enter  2FA")

            }
        }

    }

    //modal-----------------------------------------------------
    let modal2 = useSelector(state => state.auth.test)
    let modal3 = useSelector(state => state.auth.test2)
    let token = JSON.parse(localStorage.getItem('user'))
    useLockedBody(modal2)
    useLockedBody(modal3)
    const rederSymbol = () => {
        return (
            pricecoin?.map((e, i) => {

                if (e?.name != symbolto && e?.name != 'DOT' && e?.name != "QGC" && e?.name != "NBB" && e?.name != 'SWB' && e?.swap == 1) {
                    return (
                        <div onClick={() => activeSymbol(e, i)} className={`${indexsymbolfrom == i ? "css-1dbjc4n r-1awozwy r-14lw9ot r-l61ric r-z2wwpe r-1phboty r-rs99b7 r-1814a8r r-1loqt21 r-18u37iz r-tzz3ar r-320f6p r-ubezar r-vw2c0b r-oxtfae r-135wba7 r-1ifxtd0 r-88pszg r-ymttw5 r-1f1sjgu r-bnwqim r-iyfy8q" : "css-1dbjc4n r-1awozwy r-14lw9ot r-z2wwpe r-1phboty r-rs99b7 r-1814a8r r-1loqt21 r-18u37iz r-tzz3ar r-320f6p r-ubezar r-vw2c0b r-oxtfae r-135wba7 r-1ifxtd0 r-88pszg r-ymttw5 r-1f1sjgu r-bnwqim r-iyfy8q"}`} data-testid="btc-item" >
                            <div className="css-1dbjc4n r-1awozwy r-18u37iz " >
                                <div className="css-1dbjc4n" data-testid="icon-btc" style={{ height: 24, width: 24 }}>
                                    <img src={`${port}images/${e.name}.png`} alt="" style={{ width: '25px', marginRight: '10px' }} />
                                </div>
                                <div className="css-1dbjc4n r-1wbh5a2 r-1777fci r-1jkjb">
                                    <div dir="auto" className="css-901oao r-homxoj r-320f6p r-7cikom r-1s2g6fe r-t9a87b">
                                        {e.token_key}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
            })

        )
    }
    const rederSymbol2 = () => {
        return (
            pricecoin?.map((e, i) => {
                if (e?.name != symbolfrom && e?.name != 'DOT' && e?.swap == 1) {
                    return (
                        <div onClick={() => activeSymbol2(e, i)} className={`${indexsymbolto == i ? "css-1dbjc4n r-1awozwy r-14lw9ot r-l61ric r-z2wwpe r-1phboty r-rs99b7 r-1814a8r r-1loqt21 r-18u37iz r-tzz3ar r-320f6p r-ubezar r-vw2c0b r-oxtfae r-135wba7 r-1ifxtd0 r-88pszg r-ymttw5 r-1f1sjgu r-bnwqim r-iyfy8q" : "css-1dbjc4n r-1awozwy r-14lw9ot r-z2wwpe r-1phboty r-rs99b7 r-1814a8r r-1loqt21 r-18u37iz r-tzz3ar r-320f6p r-ubezar r-vw2c0b r-oxtfae r-135wba7 r-1ifxtd0 r-88pszg r-ymttw5 r-1f1sjgu r-bnwqim r-iyfy8q"}`} data-testid="btc-item">
                            <div className="css-1dbjc4n r-1awozwy r-18u37iz " >
                                <div className="css-1dbjc4n" data-testid="icon-btc" style={{ height: 24, width: 24 }}>
                                    <img src={`${port}images/${e.name}.png`} alt="" style={{ width: '25px', marginRight: '10px' }} />
                                </div>
                                <div className="css-1dbjc4n r-1wbh5a2 r-1777fci r-1jkjb">
                                    <div dir="auto" className="css-901oao r-homxoj r-320f6p r-7cikom r-1s2g6fe r-t9a87b">
                                        {e.token_key}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
            })
        )
    }

    function activeSymbol(e, i) {
        createApiNap(e?.name, e?.type, dispatch, token, t, setLoadding)
        dispatch(createA(IMAGESYMBOLFROM, `images/${e.name}.png`))
        dispatch(createA(SYMBOLFROM, e.name))
        dispatch(createA(INDEXSYMBOLFROM, i))
        closeModal()
        setTypeF(e?.type)

    }
    function activeSymbol2(e, i) {
        createApiNap(e?.name, e?.type, dispatch, token, t, setLoadding)
        closeModal()
        dispatch(createA(IMAGESYMBOLTO, `images/${e.name}.png`))
        dispatch(createA(SYMBOLTO, e.name))
        dispatch(createA(INDEXSYMBOLTO, i))
        setTypeT(e?.type)
    }
    return (
        <>
            <div className="swapnew">
                <div className="container">
                    <div className="swapnew_main row">
                        <div className="swapnew_main-text  col-md-6">
                            <h3>{t('Swap Bitcoin, Ethereum and Altcoins')}
                            </h3>
                            <p>{t('Swaptobe Apps, Trading whenever and wherever you are!')}</p>
                            <div>
                                <a href="https://testflight.apple.com/join/ysHX5JJP" target="_blank">
                                    <img src="img/appstore.png" alt="" />
                                </a>
                                <a href="https://play.google.com/store/apps/details?id=com.companyname.swaptobe" target="_blank">
                                    <img src="img/googleplay.png" alt="" />
                                </a>
                            </div>
                        </div>
                        <div className="swapnew_main-content  col-md-6 ">
                            <div className='swapnew-title'>
                                <a className='swapnew-title-active' onClick={CloseShow} ref={ref2}>   {t("Market price")}</a>
                                <a className='swapnew-title-active' onClick={handleShow} ref={ref}>{t("Your Price")}</a>
                            </div>
                            <div className='swapnew-content'>
                                <div className='swapnew-content-item'>
                                    <span>{t("Amount of")} {symbolfrom} </span>
                                    <input onChange={(e) => handleChangeCoin(e)} value={formatMoney(String(coin))} id="sourceAmount" name="sourceAmount" required type="text" />

                                    <div className='swapnew-modal' onClick={handleClickModal}>
                                        <img src={`${port}${imagesymbolfrom}`} alt="" style={{ width: '25px', marginRight: '10px' }} />
                                        <span>{symbolfrom}</span>
                                        <img src="img/arrowswap.png" alt="" />
                                    </div>

                                </div>

                                <div className='swapnew-content-item'>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <span>{t("Amount of")} {symbolto} </span>
                                        <img src="/img/swaps.png" alt="" style={{ marginRight: '30px' }} />
                                    </div>
                                    <input onChange={(e) => handleChangeCoin2(e)} value={exponentialToDecimal(formatMoney(String(fixedNumber(coin2, 10))))} disabled="disabled" inputMode="text" id="destinationAmount" name="destinationAmount" required type="text" />
                                    <div className='swapnew-modal' onClick={handleClickModal2}>
                                        <img src={`${port}${imagesymbolto}`} alt="" style={{ width: '25px', marginRight: '10px' }} />
                                        <span>{symbolto}</span>
                                        <img src="img/arrowswap.png" alt="" />
                                    </div>
                                </div>

                            </div>
                            {axiologin ?
                                <div style={{ margin: '10px 0' }}> {t("Max available:")} <span style={{ color: 'rgb(7, 173, 135)', fontFamily: 'Arial', fontWeight: '700' }}>
                                    {formatMoney(String(getwalletcoin(covertCoin(symbolfrom?.toUpperCase(), typeF), getwallet), 6))}
                                </span> <span style={{ fontWeight: '700', fontFamily: 'Arial' }}>{symbolfrom}</span></div>
                                : ""}
                            {show &&
                                <>
                                    <div className='swapnew-content-item'>
                                        <span>  {t("Price :")}</span>
                                        <input inputMode="text" id="limitPrice" name="limitPrice" autoComplete="off" type="text" placeholder={`${t("Enter the price you want to change")}`} style={{ color: "#000" }} onChange={Priceyouset} />
                                    </div>
                                    {errorExchange && <div style={{ color: 'red', margin: '10px 0' }}>{errorExchange} {exponentialToDecimal(formatMoney(String(fixedNumber(ValueMin, 10))))}</div>}
                                    {errorExchangeMax && <div style={{ color: 'red', margin: '10px 0' }}>{errorExchangeMax} {exponentialToDecimal(formatMoney(String(fixedNumber(ValueMax, 10))))}</div>}
                                </>
                            }
                            <div style={{ marginBottom: '20px' }}>  {exchangeRate(pricecoin[indexsymbolfrom]?.name, pricecoin[indexsymbolto]?.name, indexsymbolfrom, indexsymbolto)}</div>

                            {axiologin && <RecaptchaF2A />}

                            <div className='swapnew-des'>
                                <div>
                                    <img src="img/i.png" alt="" />
                                    <p>{t("The final")} {symbolto} {t("amount you receive may be slightly different due to market volatility")}</p>
                                </div>
                                <div>
                                    <img src="img/i.png" alt="" />
                                    <p>{t("Swap Fee ")}{getfee}% {t("implementation and has been deducted from the estimate above.")}</p>
                                </div>
                            </div>
                            {loadding ? <div className="example">
                                <Spin />
                            </div> :
                                show ?
                                    <div className='swapnew-button'>
                                        < button onClick={handleSubmitExchange}>{t("Swap")}</button>
                                    </div> :
                                    <div className='swapnew-button'>
                                        <button onClick={handleSubmit}>{t("Swap")}</button>
                                    </div>
                            }

                        </div>
                    </div>
                </div>
            </div >
            {axiologin &&
                <div style={{ marginTop: '50px' }}>
                    <div className="container">
                        <div className="title">
                            <h4 style={{ color: '#4bbd99', fontWeight: 'bold' }}>{t("History Swap")}</h4>
                        </div>
                        <TableHistory typeF={typeF} typeT={typeT} />
                    </div>
                </div>
            }





            {
                modal2 ? <>
                    <div role="dialog" aria-modal="true" className="dialog fade modal show" tabIndex={-1} style={modal2 = { display: "block" }}  >
                        <div class="fade modal-backdrop show" style={modal2 = { display: "block", zIndex: 0 }} onClick={closeModal} ></div>
                        <div role="document" className="modal-dialog modal-dialog-centered">
                            <div className={`modal-content ${action}`}  >
                                <div className="dialog-header modal-header">
                                    <div className="dialog-title modal-title h4">{t('Change')}</div>
                                    <button type="button" className="close" onClick={closeModal}>
                                        <span aria-hidden="true">×</span>
                                        <span className="sr-only">Close</span>
                                    </button>
                                </div>
                                <div className="dialog-body text-16 modal-body">
                                    <div className="css-1dbjc4n" data-testid="coinSelect">
                                        <div dir="auto" className="css-901oao r-1814a8r r-320f6p r-ubezar r-vw2c0b r-oxtfae r-135wba7 r-5oul0u">
                                            {t("Swap to:")}
                                        </div>
                                        <div className="css-1dbjc4n r-18u37iz r-1w6e6rj r-1h0z5md">
                                            {rederSymbol()}


                                        </div>
                                    </div>
                                </div>
                                <div className="dialog-footer empty-dialog-footer modal-footer">
                                    <div className="w-100"><div className="row" /></div>
                                </div>
                            </div>
                        </div>

                    </div>


                </> : <> </>
            }
            {
                modal3 ? <>
                    <div role="dialog" aria-modal="true" className="dialog fade modal show" tabIndex={-1} style={modal3 = { display: "block" }}  >
                        <div class="fade modal-backdrop show" style={modal3 = { display: "block", zIndex: 0 }} onClick={closeModal} ></div>
                        <div role="document" className="modal-dialog modal-dialog-centered">
                            <div className={`modal-content ${action2}`}  >
                                <div className="dialog-header modal-header">
                                    <div className="dialog-title modal-title h4">{t("Change")}</div>
                                    <button type="button" className="close" onClick={closeModal}>
                                        <span aria-hidden="true">×</span>
                                        <span className="sr-only">{t("Close")}</span>
                                    </button>
                                </div>
                                <div className="dialog-body text-16 modal-body">
                                    <div className="css-1dbjc4n" data-testid="coinSelect">
                                        <div dir="auto" className="css-901oao r-1814a8r r-320f6p r-ubezar r-vw2c0b r-oxtfae r-135wba7 r-5oul0u">
                                            {t("Swap to:")}
                                        </div>
                                        <div className="css-1dbjc4n r-18u37iz r-1w6e6rj r-1h0z5md">
                                            {
                                                rederSymbol2()

                                            }


                                        </div>
                                    </div>
                                </div>
                                <div className="dialog-footer empty-dialog-footer modal-footer">
                                    <div className="w-100"><div className="row" /></div>
                                </div>
                            </div>
                        </div>

                    </div>


                </> : <> </>
            }
            {/* {ActiveSwap && <SubmitSwap />} */}
        </>
    )
}

export default React.memo(Swap)