import React, { useEffect, useRef, useState } from 'react'
import { useTranslate } from '../../core/translate/index'
import { port } from '../../port'

export default function Referral() {
    let { t } = useTranslate()

    let [state, setState] = useState(false)
    function hanldeCopy(index) {
        setState(index)

        setTimeout(() => {
            setState(false)
        }, 1000)
    }


    return (
        <>
            <div id="scrollable-body">
                <div className="css-1dbjc4n r-1jj8364 r-lchren r-l64lwh r-1qhn6m8 r-i023vh r-13qz1uu r-1g2xzmm" data-testid="alert-container">
                    <div className="css-1dbjc4n r-1g2xzmm" data-testid="flash-access-devices">
                        <div className="css-1dbjc4n r-eqz5dr r-912u2z r-1g2xzmm" />
                    </div>
                </div>
                <div className="referral css-1dbjc4n r-11ict49" data-testid="main">
                    <section className="section-feature">
                        <section className="referral-program-banner">
                            <div className="greenlinerBold banner">
                                <div className="css-1dbjc4n r-1jj8364 r-lchren r-l64lwh r-1qhn6m8 r-i023vh r-13qz1uu r-1g2xzmm">
                                    <div className="css-1dbjc4n r-1euycsn r-1wtj0ep r-1g2xzmm">
                                        <div className="css-1dbjc4n r-1jj8364 r-lchren r-l64lwh r-1qhn6m8 r-i023vh r-13qz1uu r-1g2xzmm">
                                            <div className="css-1dbjc4n r-1awozwy r-18u37iz r-1w6e6rj r-6cv0rn r-1g2xzmm">
                                                <div className="css-1dbjc4n r-4nmn2n r-18kxxzh r-1q142lx r-zbtmmk r-aqf30s r-1vh16mv r-ymttw5 r-bnwqim r-1g2xzmm">
                                                    <div className="css-1dbjc4n" data-testid="svg-image-referral-image">
                                                        <svg width="100%" height={250} viewBox="0 0 466 255">
                                                            <defs>
                                                                <path id="referral-image_svg__a" d="M.445.477h135.731v169.706H.446z" />
                                                            </defs>
                                                            <g fill="none" fillRule="evenodd">
                                                                <path d="M259.166 119.35c21.025-7.34 37.058-27.374 39.637-49.535 2.58-22.16-20.74-26.827-39.516-38.818C242.718 20.416 232.164-.169 208.414 0c-51.674.372-51.184 26.618-72.802 73.652-4.997 10.87-29.78 12.055-35.153 42.539-3.06 17.365 9.554 37.338 27.032 39.383 17.478 2.046 34.922-3.578 51.164-10.362 16.242-6.785 32.171-14.875 49.457-18.175 17.286-3.298 36.721-1.035 49.756 10.81l-18.702-18.497" fill="#087972" />
                                                                <path d="M247.541 153.698c12.625-5.783 20.9-18.046 28.022-29.999 7.122-11.954 14.168-24.65 25.696-32.403 10.074-6.774 23.064-9 34.807-5.965 11.742 3.035 22.046 11.282 27.605 22.097 8.12 15.8 5.833 34.987.615 51.976-3.94 12.827-9.53 25.335-18.115 35.627-8.585 10.29-20.402 18.245-33.6 20.415-13.197 2.17-27.695-2.052-36.396-12.246-5.96-6.982-8.835-16.005-12.84-24.274-6.873-14.197-17.425-26.58-30.335-35.596l14.541 10.368" fill="#087972" />
                                                                <path d="M205.135 180c9.03-10.828 41.112-33.708 89.42-39.126 28.871-3.237 28.459-32.417 21.535-39.507-6.462-6.617-17.168-6.076-25.37-10.306-15.16-7.818-17.408-28.515-27.479-42.349-10.02-13.765-28.144-20.076-45.014-18.466-16.87 1.61-32.475 10.231-45.283 21.408-12.807 11.177-23.165 24.869-33.425 38.453-6.09 8.063-12.505 17.235-11.392 27.303 1.025 9.288 8.678 16.907 17.483 19.853 8.805 2.944 18.495 1.872 27.387-.792 6.785-2.033 13.351-4.976 20.36-5.974 7.009-.997 14.86.33 19.627 5.6 8.187 9.054 3.604 24.524 10.463 34.636 5.139 7.575-18.312 9.267-18.312 9.267" fill="#3FBF7A" />
                                                                <path fill="#3FBF7A" d="M194.785 202L134 141.215 271.215 4 332 64.785z" />
                                                                <path d="M137.003 140.715l57.283 57.282 133.71-133.711-57.281-57.283-133.712 133.712zM194.286 205L130 140.715 270.715 0 335 64.286 194.286 205z" fill="#087972" />
                                                                <path d="M316.642 49c-.09.086-.186.16-.275.248-8.49 8.492-8.49 22.26 0 30.752L332 64.362 316.642 49M286.5 21.858c-.086.09-.16.186-.248.275-8.492 8.49-22.26 8.49-30.752 0L271.138 6.5 286.5 21.858M179 186.368L194.6 202l15.4-15.433c-.048-.05-.089-.104-.138-.153-8.519-8.538-22.322-8.55-30.862-.046M149.575 156c8.565-8.576 8.566-22.421.005-31L134 140.503 149.575 156" fill="#087972" />
                                                                <path d="M257.042 78.958c13.277 13.278 13.277 34.806 0 48.084-13.278 13.277-34.806 13.277-48.084 0-13.277-13.278-13.277-34.806 0-48.084 13.278-13.277 34.806-13.277 48.084 0" fill="rgb(246, 190, 34)" />
                                                                <path d="M69 92.87l.562 43.13L78 113.113l-.572-11.256c-.187-3.682-2.27-7.017-5.526-8.846l-1.596-.897c-.587-.329-1.314.093-1.306.756" fill="#34A853" />
                                                                <path d="M0 211.324l54.823-25.679a4.873 4.873 0 0 0 2.523-3.114l17.262-65.703 15.526-31.615a.38.38 0 0 1 .652-.052l2.58 3.642a5.991 5.991 0 0 1 .622 5.814l-9.803 24.796-.719 15.233a.807.807 0 0 0 1.305.662l13.561-12.304a3.937 3.937 0 0 1 2.898-.807l24.802 3.007a.862.862 0 0 1 .435 1.529l-3.568 2.854a12.935 12.935 0 0 1-7.871 2.833l-11.583.186a3.941 3.941 0 0 0-2.975 1.434l-5.704 6.926c-5.27 6.397-4.924 15.724.803 21.714a16.63 16.63 0 0 0 19.73 3.244l9.053-4.738a12.934 12.934 0 0 1 9.55-.976l1.472.42a.863.863 0 0 1 .302 1.503l-31.75 25.398L0 255v-43.676" fill="#FFE9DB" />
                                                                <path d="M118.515 125.756l8.999 1.24a.42.42 0 0 0 .467-.344c.1-.546-.197-1.15-.752-1.229L117 124l.346.802c.226.527.666.886 1.169.954M136.648 160.05c-.016.013-4.578 3.897-6.189 5.271-.362.31-.529.818-.432 1.31l.074.369 6.595-5.685c.373-.326.383-.873.137-1.242-.093-.14-.165-.04-.185-.023M89.137 91.742l2.77-5.465a.852.852 0 0 0-.076-.896l-.17-.229a.385.385 0 0 0-.644.05L87 93l.65-.136a2.156 2.156 0 0 0 1.487-1.122" fill="#3FBF7A" />
                                                                <g transform="translate(329 5)">
                                                                    <mask id="referral-image_svg__b" fill="#fff">
                                                                        <use xlinkHref="#referral-image_svg__a" />
                                                                    </mask>
                                                                    <path d="M67.159 8.22l-.555 43.253-8.32-22.953.564-11.288a10.84 10.84 0 0 1 5.449-8.87l1.574-.9a.86.86 0 0 1 1.288.758" fill="#F8DAC7" mask="url(#referral-image_svg__b)" />
                                                                    <path d="M136.2 126.583l-54.724-25.635a4.862 4.862 0 0 1-2.518-3.109L61.727 32.25 46.228.69a.38.38 0 0 0-.65-.053L43 4.273a5.978 5.978 0 0 0-.619 5.804l9.784 24.754.718 15.206a.806.806 0 0 1-1.302.662L38.045 38.415c-.82-.64-1.86-.93-2.892-.805l-24.758 3.001c-.757.092-1.03 1.05-.434 1.526l3.562 2.85a12.911 12.911 0 0 0 7.857 2.828L32.941 48a3.93 3.93 0 0 1 2.97 1.43l5.694 6.915c5.26 6.387 4.915 15.697-.801 21.677a16.6 16.6 0 0 1-19.694 3.238l-9.039-4.73a12.912 12.912 0 0 0-9.532-.974l-1.47.42a.86.86 0 0 0-.3 1.5L32.46 102.83 136.2 170.183v-43.6" fill="#FFE9DB" mask="url(#referral-image_svg__b)" />
                                                                </g>
                                                                <path d="M329.352 80.05c.016.013 4.578 3.897 6.188 5.272.364.31.53.817.433 1.309l-.074.369-6.596-5.685c-.372-.325-.382-.872-.136-1.242.093-.14.166-.04.185-.023" fill="#9B59B6" />
                                                                <path d="M296.29 195.52c-3.049 10.9-15.054 17.45-26.814 14.622-11.756-2.827-18.816-13.958-15.766-24.862 3.049-10.904 15.05-17.45 26.81-14.622 11.76 2.827 18.82 13.958 15.77 24.862" fill="#F6BE22" />
                                                                <path d="M290.879 193.15c-2.253 9.189-11.193 14.688-19.96 12.287-8.772-2.405-14.055-11.803-11.798-20.989 2.253-9.19 11.193-14.689 19.96-12.283 8.772 2.4 14.055 11.798 11.798 20.984" fill="#FFF" />
                                                                <path d="M289.311 193.612c-2.108 7.699-10.4 12.32-18.525 10.323-8.125-1.993-13.003-9.853-10.895-17.547 2.108-7.699 10.4-12.32 18.525-10.323 8.12 1.993 12.999 9.853 10.895 17.547" fill="#F6BE22" />
                                                                <path d="M271.382 202.33l.724-2.9c-1.594-.462-3.123-1.427-3.906-2.403l1.141-1.746a7.547 7.547 0 0 0 3.665 2.306c1.958.53 3.574-.29 3.98-1.93.396-1.58-.436-2.847-2.263-4.259-2.527-1.906-3.936-3.699-3.314-6.197.595-2.385 2.67-3.754 5.318-3.502l.72-2.899 1.71.463-.694 2.785c1.642.502 2.66 1.27 3.337 1.972l-1.16 1.71c-.497-.501-1.469-1.439-3.235-1.913-2.124-.573-3.254.529-3.54 1.678-.37 1.495.463 2.518 2.606 4.2 2.542 1.974 3.604 3.856 2.968 6.41-.565 2.27-2.614 3.985-5.575 3.706l-.745 2.989-1.737-.47" fill="#FFF" />
                                                                <path d="M139.91 38.014c-2.107 7.694-10.401 12.317-18.527 10.322-8.122-1.996-13-9.853-10.893-17.55 2.107-7.697 10.398-12.317 18.524-10.322 8.125 1.996 13.002 9.853 10.896 17.55" fill="#F6BE22" />
                                                                <path d="M136.454 36.458c-1.596 6.252-7.933 9.992-14.147 8.359-6.217-1.636-9.96-8.029-8.361-14.277 1.596-6.251 7.933-9.992 14.147-8.356 6.217 1.634 9.96 8.026 8.361 14.274" fill="#FFF" />
                                                                <path d="M134.704 36.546c-1.426 5.534-7.035 8.855-12.531 7.42-5.497-1.433-8.796-7.082-7.37-12.612 1.426-5.534 7.035-8.855 12.531-7.42 5.494 1.433 8.794 7.082 7.37 12.612" fill="#F6BE22" />
                                                                <path d="M122.137 42.165l.523-2.066c-1.152-.33-2.258-1.017-2.825-1.713l.826-1.243a5.468 5.468 0 0 0 2.65 1.643c1.416.377 2.585-.207 2.879-1.375.286-1.126-.316-2.029-1.637-3.035-1.828-1.358-2.847-2.635-2.397-4.415.43-1.7 1.931-2.675 3.846-2.496l.52-2.065 1.237.33-.502 1.984c1.188.358 1.923.905 2.414 1.406l-.84 1.218a4.96 4.96 0 0 0-2.339-1.364c-1.536-.408-2.353.378-2.56 1.196-.267 1.065.335 1.794 1.885 2.993 1.838 1.406 2.606 2.747 2.146 4.567-.409 1.618-1.89 2.839-4.031 2.64l-.54 2.13-1.255-.335" fill="#FFF" />
                                                                <g>
                                                                    <path d="M311.596 15.802c-1.38 5.041-6.814 8.07-12.138 6.763-5.321-1.308-8.517-6.455-7.137-11.498 1.38-5.043 6.813-8.07 12.136-6.763 5.324 1.308 8.52 6.456 7.139 11.498" fill="#34A853" />
                                                                    <path d="M309.332 14.783c-1.046 4.096-5.197 6.547-9.269 5.476-4.073-1.072-6.526-5.26-5.478-9.354 1.046-4.095 5.198-6.546 9.269-5.474 4.073 1.07 6.526 5.258 5.478 9.352" fill="#FFF" />
                                                                    <path d="M308.186 14.84c-.935 3.626-4.61 5.802-8.21 4.862-3.602-.939-5.764-4.64-4.83-8.263.935-3.626 4.61-5.802 8.21-4.861 3.6.938 5.762 4.64 4.83 8.263" fill="#F6BE22" />
                                                                    <path d="M299.952 18.522l.342-1.353c-.755-.216-1.479-.667-1.85-1.123l.54-.815a3.582 3.582 0 0 0 1.737 1.077c.928.247 1.693-.135 1.886-.9.187-.739-.207-1.33-1.072-1.99-1.198-.889-1.865-1.726-1.57-2.892.281-1.113 1.264-1.752 2.519-1.635l.34-1.353.81.216-.327 1.3c.778.234 1.26.593 1.58.92l-.549.8a3.25 3.25 0 0 0-1.533-.894c-1.006-.268-1.542.247-1.677.783-.175.698.22 1.176 1.235 1.961 1.204.921 1.707 1.8 1.406 2.992-.268 1.06-1.239 1.86-2.641 1.73l-.354 1.395-.822-.22" fill="#FFF" />
                                                                </g>
                                                                <path d="M247.958 92.457c-1.286-.586-2.693-.851-4.624-.402a11.063 11.063 0 0 0-2.882 1.079c.291-.684.472-1.41.535-2.151a6.354 6.354 0 0 0-.45-3.129 8.692 8.692 0 0 0-1.834-2.626c-1.36-1.325-2.865-2.441-4.485-2.757-1.62-.317-3.337-.135-5.186.574-1.418.548-2.725 1.27-4.483 2.686l-4.82-5.353-1.465 5.112 2.942 3.267-2.68 2.413-3.093-3.434-5.17 1.166 4.828 5.36-7.297 6.57 5.966 1.91c.726-.652 1.277-1.027 1.64-1.128a1.49 1.49 0 0 1 .997.012c.297.112.793.56 1.479 1.323l13.064 14.51c.679.753 1.066 1.284 1.153 1.6.087.316.202.561.048.912-.154.351-.17.35-.89 1l-.009.007.689 6.66 7.288-6.561 4.827 5.36 3.435-3.092-4.828-5.36 2.68-2.415 4.828 5.362 3.351-3.017-4.857-5.395c2.08-2.07 3.373-3.75 4.199-5.343 1.073-2.06 1.521-4.055 1.368-6.01-.153-1.956-1.384-4.048-2.538-5.481-1.035-1.268-2.58-2.714-3.726-3.229z" fill="#fff" fillRule="nonzero" />
                                                                <path d="M222.59 95.701l-.1-.112 2.343-2.109c1.317-1.186 5.313-5.579 8.615-2.058 3.303 3.52-2.685 8.645-2.685 8.645l-2.342 2.109-.249-.276-5.582-6.199zM231.792 105.921l2.68-2.413c.545-.49 2.493-2.094 3.203-2.677a4.001 4.001 0 0 1 1.334-.738 5.606 5.606 0 0 1 1.682-.455c.366-.056.74-.048 1.103.023.16.035.32.078.476.131.4.126.78.31 1.125.548.344.22.657.485.932.787.588.652.953 1.301 1.068 2.026.205 1.038-.025 2.112-.465 3.12-1.136 2.606-3.67 4.774-3.67 4.774l-2.68 2.414-6.788-7.54z" fill="rgb(246, 190, 34)" fillRule="nonzero" />
                                                            </g>
                                                        </svg>
                                                    </div>
                                                </div>
                                                <div className="css-1dbjc4n r-1t2qqvi r-18kxxzh r-1q142lx r-r2y082 r-1oi2net r-ymttw5 r-bnwqim r-1g2xzmm">
                                                    <div className="css-1dbjc4n r-eqz5dr r-912u2z r-1g2xzmm">
                                                        <div className="css-1dbjc4n r-1l7z4oj r-1g2xzmm">
                                                            <div dir="auto" className="css-901oao r-homxoj r-320f6p r-xb2eav r-vw2c0b r-oxtfae r-1dn12g7 r-jwli3a">
                                                                {t("Want to earn passive income?")}
                                                            </div>
                                                        </div>
                                                        <div className="css-1dbjc4n r-1l7z4oj r-1g2xzmm">
                                                            <div dir="auto" className="css-901oao r-homxoj r-320f6p r-adyw6z r-oxtfae r-37tt59 r-jwli3a">
                                                                {t("Refer friends to SwapToBe and enjoy profit up to two tiers")}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className="section-feature referral-program">
                            <div className="css-1dbjc4n r-1jj8364 r-lchren r-l64lwh r-1qhn6m8 r-i023vh r-13qz1uu r-1g2xzmm">
                                <div className="css-1dbjc4n r-eqz5dr r-1g2xzmm" style={{ marginBottom: '-48px' }}>
                                    <div className="css-1dbjc4n r-1g2xzmm" style={{ paddingBottom: 48 }}>
                                        <div className="css-1dbjc4n r-eqz5dr r-1g2xzmm" style={{ marginBottom: '-48px' }}>
                                            <div className="css-1dbjc4n r-1g2xzmm" style={{ paddingBottom: 48 }}>
                                                <div>
                                                    <h4><strong>{t("Refer your friend")}</strong></h4>
                                                </div>
                                                <div className="wholesale-banner greenliner">
                                                    <h6 className="text-white" style={{ marginBottom: "15px", fontSize: "18px" }}>Referral Link</h6>
                                                    <div className="css-1dbjc4n r-18u37iz r-1w6e6rj r-6cv0rn r-1g2xzmm">
                                                        <div className="css-1dbjc4n r-1f9ke2t r-18kxxzh r-1q142lx r-m9m4bm r-ymttw5 r-bnwqim r-1g2xzmm">
                                                            <h6>
                                                                <div className="css-1dbjc4n" data-testid="copiable-area">
                                                                    <div data-focusable="true" tabIndex={0} className="css-1dbjc4n r-1loqt21 r-1otgn73 r-1i6wzkk r-lrvibr" style={{ transitionDuration: '0s' }}>
                                                                        <div className="referral_border css-1dbjc4n r-1awozwy r-adb5am r-z2wwpe r-1phboty r-rs99b7 r-18u37iz r-1wtj0ep " data-testid="port">
                                                                            <div dir="auto" className="css-901oao css-bfa6kz r-z4f626 r-320f6p r-ubezar r-oxtfae r-135wba7 r-ymttw5 r-1pyaxff r-1f1sjgu">
                                                                                <span className="css-901oao css-16my406 r-jwli3a r-320f6p r-7cikom r-1s2g6fe r-t9a87b">{port}</span>
                                                                            </div>
                                                                            <div onClick={() => { hanldeCopy(1) }} className="css-1dbjc4n r-s1qlax r-oyd9sg" >
                                                                                <div className=" css-1dbjc4n r-bnwqim r-184en5c">
                                                                                    <div className={`${state === 1 ? "active referral_copy css-1dbjc4n r-1udh08x r-633pao r-u8s1d r-j76wpu r-1jaylin" : "referral_copy css-1dbjc4n r-1udh08x r-633pao r-u8s1d r-j76wpu r-1jaylin"} `} style={{ transform: 'translateX(-46px) translateY(0px)' }}>
                                                                                        <div className="css-1dbjc4n r-orgf3d" style={{ transform: 'translateY(-5px)' }}>
                                                                                            <div className="css-1dbjc4n r-1kihuf0 r-1jkafct r-1or9b2r r-1ytwq0x r-kc8jnq r-q1p0lz r-5soawk r-417010" style={{ backgroundColor: 'rgb(41, 157, 130)' }} />
                                                                                            <div className="css-1dbjc4n r-z2wwpe r-13awgt0 r-1udh08x r-184en5c" style={{ backgroundColor: 'rgb(41, 157, 130)' }}>
                                                                                                <div dir="auto" className="css-901oao r-jwli3a r-320f6p r-1b43r93 r-uz4sb0 r-rjixqe r-1pn2ns4 r-oyd9sg r-1jaylin">
                                                                                                    Đã copy vào clipboard
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div data-focusable="true" tabIndex={0} className="css-1dbjc4n r-1loqt21 r-1otgn73">
                                                                                        <div className="css-1dbjc4n r-z2wwpe r-1udh08x">
                                                                                            <div role="button" aria-busy="false" data-focusable="true" tabIndex={0} className="css-18t94o4 css-1dbjc4n r-1loqt21 r-1otgn73 r-1i6wzkk r-lrvibr" style={{ transitionDuration: '0s' }}>
                                                                                                <div className="css-1dbjc4n r-1awozwy r-k200y r-1qnjcxv r-z2wwpe r-1yadl64 r-18u37iz r-1777fci r-5njf8e" style={{ minHeight: 40, padding: '8px 16.1px' }}>
                                                                                                    <div dir="auto" className="css-901oao r-z4f626 r-320f6p r-ubezar r-oxtfae r-135wba7 r-rjfia">
                                                                                                        copy
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </h6>
                                                        </div>
                                                        <div className="css-1dbjc4n r-1e8hiny r-18kxxzh r-1q142lx r-1ybb5if r-ymttw5 r-bnwqim r-1g2xzmm">
                                                            <div className="css-1dbjc4n r-18u37iz r-1w6e6rj r-6cv0rn r-1g2xzmm">
                                                                <div className="css-1dbjc4n r-1t2qqvi r-18kxxzh r-1q142lx r-r2y082 r-ymttw5 r-bnwqim r-1g2xzmm">
                                                                    <a href="facebook: https://www.facebook.com/SwapToBe-100970582003344/" target="_blank" role="link" data-focusable="true" className="css-4rbku5 css-18t94o4 css-1dbjc4n r-k200y r-1loqt21 r-1otgn73" rel=" noopener noreferrer" style={{ textDecorationLine: 'none' }}><div className="css-1dbjc4n r-z2wwpe r-1udh08x">
                                                                        <div role="button" aria-busy="false" data-focusable="true" tabIndex={0} className="css-18t94o4 css-1dbjc4n r-1loqt21 r-1otgn73 r-1i6wzkk r-lrvibr" style={{ transitionDuration: '0s' }}>
                                                                            <div className="css-1dbjc4n r-1awozwy r-k200y r-z2wwpe r-1yadl64 r-18u37iz r-1777fci r-1f1sjgu" style={{ backgroundColor: 'rgb(59, 89, 152)', minHeight: 48, padding: '8px 16.1px' }}>
                                                                                <div className="css-1dbjc4n" data-testid="icon-facebook-squared" style={{ height: 24, width: 24 }}>
                                                                                    <svg width={24} height={24} viewBox="0 0 27 32" fill="#FFFFFF">
                                                                                        <path d="M22.272 2.272q2.144 0 3.648 1.536t1.504 3.616v17.152q0 2.112-1.504 3.616t-3.648 1.536h-3.328V19.072h3.552l.512-4.128h-4.064v-2.656q0-.992.416-1.472t1.632-.512l2.176-.032v-3.68q-1.12-.16-3.2-.16-2.4 0-3.872 1.408t-1.44 4.064v3.04h-3.584v4.128h3.584v10.656H5.152q-2.144 0-3.648-1.536T0 24.576V7.424q0-2.112 1.504-3.616t3.648-1.536h17.12z" />
                                                                                    </svg>
                                                                                </div>
                                                                                <div dir="auto" className="css-901oao r-jwli3a r-320f6p r-ubezar r-oxtfae r-135wba7 r-1jkjb r-rjfia">
                                                                                    Facebook
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div></a>
                                                                </div>
                                                                <div className="css-1dbjc4n r-1t2qqvi r-18kxxzh r-1q142lx r-r2y082 r-ymttw5 r-bnwqim r-1g2xzmm">
                                                                    <a href="https://twitter.com/boswallet69/" target="_blank" role="link" data-focusable="true" className="css-4rbku5 css-18t94o4 css-1dbjc4n r-k200y r-1loqt21 r-1otgn73" rel=" noopener noreferrer" style={{ textDecorationLine: 'none' }}><div className="css-1dbjc4n r-z2wwpe r-1udh08x">
                                                                        <div role="button" aria-busy="false" data-focusable="true" tabIndex={0} className="css-18t94o4 css-1dbjc4n r-1loqt21 r-1otgn73 r-1i6wzkk r-lrvibr" style={{ transitionDuration: '0s' }}>
                                                                            <div className="css-1dbjc4n r-1awozwy r-k200y r-z2wwpe r-1yadl64 r-18u37iz r-1777fci r-1f1sjgu" style={{ backgroundColor: 'rgb(85, 172, 238)', minHeight: 48, padding: '8px 16.1px' }}>
                                                                                <div className="css-1dbjc4n" data-testid="icon-twitter-squared" style={{ height: 24, width: 24 }}>
                                                                                    <svg width={24} height={24} viewBox="0 0 27 32" fill="#FFFFFF">
                                                                                        <path d="M22.848 10.88q-.992.448-2.144.608 1.216-.704 1.664-2.08-1.184.672-2.4.896-1.088-1.152-2.72-1.152-1.568 0-2.656 1.088t-1.12 2.656q0 .512.096.864-2.304-.128-4.32-1.184T5.824 9.824q-.512.896-.512 1.888 0 2.048 1.632 3.136-.864-.032-1.792-.48v.032q0 1.344.896 2.4t2.176 1.28q-.512.16-.896.16-.224 0-.704-.096.384 1.152 1.344 1.888t2.144.736q-2.048 1.6-4.64 1.6-.48 0-.896-.064Q7.2 24 10.336 24q1.984 0 3.744-.64t3.008-1.696 2.144-2.432 1.344-2.912.416-3.008v-.48q1.12-.8 1.856-1.952zm4.576-3.456v17.152q0 2.112-1.504 3.616t-3.648 1.536H5.152q-2.144 0-3.648-1.536T0 24.576V7.424q0-2.112 1.504-3.616t3.648-1.536h17.12q2.144 0 3.648 1.536t1.504 3.616z" />
                                                                                    </svg>
                                                                                </div>
                                                                                <div dir="auto" className="css-901oao r-jwli3a r-320f6p r-ubezar r-oxtfae r-135wba7 r-1jkjb r-rjfia">
                                                                                    Twitter
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div></a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="css-1dbjc4n r-1g2xzmm" style={{ paddingBottom: 48 }}>
                                                <div className="css-1dbjc4n r-eqz5dr r-1g2xzmm" style={{ marginBottom: '-24px' }}>
                                                    <div className="css-1dbjc4n r-1g2xzmm" style={{ paddingBottom: 24 }}>
                                                        <div className="css-1dbjc4n r-18u37iz r-1wtj0ep r-1g2xzmm">
                                                            <h4>
                                                                <div dir="auto" className="css-901oao r-homxoj r-320f6p r-7cikom r-vw2c0b r-1s2g6fe r-t9a87b">
                                                                    {t("Your referral links list")}
                                                                </div>
                                                            </h4>
                                                        </div>
                                                    </div>
                                                    <div className="css-1dbjc4n r-1g2xzmm" style={{ paddingBottom: 24 }}>
                                                        <div className="css-1dbjc4n r-1g2xzmm">
                                                            <div className="css-1dbjc4n r-eqz5dr r-v55p34 r-1g2xzmm">
                                                                <div className="css-1dbjc4n r-xd6kpl r-1g2xzmm">
                                                                    <div className="css-1dbjc4n r-1awozwy r-6koalj r-1777fci r-1g2xzmm">
                                                                        <div data-focusable="true" tabIndex={0} className="css-1dbjc4n r-1pz39u2 r-13awgt0 r-18u37iz r-1g2xzmm">
                                                                            <div aria-disabled="true" disabled className="css-1dbjc4n r-1pz39u2 r-qklmqi r-r3u836 r-1777fci r-ymttw5 r-1yzf0co r-1g2xzmm" style={{ borderRightWidth: 1, flex: '0.5 1 0%' }}>
                                                                                <div dir="auto" className="css-901oao r-homxoj r-320f6p r-7cikom r-vw2c0b r-1s2g6fe r-t9a87b r-q4m81j">
                                                                                    #
                                                                                </div>
                                                                            </div>
                                                                            <div aria-disabled="true" disabled className="css-1dbjc4n r-1pz39u2 r-qklmqi r-r3u836 r-1777fci r-ymttw5 r-1yzf0co r-1g2xzmm" style={{ borderRightWidth: 1, flex: '0.7 1 0%' }}>
                                                                                <div dir="auto" className="css-901oao r-homxoj r-320f6p r-7cikom r-vw2c0b r-1s2g6fe r-t9a87b r-q4m81j">
                                                                                    {t("Referral ID")}
                                                                                </div>
                                                                            </div>
                                                                            <div aria-disabled="true" disabled className="css-1dbjc4n r-1pz39u2 r-qklmqi r-r3u836 r-1777fci r-ymttw5 r-1yzf0co r-1g2xzmm" style={{ borderRightWidth: 1, flex: '0.5 1 0%' }}>
                                                                                <div dir="auto" className="css-901oao r-homxoj r-320f6p r-7cikom r-vw2c0b r-1s2g6fe r-t9a87b r-q4m81j">
                                                                                    {t("You Receive / Friends Receive")}
                                                                                </div>
                                                                            </div>
                                                                            <div aria-disabled="true" disabled className="css-1dbjc4n r-1pz39u2 r-qklmqi r-r3u836 r-1777fci r-ymttw5 r-1yzf0co r-1g2xzmm" style={{ borderRightWidth: 1, flex: '0.5 1 0%' }}>
                                                                                <div dir="auto" className="css-901oao r-homxoj r-320f6p r-7cikom r-vw2c0b r-1s2g6fe r-t9a87b r-q4m81j">
                                                                                    {t("Friends")}
                                                                                </div>
                                                                            </div>
                                                                            <div aria-disabled="true" disabled className="css-1dbjc4n r-1pz39u2 r-qklmqi r-r3u836 r-13awgt0 r-1777fci r-ymttw5 r-1yzf0co r-1g2xzmm">
                                                                                <div dir="auto" className="css-901oao r-homxoj r-320f6p r-7cikom r-vw2c0b r-1s2g6fe r-t9a87b r-q4m81j">
                                                                                    {t("Action")}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div data-focusable="true" tabIndex={0} className="css-1dbjc4n r-1pz39u2 r-13awgt0 r-18u37iz r-1g2xzmm">
                                                                            <div aria-disabled="true" disabled className="css-1dbjc4n r-1pz39u2 r-qklmqi r-r3u836 r-1777fci r-ymttw5 r-1yzf0co r-1g2xzmm" style={{ flex: '0.5 1 0%' }}>
                                                                                <div dir="auto" className="css-901oao r-homxoj r-320f6p r-7cikom r-vw2c0b r-1s2g6fe r-t9a87b r-q4m81j" />
                                                                            </div>
                                                                            <div aria-disabled="true" disabled className="css-1dbjc4n r-1pz39u2 r-qklmqi r-r3u836 r-1777fci r-ymttw5 r-1yzf0co r-1g2xzmm" style={{ flex: '0.7 1 0%' }}>
                                                                                <div className="css-1dbjc4n r-dnmrzs r-1jaylin" data-testid="copiable-area">
                                                                                    <div data-focusable="true" tabIndex={0} className="css-1dbjc4n r-1loqt21 r-1otgn73 r-1i6wzkk r-lrvibr" style={{ transitionDuration: '0s' }}>
                                                                                        <div className="css-1dbjc4n r-1awozwy r-adb5am r-z2wwpe r-1phboty r-1yadl64 r-18u37iz r-1h0z5md" data-testid="3126657.25.75.hiJoFSB">
                                                                                            <div dir="auto" className="css-901oao css-bfa6kz r-z4f626 r-320f6p r-ubezar r-oxtfae r-135wba7 r-ymttw5 r-gy4na3 r-1pyaxff r-rjfia">
                                                                                                3126657.25.75.hiJoFSB
                                                                                            </div>
                                                                                            <div className="Referral_button css-1dbjc4n r-mk0yit r-rjfia">
                                                                                                <div onClick={() => { hanldeCopy(6) }} className="css-1dbjc4n r-z2wwpe r-1udh08x">
                                                                                                    <div role="button" aria-busy="false" data-focusable="true" tabIndex={0} className={`${state === 6 ? "active Referral_button-item css-18t94o4 css-1dbjc4n r-1loqt21 r-1otgn73 r-1i6wzkk r-lrvibr" : "Referral_button-item css-18t94o4 css-1dbjc4n r-1loqt21 r-1otgn73 r-1i6wzkk r-lrvibr"}`} style={{ transitionDuration: '0.25s' }}>
                                                                                                        <div className="css-1dbjc4n r-1awozwy r-k200y r-1qnjcxv r-z2wwpe r-1yadl64 r-18u37iz r-1777fci r-1pn2ns4 r-5njf8e" style={{ minHeight: 36, padding: 8 }}>
                                                                                                            <div dir="auto" className="css-901oao r-z4f626 r-320f6p r-1b43r93 r-uz4sb0 r-rjixqe r-rjfia">
                                                                                                                copy
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div
                                                                                                        className={` ${state === 6 ? "active refernal_svg  css-1dbjc4n r-1awozwy r-k200y r-1qnjcxv r-z2wwpe r-1yadl64 r-18u37iz r-1777fci r-1pn2ns4 r-5njf8e" : "refernal_svg css-1dbjc4n r-1awozwy r-k200y r-1qnjcxv r-z2wwpe r-1yadl64 r-18u37iz r-1777fci r-1pn2ns4 r-5njf8e"}`}
                                                                                                        style={{ minHeight: 36, padding: 8 }}
                                                                                                    >
                                                                                                        <div
                                                                                                            className="css-1dbjc4n"
                                                                                                            data-testid="icon-ok"
                                                                                                            style={{ height: 14, width: 14 }}
                                                                                                        >
                                                                                                            <svg width={14} height={14} viewBox="0 0 32 32" fill="#299D82">
                                                                                                                <path d="M29.856 10.112q0 .704-.512 1.216l-15.36 15.36q-.512.48-1.216.48t-1.216-.48l-8.896-8.896q-.48-.512-.48-1.216t.48-1.216l2.432-2.432q.512-.512 1.216-.512t1.216.512l5.248 5.28L24.48 6.464q.512-.512 1.216-.512t1.216.512l2.432 2.432q.512.48.512 1.216z" />
                                                                                                            </svg>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div aria-disabled="true" disabled className="css-1dbjc4n r-1pz39u2 r-qklmqi r-r3u836 r-1777fci r-ymttw5 r-1yzf0co r-1g2xzmm" style={{ flex: '0.5 1 0%' }}>
                                                                                <div dir="auto" className="css-901oao r-homxoj r-320f6p r-7cikom r-1s2g6fe r-t9a87b r-q4m81j">
                                                                                    10% / 30%
                                                                                </div>
                                                                            </div>
                                                                            <div aria-disabled="true" disabled className="css-1dbjc4n r-1pz39u2 r-qklmqi r-r3u836 r-1777fci r-ymttw5 r-1yzf0co r-1g2xzmm" style={{ flex: '0.5 1 0%' }}>
                                                                                <div dir="auto" className="css-901oao r-homxoj r-320f6p r-7cikom r-1s2g6fe r-t9a87b r-q4m81j">
                                                                                    0
                                                                                </div>
                                                                            </div>
                                                                            <div aria-disabled="true" disabled className="css-1dbjc4n r-1pz39u2 r-qklmqi r-r3u836 r-13awgt0 r-1777fci r-ymttw5 r-1yzf0co r-1g2xzmm">
                                                                                <div className="css-1dbjc4n r-1awozwy r-18u37iz r-17s6mgv r-1g2xzmm">
                                                                                    <div className="css-1dbjc4n r-z2wwpe r-1udh08x">
                                                                                        <div className="css-18t94o4 css-1dbjc4n r-1loqt21 r-1otgn73 r-1i6wzkk r-lrvibr" style={{ transitionDuration: '0s' }}>
                                                                                            <div className="css-1dbjc4n r-1awozwy r-1qnjcxv r-z2wwpe r-1yadl64 r-18u37iz r-1777fci r-1pn2ns4 r-5njf8e" style={{ minHeight: 36, padding: 8 }}>
                                                                                                <div dir="auto" className="css-901oao r-z4f626 r-320f6p r-1b43r93 r-uz4sb0 r-rjixqe r-rjfia">
                                                                                                    {t("Set default")}
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="css-1dbjc4n r-dnmrzs r-1jaylin" data-testid="copiable-area">
                                                                                        <div data-focusable="true" tabIndex={0} className="css-1dbjc4n r-1loqt21 r-1otgn73 r-1i6wzkk r-lrvibr" style={{ transitionDuration: '0s' }}>
                                                                                            <div className="css-1dbjc4n r-1awozwy r-adb5am r-z2wwpe r-1phboty r-1yadl64 r-18u37iz r-1h0z5md" >
                                                                                                <div dir="auto" className="css-901oao css-bfa6kz r-z4f626 r-320f6p r-ubezar r-oxtfae r-135wba7 r-ymttw5 r-gy4na3 r-1pyaxff r-rjfia" />
                                                                                                <div className="css-1dbjc4n r-mk0yit r-rjfia">
                                                                                                    <div onClick={() => hanldeCopy(2)} className="css-1dbjc4n r-bnwqim r-184en5c">
                                                                                                        <div className={`${state === 2 ? "active referral_copy css-1dbjc4n r-1udh08x r-633pao r-u8s1d r-j76wpu r-1jaylin" : "referral_copy css-1dbjc4n r-1udh08x r-633pao r-u8s1d r-j76wpu r-1jaylin"}`} style={{ transform: 'translateX(-56px) translateY(0px)' }}>
                                                                                                            <div className="css-1dbjc4n r-orgf3d" style={{ transform: 'translateY(-5px)' }}>
                                                                                                                <div className="css-1dbjc4n r-1kihuf0 r-1jkafct r-1or9b2r r-1ytwq0x r-kc8jnq r-q1p0lz r-5soawk r-417010" style={{ backgroundColor: 'rgb(41, 157, 130)' }} />
                                                                                                                <div className="css-1dbjc4n r-z2wwpe r-13awgt0 r-1udh08x r-184en5c" style={{ backgroundColor: 'rgb(41, 157, 130)' }}>
                                                                                                                    <div dir="auto" className="css-901oao r-jwli3a r-320f6p r-1b43r93 r-uz4sb0 r-rjixqe r-1pn2ns4 r-oyd9sg r-1jaylin">
                                                                                                                        Đã copy vào clipboard
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div data-focusable="true" tabIndex={0} className="Referral_button css-1dbjc4n r-1loqt21 r-1otgn73">
                                                                                                            <div className="css-1dbjc4n r-z2wwpe r-1udh08x">
                                                                                                                <div role="button" aria-busy="false" data-focusable="true" tabIndex={0} className="css-18t94o4 css-1dbjc4n r-1loqt21 r-1otgn73 r-1i6wzkk r-lrvibr" style={{ transitionDuration: '0s' }}>
                                                                                                                    <div className="css-1dbjc4n r-1awozwy r-k200y r-1qnjcxv r-z2wwpe r-1yadl64 r-18u37iz r-1777fci r-1pn2ns4 r-5njf8e" style={{ minHeight: 36, padding: 8 }}>
                                                                                                                        <div dir="auto" className="css-901oao r-z4f626 r-320f6p r-1b43r93 r-uz4sb0 r-rjixqe r-rjfia">
                                                                                                                            copy
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div data-focusable="true" tabIndex={0} className="css-1dbjc4n r-1pz39u2 r-13awgt0 r-18u37iz r-1g2xzmm">
                                                                            <div aria-disabled="true" disabled className="css-1dbjc4n r-1pz39u2 r-qklmqi r-r3u836 r-1777fci r-ymttw5 r-1yzf0co r-1g2xzmm" style={{ flex: '0.5 1 0%' }}>
                                                                                <div dir="auto" className="css-901oao r-homxoj r-320f6p r-7cikom r-vw2c0b r-1s2g6fe r-t9a87b r-q4m81j" />
                                                                            </div>
                                                                            <div aria-disabled="true" disabled className="css-1dbjc4n r-1pz39u2 r-qklmqi r-r3u836 r-1777fci r-ymttw5 r-1yzf0co r-1g2xzmm" style={{ flex: '0.7 1 0%' }}>
                                                                                <div className="css-1dbjc4n r-dnmrzs r-1jaylin" data-testid="copiable-area">
                                                                                    <div data-focusable="true" tabIndex={0} className="css-1dbjc4n r-1loqt21 r-1otgn73 r-1i6wzkk r-lrvibr" style={{ transitionDuration: '0s' }}>
                                                                                        <div className="css-1dbjc4n r-1awozwy r-adb5am r-z2wwpe r-1phboty r-1yadl64 r-18u37iz r-1h0z5md" data-testid="3126657.50.50.37l9rYX">
                                                                                            <div dir="auto" className="css-901oao css-bfa6kz r-z4f626 r-320f6p r-ubezar r-oxtfae r-135wba7 r-ymttw5 r-gy4na3 r-1pyaxff r-rjfia">
                                                                                                3126657.50.50.37l9rYX
                                                                                            </div>
                                                                                            <div className="Referral_button css-1dbjc4n r-mk0yit r-rjfia">
                                                                                                <div onClick={() => { hanldeCopy(7) }} className="css-1dbjc4n r-z2wwpe r-1udh08x">
                                                                                                    <div role="button" aria-busy="false" data-focusable="true" tabIndex={0} className={`${state === 7 ? "active Referral_button-item css-18t94o4 css-1dbjc4n r-1loqt21 r-1otgn73 r-1i6wzkk r-lrvibr" : " Referral_button-item css-18t94o4 css-1dbjc4n r-1loqt21 r-1otgn73 r-1i6wzkk r-lrvibr"}`} style={{ transitionDuration: '0.25s' }}>
                                                                                                        <div className="css-1dbjc4n r-1awozwy r-k200y r-1qnjcxv r-z2wwpe r-1yadl64 r-18u37iz r-1777fci r-1pn2ns4 r-5njf8e" style={{ minHeight: 36, padding: 8 }}>
                                                                                                            <div dir="auto" className="css-901oao r-z4f626 r-320f6p r-1b43r93 r-uz4sb0 r-rjixqe r-rjfia">
                                                                                                                copy
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div
                                                                                                        className={` ${state === 7 ? "active refernal_svg  css-1dbjc4n r-1awozwy r-k200y r-1qnjcxv r-z2wwpe r-1yadl64 r-18u37iz r-1777fci r-1pn2ns4 r-5njf8e" : "refernal_svg css-1dbjc4n r-1awozwy r-k200y r-1qnjcxv r-z2wwpe r-1yadl64 r-18u37iz r-1777fci r-1pn2ns4 r-5njf8e"}`}
                                                                                                        style={{ minHeight: 36, padding: 8 }}
                                                                                                    >
                                                                                                        <div
                                                                                                            className="css-1dbjc4n"
                                                                                                            data-testid="icon-ok"
                                                                                                            style={{ height: 14, width: 14 }}
                                                                                                        >
                                                                                                            <svg width={14} height={14} viewBox="0 0 32 32" fill="#299D82">
                                                                                                                <path d="M29.856 10.112q0 .704-.512 1.216l-15.36 15.36q-.512.48-1.216.48t-1.216-.48l-8.896-8.896q-.48-.512-.48-1.216t.48-1.216l2.432-2.432q.512-.512 1.216-.512t1.216.512l5.248 5.28L24.48 6.464q.512-.512 1.216-.512t1.216.512l2.432 2.432q.512.48.512 1.216z" />
                                                                                                            </svg>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div aria-disabled="true" disabled className="css-1dbjc4n r-1pz39u2 r-qklmqi r-r3u836 r-1777fci r-ymttw5 r-1yzf0co r-1g2xzmm" style={{ flex: '0.5 1 0%' }}>
                                                                                <div dir="auto" className="css-901oao r-homxoj r-320f6p r-7cikom r-1s2g6fe r-t9a87b r-q4m81j">
                                                                                    20% / 20%
                                                                                </div>
                                                                            </div>
                                                                            <div aria-disabled="true" disabled className="css-1dbjc4n r-1pz39u2 r-qklmqi r-r3u836 r-1777fci r-ymttw5 r-1yzf0co r-1g2xzmm" style={{ flex: '0.5 1 0%' }}>
                                                                                <div dir="auto" className="css-901oao r-homxoj r-320f6p r-7cikom r-1s2g6fe r-t9a87b r-q4m81j">
                                                                                    0
                                                                                </div>
                                                                            </div>
                                                                            <div aria-disabled="true" disabled className="css-1dbjc4n r-1pz39u2 r-qklmqi r-r3u836 r-13awgt0 r-1777fci r-ymttw5 r-1yzf0co r-1g2xzmm">
                                                                                <div className="css-1dbjc4n r-1awozwy r-18u37iz r-17s6mgv r-1g2xzmm">
                                                                                    <div className="css-1dbjc4n r-z2wwpe r-1udh08x">
                                                                                        <div role="button" aria-busy="false" data-focusable="true" tabIndex={0} className="css-18t94o4 css-1dbjc4n r-1loqt21 r-1otgn73 r-1i6wzkk r-lrvibr" style={{ transitionDuration: '0s' }}>
                                                                                            <div className="css-1dbjc4n r-1awozwy r-1qnjcxv r-z2wwpe r-1yadl64 r-18u37iz r-1777fci r-1pn2ns4 r-5njf8e" style={{ minHeight: 36, padding: 8 }}>
                                                                                                <div dir="auto" className="css-901oao r-z4f626 r-320f6p r-1b43r93 r-uz4sb0 r-rjixqe r-rjfia">
                                                                                                    {t("Set default")}
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="css-1dbjc4n r-dnmrzs r-1jaylin" data-testid="copiable-area">
                                                                                        <div data-focusable="true" tabIndex={0} className="css-1dbjc4n r-1loqt21 r-1otgn73 r-1i6wzkk r-lrvibr" style={{ transitionDuration: '0s' }}>
                                                                                            <div className="css-1dbjc4n r-1awozwy r-adb5am r-z2wwpe r-1phboty r-1yadl64 r-18u37iz r-1h0z5md" >
                                                                                                <div dir="auto" className="css-901oao css-bfa6kz r-z4f626 r-320f6p r-ubezar r-oxtfae r-135wba7 r-ymttw5 r-gy4na3 r-1pyaxff r-rjfia" />
                                                                                                <div className="css-1dbjc4n r-mk0yit r-rjfia">
                                                                                                    <div onClick={() => hanldeCopy(3)} className="css-1dbjc4n r-bnwqim r-184en5c">
                                                                                                        <div className={`${state === 3 ? "active referral_copy css-1dbjc4n r-1udh08x r-633pao r-u8s1d r-j76wpu r-1jaylin" : "referral_copy css-1dbjc4n r-1udh08x r-633pao r-u8s1d r-j76wpu r-1jaylin"}`} style={{ transform: 'translateX(-56px) translateY(0px)' }}>
                                                                                                            <div className="css-1dbjc4n r-orgf3d" style={{ transform: 'translateY(-5px)' }}>
                                                                                                                <div className="css-1dbjc4n r-1kihuf0 r-1jkafct r-1or9b2r r-1ytwq0x r-kc8jnq r-q1p0lz r-5soawk r-417010" style={{ backgroundColor: 'rgb(41, 157, 130)' }} />
                                                                                                                <div className="css-1dbjc4n r-z2wwpe r-13awgt0 r-1udh08x r-184en5c" style={{ backgroundColor: 'rgb(41, 157, 130)' }}>
                                                                                                                    <div dir="auto" className="css-901oao r-jwli3a r-320f6p r-1b43r93 r-uz4sb0 r-rjixqe r-1pn2ns4 r-oyd9sg r-1jaylin">
                                                                                                                        Đã copy vào clipboard
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div data-focusable="true" tabIndex={0} className="Referral_button css-1dbjc4n r-1loqt21 r-1otgn73">
                                                                                                            <div className="css-1dbjc4n r-z2wwpe r-1udh08x">
                                                                                                                <div role="button" aria-busy="false" data-focusable="true" tabIndex={0} className="css-18t94o4 css-1dbjc4n r-1loqt21 r-1otgn73 r-1i6wzkk r-lrvibr" style={{ transitionDuration: '0s' }}>
                                                                                                                    <div className="css-1dbjc4n r-1awozwy r-k200y r-1qnjcxv r-z2wwpe r-1yadl64 r-18u37iz r-1777fci r-1pn2ns4 r-5njf8e" style={{ minHeight: 36, padding: 8 }}>
                                                                                                                        <div dir="auto" className="css-901oao r-z4f626 r-320f6p r-1b43r93 r-uz4sb0 r-rjixqe r-rjfia">
                                                                                                                            copy
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div data-focusable="true" tabIndex={0} className="css-1dbjc4n r-1pz39u2 r-13awgt0 r-18u37iz r-1g2xzmm">
                                                                            <div aria-disabled="true" disabled className="css-1dbjc4n r-1pz39u2 r-qklmqi r-r3u836 r-1777fci r-ymttw5 r-1yzf0co r-1g2xzmm" style={{ flex: '0.5 1 0%' }}>
                                                                                <div dir="auto" className="css-901oao r-homxoj r-320f6p r-7cikom r-vw2c0b r-1s2g6fe r-t9a87b r-q4m81j" />
                                                                            </div>
                                                                            <div aria-disabled="true" disabled className="css-1dbjc4n r-1pz39u2 r-qklmqi r-r3u836 r-1777fci r-ymttw5 r-1yzf0co r-1g2xzmm" style={{ flex: '0.7 1 0%' }}>
                                                                                <div className="css-1dbjc4n r-dnmrzs r-1jaylin" data-testid="copiable-area">
                                                                                    <div data-focusable="true" tabIndex={0} className="css-1dbjc4n r-1loqt21 r-1otgn73 r-1i6wzkk r-lrvibr" style={{ transitionDuration: '0s' }}>
                                                                                        <div className="css-1dbjc4n r-1awozwy r-adb5am r-z2wwpe r-1phboty r-1yadl64 r-18u37iz r-1h0z5md" data-testid="3126657.75.25.B203s4F">
                                                                                            <div dir="auto" className="css-901oao css-bfa6kz r-z4f626 r-320f6p r-ubezar r-oxtfae r-135wba7 r-ymttw5 r-gy4na3 r-1pyaxff r-rjfia">
                                                                                                3126657.75.25.B203s4F
                                                                                            </div>
                                                                                            <div onClick={() => { hanldeCopy(8) }} className="Referral_button css-1dbjc4n r-mk0yit r-rjfia">
                                                                                                <div className="css-1dbjc4n r-z2wwpe r-1udh08x">
                                                                                                    <div role="button" aria-busy="false" data-focusable="true" tabIndex={0} className={`${state === 8 ? "active Referral_button-item css-18t94o4 css-1dbjc4n r-1loqt21 r-1otgn73 r-1i6wzkk r-lrvibr" : "Referral_button-item css-18t94o4 css-1dbjc4n r-1loqt21 r-1otgn73 r-1i6wzkk r-lrvibr"}`} style={{ transitionDuration: '0.25s' }}>
                                                                                                        <div className="css-1dbjc4n r-1awozwy r-k200y r-1qnjcxv r-z2wwpe r-1yadl64 r-18u37iz r-1777fci r-1pn2ns4 r-5njf8e" style={{ minHeight: 36, padding: 8 }}>
                                                                                                            <div dir="auto" className="css-901oao r-z4f626 r-320f6p r-1b43r93 r-uz4sb0 r-rjixqe r-rjfia">
                                                                                                                copy
                                                                                                            </div>
                                                                                                        </div>

                                                                                                    </div>
                                                                                                    <div
                                                                                                        className={` ${state === 8 ? "active refernal_svg  css-1dbjc4n r-1awozwy r-k200y r-1qnjcxv r-z2wwpe r-1yadl64 r-18u37iz r-1777fci r-1pn2ns4 r-5njf8e" : "refernal_svg css-1dbjc4n r-1awozwy r-k200y r-1qnjcxv r-z2wwpe r-1yadl64 r-18u37iz r-1777fci r-1pn2ns4 r-5njf8e"}`}
                                                                                                        style={{ minHeight: 36, padding: 8 }}
                                                                                                    >
                                                                                                        <div
                                                                                                            className="css-1dbjc4n"
                                                                                                            data-testid="icon-ok"
                                                                                                            style={{ height: 14, width: 14 }}
                                                                                                        >
                                                                                                            <svg width={14} height={14} viewBox="0 0 32 32" fill="#299D82">
                                                                                                                <path d="M29.856 10.112q0 .704-.512 1.216l-15.36 15.36q-.512.48-1.216.48t-1.216-.48l-8.896-8.896q-.48-.512-.48-1.216t.48-1.216l2.432-2.432q.512-.512 1.216-.512t1.216.512l5.248 5.28L24.48 6.464q.512-.512 1.216-.512t1.216.512l2.432 2.432q.512.48.512 1.216z" />
                                                                                                            </svg>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div aria-disabled="true" disabled className="css-1dbjc4n r-1pz39u2 r-qklmqi r-r3u836 r-1777fci r-ymttw5 r-1yzf0co r-1g2xzmm" style={{ flex: '0.5 1 0%' }}>
                                                                                <div dir="auto" className="css-901oao r-homxoj r-320f6p r-7cikom r-1s2g6fe r-t9a87b r-q4m81j">
                                                                                    30% / 10%
                                                                                </div>
                                                                            </div>
                                                                            <div aria-disabled="true" disabled className="css-1dbjc4n r-1pz39u2 r-qklmqi r-r3u836 r-1777fci r-ymttw5 r-1yzf0co r-1g2xzmm" style={{ flex: '0.5 1 0%' }}>
                                                                                <div dir="auto" className="css-901oao r-homxoj r-320f6p r-7cikom r-1s2g6fe r-t9a87b r-q4m81j">
                                                                                    0
                                                                                </div>
                                                                            </div>
                                                                            <div aria-disabled="true" disabled className="css-1dbjc4n r-1pz39u2 r-qklmqi r-r3u836 r-13awgt0 r-1777fci r-ymttw5 r-1yzf0co r-1g2xzmm">
                                                                                <div className="css-1dbjc4n r-1awozwy r-18u37iz r-17s6mgv r-1g2xzmm">
                                                                                    <div className="css-1dbjc4n r-z2wwpe r-1udh08x">
                                                                                        <div role="button" aria-busy="false" data-focusable="true" tabIndex={0} className="css-18t94o4 css-1dbjc4n r-1loqt21 r-1otgn73 r-1i6wzkk r-lrvibr" style={{ transitionDuration: '0s' }}>
                                                                                            <div className="css-1dbjc4n r-1awozwy r-1qnjcxv r-z2wwpe r-1yadl64 r-18u37iz r-1777fci r-1pn2ns4 r-5njf8e" style={{ minHeight: 36, padding: 8 }}>
                                                                                                <div dir="auto" className="css-901oao r-z4f626 r-320f6p r-1b43r93 r-uz4sb0 r-rjixqe r-rjfia">
                                                                                                    {t("Set default")}
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="css-1dbjc4n r-dnmrzs r-1jaylin" data-testid="copiable-area">
                                                                                        <div data-focusable="true" tabIndex={0} className="css-1dbjc4n r-1loqt21 r-1otgn73 r-1i6wzkk r-lrvibr" style={{ transitionDuration: '0s' }}>
                                                                                            <div className="css-1dbjc4n r-1awozwy r-adb5am r-z2wwpe r-1phboty r-1yadl64 r-18u37iz r-1h0z5md" >
                                                                                                <div dir="auto" className="css-901oao css-bfa6kz r-z4f626 r-320f6p r-ubezar r-oxtfae r-135wba7 r-ymttw5 r-gy4na3 r-1pyaxff r-rjfia" />
                                                                                                <div className="css-1dbjc4n r-mk0yit r-rjfia">
                                                                                                    <div onClick={() => hanldeCopy(4)} className="css-1dbjc4n r-bnwqim r-184en5c">
                                                                                                        <div className={`${state === 4 ? "active referral_copy css-1dbjc4n r-1udh08x r-633pao r-u8s1d r-j76wpu r-1jaylin" : "referral_copy css-1dbjc4n r-1udh08x r-633pao r-u8s1d r-j76wpu r-1jaylin"}`} style={{ transform: 'translateX(-56px) translateY(0px)' }}>
                                                                                                            <div className="css-1dbjc4n r-orgf3d" style={{ transform: 'translateY(-5px)' }}>
                                                                                                                <div className="css-1dbjc4n r-1kihuf0 r-1jkafct r-1or9b2r r-1ytwq0x r-kc8jnq r-q1p0lz r-5soawk r-417010" style={{ backgroundColor: 'rgb(41, 157, 130)' }} />
                                                                                                                <div className="css-1dbjc4n r-z2wwpe r-13awgt0 r-1udh08x r-184en5c" style={{ backgroundColor: 'rgb(41, 157, 130)' }}>
                                                                                                                    <div dir="auto" className="css-901oao r-jwli3a r-320f6p r-1b43r93 r-uz4sb0 r-rjixqe r-1pn2ns4 r-oyd9sg r-1jaylin">
                                                                                                                        Đã copy vào clipboard
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div data-focusable="true" tabIndex={0} className="Referral_button css-1dbjc4n r-1loqt21 r-1otgn73">
                                                                                                            <div className="css-1dbjc4n r-z2wwpe r-1udh08x">
                                                                                                                <div role="button" aria-busy="false" data-focusable="true" tabIndex={0} className="css-18t94o4 css-1dbjc4n r-1loqt21 r-1otgn73 r-1i6wzkk r-lrvibr" style={{ transitionDuration: '0s' }}>
                                                                                                                    <div className="css-1dbjc4n r-1awozwy r-k200y r-1qnjcxv r-z2wwpe r-1yadl64 r-18u37iz r-1777fci r-1pn2ns4 r-5njf8e" style={{ minHeight: 36, padding: 8 }}>
                                                                                                                        <div dir="auto" className="css-901oao r-z4f626 r-320f6p r-1b43r93 r-uz4sb0 r-rjixqe r-rjfia">
                                                                                                                            copy
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div data-focusable="true" tabIndex={0} className="css-1dbjc4n r-1pz39u2 r-13awgt0 r-18u37iz r-1g2xzmm">
                                                                            <div aria-disabled="true" disabled className="css-1dbjc4n r-1pz39u2 r-qklmqi r-r3u836 r-1777fci r-ymttw5 r-1yzf0co r-1g2xzmm" style={{ flex: '0.5 1 0%' }}>
                                                                                <div dir="auto" className="css-901oao  r-homxoj r-320f6p r-7cikom r-vw2c0b r-1s2g6fe r-t9a87b r-q4m81j">
                                                                                    <div className="css-1dbjc4n r-k200y green  r-1jkafct r-rs99b7 r-xoduu5 r-1pn2ns4 r-oyd9sg" style={{ borderColor: '#3fbf7a' }}>
                                                                                        <span className="css-901oao css-16my406 r-jwli3a r-320f6p r-1b43r93 r-vw2c0b r-uz4sb0 r-rjixqe">{t("Default")}</span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div aria-disabled="true" disabled className="css-1dbjc4n r-1pz39u2 r-qklmqi r-r3u836 r-1777fci r-ymttw5 r-1yzf0co r-1g2xzmm" style={{ flex: '0.7 1 0%' }}>
                                                                                <div className="css-1dbjc4n r-dnmrzs r-1jaylin" data-testid="copiable-area">
                                                                                    <div data-focusable="true" tabIndex={0} className="css-1dbjc4n r-1loqt21 r-1otgn73 r-1i6wzkk r-lrvibr" style={{ transitionDuration: '0s' }}>
                                                                                        <div className="css-1dbjc4n r-1awozwy r-adb5am r-z2wwpe r-1phboty r-1yadl64 r-18u37iz r-1h0z5md" data-testid={3126657}>
                                                                                            <div dir="auto" className="css-901oao css-bfa6kz r-z4f626 r-320f6p r-ubezar r-oxtfae r-135wba7 r-ymttw5 r-gy4na3 r-1pyaxff r-rjfia">
                                                                                                3126657
                                                                                            </div>
                                                                                            <div onClick={() => hanldeCopy(9)} className="Referral_button css-1dbjc4n r-mk0yit r-rjfia">
                                                                                                <div className="css-1dbjc4n r-z2wwpe r-1udh08x">
                                                                                                    <div role="button" aria-busy="false" data-focusable="true" tabIndex={0} className={` ${state === 9 ? "active Referral_button-item css-18t94o4 css-1dbjc4n r-1loqt21 r-1otgn73 r-1i6wzkk r-lrvibr" : "Referral_button-item css-18t94o4 css-1dbjc4n r-1loqt21 r-1otgn73 r-1i6wzkk r-lrvibr"}`} style={{ transitionDuration: '0.25s' }}>
                                                                                                        <div className="css-1dbjc4n r-1awozwy r-k200y r-1qnjcxv r-z2wwpe r-1yadl64 r-18u37iz r-1777fci r-1pn2ns4 r-5njf8e" style={{ minHeight: 36, padding: 8 }}>
                                                                                                            <div dir="auto" className="css-901oao r-z4f626 r-320f6p r-1b43r93 r-uz4sb0 r-rjixqe r-rjfia">
                                                                                                                copy
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div
                                                                                                        className={` ${state === 9 ? "active refernal_svg  css-1dbjc4n r-1awozwy r-k200y r-1qnjcxv r-z2wwpe r-1yadl64 r-18u37iz r-1777fci r-1pn2ns4 r-5njf8e" : "refernal_svg css-1dbjc4n r-1awozwy r-k200y r-1qnjcxv r-z2wwpe r-1yadl64 r-18u37iz r-1777fci r-1pn2ns4 r-5njf8e"}`}
                                                                                                        style={{ minHeight: 36, padding: 8 }}
                                                                                                    >
                                                                                                        <div
                                                                                                            className="css-1dbjc4n"
                                                                                                            data-testid="icon-ok"
                                                                                                            style={{ height: 14, width: 14 }}
                                                                                                        >
                                                                                                            <svg width={14} height={14} viewBox="0 0 32 32" fill="#299D82">
                                                                                                                <path d="M29.856 10.112q0 .704-.512 1.216l-15.36 15.36q-.512.48-1.216.48t-1.216-.48l-8.896-8.896q-.48-.512-.48-1.216t.48-1.216l2.432-2.432q.512-.512 1.216-.512t1.216.512l5.248 5.28L24.48 6.464q.512-.512 1.216-.512t1.216.512l2.432 2.432q.512.48.512 1.216z" />
                                                                                                            </svg>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div aria-disabled="true" disabled className="css-1dbjc4n r-1pz39u2 r-qklmqi r-r3u836 r-1777fci r-ymttw5 r-1yzf0co r-1g2xzmm" style={{ flex: '0.5 1 0%' }}>
                                                                                <div dir="auto" className="css-901oao r-homxoj r-320f6p r-7cikom r-1s2g6fe r-t9a87b r-q4m81j">
                                                                                    40% / 0%
                                                                                </div>
                                                                            </div>
                                                                            <div aria-disabled="true" disabled className="css-1dbjc4n r-1pz39u2 r-qklmqi r-r3u836 r-1777fci r-ymttw5 r-1yzf0co r-1g2xzmm" style={{ flex: '0.5 1 0%' }}>
                                                                                <div dir="auto" className="css-901oao r-homxoj r-320f6p r-7cikom r-1s2g6fe r-t9a87b r-q4m81j">
                                                                                    0
                                                                                </div>
                                                                            </div>
                                                                            <div aria-disabled="true" disabled className="css-1dbjc4n r-1pz39u2 r-qklmqi r-r3u836 r-13awgt0 r-1777fci r-ymttw5 r-1yzf0co r-1g2xzmm">
                                                                                <div className="css-1dbjc4n r-1awozwy r-18u37iz r-17s6mgv r-1g2xzmm">
                                                                                    <div className="css-1dbjc4n r-dnmrzs r-1jaylin" data-testid="copiable-area">
                                                                                        <div data-focusable="true" tabIndex={0} className="css-1dbjc4n r-1loqt21 r-1otgn73 r-1i6wzkk r-lrvibr" style={{ transitionDuration: '0s' }}>
                                                                                            <div className="css-1dbjc4n r-1awozwy r-adb5am r-z2wwpe r-1phboty r-1yadl64 r-18u37iz r-1h0z5md" >
                                                                                                <div dir="auto" className="css-901oao css-bfa6kz r-z4f626 r-320f6p r-ubezar r-oxtfae r-135wba7 r-ymttw5 r-gy4na3 r-1pyaxff r-rjfia" />
                                                                                                <div className="css-1dbjc4n r-mk0yit r-rjfia">
                                                                                                    <div onClick={() => hanldeCopy(5)} className="css-1dbjc4n r-bnwqim r-184en5c">
                                                                                                        <div className={`${state === 5 ? "active referral_copy css-1dbjc4n r-1udh08x r-633pao r-u8s1d r-j76wpu r-1jaylin" : "referral_copy css-1dbjc4n r-1udh08x r-633pao r-u8s1d r-j76wpu r-1jaylin"}`} style={{ transform: 'translateX(-56px) translateY(0px)' }}>
                                                                                                            <div className="css-1dbjc4n r-orgf3d" style={{ transform: 'translateY(-5px)' }}>
                                                                                                                <div className="css-1dbjc4n r-1kihuf0 r-1jkafct r-1or9b2r r-1ytwq0x r-kc8jnq r-q1p0lz r-5soawk r-417010" style={{ backgroundColor: 'rgb(41, 157, 130)' }} />
                                                                                                                <div className="css-1dbjc4n r-z2wwpe r-13awgt0 r-1udh08x r-184en5c" style={{ backgroundColor: 'rgb(41, 157, 130)' }}>
                                                                                                                    <div dir="auto" className="css-901oao r-jwli3a r-320f6p r-1b43r93 r-uz4sb0 r-rjixqe r-1pn2ns4 r-oyd9sg r-1jaylin">
                                                                                                                        Đã copy vào clipboard
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div data-focusable="true" tabIndex={0} className="css-1dbjc4n r-1loqt21 r-1otgn73">
                                                                                                            <div className="css-1dbjc4n r-z2wwpe r-1udh08x">
                                                                                                                <div role="button" aria-busy="false" data-focusable="true" tabIndex={0} className="css-18t94o4 css-1dbjc4n r-1loqt21 r-1otgn73 r-1i6wzkk r-lrvibr" style={{ transitionDuration: '0s' }}>
                                                                                                                    <div className="css-1dbjc4n r-1awozwy r-k200y r-1qnjcxv r-z2wwpe r-1yadl64 r-18u37iz r-1777fci r-1pn2ns4 r-5njf8e" style={{ minHeight: 36, padding: 8 }}>
                                                                                                                        <div dir="auto" className="css-901oao r-z4f626 r-320f6p r-1b43r93 r-uz4sb0 r-rjixqe r-rjfia">
                                                                                                                            copy
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="css-1dbjc4n r-1g2xzmm" style={{ paddingBottom: 48 }}>
                                                <div className="css-1dbjc4n r-z2wwpe r-1guathk r-1h4fu65 r-1g2xzmm" style={{ backgroundColor: 'rgb(243, 243, 243)' }}>
                                                    <div className="text-20-sm">
                                                        <strong>{t("Share SwapToBe with friends")}</strong>
                                                    </div>
                                                    <span>
                                                        {t("Email friends who've never tried SwapToBe, and we'll send them a reminder, too.")}
                                                    </span>
                                                    <div className="row pt-8">
                                                        <div className="col-8 text-input">
                                                            <input name="email" type="text" placeholder="Nhập email" className="form-control text-16" />
                                                        </div>
                                                        <div className="col-4 pl-0">
                                                            <div className="css-1dbjc4n r-z2wwpe r-1udh08x">
                                                                <div role="button" aria-busy="false" data-focusable="true" tabIndex={0} className="css-18t94o4 css-1dbjc4n r-1loqt21 r-1otgn73 r-1i6wzkk r-lrvibr" style={{ transitionDuration: '0s' }}>
                                                                    <div className="css-1dbjc4n r-1awozwy r-k200y r-bis4jr r-z2wwpe r-1yadl64 r-18u37iz r-1777fci r-5njf8e" style={{ minHeight: 40, padding: '8px 16.1px' }}>
                                                                        <div dir="auto" className="css-901oao r-jwli3a r-320f6p r-ubezar r-oxtfae r-135wba7 r-rjfia">
                                                                            {t("Send")}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="css-1dbjc4n r-1g2xzmm" style={{ paddingBottom: 48 }}>
                                                <div className="css-1dbjc4n r-14lw9ot r-z2wwpe r-1guathk r-1h4fu65 r-1g2xzmm" data-testid="referral-with-coin-gift">
                                                    <div className="css-1dbjc4n r-eqz5dr r-912u2z r-1g2xzmm">
                                                        <div className="css-1dbjc4n r-1l7z4oj r-1g2xzmm">
                                                            <div className="css-1dbjc4n r-18u37iz r-1w6e6rj r-6cv0rn r-1g2xzmm">
                                                                <div className="css-1dbjc4n r-1f9ke2t r-18kxxzh r-1q142lx r-m9m4bm r-ymttw5 r-bnwqim r-1g2xzmm">
                                                                    <form action="#">
                                                                        <div className="css-1dbjc4n r-eqz5dr r-912u2z r-1g2xzmm">
                                                                            <div className="css-1dbjc4n r-1l7z4oj r-1g2xzmm">
                                                                                <div dir="auto" className="css-901oao r-homxoj r-320f6p r-adyw6z r-vw2c0b r-oxtfae r-37tt59">
                                                                                    {t("Give coin gift to your friends")}
                                                                                </div>
                                                                            </div>
                                                                            <div className="css-1dbjc4n r-1l7z4oj r-1g2xzmm">
                                                                                <div className="react-phone-number-input">
                                                                                    <div className="react-phone-number-input__row">
                                                                                        <div className="react-phone-number-input__country react-phone-number-input__country--native">
                                                                                            <div className="react-phone-number-input__icon">
                                                                                                <img alt="VN" className="react-phone-number-input__icon-image" src="https://lipis.github.io/flag-icon-css/flags/4x3/vn.svg" />
                                                                                            </div>
                                                                                            <select aria-label="Country" className="react-phone-number-input__country-select"><option value="VN">Vietnam</option></select>
                                                                                            <div className="react-phone-number-input__country-select-arrow" />
                                                                                        </div>
                                                                                        <input type="tel" autoComplete="off" testid="coin-gift-receiver-number" placeholder="Nhập số điện thoại" className="react-phone-number-input__input react-phone-number-input__phone react-phone-number-input__input--style" />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="css-1dbjc4n r-1l7z4oj r-1g2xzmm">
                                                                                <div className="css-1dbjc4n" data-testid="coin-amount">
                                                                                    <div className="css-1dbjc4n r-eqz5dr r-v55p34 r-1g2xzmm" data-testid="text-input text-input-lg">
                                                                                        <div className="css-1dbjc4n r-xd6kpl r-1g2xzmm">
                                                                                            <div className="css-1dbjc4n r-1g2xzmm">
                                                                                                <div className="css-1dbjc4n r-f1odvy">
                                                                                                    <div className="css-1dbjc4n r-1awozwy r-18u37iz r-pya0ce r-1g2xzmm">
                                                                                                        <div className="css-1dbjc4n r-1wbh5a2 r-1qortcd r-1g2xzmm">
                                                                                                            <div dir="auto" className="css-901oao r-1814a8r r-320f6p r-1b43r93 r-vw2c0b r-uz4sb0 r-rjixqe">
                                                                                                                {t("Amount of USDT")}
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="css-1dbjc4n r-1g2xzmm" data-testid="undefined has-1-support-items">
                                                                                                    <input className='referral_border-color' inputMode="text" id="coinGiftAmount" name="coinAmount" autoComplete="off" type="text" defaultValue="0.25" />
                                                                                                    <div className="css-1dbjc4n r-1awozwy r-1p0dtai r-6koalj r-18u37iz r-15ighfx r-ywje51 r-u8s1d r-zchlnj r-ipm5af r-1jaylin" data-testid="input-support" style={{ height: 'initial' }}>
                                                                                                        <div className="css-1dbjc4n r-7er5f2 r-1b7u577" data-testid="currency">
                                                                                                            <div dir="auto" className="css-901oao r-1814a8r r-320f6p r-7cikom r-vw2c0b r-1s2g6fe r-t9a87b">
                                                                                                                USDT
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="css-1dbjc4n r-1l7z4oj r-1g2xzmm" style={{ marginBottom: '10px' }}>
                                                                                <div className="css-1dbjc4n r-18u37iz r-1w6e6rj r-6cv0rn r-1g2xzmm">
                                                                                    <div className="css-1dbjc4n r-1e8hiny r-18kxxzh r-1q142lx r-1ybb5if r-ymttw5 r-bnwqim r-1g2xzmm">
                                                                                        <button role="button" disabled type="submit" className="btn greenBoder btn-block btn-md">
                                                                                            {t("Give coin gift now")}
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </form>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="css-1dbjc4n r-1l7z4oj r-1g2xzmm">
                                                            <div className="css-1dbjc4n r-1g2xzmm">
                                                                <div className="css-1dbjc4n r-eqz5dr r-v55p34 r-1g2xzmm">
                                                                    <div className="css-1dbjc4n r-xd6kpl r-1g2xzmm">
                                                                        <div className="css-1dbjc4n r-eqz5dr r-v55p34 r-1g2xzmm">
                                                                            <div className="css-1dbjc4n r-xd6kpl r-1g2xzmm">
                                                                                <div dir="auto" className="css-901oao r-homxoj r-320f6p r-adyw6z r-vw2c0b r-oxtfae r-37tt59">
                                                                                    {t("Your coin gift list")}
                                                                                </div>
                                                                            </div>
                                                                            <div className="css-1dbjc4n r-xd6kpl r-1g2xzmm">
                                                                                <div dir="auto" className="css-901oao r-homxoj r-320f6p r-7cikom r-36ujnk r-1s2g6fe r-t9a87b">
                                                                                    {t("You haven't given any coin gift yet")}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="referral_account css-1dbjc4n r-1g2xzmm" style={{ paddingBottom: 48 }}>
                                                <h4>
                                                    <div dir="auto" className="css-901oao r-homxoj r-320f6p r-7cikom r-vw2c0b r-1s2g6fe r-t9a87b">
                                                        Your Referral Account
                                                    </div>
                                                </h4>
                                                <div className="css-1dbjc4n r-18u37iz r-1w6e6rj r-6cv0rn r-1g2xzmm">
                                                    <div className="css-1dbjc4n r-1e8hiny r-18kxxzh r-1q142lx r-1ybb5if r-ymttw5 r-bnwqim r-1g2xzmm">
                                                        <div className="css-1dbjc4n r-14lw9ot r-z2wwpe r-1guathk r-1h4fu65 r-1g2xzmm">
                                                            <div className="css-1dbjc4n r-eqz5dr r-1g2xzmm" style={{ marginBottom: '-4px' }}>
                                                                <div className="css-1dbjc4n r-1g2xzmm" style={{ paddingBottom: 4 }}>
                                                                    <div dir="auto" className="css-901oao r-homxoj r-320f6p r-7cikom r-1s2g6fe r-t9a87b">
                                                                        {t("You've Earned:")}
                                                                    </div>
                                                                </div>
                                                                <div className="css-1dbjc4n r-1g2xzmm" style={{ paddingBottom: 4 }}>
                                                                    <div dir="auto" className="css-901oao r-homxoj r-320f6p r-7cikom r-1s2g6fe r-t9a87b">
                                                                        <span className="css-901oao css-16my406 r-320f6p r-1x35g6 r-vw2c0b r-oxtfae r-37tt59">
                                                                            <span className="referral_bold css-901oao css-16my406">0</span>
                                                                            <span className="referral_account-text  referral_bold css-901oao css-16my406 r-1mdqdya">USDT</span></span>
                                                                    </div>
                                                                </div>
                                                                <div className="css-1dbjc4n r-1g2xzmm" style={{ paddingBottom: 4 }}>
                                                                    <div dir="auto" className="css-901oao r-1h08tih r-1loqt21 r-320f6p r-1b43r93 r-uz4sb0 r-rjixqe">
                                                                        {t("View Detail")}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="css-1dbjc4n r-1e8hiny r-18kxxzh r-1q142lx r-1ybb5if r-ymttw5 r-bnwqim r-1g2xzmm">
                                                        <div className="css-1dbjc4n r-14lw9ot r-z2wwpe r-1guathk r-1h4fu65 r-1g2xzmm" data-testid="referred-friends-badge">
                                                            <div className="css-1dbjc4n r-eqz5dr r-1g2xzmm" style={{ marginBottom: '-4px' }}>
                                                                <div className="css-1dbjc4n r-1g2xzmm" style={{ paddingBottom: 4 }}>
                                                                    <div dir="auto" className="css-901oao r-homxoj r-320f6p r-7cikom r-1s2g6fe r-t9a87b">
                                                                        {t("Number of Friends:")}
                                                                    </div>
                                                                </div>
                                                                <div className="css-1dbjc4n r-1g2xzmm" style={{ paddingBottom: 4 }}>
                                                                    <div dir="auto" className="css-901oao r-homxoj r-320f6p r-7cikom r-1s2g6fe r-t9a87b">
                                                                        <div className="css-1dbjc4n r-xoduu5 r-18u37iz r-1w6e6rj r-6cv0rn r-1g2xzmm">
                                                                            <div className="css-1dbjc4n r-xoduu5 r-1t2qqvi r-18kxxzh r-1q142lx r-r2y082 r-ymttw5 r-bnwqim r-1g2xzmm">
                                                                                <span className="css-901oao css-16my406 r-320f6p r-1x35g6 r-oxtfae r-37tt59"><span className="referral_bold css-901oao css-16my406 r-vw2c0b">0</span>
                                                                                    <span className="referral_account-text referral_bac css-901oao css-16my406 r-320f6p r-ubezar r-oxtfae r-135wba7">{t("Tier")} 1</span></span>
                                                                            </div>
                                                                            <div className="css-1dbjc4n r-xoduu5 r-1t2qqvi r-18kxxzh r-1q142lx r-r2y082 r-ymttw5 r-bnwqim r-1g2xzmm">
                                                                                <span className="css-901oao css-16my406 r-320f6p r-1x35g6 r-oxtfae r-37tt59"><span className="referral_bold css-901oao css-16my406 r-vw2c0b">0</span>
                                                                                    <span className="referral_account-text referral_bac css-901oao css-16my406 r-320f6p r-ubezar r-oxtfae r-135wba7">{t("Tier")} 2</span></span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="css-1dbjc4n r-1g2xzmm" style={{ paddingBottom: 4 }}>
                                                                    <div dir="auto" className="css-901oao r-1h08tih r-1loqt21 r-320f6p r-1b43r93 r-uz4sb0 r-rjixqe">
                                                                        {t("View Detail")}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="css-1dbjc4n r-1e8hiny r-18kxxzh r-1q142lx r-1ybb5if r-ymttw5 r-bnwqim r-1g2xzmm">
                                                        <div className="css-1dbjc4n r-14lw9ot r-z2wwpe r-1guathk r-1h4fu65 r-1g2xzmm">
                                                            <div className="css-1dbjc4n r-eqz5dr r-1g2xzmm" style={{ marginBottom: '-4px' }}>
                                                                <div className="css-1dbjc4n r-1g2xzmm" style={{ paddingBottom: 4 }}>
                                                                    <div dir="auto" className="css-901oao r-homxoj r-320f6p r-7cikom r-1s2g6fe r-t9a87b">
                                                                        {t("Your Ranking:")}
                                                                    </div>
                                                                </div>
                                                                <div className="css-1dbjc4n r-1g2xzmm" style={{ paddingBottom: 4 }}>
                                                                    <div dir="auto" className="css-901oao r-homxoj r-320f6p r-7cikom r-1s2g6fe r-t9a87b">
                                                                        <span className="css-901oao css-16my406 r-320f6p r-1x35g6 r-vw2c0b r-oxtfae r-37tt59">--</span>
                                                                    </div>
                                                                </div>
                                                                <div className="css-1dbjc4n r-1g2xzmm" style={{ paddingBottom: 4 }}>
                                                                    <div dir="auto" className="css-901oao r-1mdqdya r-320f6p r-1b43r93 r-uz4sb0 r-rjixqe">
                                                                        {t("Refer friends and Level up")}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="css-1dbjc4n r-1g2xzmm" style={{ paddingBottom: 48 }}>
                                        <div className=" row with-cols-vertical-spacing stacked-before-md item-spacing-xl">
                                            <div className="col-sm-8">
                                                <div className="css-1dbjc4n r-eqz5dr r-1g2xzmm" style={{ marginBottom: '-24px' }}>
                                                    <div className="css-1dbjc4n r-1g2xzmm" style={{ paddingBottom: 24 }}>
                                                        <div dir="auto" className="css-901oao r-z4f626 r-320f6p r-1x35g6 r-oxtfae r-37tt59">
                                                            <strong>{t("Earn money now!")}</strong>
                                                        </div>
                                                    </div>
                                                    <div className="css-1dbjc4n r-1g2xzmm" style={{ paddingBottom: 24 }}>
                                                        <div className="css-1dbjc4n r-eqz5dr r-1g2xzmm" style={{ marginBottom: '-32px' }}>
                                                            <div className="css-1dbjc4n r-1g2xzmm" style={{ paddingBottom: 32 }}>
                                                                <div className="css-1dbjc4n r-1awozwy r-18u37iz r-13v18d r-1g2xzmm">
                                                                    <div className="css-1dbjc4n r-1wbh5a2 r-11g3r6m r-1g2xzmm">
                                                                        <div className="css-1dbjc4n" data-testid="icon-icon1" style={{ height: 70, width: 70 }}>
                                                                            <svg width={70} height={70} viewBox="0 0 62 47" fill="#5E5862">
                                                                                <g fill="none" fillRule="evenodd">
                                                                                    <path d="M57.379 26.668l-4.896-20.14c-.908-3.737-4.566-6.11-8.33-5.404L7.008 8.091C2.141 9.003-.948 13.85.264 18.674l5.482 21.814a5.028 5.028 0 0 0 4.486 3.785l.028.04h3.524l-.059-.531 15.054-3.08 7.447 5.898 3.403-8.117 11.818-2.417c4.273-.874 6.966-5.141 5.932-9.398" fill="#3FBF7A" />
                                                                                    <path d="M3.864 18.629l5.482 21.757a5.038 5.038 0 0 0 5.89 3.712l39.811-8.122c4.272-.872 6.966-5.128 5.931-9.374L56.083 6.514a7.212 7.212 0 0 0-8.33-5.39L10.608 8.072c-4.865.91-7.955 5.747-6.743 10.557" stroke="#F2F2F2" strokeWidth="1.2" fill="#3FBF7A" />
                                                                                    <path fill="#FFF" d="M32.4 10.6l2.64 16.8 9.36-10.887-12-5.913" />
                                                                                    <path d="M33.476 15.4s-8.492 1.158-10.552 5.569c-1.524 3.264-2.778 6.7 2.484 13.631 0 0-1.38-8.423 3.067-11.038 3.782-2.224 6.325-1.88 6.325-1.88L33.476 15.4" fill="#FFF" />
                                                                                </g>
                                                                            </svg>
                                                                        </div>
                                                                    </div>
                                                                    <div className="css-1dbjc4n r-1wbh5a2 r-11g3r6m r-1g2xzmm">
                                                                        <div dir="auto" className="css-901oao r-homxoj r-320f6p r-1i10wst r-oxtfae r-eaezby">
                                                                            <span><strong>{t("Login / Register")}</strong>&nbsp;để
                                                                                {t(" to get your referral link.")}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="css-1dbjc4n r-1g2xzmm" style={{ paddingBottom: 32 }}>
                                                                <div className="css-1dbjc4n r-1awozwy r-18u37iz r-13v18d r-1g2xzmm">
                                                                    <div className="css-1dbjc4n r-1wbh5a2 r-11g3r6m r-1g2xzmm">
                                                                        <div className="refernal_icon css-1dbjc4n" data-testid="icon-icon2" style={{ height: 70, width: 70 }}>
                                                                            <svg width={70} height={70} viewBox="0 0 63 50" fill="#5E5862">
                                                                                <g fill="none" fillRule="evenodd">
                                                                                    <path d="M51.55 4.21L10.883 1.029C5.556.612.913 4.769.748 10.102L.003 34.228a5.333 5.333 0 0 0 5.022 5.502l18.854 1.166 5.998 8.193h3.367l2.568-7.455 12.997.804c4.7.29 8.759-3.374 8.941-8.072l.86-22.228a7.636 7.636 0 0 0-7.06-7.928" fill="#3FBF7A" />
                                                                                    <path d="M4.115 10.102L3.37 34.228a5.333 5.333 0 0 0 5.022 5.502l43.784 2.708c4.7.29 8.76-3.374 8.941-8.072l.86-22.228a7.636 7.636 0 0 0-7.06-7.928L14.25 1.029C8.923.612 4.28 4.769 4.115 10.102" stroke="#F2F2F2" strokeWidth="1.2" fill="#3FBF7A" />
                                                                                    <path d="M43.37 35.101l-11.86-.873.333-4.972c-.032-2.762 2.637-4.802 5.48-4.592 2.843.21 5.566 2.647 5.599 5.408l.448 5.03M34.044 20.124c.02 1.733 1.482 3.244 3.265 3.375 1.785.132 3.214-1.166 3.193-2.898-.02-1.732-1.483-3.243-3.266-3.375-1.783-.13-3.212 1.167-3.192 2.898" fill="#FFF" opacity="0.706" />
                                                                                    <path d="M37.146 34.654L20.4 33.419l.47-7.02c-.046-3.9 3.723-6.78 7.738-6.484 4.014.296 7.858 3.737 7.903 7.637l.635 7.102M23.977 13.505c.029 2.447 2.093 4.58 4.612 4.765 2.517.186 4.536-1.646 4.507-4.092-.029-2.447-2.093-4.579-4.612-4.765-2.517-.186-4.536 1.646-4.507 4.092" fill="#FFF" />
                                                                                </g>
                                                                            </svg>
                                                                        </div>
                                                                    </div>
                                                                    <div className="css-1dbjc4n r-1wbh5a2 r-11g3r6m r-1g2xzmm">
                                                                        <div dir="auto" className="css-901oao r-homxoj r-320f6p r-1i10wst r-oxtfae r-eaezby">
                                                                            <span>{t("Get ")}<strong>{t("40% commission")}</strong> {t(" immediately when your friends sign up and buy coins through your referral link.")}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="css-1dbjc4n r-1g2xzmm" style={{ paddingBottom: 32 }}>
                                                                <div className="css-1dbjc4n r-1awozwy r-18u37iz r-13v18d r-1g2xzmm">
                                                                    <div className="css-1dbjc4n r-1wbh5a2 r-11g3r6m r-1g2xzmm">
                                                                        <div className="refernal_icon css-1dbjc4n" data-testid="icon-icon3" style={{ height: 70, width: 70 }}>
                                                                            <svg width={70} height={70} viewBox="0 0 66 51" fill="#5E5862">
                                                                                <g fill="none" fillRule="evenodd">
                                                                                    <path d="M49.093 1.613L8.753 7.661C3.469 8.454-.12 13.55.919 18.782l4.7 23.675a5.333 5.333 0 0 0 6.132 4.232l18.633-3.105 7.687 6.633 3.28-.757.826-7.841 12.845-2.14c4.644-.775 7.775-5.259 6.896-9.878L57.756 7.75a7.636 7.636 0 0 0-8.663-6.137" fill="#3FBF7A" />
                                                                                    <path d="M4.2 18.025L8.9 41.7a5.333 5.333 0 0 0 6.131 4.231l43.272-7.21c4.644-.774 7.775-5.258 6.896-9.877L61.037 6.993A7.636 7.636 0 0 0 52.374.856l-40.34 6.048c-5.284.793-8.874 5.888-7.835 11.12" stroke="#F2F2F2" strokeWidth="1.2" fill="#3FBF7A" />
                                                                                    <path d="M51.858 32.03l-8.33 1.334-.62-3.21c-.495-1.749.997-3.47 2.995-3.79 1.996-.319 4.29.795 4.785 2.543l1.17 3.122M42.776 23.696c.331 1.085 1.686 1.802 3.028 1.601 1.341-.2 2.16-1.241 1.83-2.326-.33-1.085-1.686-1.801-3.027-1.601-1.342.2-2.161 1.242-1.83 2.326" fill="#B284C5" opacity="0.82" />
                                                                                    <path d="M46.02 32.945L34.3 34.966l-.88-4.904c-.7-2.673 1.397-5.298 4.207-5.782 2.81-.484 6.04 1.222 6.74 3.893l1.652 4.772M33.347 20.669c.439 1.676 2.222 2.788 3.984 2.484 1.764-.304 2.837-1.908 2.398-3.585-.44-1.675-2.224-2.787-3.986-2.483-1.762.304-2.835 1.908-2.396 3.584" fill="#DECAE6" opacity="0.83" />
                                                                                    <path d="M23.324 36.87l-1.243-6.926c-.987-3.773 1.973-7.48 5.94-8.163 3.967-.684 8.529 1.725 9.517 5.498l2.334 6.737-16.548 2.853zM21.978 16.681c.62 2.368 3.138 3.937 5.627 3.508 2.488-.428 4.003-2.694 3.384-5.06-.62-2.368-3.139-3.937-5.628-3.508-2.488.428-4.003 2.694-3.383 5.06" fill="#FFF" />
                                                                                </g>
                                                                            </svg>
                                                                        </div>
                                                                    </div>
                                                                    <div className="css-1dbjc4n r-1wbh5a2 r-11g3r6m r-1g2xzmm">
                                                                        <div dir="auto" className="css-901oao r-homxoj r-320f6p r-1i10wst r-oxtfae r-eaezby">
                                                                            <span>{t("Additionally, when your friends refer others to trade on SwapToBe, you will earn another ")}
                                                                                <strong>{t(" 10% commission")}</strong></span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="css-1dbjc4n r-1g2xzmm" style={{ paddingBottom: 24 }}>
                                                        <div dir="auto" className="css-901oao r-z4f626 r-320f6p r-1x35g6 r-oxtfae r-37tt59">
                                                            <strong>{t("Requirement:")}</strong>
                                                        </div>
                                                    </div>
                                                    <div className="css-1dbjc4n r-1g2xzmm" style={{ paddingBottom: 24 }}>
                                                        <div dir="auto" className="css-901oao r-z4f626 r-320f6p r-ubezar r-oxtfae r-135wba7">
                                                            {t("Accounts that are identified by our system as clones of other accounts won’t be used to calculate commission.")}
                                                        </div>
                                                    </div>
                                                    <div className="css-1dbjc4n r-1g2xzmm" style={{ paddingBottom: 24 }}>
                                                        <div dir="auto" className="css-901oao r-z4f626 r-320f6p r-ubezar r-oxtfae r-135wba7">
                                                            {t("The commission only counts for the user as the role “Taker-Buyer” who take the “Buy now” offer or the “Selling ads” offer list.")}
                                                        </div>
                                                    </div>
                                                    <div className="css-1dbjc4n r-1g2xzmm" style={{ paddingBottom: 24 }}>
                                                        <div dir="auto" className="css-901oao r-z4f626 r-320f6p r-ubezar r-oxtfae r-135wba7">
                                                            {t("Verification of a user's referrer depends on the referral link used. If a visitor clicked on multiple different referral links, only the last referral link will be effective.")}
                                                        </div>
                                                    </div>
                                                    <div className="css-1dbjc4n r-1g2xzmm" style={{ paddingBottom: 24 }}>
                                                        <div dir="auto" className="css-901oao r-z4f626 r-320f6p r-ubezar r-oxtfae r-135wba7">
                                                            {t("SwapToBe reserves the right to remove or reward a commission for specific cases and alter the referral program at any time.")}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <div className="css-1dbjc4n r-eqz5dr r-1g2xzmm" style={{ marginBottom: '-24px' }}>
                                                    <div className="css-1dbjc4n r-1g2xzmm" style={{ paddingBottom: 24 }}>
                                                        <div dir="auto" className="css-901oao r-z4f626 r-320f6p r-1x35g6 r-oxtfae r-37tt59">
                                                            <strong>{t("Leaderboard")}</strong>
                                                        </div>
                                                    </div>
                                                    <div className="css-1dbjc4n r-1g2xzmm" style={{ paddingBottom: 24 }}>
                                                        <div dir="auto" className="css-901oao r-z4f626 r-320f6p r-ubezar r-oxtfae r-135wba7">
                                                            {t("Top of people who get the most profit from SwapToBe Referral Program")}
                                                        </div>
                                                    </div>
                                                    <div className="css-1dbjc4n r-1g2xzmm" style={{ paddingBottom: 24 }}>
                                                        <div className="css-1dbjc4n r-14lw9ot r-z2wwpe r-1dye5f7 r-mfjstv r-1g2xzmm">
                                                            <div>
                                                                <div className="row">
                                                                    <div className="col-2"><strong>#1</strong></div>
                                                                    <div className="col-4 text-base">****eoer</div>
                                                                    <div className="col-6 text-right">
                                                                        <strong>30,649.60</strong>
                                                                        <strong className="text-base-light">USDT</strong>
                                                                    </div>
                                                                </div>
                                                                <div className="css-1dbjc4n r-qklmqi r-r3u836 r-1phboty r-pw2am6" />
                                                            </div>
                                                            <div>
                                                                <div className="row">
                                                                    <div className="col-2"><strong>#2</strong></div>
                                                                    <div className="col-4 text-base">****0842</div>
                                                                    <div className="col-6 text-right">
                                                                        <strong>28,024.15</strong>
                                                                        <strong className="text-base-light">USDT</strong>
                                                                    </div>
                                                                </div>
                                                                <div className="css-1dbjc4n r-qklmqi r-r3u836 r-1phboty r-pw2am6" />
                                                            </div>
                                                            <div>
                                                                <div className="row">
                                                                    <div className="col-2"><strong>#3</strong></div>
                                                                    <div className="col-4 text-base">****_vnn</div>
                                                                    <div className="col-6 text-right">
                                                                        <strong>27,757.82</strong>
                                                                        <strong className="text-base-light">USDT</strong>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </section>
                </div>
            </div>

        </>
    )
}
