import React, { useEffect, useRef, useState } from 'react'
import style from '../../assets/style.scss'
import { Link, Redirect, useParams, NavLink } from 'react-router-dom'
import { useTranslate } from '../../core/translate'
import { createAction } from '../../redux/action/authAction'
import { useDispatch, useSelector } from 'react-redux'
import { COIN, USERADLIST } from '../../redux/type'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { createA, createBuyadlist, createSelladlist } from '../../redux/action/userAction'
import 'antd/dist/antd.css';
import { Pagination } from 'antd';
import { port } from '../../port'
import { toggleShadow } from 'redux/action/toggleShadowActive'
import { REDIRECT } from 'redux/type'
import { createHomeAction } from 'redux/action/homeAction'
import PopupSell from '../../Components/PopupSell'
import PopupBuy from '../../Components/PopupBuy'
import withPriceFormat from 'core/hook/withPriceFormat'
import SeacrchDataSell from './component/SeacrchDataSell'
import { HandleGetFeeWallet } from 'redux/reducer/walletReducer'
function P2P() {
    let { t } = useTranslate()
    let [trandeVND2, setTrandeVND2] = useState(false)
    let { slug } = useParams()
    useEffect(() => {
        let tab = t("Swap tobe | Buy/Sell Bitcoin Ether and Altcoins | Cryptocurrency Exchange")
        document.title = tab
        return () => {
            document.title = "Swap tobe"
        }
    }, []);
    let { buyadlist, selladlist, transactionform, vndusd, exchange } = useSelector(state => state.home)
    let { getFeeWallet } = useSelector(state => state.wallet)
    let { axiologin } = useSelector(state => state.auth)
    let { pricecoin } = useSelector(state => state.coin)
    let reattime = 0
    if (pricecoin) {
        pricecoin.filter((e) => {
            if (e.name == slug?.toUpperCase()) {
                reattime = e
            }
        })
    }
    else {
        reattime = 0
    }
    const shadowLayer = useSelector(state => state.shadowLayer)
    let { flag } = shadowLayer
    function HandleClick() {
        dispatch(createAction(COIN, true))
        dispatch(toggleShadow(!flag))
    }
    useEffect(() => {
        dispatch(createA(REDIRECT, false))
    }, [])
    useEffect(() => {
        if (slug == undefined) {

        } else if (buyadlist == false && selladlist == false) {
            dispatch(createHomeAction(slug, 1))
        }
    }, [slug])

    const dispatch = useDispatch()
    function truncate(value, precision) {
        var step = Math.pow(10, precision || 0);
        var temp = Math.trunc(step * value);
        var a = temp / step;
        return a.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    let [pageBuy, setPageBuy] = useState(1)
    function handlePageBuy(page) {
        setPageBuy(page)
        dispatch(createSelladlist(slug, page))

    }
    let [pageSell, setPageSell] = useState(1)
    function handlePageSell(page) {
        setPageSell(page)
        dispatch(createBuyadlist(slug, page))
    }
    let [trandeVND, setTrandeVND] = useState(false)
    function changeTandeVND() {
        setTrandeVND(!trandeVND)
    }
    function changeTandeVND2() {
        setTrandeVND2(!trandeVND2)
    }
    let [indexUser, setIndexUser] = useState()
    let [link, setLink] = useState(false)
    function getUser(e, i) {
        setIndexUser(i)
        setLink(true)
        localStorage.removeItem('useradlist')
        dispatch(createA(USERADLIST, e))
    }

    let [link2, setLink2] = useState(false)
    let [indexUser2, setIndexUser2] = useState()
    function getUser2(e, i) {
        setIndexUser2(i)
        setLink2(true)
        localStorage.removeItem('useradlist')
        dispatch(createA(USERADLIST, e))
    }
    let unique_code = JSON.parse(localStorage.getItem('user'))
    let [valueCopy, setValueCopy] = useState('')
    let [activeCopy, setActiveCopy] = useState(false)
    function handleActive() {
        setActiveCopy(true)
        setTimeout(() => {
            setActiveCopy(false)
        }, 1500)
    }
    const renderSellAdList = () => {

        return (
            selladlist?.map((e, i) => {
                return (
                    <div className="css-1dbjc4n r-1g2xzmm" style={{ paddingBottom: '6px' }} key={i}>
                        {link2 ? <Redirect to={`/buy/${slug}/${indexUser2}/${pageBuy}`} /> :
                            <>
                                <div data-focusable="true" tabIndex={0} className="hovercoin css-1dbjc4n r-1a3r4sm r-1hvb8e0 r-mm0ijv r-ms8t9i r-1jkafct r-z4f626 r-1loqt21 r-ymttw5 r-1yzf0co" data-testid="offer offer-1443681" style={{ position: "relative", overflow: "hidden" }}>
                                    <div className="flex2 css-1dbjc4n r-1awozwy r-18u37iz r-1w6e6rj r-6cv0rn r-1g2xzmm" >
                                        <div className=" css-1dbjc4n r-b83rso r-18kxxzh r-1q142lx r-vjp7fe r-ymttw5 r-bnwqim r-1g2xzmm">
                                            <div className="css-1dbjc4n r-13awgt0 r-eqz5dr r-1g2xzmm" style={{ marginBottom: '-4px' }} >

                                                <div className="css-1dbjc4n r-1g2xzmm" style={{ paddingBottom: '4px' }} onClick={changeTandeVND}>
                                                    <div className="css-1dbjc4n r-18u37iz r-1g2xzmm">
                                                        <div dir="auto" className="css-901oao r-homxoj r-1loqt21 r-320f6p r-7cikom r-vw2c0b r-1s2g6fe r-t9a87b" data-testid="offer-price" style={{ width: "200px" }}>
                                                            <span className="css-901oao css-16my406 r-1rf7ul2">{!trandeVND ? `${truncate(e.amount_exchange_usd * e.amount_exchange_vnd + (e.amount_exchange_usd * e.amount_exchange_vnd) / 100 * e.percent)}` : `${truncate(e.amount_exchange_vnd)}`} </span>
                                                            <span className="css-901oao css-16my406 r-1814a8r r-tsynxw"> VND/</span>

                                                            <span className="css-901oao css-16my406 r-1814a8r">{!trandeVND ? `${e.type}` : 'USD'}</span>
                                                        </div>
                                                        <div style={{ position: "absolute", width: "100vw", height: '60px', left: '160px', top: '-10px' }} onClick={() => getUser2(e, i)}></div>
                                                    </div>
                                                </div>


                                                <div className="css-1dbjc4n r-1g2xzmm" style={{ paddingBottom: '4px' }} onClick={() => getUser2(e, i)}>
                                                    <div dir="auto" className="css-901oao r-homxoj r-320f6p r-1b43r93 r-uz4sb0 r-rjixqe" data-testid="offer-trade-limit" style={{ width: '200px' }}>{t("Maximum: ")}{e.amount_maximum} {e.type}</div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="css-1dbjc4n r-1e8hiny r-18kxxzh r-1q142lx r-1ybb5if r-ymttw5 r-bnwqim r-1g2xzmm" onClick={() => getUser2(e, i)}>
                                            <div className="css-1dbjc4n r-1awozwy r-18u37iz r-1g2xzmm" style={{ marginRight: '-6px' }}>
                                                <div className="css-1dbjc4n r-1wbh5a2 r-9jpwak r-1g2xzmm" style={{ paddingBottom: '0px', paddingRight: '6px' }}>
                                                    {transactionform?.map((el, i) => {
                                                        return (
                                                            <div key={i} dir="auto" className="css-901oao r-homxoj r-320f6p r-ubezar r-vw2c0b r-oxtfae r-135wba7">{e.type_exchange == 1 && el.name == 'SWAP VND Wallet' ? el.name : ''}</div>
                                                        )
                                                    })}
                                                </div>
                                                <div className="css-1dbjc4n r-1wbh5a2 r-9jpwak r-1g2xzmm" style={{ paddingBottom: '0px', paddingRight: '6px' }}>
                                                    <div className="css-1dbjc4n r-1g2xzmm"><div data-focusable="true" tabIndex={0} className="css-1dbjc4n r-1loqt21" data-testid="icon-info" style={{ height: '14px', width: '14px' }}>
                                                        <svg data-name="Layer 1" viewBox="0 0 16 16" fill="#34a853" width={14} height={14}><circle cx={8} cy={8} r="7.64" />
                                                            <path d="M8.63 6.3v4.91h1.56v1.22H5.83v-1.22H7.4V7.53h-1V6.3zM8 3.09a.82.82 0 1 1-.82.82.82.82 0 0 1 .82-.82z" fill="#fff" fillRule="evenodd" />
                                                        </svg>
                                                    </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div dir="auto" className="css-901oao r-homxoj r-320f6p r-1b43r93 r-uz4sb0 r-rjixqe">{t("Completion time: ~0s")}</div>
                                        </div>
                                        <div className="css-1dbjc4n r-b83rso r-18kxxzh r-1q142lx r-vjp7fe r-ymttw5 r-bnwqim r-1g2xzmm" >
                                            <div className=" css-1dbjc4n r-18u37iz r-1g2xzmm">
                                                <a href={`/profile/${slug}/${e.username}`} className="text-base" >
                                                    <div className="css-1dbjc4n r-1awozwy r-13awgt0 r-18u37iz">
                                                        <div className="css-1dbjc4n" data-testid="icon-online" style={{ height: '8px', width: '8px' }}>
                                                            <svg width={8} height={8} viewBox="0 0 8 8" fill="#5E5862">
                                                                <circle cx={117} cy={143} r={4} transform="translate(-113 -139)" fill="#83BE69" fillRule="evenodd" />
                                                            </svg>
                                                        </div>
                                                        <div className="css-1dbjc4n r-1wbh5a2 r-1777fci r-1jkjb">
                                                            <div dir="auto" className="css-901oao r-homxoj r-320f6p r-7cikom r-1s2g6fe r-t9a87b">{e.username}</div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="button-buy css-1dbjc4n r-xfqzxg r-18kxxzh r-1q142lx r-q865v0 r-ymttw5 r-bnwqim r-1g2xzmm" onClick={() => getUser2(e, i)}>
                                            <div className="css-1dbjc4n r-z2wwpe r-1udh08x">
                                                <div role="button" aria-busy="false" data-focusable="true" tabIndex={0} className="css-18t94o4 css-1dbjc4n r-1loqt21 r-1otgn73 r-1i6wzkk r-lrvibr" style={{ transitionDuration: '0s' }}>
                                                    <div className="button-buy-item css-1dbjc4n r-1awozwy r-1qnjcxv r-z2wwpe r-18u37iz r-1777fci r-5njf8e" style={{ alignSelf: 'flex-end', borderWidth: '0px', minHeight: '40px', padding: '8px 16.1px' }}>
                                                        <div dir="auto" className="button-buy-text css-901oao r-z4f626 r-320f6p r-ubezar r-oxtfae r-135wba7" style={{ paddingTop: '0px', paddingBottom: '0px' }}>{t("Buy")}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </>
                        }
                    </div>

                    // </>

                )

            }
            )


        )

    }
    const renderBuyadList = () => {
        return (
            buyadlist?.map((e, i) => {
                return (

                    <div className=" css-1dbjc4n r-1g2xzmm" style={{ paddingBottom: '6px' }} key={i}>

                        {link ? <Redirect to={`/sell/${slug}/${indexUser}/${pageSell}`} /> :
                            <div data-focusable="true" tabIndex={0} className="hovercoin css-1dbjc4n r-1a3r4sm r-1hvb8e0 r-mm0ijv r-ms8t9i r-1jkafct r-z4f626 r-1loqt21 r-ymttw5 r-1yzf0co" data-testid="offer offer-881199" >
                                <div className="flex2 css-1dbjc4n r-1awozwy r-18u37iz r-1w6e6rj r-6cv0rn r-1g2xzmm">
                                    <div className="css-1dbjc4n r-b83rso r-18kxxzh r-1q142lx r-vjp7fe r-ymttw5 r-bnwqim r-1g2xzmm">
                                        <div className="css-1dbjc4n r-13awgt0 r-eqz5dr r-1g2xzmm" style={{ marginBottom: '-4px' }}>
                                            <div className="css-1dbjc4n r-1g2xzmm" style={{ paddingBottom: '4px' }}>
                                                <div className="css-1dbjc4n r-18u37iz r-1g2xzmm">
                                                    <div dir="auto" className="css-901oao r-homxoj r-1loqt21 r-320f6p r-7cikom r-vw2c0b r-1s2g6fe r-t9a87b" data-testid="offer-price" onClick={changeTandeVND2}>
                                                        <span className="css-901oao css-16my406 r-8bscmv">{!trandeVND2 ? `${truncate((e.amount_exchange_usd * e.amount_exchange_vnd) - ((e.amount_exchange_usd * e.amount_exchange_vnd) / 100) * e.percent)}` : `${truncate(e.amount_exchange_vnd)}`}</span>
                                                        <span className="css-901oao css-16my406 r-1814a8r r-tsynxw"> VND/</span>
                                                        <span className="css-901oao css-16my406 r-1814a8r">{!trandeVND2 ? `${e.type}` : 'USD'}</span>
                                                    </div>
                                                    <div style={{ position: "absolute", width: "100vw", height: '60px', left: '160px', top: '-10px' }} onClick={() => getUser(e, i)}></div>
                                                </div>
                                            </div>
                                            <div className="css-1dbjc4n r-1g2xzmm" style={{ paddingBottom: '4px' }} onClick={() => getUser(e, i)}>
                                                <div dir="auto" className="css-901oao r-homxoj r-320f6p r-1b43r93 r-uz4sb0 r-rjixqe" data-testid="offer-trade-limit" style={{ width: '200px' }}>{t("Maximum: ")}{e.amount_maximum} {e.type}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="css-1dbjc4n r-1e8hiny r-18kxxzh r-1q142lx r-1ybb5if r-ymttw5 r-bnwqim r-1g2xzmm" onClick={() => getUser(e, i)}>
                                        <div className="css-1dbjc4n r-1awozwy r-18u37iz r-1g2xzmm" style={{ marginRight: '-6px' }}>
                                            <div className="css-1dbjc4n r-1wbh5a2 r-9jpwak r-1g2xzmm" style={{ paddingBottom: '0px', paddingRight: '6px' }}>
                                                {transactionform?.map((el, i) => {
                                                    return (
                                                        <div dir="auto" key={i} className="css-901oao r-homxoj r-320f6p r-ubezar r-vw2c0b r-oxtfae r-135wba7">{e.type_exchange == 1 && el.name == 'SWAP VND Wallet' ? el.name : ''}</div>
                                                    )
                                                })}
                                            </div>
                                            <div className="css-1dbjc4n r-1wbh5a2 r-9jpwak r-1g2xzmm" style={{ paddingBottom: '0px', paddingRight: '6px' }}>
                                                <div className="css-1dbjc4n r-1g2xzmm">
                                                    <div data-focusable="true" tabIndex={0} className="css-1dbjc4n r-1loqt21" data-testid="icon-info" style={{ height: '14px', width: '14px' }}>
                                                        <svg data-name="Layer 1" viewBox="0 0 16 16" fill="#34a853" width={14} height={14}><circle cx={8} cy={8} r="7.64" />
                                                            <path d="M8.63 6.3v4.91h1.56v1.22H5.83v-1.22H7.4V7.53h-1V6.3zM8 3.09a.82.82 0 1 1-.82.82.82.82 0 0 1 .82-.82z" fill="#fff" fillRule="evenodd" />
                                                        </svg>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div dir="auto" className="css-901oao r-homxoj r-320f6p r-1b43r93 r-uz4sb0 r-rjixqe">{t("Completion time: ~0s")}</div>
                                    </div>
                                    <div className="css-1dbjc4n r-b83rso r-18kxxzh r-1q142lx r-vjp7fe r-ymttw5 r-bnwqim r-1g2xzmm" >
                                        <div className="css-1dbjc4n r-18u37iz r-1g2xzmm">
                                            <a href={`/profile/${slug}/${e.username}`} className="text-base" store="[object Object]">
                                                <div className="css-1dbjc4n r-1awozwy r-13awgt0 r-18u37iz">
                                                    <div className="css-1dbjc4n" data-testid="icon-online" style={{ height: '8px', width: '8px' }}>
                                                        <svg width={8} height={8} viewBox="0 0 8 8" fill="#5E5862">
                                                            <circle cx={117} cy={143} r={4} transform="translate(-113 -139)" fill="#83BE69" fillRule="evenodd" />
                                                        </svg>
                                                    </div>
                                                    <div className="css-1dbjc4n r-1wbh5a2 r-1777fci r-1jkjb">
                                                        <div className="css-901oao r-homxoj r-320f6p r-7cikom r-1s2g6fe r-t9a87b">{e.username}</div>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="button-buy css-1dbjc4n r-xfqzxg r-18kxxzh r-1q142lx r-q865v0 r-ymttw5 r-bnwqim r-1g2xzmm" onClick={() => getUser(e, i)}>
                                        <div className="css-1dbjc4n r-z2wwpe r-1udh08x">
                                            <div role="button" aria-busy="false" data-focusable="true" tabIndex={0} className="css-18t94o4 css-1dbjc4n r-1loqt21 r-1otgn73 r-1i6wzkk r-lrvibr" style={{ transitionDuration: '0s' }}>
                                                <div className="button-sell-item css-1dbjc4n r-1awozwy r-1qnjcxv r-z2wwpe r-18u37iz r-1777fci r-5njf8e" style={{ alignSelf: 'flex-end', borderWidth: '0px', minHeight: '40px', padding: '8px 16.1px' }}>
                                                    <div className="button-sell-text css-901oao r-z4f626 r-320f6p r-ubezar r-oxtfae r-135wba7" style={{ paddingTop: '0px', paddingBottom: '0px' }}>{t("Sell")}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>

                )
            })
        )



    }
    return (
        <>

            {slug == undefined ? <Redirect to='/p2p/btc' />
                : <main>
                    <div id="scrollable-body" style={{ overflow: 'hidden' }}>


                        <div className=" css-1dbjc4n r-1jj8364 r-lchren r-l64lwh r-1qhn6m8 r-i023vh r-13qz1uu r-1g2xzmm" data-testid="alert-container" />
                        <div className="css-1dbjc4n r-11ict49" data-testid="main">
                            <main className="revamp-section" style={{ paddingBottom: "30px" }}>
                                <div className="css-1dbjc4n r-13awgt0 r-eqz5dr r-1g2xzmm" style={{ marginBottom: '-24px' }}>
                                    <div className="css-1dbjc4n r-1g2xzmm" style={{ paddingBottom: '24px' }}>

                                        {/* <div className="css-1dbjc4n r-1ksrn1l r-1777fci r-11yh6sk r-mk0yit r-tvv088 r-bnwqim" data-testid="block-quick-trade-container" style={{ background: "linear-gradient(to right, rgb(8, 121, 114), rgb(39, 19, 58))", minHeight: '729px' }}> */}
                                        <div loading="lazy" className="css-1dbjc4n r-1ksrn1l r-1777fci r-11yh6sk r-mk0yit r-tvv088 r-bnwqim" data-testid="block-quick-trade-container" style={{ background: "url(/img/p2p.png) no-repeat", minHeight: '729px', backgroundSize: '100% 100%' }}>
                                            <div className="p2pexchange" style={{ fontSize: "28px", textAlign: 'center', fontFamily: "Arial", marginBottom: '25px', color: '#fff' }} >P2P EXCHANGE</div>
                                            <div className="css-1dbjc4n r-1jj8364 r-lchren r-l64lwh r-1qhn6m8 r-i023vh r-13qz1uu r-1g2xzmm" >
                                                <div className="css-1dbjc4n r-18u37iz r-1w6e6rj r-6cv0rn r-1g2xzmm">
                                                    <div className="revamp-section-res css-1dbjc4n r-1f9ke2t r-18kxxzh r-1q142lx r-17qwcto r-m9m4bm r-ymttw5 r-bnwqim r-1g2xzmm">
                                                        <div className="css-1dbjc4n r-13awgt0 r-eqz5dr r-1g2xzmm" style={{ marginBottom: '-16px' }}>
                                                            <div className="css-1dbjc4n r-1g2xzmm" style={{ paddingBottom: '16px' }}>
                                                                <div className="css-1dbjc4n r-13awgt0 r-eqz5dr r-1g2xzmm" style={{ marginBottom: '-24px' }}>
                                                                    <div className="css-1dbjc4n r-1g2xzmm" style={{ paddingBottom: '24px' }}>
                                                                        <div className="css-1dbjc4n r-1g2xzmm">
                                                                            <div data-focusable="true" tabIndex={0} className=" css-1dbjc4n r-z2wwpe r-1loqt21 r-18u37iz r-1wtj0ep r-ymttw5 r-1yzf0co r-1g2xzmm" data-testid="coin-selector" style={{ backgroundColor: 'rgba(0, 124, 145, 0.54)', borderRadius: '5px' }}>
                                                                                <div className="css-1dbjc4n r-1awozwy r-13awgt0 r-18u37iz">
                                                                                    <div className="css-1dbjc4n" data-testid="icon-btc" style={{ height: '32px', width: '32px' }}>
                                                                                        {
                                                                                            <img loading="lazy" src={`${port}images/${reattime.name}.png`} alt="" />

                                                                                        }
                                                                                    </div>
                                                                                    <div className="css-1dbjc4n r-1wbh5a2 r-1777fci r-1jkjb">
                                                                                        <div dir="auto" className="text-white css-901oao r-jwli3a r-320f6p r-1i10wst r-vw2c0b r-oxtfae r-eaezby">

                                                                                            {
                                                                                                slug?.toUpperCase()
                                                                                            }



                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="css-1dbjc4n r-z2wwpe r-1pn2ns4 r-5njf8e r-1g2xzmm " data-testid="coin-selector-btn" style={{ background: 'rgb(8, 121, 114)', position: 'absolute', right: '0', top: '0', display: 'flex', justifyContent: 'center', padding: '10px', height: '100%' }} onClick={HandleClick}>
                                                                                    <div >
                                                                                        <div dir="auto" className="clickPopup text-white css-901oao r-1oixa0t r-320f6p r-1b43r93 r-uz4sb0 r-rjixqe" >{t("Choose another coin")}</div>
                                                                                        <img src="/img/arrow-header.png" alt="" style={{ marginLeft: '5px' }} />
                                                                                    </div>

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {withPriceFormat(SeacrchDataSell, reattime)}
                                                                    <PopupSell slug={slug} />
                                                                    <PopupBuy slug={slug} />
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="css-1dbjc4n r-1g2xzmm" style={{ paddingBottom: '24px' }}><div className="block-offer-list">
                                        <div className="container">
                                            <div className="css-1dbjc4n r-13awgt0 r-eqz5dr r-1g2xzmm" style={{ marginBottom: '-24px' }} >

                                                <div className="bitcoin css-1dbjc4n r-1g2xzmm" style={{ paddingBottom: '40px' }}>
                                                    <div className="css-1dbjc4n r-13awgt0 r-eqz5dr r-1g2xzmm" style={{ marginBottom: '-32px' }}>
                                                        <div className=" css-1dbjc4n r-1g2xzmm" style={{ paddingBottom: '32px' }}>
                                                            <div className="css-1dbjc4n r-1g2xzmm">
                                                                <div className="bitcoin_buy css-1dbjc4n r-13awgt0 r-eqz5dr r-1g2xzmm" data-testid="sell-offers offer-list-section" style={{ marginBottom: '-16px' }}>
                                                                    <div className=" css-1dbjc4n r-1g2xzmm" style={{ paddingBottom: '16px' }}>
                                                                        <div className="title-accent text-20">
                                                                            <div className="css-1dbjc4n r-1awozwy r-13awgt0 r-18u37iz">
                                                                                <div className="css-1dbjc4n" data-testid="icon-flag-squared" style={{ height: '24px', width: '24px' }}>
                                                                                    <svg id="flag-squared_svg__Layer_1" data-name="Layer 1" viewBox="0 0 24 24" fill="#5E5862" width={24} height={24}>
                                                                                        <defs>
                                                                                            <style dangerouslySetInnerHTML={{ __html: ".flag-squared_svg__cls-1{fill:#5e5862}" }} />
                                                                                        </defs>
                                                                                        <g id="flag-squared_svg__Page-1">
                                                                                            <g id="flag-squared_svg__Home-v5-amount-selected-login-Copy">
                                                                                                <g id="flag-squared_svg__flag-copy">
                                                                                                    <g id="flag-squared_svg__Shape">
                                                                                                        <path className="flag-squared_svg__cls-1" d="M16.14 9L19 4.36a.34.34 0 0 0 0-.31.29.29 0 0 0-.25-.15H5.58v-.6a.29.29 0 1 0-.58 0v17.4a.29.29 0 1 0 .58 0v-6.6h13.13A.29.29 0 0 0 19 14a.34.34 0 0 0 0-.31zM5.58 13.5v-9h12.6l-2.64 4.34a.36.36 0 0 0 0 .32l2.64 4.34z" />
                                                                                                        <path className="flag-squared_svg__cls-1" d="M5.29 21.5a.8.8 0 0 1-.79-.8V3.3a.79.79 0 1 1 1.58 0v.1h12.63a.78.78 0 0 1 .69.42.79.79 0 0 1 0 .8L16.72 9l2.66 4.38a.79.79 0 0 1 0 .8.78.78 0 0 1-.69.42H6.08v6.1a.79.79 0 0 1-.79.8zm.79-8.5h11.21l-2.17-3.58a.79.79 0 0 1 0-.84L17.29 5H6.08z" />
                                                                                                    </g>
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                    </svg>
                                                                                </div>
                                                                                <div className="css-1dbjc4n r-1wbh5a2 r-1777fci r-1jkjb">
                                                                                    <span style={{ color: '#000' }}>{t("You want to")} <span className="text-success">{t("Buy")}</span>&nbsp;{slug?.toUpperCase()}?</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="css-1dbjc4n r-1g2xzmm" style={{ paddingBottom: '16px' }}>
                                                                        <div className="css-1dbjc4n r-1g2xzmm"><div className="css-1dbjc4n r-13awgt0 r-eqz5dr r-v55p34 r-1g2xzmm">
                                                                            <div className="css-1dbjc4n r-1g2xzmm" style={{ paddingBottom: '8px' }}>
                                                                                <div className="css-1dbjc4n r-13awgt0 r-eqz5dr r-1g2xzmm" data-testid="offer-list" style={{ marginBottom: '-6px' }}>\
                                                                                    {withPriceFormat(renderSellAdList, reattime)}

                                                                                    {/* {renderSellAdList()} */}



                                                                                    <div className="css-1dbjc4n r-1g2xzmm" style={{ paddingBottom: '6px' }}><div className="css-1dbjc4n r-1a3r4sm r-1hvb8e0 r-mm0ijv r-ms8t9i r-1jkafct r-z4f626 r-ymttw5 r-1yzf0co">
                                                                                        <div className="css-1dbjc4n r-13awgt0 r-eqz5dr r-1g2xzmm" style={{ marginBottom: '-4px' }}>
                                                                                            <div className="css-1dbjc4n r-1g2xzmm" style={{ paddingBottom: '4px' }}>
                                                                                                <div dir="auto" className="css-901oao r-homxoj r-320f6p r-7cikom r-1s2g6fe r-t9a87b" style={{ color: '#007E95' }}>{t("Want better price?")}</div>
                                                                                            </div>
                                                                                            <div className="css-1dbjc4n r-1g2xzmm" style={{ paddingBottom: '4px' }}>
                                                                                                <div className="css-1dbjc4n r-18u37iz r-1g2xzmm"><div className="css-1dbjc4n r-z2wwpe r-1udh08x">
                                                                                                    <Link to={`/createads/${reattime?.name?.toLowerCase()}/${reattime?.type}`} role="button" aria-busy="false" data-focusable="true" tabIndex={0} className="css-18t94o4 css-1dbjc4n r-1loqt21 r-1otgn73 r-1i6wzkk r-lrvibr" data-testid="btn-create-buy-offer" style={{ transitionDuration: '0s' }}>
                                                                                                        <div className="css-1dbjc4n r-1awozwy r-k200y r-z2wwpe r-18u37iz r-1777fci r-5njf8e" style={{ backgroundColor: 'rgb(41, 157, 130)', borderWidth: '0px', minHeight: '40px', padding: '8px 16.1px' }}>
                                                                                                            <div dir="auto" className="css-901oao r-jwli3a r-320f6p r-ubezar r-oxtfae r-135wba7" style={{ paddingTop: '0px', paddingBottom: '0px' }}>{t("Create Your Buying Ad")}</div>
                                                                                                        </div>
                                                                                                    </Link>
                                                                                                </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <Pagination
                                                                                defaultPageSize={5} //default size of page
                                                                                defaultCurrent={1}
                                                                                onChange={handlePageBuy}

                                                                                total={selladlist?.data?.data?.total}
                                                                            />

                                                                        </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='linkdk'>
                                                            <div className="container">
                                                                <div className="row linkdks">
                                                                    <div className='col-md-4 linkdk_item'>
                                                                        <img loading="lazy" src="/img/linkdangky.png" alt="" />
                                                                    </div>
                                                                    <div className='col-md-4 linkdks-center' style={{ flexDirection: 'column', color: '#000', display: 'flex', justifyContent: 'center' }}>
                                                                        <h4 style={{ color: '#2A5872' }}>{t("Enjoy up to two levels of income")}</h4>
                                                                        <p style={{ color: '#2a5872' }}>{t("When refer your friend to Swaptobe.")}</p>
                                                                        <p style={{ color: '#2a5872' }}><strong style={{ color: '#4bbd99' }}>40% </strong>{t("transaction fee bonus for 1st tier referrals and")}<br></br>
                                                                            <strong style={{ color: '#4bbd99' }}>10% </strong>{t("transaction fee bonus for 2nd tier referrals.")}</p>

                                                                    </div>
                                                                    <div className='col-md-4 '>
                                                                        {axiologin ?
                                                                            <div style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                                                <input value={`https://Swaptobe.com/SignUp/${unique_code?.axiologin?.unique_code}`}
                                                                                    onChange={() => setValueCopy()} className='inputLink' style={{ color: "#000", padding: '5px' }} />
                                                                                <CopyToClipboard text={`https://Swaptobe.com/SignUp/${unique_code?.axiologin?.unique_code}`}
                                                                                    onCopy={() => setValueCopy({ copied: true })}>
                                                                                    <button onClick={handleActive} className="Copy" style={{ backgroundColor: "#4BBD99", padding: '5px', color: '#fff' }}>
                                                                                        {activeCopy ? "" : 'Copy'}
                                                                                        {activeCopy ? <img src="/img/Copy.png" alt="" style={{ width: '24px' }} /> : ""}
                                                                                    </button>
                                                                                </CopyToClipboard>
                                                                            </div>

                                                                            : <div style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                                                <a href="/login" style={{ padding: '8px 35px', background: '#4BBD99', borderRadius: '5px' }}>Login to get referral link</a>
                                                                            </div>}
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div className="css-1dbjc4n r-1g2xzmm" style={{ paddingBottom: '32px' }}><div className="css-1dbjc4n r-1g2xzmm">
                                                            <div className="bitcoin_sell css-1dbjc4n r-13awgt0 r-eqz5dr r-1g2xzmm" data-testid="buy-offers offer-list-section" style={{ marginBottom: '-16px' }}>
                                                                <div className="  css-1dbjc4n r-1g2xzmm" style={{ paddingBottom: '16px' }}>
                                                                    <div className="title-accent text-20">
                                                                        <div className="css-1dbjc4n r-1awozwy r-13awgt0 r-18u37iz">
                                                                            <div className="css-1dbjc4n" data-testid="icon-flag-squared" style={{ height: '24px', width: '24px' }}>
                                                                                <svg id="flag-squared_svg__Layer_1" data-name="Layer 1" viewBox="0 0 24 24" fill="#5E5862" width={24} height={24}>
                                                                                    <defs>
                                                                                        <style dangerouslySetInnerHTML={{ __html: ".flag-squared_svg__cls-1{fill:#5e5862}" }} />
                                                                                    </defs>
                                                                                    <g id="flag-squared_svg__Page-1">
                                                                                        <g id="flag-squared_svg__Home-v5-amount-selected-login-Copy">
                                                                                            <g id="flag-squared_svg__flag-copy">
                                                                                                <g id="flag-squared_svg__Shape">
                                                                                                    <path className="flag-squared_svg__cls-1" d="M16.14 9L19 4.36a.34.34 0 0 0 0-.31.29.29 0 0 0-.25-.15H5.58v-.6a.29.29 0 1 0-.58 0v17.4a.29.29 0 1 0 .58 0v-6.6h13.13A.29.29 0 0 0 19 14a.34.34 0 0 0 0-.31zM5.58 13.5v-9h12.6l-2.64 4.34a.36.36 0 0 0 0 .32l2.64 4.34z" />
                                                                                                    <path className="flag-squared_svg__cls-1" d="M5.29 21.5a.8.8 0 0 1-.79-.8V3.3a.79.79 0 1 1 1.58 0v.1h12.63a.78.78 0 0 1 .69.42.79.79 0 0 1 0 .8L16.72 9l2.66 4.38a.79.79 0 0 1 0 .8.78.78 0 0 1-.69.42H6.08v6.1a.79.79 0 0 1-.79.8zm.79-8.5h11.21l-2.17-3.58a.79.79 0 0 1 0-.84L17.29 5H6.08z" />
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                    </g>
                                                                                </svg>
                                                                            </div>
                                                                            <div className="css-1dbjc4n r-1wbh5a2 r-1777fci r-1jkjb" >
                                                                                <span style={{ color: '#000' }}>{t("You want to")} <span className="text-danger">{t("Sell")}</span>&nbsp;{slug?.toUpperCase()}?</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="css-1dbjc4n r-1g2xzmm" style={{ paddingBottom: '16px' }}>
                                                                    <div className="css-1dbjc4n r-1g2xzmm"><div className="css-1dbjc4n r-13awgt0 r-eqz5dr r-v55p34 r-1g2xzmm">
                                                                        <div className="css-1dbjc4n r-1g2xzmm" style={{ paddingBottom: '8px' }}>
                                                                            <div className=" css-1dbjc4n r-13awgt0 r-eqz5dr r-1g2xzmm" data-testid="offer-list" style={{ marginBottom: '-6px' }}>


                                                                                {withPriceFormat(renderBuyadList, reattime)}

                                                                                {/* {renderBuyadList()} */}

                                                                                <div className="css-1dbjc4n r-1g2xzmm" style={{ paddingBottom: '6px' }}>
                                                                                    <div className="css-1dbjc4n r-1a3r4sm r-1hvb8e0 r-mm0ijv r-ms8t9i r-1jkafct r-z4f626 r-ymttw5 r-1yzf0co">
                                                                                        <div className="css-1dbjc4n r-13awgt0 r-eqz5dr r-1g2xzmm" style={{ marginBottom: '-4px' }}>
                                                                                            <div className="css-1dbjc4n r-1g2xzmm" style={{ paddingBottom: '4px' }}>
                                                                                                <div dir="auto" className="css-901oao r-homxoj r-320f6p r-7cikom r-1s2g6fe r-t9a87b" style={{ color: '#007E95' }}>{t("Want better price?")}</div>
                                                                                            </div>
                                                                                            <div className="css-1dbjc4n r-1g2xzmm" style={{ paddingBottom: '4px' }}>
                                                                                                <div className="css-1dbjc4n r-18u37iz r-1g2xzmm"><div className="css-1dbjc4n r-z2wwpe r-1udh08x">
                                                                                                    <Link to={`/createadssell/${slug}/${reattime?.type}`} role="button" aria-busy="false" data-focusable="true" tabIndex={0} className="css-18t94o4 css-1dbjc4n r-1loqt21 r-1otgn73 r-1i6wzkk r-lrvibr" data-testid="btn-create-sell-offer" style={{ transitionDuration: '0s' }}>
                                                                                                        <div className="css-1dbjc4n r-1awozwy r-k200y r-z2wwpe r-18u37iz r-1777fci r-5njf8e" style={{ backgroundColor: 'rgb(251, 119, 5)', borderWidth: '0px', minHeight: '40px', padding: '8px 16.1px' }}>
                                                                                                            <div className="css-901oao r-jwli3a r-320f6p r-ubezar r-oxtfae r-135wba7" style={{ paddingTop: '0px', paddingBottom: '0px' }}>{t("Create Your Selling Ad")}</div>
                                                                                                        </div>
                                                                                                    </Link>
                                                                                                </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <Pagination

                                                                            defaultPageSize={5} //default size of page
                                                                            defaultCurrent={1}
                                                                            onChange={handlePageSell}
                                                                            total={buyadlist?.data?.data?.total} //total number of card data available
                                                                        />


                                                                    </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                    <div className="mobileapp-section p-tb black-bg white-sec" id="press">
                                        <div className="container">
                                            <div className="row align-items-center flex-row-reverse">
                                                <div className="col-lg-5">
                                                    <div className="iphone-img">
                                                        <img loading="lazy" src="/img/mockup dt android xanh.png" alt="" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-7">
                                                    <h3 className="section-heading">Swaptobe Apps </h3>
                                                    <span style={{ fontSize: '22px' }}>{t("Trading whenever and wherever you are!")}</span>
                                                    <div style={{ margin: '30px 0' }}>
                                                        <p style={{ fontSize: '18px', fontFamily: 'IBM Plex Sans' }}>
                                                            {t("Swap tobe is compatible with iOS, Android, Website, intuitive user interface and easy navigation. Easily pair your web wallet with a mobile device by scanning a QR code")}
                                                        </p>
                                                        <p style={{ fontSize: '18px', fontFamily: 'IBM Plex Sans' }}>{t("Click to download app store for ios operating system, click download google play for android OS")}</p>
                                                    </div>
                                                    <div className="button-wrapper">
                                                        <a href="https://testflight.apple.com/join/ysHX5JJP" target="_blank" className="apple-btn">
                                                            <img loading="lazy" src="/img/appstore.png" alt="Download on the app store" title="Download on the app store" />
                                                        </a>
                                                        <a href="https://play.google.com/store/apps/details?id=com.companyname.swaptobe" target="_blank" className="google-btn">
                                                            <img loading="lazy" src="/img/googleplay.png" alt="Download on the google play" title="Download on the google play" />
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </main>
                        </div>
                    </div>

                </main >}

        </>
    )
}
export default P2P