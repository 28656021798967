
import { createOther } from "redux/action/orderAction"
import { HANDLELOADDING } from "redux/type"

import { createNapCoin, createNapCoinTRC } from "../redux/action/userAction"

let user = JSON.parse(localStorage.getItem('user'))
let createApiNap = (coin, type, dispatch, token, t, setLoadding) => {
    dispatch(createOther(HANDLELOADDING, true))
    if (user) {
        if (type == "TRC20") {
            dispatch(createNapCoinTRC(token?.axiologin?.token, `${coin}_TRC20`, t, setLoadding))
        } else if (coin == "USDT") {
            dispatch(createNapCoin(token?.axiologin?.token, `${coin}.TRC20`, t, setLoadding))
        } else if (coin == "WIN") {
            dispatch(createNapCoin(token?.axiologin?.token, `WIN.BEP20`, t, setLoadding))
        }
        else if (coin == "SHIB") {
            dispatch(createNapCoin(token?.axiologin?.token, `SHIB.BEP20`, t, setLoadding))
        }
        else {
            dispatch(createNapCoin(token?.axiologin?.token, coin, t, setLoadding))
        }
    }
}
export default createApiNap



