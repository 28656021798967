import { Box, FormControl, MenuItem, Select } from '@mui/material';
import { Spin } from 'antd';
import User from 'api/User';
import { SuccessMessage } from 'core/Message';
import { ErrorMessage } from 'core/Message';
import { useTranslate } from 'core/translate';
import { port } from 'port';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createGetlistbankinguser } from 'redux/action/userAction';
import { ShowLoadding } from 'redux/reducer/loaddingReducer';

function AddBanking({ setContent, setIdBanking, idBanking }) {
    const dispatch = useDispatch()
    const { t } = useTranslate()
    const { getbaking } = useSelector(state => state.user)
    const { showLoadding } = useSelector(state => state.loadding)

    const token = JSON.parse(localStorage.getItem('user'))

    let [form, setForm] = useState()

    const handleChange = (e) => {
        let name = e.target.name
        let value = e.target.value
        setForm({
            ...form,
            [name]: value,
        })
    }
    const HandleBanking = (event) => {
        setIdBanking(event.target.value)
    }


    async function onSubmit() {
        dispatch(ShowLoadding(true))

        try {
            if (form?.numberBanking && form?.ownerBanking) {
                let res = await User.createApiToken(`${port}api/user/addListBanking`, token?.axiologin?.token
                    , { idBanking, numberBanking: form?.numberBanking, ownerBanking: form?.ownerBanking })
                if (res) {
                    SuccessMessage('Successfully added bank account number', t)
                    setContent('banking')
                    dispatch(createGetlistbankinguser(token?.axiologin?.token, 1))
                }
            } else {
                ErrorMessage(("Please enter full information"), t)
            }
        } catch (error) {
            ErrorMessage(error?.response?.data?.message, t)
        }
        dispatch(ShowLoadding(false))

    }
    const selectBanking = () => {
        return (
            <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth variant="filled">
                    <Select
                        labelId="demo-simple-select-disabled-label"
                        id="demo-simple-select-disabled"
                        value={idBanking}
                        onChange={HandleBanking}
                    >
                        {getbaking?.data?.data?.map((e, i) => {
                            return (
                                <MenuItem key={i} value={e?.id} >{e?.name_banking} </MenuItem>
                            )
                        })}
                    </Select>
                </FormControl>
            </Box>
        )
    }
    return (
        <>
            <div className='depositvnd-title withdrawcoin-title'>
                <p>{t('Add new account')}</p>
            </div>
            <div className='depositvnd-input'>
                <p>{t('Bank name')}</p>
                {selectBanking()}
            </div>
            <div className='depositvnd-input'>
                <p>{t('Account number')}</p>
                <input type="text" onChange={handleChange} name='numberBanking' />
            </div>
            <div className='depositvnd-input'>
                <p>{t("Account name")} </p>
                <div style={{ position: 'relative' }}>
                    <input type="text" onChange={handleChange} name='ownerBanking' />
                </div>
            </div>
            <div className="depositvnd-submit" style={{ display: 'flex', justifyContent: 'space-between' }}>
                {showLoadding ? <div className="example" style={{ width: '100%' }}>
                    <Spin />
                </div> :
                    <>
                        <button onClick={onSubmit} style={{ width: '40%' }}>Send</button>
                        <button onClick={() => setContent('banking')} style={{ background: '#D1D0D0', width: '40%' }}>{t('Back')}</button>
                    </>
                }
            </div>
        </>
    );
}

export default AddBanking;