import CreateApi from "api/CreateApi"
import { ErrorMessage, ErrorMessageNoT, SuccessMessage, SuccessMessageNoT } from "core/Message"
import reduxToolkit from "../../core/reduxToolkit"

const initialState = {
    getInfoTBC: '',
    getHistory:'',
    getPackage:[]

}




export const HandleGetInfoTBC = () => {
    return async (dispatch) => {
            try {
                let res = await CreateApi.CreateApiGET('api/blockico/get-block-now')
                dispatch(GetInfoTBC(res?.data?.data))
            } catch (error) {
            }

    }
}


export const HandlePackageTBC = () => {
    return async (dispatch) => {
            try {
                let res = await CreateApi.CreateApiGET('api/blockico/get-package-tbc')
                dispatch(GetPackage(res?.data?.data))
            } catch (error) {
            }

    }
}





export const HandleGetHistory = (page,setLoading) => {
    return async (dispatch) => {
        setLoading(true)
  
            try {
                let res = await CreateApi.CreateApiS('api/blockico/gethistorybuyblock'
                ,{
                    limit:10,
                    page
                }, true)
                dispatch(GetHistory(res?.data?.data))
            } catch (error) {

            }
            setLoading(false)

    }
}


let { reducer, action, TYPE } = reduxToolkit({
    name: 'minning',
    initialState,
    reducers: {

        GetInfoTBC: function (state, action) {
            return {
                ...state,
                getInfoTBC: action.payload
            }
        },
        GetPackage: function (state, action) {
            return {
                ...state,
                getPackage: action.payload
            }
        },
        GetHistory: function (state, action) {
            return {
                ...state,
                getHistory: action.payload
            }
        },

    }
})

export default reducer
export const WITHDRAW = TYPE
export const { GetInfoTBC ,GetHistory ,GetPackage} = action