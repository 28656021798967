import React, { useEffect } from 'react'
import { useTranslate } from '../../core/translate'

export default function Await() {
    let { t } = useTranslate()
    useEffect(() => {
        let tab = t("KYC")
        document.title = tab
        return () => {
            document.title = "Swap tobe"
        }
    }, [t]);
    return (
        <>
            <div style={{ height: '900px', backgroundColor: "#fff", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div className="qs-answer-section wait">
                    <h4 className="ng-binding">{t("Identity verification pending")}</h4>
                    <span className="ng-binding" style={{ color: '#000' }}>{t("We are checking your verified identity. We will send you the results to your inbox")}</span>
                    <div className="btn-group">
                        <a href="/" onclick="window.location.href='/landing.html'" className="greenliner btn btn-default ng-binding">{t("Go back to the main page")}</a>
                    </div>
                </div>
            </div>
            {/* 
            <div style={{ padding: "90px" }}>
                <div className="qs-answer-section wait">
                    <h4 className="ng-binding">Xác minh danh tính đã bị từ chối </h4>
                    <span className="ng-binding" style={{ color: '#000' }}>Vui lòng update lại</span>
                    <div className="btn-group">
                        <a href="/" onclick="window.location.href='/landing.html'" className="greenliner btn btn-default ng-binding" onClick={UploadAgain}>Update lại KYC</a>
                    </div>
                </div>
            </div> */}
        </>
    )
}
