import { Pagination, Spin } from 'antd'
import { fixedNumber } from 'core/hook/fixedNumber'
import { formatMoney } from 'core/hook/formarMoney'
import { showSortAddress } from 'core/showSortAddress'
import React, {  useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { HandleGetHistory } from 'redux/reducer/buytbcReducer'




const titleH = [
    {
        name:"NO."
    },
    {
        name:"Amount"
    },
    {
        name:"Price"
    },
    {
        name:"Time"
    },
]








export default function History() {
    const dispatch = useDispatch()
    const [page,setPage] = useState(1)
    const [loading, setLoading] = useState(false)
    const {getHistory} = useSelector(state => state.buytbc)
    useEffect(() => {
       dispatch(HandleGetHistory(page,setLoading))
    },[page])
   
    return (
        <>
{getHistory?.total > 0 ?
        <div className="buyToken-main-history">
        <div className='history-title'>
            <h3>Reserve History</h3>
        </div>
        <div className="history-context">
            <div className="history-context-title history-context-item">
             {titleH?.map((e) => (
                <div key={e?.name}>{e?.name}</div>
             ))}
            </div>
            {loading ? <div style={{height:"350px", textAlign:'center'}}>
                <Spin  style={{marginTop:"50px"}} />
            </div>:
                <div style={{height:"350px"}}>
            {getHistory?.array?.map((e,i) => {
                    return (
                    <div className="history-context-item">
                        <div>{i + 1 + (page - 1) * 10}</div>
                        <div>{e?.amount} {e?.symbol}</div>
                        <div> {e?.price_pay} {e?.symbol_form}</div>
                        <div>{e?.created_at}</div>
                    </div>
                    )
                })}
         </div>}
         {getHistory?.total >= 10 && 
       <Pagination
                style={{ marginTop: '10px' }}
                defaultPageSize={10} //default size of page
                defaultCurrent={1}
                onChange={(page) => setPage(page)}
                total={getHistory?.total} //total number of card data available
            />
        }
          

        </div>

    </div> : ''}
    </>

 
    )
}
