import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, Redirect } from 'react-router-dom'
import Swal from 'sweetalert2'
import { useTranslate } from '../../core/translate'
import { IMGSAU, IMGTRUOC, SUCCESS } from '../../redux/type'
import { createA, createImg, getprofile } from '../../redux/action/userAction'


export default function ImageVerification({ form }) {
    let [image, setImage] = useState()
    let [image2, setImage2] = useState()
    let [active2, setActive2] = useState(false)
    let [active, setActive] = useState(false)
    let [flag, setFlag] = useState(false)
    let { axiologin } = useSelector(state => state.auth)
    let [TextError, setTextError] = useState('')



    let token = JSON.parse(localStorage.getItem('user'))
    useEffect(async () => {
        if (axiologin) {
            if (!profile) dispatch(getprofile(token.axiologin.token))
        }
    }, [])


    const { profile } = useSelector(state => state.user)

    const verified = profile?.data?.data?.verified

    useEffect(() => {
        if (verified == 3) {
            setTextError(t("Has been  admin rejected, please re-upload"))
        }
    }, [verified])
    function VerificationImg(event) {
        if (event.target.files[0].type === "image/jpeg" || event.target.files[0].type === "image/png") {
            setImage(event.target.files[0]);
            setActive(true)

        } else {
            setTimeout(() => {
                window.location.reload()
            }, 1000);
            Swal.fire({
                title: 'Chỉ nhận file Image jpg,png,svg dưới 5m',
                icon: 'error',
                timer: 1000
            })
        }
    }
    function VerificationImg2(event) {
        if (event.target.files[0].type === "image/jpeg" || event.target.files[0].type === "image/png") {
            setImage2(event.target.files[0]);
            setActive2(true)

        } else {
            setTimeout(() => {
                window.location.reload()
            }, 1000);
            Swal.fire({
                title: 'Chỉ nhận file Image jpg,png,svg dưới 5m',
                icon: 'error',
                timer: 1000
            })
        }

    }



    let { t } = useTranslate()
    useEffect(() => {
        let tab = t("KYC")
        document.title = tab
        return () => {
            document.title = "Swap tobe"
        }
    }, [t]);
    const dispatch = useDispatch()

    function onClick() {

        if (active && active2) {

            dispatch(createA(IMGTRUOC, image))
            dispatch(createA(IMGSAU, image2))
            dispatch(createA(SUCCESS, true))
            setFlag(true)
        }
        else {
            Swal.fire({
                title: t('please upload pictures'),
                icon: 'error',
                timer: 2000
            })
        }
    }
    let { userinformation } = useSelector(state => state.user)

    if (!userinformation) {
        return <Redirect to='/kyc-basic' />
    }

    if (!axiologin) {
        return <Redirect to='/form' />
    }


    if (verified == 1) {
        return <Redirect to='/verifiedKyc' />
    }

    if (verified == 2) {
        return <Redirect to='/verifiedKyc' />
    }

    return (
        <>
            {flag ? <Redirect to='/camera' /> :
                <>
                    <div className="ImageVerifications" style={{ backgroundColor: "#fff" }}>
                        <div className="container" style={{ display: 'flex', justifyContent: "center", alignItems: 'center' }}>
                            <div className="ImageVerification greenliner">
                                <div className="ImageVerification_title">
                                    <div style={{ display: 'flex', justifyContent: 'space-between', margin: '0 30px' }}>
                                        <NavLink to='/p2p' className="ImageVerification_title-back">
                                            <img src="/img/340.png" alt="" />
                                        </NavLink>
                                        <NavLink to='/p2p' className="ImageVerification_title-close">
                                            <svg width="24" height="24" viewBox="0 0 15 32" fill="#fff"><path d="M14.464 20.992q.576.576.576 1.376t-.576 1.376q-.576.512-1.376.512t-1.376-.512L7.488 18.88l-4.224 4.864q-.576.512-1.376.512t-1.376-.512Q0 23.168 0 22.368t.512-1.376L4.928 16 .512 10.944Q0 10.368 0 9.568t.512-1.376q.576-.512 1.376-.512t1.376.512l4.224 4.864 4.224-4.864q.576-.512 1.376-.512t1.376.512q.576.576.576 1.376t-.576 1.376L10.048 16z"></path></svg>
                                        </NavLink>
                                    </div>
                                    {
                                        TextError ? <div className="error" style={{ color: 'red', fontFamily: 'Arial', marginBottom: '10px', fontSize: '16px' }}>
                                            {TextError}
                                        </div> : ''
                                    }
                                    <div className="ImageVerification_title-text">
                                        <h4>{t("Verify image")}</h4>

                                    </div>
                                </div>
                                <div className="ImageVerification_mains">
                                    <div className="ImageVerification_main">
                                        <div className="ImageVerification_main-text">
                                            <svg width="16" height="16" viewBox="0 0 14 14" fill="#fff"><defs><path id="add_svg__a" d="M7.75 6.25v-6h-1.5v6h-6v1.5h6v6h1.5v-6h6v-1.5z"></path></defs><use fill="#fff" href="#add_svg__a" fill-rule="evenodd"></use></svg>
                                            <p>{t("Upload pictures")}</p>
                                            <span>{t("Wear first")}</span>
                                        </div>
                                        <div className='ImageVerification_input'>
                                            <input type="file" onChange={VerificationImg} name='file' />
                                        </div>
                                        {active ? <div className='ImageVerification_img' >
                                            <img src={URL.createObjectURL(image)} />
                                        </div> : ''
                                        }
                                    </div>
                                    <div className="ImageVerification_main">
                                        <div className="ImageVerification_main-text">
                                            <svg width="16" height="16" viewBox="0 0 14 14" fill="#fff"><defs><path id="add_svg__a" d="M7.75 6.25v-6h-1.5v6h-6v1.5h6v6h1.5v-6h6v-1.5z"></path></defs><use fill="#fff" href="#add_svg__a" fill-rule="evenodd"></use></svg>
                                            <p>{t("Upload pictures")}</p>
                                            <span >{t("Wear later")}</span>
                                        </div>
                                        <div className='ImageVerification_input'>
                                            <input type="file" onChange={VerificationImg2} name='file' />
                                        </div>
                                        {active2 ? <div className='ImageVerification_img2'>
                                            <img src={URL.createObjectURL(image2)} />
                                        </div> : ''
                                        }
                                    </div>

                                </div>
                                <div className="ImageVerification_des">
                                    <p>{t("Upload a .JPG/.JPEG/.PNG file no larger than 5M")}</p>
                                    <div className="camera_des-item"><div className="css-rh6rhj"> <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" className="css-kddq4d">
                                        <path d="M9 18.83l-6-6.01 1.41-1.41L9 16 19.18 5.82l1.42 1.42L9 18.83z" fill="#087972" /></svg></div><div className="css-d4wfic">{t("Granted by the government")}</div></div>
                                    <div className="camera_des-item"><div className="css-rh6rhj"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" className="css-kddq4d">
                                        <path d="M9 18.83l-6-6.01 1.41-1.41L9 16 19.18 5.82l1.42 1.42L9 18.83z" fill="#087972" /></svg></div><div className="css-d4wfic">{t("Original, full-sized, unedited papers")}</div></div>
                                    <div className="camera_des-item"><div className="css-rh6rhj"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" className="css-kddq4d">
                                        <path d="M9 18.83l-6-6.01 1.41-1.41L9 16 19.18 5.82l1.42 1.42L9 18.83z" fill="#087972" /></svg></div><div className="css-d4wfic">{t("Set of papers on a monochrome background")}</div></div>
                                    <div className="camera_des-item"><div className="css-rh6rhj"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" className="css-kddq4d">
                                        <path d="M9 18.83l-6-6.01 1.41-1.41L9 16 19.18 5.82l1.42 1.42L9 18.83z" fill="#087972" /></svg></div><div className="css-d4wfic">{t("Colored, well-lit, readable images")}</div></div>
                                    <div className="camera_des-item"><div className="css-rh6rhj"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" className="css-10ls1ot"><path d="M10.586 12L4.293 5.707 5 5l.707-.707L12 10.586l6.293-6.293L19 5l.707.707L13.414 12l6.293 6.293-1.414 1.414L12 13.414l-6.293 6.293L5 19l-.707-.707L10.586 12z" fill="rgb(246, 70, 93)" /></svg></div><div className="css-d4wfic">{t("Black and white images are not accepted")}</div></div>
                                    <div className="camera_des-item"><div className="css-rh6rhj"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" className="css-10ls1ot"><path d="M10.586 12L4.293 5.707 5 5l.707-.707L12 10.586l6.293-6.293L19 5l.707.707L13.414 12l6.293 6.293-1.414 1.414L12 13.414l-6.293 6.293L5 19l-.707-.707L10.586 12z" fill="rgb(246, 70, 93)" /></svg></div><div className="css-d4wfic">{t("Modified or expired paper will not be accepted")}</div></div>
                                    <div className='ImageVerification_svg'>
                                        <img src="img/ps-1.png" alt="" />
                                    </div>
                                </div>
                                <div className="ImageVerification_bottom">
                                    <div className="ImageVerification_bottom-button">
                                        <div className="ImageVerification_button" style={{ backgroundColor: "#5ea192" }}>
                                            <a href="">{t("Cancel")}</a>
                                        </div>
                                        <div className="ImageVerification_button" style={{ backgroundColor: '#FBBC05' }} onClick={onClick}>
                                            <a >{t("Next")}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>



                </>}

        </>

    )
}
