import User from "api/User";
import axios from "axios";
import { port } from "port";
import { HOMELIST } from "redux/type";

import { createA } from "./userAction";


export function createHomeAction(slug, page, values, keys, keyss) {

    return async (dispatch) => {

        // values = [
        //     User.vndusd(),
        //     User.exchange(),
        //     User.getTransactionForm()]

        // keys = [
        //     "vndusd", "exchange", "transactionform"
        // ]
        // keyss = [
        //     "1", "2", "2"
        // ]


        // Promise.all(values).then((res) => {
        //     let result = {}

        //     res.map((item, idx) => {
        //         result[keys[idx]] = item

        //     })


        // })

        try {

            let res = axios(`${port}api/p2p/buyadlist`, {
                method: 'POST',
                data: {
                    "limit": 5,
                    "page": page,
                    "symbol": slug
                }
            })
            let res2 = axios(`${port}api/p2p/selladlist`, {
                method: 'POST',
                data: {
                    "limit": 5,
                    "page": page,
                    "symbol": slug

                }
            })


            let res3 = User.vndusd()
            // let res3 = 0
            let res4 = User.exchange()
            let res5 = User.getTransactionForm()

            // let test = await Promise.all([res, res2, res3, res4, res5])
            // dispatch(createA(HOMELIST, {
            //     buy: [res?.data?.data?.array],
            //     sell: [res2?.data?.data?.array],
            //     vnd: [res3],
            //     exchange: [res4],
            //     transactionform: [res5]

            // }))

            //   Promise.all([array]).then(([buylist, sellList, vndusd, exchange, transactionform]) => {
            //     dispatch(createA(HOMELIST, {
            //         buy: [buylist?.data?.data?.array],
            //         sell: [sellList?.data?.data?.array],
            //         vnd: [vndusd],
            //         exchange: [exchange],
            //         transactionform: [transactionform]

            //     }))
            // })
            Promise.all([res, res2, res3, res4, res5]).then(([buylist, sellList, vndusd, exchange, transactionform]) => {
                dispatch(createA(HOMELIST, {
                    buy: [buylist?.data?.data?.array],
                    sell: [sellList?.data?.data?.array],
                    vnd: [vndusd],
                    exchange: [exchange],
                    transactionform: [transactionform]

                }))
            })
            //         const [buylist, sellList, vndusd, exchange, transactionform] =   Promise.all([resresQuery, res2resQuery, res3resQuery, res4resQuery, res5resQuery])
            //     dispatch(createA(HOMELIST, {
            //        buy: [buylist?.data?.data?.array],
            //        sell: [sellList?.data?.data?.array],
            //        vnd: [vndusd],
            //        exchange: [exchange],
            //        transactionform: [transactionform]

            //    }))

        } catch (error) {

        }


    }
}


