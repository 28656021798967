import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DataWithdrawVnd } from 'redux/reducer/withdrawReducer';
import AddBanking from './AddBanking';
import DataBanking from './DataBanking';
import DataWithdraw from './DataWithdraw';
import InputWithdraw from './InputWithdraw';

function Withdraw() {
    let [active, setActive] = useState()
    let [content, setContent] = useState('banking')
    let [idBanking, setIdBanking] = useState()
    const dispatch = useDispatch()

    const HanleActive = (e, i) => {
        setActive(i + 1)
        dispatch(DataWithdrawVnd(e))

    }
    const handleAddBanking = () => setContent('addbanking')
    const handleWithdraw = () => setContent('withdraw')
    return (
        <>
            {content == 'withdraw' && <InputWithdraw setContent={setContent} setIdBanking={setIdBanking} idBanking={idBanking} />}
            {content == 'addbanking' && <AddBanking setContent={setContent} setIdBanking={setIdBanking} idBanking={idBanking} />}
            {content == 'banking' &&
                <>
                    <div className='depositvnd-title withdrawvnd-title'>
                        <p>Withdraw VND</p>
                    </div>
                    <DataBanking HanleActive={HanleActive} active={active} />
                    <div className='withdrawvnd-input'>
                        <button onClick={handleAddBanking}>Add new account</button>
                        <button className={`${active ? 'active' : ''}`} onClick={handleWithdraw}>Continue</button>
                    </div>
                </>
            }
        </>
    );
}

export default Withdraw;