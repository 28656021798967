import { Spin } from 'antd'
import React, { useEffect, useState  } from 'react'
import { useDispatch } from 'react-redux'

import { makeStyles } from '@mui/styles';
import { ShowKyc } from 'redux/reducer/kycLevelReducer'
import CreateApi from 'api/CreateApi';
import { ErrorMessage, SuccessMessage } from 'core/Message';
import { useTranslate } from 'core/translate';
import { HandleGetValueMinning } from 'redux/reducer/minningReducer';
import { getprofile } from 'redux/action/userAction';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';




const useStyles = makeStyles({
 
    flex:{
        display:'flex',
        justifyContent:'space-between',
        alignItems:'center',
        gap:"30px",
    },
    button:{
        width:'150px',
        height:"30px",
        borderRadius:'10px'
    },
    overlay: {
        position: "fixed",
        top: "0",
        left: "0",
        width: "100%",
        height: "100%",
        zIndex: "998",
        opacity: "0.5",
        background: "black",
    },
    

});
export default function Popup({setNotify, notify}) {
    const history = useHistory()
    const dispatch = useDispatch()
    const {t} = useTranslate()
    const classes = useStyles();
    const [loading, setLoading] = useState(false)
    let token = JSON.parse(localStorage.getItem('user'))
    const HandleKyc = () => {
        dispatch(ShowKyc(true))
        setNotify(false)

    }
    const handleMiner = async() => {
        setLoading(true)
        try {
            let res = await CreateApi?.CreateApiSSS('api/viewearn/is-miner', true)
            SuccessMessage(res?.data?.message,t)
            dispatch(HandleGetValueMinning())
            dispatch(getprofile(token.axiologin.token))
          
        } catch (error) {
            ErrorMessage(error?.response?.data?.message, t)
        }
        setLoading(false)
        setNotify(false)
    }
    return (
        <>

{notify == 'kyc2' &&
<>
        <div className="kyc2" style={{ backgroundColor: '#f0f2f5', padding: '20px' }}>
                <div className="kyc2-main" style={{     maxWidth:'350px'}}>
                    <div className="title">
                        <span style={{fontSize:'14px'}}>{t("Please KYC level 2")} </span>
                        <img class="logo-img" src="/img/logoswapcolorful.png" style={{height:"20px"}} />

                    </div>
                    <div className="content">
                        <p style={{color:"#000", fontWeight:'700',textAlign:'center' }}>{t("Mining function only allows KYC level 2")}</p>
                        <div className={classes?.flex}>
                            {/* <button className={classes?.button} style={{background:'#b7b4b4'}} onClick={() => setNotify(false)}>Back</button> */}
                            <button className={classes?.button} style={{background:'#b7b4b4'}} onClick={() => history.push('/')}>{t("Back")}</button>
                            <button className={classes?.button} style={{background: "#1d6060"}} onClick={HandleKyc}>{t("Update Now")}</button>
                        </div>
                    </div>
                </div>
        </div>
        <div className={classes.overlay} onClick={() => setNotify(false)}/>
        </>}
{notify == 'miner' &&
    <>
        <div className="kyc2" style={{ backgroundColor: '#f0f2f5', padding: '20px' }}>
                <div className="kyc2-main" style={{     maxWidth:'350px'}}>
                    <div className="title">
                        <span>Mining </span>
                        <img class="logo-img" src="/img/logoswapcolorful.png" style={{height:"20px"}} />

                    </div>
                    <div className="content">
                        <p style={{color:"#000", fontWeight:'700',textAlign:'center' }}>Do you want to be a miner?</p>
                        <div className={classes?.flex}>
                            <button className={classes?.button} style={{background:'#b7b4b4'}}onClick={() => history.push('/')}>No</button>
                            {loading ?    <button className={classes?.button} style={{background: "#1d6060", opacity:'0.5'}} > <Spin /> Yes</button> :   <button className={classes?.button} style={{background: "#1d6060"}} onClick={handleMiner}>Yes</button>}
                         
                        </div>
                    </div>
                </div>
        </div>
        <div className={classes.overlay} onClick={() => setNotify(false)}/>
        </>}
        </>

    )
}
