
// import './App.css';
import RenderRouters from './core/RoutersConfig';
import { BrowserRouter } from "react-router-dom";
import { Provider } from 'react-redux';
import TranslateProvider from './core/translate';
import routers from './router';
import store from './redux/reducer';
import { useEffect } from 'react';
import axios from 'axios';
import { port } from './port';





function App() {

  

  useEffect(async () => {

    try {
      let token = JSON.parse(localStorage.getItem('user'))
      if (token) {
        let res = await axios({
          method: "POST",
          url: `${port}api/user/dailyInterestPackage`,
          headers: {
            'Authorization': 'Bearer ' + token?.axiologin?.token
          },
        })

      }
    } catch (error) {

    }
  }, [])

  return (
    <>
      <TranslateProvider >
        <Provider store={store}>
          <BrowserRouter>
            {RenderRouters(routers)}
          </BrowserRouter>
        </Provider>
      </TranslateProvider>




    </>
  );
}

export default App;
