import { Spin } from 'antd';
import RecaptchaF2A from 'Components/RecaptchaF2A';
import { ErrorMessage } from 'core/Message';
import React, { useEffect, useState } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Redirect, useHistory } from 'react-router-dom';


import { useTranslate } from "../../../core/translate/index"
import { port } from '../../../port';
import { createuseradlist, createSellP2Padlist, createBuyadlist } from '../../../redux/action/userAction';


export default function SellSearch() {
    let { slug2 } = useParams()
    let { slug } = useParams()
    let { page } = useParams()


    let { axiologin } = useSelector(state => state.auth)
    let { redirect } = useSelector(state => state.user)
    let { vndusd } = useSelector(state => state.home)
    let { useradlist } = useSelector(state => state.user)
    let [valueCopy, setValueCopy] = useState('')

    let valueVnd = (useradlist?.amount_exchange_usd * useradlist?.amount_exchange_vnd - (useradlist?.amount_exchange_usd * useradlist?.amount_exchange_vnd / 100) * useradlist?.percent)
    let [value, setValue] = useState(slug2)

    let [err, setErr] = useState('')
    let [value2, setValue2] = useState(value * (useradlist?.amount_exchange_usd * useradlist?.amount_exchange_vnd - (useradlist?.amount_exchange_usd * useradlist?.amount_exchange_vnd / 100) * useradlist?.percent))
    let value3 = value2 / vndusd

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        dispatch(createBuyadlist(slug, page))
    }, [slug, page])

    function inputchange(e) {
        let value = e.target.value


        let value1 = ""
        let dotIndex = value.indexOf(".")

        for (let index = 0; index < value.length; index++) {
            if (value[index].match(/[0-9]/))
                value1 += value[index]
            if (index === dotIndex)
                value1 += "."
        }
        if (slug2) {
            setValue(slug2)
            setValue2(slug2 * valueVnd)
        }

        setValue2(value1 * valueVnd)
        setValue(value1)

        if (value2 == '') {
            setValue2(value1 * valueVnd)
            setValue(value1)
        }

        if (value > useradlist?.amount_maximum) {


            setErr(`Lượng ${slug} tối đa là ${useradlist?.amount_maximum} ${slug}`)

        }

        else if (value < useradlist?.amount) {
            setErr(`Lượng ${slug} thấp nhất là ${useradlist?.amount} ${slug}`)
        }
        else {
            setErr('')
        }


    }

    const numberOnly = (str) => {
        return str.replace(/[- #*;,.<>\{\}\[\]\\\/]/gi, '');
    };
    function inputchange2(e) {
        let text = e.target.value
        let value1 = ""


        for (let index = 0; index < text.length; index++) {
            if (text[index].match(/[0-9.]/))
                value1 += text[index]
        }
        let value0 = numberOnly(value1);
        const value2 = Number(value0);
        if (value == '') {
            setValue2(value2)
            setValue(truncate(value2 / (useradlist?.amount_exchange_usd * useradlist?.amount_exchange_vnd - (useradlist?.amount_exchange_usd * useradlist?.amount_exchange_vnd / 100) * useradlist?.percent), 3))
        }
        if (value) {
            setValue2(value2)
            setValue(truncate(value2 / (useradlist?.amount_exchange_usd * useradlist?.amount_exchange_vnd - (useradlist?.amount_exchange_usd * useradlist?.amount_exchange_vnd / 100) * useradlist?.percent), 3))
        }



        if (truncate(value2 / (useradlist?.amount_exchange_usd * useradlist?.amount_exchange_vnd - (useradlist?.amount_exchange_usd * useradlist?.amount_exchange_vnd / 100) * useradlist?.percent), 3) > useradlist?.amount_maximum || truncate(value2 / (useradlist?.amount_exchange_usd * useradlist?.amount_exchange_vnd - (useradlist?.amount_exchange_usd * useradlist?.amount_exchange_vnd / 100) * 10 * useradlist?.percent), 3) > "10000") {


            setErr(`Lượng ${slug} tối đa là ${useradlist?.amount_maximum} ${slug}`)

        }
        else if (truncate(value2 / (useradlist?.amount_exchange_usd * useradlist?.amount_exchange_vnd - (useradlist?.amount_exchange_usd * useradlist?.amount_exchange_vnd / 100) * 10 * useradlist?.percent), 3) < useradlist?.amount) {
            setErr(`Lượng ${slug} thấp nhất là ${useradlist?.amount} ${slug}`)
        }
        else {
            setErr('')
        }
    }


    function truncate(value, precision) {
        var step = Math.pow(10, precision || 0);
        var temp = Math.trunc(step * value);

        var a = temp / step;
        return a.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }




    const dispatch = useDispatch()
    let token = JSON.parse(localStorage.getItem('user'))
    let history = useHistory()
    const { profile } = useSelector(state => state.user)
    let { tokenRe, value2fa } = useSelector(state => state.other)
    const twofa = profile?.data?.data?.enabled_twofa
    const verified = profile?.data?.data?.verified
    let [errTwofa, setErrTwofa] = useState('')
    let [loadding, setLoadding] = useState(false)
    function handleSubmid() {

        if (!axiologin) {
            history.push('/form')
        }
        else if (tokenRe.length > 0 || value2fa) {
            if (verified == null) {
                return history.push('/kyc-basic')
            } else if (verified == 3) {
                return history.push('/kyc-basic')
            }

            else if (verified == 2) {
                return history.push('/await')

            }
            else if (value > useradlist?.amount_maximum) {
                ErrorMessage(`The maximum of ${slug} is ${useradlist?.amount_maximum} ${slug}`, t)
            }
            else if (value < useradlist?.amount) {
                ErrorMessage(`Lowest Amount of ${slug} is ${useradlist?.amount} ${slug}`, t)
            }
            else {
                dispatch(createSellP2Padlist(token?.axiologin?.token, value, useradlist?.id, slug?.toUpperCase(), useradlist?.type_exchange, tokenRe, value2fa, t, setLoadding))
            }
            setErrTwofa('')
        } else {
            if (twofa == 0 && tokenRe == '') {
                setErrTwofa("Please enter ReCAPTCHA")

            } else {
                setErrTwofa("Please enter  2FA")

            }
        }
    }

    let { t } = useTranslate()
    const dataBuy = [
        {
            title: `${t("SwapToBe VND Wallet")}`,
            des: `${t("SwapToBe VND wallet (fiat-wallet) is used to store the VND belong to each user (similar to any other coin wallet). Users can deposit, withdraw and trade using fiat-wallet. Therefore, trading using fiat-wallet is automatic, instant and 100% safe.")}`
        },

    ]





    return (
        <>
            {redirect ? <Redirect to={`/p2p/${slug}`} /> :


                <div id="scrollable-body" style={{ overflow: 'initial' }}>

                    <section className="page-heading remi-heading">
                        <div className="css-1dbjc4n r-adacv r-1yzf0co">
                            <div className="css-1dbjc4n r-1jj8364 r-lchren r-l64lwh r-1qhn6m8 r-i023vh r-13qz1uu r-1g2xzmm r-1cmskyw">
                                <div dir="auto" className="css-901oao r-jwli3a r-320f6p r-adyw6z r-vw2c0b r-oxtfae r-37tt59">
                                    {t(`Sell ${slug?.toUpperCase()} cheap using VND vi SwapToBe VND Wallet in Việt Nam from ${useradlist?.username}`)}
                                </div>
                            </div>
                        </div>
                    </section>


                    <div className=" css-1dbjc4n r-1jj8364 r-lchren r-l64lwh r-1qhn6m8 r-i023vh r-13qz1uu r-1g2xzmm" data-testid="alert-container">
                        <div className=" css-1dbjc4n r-1g2xzmm" data-testid="flash-access-devices">
                            <div className="css-1dbjc4n r-eqz5dr r-912u2z r-1g2xzmm" style={{}} />
                        </div>
                    </div>
                    <div className="homebuy css-1dbjc4n r-11ict49" data-testid="main">
                        <div className="r-1cmskyw css-1dbjc4n r-1jj8364 r-lchren r-l64lwh r-1qhn6m8 r-i023vh r-13qz1uu r-1g2xzmm" data-testid="offer-details-container">
                            <div className="css-1dbjc4n r-eqz5dr r-v55p34 r-1g2xzmm">
                                <div className="css-1dbjc4n r-xd6kpl r-1g2xzmm">
                                    <div className="css-1dbjc4n r-18u37iz r-1w6e6rj r-6cv0rn r-1g2xzmm">
                                        <div className="r-dnmrzs  r-1fq43b1 css-1dbjc4n r-1t2qqvi r-18kxxzh r-1q142lx r-9rstsh r-r2y082 r-ymttw5 r-bnwqim r-1g2xzmm">
                                            <div className="css-1dbjc4n r-1g2xzmm" data-testid="offer-details-table">
                                                <div className="css-1dbjc4n r-eqz5dr r-912u2z r-1g2xzmm" data-testid="open-trade-form">
                                                    <div className="css-1dbjc4n r-1l7z4oj r-1g2xzmm">
                                                        <div className="homebuy_list css-1dbjc4n r-18u37iz r-1w6e6rj r-6cv0rn r-1g2xzmm">
                                                            <div className="homebuy_list-item css-1dbjc4n r-1t2qqvi r-18kxxzh r-1q142lx r-r2y082 r-ymttw5 r-bnwqim r-1g2xzmm">
                                                                <div className="css-1dbjc4n" data-testid="coin-amount">
                                                                    <div className="css-1dbjc4n r-eqz5dr r-v55p34 r-1g2xzmm" data-testid="text-input text-input-lg">
                                                                        <div className="css-1dbjc4n r-xd6kpl r-1g2xzmm">
                                                                            <div className="css-1dbjc4n r-1g2xzmm">
                                                                                <div className="css-1dbjc4n r-f1odvy">
                                                                                    <div className="css-1dbjc4n r-1awozwy r-18u37iz r-17grq5a r-1g2xzmm">
                                                                                        <div className="css-1dbjc4n r-1wbh5a2 r-1pyaxff r-1g2xzmm">
                                                                                            <div dir="auto" className="css-901oao r-1814a8r r-320f6p r-1b43r93 r-vw2c0b r-uz4sb0 r-rjixqe">
                                                                                                {t(`Amount of ${slug?.toUpperCase()}`)}*
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="css-1dbjc4n r-1g2xzmm">
                                                                                    {/* defaultValue="0.00049504" */}
                                                                                    <input className='inputbuy' style={{ color: "#000" }} onChange={inputchange} value={value} />
                                                                                    {err ? <div className='err' style={{ color: 'red', fontStyle: 'italic' }}> {err}</div> : ''}
                                                                                    <div className="css-1dbjc4n r-1awozwy r-1p0dtai r-6koalj r-18u37iz r-15ighfx r-ywje51 r-u8s1d r-zchlnj r-ipm5af r-1jaylin" data-testid="input-support" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="css-1dbjc4n r-xd6kpl r-1g2xzmm">
                                                                            <div className="css-1dbjc4n r-18u37iz r-1wbh5a2" data-testid="message message-info">
                                                                                <div dir="auto" className="css-901oao r-1kb76zh">
                                                                                    <div className="css-1dbjc4n r-xoduu5" data-testid="icon-info" style={{ height: 16, width: 16 }}>
                                                                                        <svg data-name="Layer 1" viewBox="0 0 16 16" fill="#308CD5" width={16} height={16}>
                                                                                            <circle cx={8} cy={8} r="7.64" />
                                                                                            <path d="M8.63 6.3v4.91h1.56v1.22H5.83v-1.22H7.4V7.53h-1V6.3zM8 3.09a.82.82 0 1 1-.82.82.82.82 0 0 1 .82-.82z" fill="#fff" fillRule="evenodd" />
                                                                                        </svg>
                                                                                    </div>
                                                                                </div>
                                                                                <div dir="auto" className="css-901oao r-z4f626 r-320f6p r-1b43r93 r-uz4sb0 r-rjixqe">
                                                                                    <span className="css-901oao css-16my406 r-1rf7ul2" data-testid="usd-equivalent" onChange={inputchange} value={value3}>≈ {truncate(value3)} USD</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="homebuy_list-item css-1dbjc4n r-1t2qqvi r-18kxxzh r-1q142lx r-r2y082 r-ymttw5 r-bnwqim r-1g2xzmm">
                                                                <div className="css-1dbjc4n" data-testid="coin-amount">
                                                                    <div className="css-1dbjc4n r-eqz5dr r-v55p34 r-1g2xzmm" data-testid="text-input text-input-lg">
                                                                        <div className="css-1dbjc4n r-xd6kpl r-1g2xzmm">
                                                                            <div className="css-1dbjc4n r-1g2xzmm">
                                                                                <div className="css-1dbjc4n r-f1odvy">
                                                                                    <div className="css-1dbjc4n r-1awozwy r-18u37iz r-17grq5a r-1g2xzmm">
                                                                                        <div className="css-1dbjc4n r-1wbh5a2 r-1pyaxff r-1g2xzmm">
                                                                                            <div dir="auto" className="css-901oao r-1814a8r r-320f6p r-1b43r93 r-vw2c0b r-uz4sb0 r-rjixqe">
                                                                                                {t("Amount of VND: *")}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="css-1dbjc4n r-1g2xzmm">
                                                                                    <input className='inputbuy' inputMode="text" id="fiat_amount" name="fiatAmount" autoComplete="off" required type="text" defaultValue="400,481" style={{ color: "#000" }} onChange={inputchange2} value={truncate(value2)} />
                                                                                    <div className="css-1dbjc4n r-1awozwy r-1p0dtai r-6koalj r-18u37iz r-15ighfx r-ywje51 r-u8s1d r-zchlnj r-ipm5af r-1jaylin" data-testid="input-support" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="css-1dbjc4n r-1l7z4oj r-1g2xzmm">
                                                        <div className="homebuy_list css-1dbjc4n r-18u37iz r-1w6e6rj r-6cv0rn r-1g2xzmm">
                                                            {/* <div className="homebuy_list-item css-1dbjc4n r-1t2qqvi r-18kxxzh r-1q142lx r-r2y082 r-ymttw5 r-bnwqim r-1g2xzmm">
                                                        <div>
                                                            <div className="btc-address-input form-group" style={{ color: '#000', fontWeight: '500' }}>
                                                                <div className="select-form-group dropdown-address-root">
                                                                    <div className="dropdown-address-control" >
                                                                        <div className="dropdown-address-placeholder" onClick={clickSelect}>
                                                                            {state}
                                                                        </div>
                                                                        <span className="qr-scanner-span" />
                                                                        <span className="dropdown-address-span">
                                                                            <div className="css-1dbjc4n" data-testid="icon-down-open-1" style={{ height: 14, width: 14 }}>
                                                                                <svg width={14} height={14} viewBox="0 0 19 32" fill="#5E5862">
                                                                                    <path d="M18.048 13.696l-7.488 7.168q-.576.576-1.28.576T8 20.864L.512 13.696Q0 13.184 0 12.384t.512-1.312q1.216-1.216 2.496 0l6.272 6.016 6.272-6.016q1.28-1.216 2.496 0 .512.512.512 1.312t-.512 1.312z" />
                                                                                </svg>
                                                                            </div>
                                                                        </span>
                                                                    </div>
                                                                    {
                                                                        active ? <div className="dropdown-address-menu">
                                                                            <div className="dropdown-address-option remi-wallet" >
                                                                                {
                                                                                    <a onClick={handleText} name={"SWAPTOBE VND Wallet"} >BOSS VND Wallet</a>
                                                                                }

                                                                            </div>
                                                                        </div> : ''
                                                                    }

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> */}


                                                            <div className="homebuy_list-item css-1dbjc4n r-1t2qqvi r-18kxxzh r-1q142lx r-r2y082 r-ymttw5 r-bnwqim r-1g2xzmm">
                                                                {axiologin &&

                                                                    <RecaptchaF2A />

                                                                }
                                                                {errTwofa && <p className=" errorLogin" style={{ marginTop: '0' }}>{t(errTwofa)}</p>}
                                                                {loadding ? <div className="example" style={{ margin: '0' }}>
                                                                    <Spin />
                                                                </div> :
                                                                    <div className="css-1dbjc4n r-z2wwpe r-1udh08x" onClick={handleSubmid}>
                                                                        <div role="button" aria-busy="false" className="css-18t94o4 css-1dbjc4n r-1loqt21 r-1otgn73 r-1i6wzkk r-lrvibr" data-testid="btn-open-trade" data-focusable="true" tabIndex={0} style={{ transitionDuration: '0s' }}>
                                                                            <div className="css-1dbjc4n r-1awozwy r-z2wwpe r-18u37iz r-1777fci r-5njf8e" style={{ backgroundColor: '#34a853', borderWidth: 0, minHeight: 40, padding: '8px 16.1px' }}>
                                                                                <div dir="auto" className="css-901oao r-jwli3a r-320f6p r-ubezar r-oxtfae r-135wba7 r-rjfia" style={{}}>
                                                                                    {t(`Sell ${slug?.toUpperCase()}`)}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="css-1dbjc4n r-qklmqi r-r3u836 r-1phboty r-pw2am6" />
                                            <div className="css-1dbjc4n r-eqz5dr r-912u2z r-1g2xzmm" style={{ color: '#000' }}>
                                                <div className="css-1dbjc4n r-1l7z4oj r-1g2xzmm">
                                                    <div className="css-1dbjc4n r-14lw9ot r-adb5am r-z2wwpe r-1phboty r-rs99b7 r-1g2xzmm" data-testid="offer-details">
                                                        <div className="css-1dbjc4n r-1habvwh r-1qnjcxv r-18u37iz r-nsbfu8 r-1g2xzmm">
                                                            <div className="css-1dbjc4n r-1kihuf0 r-z4f626 r-16y2uox r-1wbh5a2 r-320f6p r-adyw6z r-oxtfae r-37tt59 r-1g2xzmm">
                                                                <div dir="auto" className="css-901oao r-homxoj r-320f6p r-7cikom r-1s2g6fe r-t9a87b">
                                                                    {t("Advertisement details")}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="css-1dbjc4n r-mk0yit r-rjfia r-1g2xzmm">
                                                            <div className="css-1dbjc4n r-1awozwy r-6koalj r-1777fci r-1g2xzmm">
                                                                <div data-focusable="true" tabIndex={0} className="css-1dbjc4n r-1pz39u2 r-13awgt0 r-18u37iz r-1g2xzmm">
                                                                    <div aria-disabled="true" disabled className="css-1dbjc4n r-1pz39u2 r-qklmqi r-r3u836 r-1777fci r-ymttw5 r-1yzf0co r-1g2xzmm" style={{ borderRightWidth: 1, flex: '0.5 1 0%' }}>
                                                                        <div dir="auto" className="css-901oao r-homxoj r-320f6p r-7cikom r-1s2g6fe r-t9a87b r-tsynxw">
                                                                            {t("Buying from")}
                                                                        </div>
                                                                    </div>
                                                                    <div aria-disabled="true" disabled className="css-1dbjc4n r-1pz39u2 r-qklmqi r-r3u836 r-13awgt0 r-1777fci r-ymttw5 r-1yzf0co r-1g2xzmm">
                                                                        <a href={`/profile/${slug}/${useradlist?.username}`} store="[object Object]">
                                                                            <div dir="auto" className="css-901oao r-z4f626 r-320f6p r-7cikom r-1s2g6fe r-t9a87b r-tsynxw">
                                                                                <u>{useradlist?.username}</u>
                                                                            </div>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                                <div data-focusable="true" tabIndex={0} className="css-1dbjc4n r-1pz39u2 r-13awgt0 r-18u37iz r-1g2xzmm">
                                                                    <div aria-disabled="true" disabled className="css-1dbjc4n r-1pz39u2 r-qklmqi r-r3u836 r-1777fci r-ymttw5 r-1yzf0co r-1g2xzmm" style={{ borderRightWidth: 1, flex: '0.5 1 0%' }}>
                                                                        <div dir="auto" className="css-901oao r-homxoj r-320f6p r-7cikom r-1s2g6fe r-t9a87b">
                                                                            Giá
                                                                        </div>
                                                                    </div>
                                                                    <div aria-disabled="true" disabled className="css-1dbjc4n r-1pz39u2 r-qklmqi r-r3u836 r-13awgt0 r-1777fci r-ymttw5 r-1yzf0co r-1g2xzmm">
                                                                        <div dir="auto" className="css-901oao r-homxoj r-1loqt21 r-320f6p r-7cikom r-vw2c0b r-1s2g6fe r-t9a87b" data-testid="offer-price">
                                                                            <span className="css-901oao css-16my406 r-1rf7ul2">{`${truncate(useradlist?.amount_exchange_usd * useradlist?.amount_exchange_vnd - (useradlist?.amount_exchange_usd * useradlist?.amount_exchange_vnd / 100) * useradlist?.percent)}`} </span>
                                                                            <span className="css-901oao css-16my406 r-1rf7ul2 r-tsynxw">VND/</span>
                                                                            <span className="css-901oao css-16my406 r-4287bc">{slug?.toUpperCase()}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div data-focusable="true" tabIndex={0} className="css-1dbjc4n r-1pz39u2 r-13awgt0 r-18u37iz r-1g2xzmm">
                                                                    <div aria-disabled="true" disabled className="css-1dbjc4n r-1pz39u2 r-qklmqi r-r3u836 r-1777fci r-ymttw5 r-1yzf0co r-1g2xzmm" style={{ borderRightWidth: 1, flex: '0.5 1 0%' }}>
                                                                        <div dir="auto" className="css-901oao r-homxoj r-320f6p r-7cikom r-1s2g6fe r-t9a87b">
                                                                            {t("Amount limits:")}
                                                                        </div>
                                                                    </div>
                                                                    <div aria-disabled="true" disabled className="css-1dbjc4n r-1pz39u2 r-qklmqi r-r3u836 r-13awgt0 r-1777fci r-ymttw5 r-1yzf0co r-1g2xzmm">
                                                                        <div className="css-1dbjc4n r-18u37iz r-1w6e6rj r-p1pxzi r-2eo2mk r-1g2xzmm">
                                                                            <div className="css-1dbjc4n r-1wbh5a2 r-1mdbw0j r-1hvjb8t r-1g2xzmm">
                                                                                <span className="currency-amount">
                                                                                    <span className="badge badge-warning btc">{useradlist?.amount} {slug.toUpperCase()}</span>
                                                                                </span>
                                                                            </div>
                                                                            <div className="css-1dbjc4n r-1wbh5a2 r-1mdbw0j r-1hvjb8t r-1g2xzmm">
                                                                                <div dir="auto" className="css-901oao r-homxoj r-320f6p r-7cikom r-1s2g6fe r-t9a87b">
                                                                                    -
                                                                                </div>
                                                                            </div>
                                                                            <div className="css-1dbjc4n r-1wbh5a2 r-1mdbw0j r-1hvjb8t r-1g2xzmm">
                                                                                <span className="currency-amount"><span className="badge badge-warning btc">{useradlist?.amount_maximum} {slug?.toUpperCase()}</span>
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div data-focusable="true" tabIndex={0} className="css-1dbjc4n r-1pz39u2 r-13awgt0 r-18u37iz r-1g2xzmm">
                                                                    <div aria-disabled="true" disabled className="css-1dbjc4n r-1pz39u2 r-qklmqi r-r3u836 r-1777fci r-ymttw5 r-1yzf0co r-1g2xzmm" style={{ borderRightWidth: 1, flex: '0.5 1 0%' }}>
                                                                        <div dir="auto" className="css-901oao r-homxoj r-320f6p r-7cikom r-1s2g6fe r-t9a87b">
                                                                            {t("Payment method")}
                                                                        </div>
                                                                    </div>
                                                                    <div aria-disabled="true" disabled className="css-1dbjc4n r-1pz39u2 r-qklmqi r-r3u836 r-13awgt0 r-1777fci r-ymttw5 r-1yzf0co r-1g2xzmm">
                                                                        <div dir="auto" className="css-901oao r-homxoj r-320f6p r-7cikom r-1s2g6fe r-t9a87b">
                                                                            <span className="css-901oao css-16my406">{t("SwapToBe VND Wallet")}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div data-focusable="true" tabIndex={0} className="css-1dbjc4n r-1pz39u2 r-13awgt0 r-18u37iz r-1g2xzmm">
                                                                    <div aria-disabled="true" disabled className="css-1dbjc4n r-1pz39u2 r-qklmqi r-r3u836 r-1777fci r-ymttw5 r-1yzf0co r-1g2xzmm" style={{ borderRightWidth: 1, flex: '0.5 1 0%' }}>
                                                                        <div dir="auto" className="css-901oao r-homxoj r-320f6p r-7cikom r-1s2g6fe r-t9a87b">
                                                                            {t("Location")}
                                                                        </div>
                                                                    </div>
                                                                    <div aria-disabled="true" disabled className="css-1dbjc4n r-1pz39u2 r-qklmqi r-r3u836 r-13awgt0 r-1777fci r-ymttw5 r-1yzf0co r-1g2xzmm">
                                                                        <div dir="auto" className="css-901oao r-homxoj r-320f6p r-7cikom r-1s2g6fe r-t9a87b">
                                                                            Việt Nam
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div data-focusable="true" tabIndex={0} className="css-1dbjc4n r-1pz39u2 r-13awgt0 r-18u37iz r-1g2xzmm">
                                                                    <div aria-disabled="true" disabled className="css-1dbjc4n r-1pz39u2 r-qklmqi r-r3u836 r-1777fci r-ymttw5 r-1yzf0co r-1g2xzmm" style={{ borderRightWidth: 1, flex: '0.5 1 0%' }}>
                                                                        <div dir="auto" className="css-901oao r-homxoj r-320f6p r-7cikom r-1s2g6fe r-t9a87b">
                                                                            {t("Payment window")}
                                                                        </div>
                                                                    </div>
                                                                    <div aria-disabled="true" disabled className="css-1dbjc4n r-1pz39u2 r-qklmqi r-r3u836 r-13awgt0 r-1777fci r-ymttw5 r-1yzf0co r-1g2xzmm">
                                                                        <div dir="auto" className="css-901oao r-homxoj r-320f6p r-7cikom r-1s2g6fe r-t9a87b">
                                                                            {t("15 minutes")}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div data-focusable="true" tabIndex={0} className="css-1dbjc4n r-1pz39u2 r-13awgt0 r-18u37iz r-1g2xzmm">
                                                                    <div aria-disabled="true" disabled className="css-1dbjc4n r-1awozwy r-1pz39u2 r-qklmqi r-r3u836 r-1777fci r-ymttw5 r-1yzf0co r-1g2xzmm" style={{ flex: '2 1 0%' }}>
                                                                        <div className="css-1dbjc4n r-18u37iz">
                                                                            <div className="css-1dbjc4n r-1awozwy r-18u37iz">
                                                                                <div className="css-1dbjc4n" data-testid="icon-lock" style={{ height: 14, width: 14 }}>
                                                                                    <svg width={14} height={14} viewBox="0 0 21 32" fill="#5E5862">
                                                                                        <path d="M5.728 13.728h9.12v-3.456q0-1.888-1.344-3.232t-3.232-1.312T7.04 7.04t-1.312 3.232v3.456zm14.848 1.696v10.304q0 .704-.512 1.184t-1.216.512H1.728q-.736 0-1.216-.512T0 25.728V15.424q0-.704.512-1.216t1.216-.48h.544v-3.456q0-3.264 2.368-5.632t5.632-2.368 5.664 2.368 2.336 5.632v3.456h.576q.736 0 1.216.48t.512 1.216z" />
                                                                                    </svg>
                                                                                </div>
                                                                                <div className="css-1dbjc4n r-1wbh5a2 r-1777fci r-1jkjb">
                                                                                    <div dir="auto" className="css-901oao r-159qvuu r-320f6p r-7cikom r-1s2g6fe r-t9a87b">
                                                                                        {t("Documents verification required")}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="css-1dbjc4n r-1l7z4oj r-1g2xzmm">
                                                    <div className="css-1dbjc4n r-1g2xzmm" data-testid="offer-share-btns">
                                                        <div className="css-1dbjc4n r-eqz5dr r-1ytwq0x r-1g2xzmm">
                                                            <div className="css-1dbjc4n r-1g2xzmm" style={{ paddingBottom: 6 }}>
                                                                <div dir="auto" className="css-901oao r-homxoj r-320f6p r-1i10wst r-vw2c0b r-oxtfae r-eaezby">
                                                                    {t("Share:")}
                                                                </div>
                                                            </div>
                                                            <div className="css-1dbjc4n r-1g2xzmm" style={{ paddingBottom: 6 }}>
                                                                <div className="css-1dbjc4n r-18u37iz r-1w6e6rj r-v55p34 r-xzortm r-1g2xzmm">
                                                                    <div className="css-1dbjc4n r-1wbh5a2 r-xd6kpl r-i023vh r-1g2xzmm">
                                                                        <a href={`https://www.facebook.com/dialog/feed?app_id=117488185335202&display=popup&link=${port}`} target="_blank" role="link" data-focusable="true" className="css-4rbku5 css-18t94o4 css-1dbjc4n r-k200y r-1loqt21 r-1otgn73" rel=" noopener noreferrer" style={{ textDecorationLine: 'none' }}><div className="css-1dbjc4n r-z2wwpe r-1udh08x">
                                                                            <div role="button" aria-busy="false" data-focusable="true" tabIndex={0} className="css-18t94o4 css-1dbjc4n r-1loqt21 r-1otgn73 r-1i6wzkk r-lrvibr" style={{ transitionDuration: '0s' }}>
                                                                                <div className="css-1dbjc4n r-1awozwy r-k200y r-z2wwpe r-18u37iz r-1777fci r-5njf8e" style={{ backgroundColor: 'rgb(59, 89, 152)', borderWidth: 0, minHeight: 40, padding: '8px 16.1px' }}>
                                                                                    <div className="css-1dbjc4n" data-testid="icon-facebook-squared" style={{ height: 24, width: 24 }}>
                                                                                        <svg width={24} height={24} viewBox="0 0 27 32" fill="#FFFFFF">
                                                                                            <path d="M22.272 2.272q2.144 0 3.648 1.536t1.504 3.616v17.152q0 2.112-1.504 3.616t-3.648 1.536h-3.328V19.072h3.552l.512-4.128h-4.064v-2.656q0-.992.416-1.472t1.632-.512l2.176-.032v-3.68q-1.12-.16-3.2-.16-2.4 0-3.872 1.408t-1.44 4.064v3.04h-3.584v4.128h3.584v10.656H5.152q-2.144 0-3.648-1.536T0 24.576V7.424q0-2.112 1.504-3.616t3.648-1.536h17.12z" />
                                                                                        </svg>
                                                                                    </div>
                                                                                    <div dir="auto" className="css-901oao r-jwli3a r-320f6p r-ubezar r-oxtfae r-135wba7 r-1jkjb r-rjfia">
                                                                                        Facebook
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div></a>
                                                                    </div>
                                                                    <div className="css-1dbjc4n r-1wbh5a2 r-xd6kpl r-i023vh r-1g2xzmm">
                                                                        <a href={`https://twitter.com/intent/tweet?text=${port}`} target="_blank" role="link" data-focusable="true" className="css-4rbku5 css-18t94o4 css-1dbjc4n r-k200y r-1loqt21 r-1otgn73" rel=" noopener noreferrer" style={{ textDecorationLine: 'none' }}><div className="css-1dbjc4n r-z2wwpe r-1udh08x">
                                                                            <div role="button" aria-busy="false" data-focusable="true" tabIndex={0} className="css-18t94o4 css-1dbjc4n r-1loqt21 r-1otgn73 r-1i6wzkk r-lrvibr" style={{ transitionDuration: '0s' }}>
                                                                                <div className="css-1dbjc4n r-1awozwy r-k200y r-z2wwpe r-18u37iz r-1777fci r-5njf8e" style={{ backgroundColor: 'rgb(85, 172, 238)', borderWidth: 0, minHeight: 40, padding: '8px 16.1px' }}>
                                                                                    <div className="css-1dbjc4n" data-testid="icon-twitter-squared" style={{ height: 24, width: 24 }}>
                                                                                        <svg width={24} height={24} viewBox="0 0 27 32" fill="#FFFFFF">
                                                                                            <path d="M22.848 10.88q-.992.448-2.144.608 1.216-.704 1.664-2.08-1.184.672-2.4.896-1.088-1.152-2.72-1.152-1.568 0-2.656 1.088t-1.12 2.656q0 .512.096.864-2.304-.128-4.32-1.184T5.824 9.824q-.512.896-.512 1.888 0 2.048 1.632 3.136-.864-.032-1.792-.48v.032q0 1.344.896 2.4t2.176 1.28q-.512.16-.896.16-.224 0-.704-.096.384 1.152 1.344 1.888t2.144.736q-2.048 1.6-4.64 1.6-.48 0-.896-.064Q7.2 24 10.336 24q1.984 0 3.744-.64t3.008-1.696 2.144-2.432 1.344-2.912.416-3.008v-.48q1.12-.8 1.856-1.952zm4.576-3.456v17.152q0 2.112-1.504 3.616t-3.648 1.536H5.152q-2.144 0-3.648-1.536T0 24.576V7.424q0-2.112 1.504-3.616t3.648-1.536h17.12q2.144 0 3.648 1.536t1.504 3.616z" />
                                                                                        </svg>
                                                                                    </div>
                                                                                    <div dir="auto" className="css-901oao r-jwli3a r-320f6p r-ubezar r-oxtfae r-135wba7 r-1jkjb r-rjfia">
                                                                                        Twitter
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div></a>
                                                                    </div>
                                                                    <div className="css-1dbjc4n r-1wbh5a2 r-xd6kpl r-i023vh r-1g2xzmm">
                                                                        <div className="css-1dbjc4n" data-testid="referral-link">
                                                                            <div data-focusable="true" tabIndex={0} className="css-1dbjc4n r-1loqt21 r-1otgn73 r-1i6wzkk r-lrvibr" data-testid="https://SwapToBe.com/btc/vn/offers/381715-mua-bitcoin-gi%C3%A1-t%E1%BB%91t-v%E1%BB%9Bi-VND-t%E1%BA%A1i-Vi%E1%BB%87t-Nam-qua-fiat-wallet-t%E1%BB%AB-ryanhiga25?ref=3126657" style={{ transitionDuration: '0s' }}>
                                                                                <div className="css-1dbjc4n r-z2wwpe r-1udh08x">
                                                                                    <div role="button" aria-busy="false" data-focusable="true" tabIndex={0} className="css-18t94o4 css-1dbjc4n r-1loqt21 r-1otgn73 r-1i6wzkk r-lrvibr" style={{ transitionDuration: '0s' }}>
                                                                                        <div className="css-1dbjc4n r-1awozwy r-k200y r-z2wwpe r-18u37iz r-1777fci r-5njf8e" style={{ backgroundColor: 'rgb(94, 88, 98)', borderWidth: 0, minHeight: 40, padding: '8px 16.1px' }}>
                                                                                            <div className="css-1dbjc4n" data-testid="icon-clone" style={{ height: 24, width: 24 }}>
                                                                                                <svg width={24} height={24} viewBox="0 0 32 32" fill="#FFFFFF">
                                                                                                    <path d="M29.728 29.152V9.728q0-.256-.192-.416t-.384-.16H9.728q-.256 0-.416.16t-.16.416v19.424q0 .224.16.384t.416.192h19.424q.224 0 .384-.192t.192-.384zM32 9.728v19.424q0 1.184-.832 2.016T29.152 32H9.728q-1.184 0-2.016-.832t-.864-2.016V9.728q0-1.184.864-2.048t2.016-.832h19.424q1.184 0 2.016.832T32 9.728zm-6.848-6.88v2.88h-2.304v-2.88q0-.224-.16-.384t-.416-.192H2.848q-.224 0-.384.192t-.192.384v19.424q0 .256.192.416t.384.16h2.88v2.304h-2.88q-1.152 0-2.016-.864T0 22.272V2.848Q0 1.664.832.832T2.848 0h19.424q1.184 0 2.048.832t.832 2.016z" />
                                                                                                </svg>
                                                                                            </div>
                                                                                            <CopyToClipboard text={`http://localhost:3000/sell/${slug}`}
                                                                                                onCopy={() => setValueCopy({ copied: true })}>
                                                                                                <button dir="auto" className="css-901oao r-jwli3a r-320f6p r-ubezar r-oxtfae r-135wba7 r-1jkjb r-rjfia" style={{ backgroundColor: "initial" }}>
                                                                                                    {t("Copy link:")}
                                                                                                </button>
                                                                                            </CopyToClipboard>

                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="css-1dbjc4n r-qklmqi r-r3u836 r-1phboty r-pw2am6" />
                                            <div className="css-1dbjc4n r-1g2xzmm">
                                                <div className="accordion" style={{ color: '#000' }}>
                                                    <AccordionBuy data={dataBuy} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

function AccordionBuy({ data }) {

    let [active, setActive] = useState(-1)
    function ShowAccordionBuy(i) {
        if (active === i) {
            return setActive(null)
        }
        setActive(i)
    }


    return (
        <>
            {data.map((item, i) => {
                return (
                    <div className="accordion-item" key={i} onClick={() => ShowAccordionBuy(i)}>
                        <div className="text-container">
                            <div className="title text-16">
                                <strong>{item.title}</strong>
                                <div className={active === i ? "icon-container active" : "icon-container"} >
                                    <div className="css-1dbjc4n" data-testid="icon-down-open-big" style={{ height: 16, width: 16 }}>
                                        <svg width={16} height={16} viewBox="0 0 28 32" fill="#34A853">
                                            <path d="M2.016 8.96l11.84 11.392L25.76 8.96q.704-.832 1.536 0 .832.704 0 1.536L14.624 23.04q-.704.704-1.536 0L.416 10.496q-.832-.832 0-1.536.768-.768 1.6 0z" />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            <div className={active === i ? 'content text-16 active' : 'content text-16'}>
                                <p>
                                    {item.des}
                                </p>
                            </div>
                        </div>
                    </div>
                )
            })}
        </>
    )
}