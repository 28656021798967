import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';

import { Redirect, useParams, useHistory, NavLink } from 'react-router-dom';
import { useTranslate } from "../../../core/translate/index"
import { createCoin, createGetPricecoin, } from '../../../redux/action/coinAction';


import useLockedBody from '../../../core/useLockedBody'
import { createAddSellP2P, createBuysell, createTransactionForm } from '../../../redux/action/userAction';
import { ErrorMessage } from '../../../core/Message';
import { port } from '../../../port';
import { toggleShadow } from '../../../redux/action/toggleShadowActive';
import ShadowLayer from '../../../Components/ShadowLayer';
import { PRICECOIN } from '../../../redux/type';

import { Spin } from 'antd';
import createApiNap from '../../../core/createApiNap';
import covertCoin from '../../../core/CovertCoin';
import { formatMoney } from 'core/hook/formarMoney';
import { fixedNumber } from 'core/hook/fixedNumber';
import RecaptchaF2A from 'Components/RecaptchaF2A';





export default function CreateAdsSell() {

    let { buysell, transactionform, redirect } = useSelector(state => state.user)
    let { vndusd } = useSelector(state => state.home)
    let [value, setValue] = useState()
    let [value2, setValue2] = useState(0.5)
    let [value3, setValue3] = useState(0.5)
    let [error, setEroor] = useState(false)
    let [error2, setEroor2] = useState(false)
    let [error3, setEroor3] = useState(false)

    let [active, setActive] = useState(false)
    let [createSell, setCreateSell] = useState(true)
    let [createSell2, setCreateSell2] = useState(false)
    let [state, setState] = useState("SWAP VND Wallet")
    let [createActive, setCreateActive] = useState("SWAP VND Wallet")
    let [valueMin, setValueMin] = useState(0)
    let { slug } = useParams()
    let { type } = useParams()
    let exchange = localStorage.getItem('exchange')
    let [preview, setPreview] = useState(false)
    const shadowLayer = useSelector(state => state.shadowLayer)
    let { flag } = shadowLayer
    useLockedBody(preview)
    const dispatch = useDispatch()
    let { t } = useTranslate()
    useEffect(() => {
        let tab = t("Create new advertisement")
        document.title = tab
        return () => {
            document.title = "Swap tobe"
        }
    }, [t]);
    useEffect(() => {
        setValueMin('')
    }, [slug])
    let { axiologin } = useSelector(state => state.auth)
    let { pricecoin } = useSelector(state => state.coin)
    let token = JSON.parse(localStorage.getItem('user'))

    useEffect(() => {
        window.scrollTo(0, 0)
        dispatch(createBuysell())
        dispatch(createTransactionForm())

    }, [])

    let reattime = 0

    if (pricecoin) {
        pricecoin?.filter((e) => {
            if (e.name == slug?.toUpperCase()) {
                reattime = e

            }
        })
    }
    let gvalue = [{
        name: "",
        vnd: reattime.price * vndusd,
        image: ''

    }]
    if (pricecoin?.data?.data?.length > 0) {
        gvalue = pricecoin?.filter(e => e.name == slug?.toUpperCase())
    }
    useEffect(() => {
        setValue('')
        setEroor2('')
        setEroor('')
    }, [slug])




    function truncate(value, precision) {
        var step = Math.pow(10, precision || 0);
        var temp = Math.trunc(step * value);
        var a = temp / step;
        return a.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    function clickSelect() {
        setActive(!active)
    }

    let [idWallet, setIdWallet] = useState(1)
    function handleText(e) {
        let name = e.target.name
        let id = e.target.id

        setIdWallet(id)

        setCreateActive(name)
        setState(name)
    }

    function clickSell() {
        setCreateSell(true)
        setCreateSell2(false)
    }
    function clickSell2() {
        setCreateSell(false)
        setCreateSell2(true)
    }


    const separateNumberWithDot = (number) => {
        if (number === 0) {
            return '';
        }
        return number.toLocaleString('en-GB');
    };

    const numberOnly = (str) => {
        return str.replace(/[- #*;,.<>\{\}\[\]\\\/]/gi, '');
    };

    let valueSell = ((((reattime.price * vndusd) / 100) * exchange + (reattime.price * vndusd)))

    function inputChange(e) {
        let text = e.target.value
        let value1 = ""

        text = text.split(',').join('')
        let dotIndex = text.indexOf(".")

        for (let index = 0; index < text.length; index++) {
            if (text[index].match(/[0-9]/))
                value1 += text[index]
            if (index === dotIndex) {
                value1 += "."
            }

        }


        if (dotIndex !== -1) {
            value1 = value1.slice(0, dotIndex + 3)
        }
        setValue(value1)
        let value0 = numberOnly(value1);
        const value2 = Number(value0);
        let vndMin = valueSell - ((valueSell * 5) / 100)
        let vndMax = valueSell + ((valueSell * 5) / 100)
        setValueMin(separateNumberWithDot(Math.floor(parseFloat(value1) + (parseFloat(value1) / 100) * buysell)))
        if (value1 == '') {
            setValueMin(0)
        }
        if (value1 < vndMin) {
            setEroor(true)
        }
        else {
            setEroor(false)
        }
        if (value1 > vndMax) {
            setEroor2(true)
        }
        else {
            setEroor2(false)
        }
    }
    function openPreview() {
        setPreview(true)
    }

    function closePreview() {
        setPreview(false)
    }
    function inputchange2(e) {


        let value = e.target.value
        let value1 = ""
        let dotIndex = value.indexOf(".")

        for (let index = 0; index < value.length; index++) {
            if (value[index].match(/[0-9]/))
                value1 += value[index]
            if (index === dotIndex)
                value1 += "."
        }
        setValue2(value1)

    }
    function inputchange3(e) {
        let value = e.target.value
        let value1 = ""
        let dotIndex = value.indexOf(".")

        for (let index = 0; index < value.length; index++) {
            if (value[index].match(/[0-9]/))
                value1 += value[index]
            if (index === dotIndex)
                value1 += "."
        }
        setValue3(value1)

    }
    useEffect(() => {
        if (slug == "STF" || slug == "stf") {
            setEroor3(true)
        } else {
            setEroor3(false)
        }
    }, [slug])
    let history = useHistory()
    const { profile } = useSelector(state => state.user)
    let [loadding, setLoadding] = useState(false)
    const verified = profile?.data?.data?.verified
    const twofa = profile?.data?.data?.enabled_twofa
    let { tokenRe, value2fa } = useSelector(state => state.other)
    let [err, setErr] = useState()

    function submit() {
        if (!axiologin) {
            history.push('/form')
        }
        else if (tokenRe.length > 0 || value2fa) {
            if (slug == "STF" || slug == "stf") {
                ErrorMessage('STF chưa thể giao dịch lúc nào', t)
            }
            else if (verified == null) {
                return history.push('/kyc-basic')
            } else if (verified == 3) {
                return history.push('/kyc-basic')
            }

            else if (verified == 2) {
                return history.push('/await')

            }
            let usd = 0
            if (value) {
                usd = value.replace(/\,/g, "");
            }

            if (error3 == false && error == false && error2 == false) {
                setErr('')
                dispatch(createAddSellP2P(token.axiologin.token, covertCoin(reattime.name?.toUpperCase(), type), usd / vndusd, value2, value3, idWallet, covertCoin(slug?.toUpperCase(), type), t, setLoadding, tokenRe, value2fa))
            } else {
                ErrorMessage('Please check the price again', t)
            }
        } else {
            if (twofa == 0 && tokenRe == '') {
                setErr("Please enter ReCAPTCHA")

            } else {
                setErr("Please enter  2FA")

            }
        }


    }
    const chooseCoin = () => {
        return (
            pricecoin?.map((e, i) => {
                if (e?.name != "STF" && e?.name != "QGC" && e?.name != "NBB" && e?.p2p == 1)
                    return (
                        <NavLink onClick={() => {
                            if (axiologin)
                                createApiNap(e?.name, e?.type, dispatch, token, t,setLoadding)
                        }} to={`/createadssell/${e?.name}/${e?.type}`} className="item " >
                            <div className="icon"> <img src={`${port}images/${e?.name}.png`} style={{ width: "25px" }} /></div>
                            <div>
                                <span style={{ color: "#000" }}>{e?.name}</span>
                            </div>
                        </NavLink>
                    )

            })
        )
    }
    return (
        <>
            {redirect ? <Redirect to={`/p2p/${slug}`} /> : <>
                {flag &&
                    <>
                        <div style={{ zIndex: 1001, color: "#000" }}>
                            <div className={`change-btc-container ${flag && "active"}`}>

                                <div className="CreateMenu">
                                <div>{t("Choose coin")}</div>
                                    <div onClick={() => dispatch(toggleShadow(!flag))}><i class="fas fa-times"></i></div>
                                </div>

                                <div className="content">
                                    <div className='content-title'>{t("Choose Cryptocurrency")}</div>

                                    <div className='content-list-item'>
                                        {chooseCoin()}

                                    </div>

                                    <div className="content-btn" >
                                        <button onClick={() => dispatch(toggleShadow(!flag))}>{t("Back")}</button>
                                        <button onClick={() => dispatch(toggleShadow(!flag))} className="highlight">Thay đổi</button>
                                    </div>
                                </div>
                            </div >
                            <ShadowLayer style={{ zIndex: 1000 }} />
                        </div>
                    </>
                }
                {preview ?
                    <>
                        <div className="popupcreateads">
                            <div className="popupcreateadss">
                                <div className="popupcreateadss_title">
                                    <h4>Preview</h4>
                                    <div onClick={closePreview}>
                                        <svg width={24} height={24} viewBox="0 0 15 32" fill="#767478"><path d="M14.464 20.992q.576.576.576 1.376t-.576 1.376q-.576.512-1.376.512t-1.376-.512L7.488 18.88l-4.224 4.864q-.576.512-1.376.512t-1.376-.512Q0 23.168 0 22.368t.512-1.376L4.928 16 .512 10.944Q0 10.368 0 9.568t.512-1.376q.576-.512 1.376-.512t1.376.512l4.224 4.864 4.224-4.864q.576-.512 1.376-.512t1.376.512q.576.576.576 1.376t-.576 1.376L10.048 16z" /></svg>
                                    </div>
                                </div>
                                <div className="popupcreateadss_main">
                                    <div className="popupcreateadss_main-item">
                                        <div className="popupdes">
                                            <span style={{ fontSize: '16px', fontWeight: '500', color: 'rgb(230, 86, 86)' }}>{formatMoney(String(fixedNumber(valueMin, 0).toFixed()))} <strong style={{ color: 'rgb(94, 88, 98)' }}>VND/BTC</strong></span>
                                            <p style={{ marginBottom: 0 }}>{t("Maximum: ")} {value3} {reattime.name}</p>
                                            <span>{t("price that you pay:")} <strong>{formatMoney(String(fixedNumber(value, 2)))} VND/{reattime.name}</strong></span>
                                        </div>
                                        <div className="popupwallet">
                                            {t("SwapToBe VND Wallet")}
                                            <svg data-name="Layer 1" viewBox="0 0 16 16" fill="#087972" width={14} height={14}><circle cx={8} cy={8} r="7.64" /><path d="M8.63 6.3v4.91h1.56v1.22H5.83v-1.22H7.4V7.53h-1V6.3zM8 3.09a.82.82 0 1 1-.82.82.82.82 0 0 1 .82-.82z" fill="#fff" fillRule="evenodd" /></svg>
                                        </div>
                                        <div className="popupname">
                                            <svg width={8} height={8} viewBox="0 0 8 8" fill="#5E5862"><circle cx={87} cy={143} r={4} transform="translate(-83 -139)" fill="#C5C5C5" fillRule="evenodd" /></svg>
                                            {token?.axiologin?.username}
                                        </div>
                                        <div className="inputsell">
                                            <a href="">{t("Buy")}</a>
                                        </div>
                                    </div>
                                    <div className="popupcreateadss_bottom">
                                        <a href="" onClick={closePreview}>{t("Close")}</a>
                                    </div>

                                </div>

                            </div>



                        </div>
                        <div className="overlayCreate" onClick={closePreview}></div>
                    </> : ''}
                <div id="scrollable-body" style={{ overflow: 'initial' }}>
                    <div className="css-1dbjc4n r-1jj8364 r-lchren r-1qhn6m8 r-i023vh r-13qz1uu r-1g2xzmm" data-testid="alert-container">
                        <div className="css-1dbjc4n r-1g2xzmm" data-testid="flash-access-devices">
                            <div className="css-1dbjc4n r-eqz5dr r-912u2z r-1g2xzmm" style={{}} />
                        </div>
                    </div>
                    <section className="page-heading remi-heading">
                        <div className="css-1dbjc4n r-adacv r-1yzf0co">
                            <div className="container css-1dbjc4n r-1jj8364 r-lchren r-1qhn6m8 r-i023vh r-13qz1uu r-1g2xzmm">
                                <div dir="auto" className="css-901oao r-jwli3a r-320f6p r-adyw6z r-vw2c0b r-oxtfae r-37tt59">
                                    {t("Create new advertisement")}
                                </div>
                            </div>
                        </div>
                    </section>
                    <div className="createads css-1dbjc4n r-11ict49" data-testid="main">
                        <div className="createadss css-1dbjc4n r-1jj8364 r-lchren r-1qhn6m8 r-i023vh r-13qz1uu r-1g2xzmm" data-testid="offer-create-page">
                            <div className="css-1dbjc4n r-18u37iz r-1w6e6rj r-6cv0rn r-1g2xzmm">
                                <div className="createadss_list css-1dbjc4n r-1fq43b1 r-18kxxzh r-1q142lx r-dnmrzs r-ymttw5 r-bnwqim r-1g2xzmm">
                                    <div className="createadss_list-item css-1dbjc4n r-6cv0rn">
                                        <form action="#" >
                                            <div className="css-1dbjc4n r-eqz5dr r-1ytwq0x r-1g2xzmm">
                                                <div className="css-1dbjc4n r-1g2xzmm" style={{ paddingBottom: 6 }}>
                                                    <div className="css-1dbjc4n r-14lw9ot r-z2wwpe r-ymttw5 r-1yzf0co r-1g2xzmm">
                                                        <div className="css-1dbjc4n r-18u37iz r-1wtj0ep r-1g2xzmm">
                                                            <div className="css-1dbjc4n r-1awozwy r-18u37iz">
                                                                <div className="css-1dbjc4n" data-testid="icon-flag-squared" style={{ height: 32, width: 32 }}>
                                                                    <svg id="flag-squared_svg__Layer_1" data-name="Layer 1" viewBox="0 0 24 24" fill="#5E5862" width={32} height={32}>
                                                                        <defs>
                                                                            <style dangerouslySetInnerHTML={{ __html: "\n                                .flag-squared_svg__cls-1 {\n                                  fill: #5e5862;\n                                }\n                              " }} />
                                                                        </defs>
                                                                        <g id="flag-squared_svg__Page-1">
                                                                            <g id="flag-squared_svg__Home-v5-amount-selected-login-Copy">
                                                                                <g id="flag-squared_svg__flag-copy">
                                                                                    <g id="flag-squared_svg__Shape">
                                                                                        <path className="flag-squared_svg__cls-1" d="M16.14 9L19 4.36a.34.34 0 0 0 0-.31.29.29 0 0 0-.25-.15H5.58v-.6a.29.29 0 1 0-.58 0v17.4a.29.29 0 1 0 .58 0v-6.6h13.13A.29.29 0 0 0 19 14a.34.34 0 0 0 0-.31zM5.58 13.5v-9h12.6l-2.64 4.34a.36.36 0 0 0 0 .32l2.64 4.34z" />
                                                                                        <path className="flag-squared_svg__cls-1" d="M5.29 21.5a.8.8 0 0 1-.79-.8V3.3a.79.79 0 1 1 1.58 0v.1h12.63a.78.78 0 0 1 .69.42.79.79 0 0 1 0 .8L16.72 9l2.66 4.38a.79.79 0 0 1 0 .8.78.78 0 0 1-.69.42H6.08v6.1a.79.79 0 0 1-.79.8zm.79-8.5h11.21l-2.17-3.58a.79.79 0 0 1 0-.84L17.29 5H6.08z" />
                                                                                    </g>
                                                                                </g>
                                                                            </g>
                                                                        </g>
                                                                    </svg>
                                                                </div>
                                                                <div className="css-1dbjc4n r-1wbh5a2 r-1777fci r-1jkjb">
                                                                    <div dir="auto" className="css-901oao r-z4f626 r-320f6p r-1x35g6 r-vw2c0b r-oxtfae r-37tt59">
                                                                        {t(`Ad to sell`)} {reattime.name}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="css-1dbjc4n r-z2wwpe r-1udh08x" onClick={() => dispatch(toggleShadow(!flag))}>
                                                                <div role="button" aria-busy="false" data-focusable="true" tabIndex={0} className="css-18t94o4 css-1dbjc4n r-1loqt21 r-1otgn73 r-1i6wzkk r-lrvibr" data-testid="change-offer" style={{ transitionDuration: '0s' }}>
                                                                    <div className="css-1dbjc4n r-1awozwy r-k200y r-z2wwpe r-18u37iz r-1777fci r-1pn2ns4 r-5njf8e" style={{ backgroundColor: 'rgb(235, 236, 240)', borderWidth: 0, minHeight: 36, padding: 8 }}>
                                                                        <div className="css-1dbjc4n" data-testid="icon-edit" style={{ height: 24, width: 24 }}>
                                                                            <svg width={24} height={24} viewBox="0 0 16 17" fill="#5E5862">
                                                                                <path d="M.2 15.4h15.6v1.2H.2v-1.2zM13.648 4.6a1.2 1.2 0 0 0 0-1.697L11.497.752a1.202 1.202 0 0 0-1.697 0l-9 9V13.6h3.848l9-9zm-3-3L12.8 3.751l-1.8 1.8L8.848 3.4l1.8-1.8zM2 12.4v-2.152l6-6L10.152 6.4l-6 6H2z" />
                                                                            </svg>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div dir="auto" className="css-901oao r-z4f626 r-320f6p r-ubezar r-oxtfae r-135wba7" data-testid="market-buy-price">
                                                            {t("Market sell price: ")}
                                                            <span className="css-901oao css-16my406 r-8bscmv r-320f6p r-ubezar r-vw2c0b r-oxtfae r-135wba7"><span><span className="createads_amount">   {
                                                                `${formatMoney(String(fixedNumber(valueSell, 2)))} VND`
                                                            }</span>
                                                                {/* <span className="createads_currency text-dark">VND</span> */}
                                                            </span>
                                                            </span>
                                                        </div>
                                                        <div className="css-1dbjc4n r-qklmqi r-r3u836 r-1phboty r-pw2am6" />
                                                        <div className="css-1dbjc4n" data-testid="coin_price_type">
                                                            <div className="css-1dbjc4n r-18u37iz r-1w6e6rj r-1h0z5md" >
                                                                <div className={`${createSell ? 'active createads_active css-1dbjc4n r-1awozwy r-14lw9ot r-l61ric r-z2wwpe r-1phboty r-rs99b7 r-1814a8r r-1loqt21 r-18u37iz r-tzz3ar r-320f6p r-ubezar r-vw2c0b r-oxtfae r-135wba7 r-1ifxtd0 r-88pszg r-ymttw5 r-1f1sjgu r-bnwqim r-iyfy8q' : ' createads_active  css-1dbjc4n r-1awozwy r-14lw9ot r-l61ric r-z2wwpe r-1phboty r-rs99b7 r-1814a8r r-1loqt21 r-18u37iz r-tzz3ar r-320f6p r-ubezar r-vw2c0b r-oxtfae r-135wba7 r-1ifxtd0 r-88pszg r-ymttw5 r-1f1sjgu r-bnwqim r-iyfy8q'} `} data-testid="fixed-item" onClick={clickSell}>
                                                                    <div dir="auto" className="css-901oao r-homxoj r-320f6p r-7cikom r-1s2g6fe r-t9a87b">
                                                                        {t("Fixed price")}
                                                                    </div>
                                                                    {createSell ? <div className="css-1dbjc4n r-u8s1d r-1beiz5u r-1wrvin1" data-testid="fixed-item-active">
                                                                        <div className="css-1dbjc4n" data-testid="icon-ok-filled-circled" style={{ height: 14, width: 14 }}>
                                                                            <svg width={14} height={14} viewBox="0 0 24 24" fill="#5E5862">
                                                                                <defs>
                                                                                    <circle id="ok-filled-circled_svg__a" cx={11} cy={11} r="10.5" />
                                                                                    <path id="ok-filled-circled_svg__b" d="M9.5 15.125l-3.75-3.75 1.192-1.125L9.5 12.762l5.557-5.512 1.193 1.185z" />
                                                                                </defs>
                                                                                <g transform="translate(1 1)" fill="none" fillRule="evenodd">
                                                                                    <use fill="#087972" xlinkHref="#ok-filled-circled_svg__a" />
                                                                                    <use fill="#FFF" xlinkHref="#ok-filled-circled_svg__b" />
                                                                                </g>
                                                                            </svg>
                                                                        </div>
                                                                    </div> : ''}

                                                                </div>
                                                                {/* <div className={`${createSell2 ? 'active createads_active css-1dbjc4n r-1awozwy r-adb5am r-z2wwpe r-1phboty r-rs99b7 r-1814a8r r-1loqt21 r-18u37iz r-tzz3ar r-320f6p r-ubezar r-vw2c0b r-oxtfae r-135wba7 r-1ifxtd0 r-88pszg r-ymttw5 r-1f1sjgu r-bnwqim r-iyfy8q' : ' createads_active  css-1dbjc4n r-1awozwy r-adb5am r-z2wwpe r-1phboty r-rs99b7 r-1814a8r r-1loqt21 r-18u37iz r-tzz3ar r-320f6p r-ubezar r-vw2c0b r-oxtfae r-135wba7 r-1ifxtd0 r-88pszg r-ymttw5 r-1f1sjgu r-bnwqim r-iyfy8q'} `} data-testid="dynamic-item" onClick={clickSell2}>
                                                                <div dir="auto" className="css-901oao r-homxoj r-320f6p r-7cikom r-1s2g6fe r-t9a87b">
                                                                    {t("Dynamic price")}
                                                                </div>
                                                                {createSell2 ? <div className="css-1dbjc4n r-u8s1d r-1beiz5u r-1wrvin1" data-testid="fixed-item-active">
                                                                    <div className="css-1dbjc4n" data-testid="icon-ok-filled-circled" style={{ height: 14, width: 14 }}>
                                                                        <svg width={14} height={14} viewBox="0 0 24 24" fill="#5E5862">
                                                                            <defs>
                                                                                <circle id="ok-filled-circled_svg__a" cx={11} cy={11} r="10.5" />
                                                                                <path id="ok-filled-circled_svg__b" d="M9.5 15.125l-3.75-3.75 1.192-1.125L9.5 12.762l5.557-5.512 1.193 1.185z" />
                                                                            </defs>
                                                                            <g transform="translate(1 1)" fill="none" fillRule="evenodd">
                                                                                <use fill="#087972" xlinkHref="#ok-filled-circled_svg__a" />
                                                                                <use fill="#FFF" xlinkHref="#ok-filled-circled_svg__b" />
                                                                            </g>
                                                                        </svg>
                                                                    </div>
                                                                </div> : ""}
                                                            </div> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {createSell ? <div className="css-1dbjc4n r-1g2xzmm" style={{ paddingBottom: 6 }}>
                                                    <div className="css-1dbjc4n r-14lw9ot r-z2wwpe r-ymttw5 r-1yzf0co r-1g2xzmm">
                                                        <div className="css-1dbjc4n r-eqz5dr r-1g2xzmm" style={{ marginBottom: '-24px' }}>
                                                            <div className="css-1dbjc4n r-1g2xzmm" style={{ paddingBottom: 24 }}>
                                                                <div dir="auto" className="css-901oao r-z4f626 r-320f6p r-adyw6z r-vw2c0b r-oxtfae r-37tt59">
                                                                    Giá
                                                                </div>
                                                            </div>
                                                            <div className="css-1dbjc4n r-1g2xzmm" style={{ paddingBottom: 24 }}>
                                                                <div className="collapse show" style={{}}>
                                                                    <div className="css-1dbjc4n r-1g2xzmm" data-testid="fixed-coin-price">
                                                                        <div className="css-1dbjc4n r-eqz5dr r-v55p34 r-1g2xzmm" data-testid="text-input text-input-lg">
                                                                            <div className="css-1dbjc4n r-xd6kpl r-1g2xzmm">
                                                                                <div className="css-1dbjc4n r-1g2xzmm">
                                                                                    <div className="css-1dbjc4n r-f1odvy">
                                                                                        <div className="css-1dbjc4n r-1awozwy r-18u37iz r-17grq5a r-1g2xzmm">
                                                                                            <div className="css-1dbjc4n r-1wbh5a2 r-1pyaxff r-1g2xzmm">
                                                                                                <div dir="auto" className="css-901oao r-1814a8r r-320f6p r-1b43r93 r-vw2c0b r-uz4sb0 r-rjixqe">
                                                                                                    {t("Fixed price: ")}
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="css-1dbjc4n r-1g2xzmm" >
                                                                                        <input className="form-control" onChange={inputChange} value={formatMoney(String(value))} />
                                                                                        <div className="createads_input css-1dbjc4n r-1awozwy r-1p0dtai r-6koalj r-18u37iz r-15ighfx r-ywje51 r-u8s1d r-zchlnj r-ipm5af r-1jaylin" data-testid="input-support">
                                                                                            <div className=" css-1dbjc4n r-7er5f2 r-1b7u577" data-testid="currency">
                                                                                                <div dir="auto" className="css-901oao r-1814a8r r-320f6p r-7cikom r-vw2c0b r-1s2g6fe r-t9a87b">
                                                                                                    VND
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    {
                                                                                        error ? <div className="nap_content-error" style={{ marginTop: '3px', fontStyle: "italic" }} >
                                                                                            <a href="" style={{ color: "red " }}> {t("The price cannot be lower")} {`${truncate(((valueSell) - ((valueSell) / 100) * 5), 0)}`} </a>
                                                                                        </div> : ''
                                                                                    }
                                                                                    {
                                                                                        error2 ? <div className="nap_content-error" style={{ marginTop: '3px', fontStyle: "italic" }} >
                                                                                            <a href="" style={{ color: "red " }}>{t("Price cannot be more than")} {` ${truncate(((valueSell) + ((valueSell) / 100) * 5), 0)}`} </a>
                                                                                        </div> : ''
                                                                                    }

                                                                                </div>
                                                                                <div className="createads_height3  css-1dbjc4n r-1g2xzmm">
                                                                                    <div className="css-1dbjc4n r-f1odvy">
                                                                                        <div className="reateads_height2 css-1dbjc4n r-1awozwy r-18u37iz r-17grq5a r-1g2xzmm">
                                                                                            <div className="css-1dbjc4n r-1wbh5a2 r-1pyaxff r-1g2xzmm">
                                                                                                <div dir="auto" className="css-901oao r-1814a8r r-320f6p r-1b43r93 r-vw2c0b r-uz4sb0 r-rjixqe">
                                                                                                    Giá bitUSD:
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="css-1dbjc4n r-1wbh5a2 r-1pyaxff r-1g2xzmm">
                                                                                                <div className="css-1dbjc4n r-1g2xzmm">
                                                                                                    <div data-focusable="true" tabIndex={0} className="css-1dbjc4n r-1loqt21" data-testid="icon-info" style={{ height: 14, width: 14 }}>
                                                                                                        <svg data-name="Layer 1" viewBox="0 0 16 16" fill="#A29DA5" width={14} height={14}>
                                                                                                            <circle cx={8} cy={8} r="7.64" />
                                                                                                            <path d="M8.63 6.3v4.91h1.56v1.22H5.83v-1.22H7.4V7.53h-1V6.3zM8 3.09a.82.82 0 1 1-.82.82.82.82 0 0 1 .82-.82z" fill="#fff" fillRule="evenodd" />
                                                                                                        </svg>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="css-1dbjc4n r-1g2xzmm" data-testid="undefined has-2-support-items">
                                                                                        <div className="css-1dbjc4n r-eqz5dr r-1g2xzmm" data-testid="text-input text-input-md" style={{ marginBottom: '-8px' }}>
                                                                                            <div className="css-1dbjc4n r-1g2xzmm" style={{ paddingBottom: 8 }}>
                                                                                                <div className="css-1dbjc4n r-1g2xzmm">
                                                                                                    <div className="css-1dbjc4n r-1g2xzmm">
                                                                                                        <div className="css-1dbjc4n r-zgxqbw r-l5qhdd r-z2wwpe r-1phboty r-rs99b7 r-13qz1uu">
                                                                                                            <input autoCapitalize="none" autoCorrect="off" autoComplete="on" dir="auto" spellCheck="false" type="text" data-focusable="true" className="css-11aywtz r-zgxqbw r-l5qhdd r-z2wwpe r-1phboty r-rs99b7 r-320f6p r-1b43r93 r-eu3ka r-uz4sb0 r-rjixqe r-1bq9uc5 r-13qz1uu" id="price" data-testid="price" defaultValue="23,621.95" />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="css-1dbjc4n r-1awozwy r-1p0dtai r-6koalj r-18u37iz r-15ighfx r-ywje51 r-u8s1d r-zchlnj r-ipm5af r-1jaylin" data-testid="input-support">
                                                                                            <div className="css-1dbjc4n r-7er5f2 r-1b7u577" data-testid="currency">
                                                                                                <div dir="auto" className="css-901oao r-1814a8r r-320f6p r-7cikom r-vw2c0b r-1s2g6fe r-t9a87b">
                                                                                                    VND/{slug?.toUpperCase()}
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="collapse" style={{}}>
                                                                    <div className="css-1dbjc4n r-1g2xzmm" data-testid="price-input">
                                                                        <div className="css-1dbjc4n r-eqz5dr r-v55p34 r-1g2xzmm" data-testid="text-input text-input-lg">
                                                                            <div className="css-1dbjc4n r-xd6kpl r-1g2xzmm">
                                                                                <div className="css-1dbjc4n r-1g2xzmm">
                                                                                    <div className="css-1dbjc4n r-f1odvy">
                                                                                        <div className="css-1dbjc4n r-1awozwy r-18u37iz r-17grq5a r-1g2xzmm">
                                                                                            <div className="css-1dbjc4n r-1wbh5a2 r-1pyaxff r-1g2xzmm">
                                                                                                <div dir="auto" className="css-901oao r-1814a8r r-320f6p r-1b43r93 r-vw2c0b r-uz4sb0 r-rjixqe">
                                                                                                    Giá {slug?.toUpperCase()}:
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="css-1dbjc4n r-1wbh5a2 r-1pyaxff r-1g2xzmm">
                                                                                                <div className="css-1dbjc4n r-1g2xzmm">
                                                                                                    <div data-focusable="true" tabIndex={0} className="css-1dbjc4n r-1loqt21" data-testid="icon-info" style={{ height: 14, width: 14 }}>
                                                                                                        <svg data-name="Layer 1" viewBox="0 0 16 16" fill="#A29DA5" width={14} height={14}>
                                                                                                            <circle cx={8} cy={8} r="7.64" />
                                                                                                            <path d="M8.63 6.3v4.91h1.56v1.22H5.83v-1.22H7.4V7.53h-1V6.3zM8 3.09a.82.82 0 1 1-.82.82.82.82 0 0 1 .82-.82z" fill="#fff" fillRule="evenodd" />
                                                                                                        </svg>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="css-1dbjc4n r-1g2xzmm" data-testid="undefined has-2-support-items">
                                                                                        <input inputMode="text" name="price" className="form-control" type="text" defaultValue="23,635.89" />
                                                                                        <div className="createads_input css-1dbjc4n r-1awozwy r-1p0dtai r-6koalj r-18u37iz r-15ighfx r-ywje51 r-u8s1d r-zchlnj r-ipm5af r-1jaylin" data-testid="input-support">
                                                                                            <div className=" css-1dbjc4n r-7er5f2 r-1b7u577" data-testid="currency">
                                                                                                <div dir="auto" className="css-901oao r-1814a8r r-320f6p r-7cikom r-vw2c0b r-1s2g6fe r-t9a87b">
                                                                                                    VND/{slug?.toUpperCase()}
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="css-1dbjc4n r-1g2xzmm" style={{ paddingBottom: 24 }}>
                                                                <div className="css-1dbjc4n r-1g2xzmm" data-testid="taker">
                                                                    <div className="css-1dbjc4n r-eqz5dr r-v55p34 r-1g2xzmm" data-testid="text-input text-input-lg">
                                                                        <div className="css-1dbjc4n r-xd6kpl r-1g2xzmm">
                                                                            <div className="css-1dbjc4n r-1g2xzmm">
                                                                                <div className="css-1dbjc4n r-f1odvy">
                                                                                    <div className="css-1dbjc4n r-1awozwy r-18u37iz r-17grq5a r-1g2xzmm">
                                                                                        <div className="css-1dbjc4n r-1wbh5a2 r-1pyaxff r-1g2xzmm">
                                                                                            <div dir="auto" className="css-901oao r-1814a8r r-320f6p r-1b43r93 r-vw2c0b r-uz4sb0 r-rjixqe">
                                                                                                {t(`${reattime.name} `)} {t("price that sellers see:")}
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="css-1dbjc4n r-1wbh5a2 r-1pyaxff r-1g2xzmm">
                                                                                            <div className="css-1dbjc4n r-1g2xzmm">
                                                                                                <div data-focusable="true" tabIndex={0} className="css-1dbjc4n r-1loqt21" data-testid="icon-info" style={{ height: 14, width: 14 }}>
                                                                                                    <svg data-name="Layer 1" viewBox="0 0 16 16" fill="#A29DA5" width={14} height={14}>
                                                                                                        <circle cx={8} cy={8} r="7.64" />
                                                                                                        <path d="M8.63 6.3v4.91h1.56v1.22H5.83v-1.22H7.4V7.53h-1V6.3zM8 3.09a.82.82 0 1 1-.82.82.82.82 0 0 1 .82-.82z" fill="#fff" fillRule="evenodd" />
                                                                                                    </svg>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className=" css-1dbjc4n r-1g2xzmm" data-testid="undefined has-1-support-items">
                                                                                    <div className="createads_border css-1dbjc4n r-zgxqbw r-l5qhdd r-z2wwpe r-1phboty r-rs99b7 r-13qz1uu">

                                                                                        <input autoCapitalize="none" autoCorrect="off" autoComplete="on" dir="auto" readOnly spellCheck="false" type="text" className="css-11aywtz r-zgxqbw r-l5qhdd r-z2wwpe r-1phboty r-rs99b7 r-320f6p r-ubezar r-h3s6tt r-oxtfae r-135wba7 r-q81ovl r-gtt5ci r-13qz1uu" id="price-for-taker" value={(valueMin)} onChange={inputChange} style={{ color: "#000" }} defaultValue={valueMin} />
                                                                                    </div>
                                                                                    <div className="createads_input css-1dbjc4n r-1awozwy r-1p0dtai r-6koalj r-18u37iz r-15ighfx r-ywje51 r-u8s1d r-zchlnj r-ipm5af r-1jaylin" data-testid="input-support">
                                                                                        <div className=" css-1dbjc4n r-7er5f2 r-1b7u577" data-testid="currency">
                                                                                            <div dir="auto" className="css-901oao r-1814a8r r-320f6p r-7cikom r-vw2c0b r-1s2g6fe r-t9a87b">
                                                                                                VND/{slug?.toUpperCase()}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="css-1dbjc4n r-1g2xzmm" style={{ paddingBottom: 24 }}>
                                                                <div className="css-1dbjc4n r-1g2xzmm" data-testid="maker">
                                                                    <div className="css-1dbjc4n r-eqz5dr r-v55p34 r-1g2xzmm" data-testid="text-input text-input-lg">
                                                                        <div className="css-1dbjc4n r-xd6kpl r-1g2xzmm">
                                                                            <div className="css-1dbjc4n r-1g2xzmm">
                                                                                <div className="css-1dbjc4n r-f1odvy">
                                                                                    <div className="css-1dbjc4n r-1awozwy r-18u37iz r-17grq5a r-1g2xzmm">
                                                                                        <div className="css-1dbjc4n r-1wbh5a2 r-1pyaxff r-1g2xzmm">
                                                                                            <div dir="auto" className="css-901oao r-1814a8r r-320f6p r-1b43r93 r-vw2c0b r-uz4sb0 r-rjixqe">
                                                                                                {t(`${reattime.name} `)}{t("price that you pay:")}
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="css-1dbjc4n r-1wbh5a2 r-1pyaxff r-1g2xzmm">
                                                                                            <div className="css-1dbjc4n r-1g2xzmm">
                                                                                                <div data-focusable="true" tabIndex={0} className="css-1dbjc4n r-1loqt21" data-testid="icon-info" style={{ height: 14, width: 14 }}>
                                                                                                    <svg data-name="Layer 1" viewBox="0 0 16 16" fill="#A29DA5" width={14} height={14}>
                                                                                                        <circle cx={8} cy={8} r="7.64" />
                                                                                                        <path d="M8.63 6.3v4.91h1.56v1.22H5.83v-1.22H7.4V7.53h-1V6.3zM8 3.09a.82.82 0 1 1-.82.82.82.82 0 0 1 .82-.82z" fill="#fff" fillRule="evenodd" />
                                                                                                    </svg>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className=" css-1dbjc4n r-1g2xzmm" data-testid="undefined has-1-support-items">
                                                                                    <div className="createads_border css-1dbjc4n r-zgxqbw r-l5qhdd r-z2wwpe r-1phboty r-rs99b7 r-13qz1uu">
                                                                                        <input autoCapitalize="none" autoCorrect="off" autoComplete="on" dir="auto" readOnly spellCheck="false" type="text" data-focusable="true" className="css-11aywtz r-zgxqbw r-l5qhdd r-z2wwpe r-1phboty r-rs99b7 r-320f6p r-ubezar r-h3s6tt r-oxtfae r-135wba7 r-q81ovl r-gtt5ci r-13qz1uu" id="price-for-maker" value={formatMoney(String(value))} style={{ color: "#000" }} />
                                                                                    </div>
                                                                                    <div className="createads_input css-1dbjc4n r-1awozwy r-1p0dtai r-6koalj r-18u37iz r-15ighfx r-ywje51 r-u8s1d r-zchlnj r-ipm5af r-1jaylin" data-testid="input-support">
                                                                                        <div className="css-1dbjc4n r-7er5f2 r-1b7u577" data-testid="currency">
                                                                                            <div dir="auto" className="css-901oao r-1814a8r r-320f6p r-7cikom r-vw2c0b r-1s2g6fe r-t9a87b">
                                                                                                VND/{slug?.toUpperCase()}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> : ''}
                                                {createSell2 ? <div className="css-1dbjc4n r-1g2xzmm" style={{ paddingBottom: 6 }}>
                                                    <div className="css-1dbjc4n r-14lw9ot r-z2wwpe r-1guathk r-1h4fu65 r-1g2xzmm">
                                                        <div className="css-1dbjc4n r-eqz5dr r-1g2xzmm" style={{ marginBottom: '-24px' }}>
                                                            <div className="css-1dbjc4n r-1g2xzmm" style={{ paddingBottom: 24 }}>
                                                                <div dir="auto" className="css-901oao r-z4f626 r-320f6p r-adyw6z r-vw2c0b r-oxtfae r-37tt59">
                                                                    Giá
                                                                </div>
                                                            </div>
                                                            <div className="css-1dbjc4n r-1g2xzmm" style={{ paddingBottom: 24 }}>
                                                                <div className="collapse" style={{}}>
                                                                    <div className="css-1dbjc4n r-1g2xzmm" data-testid="fixed-coin-price">
                                                                        <div className="css-1dbjc4n r-eqz5dr r-1g2xzmm" data-testid="text-input text-input-lg" style={{ marginBottom: '-8px' }}>
                                                                            <div className="css-1dbjc4n r-1g2xzmm" style={{ paddingBottom: 8 }}>
                                                                                {/* <div className="css-1dbjc4n r-1g2xzmm">
                                                                                <div className="css-1dbjc4n r-f1odvy">
                                                                                    <div className="css-1dbjc4n r-1awozwy r-18u37iz r-17grq5a r-1g2xzmm">
                                                                                        <div className="css-1dbjc4n r-1wbh5a2 r-1pyaxff r-1g2xzmm">
                                                                                            <div dir="auto" className="css-901oao r-1814a8r r-320f6p r-1b43r93 r-vw2c0b r-uz4sb0 r-rjixqe">
                                                                                                {t("Fixed price: ")}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="css-1dbjc4n r-1g2xzmm" data-testid="undefined has-1-support-items">
                                                                                    <div className="css-1dbjc4n r-eqz5dr r-1g2xzmm" data-testid="text-input text-input-md" style={{ marginBottom: '-8px' }}>
                                                                                        <div className="css-1dbjc4n r-1g2xzmm" style={{ paddingBottom: 8 }}>
                                                                                            <div className="css-1dbjc4n r-1g2xzmm">
                                                                                                <div className="css-1dbjc4n r-1g2xzmm">
                                                                                                    <div className="css-1dbjc4n r-zgxqbw r-l5qhdd r-z2wwpe r-1phboty r-rs99b7 r-13qz1uu">
                                                                                                        <input autoCapitalize="none" autoCorrect="off" autoComplete="on" dir="auto" spellCheck="false" type="text" data-focusable="true" className="css-11aywtz r-zgxqbw r-l5qhdd r-z2wwpe r-1phboty r-rs99b7 r-320f6p r-1b43r93 r-eu3ka r-uz4sb0 r-rjixqe r-1bq9uc5 r-13qz1uu" id="fixed_coin_price" data-testid="fixed_coin_price" onChange={inputChange} value={value} />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="css-1dbjc4n r-1awozwy r-1p0dtai r-6koalj r-18u37iz r-15ighfx r-ywje51 r-u8s1d r-zchlnj r-ipm5af r-1jaylin" data-testid="input-support">
                                                                                        <div className="css-1dbjc4n r-7er5f2 r-1b7u577" data-testid="currency">
                                                                                            <div dir="auto" className="css-901oao r-1814a8r r-320f6p r-7cikom r-vw2c0b r-1s2g6fe r-t9a87b">
                                                                                                VND
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div> */}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className=" collapse show" style={{}}>
                                                                    <div className="css-1dbjc4n r-1g2xzmm" data-testid="price-input">
                                                                        <div className="css-1dbjc4n r-eqz5dr r-1g2xzmm" data-testid="text-input text-input-lg" style={{ marginBottom: '-8px' }}>
                                                                            <div className="css-1dbjc4n r-1g2xzmm" style={{ paddingBottom: 8 }}>
                                                                                <div className={`${createSell2 ? 'active createads_height' : 'createads_height'}`}>
                                                                                    <div dir="auto" className="css-901oao r-homxoj r-320f6p r-7cikom r-1s2g6fe r-t9a87b">
                                                                                        {t("Fixed price")}
                                                                                    </div>
                                                                                    <div className="  css-1dbjc4n r-1g2xzmm" data-testid="undefined has-1-support-items">
                                                                                        <input inputMode="text" name="fixed_coin_price" className="form-control" type="text" />
                                                                                        <div className="createads_input css-1dbjc4n r-1awozwy r-1p0dtai r-6koalj r-18u37iz r-15ighfx r-ywje51 r-u8s1d r-zchlnj r-ipm5af r-1jaylin" data-testid="input-support">
                                                                                            <div className=" css-1dbjc4n r-7er5f2 r-1b7u577" data-testid="currency">
                                                                                                <div dir="auto" className="css-901oao r-1814a8r r-320f6p r-7cikom r-vw2c0b r-1s2g6fe r-t9a87b">
                                                                                                    VND
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                                <div className="createads_height2   css-1dbjc4n r-1g2xzmm">
                                                                                    <div className="css-1dbjc4n r-f1odvy">
                                                                                        <div className="reateads_height2 css-1dbjc4n r-1awozwy r-18u37iz r-17grq5a r-1g2xzmm">
                                                                                            <div className="css-1dbjc4n r-1wbh5a2 r-1pyaxff r-1g2xzmm">
                                                                                                <div dir="auto" className="css-901oao r-1814a8r r-320f6p r-1b43r93 r-vw2c0b r-uz4sb0 r-rjixqe">
                                                                                                    Giá {reattime.name}:
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="css-1dbjc4n r-1wbh5a2 r-1pyaxff r-1g2xzmm">
                                                                                                <div className="css-1dbjc4n r-1g2xzmm">
                                                                                                    <div data-focusable="true" tabIndex={0} className="css-1dbjc4n r-1loqt21" data-testid="icon-info" style={{ height: 14, width: 14 }}>
                                                                                                        <svg data-name="Layer 1" viewBox="0 0 16 16" fill="#A29DA5" width={14} height={14}>
                                                                                                            <circle cx={8} cy={8} r="7.64" />
                                                                                                            <path d="M8.63 6.3v4.91h1.56v1.22H5.83v-1.22H7.4V7.53h-1V6.3zM8 3.09a.82.82 0 1 1-.82.82.82.82 0 0 1 .82-.82z" fill="#fff" fillRule="evenodd" />
                                                                                                        </svg>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="css-1dbjc4n r-1g2xzmm" data-testid="undefined has-2-support-items">
                                                                                        <div className="css-1dbjc4n r-eqz5dr r-1g2xzmm" data-testid="text-input text-input-md" style={{ marginBottom: '-8px' }}>
                                                                                            <div className="css-1dbjc4n r-1g2xzmm" style={{ paddingBottom: 8 }}>
                                                                                                <div className="css-1dbjc4n r-1g2xzmm">
                                                                                                    <div className="css-1dbjc4n r-1g2xzmm">
                                                                                                        <div className="createads_border  css-1dbjc4n r-zgxqbw r-l5qhdd r-z2wwpe r-1phboty r-rs99b7 r-13qz1uu">
                                                                                                            <input autoCapitalize="none" autoCorrect="off" autoComplete="on" dir="auto" spellCheck="false" type="text" data-focusable="true" className="css-11aywtz r-zgxqbw r-l5qhdd r-z2wwpe r-1phboty r-rs99b7 r-320f6p r-1b43r93 r-eu3ka r-uz4sb0 r-rjixqe r-1bq9uc5 r-13qz1uu" id="price" data-testid="price" defaultValue="23,621.95" />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="createads_input css-1dbjc4n r-1awozwy r-1p0dtai r-6koalj r-18u37iz r-15ighfx r-ywje51 r-u8s1d r-zchlnj r-ipm5af r-1jaylin" data-testid="input-support">
                                                                                            <div className=" css-1dbjc4n r-7er5f2 r-1b7u577" data-testid="currency">
                                                                                                <div dir="auto" className="css-901oao r-1814a8r r-320f6p r-7cikom r-vw2c0b r-1s2g6fe r-t9a87b">
                                                                                                    VND/bitUSD
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="css-1dbjc4n r-1g2xzmm" style={{ paddingBottom: 24 }}>
                                                                <div className="css-1dbjc4n r-1g2xzmm" data-testid="taker">
                                                                    <div className="css-1dbjc4n r-eqz5dr r-1g2xzmm" data-testid="text-input text-input-lg" style={{ marginBottom: '-8px' }}>
                                                                        <div className="css-1dbjc4n r-1g2xzmm" style={{ paddingBottom: 8 }}>
                                                                            <div className="css-1dbjc4n r-1g2xzmm">
                                                                                <div className="css-1dbjc4n r-f1odvy">
                                                                                    <div className="css-1dbjc4n r-1awozwy r-18u37iz r-17grq5a r-1g2xzmm">
                                                                                        <div className="css-1dbjc4n r-1wbh5a2 r-1pyaxff r-1g2xzmm">
                                                                                            <div dir="auto" className="css-901oao r-1814a8r r-320f6p r-1b43r93 r-vw2c0b r-uz4sb0 r-rjixqe">
                                                                                                {t(`Giá ${reattime.name} người bán thấy:`)}
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="css-1dbjc4n r-1wbh5a2 r-1pyaxff r-1g2xzmm">
                                                                                            <div className="css-1dbjc4n r-1g2xzmm">
                                                                                                <div data-focusable="true" tabIndex={0} className="css-1dbjc4n r-1loqt21" data-testid="icon-info" style={{ height: 14, width: 14 }}>
                                                                                                    <svg data-name="Layer 1" viewBox="0 0 16 16" fill="#A29DA5" width={14} height={14}>
                                                                                                        <circle cx={8} cy={8} r="7.64" />
                                                                                                        <path d="M8.63 6.3v4.91h1.56v1.22H5.83v-1.22H7.4V7.53h-1V6.3zM8 3.09a.82.82 0 1 1-.82.82.82.82 0 0 1 .82-.82z" fill="#fff" fillRule="evenodd" />
                                                                                                    </svg>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="css-1dbjc4n r-1g2xzmm" data-testid="undefined has-1-support-items">
                                                                                    <div className="createads_border css-1dbjc4n r-zgxqbw r-l5qhdd r-z2wwpe r-1phboty r-rs99b7 r-13qz1uu">
                                                                                        <input autoCapitalize="none" autoCorrect="off" autoComplete="on" dir="auto" readOnly spellCheck="false" type="text" data-focusable="true" className="css-11aywtz r-zgxqbw r-l5qhdd r-z2wwpe r-1phboty r-rs99b7 r-320f6p r-ubezar r-h3s6tt r-oxtfae r-135wba7 r-q81ovl r-gtt5ci r-13qz1uu" id="price-for-taker" defaultValue="768,624,929" />
                                                                                    </div>
                                                                                    <div className="createads_input css-1dbjc4n r-1awozwy r-1p0dtai r-6koalj r-18u37iz r-15ighfx r-ywje51 r-u8s1d r-zchlnj r-ipm5af r-1jaylin" data-testid="input-support">
                                                                                        <div className="css-1dbjc4n r-7er5f2 r-1b7u577" data-testid="currency">
                                                                                            <div dir="auto" className="css-901oao r-1814a8r r-320f6p r-7cikom r-vw2c0b r-1s2g6fe r-t9a87b">
                                                                                                VND/BTC
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="css-1dbjc4n r-1g2xzmm" style={{ paddingBottom: 24 }}>
                                                                <div className="css-1dbjc4n r-1g2xzmm" data-testid="coin-price">
                                                                    <div className="css-1dbjc4n r-eqz5dr r-1g2xzmm" data-testid="text-input text-input-lg" style={{ marginBottom: '-8px' }}>
                                                                        <div className="css-1dbjc4n r-1g2xzmm" style={{ paddingBottom: 8 }}>
                                                                            <div className="css-1dbjc4n r-1g2xzmm">
                                                                                <div className="css-1dbjc4n r-f1odvy">
                                                                                    <div className="css-1dbjc4n r-1awozwy r-18u37iz r-17grq5a r-1g2xzmm">
                                                                                        <div className="css-1dbjc4n r-1wbh5a2 r-1pyaxff r-1g2xzmm">
                                                                                            <div dir="auto" className="css-901oao r-1814a8r r-320f6p r-1b43r93 r-vw2c0b r-uz4sb0 r-rjixqe">
                                                                                                {t("Maximum BTC price:")}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="css-1dbjc4n r-1g2xzmm" data-testid="undefined has-1-support-items">
                                                                                    <div className="css-1dbjc4n r-eqz5dr r-1g2xzmm" data-testid="text-input text-input-md" style={{ marginBottom: '-8px' }}>
                                                                                        <div className="css-1dbjc4n r-1g2xzmm" style={{ paddingBottom: 8 }}>
                                                                                            <div className="css-1dbjc4n r-1g2xzmm">
                                                                                                <div className=" css-1dbjc4n r-1g2xzmm">
                                                                                                    <div className="createads_border css-1dbjc4n r-zgxqbw r-l5qhdd r-z2wwpe r-1phboty r-rs99b7 r-13qz1uu">
                                                                                                        <input autoCapitalize="none" autoCorrect="off" autoComplete="on" dir="auto" spellCheck="false" type="text" data-focusable="true" className="css-11aywtz r-zgxqbw r-l5qhdd r-z2wwpe r-1phboty r-rs99b7 r-320f6p r-1b43r93 r-eu3ka r-uz4sb0 r-rjixqe r-1bq9uc5 r-13qz1uu" id="max_coin_price" data-testid="max_coin_price" />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="createads_input css-1dbjc4n r-1awozwy r-1p0dtai r-6koalj r-18u37iz r-15ighfx r-ywje51 r-u8s1d r-zchlnj r-ipm5af r-1jaylin" data-testid="input-support">
                                                                                        <div className="css-1dbjc4n r-7er5f2 r-1b7u577" data-testid="currency">
                                                                                            <div dir="auto" className="css-901oao r-1814a8r r-320f6p r-7cikom r-vw2c0b r-1s2g6fe r-t9a87b">
                                                                                                VND
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="css-1dbjc4n r-1g2xzmm" style={{ paddingBottom: 24 }}>
                                                                <div className="css-1dbjc4n r-1g2xzmm" data-testid="maker">
                                                                    <div className="css-1dbjc4n r-eqz5dr r-1g2xzmm" data-testid="text-input text-input-lg" style={{ marginBottom: '-8px' }}>
                                                                        <div className="css-1dbjc4n r-1g2xzmm" style={{ paddingBottom: 8 }}>
                                                                            <div className="css-1dbjc4n r-1g2xzmm">
                                                                                <div className="css-1dbjc4n r-f1odvy">
                                                                                    <div className="css-1dbjc4n r-1awozwy r-18u37iz r-17grq5a r-1g2xzmm">
                                                                                        <div className="css-1dbjc4n r-1wbh5a2 r-1pyaxff r-1g2xzmm">
                                                                                            <div dir="auto" className="css-901oao r-1814a8r r-320f6p r-1b43r93 r-vw2c0b r-uz4sb0 r-rjixqe">
                                                                                                {`${reattime.name} price that you pay:`}
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="css-1dbjc4n r-1wbh5a2 r-1pyaxff r-1g2xzmm">
                                                                                            <div className="css-1dbjc4n r-1g2xzmm">
                                                                                                <div data-focusable="true" tabIndex={0} className="css-1dbjc4n r-1loqt21" data-testid="icon-info" style={{ height: 14, width: 14 }}>
                                                                                                    <svg data-name="Layer 1" viewBox="0 0 16 16" fill="#A29DA5" width={14} height={14}>
                                                                                                        <circle cx={8} cy={8} r="7.64" />
                                                                                                        <path d="M8.63 6.3v4.91h1.56v1.22H5.83v-1.22H7.4V7.53h-1V6.3zM8 3.09a.82.82 0 1 1-.82.82.82.82 0 0 1 .82-.82z" fill="#fff" fillRule="evenodd" />
                                                                                                    </svg>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="css-1dbjc4n r-1g2xzmm" data-testid="undefined has-1-support-items">
                                                                                    <div className="createads_border css-1dbjc4n r-zgxqbw r-l5qhdd r-z2wwpe r-1phboty r-rs99b7 r-13qz1uu">
                                                                                        <input autoCapitalize="none" autoCorrect="off" autoComplete="on" dir="auto" readOnly spellCheck="false" type="text" data-focusable="true" className="css-11aywtz r-zgxqbw r-l5qhdd r-z2wwpe r-1phboty r-rs99b7 r-320f6p r-ubezar r-h3s6tt r-oxtfae r-135wba7 r-q81ovl r-gtt5ci r-13qz1uu" id="price-for-maker" defaultValue="776,388,817" />
                                                                                    </div>
                                                                                    <div className="createads_input css-1dbjc4n r-1awozwy r-1p0dtai r-6koalj r-18u37iz r-15ighfx r-ywje51 r-u8s1d r-zchlnj r-ipm5af r-1jaylin" data-testid="input-support">
                                                                                        <div className="css-1dbjc4n r-7er5f2 r-1b7u577" data-testid="currency">
                                                                                            <div dir="auto" className="css-901oao r-1814a8r r-320f6p r-7cikom r-vw2c0b r-1s2g6fe r-t9a87b">
                                                                                                VND/BTC
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="css-1dbjc4n r-1g2xzmm" style={{ paddingBottom: 24 }}>
                                                                <div className="reference-exchange select-container lg">
                                                                    <div className="css-1dbjc4n r-f1odvy">
                                                                        <div className="css-1dbjc4n r-1awozwy r-18u37iz r-17grq5a r-1g2xzmm">
                                                                            <div className="css-1dbjc4n r-1wbh5a2 r-1pyaxff r-1g2xzmm">
                                                                                <div dir="auto" className="css-901oao r-1814a8r r-320f6p r-1b43r93 r-vw2c0b r-uz4sb0 r-rjixqe">
                                                                                    {t("Reference Exchange:")}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="select dropdown">
                                                                        <button type="button" className="select-toggle select-toggle-button btn btn-lg btn-block">
                                                                            Bitstamp BTC - 32867.25
                                                                            <div className="css-1dbjc4n" data-testid="icon-toggle" style={{ height: 16, width: 16 }}>
                                                                                <svg width={16} height={16} viewBox="0 0 28 32" fill="#5E5862">
                                                                                    <path d="M2.016 8.96l11.84 11.392L25.76 8.96q.704-.832 1.536 0 .832.704 0 1.536L14.624 23.04q-.704.704-1.536 0L.416 10.496q-.832-.832 0-1.536.768-.768 1.6 0z" />
                                                                                </svg>
                                                                            </div>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                    : ""}

                                                <div className="css-1dbjc4n r-1g2xzmm" style={{ paddingBottom: 6 }}>
                                                    <div className="css-1dbjc4n r-14lw9ot r-z2wwpe r-ymttw5 r-1yzf0co r-1g2xzmm">
                                                        <div className="css-1dbjc4n r-eqz5dr r-1g2xzmm" style={{ marginBottom: '-24px' }}>
                                                            <div className="css-1dbjc4n r-1g2xzmm" style={{ paddingBottom: 24 }}>
                                                                <div dir="auto" className="css-901oao r-z4f626 r-320f6p r-adyw6z r-vw2c0b r-oxtfae r-37tt59">
                                                                    {t("Amount")}
                                                                </div>
                                                            </div>
                                                            <div className="css-1dbjc4n r-1g2xzmm" style={{ paddingBottom: 24 }}>
                                                                <div className="css-1dbjc4n r-1g2xzmm" data-testid="total-amount">
                                                                    <div className="css-1dbjc4n r-eqz5dr r-v55p34 r-1g2xzmm" data-testid="text-input text-input-lg">
                                                                        <div className="css-1dbjc4n r-xd6kpl r-1g2xzmm">
                                                                            <div className="css-1dbjc4n r-1g2xzmm">
                                                                                <div className="css-1dbjc4n r-f1odvy">
                                                                                    <div className="css-1dbjc4n r-1awozwy r-18u37iz r-17grq5a r-1g2xzmm">
                                                                                        <div className="css-1dbjc4n r-1wbh5a2 r-1pyaxff r-1g2xzmm">
                                                                                            <div dir="auto" className="css-901oao r-1814a8r r-320f6p r-1b43r93 r-vw2c0b r-uz4sb0 r-rjixqe">
                                                                                                {t(`Amount of ${reattime.name}:`)}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="css-1dbjc4n r-1g2xzmm" data-testid="undefined has-1-support-items">
                                                                                    <input inputMode="text" name="total_amount" className="form-control" type="text" defaultValue={0.5} value={value2} onChange={inputchange2} />
                                                                                    <div className="createads_input css-1dbjc4n r-1awozwy r-1p0dtai r-6koalj r-18u37iz r-15ighfx r-ywje51 r-u8s1d r-zchlnj r-ipm5af r-1jaylin" data-testid="input-support">
                                                                                        <div className=" css-1dbjc4n r-7er5f2 r-1b7u577" data-testid="currency">
                                                                                            <div dir="auto" className="css-901oao r-1814a8r r-320f6p r-7cikom r-vw2c0b r-1s2g6fe r-t9a87b">
                                                                                                {reattime.name}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="css-1dbjc4n r-1g2xzmm" style={{ paddingBottom: 24 }}>
                                                                <div className="css-1dbjc4n r-1g2xzmm" data-testid="max-amount">
                                                                    <div className="css-1dbjc4n r-eqz5dr r-v55p34 r-1g2xzmm" data-testid="text-input text-input-lg">
                                                                        <div className="css-1dbjc4n r-xd6kpl r-1g2xzmm">
                                                                            <div className="css-1dbjc4n r-1g2xzmm">
                                                                                <div className="css-1dbjc4n r-f1odvy">
                                                                                    <div className="css-1dbjc4n r-1awozwy r-18u37iz r-17grq5a r-1g2xzmm">
                                                                                        <div className="css-1dbjc4n r-1wbh5a2 r-1pyaxff r-1g2xzmm">
                                                                                            <div dir="auto" className="css-901oao r-1814a8r r-320f6p r-1b43r93 r-vw2c0b r-uz4sb0 r-rjixqe">
                                                                                                {t(`Maximum ${reattime.name} amount:`)}
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="css-1dbjc4n r-1wbh5a2 r-1pyaxff r-1g2xzmm">
                                                                                            <div className="css-1dbjc4n r-1g2xzmm">
                                                                                                <div data-focusable="true" tabIndex={0} className="css-1dbjc4n r-1loqt21" data-testid="icon-info" style={{ height: 14, width: 14 }}>
                                                                                                    <svg data-name="Layer 1" viewBox="0 0 16 16" fill="#A29DA5" width={14} height={14}>
                                                                                                        <circle cx={8} cy={8} r="7.64" />
                                                                                                        <path d="M8.63 6.3v4.91h1.56v1.22H5.83v-1.22H7.4V7.53h-1V6.3zM8 3.09a.82.82 0 1 1-.82.82.82.82 0 0 1 .82-.82z" fill="#fff" fillRule="evenodd" />
                                                                                                    </svg>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="css-1dbjc4n r-1g2xzmm" data-testid="undefined has-1-support-items">
                                                                                    <input inputMode="text" name="max_amount" className="form-control" type="text" defaultValue={0.5} value={value3} onChange={inputchange3} />
                                                                                    <div className="createads_input css-1dbjc4n r-1awozwy r-1p0dtai r-6koalj r-18u37iz r-15ighfx r-ywje51 r-u8s1d r-zchlnj r-ipm5af r-1jaylin" data-testid="input-support">
                                                                                        <div className="css-1dbjc4n r-7er5f2 r-1b7u577" data-testid="currency">
                                                                                            <div dir="auto" className="css-901oao r-1814a8r r-320f6p r-7cikom r-vw2c0b r-1s2g6fe r-t9a87b">
                                                                                                {
                                                                                                    reattime.name
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="css-1dbjc4n r-1g2xzmm" style={{ paddingBottom: 6 }}>
                                                    <div className="css-1dbjc4n r-14lw9ot r-z2wwpe r-ymttw5 r-1yzf0co r-1g2xzmm" data-testid="payment-details-section">
                                                        <div className="css-1dbjc4n r-eqz5dr r-1g2xzmm" style={{ marginBottom: '-24px' }}>
                                                            <div className="css-1dbjc4n r-1g2xzmm" style={{ paddingBottom: 24 }}>
                                                                <div dir="auto" className="css-901oao r-z4f626 r-320f6p r-adyw6z r-vw2c0b r-oxtfae r-37tt59">
                                                                    {t("Payment details")}
                                                                </div>
                                                            </div>
                                                            <div className="css-1dbjc4n r-1g2xzmm" style={{ paddingBottom: 24 }}>
                                                                <div className="payment-method select-container lg">
                                                                    <div className="css-1dbjc4n r-f1odvy">
                                                                        <div className="css-1dbjc4n r-1awozwy r-18u37iz r-17grq5a r-1g2xzmm">
                                                                            <div className="css-1dbjc4n r-1wbh5a2 r-1pyaxff r-1g2xzmm">
                                                                                <div dir="auto" className="css-901oao r-1814a8r r-320f6p r-1b43r93 r-vw2c0b r-uz4sb0 r-rjixqe">
                                                                                    {t("Payment method:")}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="select dropdown" onClick={clickSelect}>
                                                                        <button type="button" className="select-toggle select-toggle-button  btn-lg btn-block" style={{ color: "#000" }}>
                                                                            {state}
                                                                            <div className="css-1dbjc4n" data-testid="icon-toggle" style={{ height: 16, width: 16 }}>
                                                                                <svg width={16} height={16} viewBox="0 0 28 32" fill="#5E5862">
                                                                                    <path d="M2.016 8.96l11.84 11.392L25.76 8.96q.704-.832 1.536 0 .832.704 0 1.536L14.624 23.04q-.704.704-1.536 0L.416 10.496q-.832-.832 0-1.536.768-.768 1.6 0z" />
                                                                                </svg>
                                                                            </div>
                                                                        </button>

                                                                        {active ? <>
                                                                            <div className="dropdown-menu show w-100">
                                                                                {
                                                                                    transactionform.map((e, i) => {
                                                                                        return (
                                                                                            <a onClick={handleText} id={e.id} name={e.name} className={`${createActive === `${e.name}` ? 'local_bank-item text-16 dropdown-item active' : "local_bank-item text-16 dropdown-item"}`} role="button">{t(`${e.name}`)}</a>
                                                                                        )
                                                                                    }
                                                                                    )
                                                                                }
                                                                                {/* <a onClick={handleText} name="Chuyển khoản ngân hàng" className={`${createActive === 'Chuyển khoản ngân hàng' ? 'local_bank-item text-16 dropdown-item active' : "local_bank-item text-16 dropdown-item"}`} role="button">{t("Bank transfer")}</a>
                                                                            <a onClick={handleText} name="ViettelPay" className={`${createActive === 'ViettelPay' ? 'ViettelPay-item text-16 dropdown-item active' : 'ViettelPay-item text-16 dropdown-item'}`} role="button">ViettelPay</a>
                                                                            <a onClick={handleText} name='MoMo' className={`${createActive === 'MoMo' ? 'MoMo-item text-16 dropdown-item active' : 'MoMo-item text-16 dropdown-item'}`} role="button">MoMo</a>
                                                                            <a onClick={handleText} name="Ví VND Swaptobe" className={`${createActive === 'Ví VND Swaptobe' ? "fiat_wallet-item text-16 dropdown-item active" : "fiat_wallet-item text-16 dropdown-item"} `} role="button">{t("Swaptobe VND Wallet")}</a> */}
                                                                            </div> </> : ''}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="css-1dbjc4n r-1g2xzmm" style={{ paddingBottom: 24 }}>
                                                                <div dir="auto" className="css-901oao r-homxoj r-320f6p r-7cikom r-1s2g6fe r-t9a87b">
                                                                    <div className="css-1dbjc4n r-6koalj r-1g2xzmm" data-testid="offer-details" />
                                                                </div>
                                                            </div>
                                                            <div className="css-1dbjc4n r-1g2xzmm" style={{ paddingBottom: 24 }}>
                                                                <div className="payment-time select-container lg">
                                                                    <div className="css-1dbjc4n r-f1odvy">
                                                                        <div className="css-1dbjc4n r-1awozwy r-18u37iz r-17grq5a r-1g2xzmm">
                                                                            <div className="css-1dbjc4n r-1wbh5a2 r-1pyaxff r-1g2xzmm">
                                                                                <div dir="auto" className="css-901oao r-1814a8r r-320f6p r-1b43r93 r-vw2c0b r-uz4sb0 r-rjixqe">
                                                                                    {t("Payment window:")}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="select dropdown">
                                                                        <button type="button" className="select-toggle select-toggle-button  btn-lg btn-block" style={{ color: '#000' }}>
                                                                            {t("15 minutes")}
                                                                            <div className="css-1dbjc4n" data-testid="icon-toggle" style={{ height: 16, width: 16 }}>
                                                                                <svg width={16} height={16} viewBox="0 0 28 32" fill="#5E5862">
                                                                                    <path d="M2.016 8.96l11.84 11.392L25.76 8.96q.704-.832 1.536 0 .832.704 0 1.536L14.624 23.04q-.704.704-1.536 0L.416 10.496q-.832-.832 0-1.536.768-.768 1.6 0z" />
                                                                                </svg>
                                                                            </div>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="css-1dbjc4n r-1g2xzmm" style={{ paddingBottom: 6 }}>
                                                    <div className="css-1dbjc4n r-14lw9ot r-z2wwpe r-ymttw5 r-1yzf0co r-1g2xzmm">
                                                        <div className="css-1dbjc4n r-eqz5dr r-1g2xzmm" style={{ marginBottom: '-24px' }}>
                                                            <div className="css-1dbjc4n r-1g2xzmm" style={{ paddingBottom: 24 }}>
                                                                <div className="css-1dbjc4n r-1awozwy r-18u37iz r-1g2xzmm">
                                                                    <div className="css-1dbjc4n r-18u37iz r-1w6e6rj r-p1pxzi r-17grq5a r-1g2xzmm">
                                                                        <div className="css-1dbjc4n r-1wbh5a2 r-1mdbw0j r-1pyaxff r-1g2xzmm">
                                                                            <div className="css-1dbjc4n r-1awozwy r-18u37iz">
                                                                                <div className="css-1dbjc4n" data-testid="icon-eye" style={{ height: 24, width: 24 }}>
                                                                                    <svg width={24} height={24} viewBox="0 0 24 18" fill="#5E5862">
                                                                                        <defs>
                                                                                            <path d="M23.205 8.745A12.525 12.525 0 0 0 12 .75C7.004.94 2.6 4.083.795 8.745a.75.75 0 0 0 0 .51A12.525 12.525 0 0 0 12 17.25c4.996-.19 9.4-3.333 11.205-7.995a.75.75 0 0 0 0-.51zM12 15.75c-3.975 0-8.175-2.947-9.697-6.75C3.825 5.197 8.024 2.25 12 2.25c3.975 0 8.175 2.947 9.698 6.75-1.523 3.803-5.723 6.75-9.698 6.75zM12 4.5a4.5 4.5 0 1 0 0 9 4.5 4.5 0 0 0 0-9zm0 7.5a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" id="eye_svg__a" />
                                                                                        </defs>
                                                                                        <use fill="#000" xlinkHref="#eye_svg__a" fillRule="evenodd" />
                                                                                    </svg>
                                                                                </div>
                                                                                <div className="css-1dbjc4n r-1wbh5a2 r-1777fci r-1jkjb">
                                                                                    <div dir="auto" className="css-901oao r-z4f626 r-320f6p r-ubezar r-oxtfae r-135wba7">
                                                                                        <a href="#" onClick={openPreview} style={{ color: '#1890ff' }}>{t("Preview")}</a>
                                                                                        {t(" your ad")}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="css-1dbjc4n r-1wbh5a2 r-1mdbw0j r-1pyaxff r-1g2xzmm" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {
                                                                error3 ? <div>
                                                                    <p style={{ color: 'red' }}>{t("STF cannot be traded at the moment")}</p>
                                                                </div> : ''
                                                            }
                                                            {axiologin && <RecaptchaF2A />}
                                                            {err && <p className=" errorLogin" style={{ marginTop: '0' }}>{t(err)}</p>}

                                                            {loadding ? <div className="example" style={{ margin: '0' }}>
                                                                <Spin />
                                                            </div> :
                                                                <div className="css-1dbjc4n r-1g2xzmm" style={{ paddingBottom: 24 }}>
                                                                    <div className="css-1dbjc4n r-18u37iz r-1w6e6rj r-6cv0rn r-1g2xzmm">
                                                                        <div className="css-1dbjc4n r-1t2qqvi r-18kxxzh r-1q142lx r-r2y082 r-ymttw5 r-bnwqim r-1g2xzmm">
                                                                            <a href="/eth/vn" store="[object Object]">
                                                                                <button role="button" type="button" className=" btn-ghost btn-block btn-lg" style={{ fontSize: "14px", color: "#fff", border: 0, backgroundColor: "rgb(251, 188, 5)" }}>
                                                                                    {t("Cancel")}
                                                                                </button></a>
                                                                        </div>
                                                                        <div className="css-1dbjc4n r-1t2qqvi r-18kxxzh r-1q142lx r-r2y082 r-ymttw5 r-bnwqim r-1g2xzmm">
                                                                            <div className="css-1dbjc4n r-1g2xzmm">
                                                                                <a onClick={submit} role="button" type="submit" className=" CreateAds_button btn-save-offer   btn-block btn-lg" style={{ fontSize: "14px", color: '#ffff', border: 0, backgroundColor: "#087972", textAlign: 'center' }}>
                                                                                    {t("Create new advertisement")}
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <div className="css-1dbjc4n r-3judzb r-z80fyv r-1777fci" data-testid="mobile-debug-bar-container">
                        <div dir="auto" className="css-901oao r-jwli3a r-ubezar r-q4m81j">
                            ssllss | Web | tranngoctien
                        </div>
                    </div> */}
                </div>

            </>}
        </>
    )
}
