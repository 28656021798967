import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import UploadImg from './component/UploadImg'
import { makeStyles } from '@mui/styles';
import { ShowKyc } from 'redux/reducer/kycLevelReducer'
import { useTranslate } from 'core/translate'
import { ErrorMessage } from 'core/Message'
import useLockedBody from 'core/useLockedBody'





const useStyles = makeStyles({
    overlay: {
        position: "fixed",
        top: "0",
        left: "0",
        width: "100%",
        height: "100%",
        zIndex: "998",
        opacity: "0.5",
        background: "black",
    },
    submit:{
        background: "#1d6060",
        width:'200px',
        height:'40px',
        borderRadius:'15px',
        fontWeight:'700'
    },
    center:{
        display:'flex',
        justifyContent:'center',
        alignItems:'center'
    },
    flex:{
        display:'flex',
        alignItems:'center',
        marginBottom:'5px'
    }
   
    

});
export default function KYC2() {
    const dispatch = useDispatch()
    const classes = useStyles();
    const {t} = useTranslate()
    let {showKyc} = useSelector(state => state.kyc)
    let [image, setImage] = useState('') 
    const [valueInput, setValueInput] = useState([])
    const [next, setNext] = useState(false)
    useLockedBody(showKyc)

    const handleChange = (e) => {
        let name = e.target.name
        let value = e.target.value
        setValueInput({...valueInput, [name]:value})
    }
 
        const HandleNext = () => {
            if( valueInput?.ID){
                setNext(true)
            }else{
                ErrorMessage('Please check the fields',t)
            }
        }
    return (
        <>
   <div className="kyc2" style={{ backgroundColor: '#f0f2f5', padding: '20px', minWidth:"300px" }}>
                <div className="kyc2-main" >
                    <div className="title">
                        <span>{t("KYC Level 2")} </span>
                    <img class="logo-img" src="/img/logoswapcolorful.png" style={{height:"30px"}} />

                    </div>
                    <div className="content">
                        {!next && 
                        <>
                          <div className="item">
                            <span>{t("FullName")}:</span>
                            <input type="text"onChange={handleChange} name='fullname' />
                        </div>
                        <div className="item">
                            <span>Id Card/Passport:</span>
                            <input type="text" onChange={handleChange} name='ID'/>
                        </div>
                        </>
                        }
                      {next && <UploadImg image={image} setImage={setImage} setNext={setNext} valueInput={valueInput}/> }
                       {!next &&<div className={classes.center}><button className={classes.submit} onClick={HandleNext}>{t("Next")}</button> </div>}
                    
                    </div>
                </div>
        </div>
        <div className={classes.overlay} onClick={() => dispatch(ShowKyc(!showKyc))}/>
        </>

    )
}
