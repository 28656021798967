import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ShowDeposit } from 'redux/reducer/depositReducer';
import { ShowWithdraw } from 'redux/reducer/withdrawReducer';
import ListCoin from './ListCoin';
import TotalWallet from './TotalWallet';
import { Redirect } from 'react-router-dom'

function Wallet() {
    const user = JSON.parse(localStorage.getItem('user'))

    let { showDeposit } = useSelector(state => state.deposit)
    let { showWithdrawCoin } = useSelector(state => state.withdraw)
    let [depositVND, setDepositVND] = useState(false)
    let [showWithdrawVND, setShowWithdrawVND] = useState(false)
    let { symbol } = useSelector(state => state.user)

    const dispatch = useDispatch()
    const handleBackDeposit = () => {
        dispatch(ShowDeposit(false))
        dispatch(ShowWithdraw(false))
        setShowWithdrawVND(false)
        setDepositVND(false)
    }
    if (!user) return <Redirect to='/login' />
    return (
        <div className='wallet'>
            <div className="container">
                {!showDeposit && !showWithdrawVND && !depositVND && !showWithdrawCoin ? <div className="wallet-title">
                    <h5>Wallet Overview</h5>
                </div> : ''}
                {showDeposit && <div className="wallet-title" onClick={handleBackDeposit}>
                    <img src="/img/arrow-deposit.png" alt="" />
                    <h5>Deposit {symbol}</h5>
                </div>}
                {showWithdrawCoin && <div className="wallet-title" onClick={handleBackDeposit}>
                    <img src="/img/arrow-deposit.png" alt="" />
                    <h5>Withdraw {symbol}</h5>
                </div>}
                {showWithdrawVND && <div className="wallet-title" onClick={handleBackDeposit}>
                    <img src="/img/arrow-deposit.png" alt="" />
                    <h5>Withdraw VND</h5>
                </div>}
                {depositVND && <div className="wallet-title" onClick={handleBackDeposit}>
                    <img src="/img/arrow-deposit.png" alt="" />
                    <h5>Deposit VND</h5>
                </div>}
                <TotalWallet />

                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginBottom: '50px' }}>
                    {/* <ControlledCheckbox /> */}
                </div>
                <ListCoin setDepositVND={setDepositVND} depositVND={depositVND} setShowWithdrawVND={setShowWithdrawVND} showWithdrawVND={showWithdrawVND} />


            </div>
        </div >
    );
}

export default Wallet;