import { Box, Typography } from '@mui/material';
import { Pagination, Spin } from 'antd';
import { formatDateTime } from 'core/hook/formatDateTime';
import { useTranslate } from 'core/translate';
import React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createHistoryDepositCoin } from 'redux/action/userAction';
import { ShowLoadding } from 'redux/reducer/loaddingReducer';


const box = {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '200px',
    overflow: 'hidden',
    "@media (max-width:991px)": {
        maxWidth: '150px',
        display: 'flex',
        flexDirection: 'column',
    },
    "@media (max-width:375px)": {
        maxWidth: '100px',
        display: 'flex',
        flexDirection: 'column',
    }
}
const p = {
    width: '100%',
    overFlow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
}
const status = {
    color: '#4BBD99',
    fontFamily: 'IBM Plex Sans',
    fontWeight: '400'
}
function History() {
    let token = JSON.parse(localStorage.getItem('user'))
    let { symbol, historydepositcoin } = useSelector(state => state.user)
    let { showLoadding } = useSelector(state => state.loadding)
    let { t } = useTranslate()

    const dispatch = useDispatch()
    useEffect(() => {
        if (symbol == "USDT.TRC20") {
            dispatch(createHistoryDepositCoin(token?.axiologin?.token, 1, "USDT"))
        } else if (symbol == "USDT.ERC20") {
            dispatch(createHistoryDepositCoin(token?.axiologin?.token, 1, "USDT"))
        } else {
            dispatch(createHistoryDepositCoin(token?.axiologin?.token, 1, symbol))
        }
    }, [symbol])

    function handlehistorydepositcoin(page) {
        dispatch(ShowLoadding(true))
        if (symbol == "USDT.TRC20") {
            dispatch(createHistoryDepositCoin(token?.axiologin?.token, page, "USDT"))
        } else if (symbol == "USDT.ERC20") {
            dispatch(createHistoryDepositCoin(token?.axiologin?.token, page, "USDT"))
        }
        else {
            dispatch(createHistoryDepositCoin(token?.axiologin?.token, page, symbol))
        }
    }
    const list = historydepositcoin?.data?.data?.array?.map((e, i) => {
        return (
            <div className="dhistory-content" key={i}>
                <Box sx={box}>
                    <Typography variant="p" sx={p}>
                        {e?.hash}
                    </Typography>
                    <Typography variant="p" sx={p}>{formatDateTime(e?.created_at.substring(0, 9), "/")} {formatDateTime(e?.created_at.substring(10, 17), ":")}</Typography>
                </Box>
                <Box sx={box}>
                    <Typography variant="p" sx={p}> + {e?.amount} {e?.coin_key}</Typography>
                    <Typography variant="p" sx={status}>{e?.status == 1 ? "Đã xác minh" : ''}</Typography>
                </Box>

            </div>

        )
    })
    return (
        <>
            {showLoadding ? <div className="example" style={{ width: '100%' }} ><Spin /></div> :
                <>
                    <div className='dhistory-title'>
                        <p>Deposit {symbol} history</p>
                    </div>

                    {historydepositcoin?.data?.data?.total > 0 ? <div className="content" style={{ background: '#fff', padding: '20px' }}>
                        <div className="interest-list">
                            <div style={{ color: "#000", fontSize: '18px', fontStyle: 'italic', textAlign: 'center' }}>
                                {t("No transactions yet")}
                            </div>
                        </div>
                    </div> :
                        <>
                            {list}
                        </>}
                </>
            }

            <Pagination
                style={{ marginTop: '20px' }}
                defaultPageSize={5} //default size of page
                onChange={handlehistorydepositcoin}
                defaultCurrent={1}
                total={historydepositcoin?.data?.data?.total} //total number of card data available
            />
        </>
    );
}

export default History;