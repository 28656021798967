import { makeStyles } from '@mui/styles';
import { Spin } from 'antd';
import axios from 'axios';
import { ErrorMessage, SuccessMessage } from 'core/Message';
import { useTranslate } from 'core/translate';
import { port } from 'port';
import React, { useState }  from 'react'
import { useDispatch } from 'react-redux'
import { getprofile } from 'redux/action/userAction';
import { ShowKyc } from 'redux/reducer/kycLevelReducer';
const useStyles = makeStyles({
  
    submit:{
        background: "#1d6060",
        width:'150px',
        height:'40px',
        borderRadius:'15px',
        fontWeight:'700'
    },
   
   

});
export default function Submit({valueInput,image}) {
    const dispatch = useDispatch()
    const classes = useStyles();
    const [loading, setLoading] = useState(false)
    const token = JSON.parse(localStorage.getItem('user'))
    const {t} = useTranslate()



    async function submit() {
        setLoading(true)
            try {

                const data = new FormData();
                data.append("photo", image?.img1);
                data.append("photo", image?.img2);
                data.append("photo", image?.proof);
                data.append("photo", image?.avatar);
                data.append("fullname", valueInput.fullname);
                data.append("card_id_kyc2", valueInput.ID);
                data.append("bank_number_1_kyc2", valueInput?.number);
                data.append("bank_name_1_kyc2", valueInput?.banking);
                const res = await axios({
                    url: `${port}api/kyc/upload-v2`,
                    method: `POST`,
                    headers: {
                        "Content-Type": "multipart/form-data",
                        'Authorization': 'Bearer ' + token.axiologin.token
                    },
                    data,
                });
                SuccessMessage(res?.data?.message,t)
                dispatch(ShowKyc(false))
                dispatch(getprofile(token.axiologin.token))
            } catch (error) {
                ErrorMessage(error?.response?.data?.message,t)
            }
        setLoading(false)

        
    }
    
    return (
            <>
            {loading ?      <button className={classes.submit} ><Spin /></button> : 
            <button className={classes.submit} onClick={submit}>Send</button>}
            </>
       
    )
}
