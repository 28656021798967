import covertCoin from 'core/CovertCoin';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { gethistory } from '../Components/GetHistory';
import HistoryWallet from './HistoryWallet';
import Withdraw from './Withdraw';

function WithdrawCoin() {
    const { dataWithdrawCoin } = useSelector(state => state.withdraw)
    const dispatch = useDispatch()
    const token = JSON.parse(localStorage.getItem('user'))

    let [Menuflag, setMenuflag] = useState('')
    let [menu, setMenu] = useState(1)
    let coin = covertCoin(dataWithdrawCoin?.name, dataWithdrawCoin?.type)
    useEffect(() => {
        gethistory(token?.axiologin?.token, 1, coin, dataWithdrawCoin?.type, dispatch)
    }, [menu])


    return (
        <>
            <div className='depositvnd'>
                <div className="container">
                    <div className="row depositvnd-row" style={{justifyContent:'space-between'}}>
                        <div className={`${!Menuflag == '' ? "col-lg-6 col-md-12 depositvnd-main withdrawcoin-main" : 'col-lg-6 col-md-12 depositvnd-main'}`} style={{maxWidth:'450px'}}>
                            <Withdraw setMenuflag={setMenuflag} Menuflag={Menuflag} setMenu={setMenu} menu={menu} />
                        </div>
                        <div className="col-lg-6 col-md-12 dvndhistory" style={{maxWidth:'450px'}}>
                            <HistoryWallet setMenu={setMenu} menu={menu} />
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
}

export default WithdrawCoin;