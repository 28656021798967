import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useHistory } from "react-router-dom";
import ShowMoreText from "react-show-more-text";

import { useTranslate } from "../core/translate";

import { axios, axiosLogin, createAction } from "../redux/action/authAction";
import { createGetnotification, createNap, wallet } from "../redux/action/userAction";
import { DEPOSIT, LOGIN } from "../redux/type";
import useLockedBody from '../core/useLockedBody'
import { port } from "../port";
import { Spin } from "antd";
import { formatMoney } from "../core/hook/formarMoney";
import { fixedMoney } from "../core/hook/fixedMoney";

import { getprofile } from "redux/action/userAction";
import socket from "socket";
import { createCoin } from "redux/action/coinAction";
import { PRICECOIN } from "redux/type";
import UpdateCountry from "./UpdateCountry";
import { HandleGetNotify } from "redux/reducer/notifyReducer";



export default function Header() {
    
    const { getwallet, getnotification, profile } = useSelector(state => state.user)
    const {getNotify} = useSelector(state =>state.notify)
    const { axiologin, google } = useSelector(state => state.auth)
    // let { pricecoin } = useSelector(state => state.coin)

    let [loadding, setLoadding] = useState(false)
    let activeimg = localStorage.getItem('img')
    let state = useSelector(state => state.auth.show)
    let slug = localStorage.getItem('slug')
    if (slug == undefined) {
        slug = 'btc'
    }
    // let SWBUSDT = pricecoin.filter((e) => {
    //     if (e.name == 'USDT') {
    //         return e
    //     }
    // })


    let token = JSON.parse(localStorage.getItem('user'))

    let [page, setPage] = useState(1)
    let [limit, setLimit] = useState(5)

    useEffect(() => {
        dispatch(HandleGetNotify())
    },[])

 
    
 
    useEffect(() => {
        socket.on("listCoin", (res) => {
            dispatch(createCoin(PRICECOIN, res))
        })
        return () => {
            socket.off('listCoin')
        }
    }, [])
    useEffect(() => {
        window.scrollTo(0, 0)
        if (axiologin) {
            dispatch(wallet(token?.axiologin?.token))
            dispatch(getprofile(token.axiologin.token))
        }
    }, [])

    useEffect(() => {
        if (limit <= getnotification?.total) {

            dispatch(createGetnotification(token?.axiologin?.token, limit, page, setLoadding))
        }

    }, [limit])

    function handleScroll(e) {
        const { scrollTop, clientHeight, scrollHeight } = e.currentTarget
        if (scrollHeight - scrollTop === clientHeight) {
            setLimit(limit = limit + 1)
        }
    }
    let [show, setShow] = useState('')
    function onClick() {
        setShow(show === "" ? "show" : "");
    }
    function removeShow() {
        setShow(state)
        setActive('')
        setDashBoard('')
    }

    let [active, setActive] = useState(false)
    function selectProfile() {
        setDashBoard(false)
        setActive(!active)
    }

    let [dashboard, setDashBoard] = useState(false)
    const dashboardLog = () => {
        setDashBoard(!dashboard)
        setActive(false)
    }
    let [nav, setNav] = useState('')

    let [locker, setLocker] = useState(false)
    useLockedBody(locker)
    function handleNav() {
        setLocker(!locker)
        setNav(nav === '' ? 'active' : '')
    }
    function CloseHandleNav() {
        setLocker(false)
        setNav('')
    }
    //Ngôn Ngữ
    let { t, setTranslate, lang } = useTranslate()
    function ChangeLang(lang) {
        setTranslate(lang)
        setShow(state)
        setNav('')
        setLocker(false)
    }
    const dispatch = useDispatch()
    let history = useHistory()
    function logout() {
        let testTop = document.querySelector("header .remi-header-main .navbar-nav").scrollTop = 0
        CloseHandleNav()
        dispatch(createAction(LOGIN, false))
        dispatch(axiosLogin())
        dispatch(axios())
        dispatch(createNap(DEPOSIT, false))
        localStorage.removeItem("user");
        localStorage.removeItem("vndusd");
        localStorage.removeItem("TransactionForm");
        localStorage.removeItem("exchange");
        localStorage.removeItem("wallet");
        localStorage.removeItem("useradlist");
        localStorage.removeItem("img")
        history.push('/p2p')
    }

    let [notify, setNotify] = useState(false)
    useLockedBody(notify)
    function openNotify() {
      
        setNotify(!notify)
        setNotify(true)
    }


    let [notification, setNotification] = useState(false)
    let [ActiveimgNotifi, setActiveImgNotifi] = useState(false)
    function handleNotification() {
        setNotification(!notification)
        dispatch(createGetnotification(token?.axiologin?.token, limit, page, setLoadding))
        setActiveImgNotifi(true)
        localStorage.setItem('img', ActiveimgNotifi)
    }
    function closeNotify() {
        setNotify(false)
    }

    let [megaMenu, setMegaMenu] = useState(false)
    let handleOnMouseEnter = () => {
        setMegaMenu(true)
    }
    let handleOnMouseLeave = () => {
        setMegaMenu(false)
    }

    let [activeMenuMegaMoblie, setActiveMenuMegaMoblie] = useState(false)
    function handleMenuMegaMoblie() {
        setActiveMenuMegaMoblie(!activeMenuMegaMoblie)
    }
    const MenuMegaMoblie = () => {
        return (
            <div className="MenuMegaMoblie">
                <ul >
                    <li >
                        <div><img src="/img/logoswap.png" alt="" /></div>
                        <div className="MenuMega_content_item">

                            <div>
                                <a href="https://metaversetobe.com" target="_blank">
                                    METAVERSETOBE.COM
                                </a>
                            </div>

                        </div>
                    </li>
                    <li >
                        <div><img src="/img/logoswap.png" alt="" /></div>
                        <div className="MenuMega_content_item">
                            <div>
                                <a href="https://tobecoin.net" target="_blank">
                                    tobecoin.net
                                </a>
                            </div>

                        </div>
                    </li>
                    <li >
                        <div><img src="/img/logoswap.png" alt="" /></div>
                        <div className="MenuMega_content_item">

                            <div>
                                <a href="https://tobescan.com" target="_blank">
                                    tobescan.com
                                </a>
                            </div>

                        </div>
                    </li>
                    <li >
                        <div><img src="/img/logoswap.png" alt="" /></div>
                        <div className="MenuMega_content_item">
                            <div>
                                <a href="https://exchangetobe.com" target="_blank">
                                    exchangetobe.com
                                </a>
                            </div>

                        </div>
                    </li>


                </ul>
                <ul>
                    <li >
                        <div><img src="/img/Blochain Farm.png" alt="" /></div>
                        <div className="MenuMega_content_item">

                            <div>
                                <a href="https://farmtobe.com " target="_blank">
                                    farmtobe.com
                                </a>
                            </div>

                        </div>
                    </li>
                    <li >
                        <div><img src="/img/logo Z.png" alt="" /></div>
                        <div className="MenuMega_content_item">
                            <div>
                                <a href="https://tobe.games" target="_blank">
                                    tobe.games
                                </a>
                            </div>

                        </div>
                    </li>
                    <li >
                        <div><img src="/img/logoswap.png" alt="" /></div>
                        <div className="MenuMega_content_item">

                            <div>
                                <a href="https://nfttobe.net" target="_blank">
                                    nfttobe.net
                                </a>
                            </div>

                        </div>
                    </li>
                    <li >
                        <div><img src="/img/logoswap.png" alt="" /></div>
                        <div className="MenuMega_content_item">
                            <div>
                                <a href="https://realtobe.com" target="_blank">
                                    realtobe.com
                                </a>
                            </div>

                        </div>
                    </li>


                </ul>
                <ul>
                    <li >
                        <div><img src="/img/logoswap.png" alt="" /></div>
                        <div className="MenuMega_content_item">
                            <div>
                                <a href="https://marketcaptobe.com" target="_blank">
                                    marketcaptobe.com
                                </a>
                            </div>

                        </div>
                    </li>
                    <li >
                        <div><img src="/img/vina.ico" alt="" /></div>
                        <div className="MenuMega_content_item">
                            <div>
                                <a href="https://wallettobe.com" target="_blank">
                                    wallettobe.com
                                </a>
                            </div>

                        </div>
                    </li>
                    <li >
                        <div><img src="/img/logoswap.png" alt="" /></div>
                        <div className="MenuMega_content_item">
                            <div>
                                <a href="https://tobe.shopping" target="_blank">
                                    tobe.shopping
                                </a>
                            </div>

                        </div>
                    </li>
                    <li >
                        <div><img src="/img/logoswap.png" alt="" /></div>
                        <div className="MenuMega_content_item">
                            <div>
                                <a href="https://tobe.chat" target="_blank">
                                    tobe.chat
                                </a>
                            </div>

                        </div>
                    </li>
                </ul>
            </div>
        )
    }
    const MenuMega = () => {
        return (
            <div className="MenuMega">

                <div className="MenuMega_title" onMouseLeave={handleOnMouseLeave} onMouseEnter={handleOnMouseEnter}>
                    {/* <i className="nav-icon fas fa-th" /> */}
                    <img src="/img/nav.png" alt="" />
                </div>
                {megaMenu && <div className="MenuMega_content" onMouseLeave={handleOnMouseLeave} onMouseEnter={handleOnMouseEnter}>
                    <div>
                        <ul>
                            <li >
                                <div style={{ width: '90px' }}><img loading="lazy" src="/img/extobe.png" alt="" /></div>
                                <div className="MenuMega_content_item">
                                    <div>
                                        <a href="https://extobe.com" target="_blank">
                                            EX TOBE
                                        </a>
                                    </div>
                                    <span>
                                        Crypto exchange trade tobe
                                    </span>
                                </div>

                            </li>
                            <li >
                                <div style={{ width: '90px' }}><img loading="lazy" src="/img/swaptobe1.png" alt="" /></div>
                                <div className="MenuMega_content_item">
                                    <div>
                                        <a href="https://swaptobe.com" target="_blank">
                                            SWAP TOBE
                                        </a>
                                    </div>
                                    <span>
                                        Buy/Sell Bitcoin Ether and Altcoins

                                    </span>
                                </div>
                            </li>
                            <li >
                                <div style={{ width: '90px' }}><img loading="lazy" src="/img/tobemeta.png" alt="" /></div>
                                <div className="MenuMega_content_item">

                                    <div>
                                        <a href="https://marketcaptobe.com/" target="_blank">
                                            TOBE META
                                        </a>
                                    </div>
                                    <span>
                                        Blockchain Metaverse Tobe
                                    </span>
                                </div>
                            </li>
                            <li >
                                <div style={{ width: '90px' }}><img loading="lazy" src="/img/tobesonet.png" alt="" /></div>
                                <div className="MenuMega_content_item">
                                    <div>
                                        <a href="https://tobesonet.com/" target="_blank">
                                            TOBE SONET
                                        </a>
                                    </div>
                                    <span>
                                        Blockchain Social NetWork ToBe
                                    </span>
                                </div>
                            </li>
                        </ul>
                        <ul>
                            <li >
                                <div style={{ width: '90px' }}><img loading="lazy" src="/img/tobereal.png" alt="" /></div>
                                <div className="MenuMega_content_item">
                                    <div>
                                        <a href="http://realtobe.com/" target="_blank">
                                            TOBE REAL
                                        </a>
                                    </div>
                                    <span>
                                        Real estate blockchain tobe
                                    </span>
                                </div>
                            </li>
                            <li >
                                <div style={{ width: '90px' }}><img loading="lazy" src="/img/tobemarket.png" alt="" /></div>
                                <div className="MenuMega_content_item">
                                    <div>
                                        <a href="https://marketcaptobe.com/" target="_blank">
                                            TOBE MARKET
                                        </a>
                                    </div>
                                    <span>
                                        Crypto price chart tobe
                                    </span>
                                </div>
                            </li>
                            <li >
                                <div style={{ width: '90px' }}><img loading="lazy" src="/img/tobecare.png" alt="" /></div>
                                <div className="MenuMega_content_item">

                                    <div>
                                        <a href="http://wallettobe.com/" target="_blank">
                                            TOBE CARE
                                        </a>
                                    </div>
                                    <span>
                                        Blockchain wallet tobe
                                    </span>
                                </div>
                            </li>
                            <li >
                                <div style={{ width: '90px' }}><img loading="lazy" src="/img/tobegame.png" alt="" /></div>
                                <div className="MenuMega_content_item">
                                    <div>
                                        <a href="http://tobe.games/" target="_blank">
                                            TOBE GAME
                                        </a>
                                    </div>
                                    <span>
                                        Blockchain game tobe
                                    </span>
                                </div>
                            </li>


                        </ul>
                        <ul>
                            <li >
                                <div style={{ width: '90px' }}><img loading="lazy" src="/img/tobeecom.png" alt="" /></div>
                                <div className="MenuMega_content_item">
                                    <div>
                                        <a href="http://tobe.shopping/" target="_blank">
                                            TOBE ECOM
                                        </a>
                                    </div>
                                    <span>
                                        Blockchain E-commerce ToBe

                                    </span>
                                </div>
                            </li>
                            <li >
                                <div style={{ width: '90px' }}><img loading="lazy" src="/img/tobenft.png" alt="" /></div>
                                <div className="MenuMega_content_item">
                                    <div>
                                        <a href="https://nfttobe.net/" target="_blank">
                                            TOBE NFT
                                        </a>
                                    </div>
                                    <span>
                                        Nft blockchain tobe

                                    </span>
                                </div>
                            </li>
                            <li >
                                <div style={{ width: '90px' }}><img loading="lazy" src="/img/ToBeFarm.png" alt="" /></div>
                                <div className="MenuMega_content_item">
                                    <div>
                                        <a href="https://farmtobe.com/" target="_blank">
                                            TOBE FARM
                                        </a>
                                    </div>
                                    <span>
                                        Blockchain farm tobe

                                    </span>
                                </div>
                            </li>
                            <li >
                                <div style={{ width: '90px' }}><img loading="lazy" src="/img/Tobe Coin.png" alt="" /></div>
                                <div className="MenuMega_content_item">
                                    <div>
                                        <a href="https://tobecoin.net/" target="_blank">
                                            TOBE COIN
                                        </a>
                                    </div>
                                    <span>
                                        Tobe Coin (TBC) is a shared cryptocurrency for the entire Tobe Chain ecosystem.
                                    </span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>}

            </div>
        )
    }

    // let [Finance, setFinance] = useState(false)
    // let handleOnMouseEnterFinance = () => {
    //     setFinance(true)
    // }
    // let handleOnMouseLeaveFinance = () => {
    //     setFinance(false)
    // }

    const MenuItem = () => {
        return (
            <div className="Menu_item" style={{ margin: '0 20px' }}>
                {/* <div className="finance" onMouseLeave={handleOnMouseLeaveFinance} onMouseEnter={handleOnMouseEnterFinance}>
                    <div className="bordernav css-2db" style={{ paddingBottom: '0px' }} >
                        Finance
                        <div><i class="fas fa-sort-down"></i></div>

                    </div>
                    {Finance && <div className="finance_content">
                        <ul>
                            <li>
                                <NavLink exact to={`/Lending`} className=" css-2db" style={{ paddingBottom: '0px' }}>
                                    ToBe Credit
                                </NavLink>
                            </li>
                            <li><NavLink exact to={`/Staking`} className=" css-2db" style={{ paddingBottom: '0px' }}>
                                Earn Tobe
                            </NavLink></li>
                        </ul>
                    </div>}

                </div> */}


                <NavLink exact to={`/p2p/${slug?.toLocaleLowerCase()}`} className="bordernav css-2db" style={{ paddingBottom: '0px' }}>
                    {t("Exchange")}
                </NavLink>


                <NavLink to='/Swap' className="bordernav css-2db" style={{ paddingBottom: '0px' }}>
                    {t("Swap")}
                </NavLink>



                {axiologin ? <NavLink exact to='/parent' className="bordernav css-2db" style={{ paddingBottom: '0px' }}>
                    {t("Referral")}
                </NavLink> : ''}
                <a href='https://news.swaptobe.com/' target="_blank" className="bordernav css-2db" style={{ paddingBottom: '0px' }}>
                    {t("News")}
                </a>
               
          
                <NavLink to='/mining' className="bordernav css-2db" style={{ paddingBottom: '0px' }}>
                {t("Mining")}
                </NavLink>
                <NavLink to='/reserve-tobecoin' className="bordernav css-2db" style={{ paddingBottom: '0px' }}>
              {t("Reserve TBC")}
                </NavLink>
                <NavLink to='/invitation' className="bordernav css-2db" style={{ paddingBottom: '0px' }}>
                </NavLink>
               
            </div>
        )
    }

    return (
        <>
            <header className="remi-header-container coin" style={show ? { zIndex: 1080 } : { zIndex: 500 }} >

                <div className={`overLang ${show} ${active} ${dashboard}`} onClick={removeShow}></div>
                <div className="remi-header-balance active" onClick={CloseHandleNav} style={!axiologin ? { padding: '0' } : { padding: '6px' }}>
                    <div className="container " >
                        {axiologin &&
                            <div className="headernew">
                                <div className="row headernew-main">
                                    <div className="col-lg-6 col-md-12 headernew-main-item" style={{ textAlign: 'center', justifyContent:'start' }}>
                                        <p style={{ color: '#d8d9de', margin: "0" }}>VND balance:   <span style={{ fontWeight: '700', fontFamily: 'Arial', color: '#fff' }}>{formatMoney(String(fixedMoney(getwallet?.data?.data.vnd_balance)))}  </span></p>
                                        
                                    </div>
                                    <div className=" col-md-6 headernew-main-item headernew-none" style={{justifyContent:'flex-end', gap:'20px'}}>
                                        {/* <a href="https://swbcoin.net/" target="_blank" style={{ color: '#FAA51A', fontWeight: 'bold' }}> Pre-Sale ICO SWB</a> */}
                                        <div onClick={openNotify}>
                                            <img loading="lazy" src="/img/loa.png" alt="" style={{ width: '25px', marginBottom: '5px' }} />
                                        </div>

                                        <div onClick={handleNotification}>
                                            {!ActiveimgNotifi ? <img src="/img/baochuong !.png" alt="" style={{ width: '24px', marginBottom: '5px' }} /> :
                                                <i className="far fa-bell" style={{ fontSize: '18px', marginTop: '5px' }}></i>
                                            }

                                            {/* <i className="far fa-bell"></i> */}
                                        </div>
                                        <div className="notification">
                                            {notification &&
                                                <>
                                                    <div onClick={() => setNotification(!notification)} className="overlayNotification"></div>
                                                    <div className="notification-container" onScroll={handleScroll} style={{ top: '30px', right: '-65px' }}>
                                                        <div className="title">
                                                            <span>{t("Notify")} </span>

                                                        </div>
                                                        <div className="view-all">
                                                            <div />

                                                            <NavLink to="/Notification" onClick={() => setNotification(!notification)}>{t("See all")}</NavLink>
                                                        </div>

                                                        <div className="contentNotification">
                                                            {getnotification?.total == 0 ? <div style={{ textAlign: 'center', padding: '20px' }}>{t("There are no announcements yet")}</div> :
                                                                getnotification?.array?.map((e, i) => {
                                                                    let day = new Date()
                                                                    let lastedNotifi = (e?.created_at * 1000)
                                                                    let dateNow = day.getTime() - lastedNotifi

                                                                    let ngay = Math.floor(dateNow / 1000 / 60 / 60 / 24)
                                                                    let gio = Math.floor(dateNow / 1000 / 60 / 60 - ngay * 24)
                                                                    let phut = Math.floor(dateNow / 1000 / 60 - ngay * 24 * 60 - gio * 60)
                                                                    let giay = Math.floor(dateNow / 1000 - ngay * 24 * 60 * 60 - gio * 60 * 60 - phut * 60)
                                                                    let validateGiay = 1000 * 60
                                                                    let validatePhut = validateGiay * 60
                                                                    let validateGio = validatePhut * 24
                                                                    let validateNgay = validateGio * 30
                                                                    let str;
                                                                    if (dateNow < validateGiay) {
                                                                        str = `${giay} ${t("seconds prior to")}`
                                                                    } else if (dateNow < validatePhut) {
                                                                        str = `${phut} ${t("minutes prior to")}`
                                                                    } else if (dateNow < validateGio) {
                                                                        str = `${gio} ${t("hours ago")}`
                                                                    } else if (dateNow < validateNgay) {
                                                                        str = `${ngay} ${t("yesterday")}`
                                                                    }
                                                                    return (
                                                                        <>

                                                                            <div key={i} className="notification-item" style={{ marginBottom: '10px' }}>
                                                                                <div className="avatar"><img src={`/logoS.png`} alt="" style={{ maxWidth: '35px', maxHeight:'35px' }} /></div>
                                                                                <div className="noti">
                                                                                    <div>[{e?.title}]</div>
                                                                                    <div>{e?.detail}</div>
                                                                                    <p>{str}</p>
                                                                                </div>
                                                                            </div>


                                                                        </>


                                                                    )
                                                                })}
                                                            {getnotification?.total == 0 ? '' : loadding ? <div className="example">
                                                                <Spin />
                                                            </div> : ""}

                                                        </div>
                                                    </div>


                                                </>
                                            }
                                        </div>




                                        <div onClick={dashboardLog}>
                                            {t("Dashboard")}
                                            <img src="/img/arrow-header.png" alt="" style={{ marginLeft: '5px' }} />
                                            {dashboard && <div className="dashboardnew">
                                                <NavLink to="/trades">
                                               {t("History p2p")}
                                                </NavLink>
                                                <NavLink to="/wallet">
                                                    {t("Wallets")}
                                                </NavLink>
                                            </div>}

                                        </div>
                                        <div onClick={selectProfile}>
                                            {axiologin ? `${axiologin?.username}` : ''}
                                            <img src="/img/arrow-header.png" alt="" style={{ marginLeft: '5px' }} />
                                            {active && <div className="dashboardnew" style={{ width: '70px', right: '10px' }}>
                                                <NavLink to={`/profile/${slug}/${axiologin?.username}`} >
                                                {t("Profile")}
                                                </NavLink>
                                                <NavLink to="/kyc-basic" >
                                                    KYC
                                                </NavLink>
                                                <NavLink to="/setting">
                                                    {t("Setting")}
                                                </NavLink>
                                                <NavLink to="#" onClick={logout} >
                                                    {t("Log out")}
                                                </NavLink>
                                            </div>}
                                        </div>
                                    </div>
                                </div>
                            </div>}

                        {profile?.data?.data &&
                            <UpdateCountry />}



                        {notify ? 
                        <div className="Notify" style={{ color: "#000" }}>
                            <div className="Notify_top">
                                <span>{getNotify == null ? 'Notification': getNotify?.title}</span>
                                <div onClick={closeNotify}>
                                    <svg width={24} height={24} viewBox="0 0 15 32" fill="#767478"><path d="M14.464 20.992q.576.576.576 1.376t-.576 1.376q-.576.512-1.376.512t-1.376-.512L7.488 18.88l-4.224 4.864q-.576.512-1.376.512t-1.376-.512Q0 23.168 0 22.368t.512-1.376L4.928 16 .512 10.944Q0 10.368 0 9.568t.512-1.376q.576-.512 1.376-.512t1.376.512l4.224 4.864 4.224-4.864q.576-.512 1.376-.512t1.376.512q.576.576.576 1.376t-.576 1.376L10.048 16z" /></svg>
                                </div>
                            </div>
                            <div className="Notify_content">
                                <div className="Notify_content-item" style={{ overflow: "hidden",wordBreak: "break-word"}}>
                                {getNotify == null         ?<div style={{textAlign:'center'}}>There are currently no announcements</div>: 
                                    <ShowMoreText lines={10}
                                        more="Show more"
                                        less="Show less"
                                        className="content-css showmore"
                                        anchorClass="my-anchor-css-class"
                                        expanded={false}
                                        width='1000'
                                        truncatedEndingComponent={"... "}
                                    // onClick={handleShowMoreText}
                                    >
                                                     <div  dangerouslySetInnerHTML={{ __html: getNotify?.content }} />
                                    </ShowMoreText>}


                                </div>
                            </div>
                        </div> : ''}
                        {notify ? <div className="overlayNotify" onClick={closeNotify}></div> : ''}

                    </div>
                </div>
                <div className="remi-header-top">
                    <div className="css-1dbjc4n ">
                        <div className="container css-1d">
                            <div className="remi-header-logo" style={{ display: 'flex', alignItems: 'center' }}>
                                <a href="/" store="[object Object]">
                                    <div className="remi-logo-container">
                                        <div className=" remi-logo-container">
                                            <img className="logo-img" src="/img/logoswapcolorful.png" style={{ height: '2rem' }} />
                                        </div>
                                    </div>
                                </a>

                            </div>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                {MenuMega()}
                                {MenuItem()}
                                {!axiologin &&
                                    <div className="remi-header-top-menu">
                                        <div className="css-2d">
                                            <div className="language-country-select">
                                                <div className="css-3" style={{ paddingBottom: '0px' }}>
                                                    <div className="select-container ">
                                                        <div id="languages-countries" className="select dropdown">
                                                            <div onClick={onClick} className="select-toggle">
                                                                <div className="css-3d">
                                                                    <div className="css-3db">
                                                                        <div className="css-3dbc">
                                                                            <span className="pre-text">
                                                                                {lang === "vi"
                                                                                    ? 'VI'
                                                                                    : "EN"}
                                                                            </span>

                                                                        </div>
                                                                    </div>
                                                                    <div className="css-3db" data-testid="icon-down-dir" style={{ display: 'flex' }}>
                                                                        <svg width={14} height={14} viewBox="0 0 18 32" fill="#FFFFFF">
                                                                            <path d="M18.272 12.576q0 .448-.32.8l-8 8q-.352.352-.8.352t-.8-.352l-8-8Q0 13.024 0 12.576t.352-.8.8-.352h16q.448 0 .8.352t.32.8z" />
                                                                        </svg>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className={`langmenu dropdown-menu  dropdown-menu-right select-multi-col ${show}`}>
                                                                <div className="select-column">
                                                                    <div className="select-column-title md">{t("Language")}</div>
                                                                    <div className="select-column-menu">
                                                                        <a href="#" data-value='vi' className={`${lang === "vi" ? "vi-item text-16 dropdown-item active" : "vi-item text-16 dropdown-item"}`} onClick={() => ChangeLang('vi')}>Tiếng Việt</a>
                                                                        <a href="#" data-value='en' className={`${lang === 'en' ? "en-item text-16 dropdown-item active" : "en-item text-16 dropdown-item"}`} onClick={() => ChangeLang('en')}>English</a>
                                                                    </div>
                                                                </div>
                                                                <div className="select-column">
                                                                    <div className="select-column-title md">{t("Market")}</div>
                                                                    <div className="select-column-menu">
                                                                        <a className={`${lang === "vi" ? "vn-item text-16 dropdown-item active" : "vn-item text-16 dropdown-item "}`} role="button">
                                                                            <div className="css-1dbjc4n r-1awozwy r-18u37iz r-1g2xzmm" data-testid="country-label" style={{ marginRight: '-6px' }}>
                                                                                <div className="css-1dbjc4n r-1wbh5a2 r-9jpwak r-1g2xzmm" style={{ paddingBottom: '0px', paddingRight: '6px' }}>
                                                                                    <img src={`${port}img/VN-Vietnam-Flag-icon.png`} alt="" style={{ width: '25px' }} />
                                                                                </div>
                                                                                <div className="css-1dbjc4n r-1wbh5a2 r-9jpwak r-1g2xzmm" style={{ paddingBottom: '0px', paddingRight: '6px' }}>
                                                                                    <div dir="auto" className="css-901oao r-homxoj r-320f6p r-7cikom r-1s2g6fe r-t9a87b">Việt Nam</div>
                                                                                </div>
                                                                            </div>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>}

                            </div>
                            {!axiologin ? <div style={{ padding: '5px', color: '#4BBD99', background: '#fff', borderRadius: '5px' }}>
                                <a href="/login" style={{ padding: '10px', color: '#4BBD99' }}>Login / Register</a>
                            </div> :
                                <div className="remi-header-top-menu">
                                    <div className="css-2d">
                                        <div className="language-country-select">
                                            <div className="css-3" style={{ paddingBottom: '0px' }}>
                                                <div className="select-container ">
                                                    <div id="languages-countries" className="select dropdown">
                                                        <div onClick={onClick} className="select-toggle">
                                                            <div className="css-3d">
                                                                <div className="css-3db">
                                                                    <div className="css-3dbc">
                                                                        <span className="pre-text">
                                                                            {lang === "vi"
                                                                                ? 'VI'
                                                                                : "EN"}
                                                                        </span>

                                                                    </div>
                                                                </div>
                                                                <div className="css-3db" data-testid="icon-down-dir" style={{ display: 'flex' }}>
                                                                    <svg width={14} height={14} viewBox="0 0 18 32" fill="#FFFFFF">
                                                                        <path d="M18.272 12.576q0 .448-.32.8l-8 8q-.352.352-.8.352t-.8-.352l-8-8Q0 13.024 0 12.576t.352-.8.8-.352h16q.448 0 .8.352t.32.8z" />
                                                                    </svg>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className={`langmenu dropdown-menu  dropdown-menu-right select-multi-col ${show}`}>
                                                            <div className="select-column">
                                                                <div className="select-column-title md">{t("Language")}</div>
                                                                <div className="select-column-menu">
                                                                    <a href="#" data-value='vi' className={`${lang === "vi" ? "vi-item text-16 dropdown-item active" : "vi-item text-16 dropdown-item"}`} onClick={() => ChangeLang('vi')}>Tiếng Việt</a>
                                                                    <a href="#" data-value='en' className={`${lang === 'en' ? "en-item text-16 dropdown-item active" : "en-item text-16 dropdown-item"}`} onClick={() => ChangeLang('en')}>English</a>
                                                                </div>
                                                            </div>
                                                            <div className="select-column">
                                                                <div className="select-column-title md">{t("Market")}</div>
                                                                <div className="select-column-menu">
                                                                    <a className={`${lang === "vi" ? "vn-item text-16 dropdown-item active" : "vn-item text-16 dropdown-item "}`} role="button">
                                                                        <div className="css-1dbjc4n r-1awozwy r-18u37iz r-1g2xzmm" data-testid="country-label" style={{ marginRight: '-6px' }}>
                                                                            <div className="css-1dbjc4n r-1wbh5a2 r-9jpwak r-1g2xzmm" style={{ paddingBottom: '0px', paddingRight: '6px' }}>
                                                                                <img src={`${port}img/VN-Vietnam-Flag-icon.png`} alt="" style={{ width: '25px' }} />
                                                                            </div>
                                                                            <div className="css-1dbjc4n r-1wbh5a2 r-9jpwak r-1g2xzmm" style={{ paddingBottom: '0px', paddingRight: '6px' }}>
                                                                                <div dir="auto" className="css-901oao r-homxoj r-320f6p r-7cikom r-1s2g6fe r-t9a87b">Việt Nam</div>
                                                                            </div>
                                                                        </div>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>}



                        </div>
                    </div>
                </div>
                <div className="remi-header-main">
                    <nav className="navbar navbar-expand-true navbar-dark">
                        <span className="navbar-brand" style={{ marginLeft: "-15px" }}>
                            <div className="remi-header-logo">
                                <a href="/" store="[object Object]">
                                    <div className="remi-logo-container"><div className="remi-logo-container remi-logo-img">
                                        <img loading="lazy" className="logo-img" src="/img/logoswapcolorful.png" />
                                    </div>
                                    </div>
                                </a>
                            </div>
                        </span>
                        <div className="navbar-menu css-1dbjc4n r-1awozwy r-18u37iz r-1g2xzmm"  >
                            <div className="css-1dbjc4n r-1wbh5a2 r-9jpwak r-i023vh r-1g2xzmm" style={{ paddingBottom: '0px' }} />
                            <div onClick={openNotify}>
                                {!activeimg ? <img src="/img/loa.png" alt="" style={{ width: '24px', marginRight: '10px', color: 'transparent' }} /> : <img src="/img/loa.png" alt="" style={{ width: '24px', marginRight: '10px', marginBottom: '10px' }} />}
                            </div>
                            {axiologin ? <div style={{ marginRight: '10px' }} className="notification-menu" onClick={handleNotification}>
                                {!activeimg ? <img src="/img/baochuong !.png" style={{ width: '23px', height: '23px' }} /> : <i style={{ fontSize: '16px' }} className="far fa-bell"></i>}

                            </div> : ""}
                            <div className="notification">
                                
                                {notification &&
                                    <>
                                        <div onClick={() => setNotification(!notification)} className="overlayNotification"></div>
                                        <div className="notification-container" onScroll={handleScroll} style={{ top: '30px', right: '-65px' }}>
                                            <div className="title">
                                                <span>{t("Notify")} </span>

                                            </div>
                                            <div className="view-all">
                                                <div />

                                                <NavLink to="/Notification" onClick={() => setNotification(!notification)}>{t("See all")}</NavLink>
                                            </div>

                                            <div className="contentNotification">
                                                {getnotification?.total == 0 ? <div style={{ textAlign: 'center', padding: '20px' }}>{t("There are no announcements yet")}</div> :
                                                    getnotification?.array?.map((e, i) => {
                                                        let day = new Date()
                                                        let lastedNotifi = (e?.created_at * 1000)
                                                        let dateNow = day.getTime() - lastedNotifi

                                                        let ngay = Math.floor(dateNow / 1000 / 60 / 60 / 24)
                                                        let gio = Math.floor(dateNow / 1000 / 60 / 60 - ngay * 24)
                                                        let phut = Math.floor(dateNow / 1000 / 60 - ngay * 24 * 60 - gio * 60)
                                                        let giay = Math.floor(dateNow / 1000 - ngay * 24 * 60 * 60 - gio * 60 * 60 - phut * 60)
                                                        let validateGiay = 1000 * 60
                                                        let validatePhut = validateGiay * 60
                                                        let validateGio = validatePhut * 24
                                                        let validateNgay = validateGio * 30
                                                        let str;
                                                        if (dateNow < validateGiay) {
                                                            str = `${giay} ${t("seconds prior to")}`
                                                        } else if (dateNow < validatePhut) {
                                                            str = `${phut} ${t("minutes prior to")}`
                                                        } else if (dateNow < validateGio) {
                                                            str = `${gio} ${t("hours ago")}`
                                                        } else if (dateNow < validateNgay) {
                                                            str = `${ngay} ${t("yesterday")}`
                                                        }
                                                        return (
                                                            <>

                                                                <div key={i} className="notification-item" style={{ marginBottom: '10px' }}>
                                                                    <div className="avatar"><img src={`/logoS.png`} alt="" style={{ maxWidth: '35px', maxHeight:'35px' }}/></div>
                                                                    <div className="noti">
                                                                        <div>[{e?.title}]</div>
                                                                        <div>{e?.detail}</div>
                                                                        <p>{str}</p>
                                                                    </div>
                                                                </div>


                                                            </>


                                                        )
                                                    })}
                                                {getnotification?.total == 0 ? '' : loadding ? <div className="example">
                                                    <Spin />
                                                </div> : ""}

                                            </div>
                                        </div>




                                    </>
                                }
                            </div>
                            <div className="css-1dbjc4n r-1wbh5a2 r-9jpwak r-i023vh r-1g2xzmm" style={{ paddingBottom: '0px' }} onClick={handleNav}>

                                <button type="button" aria-label="Toggle navigation" className="navbar-toggler collapsed" onClick={CloseHandleNav}>
                                    <div className="css-1dbjc4n" data-testid="icon-menu" style={{ height: '24px', width: '24px' }}>
                                        <svg width={24} height={24} viewBox="0 0 18 16" fill="#FFFFFF"><g fill="#FFF" fillRule="evenodd"><path d="M.864.623h16.531c.323 0 .605.28.605.601v1.203c0 .32-.282.601-.605.601H.865a.618.618 0 0 1-.605-.601V1.224c0-.32.282-.601.604-.601zM.864 13.317h16.531c.323 0 .605.28.605.601v1.203c0 .32-.282.601-.605.601H.865a.618.618 0 0 1-.605-.601v-1.203c0-.32.282-.601.604-.601zM3.526 6.97h13.87c.322 0 .604.28.604.601v1.203c0 .32-.282.601-.605.601H3.525a.618.618 0 0 1-.604-.601V7.571c0-.32.282-.601.605-.601z" />
                                        </g>
                                        </svg>
                                    </div>
                                </button>
                            </div>

                        </div>




                        <div className={`remi-header-menu navbar-collapse collapse ${nav}`}>
                            <div className="navbar-nav" style={locker ? { overflow: 'auto', paddingBottom: '280px' } : {}}>
                                {axiologin ? "" :
                                    <li role="presentation" className="navbar-item" onClick={CloseHandleNav}>
                                        <a href="/login" store="[object Object]">
                                            <div className="css-1dbjc4n r-1awozwy r-18u37iz r-1g2xzmm" style={{ marginRight: '-16px' }}>
                                                <div className="css-1dbjc4n r-1wbh5a2 r-9jpwak r-i023vh r-1g2xzmm" style={{ paddingBottom: '0px' }}>
                                                    <div className="css-1dbjc4n r-z2wwpe r-1udh08x" style={{}}>
                                                        <div role="button" aria-busy="false" data-focusable="true" tabIndex={0} className="css-18t94o4 css-1dbjc4n r-1loqt21 r-1otgn73 r-1i6wzkk r-lrvibr" style={{ transitionDuration: '0s' }}>
                                                            <div className="css-1dbjc4n r-1awozwy r-k200y r-z2wwpe r-18u37iz r-1777fci r-5njf8e" style={{ backgroundColor: '#4BBD99', borderWidth: '0px', minHeight: '40px', padding: '8px 24.1px', borderRadius: '5px' }}>
                                                                <div dir="auto" className="css-901oao r-jwli3a r-320f6p r-ubezar r-oxtfae r-135wba7" style={{ paddingTop: '0px', paddingBottom: '0px', color: '#fff' }}>{t("Login / Register")} </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </a>
                                    </li>
                                }
                                {/* <div className="navbar-item-category">{t("ICO SWB")}
                                </div> */}
                                {/* <li className="navbar-item" style={{ textAlign: 'left' }}>
                                    <a href="https://swbcoin.net/" target='_blank' style={{ margin: '0' }} className="buy-ico" >Pre-Sale ICO SWB</a>
                                </li> */}
                                <div className="navbar-item-category">{t("Service")}
                                </div>

                                <li role="presentation" className="navbar-item">
                                    <NavLink to="/p2p" onClick={CloseHandleNav} store="[object Object]"><div className="css-1dbjc4n r-1awozwy r-18u37iz r-1g2xzmm" style={{ marginRight: '-16px' }}>
                                        <div className="css-1dbjc4n r-1wbh5a2 r-9jpwak r-i023vh r-1g2xzmm" style={{ paddingBottom: '0px' }}>
                                            <div className="css-1dbjc4n" data-testid="icon-double-circle" style={{ height: '24px', width: '24px' }}>
                                                <svg width={24} height={24} viewBox="0 0 23 23" fill="#9B59B6">
                                                    <defs>
                                                        <path id="double-circle_svg__a" d="M.644 0h16.814v16.882H.644z" />
                                                    </defs>
                                                    <g fill="none" fillRule="evenodd">
                                                        <path d="M8.242 14.728A8.645 8.645 0 0 1 6.1 6.118a8.528 8.528 0 0 0-3.582 2.154c-3.356 3.37-3.356 8.832 0 12.2a8.57 8.57 0 0 0 12.153 0 8.588 8.588 0 0 0 2.143-3.59 8.565 8.565 0 0 1-8.57-2.154" fill="#FFF" />
                                                        <g transform="translate(5.455)">
                                                            <mask id="double-circle_svg__b" fill="#fff">
                                                                <use xlinkHref="#double-circle_svg__a" />
                                                            </mask>
                                                            <path d="M2.788 2.527a8.588 8.588 0 0 0-2.144 3.59 8.568 8.568 0 0 1 8.571 2.155 8.644 8.644 0 0 1 2.144 8.61 8.536 8.536 0 0 0 3.581-2.153c3.357-3.37 3.357-8.833 0-12.202a8.57 8.57 0 0 0-12.152 0" fill="#34a853" mask="url(#double-circle_svg__b)" />
                                                        </g>
                                                        <path d="M14.67 8.272a8.568 8.568 0 0 0-8.571-2.155 8.647 8.647 0 0 0 2.143 8.612 8.565 8.565 0 0 0 8.572 2.153 8.644 8.644 0 0 0-2.144-8.61" fill="#34a853" />
                                                    </g>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="css-1dbjc4n r-16y2uox r-1wbh5a2 r-9jpwak r-i023vh r-1g2xzmm" style={{ paddingBottom: '0px' }}>
                                            <div className="css-1dbjc4n r-1awozwy r-18u37iz r-1wtj0ep r-1g2xzmm">
                                                <div dir="auto" className="css-901oao r-homxoj r-320f6p r-7cikom r-vw2c0b r-1s2g6fe r-t9a87b">P2P EXCHANGE
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    </NavLink>
                                </li>

                                <li role="presentation" className="navbar-item" >
                                    <NavLink to="/swap" onClick={CloseHandleNav} store="[object Object]">
                                        <div className="css-1dbjc4n r-1awozwy r-18u37iz r-1g2xzmm" style={{ marginRight: '-16px' }}>
                                            <div className="css-1dbjc4n r-1wbh5a2 r-9jpwak r-i023vh r-1g2xzmm" style={{ paddingBottom: '0px' }}>
                                                <div className="css-1dbjc4n" data-testid="icon-swap" style={{ height: '24px', width: '24px' }}>
                                                    <svg width={24} height={24} viewBox="0 0 23 25" fill="#9B59B6">
                                                        <g fill="none" fillRule="evenodd">
                                                            <path fill="#34a853" d="M15.683.553l6.779 6.603-6.78 6.603V9.043H3.646V5.269h12.038z" />
                                                            <path fill="#FFF" d="M7.302 24.874L.523 18.27l6.779-6.603v4.716h12.03v3.773H7.302z" />
                                                        </g>
                                                    </svg>
                                                </div>
                                            </div>
                                            <div className="css-1dbjc4n r-16y2uox r-1wbh5a2 r-9jpwak r-i023vh r-1g2xzmm" style={{ paddingBottom: '0px' }}>
                                                <div className="css-1dbjc4n r-1awozwy r-18u37iz r-1wtj0ep r-1g2xzmm">
                                                    <div dir="auto" className="css-901oao r-homxoj r-320f6p r-7cikom r-vw2c0b r-1s2g6fe r-t9a87b">Swap</div>
                                                </div>
                                            </div>
                                        </div>
                                    </NavLink>
                                </li>

                                <div onClick={handleMenuMegaMoblie} className="activeMenuMegaMoblie navbar-item-category">{t("Product")}
                                    {activeMenuMegaMoblie ?
                                        <div className="sort-up"><i class="fas fa-sort-up"></i></div> :
                                        <div className="sort-down"><i class="fas fa-sort-down"></i></div>
                                    }
                                </div>
                                {activeMenuMegaMoblie && MenuMegaMoblie()}



                                <div className="navbar-item-category">{t("Dashboard")}
                                </div>
                                <a role="presentation" className="navbar-item">
                                    <NavLink to="/kyc-basic" onClick={CloseHandleNav}>
                                        <div className="css-1dbjc4n r-1awozwy r-18u37iz r-1g2xzmm" style={{ marginRight: '-16px' }}>

                                            <div className="css-1dbjc4n r-16y2uox r-1wbh5a2 r-9jpwak r-i023vh r-1g2xzmm" style={{ paddingBottom: '0px' }}>
                                                <div className="css-1dbjc4n r-1awozwy r-18u37iz r-1wtj0ep r-1g2xzmm">
                                                    <div dir="auto" className="css-901oao r-homxoj r-320f6p r-7cikom r-vw2c0b r-1s2g6fe r-t9a87b">KYC</div>
                                                </div>
                                            </div>
                                        </div>
                                    </NavLink>
                                </a>
                                <li role="presentation" className="navbar-item">
                                    <NavLink to='/wallet' onClick={CloseHandleNav}>
                                        <div className="css-1dbjc4n r-1awozwy r-18u37iz r-1g2xzmm" style={{ marginRight: '-16px' }}>

                                            <div className="css-1dbjc4n r-16y2uox r-1wbh5a2 r-9jpwak r-i023vh r-1g2xzmm" style={{ paddingBottom: '0px' }}>
                                                <div className="css-1dbjc4n r-1awozwy r-18u37iz r-1wtj0ep r-1g2xzmm">
                                                    <div dir="auto" className="css-901oao r-homxoj r-320f6p r-7cikom r-vw2c0b r-1s2g6fe r-t9a87b">Wallets</div>
                                                </div>
                                            </div>
                                        </div>
                                    </NavLink>
                                </li>
                                <li role="presentation" className="navbar-item">
                                    <NavLink to='/invitation' onClick={CloseHandleNav}>
                                        <div className="css-1dbjc4n r-1awozwy r-18u37iz r-1g2xzmm" style={{ marginRight: '-16px' }}>

                                            <div className="css-1dbjc4n r-16y2uox r-1wbh5a2 r-9jpwak r-i023vh r-1g2xzmm" style={{ paddingBottom: '0px' }}>
                                                <div className="css-1dbjc4n r-1awozwy r-18u37iz r-1wtj0ep r-1g2xzmm">
                                                    <div dir="auto" className="css-901oao r-homxoj r-320f6p r-7cikom r-vw2c0b r-1s2g6fe r-t9a87b">Invitation</div>
                                                </div>
                                            </div>
                                        </div>
                                    </NavLink>
                                </li>
                          
                            
                                {axiologin ? 
                                <>
                                <li role="presentation" className="navbar-item">
                                    <NavLink to='/Parent' onClick={CloseHandleNav}>
                                        <div className="css-1dbjc4n r-1awozwy r-18u37iz r-1g2xzmm" style={{ marginRight: '-16px' }}>

                                            <div className="css-1dbjc4n r-16y2uox r-1wbh5a2 r-9jpwak r-i023vh r-1g2xzmm" style={{ paddingBottom: '0px' }}>
                                                <div className="css-1dbjc4n r-1awozwy r-18u37iz r-1wtj0ep r-1g2xzmm">
                                                    <div dir="auto" className="css-901oao r-homxoj r-320f6p r-7cikom r-vw2c0b r-1s2g6fe r-t9a87b">{t("Referral")}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </NavLink>
                                </li>
                                <li role="presentation" className="navbar-item">
                                <NavLink to='/mining' onClick={CloseHandleNav}>
                                    <div className="css-1dbjc4n r-1awozwy r-18u37iz r-1g2xzmm" style={{ marginRight: '-16px' }}>

                                        <div className="css-1dbjc4n r-16y2uox r-1wbh5a2 r-9jpwak r-i023vh r-1g2xzmm" style={{ paddingBottom: '0px' }}>
                                            <div className="css-1dbjc4n r-1awozwy r-18u37iz r-1wtj0ep r-1g2xzmm">
                                                <div dir="auto" className="css-901oao r-homxoj r-320f6p r-7cikom r-vw2c0b r-1s2g6fe r-t9a87b">{t(" Mining")}</div>
                                            </div>
                                        </div>
                                    </div>
                                </NavLink>
                            </li>
                            <li role="presentation" className="navbar-item">
                                <NavLink to='/reserve-tobecoin' onClick={CloseHandleNav}>
                                    <div className="css-1dbjc4n r-1awozwy r-18u37iz r-1g2xzmm" style={{ marginRight: '-16px' }}>

                                        <div className="css-1dbjc4n r-16y2uox r-1wbh5a2 r-9jpwak r-i023vh r-1g2xzmm" style={{ paddingBottom: '0px' }}>
                                            <div className="css-1dbjc4n r-1awozwy r-18u37iz r-1wtj0ep r-1g2xzmm">
                                                <div dir="auto" className="css-901oao r-homxoj r-320f6p r-7cikom r-vw2c0b r-1s2g6fe r-t9a87b">{t(" Reserve TBC")}</div>
                                            </div>
                                        </div>
                                    </div>
                                </NavLink>
                            </li>
                            </> : ""}


                                <div className="navbar-item-category">{t("Account")}
                                </div>
                                <li role="presentation" className="navbar-item">
                                    <NavLink to={`/Profile/${slug?.toUpperCase()}`} onClick={CloseHandleNav}>
                                        <div className="css-1dbjc4n r-1awozwy r-18u37iz r-1g2xzmm" style={{ marginRight: '-16px' }}>

                                            <div className="css-1dbjc4n r-16y2uox r-1wbh5a2 r-9jpwak r-i023vh r-1g2xzmm" style={{ paddingBottom: '0px' }}>
                                                <div className="css-1dbjc4n r-1awozwy r-18u37iz r-1wtj0ep r-1g2xzmm">
                                                    <div dir="auto" className="css-901oao r-homxoj r-320f6p r-7cikom r-vw2c0b r-1s2g6fe r-t9a87b">{t("Profile")}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </NavLink>

                                </li>

                                <li role="presentation" className="navbar-item">
                                    <NavLink to="/setting" onClick={CloseHandleNav}>
                                        <div className="css-1dbjc4n r-1awozwy r-18u37iz r-1g2xzmm" style={{ marginRight: '-16px' }}>

                                            <div className="css-1dbjc4n r-16y2uox r-1wbh5a2 r-9jpwak r-i023vh r-1g2xzmm" style={{ paddingBottom: '0px' }}>
                                                <div className="css-1dbjc4n r-1awozwy r-18u37iz r-1wtj0ep r-1g2xzmm">
                                                    <div dir="auto" className="css-901oao r-homxoj r-320f6p r-7cikom r-vw2c0b r-1s2g6fe r-t9a87b">{t("Setting")}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </NavLink>
                                </li>
                                <li role="presentation" className="navbar-item">
                                    <NavLink to="/trades" onClick={CloseHandleNav}>
                                        <div className="css-1dbjc4n r-1awozwy r-18u37iz r-1g2xzmm" style={{ marginRight: '-16px' }}>

                                            <div className="css-1dbjc4n r-16y2uox r-1wbh5a2 r-9jpwak r-i023vh r-1g2xzmm" style={{ paddingBottom: '0px' }}>
                                                <div className="css-1dbjc4n r-1awozwy r-18u37iz r-1wtj0ep r-1g2xzmm">
                                                    <div dir="auto" className="css-901oao r-homxoj r-320f6p r-7cikom r-vw2c0b r-1s2g6fe r-t9a87b">{t("History p2p")}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </NavLink>
                                </li>
                                <div className="navbar-item-category">{t("Is different")}
                                </div>
                                <li role="presentation" className="navbar-item">
                                    <a href="#">
                                        <div className="css-1dbjc4n r-1awozwy r-18u37iz r-1g2xzmm" style={{ marginRight: '-16px' }}>
                                            <div className="css-1dbjc4n r-16y2uox r-1wbh5a2 r-9jpwak r-i023vh r-1g2xzmm" style={{ paddingBottom: '0px' }}>
                                                <div className="css-1dbjc4n r-1awozwy r-18u37iz r-1wtj0ep r-1g2xzmm">
                                                    <div dir="auto" className="css-901oao r-homxoj r-320f6p r-7cikom r-vw2c0b r-1s2g6fe r-t9a87b">{t("Language")}
                                                    </div>
                                                    <a href="#" data-value='vi' onClick={() => ChangeLang('vi')} style={{ fontSize: '16px', cursor: 'pointer', padding: '8px' }}>{t("Vietnamese")}</a>
                                                    <a href="#" data-value='en' onClick={() => ChangeLang('en')} style={{ fontSize: '16px', cursor: 'pointer', padding: '8px' }}>English</a>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </li>

                                <li role="presentation" className="navbar-item">
                                    <NavLink to="/btc/vn/referral" onClick={CloseHandleNav}>
                                        <div className="css-1dbjc4n r-1awozwy r-18u37iz r-1g2xzmm" style={{ marginRight: '-16px' }}>
                                            <div className="css-1dbjc4n r-16y2uox r-1wbh5a2 r-9jpwak r-i023vh r-1g2xzmm" style={{ paddingBottom: '0px' }}>
                                                <div className="css-1dbjc4n r-1awozwy r-18u37iz r-1wtj0ep r-1g2xzmm">
                                                    <div dir="auto" className="css-901oao r-homxoj r-320f6p r-7cikom r-vw2c0b r-1s2g6fe r-t9a87b">{t("Introduce a friend")}
                                                    </div>
                                                    <div className="text-explanation">
                                                        <div className="css-1dbjc4n r-z2wwpe r-1udh08x" style={{ borderRadius: '4px' }}>
                                                            <div role="button" aria-busy="false" data-focusable="true" tabIndex={0} className="css-18t94o4 css-1dbjc4n r-1loqt21 r-1otgn73 r-1i6wzkk r-lrvibr" style={{ transitionDuration: '0s' }}>
                                                                <div className="css-1dbjc4n r-1awozwy r-k200y r-z2wwpe r-18u37iz r-1777fci r-1pn2ns4 r-5njf8e" style={{ backgroundColor: '#4BBD99', borderWidth: '0px', minHeight: '36px', padding: '8px' }}>
                                                                    <span>
                                                                        <b>40%</b>&nbsp;{t("Rose")}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </NavLink>
                                </li>

                                {axiologin ? <li style={{ marginTop: '20px' }} onClick={logout}>
                                    <a style={{ padding: '10px', backgroundColor: '#4BBD99', borderRadius: '4px', fontSize: '16px', fontFamily: 'Arial', marginLeft: '20px', marginRight: '20px' }}>{t('Log out')}</a>
                                </li> : ""}

                            </div>
                        </div>
                    </nav>
                    <div />
                    <div />
                </div>
            </header >
   

        </>


    )
}
