import { Spin } from 'antd';
import RecaptchaF2A from 'Components/RecaptchaF2A';
import covertCoin from 'core/CovertCoin';
import { fixedNumber } from 'core/hook/fixedNumber';
import { formatMoney } from 'core/hook/formarMoney';
import { ErrorMessage } from 'core/Message';
import { useTranslate } from 'core/translate';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { GetFeeERC20 } from 'redux/action/userAction';
import { GetFee } from 'redux/action/userAction';
import { createTransferToUserName } from 'redux/action/userAction';

function SubmitUser({ UserCoin, username, slug, type, note, setMenuflag }) {
    const dispatch = useDispatch()
    let { t } = useTranslate()

    const { profile } = useSelector(state => state.user)
    const twofa = profile?.data?.data?.enabled_twofa
    let { getfee, getfeeErc20 } = useSelector(state => state.user)
    let symbol = JSON.parse(localStorage.getItem('symbol'))
    let user = JSON.parse(localStorage.getItem('user'))
    let [loadding, setLoadding] = useState(false)
    let slugHistory = slug


    useEffect(() => {
        dispatch(GetFee('transfer'))
        dispatch(GetFeeERC20('WIDTHDRAWUSDTERC20'))
    }, [])
    let total = Number(UserCoin) + ((UserCoin / 100) * getfee[0]?.raito)
    let nameCoin = covertCoin(slug, type)
    let { tokenRe, value2fa } = useSelector(state => state.other)
    function handleSubmitUser() {
        if (!tokenRe.length > 0 || !value2fa) {
            setLoadding(true)
            if (slug == "USDT") {
                if (symbol == "USDT.ERC20") {
                    dispatch(createTransferToUserName(user?.axiologin?.token, `${slug}.ERC20`, Number(UserCoin), username, note, slugHistory, tokenRe, value2fa, setLoadding, t, setMenuflag))
                } else {
                    dispatch(createTransferToUserName(user?.axiologin?.token, `${slug}.TRC20`, Number(UserCoin), username, note, slugHistory, tokenRe, value2fa, setLoadding, t, setMenuflag))
                }
            }
            else {
                dispatch(createTransferToUserName(user?.axiologin?.token, nameCoin, Number(UserCoin), username, note, nameCoin, tokenRe, value2fa, setLoadding, t, setMenuflag))
            }
        } else {
            if (twofa == 0 && tokenRe == '') {
                ErrorMessage("Please enter ReCAPTCHA", t)
            } else {
                ErrorMessage("Please enter 2FA", t)
            }
        }
    }
    return (
        <>
            <div>
                <div className='depositvnd-title'>
                    <p>{t("Remittance confirmation")}</p>
                </div>
                <div className='depositvnd-input'>
                    <p>{t("Receiver")} </p>
                    <p>{username}</p>
                </div>
                <div className='depositvnd-input'>
                    <p> {t("Amount")} {slug}</p>
                    <p>{formatMoney(String(UserCoin))}  </p>
                </div>
                <div className='depositvnd-input'>
                    <p> {t("Withdrawal Fee")} </p>
                    {symbol == "USDT.ERC20" ? <p >{getfeeErc20[0]?.raito} $</p> : <p >{getfee[0]?.raito}%</p>}
                </div>
                <div className='depositvnd-input'>
                    <p> {t("Amount of money")} </p>
                    {UserCoin == 0.1 ? <p>{formatMoney(String(fixedNumber(total, 4)))} {slug}</p> : <p >{formatMoney(String(fixedNumber(total, 6)))} {slug}</p>}
                </div>

                <RecaptchaF2A />
                <div className="depositvnd-submit">
                    {loadding ? <div className="example" style={{ textAlign: 'center', width: '100%' }}>
                        <Spin />
                    </div> :
                        <>
                            <button onClick={handleSubmitUser}>Confirm</button>
                            <button onClick={() => setMenuflag(false)}>Back</button>
                        </>}
                </div>
            </div>
        </>
    );
}

export default SubmitUser;