import { Box, Button, Modal, TextField, Typography } from '@mui/material';
import { fontWeight } from '@mui/system';
import { Pagination } from 'antd';
import { formatDateTime } from 'core/hook/formatDateTime';
import { useTranslate } from 'core/translate';
import { port } from 'port';
import React from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spin } from 'antd'
import { createHistorywidthdrawAdress } from 'redux/action/userAction';
import { logDOM } from '@testing-library/react';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};


const flex = {
    display: 'flex',
    justifyContent: 'space-between'
};
const textt = {
    fontSize: '16px',
    fontFamily: 'IBM Plex Sans',
    marginTop: '20px',
    color: '#757575',
    fontWeight: 'bold'
};
const flexImg = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontWeight: 'bold'
};

const flexMT = {
    marginTop: '20px'
};
const button = {
    width: "100%",
    background: '#4BBD99'
}
function HistoryWallet() {
    const dispatch = useDispatch()
    const { t } = useTranslate()
    const token = JSON.parse(localStorage.getItem('user'))
    const { historywidthdrawadress } = useSelector(state => state.user)
    const { dataWithdrawCoin, symbolHistory } = useSelector(state => state.withdraw)
    const { createwallettrc, createwallet } = useSelector(state => state.deposit)
    const { showLoadding } = useSelector(state => state.loadding)

    let [details, setDetails] = useState('')
    let [open, setOpen] = React.useState(false);




    const handleOpen = (e) => {
        setDetails(e)
        setOpen(true)
    }
    const handleClose = () => setOpen(false);
    const handlePage = (page) => {
        if (symbolHistory == 'USDT.TRC20') dispatch(createHistorywidthdrawAdress(token?.axiologin?.token, page, 'USDT', createwallet?.data?.data?.address))
        else dispatch(createHistorywidthdrawAdress(token?.axiologin?.token, page, symbolHistory, createwallet?.data?.data?.address))
    }


    const list = historywidthdrawadress?.data?.data?.array?.map((e, i) => {
        return (
            <div className="dhistory-content" key={i}>
                <div>
                    <p>{t('Wallet')} {dataWithdrawCoin?.name}</p>
                    <p>{formatDateTime(e?.created_at.substring(0, 9), "/")} {formatDateTime(e?.created_at.substring(10, 17), ":")}</p>

                </div>
                <div style={{ textAlign: 'end' }}>
                    <p>                 {createwallet?.data?.status == true ? e?.to_address == createwallet?.data?.data ? '+' : '-' : ''}
                        {createwallettrc?.data?.status == true ? e?.to_address == createwallettrc?.data?.data ? '+' : '-' : ''}
                        {e?.amount} {e?.coin_key?.toUpperCase()}</p>
                    {e?.status == 1 &&
                        <p style={{ color: '#17a64d', fontWeight: 'bold' }}>
                            {t("Accomplished")}
                        </p>}
                    {e?.status == 2 &&
                        <p className='text text-warning' style={{ fontWeight: 'bold' }} >
                            {t("Pending")}
                        </p>}
                    {e?.status == 0 &&
                        <p style={{ color: '#c02c2c', fontWeight: 'bold' }}>
                            {t("Cancel")}
                        </p>}
                    <Button variant="contained" sx={{ background: '#4BBD99' }} onClick={() => handleOpen(e)}>{t("Details")}</Button>

                </div>


            </div>
        )
    })
    return (
        <>
            {showLoadding ? <div className="example" style={{ width: '100%' }} ><Spin /></div> :
                <>
                    <div className='dhistory-title'>
                        <p> History</p>
                    </div>
                    {historywidthdrawadress?.data?.data?.total == 0 ? <div className="content" style={{ background: '#fff', padding: '20px' }}>
                        <div className="interest-list">
                            <div style={{ color: "#000", fontSize: '18px', fontStyle: 'italic', textAlign: 'center' }}>
                                {t("No transactions yet")}
                            </div>
                        </div>
                    </div> :
                        <>
                            {list}
                        </>}
                    <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={style}>
                            <Typography id="modal-modal-title" variant="h6" component="h2" style={flexImg} >
                                {t('Details')}
                                <img src={`${port}images/${dataWithdrawCoin?.name}.png`} alt="" style={{ width: '25px', marginRight: '5px' }} />
                            </Typography>
                            <Box>
                            </Box>
                            <Box sx={flex}>
                                <Typography id="modal-modal-description" sx={textt}>
                                    {t("Type")}
                                </Typography>
                                <Typography id="modal-modal-description" sx={textt}>
                                    {t("Transfer ousite")}
                                </Typography>
                            </Box>
                            <Box sx={flex}>
                                <Typography id="modal-modal-description" sx={textt}>
                                    {t("Status")}
                                </Typography>
                                <Typography id="modal-modal-description" sx={textt}>
                                    {details?.status == 1 && <p className='text text-success'>{t("Accomplished")}</p>}
                                    {details?.status == 2 && <p className='text text-warning'>{t("Pending")}</p>}
                                    {details?.status == 0 && <p className='text text-danger' style={{ fontWeight: 'bold', fontFamily: 'IBM Plex Sans', fontSize: '16px' }}>{t("Cancel")}</p>}


                                </Typography>
                            </Box>
                            {details?.note == undefined ? "" :
                                <Box sx={flex}>
                                    <Typography id="modal-modal-description" sx={textt}>
                                        {t("Note")}
                                    </Typography>
                                    <Typography id="modal-modal-description" sx={textt} style={{ color: 'red', fontWeight: '700' }}>
                                        {details?.note}
                                    </Typography>
                                </Box>}
                            <Box sx={flex}>
                                <Typography id="modal-modal-description" sx={textt}>
                                    {t("Amount of")}
                                </Typography>
                                <Typography id="modal-modal-description" sx={textt}>
                                    {details?.address_to == token?.axiologin?.username || details?.address_to == createwallet?.data?.data ? "+" : '-'}
                                    {details?.amount}
                                </Typography>
                            </Box>
                            {details?.form_address == undefined ? '' :
                                <Box sx={flex}>
                                    <Typography id="modal-modal-description" sx={textt}>
                                        {t("From")}
                                    </Typography>
                                    <Typography id="modal-modal-description" sx={textt}>
                                        {details?.form_address}
                                    </Typography>
                                </Box>}
                            {details?.to_address == undefined ? "" :
                                <Box sx={flex}>
                                    <Typography id="modal-modal-description" sx={textt}>
                                        {t("Come")}
                                    </Typography>
                                    <Typography id="modal-modal-description" sx={textt}>
                                        {details?.to_address}
                                    </Typography>
                                </Box>}



                            <Box sx={flex}>
                                <Typography id="modal-modal-description" sx={textt}>
                                    {t("Time")}
                                </Typography>
                                <Typography id="modal-modal-description" sx={textt}>
                                    {details?.created_at}
                                </Typography>
                            </Box>
                            <Box sx={flexMT}>
                                <Button variant="contained" onClick={handleClose} sx={button}>{t("Back")}</Button>
                            </Box>
                        </Box>

                    </Modal>
                </>}
            <Pagination
                style={{ marginTop: '20px' }}
                defaultPageSize={5} //default size of page
                onChange={handlePage}
                defaultCurrent={1}
                total={historywidthdrawadress?.data?.data?.total} //total number of card data available
            />
        </>
    );
}

export default HistoryWallet;