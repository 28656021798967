import CreateApi from "api/CreateApi"
import reduxToolkit from "../../core/reduxToolkit"

const initialState = {
    showKyc: false,
}








let { reducer, action, TYPE } = reduxToolkit({
    name: 'kycLevel',
    initialState,
    reducers: {

        ShowKyc: function (state, action) {

            return {
                ...state,
                showKyc: action.payload
            }
        },

    }
})

export default reducer
export const WITHDRAW = TYPE
export const { ShowKyc } = action