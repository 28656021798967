import { FormControl, TextField } from '@mui/material'
import { DataPriceCoin } from 'core/DataPriceCoin'
import { fixedNumber } from 'core/hook/fixedNumber'
import { formatMoney } from 'core/hook/formarMoney'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'







export default function Price({valueInput }) {
    let { pricecoin } = useSelector(state => state.coin)
    const {getInfoTBC} = useSelector(state => state.buytbc)
    const data = DataPriceCoin(pricecoin,valueInput?.symbol)
    const valuePrice = valueInput?.symbol == "USDT" ? (valueInput?.amount  * getInfoTBC?.price) / 1 :  (valueInput?.amount *getInfoTBC?.price) / data?.price
    const {profile} = useSelector(state => state.user)
    let MaxToken = 500- profile?.data?.data?.max_tbc_token
    return (
        <>
            <div>
                <span>Amount of {valueInput?.symbol}</span>
            <FormControl fullWidth>
                <TextField id="outlined-basic"   disabled value={data ? valuePrice : 0} variant='outlined'/>
            </FormControl>

            </div>
            <div  style={{color:'#235161', fontWeight:'700'}}>
                  Maximum TBC reserve: {formatMoney(String(fixedNumber(MaxToken / data?.price, 2)))} {valueInput?.symbol}
          </div>
            </>
    )
}
