import { Pagination } from 'antd';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createHistoryLendingTime } from '../../redux/action/userAction';

export default function HistoryLendingTime() {
    let { historylendingtime } = useSelector(state => state.user)

    const dispatch = useDispatch()
    let token = JSON.parse(localStorage.getItem('user'))
    let [limit, setLimit] = useState(10)

    useEffect(() => {
        window.scrollTo(0, 0)
        dispatch(createHistoryLendingTime(token?.axiologin?.token, limit, 1))
    }, [])
    function handlePageLedingTime(page) {
        window.scrollTo(0, 0)
        dispatch(createHistoryLendingTime(token?.axiologin?.token, limit, page))
    }
    return (
        <div style={{ color: "#000", backgroundColor: "#fff" }}>
            {historylendingtime?.data?.data?.array?.map((e, i) => {
                return (
                    <div style={{ textAlign: 'center', margin: '25px', borderBottom: '1px solid', paddingBottom: '25px', backgroundColor: '#fff', fontFamily: 'Arial', maxWidth: '450px', width: '450px', margin: '0 auto', marginTop: '20px' }}>
                        <div className="container">
                            <div style={{ border: '1px solid', padding: '20px', textAlign: 'left', fontFamily: 'Arial', fontSize: '16px' }}>
                                <div>username: {e?.username}</div>
                                <div>amount: {e?.amount}</div>
                                <div>amount_bonus: {e?.amount_bonus}</div>
                                <div>bonus_percent_day: {e?.bonus_percent_day}</div>
                                <div>bonus_percent_month: {e?.bonus_percent_month}</div>
                                <div>coin_amount: {e?.coin_amount}</div>
                                <div>coin_rate: {e?.coin_rate}</div>
                                <div>created_at: {e?.created_at}</div>
                                <div>cron_date: {e?.cron_date}</div>
                                <div>cycle: {e?.cycle}</div>
                                <div>direct_bonus_percent: {e?.direct_bonus_percent}</div>
                                <div>execute_date: {e?.execute_date}</div>
                                <div>expired_date: {e?.expired_date}</div>
                                <div>id: {e?.id}</div>
                                <div>interest: {e?.interest}</div>
                                <div>is_direct_bonus: {e?.is_direct_bonus}</div>
                                <div>lock_interest: {e?.lock_interest}</div>
                                <div>max_day: {e?.max_day}</div>
                                <div>max_out_amount: {e?.max_out_amount}</div>
                                <div>message: {e?.message}</div>
                                <div>package_id: {e?.package_id}</div>
                                <div>paid_amount: {e?.paid_amount}</div>
                                <div>paid_day: {e?.paid_day}</div>
                                <div>profit: {e?.profit}</div>
                                <div>rate: {e?.rate}</div>
                                <div>source: {e?.source}</div>
                                <div>source_amount: {e?.source_amount}</div>
                                <div>status: {e?.status}</div>
                                <div>user_id: {e?.user_id}</div>
                                <div>username_parentF1: {e?.username_parentF1}</div>
                            </div>
                        </div>

                    </div>
                )
            })}
            <Pagination style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingBottom: '20px', marginTop: '20px' }}
                defaultCurrent={1}
                defaultPageSize={10} //default size of page
                onChange={handlePageLedingTime}
                total={historylendingtime?.data?.data?.total} //total number of card data available
            />

        </div>
    )
}
