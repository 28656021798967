import { INDEXBUY, INDEXSELL, SEARCHMESSAGE, SEARCHMESSAGE2, SREACHBUYNOW, SREACHSELLNOW, TYPETOTAL, VALUEBUY, VALUESELL } from "../type"

let initState = {
    searchbuynow: [],
    searchsellnow: [],
    searchmessage: [],
    searchmessage2: [],
    valuebuy: [],
    valuesell: [],
    indexbuy: [],
    indexsell: [],
    typetotal: 0


}


export default function authReducer(state = initState, action) {
    switch (action.type) {
        
        case SREACHBUYNOW:
            return {
                ...state,
                searchbuynow: action?.payload,
            }
        case SREACHSELLNOW:
            return {
                ...state,
                searchsellnow: action?.payload,
            }
        case SEARCHMESSAGE:
            return {
                ...state,
                searchmessage: action?.payload,
            }
        case SEARCHMESSAGE2:
            return {
                ...state,
                searchmessage2: action?.payload,
            }
        case VALUEBUY:
            return {
                ...state,
                valuebuy: action?.payload,
            }
        case VALUESELL:
            return {
                ...state,
                valuesell: action?.payload,
            }
        case INDEXBUY:
            return {
                ...state,
                indexbuy: action?.payload,
            }
        case INDEXSELL:
            return {
                ...state,
                indexsell: action?.payload,
            }
        case TYPETOTAL:
            return {
                ...state,
                typetotal: action?.payload,
            }



        default:
            return state;
    }
}