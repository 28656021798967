import React, { useEffect, useRef } from 'react'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
// import backgroundPhone from 'assets/img/bg-phone-minning.png'
import bgmining from 'assets/img/svg/bg-mining.svg'
import { Spin } from 'antd'
import { HandleGetValueMinning } from 'redux/reducer/minningReducer'
import { HandleCreateApiCoin } from 'redux/reducer/depositReducer'
import { Redirect } from 'react-router-dom/cjs/react-router-dom'
import Popup from './components/Popup'
import { ErrorMessage } from 'core/Message'
import { useTranslate } from 'core/translate'
import { formatMoney } from 'core/hook/formarMoney'
import { fixedNumber } from 'core/hook/fixedNumber'
import Canvas from 'page/Canvas'
import Matrix from './components'
import People from 'assets/img/svg/people.svg'
import Vongtron from 'assets/img/svg/vongtron.svg'
import yourmining from 'assets/img/svg/yourmining.svg'
import power from 'assets/img/svg/power.svg'
import coin1 from 'assets/img/svg/coin1.svg'
import coin2 from 'assets/img/svg/coin2.svg'

import Lottie from './components/Lottie'
import History from './components/History'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
// import CountdownPage from './components/CountdownPage'






export default function Minning() {
    const [open, setOpen] = useState(false)
    const history = useHistory()
    const [active, setActive] = useState(false)
    const {getHistory} = useSelector(state => state.minning)
    const [notify, setNotify] = useState(false)
    const user = JSON.parse(localStorage.getItem('user'))
    const check = JSON.parse(localStorage.getItem('confirm'))
    const { profile } = useSelector(state => state.user)
    const verified = profile?.data?.data?.verified
    const {t} = useTranslate()
    const dispatch= useDispatch()
    const {matrix} = useSelector(state => state.minning)
    const HandleOpen = () => {
        if(profile?.data?.data?.kyc_confirm != 2){
            ErrorMessage('Please KYC Level 2', t)
            history.push('/verifiedKyc')
        }else{
            setOpen(true)
        }
    }
    useEffect(() => {
        if(check?.check1 === 2 && matrix?.isMiner === false){
            setNotify('miner')
        }
        else if(check && verified == 1){
            if(check?.check1 != 2 ){
                if(check?.check != 2){
                   setNotify('kyc2')
                }else{
                   setNotify(false)
                }
        }else{
            setNotify(false)
        }
    }
    },[check,matrix])
    useEffect(() => {
        dispatch(HandleGetValueMinning())
        dispatch(HandleCreateApiCoin("TBC"))
    },[])
    // useEffect(() => {
     
    //     }
    // },[profile])

   

    if(!user) return  <Redirect to='/form' />
    if(check?.check2 != 1 ) return <Redirect to='/kyc-basic' />
    return (
        <>
        {active && 
          <Canvas  active={active} setActive={setActive}/>
        }
       <div className='minning'>
        <div className="container mining-bg">
            <div className="minning-main" style={{background:`url(${bgmining})`, backgroundSize:'cover'}}>
            <div className='minning-main-title'>
                <h3>TBC MINING</h3>
            </div>
            {open ? 
            <>
            <Matrix setOpen={setOpen} setActive={setActive}/> 
          
            </>: 
            
            <>
  <Lottie />
         
             <div className='minning-main-text'>
                    <div>Mining power</div>
                    <img src={power} alt="" />
                    <strong>{formatMoney(String(fixedNumber(matrix?.mining_power, 4)))}</strong>
                    <span>TBC</span>
                    <p>/decryption</p>
            </div>
            <div className='minning-main-input'>
                <div>
                    <img src={People} alt="" />
                    <div>Phone miner</div>
                </div>
                <strong>{matrix?.people_mining}</strong>
            </div>
            <div className='minning-main-input'>
                <div>
                    <img src={Vongtron} alt="" />
                    <div>Total mined</div>
                </div>
                <strong style={{color:'#17D5C9'}}>{formatMoney(String(fixedNumber(matrix?.tcb_mined, 2)))} </strong>
            </div>
            <div className='minning-main-input'>
                <div>
                    <img src={yourmining} alt="" />
                    <div>Your mined</div>
                </div>
                <strong style={{color:'#E77D64'}}>{matrix?.your_mining} </strong>
            </div>
         
                </>}
                {!matrix?.isMiner ? <div className='minning-main-button'>  <button  disabled={true} style={{opacity:`0.5`}}> Mine TBC    </button> </div>:
                <>
        {/* <CountdownPage /> */}

            {
                matrix?.active ? <strong style={{color:'rgb(217,224,33)'}}>You have run out of minning session today</strong> : 
                <div className='minning-main-button'> 
                <button onClick={HandleOpen} disabled={open?true:false} style={{opacity:`${open ? "0.5": "1"}`}}> {!open ? "Mine TBC" : <Spin />}    </button>
                </div>
                }

      


                </>
                }
                      <img className='coin1' src={coin1} alt=""/>
            <img className='coin2' src={coin2} alt="" />
            </div>
           
      <History />
        </div>
       </div>
      {notify &&<Popup setNotify={setNotify} notify={notify}/> } 
       </>
    )
}
