import CreateApi from "api/CreateApi"
import reduxToolkit from "../../core/reduxToolkit"

const initialState = {
    showDeposit: '',
    createwallet: '',
    createwalleterc: '',
    createwallettrc: '',
}

export const HandleCreateApiCoin = (symbol) => {
    return async (dispatch) => {
        try {
            let res = await CreateApi.CreateApiS('api/user/createWallet', {
                "symbol": symbol,
            }, true)
            dispatch(Createwallet(res))

        } catch (error) {
        }

    }
}
export const HandleCreateApiCoinTRC = (symbol) => {
    return async (dispatch) => {
        try {
            let res = await CreateApi.CreateApiS('api/user/updateWalletDepositTRC20', {
                "symbol": symbol,
            }, true)
            dispatch(Createwallettrc(res))

        } catch (error) {
        }

    }
}
export const HandleCreateApiCoinERC = (symbol) => {
    return async (dispatch) => {
        try {
            let res = await CreateApi.CreateApiS('api/user/createWalletERC20', {
                "symbol": symbol,
            }, true)
            dispatch(Createwalleterc(res))

        } catch (error) {
        }

    }
}


export const HandleCreateApiDeposit = (symbol, type) => {
    return async (dispatch) => {
        try {
            if (type == "TRC20") {
                dispatch(HandleCreateApiCoinTRC(`${symbol}_TRC20`))
            } else if (symbol == "USDT") {
                dispatch(HandleCreateApiCoin(`${symbol}.TRC20`))
            } else if (symbol == "WIN") {
                dispatch(HandleCreateApiCoin('WIN.BEP20'))
            }
            else if (symbol == "SHIB") {
                dispatch(HandleCreateApiCoin('SHIB.BEP20'))
            }
            else {
                dispatch(HandleCreateApiCoin(symbol))
            }
        } catch (error) {
        }
    }
}



let { reducer, action, TYPE } = reduxToolkit({
    name: 'deposit',
    initialState,
    reducers: {

        ShowDeposit: function (state, action) {
            return {
                ...state,
                showDeposit: action.payload
            }
        },
        Createwallet: function (state, action) {
            return {
                ...state,
                createwallet: action.payload,
                createwalleterc: false,
                createwallettrc: false,

            }
        },
        Createwallettrc: function (state, action) {
            return {
                ...state,

                createwallettrc: action.payload,
                createwallet: false,
                createwalleterc: false

            }
        },
        Createwalleterc: function (state, action) {
            return {
                ...state,
                createwalleterc: action.payload,
                createwallet: false,
                createwallettrc: false

            }
        },


    }
})

export default reducer
export const DEPOSIT = TYPE
export const { ShowDeposit, Createwallet, Createwalleterc, Createwallettrc } = action