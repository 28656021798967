export const DataPriceCoin = (listcoin, name) => {
    let data
    if (listcoin) {
        listcoin.filter((e) => {
            if (e.name == name) {
                data = e
            }
        })
    }
    else {
        data = 0
    }
    return data
}