
import lottie from 'lottie-web';
import Json from 'assets/json/json.json'
import React, { useEffect, useRef } from 'react'



export default function Lottie() {
   
    const container = useRef()
    useEffect(() => {
        lottie.loadAnimation({
            container:container.current,
            renderer:'svg',
            loop:true,
            autoplay:true,
            animationData:Json
        })
    },[])

    return (
        <div className='minning-main-img'>
        <div  ref={container}></div>
        </div>

    )
}
