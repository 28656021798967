import React from 'react'
import Footer from '../Components/Footer'
import Header from '../Components/Header'
import PopupCoin from '../Components/PopupCoin'


import ShadowLayer from '../Components/ShadowLayer'
import KYC2 from 'page/KYC2'
import { useSelector } from 'react-redux'
import Canvas from 'page/Canvas'






export default function MainLayout({ children }) {
    const {showKyc}  = useSelector(state => state.kyc)
    return (
        <>
            <Header />
            {children}
            <PopupCoin />
            {/* <PopupSell />
            <PopupBuy /> */}
            <Footer />
            <ShadowLayer />
            {showKyc &&    <KYC2 />}
         

            <div className="open-button1"><a href="https://t.me/swaptobeglobal" target="_blank"><img className="tl_main_logo" xlinkHref="https://telegram.org//img/t_logo.svg?1" src="/img/t_logo.png?1" srcSet="https://telegram.org//img/t_logo.svg?1 2x, https://telegram.org//img/t_logo.svg?1" alt="Telegram logo" width={50} height={50} /></a></div>
        </>
    )
}
