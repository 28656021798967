import Swal from 'sweetalert2';
import responseGoogle from '../../api/Google';
import { AUTH_LOGIN_ERROR, AUTH_LOGIN_STATUS, AXIOSLOGIN, BICANCE, DATANEWS, F2A, REMOVE, SELECT } from "../type";
import User from '../../api/User';
import { toggleShadow } from './toggleShadowActive';
import { getprofile } from './userAction';
import { wallet } from './userAction';
import { ErrorMessage } from 'core/Message';
import { SuccessMessage } from 'core/Message';



export function remove(flag) {
    return {
        type: REMOVE,
        payload: flag
    }
}
export function createAction(type, payload) {
    return {
        type,
        payload
    }
}


export function createLang(type, payload) {
    return {
        type,
        payload
    }
}
export function createNav(type, payload) {
    return {
        type,
        payload
    }
}
export function createSelect(payload) {
    return {
        type: SELECT,
        payload
    }
}
export function axiosLogin(payload) {
    return {
        type: AXIOSLOGIN,
        payload
    }
}
export function axiosError(payload) {
    return {
        type: AUTH_LOGIN_ERROR,
        payload
    }
}
export function axiosStatus(payload) {
    return {
        type: AUTH_LOGIN_STATUS,
        payload
    }
}

export function axios(data) {
    return async (dispatch) => {
        if (responseGoogle) {
            dispatch(responseGoogle(data))
        }

    }
}

export function axiosApi(data, t, setLoadding, setTokenR) {


    return async (dispatch) => {

        setLoadding(true)
        try {

            let res = await User.login(data)
            if (res.data.status == true) {
                SuccessMessage("Logged in successfully", t)
                dispatch(wallet(res?.data?.data?.token))
                dispatch(axiosLogin(res?.data?.data))
                dispatch(axiosStatus(res?.data?.status))
                dispatch(getprofile(res?.data?.data?.token))
                dispatch(toggleShadow(false))
            }

        } catch (error) {
            dispatch(toggleShadow(false))
            if (error.response?.data?.message == 'Có lỗi trong quá trình xử lý') {
                ErrorMessage("This functionality is upgrading, please come back in a few minutes!", t)
            } else if (error?.response?.data?.errors == 11) {
                dispatch(axiosError(error?.response?.data?.message))
                ErrorMessage(error?.response?.data?.message, t)
            } else {
                dispatch(axiosError("Email or password is incorrect!"))
                ErrorMessage("Email or password is incorrect!", t)
            }
            document.getElementById("myForm").reset();
            window.grecaptcha.reset();
            setTokenR(null)
        }
        setLoadding(false)





    }

}
export function verifyemail(data, t) {


    return async (dispatch) => {
        try {
            let res = await User.verifyEmail(data)

            if (res.data.status == true) {
                Swal.fire({
                    title: "Đăng nhập thành công",
                    icon: 'success',
                    timer: 15000
                })
                dispatch(axiosLogin(res.data.data))
                dispatch(axiosStatus(res.data.status))
            }

        } catch (error) {
            dispatch(axiosError(error.response.data.message))
            Swal.fire({
                title: t(error.response.data.message),
                icon: "error",
                timer: 15000
            }
            )
        }
    }

}

