import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory } from 'react-router';
import { useTranslate } from '../../core/translate';
import useFormValidate from '../../core/useFormValidate'
import { createA, getprofile } from '../../redux/action/userAction';
import { USERINFORMATION } from '../../redux/type';



export default function PersonalVerification() {
    const dispatch = useDispatch()
    let { t } = useTranslate()
    useEffect(() => {
        let tab = t("KYC")
        document.title = tab
        return () => {
            document.title = "Swap tobe"
        }
    }, [t]);
    let history = useHistory()
    let { axiologin } = useSelector(state => state.auth)
    const { profile } = useSelector(state => state.user)

    const verified = profile?.data?.data?.verified
    let { form, error, inputChange, check } = useFormValidate({

    }, {
        rule: {
            phone: {
                required: true,

            },
            fullname: {
                required: true,

            },
            CCCD: {
                required: true,

            },
         
            address: {
                required: true,
            }

        },
    })




    async function _login() {

        let error = check();

        if (Object.keys(error).length === 0) {
            dispatch(createA(USERINFORMATION, form))
            history.push('/kyc-image')

        }
    }
    if (!axiologin) {
        return <Redirect to='/form' />
    }

    if (verified == 1) {
        return <Redirect to='/verifiedKyc' />
    }

    if (verified == 2) {
        return <Redirect to='/verifiedKyc' />
    }
    return (
        <>


            <div className='Verification' style={{ backgroundColor: "#fff", color: '#000' }}>
                <div id="main-wrapper" className='main-dashboard'>
                    <div className="authincation section-padding">
                        <div className="container h-100">
                            <div className="row justify-content-center h-100 align-items-center" style={{ margin: '40px 0' }} >
                                <div className="col-xl-5 col-md-8">

                                    <div className="auth-form card">
                                        <div className="card-header justify-content-center">
                                            <h4 className="card-title">{t("User information")}</h4>
                                        </div>
                                        <div className="card-body">
                                            <form method="post" name="myform" className="signin_validate" action="https://demo.themefisher.com/tradio/otp-1.html">
                                                <div className="form-group">
                                                    <label>{t("Phone number")}</label>
                                                    <input
                                                        onChange={inputChange}
                                                        type="text"
                                                        className="form-control"
                                                        placeholder={`${t("Phone number")}`}
                                                        name="phone"
                                                        value={form.phone}
                                                    />

                                                </div>
                                                {error.phone && <p className=" errorLogin">{error.phone}</p>}
                                                <div className="form-group">
                                                    <label>{t("Name")}</label>
                                                    <input type='text' name='fullname' value={form.fullname} onChange={inputChange}
                                                        className="form-control"
                                                        placeholder={t("Name")} />

                                                </div>
                                                {error.fullname && <p className=" errorLogin">{error.fullname}</p>}
                                                
                                                <div className="form-group">
                                                    <label>{t("CCCD/CMND")}</label>
                                                    <input
                                                        onChange={inputChange}
                                                        type="text"
                                                        className="form-control"
                                                        placeholder={t("CCCD/CMND")}
                                                        name="CCCD"
                                                        value={form.CCCD}
                                                    />

                                                </div>
                                                {error.address && <p className=" errorLogin">{error.address}</p>}

                                             

                                                <div className="form-group">
                                                    <label>{t("Address")}</label>
                                                    <input
                                                        onChange={inputChange}
                                                        type="text"
                                                        className="form-control"
                                                        placeholder={t("Address")}
                                                        name="address"
                                                        value={form.address}
                                                    />

                                                </div>
                                                {error.address && <p className=" errorLogin">{error.address}</p>}

                                                <div className="text-center">
                                                    <a className="btn btn-success btn-block" onClick={_login} style={{ color: '#fff' }}>
                                                        {t("Complete")}
                                                    </a>
                                                </div>
                                            </form>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
