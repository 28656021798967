import CreateApi from "api/CreateApi"
import { ErrorMessage, ErrorMessageNoT, SuccessMessage, SuccessMessageNoT } from "core/Message"
import reduxToolkit from "../../core/reduxToolkit"

const initialState = {
    matrix: '',
    getHistory:''

}




export const HandleGetValueMinning = () => {
    return async (dispatch) => {
            try {
                let res = await CreateApi.CreateApiGETToken('api/viewearn/bonus', true)
                dispatch(SetMatrix(res?.data?.data))
            } catch (error) {
            }

    }
}


export const HandleCallMinning = (setOpen,setActive) => {
    return async (dispatch) => {
            try {
               
                let res = await CreateApi.CreateApiSSS('api/viewearn/bonus', true)
                setActive(res?.data?.message)
                dispatch(HandleGetValueMinning())
                dispatch(HandleGetHistory(1 ))
            } catch (error) {
                ErrorMessageNoT(error?.response?.data?.message)

            }
            setOpen(false)

    }
}

export const HandleGetHistory = (page,setLoading) => {
    return async (dispatch) => {
        if(setLoading)   setLoading(true)
            try {
                let res = await CreateApi.CreateApiS('api/viewearn/history-miner'
                ,{
                    limit:10,
                    page
                }, true)
                dispatch(GetHistory(res?.data?.data))
            } catch (error) {

            }
            if(setLoading) setLoading(false)

    }
}


let { reducer, action, TYPE } = reduxToolkit({
    name: 'minning',
    initialState,
    reducers: {

        SetMatrix: function (state, action) {
            return {
                ...state,
                matrix: action.payload
            }
        },
        GetHistory: function (state, action) {
            return {
                ...state,
                getHistory: action.payload
            }
        },

    }
})

export default reducer
export const WITHDRAW = TYPE
export const { SetMatrix ,GetHistory} = action