import MainLayout from "./layout/MainLayout"
import P2P from "./page/P2P"

import Login from "./page/login"
import Swap from "./page/Swap"
import Referral from "./page/Referral"
import Profile from "./page/profile"
import Buy from './page/P2P/component/Buy'
import Sell from "./page/P2P/component/Sell"
import CreateAds from "./page/P2P/component/CreateAds"
import CreateAdsSell from "./page/P2P/component/CreateAdsSell"
import LoginApi from "./Components/LoginApi"
import ConfirmPhone from "./page/Confirm/ConfirmPhone"
import SignUp from "./Components/SignUp"
import VerifiedKyc from "./page/KYC/VerifiedKyc"
import Transfer from "./page/transfer/Transfer"
import Camera from "./page/KYC/Camera"
import PersonalVerification from './page/KYC/PersonalVerification'
import ImageVerification from './page/KYC/ImageVerification'

import Home from "./page/Home"
import VerifyEmail from "./Components/VerifyEmail/VerifyEmail"
import Setting from "./Components/Setting"
import Await from "./page/KYC/Await"
import Lending from "./page/Lending"
import LendingHistory from "./page/Lending/component/LendingHistory"
import LendingActive from "./page/Lending/component/LendingActive"
import Staking from "./page/Staking"

import Trades from "./Components/Trades"
import LegalPolicy from "./Components/LegalPolicy"
import News from "./page/News"
import DirectNetwork from "./page/DirectNetwork"
import HistoryLendingTime from "./page/HistoryLendingTime"
import Notification from "./page/Notification"
import Sendmailforgetpassword from "./Components/Sendmailforgetpassword"
import Forgetpassword from "./Components/Forgetpassword"
import SellSearch from "./page/P2P/component/SellSearch"
import BuySearch from "./page/P2P/component/BuySearch"
// import Admin from "./page/Admin"
// import AdminLayout from "./layout/AdminLayout"
// import AdminP2P from "./page/Admin/components/AdminP2P"
// import ApproveP2P from "./page/Admin/components/ApproveP2P"
// import DepositVndAdmin from "./page/Admin/components/DepositVndAdmin"
// import WithdrawVndAdmin from "./page/Admin/components/WithdrawVndAdmin"
// import ListWithdrawVndAdmin from "./page/Admin/components/ListWithdrawVndAdmin"
// import ListDepositVndAdmin from "./page/Admin/components/ListDepositVndAdmin"
// import ListWithdrawCoinAdmin from "./page/Admin/components/ListWithdrawCoinAdmin"
// import WithdrawCoinAdmin from "./page/Admin/components/WithdrawCoinAdmin"
import MyPage from "./page/MyPage"
import CoinApply from "./page/coinApply"

import ListingApplication from "./page/ListingApplication"
// import ActiveKycAd from "page/Admin/components/ActiveKycAd"
import ContactUs from "Components/ContactUs"
// import AdminLayout2 from "layout/AdminLayout2"
// import GetAllUser from "page/Admin/components/GetAllUser"
// import SearchUser from "page/Admin/components/SearchUser"
import Login12Character from "Components/Login12Character"
import Promotion from "Components/Promotion"
// import TurnToken from "page/Admin/components/TurnToken"
import Test from "Components/Test"
// import Statistical from "page/Admin/statistical"
import Wallet from "page/Wallet"
import Support from "page/support"
import Minning from "page/Minning"
import BuyTBC from "page/BuyTBC"
import BuyToken from "page/BuyToken"
import Listting from "page/ListtingForm"







const routers = [

   
    {
        path: '/',
        component: Home,
        exact: true
    },

    {
        path: '/',
        component: MainLayout,
        auth: true,
        routers: [
            {
                path: '/ListingApplication',
                component: ListingApplication,
                exact: true
            },
            {
                path: '/mining',
                component: Minning,
                exact: true
            },
            {
                path: '/invitation',
                component: Listting,
                exact: true
            },
            {
                path: '/buy-tbc',
                component: BuyTBC,
                exact: true
            },
            {
                path: '/reserve-tobecoin',
                component: BuyTBC,
                exact: true
            },
            {
                path: '/buy-token',
                component: BuyToken,
                exact: true
            },
            {
                path: '/reserve-tobecoins',
                component: BuyToken,
                exact: true
            },
          
            {
                path: '/login-characters',
                component: Login12Character,
                exact: true
            },

            {
                path: '/promotions',
                component: Promotion,
                exact: true
            },

            {
                path: '/support',
                component: Support,
                exact: true
            },
            // {
            //     path: '/test',
            //     component: Test,
            //     exact: true
            // },
            {
                path: '/wallet',
                component: Wallet,
                exact: true
            },
            {
                path: '/contact',
                component: ContactUs,
                exact: true
            },
            {
                path: '/profile/:slug',
                component: Profile,
                exact: true

            },

            {
                path: '/profile/:slug/:slug2',
                component: Profile,
                exact: true
            },


            {
                path: '/verify-email/:slug',
                component: VerifyEmail,
            },
            {
                path: '/SignUp/:slug',
                component: SignUp,

            },
            {
                path: '/SignUp',
                component: SignUp,

            },
            {
                path: '/mypage',
                component: MyPage,

            },
            {
                path: '/verification-password',
                component: Sendmailforgetpassword,

            },
            {
                path: '/forget-password/:slug',
                component: Forgetpassword,

            },
            {
                path: '/coin-apply',
                component: CoinApply,

            },
            {
                path: '/Chuyen-khoan',
                component: Transfer
            },
            {
                path: '/kyc-basic',
                component: PersonalVerification
            },
            {
                path: '/kyc-image',
                component: ImageVerification
            },

            {
                path: '/camera',
                component: Camera
            },

            {
                path: '/await',
                component: Await
            },
            {
                path: '/verifiedKyc',
                component: VerifiedKyc
            },

            {
                path: '/buy/:slug/:index/:page',
                component: Buy,

            },
            {
                path: '/sell/:slug/:index/:page',
                component: Sell,

            },
            {
                path: '/sell/:slug/:slug2',
                component: SellSearch,

            },
            {
                path: '/buy/:slug/:slug2',
                component: BuySearch,

            },
            {
                path: '/lending',
                component: Lending,
                routers: [

                    {
                        path: '/active',
                        component: LendingActive
                    },
                    {
                        path: '/',
                        component: LendingHistory,
                        exact: true
                    },

                ]
            },
            {
                path: '/HistorylendingTime',
                component: HistoryLendingTime,

            },
            {
                path: '/staking',
                component: Staking,

            },

            {
                path: '/Notification',
                component: Notification,

            },

            {
                path: "/parent",
                component: DirectNetwork,
            },

            {
                path: '/referral',
                component: Referral
            },
            {
                path: '/new',
                component: News
            },
            {
                path: '/LegalPolicy',
                component: LegalPolicy
            },
            {
                path: '/trades',
                component: Trades
            },

            {
                path: '/Swap',
                component: Swap
            },

            {
                path: '/setting',
                component: Setting
            },
            {
                path: '/ConfirmPhone',
                component: ConfirmPhone,
            },
            {
                path: '/P2p/:slug',
                component: P2P,
                exact: true
            },
            {
                path: '/P2p',
                component: P2P,
                exact: true
            },

            {
                path: '/createads/:slug/:type',
                component: CreateAds
            },
            {
                path: '/createadssell/:slug/:type',
                component: CreateAdsSell
            },
            {
                path: '/Login',
                component: Login,

            },


            {
                path: '/form',
                component: LoginApi,
                exact: true
            },




        ]
    },




]

export default routers