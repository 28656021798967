import { Spin } from 'antd'
import CreateApi from 'api/CreateApi'
import { ErrorMessage } from 'core/Message'
import { SuccessMessage } from 'core/Message'
import { useTranslate } from 'core/translate'

import { reaload } from 'func'
import React from 'react'

import { useState } from 'react'

import { useDispatch } from 'react-redux'


export default function SubmitCharacterOff() {
    let { t } = useTranslate()
    let [loadding, setLoadding] = useState(false)
    const dispatch = useDispatch()
    let [array, setArray] = useState([])



    function handleChange(e) {
        let text = e.target.value
        setArray(text)
    }




    const handleSubmit = async (operation) => {
        setLoadding(true)
        try {
            let data = await CreateApi.CreateApiS("api/characters/turn12Characters", { characters: array?.split(" "), operation, operation }, true)
            SuccessMessage('Successfully enabled 12 characters', t)
            reaload()

        } catch (error) {
            if (error.response?.data?.message == 'Có lỗi trong quá trình xử lý') {
                ErrorMessage("This functionality is upgrading, please come back in a few minutes!", t)
            } else {
                ErrorMessage("Invalid 12 characters", t)
            }
        }
        setLoadding(false)
    }
    return (

        <div>
            <div><h4>{t('Off 12 characters')}</h4></div>
            <div className="SecretPassphrase_off">
                <input onChange={handleChange} type="text" style={{ borderColor: '#000' }} />
            </div>

            <div style={{ marginTop: '30px', display: 'flex', justifyContent: 'center' }}>
                {loadding ? <div className="example" style={{ margin: '0' }}>
                    <Spin />
                </div> :
                    <div onClick={() => handleSubmit(false)} className="SecretPassphrase_btn" style={{ color: "#fff", fontWeight: "initial", width: '100px', backgroundColor: '#087972' }}>{t("Turn off")}</div>}


            </div>
        </div>

    )
}
