import { Pagination } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useRouteMatch } from 'react-router-dom';
import { useTranslate } from '../../../core/translate';
import { createHistoryLending } from '../../../redux/action/userAction';



function LendingHistory() {
    let { historylending } = useSelector(state => state.user)
    let { t } = useTranslate()

    let token = JSON.parse(localStorage.getItem('user'))
    let { url } = useRouteMatch();
    let dispatch = useDispatch()

    const formatDateTime = (str, char) => {
        let strSplit = str.split(char)
        let value1 = `${Number(strSplit[0]) < 10 ? `0${strSplit[0]}` : strSplit[0]}`
        let value2 = `${Number(strSplit[1]) < 10 ? `${char}0${strSplit[1]}` : `${char}${strSplit[1]}`}`
        let value3 = `${Number(strSplit[2]) < 10 ? `${char}0${strSplit[2]}` : `${char}${strSplit[2]}`}`

        return value1 + value2 + value3
    }

    useEffect(() => {
        dispatch(createHistoryLending(token?.axiologin?.token, 1))
    }, [])
    function truncate(value, precision) {
        var step = Math.pow(10, precision || 0);
        var temp = Math.trunc(step * value);

        var a = temp / step;
        return a.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    function handlePageLending(page) {
        dispatch(createHistoryLending(token?.axiologin?.token, page))
    }

    return (
        <div style={{ background: " url('/img/Background_signup.png') 0% 0% / 100% 100% no-repeat", padding: '100px 0', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
            <div className="lending-history-order main-container">
                <div className="header center ">
                    <div className="header-container center">
                        <div style={{ fontFamily: 'Arial', fontWeight: 'bold' }}>TOBE CREDIT</div>
                    </div>
                </div>
                <div className="content" style={{ padding: '40px 0' }}>
                    <div>
                        <p style={{ textAlign: 'center', textTransform: 'uppercase', fontSize: '16px', fontFamily: 'Arial', color: '#087972', fontWeight: 'bold', margin: '0' }}>ToBe Credit</p>
                        <div style={{ color: '#4BBD99', fontWeight: 'bold', margin: '10px 0' }}>{t("Months :")}
                            <span> 6 {t("Months")}</span>
                        </div>
                        <div style={{ color: '#4BBD99', fontWeight: 'bold' }}>{t("Bonus/Month")}: <span>1%</span></div>
                    </div>
                    <NavLink to={`${url}/active`} style={{ marginTop: '10px' }}>
                        <button className="color-bg-green">{t("ACTIVE")}</button>
                    </NavLink>
                </div>


            </div>
            {historylending?.data?.data?.total == 0 ?
                <div className="history-interest">
                    <div className="header " style={{ background: 'linear-gradient(90.17deg,#4bbd99 1.71%,#007c91 97.5%' }}>
                        <div className="header-column">
                            <div>{t("Profit history lending")}</div>
                        </div>
                    </div>
                    <div className="content">
                        <div className="interest-list">
                            <div style={{ color: "#000", paddingTop: '20px', fontSize: '18px', fontStyle: 'italic', textAlign: 'center' }}>
                                {t("No transactions yet")}
                            </div>
                        </div>
                    </div>
                </div>

                :

                <div style={{ marginTop: '20px', maxWidth: '550px', width: '90%' }}>
                    <div className="header center " style={{ minHeight: '50px', borderRadius: '15px 15px 0 0', background: 'linear-gradient(90.17deg, #4BBD99 1.71%, #007C91 97.5%)' }}>
                        <div className="header-container center">
                            <div style={{ fontFamily: 'Arial', fontWeight: 'bold' }}>History Lending</div>
                        </div>
                    </div>

                    <div className="more-content center color-bg-T" style={{ flexDirection: 'column', borderRadius: ' 0 0 15px 15px', background: '#FFFFFF', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)' }}>
                        {historylending?.data?.data?.array?.map((e, i) => {
                            return (
                                <div style={{ marginTop: "15px", color: '#1C5D67', fontWeight: 'bold' }}>
                                    <div>{t("Amount")}: {truncate(e?.amount, 6)} USD</div>
                                    <div>{t("Source")}: {e?.source?.toUpperCase()}</div>
                                    <div>{t("Source Amount")}: {e?.source_amount}</div>
                                    <div>{t("Bonus/Month")}: {e?.bonus_percent_month}%</div>
                                    <div>{t("Created At")}: {formatDateTime(e?.created_at.substring(0, 9), "/")} {formatDateTime(e?.created_at.substring(10, 17), ":")}</div>
                                </div>
                            )
                        })}
                        <Pagination className="center"
                            style={{ paddingBottom: '20px', marginTop: '20px' }}
                            defaultCurrent={1}
                            defaultPageSize={5} //default size of page
                            onChange={handlePageLending}
                            total={historylending?.data?.data?.total} //total number of card data available
                        />


                    </div>

                </div>
            }
        </div>
    );
}

export default LendingHistory;