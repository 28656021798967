import { Spin } from 'antd'
import { SuccessMessage } from 'core/Message'
import { useTranslate } from 'core/translate'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { GetReq12CharacterRamdom } from 'redux/action/orderAction'
import { Req12CharacterTurn } from 'redux/action/orderAction'
import User from '../../../api/User'
import { ErrorMessage } from 'core/Message'
import CreateApi from 'api/CreateApi'
import { reaload } from 'func'

export default function SubmitCharacterTurn() {
    let { t } = useTranslate()
    let { list12characters } = useSelector(state => state.other)
    const dispatch = useDispatch()
    let [array, setArray] = useState([])

    let [ramdom, setRamdom] = useState()
    let [active, setActive] = useState(false)
    let [loadding, setLoadding] = useState(false)

    useEffect(() => {
        setRamdom(list12characters?.data.sort((a, b) => 0.5 - Math.random()))

        let dot = document.querySelectorAll(".SecretPassphrase_btn2")
        dot?.forEach((e) => {
            e.addEventListener('click', () => {
                e.classList.toggle("active")

            })
        })
    }, [])



    const handleGetList = (e, i) => {
        let flag = false
        let indexx
        for (let j = 0; j < array.length; j++)
            if (array[j] == e) {
                flag = true
                indexx = j
            }
        if (!flag) array.push(list12characters?.data[i])
        else array.splice(indexx, 1)
        setArray([...array])

    }


    const handleSubmit = async (operation) => {
        setLoadding(true)
        try {
            let data = await CreateApi.CreateApiS("api/characters/turn12Characters", { characters: array, operation }, true)
            SuccessMessage('Successfully enabled 12 characters', t)
            reaload()

        } catch (error) {
            if (error.response?.data?.message == 'Có lỗi trong quá trình xử lý') {
                ErrorMessage("This functionality is upgrading, please come back in a few minutes!", t)
            } else {
                ErrorMessage("Invalid 12 characters", t)
            }
        }
        setLoadding(false)
    }
    return (

        <div>
            <div className="SecretPassphrase_item">
                {array?.map((e, i) => {
                    return (
                        <div key={i} className="SecretPassphrase_btn">
                            <div  >{e}</div>
                        </div>
                    )

                })}
            </div>

            <div className="SecretPassphrase" style={{ color: '#000' }}>
                {
                    list12characters?.data?.map((e, i) => {

                        return (
                            <>
                                <div key={i} className={"SecretPassphrase_btn2"} id={i} onClick={() => handleGetList(e, i)}>{i + 1}. {e}</div>
                            </>

                        )


                    })}
            </div>
            <div style={{ marginTop: '30px', display: 'flex', justifyContent: 'center' }}>
                {loadding ? <div className="example" style={{ margin: '0' }}>
                    <Spin />
                </div> :
                    <div onClick={() => handleSubmit(true)} className="SecretPassphrase_btn" style={{ color: "#fff", fontWeight: "initial", width: '100px', backgroundColor: '#087972' }}>Bật</div>}


            </div>
        </div>

    )
}
