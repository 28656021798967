import { LIST12CHARACTERSRAMDOM } from "redux/type";
import { VALUE2FA } from "redux/type";
import { HANDLELOADDING } from "redux/type";
import { TOKENRE } from "redux/type";
import { LIST12CHARACTERS } from "redux/type";
import { DATANEWS, REFERRAL, VALUEPUPOPBUY, VALUEPUPOPSELL } from "../type";


let initState = {
    datanews: [],
    valuepupopbuy: [],
    valuepupopsell: [],

    referral: [],
    list12characters: [],
    tokenRe: [],
    value2fa: '',
    handleloadding: false
}


export default function otherReducer(state = initState, action) {
    switch (action.type) {
        case DATANEWS:
            return {
                ...state,
                datanews: action.payload,
            }
        case VALUEPUPOPBUY:
            return {
                ...state,
                valuepupopbuy: action.payload,
            }
        case VALUEPUPOPSELL:
            return {
                ...state,
                valuepupopsell: action.payload,
            }
        case REFERRAL:
            return {
                ...state,
                referral: action.payload,
            }

        case LIST12CHARACTERS:

            return {
                ...state,
                list12characters: action.payload,
            }


        case TOKENRE:

            return {
                ...state,
                tokenRe: action.payload,
            }

        case VALUE2FA:

            return {
                ...state,
                value2fa: action.payload,
            }

        case HANDLELOADDING:

            return {
                ...state,
                handleloadding: action.payload,
            }







        default:
            return state;
    }
}