import React, {  useEffect, useState } from 'react'

import BgBuybtc from 'assets/img/svg/bg-buybtc.svg'
import {  FormControl, MenuItem, Select, TextField } from '@mui/material'
import { OnlyNumber } from 'core/hook/ValidateOnly'
import Price from './components/Price'
import Submit from './components/Submit'


import { formatMoney } from 'core/hook/formarMoney'
import { getwalletcoin } from 'core/GetWalletCoin'
import { useDispatch, useSelector } from 'react-redux'
import { HandleGetInfoTBC, HandlePackageTBC } from 'redux/reducer/buytbcReducer'
import { port } from 'port'
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min'
import History from './components/History'
import { useTranslate } from 'core/translate'







export default function BuyToken() {
    let [valueInput, setValueInput] = useState({
        amount:'',
        symbol:'USDT'
    })
    const user = JSON.parse(localStorage.getItem('user'))
    const {t} = useTranslate()
    const dispatch = useDispatch()
    const {getInfoTBC,getPackage} = useSelector(state => state.buytbc)
    let { getwallet } = useSelector(state => state.user)
  
    useEffect(() =>{
        if(getPackage?.length == 0)   dispatch(HandlePackageTBC())
        dispatch(HandleGetInfoTBC())
    },[])
    const handleChange = (e) => {
        let {value, name} = e.target
      let ValuePriceyouset = OnlyNumber(value)
        setValueInput({...valueInput, [name]:ValuePriceyouset})
    }

    const handleChangeSelect = (e) => {
        let {value, name } = e.target
        setValueInput({...valueInput, [name]:value})

    }
    if(!user) return  <Redirect to='/form' />
    return (

        <div className='buyToken' style={{background:`url(${BgBuybtc})`}} >
            <div className="container">
                <div className="buyToken-main">
                    <div className="buyToken-main-context">
                        <h3>{t("Reserve TBC")}</h3>
                        <div>
                            <span>{t("Choose coin")}</span>
                            <FormControl fullWidth>
                     
                            <Select labelId='demo-simple-select-label' id="demo-simple-select" value={valueInput?.symbol}    name='symbol' label="Coin" onChange={handleChangeSelect}  style={{display:'flex', alignItems:'center',gap:'10px'}}>
                                {getPackage?.map((e,i) => {
                                    return  <MenuItem value={e?.symbol}  style={{display:'flex', alignItems:'center',gap:'10px', backgroundColor:'#fff'}}> <img src={`${port}images/${e?.symbol}.png`} alt=""  style={{width:'25px',marginTop:'-5px'}}/> {e?.symbol}</MenuItem>
                                   
                                })}
                              
                            </Select>
                            </FormControl>
                        </div>
                        <div>
                            <span>{t("Amount")} of TBC</span>
                            <FormControl fullWidth>
                                <TextField id="outlined-basic" name='amount' value={valueInput?.amount}  variant='outlined' onChange={handleChange} />
                           </FormControl>
                        </div>
                        
                        <Price valueInput={valueInput} />

                     
                        <div style={{color:'#235161', fontWeight:'700'}}>
                             {t("Max available")}:  {formatMoney(String(getwalletcoin(valueInput?.symbol, getwallet), 6))} {valueInput?.symbol}
                        </div>
                  
                        <div>
                           <p style={{margin: 0, fontWeight:'700'}}>{t("Price")} {getInfoTBC?.price}$/TBC</p>
                        </div>
                      
                   
                     <Submit valueInput={valueInput}/>
                    </div>
                    
                    <History />

                </div>
            </div>
        </div>

 
    )
}
