import React, { useEffect } from 'react'
import { useState } from 'react'
import { useRef } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { createOther } from 'redux/action/orderAction'
import { TOKENRE } from 'redux/type'
import { TextField } from '@mui/material';
import { VALUE2FA } from 'redux/type'

export default function RecaptchaF2A() {

    let { value2fa } = useSelector(state => state.other)
    let reRef = useRef()
    const { profile } = useSelector(state => state.user)
    const twofa = profile?.data?.data?.enabled_twofa

    let dispatch = useDispatch()


    const [token, setStateToken] = useState('')
    function ErroRepcatcha(token) {
        if (token) {
            dispatch(createOther(TOKENRE, token))
        } else {
            dispatch(createOther(TOKENRE, ''))
        }

    }
    const handleChange2FA = (e) => {
        dispatch(createOther(VALUE2FA, e.target.value))
    }
    return (
        <div style={{ margin: '30px 0' }}>
            {twofa == 0 ?
                <ReCAPTCHA
                    ref={reRef}
                    sitekey="6LdflhodAAAAAJXLVHcko0p8_6QYYTXFTaOwPUA4"
                    onChange={async (token) => { ErroRepcatcha(token) }}
                    onExpired={(e) => {
                        setStateToken("");
                    }}
                />
                :
                <div >
                    <TextField fullWidth label="F2A" id="fullWidth" value={value2fa} onChange={(e) => handleChange2FA(e)} style={{ zIndex: '0' }} />
                </div>}


        </div>
    )
}
