import { Spin } from 'antd'
import { useTranslate } from 'core/translate'
import React from 'react'
import { useEffect } from 'react'
import { useRef } from 'react'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { createAction } from 'redux/action/authAction'
import { createOther } from 'redux/action/orderAction'
import { createSearchSellNow } from 'redux/action/searchAction'
import { createSearchBuyNow } from 'redux/action/searchAction'
import { createSearch } from 'redux/action/searchAction'
import { INDEXBUY } from 'redux/type'
import { VALUEPUPOPBUY } from 'redux/type'
import { SEARCHMESSAGE } from 'redux/type'
import { SEARCHMESSAGE2 } from 'redux/type'
import { POPUPBUY } from 'redux/type'
import { POPUPSELL } from 'redux/type'
import { TYPETOTAL } from 'redux/type'
import { VALUEPUPOPSELL } from 'redux/type'
import { INDEXSELL } from 'redux/type'


export default function SeacrchDataSell(reattime) {
    let { t } = useTranslate()
    let { slug } = useParams()
    let [value, setValue] = useState('')
    let [value2, setValue2] = useState('')
    let [type, setType] = useState(0)
    let [trandeVND2, setTrandeVND2] = useState(false)
    let [trandeVND, setTrandeVND] = useState(false)
    let [change, setChange] = useState(false)
    let [show, setShow] = useState('')
    let refClick = useRef()
    let refClick2 = useRef()
    let { searchbuynow, searchmessage, searchsellnow, searchmessage2 } = useSelector(state => state.search)

    let [loadding, setLoadding] = useState(false)
    let [change2, setChange2] = useState(false)
    let [show2, setShow2] = useState('')

    let [textSearch, setTextSearch] = useState(false)
    let [name, setName] = useState(`${slug?.toUpperCase()}`)
    const dispatch = useDispatch()
    let { transactionform, vndusd, exchange } = useSelector(state => state.home)

    function handleChangeText() {
        setTextSearch(true)
    }
    const separateNumberWithDot = (number) => {
        if (number === 0) {
            return '';
        }
        return number.toLocaleString('en-GB');
    };
    const numberOnly = (str) => {
        return str.replace(/[- #*;,.<>\{\}\[\]\\\/]/gi, '');
    };
    function handleText(e) {
        let name = e.target.name
        let type = e.target.id
        setName(name)
        setType(type)
        setTextSearch(false)
        dispatch(createSearch(TYPETOTAL, type))
        setValue('')
        setValue2('')
    }
    function truncate(value, precision) {
        var step = Math.pow(10, precision || 0);
        var temp = Math.trunc(step * value);

        var a = temp / step;
        return a.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    function backClick2() {
        setShow2('')
    }

    useEffect(() => {

        if (value) {
            setLoadding(true)
            const searchTime = setTimeout(() => {
                setLoadding(false)
                if (type == 1) {
                    let usd = 0
                    if (value) {
                        usd = value.replace(/\,/g, "");
                    }
                    dispatch(createSearchBuyNow(usd, type, slug?.toUpperCase()))
                } else {
                    dispatch(createSearchBuyNow(value, type, slug?.toUpperCase()))
                }

            }, 300);
            return () => clearTimeout(searchTime)
        }
    }, [value, slug])
    useEffect(() => {
        if (value2) {
            setLoadding(true)
            const searchTime = setTimeout(() => {
                setLoadding(false)
                if (type == 1) {
                    let usd = 0
                    if (value2) {
                        usd = value2.replace(/\,/g, "");
                    }

                    dispatch(createSearchSellNow(parseInt(usd), type, slug?.toUpperCase()))
                } else {
                    dispatch(createSearchSellNow(value2, type, slug?.toUpperCase()))
                }


            }, 300);
            return () => clearTimeout(searchTime)
        }
    }, [value2, slug])
    function handlePopupSell() {

        dispatch(createAction(POPUPSELL, true))
    }
    function handleChange(e) {

        let value = e.target.value
        let value0 = numberOnly(value);
        const value1 = Number(value0);
        dispatch(createOther(VALUEPUPOPBUY, value))
        if (value) {
            if (!isNaN(value1)) {
                setChange(true)
                if (type == 1) {
                    setValue(separateNumberWithDot(value1))
                } else {
                    setValue(value)
                }

            }
        } else {
            setChange(false)
            setValue('')
            setLoadding(false)
        }

    }
    function handleChange2(e) {
        let value2 = e.target.value
        let value0 = numberOnly(value2);
        const value1 = Number(value0);
        dispatch(createOther(VALUEPUPOPSELL, value2))
        if (value2) {
            if (!isNaN(value1)) {
                if (type == 1) {
                    setValue2(separateNumberWithDot(value1))

                } else {
                    setValue2(value2)
                }
                setChange2(true)

            }
        } else {
            setChange2(false)
            setValue2('')
        }
    }

    function changeTandeVND() {
        setTrandeVND(!trandeVND)
    }
    function changeTandeVND2() {
        setTrandeVND2(!trandeVND2)
    }
    function indexSell(i) {
        dispatch(createSearch(INDEXSELL, i))
    }
    function indexBuy(i) {
        dispatch(createSearch(INDEXBUY, i))
    }
    function buyClick() {
        refClick = setShow('show')
    }
    function sellClick() {
        refClick2 = setShow2('show')

    }
    function backClick() {
        dispatch(createAction(POPUPBUY, false))

        setShow('')
    }
    function handlePopupBuy() {
        dispatch(createAction(POPUPBUY, true))
    }

    return (
        <>
            <div ref={refClick} className={`${show} ${show2} buycoin css-1dbjc4n r-1g2xzmm`} style={{ paddingBottom: '24px' }}>
                <div className="css-1dbjc4n" data-testid="remi-bid-ask-info">
                    <div className=" row two-col-compact">
                        <div className=" left-col col col-12 col-md-6 col-sm-6">
                            <div className="css-1dbjc4n r-z2wwpe r-ymttw5 r-1yzf0co r-1g2xzmm" data-testid="ads-rate-panel-wrapper" style={{ backgroundColor: 'rgba(0, 124, 145, 0.54)' }}>
                                <div className="css-1dbjc4n r-13awgt0 r-eqz5dr r-1g2xzmm" style={{ marginBottom: '-16px' }}>
                                    <div className="css-1dbjc4n r-1g2xzmm" style={{ paddingBottom: '16px' }}>
                                        <div className="css-1dbjc4n r-13awgt0 r-eqz5dr r-v55p34 r-1g2xzmm">
                                            <div className="css-1dbjc4n r-1g2xzmm" style={{ paddingBottom: '8px' }}>
                                                <div className="css-1dbjc4n r-1awozwy r-13awgt0 r-18u37iz">
                                                    <div className="css-1dbjc4n" data-testid="icon-flag" style={{ height: '14px', width: '14px' }}>
                                                        <svg width={14} height={14} viewBox="0 0 32 32" fill="#ffff">
                                                            <path d="M5.728 4.576q0 1.28-1.152 1.952v22.624q0 .224-.16.384T4 29.728H2.848q-.224 0-.384-.192t-.192-.384V6.528q-1.12-.672-1.12-1.952 0-.96.672-1.632t1.6-.672 1.632.672.672 1.632zM32 5.728v13.6q0 .448-.224.704t-.704.48q-3.84 2.08-6.592 2.08-1.088 0-2.208-.384t-1.92-.864-2.08-.864-2.528-.384q-3.424 0-8.288 2.592-.32.16-.608.16-.448 0-.8-.32t-.32-.8V8.448q0-.544.544-.96.384-.256 1.408-.768 4.224-2.144 7.52-2.144 1.92 0 3.584.512t3.904 1.568q.672.352 1.568.352.96 0 2.08-.384t1.984-.832 1.568-.832.96-.384q.48 0 .8.32t.352.832z" />
                                                        </svg>
                                                    </div>
                                                    <div className="css-1dbjc4n r-1wbh5a2 r-1777fci r-1jkjb">
                                                        <div dir="auto" className=" text-white css-901oao r-jwli3a r-320f6p r-ubezar r-oxtfae r-135wba7">{t("Selling price:")}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="css-1dbjc4n r-1g2xzmm" style={{ paddingBottom: '8px' }}>
                                                <div dir="auto" className=" css-901oao  r-320f6p r-adyw6z r-vw2c0b r-oxtfae r-37tt59">
                                                    <span>
                                                        <span className="amount">



                                                            {
                                                                `${truncate(((reattime.price * vndusd) / 100) * exchange + (reattime.price * vndusd), 0)} VND`
                                                            }
                                                        </span>
                                                        {/* <span className="currency text-white">VND</span> */}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="css-1dbjc4n r-1g2xzmm" style={{ paddingBottom: '16px' }}>
                                        <div className="css-1dbjc4n">
                                            <div className="css-1dbjc4n r-z2wwpe r-1udh08x">
                                                <div onClick={buyClick} role="button" aria-busy="false" data-focusable="true" tabIndex={0} className="buttonC css-18t94o4 css-1dbjc4n r-1loqt21 r-1otgn73 r-1i6wzkk r-lrvibr" data-testid="btn-ask" style={{ transitionDuration: '0s' }}>
                                                    <div className="button_main css-1dbjc4n r-1awozwy r-k200y r-z2wwpe r-18u37iz r-1777fci r-5njf8e" style={{ backgroundColor: '#4BBD99', borderWidth: '0px', minHeight: '40px', padding: '8px 20px' }}>
                                                        <div dir="auto" className="css-901oao r-jwli3a r-320f6p r-ubezar r-vw2c0b r-oxtfae r-135wba7" style={{ paddingTop: '0px', paddingBottom: '0px' }}>{t("Buy now")}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col col-12 col-md-6 col-sm-6 right-col">
                            <div className="css-1dbjc4n r-z2wwpe r-ymttw5 r-1yzf0co r-1g2xzmm" data-testid="ads-rate-panel-wrapper" style={{ backgroundColor: 'rgba(0, 124, 145, 0.54)' }}>
                                <div className="css-1dbjc4n r-13awgt0 r-eqz5dr r-1g2xzmm" style={{ marginBottom: '-16px' }}>
                                    <div className="css-1dbjc4n r-1g2xzmm" style={{ paddingBottom: '16px' }}>
                                        <div className="css-1dbjc4n r-13awgt0 r-eqz5dr r-v55p34 r-1g2xzmm">
                                            <div className="css-1dbjc4n r-1g2xzmm" style={{ paddingBottom: '8px' }}>
                                                <div className="css-1dbjc4n r-1awozwy r-13awgt0 r-18u37iz">
                                                    <div className="css-1dbjc4n" data-testid="icon-flag" style={{ height: '14px', width: '14px' }}>
                                                        <svg width={14} height={14} viewBox="0 0 32 32" fill="#ffff">
                                                            <path d="M5.728 4.576q0 1.28-1.152 1.952v22.624q0 .224-.16.384T4 29.728H2.848q-.224 0-.384-.192t-.192-.384V6.528q-1.12-.672-1.12-1.952 0-.96.672-1.632t1.6-.672 1.632.672.672 1.632zM32 5.728v13.6q0 .448-.224.704t-.704.48q-3.84 2.08-6.592 2.08-1.088 0-2.208-.384t-1.92-.864-2.08-.864-2.528-.384q-3.424 0-8.288 2.592-.32.16-.608.16-.448 0-.8-.32t-.32-.8V8.448q0-.544.544-.96.384-.256 1.408-.768 4.224-2.144 7.52-2.144 1.92 0 3.584.512t3.904 1.568q.672.352 1.568.352.96 0 2.08-.384t1.984-.832 1.568-.832.96-.384q.48 0 .8.32t.352.832z" />
                                                        </svg>
                                                    </div>
                                                    <div className="css-1dbjc4n r-1wbh5a2 r-1777fci r-1jkjb">
                                                        <div dir="auto" className="text-white css-901oao r-jwli3a r-320f6p r-ubezar r-oxtfae r-135wba7">{t("Buying price:")}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="css-1dbjc4n r-1g2xzmm" style={{ paddingBottom: '8px' }}>
                                                <div dir="auto" className="css-901oao r-8bscmv r-320f6p r-adyw6z r-vw2c0b r-oxtfae r-37tt59">
                                                    <span>
                                                        {
                                                            `${truncate(reattime?.price * vndusd, 0)} VND`
                                                        }
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="css-1dbjc4n r-1g2xzmm" style={{ paddingBottom: '16px' }}>
                                        <div className="css-1dbjc4n">
                                            <div className="css-1dbjc4n r-z2wwpe r-1udh08x">
                                                <div onClick={sellClick} role="button" aria-busy="false" data-focusable="true" tabIndex={0} className="buttonC css-18t94o4 css-1dbjc4n r-1loqt21 r-1otgn73 r-1i6wzkk r-lrvibr" data-testid="btn-bid" style={{ transitionDuration: '0s' }}>
                                                    <div className="button_main css-1dbjc4n r-1awozwy r-k200y r-z2wwpe r-18u37iz r-1777fci r-5njf8e" style={{ backgroundColor: '#fb7705', borderWidth: '0px', minHeight: '40px', padding: '8px 20px' }}>
                                                        <div className="css-901oao r-jwli3a r-320f6p r-ubezar r-vw2c0b r-oxtfae r-135wba7" style={{ paddingTop: '0px', paddingBottom: '0px' }} >{t("Sell now")}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <PriceP2P /> */}
                </div>
            </div>
            <div ref={refClick} className={` actionbuycoin css-1dbjc4n r-1g2xzmm ${show}`} style={{ paddingBottom: '24px' }}>
                <div className="css-1dbjc4n r-eqz5dr r-1g2xzmm" data-testid="quick-trade" style={{ marginBottom: '-16px' }}>
                    <div className="css-1dbjc4n r-1g2xzmm" style={{ paddingBottom: '16px' }}>
                        <div className="css-1dbjc4n r-eqz5dr r-1g2xzmm" style={{ marginBottom: '-24px' }}>
                            <div className="css-1dbjc4n r-1g2xzmm" style={{ paddingBottom: '24px' }}>
                                <div className="css-1dbjc4n r-eqz5dr r-1g2xzmm" style={{ marginBottom: '-6px' }}>
                                    <div className="css-1dbjc4n r-1g2xzmm" style={{ paddingBottom: '6px' }}>
                                        <div className="css-1dbjc4n r-18u37iz r-1wtj0ep r-1g2xzmm">
                                            <div className=" css-901oao r-jwli3a r-320f6p r-ubezar r-oxtfae r-135wba7">Amount: </div>

                                        </div>
                                    </div>
                                    <div className="css-1dbjc4n r-1g2xzmm" style={{ paddingBottom: '6px' }}>
                                        <div className="css-1dbjc4n" data-testid="coin-amount">
                                            <div className="css-1dbjc4n r-eqz5dr r-v55p34 r-1g2xzmm" data-testid="text-input text-input-lg">
                                                <div className="css-1dbjc4n r-1g2xzmm" style={{ paddingBottom: '8px' }}>
                                                    <div className="css-1dbjc4n r-1g2xzmm">
                                                        <div className="css-1dbjc4n r-1g2xzmm" data-testid="undefined has-1-support-items">
                                                            <input onChange={handleChange} value={value} name="amount" placeholder={`Enter ${slug?.toUpperCase()} amount to buy`} autoComplete="off" type="text" style={{ color: '#000' }} />
                                                            <div className="btc css-1dbjc4n r-1awozwy r-1p0dtai r-6koalj r-18u37iz r-15ighfx r-ywje51 r-u8s1d r-zchlnj r-ipm5af r-1jaylin" data-testid="input-support">
                                                                <div className="css-1dbjc4n r-7er5f2 r-1b7u577" data-testid="currency" onClick={handleChangeText} style={{ border: '1px solid rgb(235, 236, 240)', padding: '5px', marginTop: '-5px', backgroundColor: '#087972', cursor: 'pointer' }}>
                                                                    <div dir="auto" className="btc-color css-901oao r-1814a8r r-320f6p r-7cikom r-vw2c0b r-1s2g6fe r-t9a87b" style={{ color: "#fff" }}>{slug.toUpperCase()}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {loadding ? <div className="example">
                                                            <Spin />
                                                        </div> : ''}
                                                        <div></div>
                                                        {textSearch ? <div className="searchCoin" style={{ backgroundColor: "#fff", padding: '15px', borderRadius: '4px', marginTop: '10px' }}>
                                                            <div className="searchCoins">
                                                                <p style={{ color: 'rgb(94, 88, 98)', fontWeight: '500', marginBottom: '15px' }}>Nhập bằng</p>
                                                                <div className="searchCoin_list" style={{ marginTop: '20px' }}>
                                                                    <a style={{ border: '1px solid rgb(94, 88, 98)', borderRadius: '4px', color: 'rgb(94, 88, 98)', fontWeight: '500', padding: '10px', marginRight: '10px' }} id={1} name={"VND"} onClick={(e) => handleText(e)}>VND</a>
                                                                    <a style={{ border: '1px solid rgb(94, 88, 98)', borderRadius: '4px', color: 'rgb(94, 88, 98)', fontWeight: '500', padding: '10px 12px' }} id={0} name={slug?.toUpperCase()} onClick={(e) => handleText(e)}>{slug?.toUpperCase()}</a>
                                                                </div>
                                                            </div>
                                                        </div> : ""}

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="css-1dbjc4n r-1g2xzmm" style={{ paddingBottom: '6px' }} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={` ${change ? "contentcoin active" : "contentcoin"}`}>
                        {loadding ? "" : <>

                            {searchmessage ? <p style={{ fontSize: '16px', color: 'red', fontWeight: '500', fontStyle: "italic", fontFamily: 'Arial', marginBottom: '10px' }}>{searchmessage}</p> : ''}
                            {searchbuynow.map((e, i) => {
                                let value0 = 0
                                let value1 = 0
                                if (value) {
                                    value0 = value.replace(/\,/g, "");
                                    value1 = parseInt(value0)
                                }
                                return (
                                    <>

                                        <div className="coin-content css-1dbjc4n r-1g2xzmm" style={{ paddingBottom: 6 }} onClick={() => indexBuy(i)} key={i}>
                                            <div className="css-1dbjc4n r-1a3r4sm r-1hvb8e0 r-mm0ijv r-ms8t9i r-1jkafct r-z4f626 r-1loqt21 r-ymttw5 r-1yzf0co">
                                                <div className="css-1dbjc4n r-1awozwy r-18u37iz r-1wtj0ep r-1g2xzmm">
                                                    <div className="css-1dbjc4n r-eqz5dr r-1g2xzmm" style={{ marginBottom: "-4px" }}>
                                                        <div className="css-1dbjc4n r-1g2xzmm" style={{ paddingBottom: 4 }}>
                                                            <div className="css-1dbjc4n r-1awozwy r-18u37iz r-2eo2mk r-1g2xzmm">
                                                                <div className="css-1dbjc4n r-1wbh5a2 r-1hvjb8t r-1g2xzmm">
                                                                    <div className="css-901oao r-homxoj r-1loqt21 r-320f6p r-7cikom r-vw2c0b r-1s2g6fe r-t9a87b" onClick={changeTandeVND}>
                                                                        <span className="css-901oao css-16my406 r-1rf7ul2">

                                                                            {!trandeVND ? `${truncate(e.amount_exchange_usd * e.amount_exchange_vnd + (e.amount_exchange_usd * e.amount_exchange_vnd) / 100 * e.percent)}` : `${truncate(e.amount_exchange_vnd)}`}
                                                                        </span>
                                                                        <span className="css-901oao css-16my406 r-1814a8r r-tsynxw">
                                                                            {" "}
                                                                            {!trandeVND ? 'VND/' : 'USD/'}

                                                                        </span>
                                                                        <span className="css-901oao css-16my406 r-1814a8r">{slug?.toUpperCase()}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="css-1dbjc4n r-1wbh5a2 r-1hvjb8t r-1g2xzmm" onClick={handlePopupBuy}>
                                                                    <div className="css-1dbjc4n r-1awozwy r-18u37iz r-17grq5a r-1g2xzmm">
                                                                        <div className="css-1dbjc4n r-1wbh5a2 r-1pyaxff r-1g2xzmm">
                                                                            <div className="css-901oao r-homxoj r-320f6p r-7cikom r-1s2g6fe r-t9a87b" >
                                                                                <span className="css-901oao css-16my406 r-1mdqdya r-320f6p r-ubezar r-oxtfae r-135wba7">
                                                                                    {" "}
                                                                                    –{" "}
                                                                                </span>
                                                                                <span className="css-901oao css-16my406 r-1mdqdya r-320f6p r-ubezar r-oxtfae r-135wba7">
                                                                                    qua{" "}
                                                                                </span>
                                                                                {transactionform?.map((el, i) => {
                                                                                    return (

                                                                                        <span className="css-901oao css-16my406 r-z4f626 r-320f6p r-ubezar r-oxtfae r-135wba7">
                                                                                            {e.type_exchange == 1 && el.name == 'BOSS VND Wallet' ? el.name : ''}
                                                                                        </span>
                                                                                    )
                                                                                })}

                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="css-1dbjc4n r-1g2xzmm" style={{ paddingBottom: 4 }} >
                                                            <div className="css-1dbjc4n r-eqz5dr r-1g2xzmm" style={{ marginBottom: "-4px" }} >
                                                                <div className="css-1dbjc4n r-1g2xzmm" style={{ paddingBottom: 4 }}>
                                                                    <div className="css-1dbjc4n r-18u37iz r-1g2xzmm">
                                                                        <a
                                                                            href={`/profile/${slug}`}
                                                                            className="text-base"
                                                                            store="[object Object]"
                                                                        >
                                                                            <div className="css-1dbjc4n r-1awozwy r-18u37iz">
                                                                                <div
                                                                                    className="css-1dbjc4n"
                                                                                    data-testid="icon-online"
                                                                                    style={{ height: 8, width: 8 }}
                                                                                >
                                                                                    <svg
                                                                                        width={8}
                                                                                        height={8}
                                                                                        viewBox="0 0 8 8"
                                                                                        fill="#5E5862"
                                                                                    >
                                                                                        <circle
                                                                                            cx={117}
                                                                                            cy={143}
                                                                                            r={4}
                                                                                            transform="translate(-113 -139)"
                                                                                            fill="#83BE69"
                                                                                            fillRule="evenodd"
                                                                                        />
                                                                                    </svg>
                                                                                </div>
                                                                                <div className="css-1dbjc4n r-1wbh5a2 r-1777fci r-1jkjb">
                                                                                    <div
                                                                                        dir="auto"
                                                                                        className="css-901oao r-homxoj r-320f6p r-7cikom r-1s2g6fe r-t9a87b"
                                                                                    >
                                                                                        {e.username}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="css-1dbjc4n r-1g2xzmm" style={{ paddingBottom: 4 }} onClick={handlePopupBuy}>
                                                            <div className="css-1dbjc4n r-1g2xzmm" data-testid="total-amount">
                                                                <div className="css-1dbjc4n r-1awozwy r-18u37iz r-17grq5a r-1g2xzmm">
                                                                    <div className="css-1dbjc4n r-1wbh5a2 r-1pyaxff r-1g2xzmm">
                                                                        <div
                                                                            dir="auto"
                                                                            className="css-901oao r-1mdqdya r-320f6p r-ubezar r-oxtfae r-135wba7"
                                                                        >
                                                                            {t("Total amount:")}
                                                                        </div>
                                                                    </div>
                                                                    <div className="css-1dbjc4n r-1wbh5a2 r-1pyaxff r-1g2xzmm">
                                                                        <div
                                                                            dir="auto"
                                                                            className="css-901oao r-z4f626 r-320f6p r-ubezar r-vw2c0b r-oxtfae r-135wba7"

                                                                        >
                                                                            {type == 0 ? `${truncate(value * (e.amount_exchange_usd * e.amount_exchange_vnd + (e.amount_exchange_usd * e.amount_exchange_vnd) / 100 * e.percent))}` : value1 / (e.amount_exchange_usd * e.amount_exchange_vnd + (e.amount_exchange_usd * e.amount_exchange_vnd) / 100 * e.percent)}

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="css-1dbjc4n r-1g2xzmm" style={{ paddingBottom: 4 }} onClick={handlePopupBuy}>
                                                            <div className="css-1dbjc4n r-1g2xzmm" data-testid="completion-time">
                                                                <div className="css-1dbjc4n r-1awozwy r-18u37iz r-17grq5a r-1g2xzmm">
                                                                    <div className="css-1dbjc4n r-1wbh5a2 r-1pyaxff r-1g2xzmm">
                                                                        <div
                                                                            dir="auto"
                                                                            className="css-901oao r-1mdqdya r-320f6p r-1b43r93 r-uz4sb0 r-rjixqe"
                                                                        >
                                                                            {t("Completion time:")}
                                                                        </div>
                                                                    </div>
                                                                    <div className="css-1dbjc4n r-1wbh5a2 r-1pyaxff r-1g2xzmm">
                                                                        <div
                                                                            dir="auto"
                                                                            className="css-901oao r-z4f626 r-320f6p r-1b43r93 r-uz4sb0 r-rjixqe"
                                                                        >
                                                                            ~0s
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div onClick={handlePopupBuy} className="css-1dbjc4n r-z2wwpe r-1udh08x buynow">
                                                        <div className="css-18t94o4 css-1dbjc4n r-1loqt21 r-1otgn73 r-1i6wzkk r-lrvibr" style={{ transitionDuration: "0s" }}>
                                                            <div className="css-1dbjc4n r-1awozwy r-k200y r-z2wwpe r-18u37iz r-1777fci r-5njf8e"
                                                                style={{
                                                                    backgroundColor: "rgb(8, 121, 114)",
                                                                    borderWidth: 0,
                                                                    minHeight: 40,
                                                                    padding: "8px 16.1px"
                                                                }}
                                                            >
                                                                <div className="css-901oao r-jwli3a r-320f6p r-ubezar r-oxtfae r-135wba7"
                                                                    style={{ paddingTop: 0, paddingBottom: 0 }} >
                                                                    {t("Buy now")}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )
                            })}
                        </>
                        }


                    </div>

                    <div className="css-1dbjc4n r-1g2xzmm" style={{ paddingBottom: '16px' }}>
                        <div className="coin2 css-1dbjc4n r-1g2xzmm">
                            <a href="/btc/ae/sell" className="change-trade-type text-white" store="[object Object]">{t("Looking to sell")} {slug?.toUpperCase()}?</a>
                            <a href="#" onClick={backClick} className='text-white quaylai'>{t("Back")}</a>
                        </div>
                    </div>
                </div>
            </div>
            <div ref={refClick2} className={` actionbuycoin css-1dbjc4n r-1g2xzmm ${show2}`} style={{ paddingBottom: '24px' }}>
                <div className="css-1dbjc4n r-eqz5dr r-1g2xzmm" data-testid="quick-trade" style={{ marginBottom: '-16px' }}>
                    <div className="css-1dbjc4n r-1g2xzmm" style={{ paddingBottom: '16px' }}>
                        <div className="css-1dbjc4n r-eqz5dr r-1g2xzmm" style={{ marginBottom: '-24px' }}>
                            <div className="css-1dbjc4n r-1g2xzmm" style={{ paddingBottom: '24px' }}>
                                <div className="css-1dbjc4n r-eqz5dr r-1g2xzmm" style={{ marginBottom: '-6px' }}>
                                    <div className="css-1dbjc4n r-1g2xzmm" style={{ paddingBottom: '6px' }}>
                                        <div className="css-1dbjc4n r-18u37iz r-1wtj0ep r-1g2xzmm">
                                            <div className=" css-901oao r-jwli3a r-320f6p r-ubezar r-oxtfae r-135wba7">Amount: </div>

                                        </div>
                                    </div>
                                    <div className="css-1dbjc4n r-1g2xzmm" style={{ paddingBottom: '6px' }}>
                                        <div className="css-1dbjc4n" data-testid="coin-amount">
                                            <div className="css-1dbjc4n r-eqz5dr r-v55p34 r-1g2xzmm" data-testid="text-input text-input-lg">
                                                <div className="css-1dbjc4n r-1g2xzmm" style={{ paddingBottom: '8px' }}>
                                                    <div className="css-1dbjc4n r-1g2xzmm">
                                                        <div className="css-1dbjc4n r-1g2xzmm" data-testid="undefined has-1-support-items">
                                                            <input onChange={handleChange2} value={value2} name="amount" placeholder={`Enter ${slug?.toUpperCase()} amount to buy`} autoComplete="off" type="text" style={{ color: '#000' }} />
                                                            <div className="btc css-1dbjc4n r-1awozwy r-1p0dtai r-6koalj r-18u37iz r-15ighfx r-ywje51 r-u8s1d r-zchlnj r-ipm5af r-1jaylin" data-testid="input-support">
                                                                <div className="css-1dbjc4n r-7er5f2 r-1b7u577" data-testid="currency" style={{ border: '1px solid rgb(235, 236, 240)', padding: '5px', marginTop: '-5px', backgroundColor: '#087972', cursor: "pointer" }} onClick={handleChangeText}>
                                                                    <div dir="auto" className="btc-color css-901oao r-1814a8r r-320f6p r-7cikom r-vw2c0b r-1s2g6fe r-t9a87b" style={{ color: "#fff" }}>{slug.toUpperCase()}</div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                        {loadding ? <div className="example">
                                                            <Spin />
                                                        </div> : ''}
                                                        <div></div>
                                                        {textSearch ? <div className="searchCoin" style={{ backgroundColor: "#fff", padding: '15px', borderRadius: '4px', marginTop: '10px' }}>
                                                            <div className="searchCoins">
                                                                <p style={{ color: 'rgb(94, 88, 98)', fontWeight: '500' }}>Nhập bằng</p>
                                                                <div className="searchCoin_list">
                                                                    <a style={{ border: '1px solid rgb(94, 88, 98)', borderRadius: '4px', color: 'rgb(94, 88, 98)', fontWeight: '500', padding: '10px', marginRight: '10px' }} id={1} name={"VND"} onClick={(e) => handleText(e)}>VND</a>
                                                                    <a style={{ border: '1px solid rgb(94, 88, 98)', borderRadius: '4px', color: 'rgb(94, 88, 98)', fontWeight: '500', padding: '10px 12px' }} id={0} name={slug?.toUpperCase()} onClick={(e) => handleText(e)}>{slug?.toUpperCase()}</a>
                                                                </div>
                                                            </div>
                                                        </div> : ""}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="css-1dbjc4n r-1g2xzmm" style={{ paddingBottom: '6px' }} />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={` ${change2 ? "contentcoin active" : "contentcoin"}`}>

                        {loadding ? "" : <>

                            {searchmessage2 ? <p style={{ fontSize: '16px', color: 'red', fontWeight: '500', fontStyle: "italic", fontFamily: 'Arial', marginBottom: '10px' }}>{searchmessage}</p> : ''}
                            {searchsellnow.map((e, i) => {
                                let value0 = 0
                                let value1 = 0
                                if (value2) {
                                    value0 = value2.replace(/\,/g, "");
                                    value1 = parseInt(value0)
                                }
                                return (
                                    <>
                                        <div className="coin-content css-1dbjc4n r-1g2xzmm" style={{ paddingBottom: 6 }} onClick={() => indexSell(i)}>
                                            <div className="css-1dbjc4n r-1a3r4sm r-1hvb8e0 r-mm0ijv r-ms8t9i r-1jkafct r-z4f626 r-1loqt21 r-ymttw5 r-1yzf0co">
                                                <div className="css-1dbjc4n r-1awozwy r-18u37iz r-1wtj0ep r-1g2xzmm">
                                                    <div className="css-1dbjc4n r-eqz5dr r-1g2xzmm" style={{ marginBottom: "-4px" }}>
                                                        <div className="css-1dbjc4n r-1g2xzmm" style={{ paddingBottom: 4 }}>
                                                            <div className="css-1dbjc4n r-1awozwy r-18u37iz r-2eo2mk r-1g2xzmm">
                                                                <div className="css-1dbjc4n r-1wbh5a2 r-1hvjb8t r-1g2xzmm">
                                                                    <div className="css-901oao r-homxoj r-1loqt21 r-320f6p r-7cikom r-vw2c0b r-1s2g6fe r-t9a87b" onClick={changeTandeVND2}>
                                                                        <span className="css-901oao css-16my406 r-1rf7ul2">
                                                                            {!trandeVND2 ? `${truncate(e.amount_exchange_usd * e.amount_exchange_vnd - (e.amount_exchange_usd * e.amount_exchange_vnd) / 100 * e.percent)}` : `${truncate(e.amount_exchange_vnd)}`}
                                                                        </span>
                                                                        <span className="css-901oao css-16my406 r-1814a8r r-tsynxw">
                                                                            {" "}
                                                                            {!trandeVND2 ? "VND/" : 'USD/'}
                                                                        </span>
                                                                        <span className="css-901oao css-16my406 r-1814a8r">{slug?.toUpperCase()}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="css-1dbjc4n r-1wbh5a2 r-1hvjb8t r-1g2xzmm" onClick={handlePopupSell}>
                                                                    <div className="css-1dbjc4n r-1awozwy r-18u37iz r-17grq5a r-1g2xzmm">
                                                                        <div className="css-1dbjc4n r-1wbh5a2 r-1pyaxff r-1g2xzmm">
                                                                            <div className="css-901oao r-homxoj r-320f6p r-7cikom r-1s2g6fe r-t9a87b" >
                                                                                <span className="css-901oao css-16my406 r-1mdqdya r-320f6p r-ubezar r-oxtfae r-135wba7">
                                                                                    {" "}
                                                                                    –{" "}
                                                                                </span>
                                                                                <span className="css-901oao css-16my406 r-1mdqdya r-320f6p r-ubezar r-oxtfae r-135wba7">
                                                                                    qua{" "}
                                                                                </span>
                                                                                {transactionform?.map((el, i) => {
                                                                                    return (

                                                                                        <span className="css-901oao css-16my406 r-z4f626 r-320f6p r-ubezar r-oxtfae r-135wba7">
                                                                                            {e.type_exchange == 1 && el.name == 'BOSS VND Wallet' ? el.name : ''}
                                                                                        </span>
                                                                                    )
                                                                                })}

                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="css-1dbjc4n r-1g2xzmm" style={{ paddingBottom: 4 }} onClick={handlePopupSell}>
                                                            <div className="css-1dbjc4n r-eqz5dr r-1g2xzmm" style={{ marginBottom: "-4px" }} >
                                                                <div className="css-1dbjc4n r-1g2xzmm" style={{ paddingBottom: 4 }}>
                                                                    <div className="css-1dbjc4n r-18u37iz r-1g2xzmm">
                                                                        <a
                                                                            href={`/profile/${slug}`}
                                                                            className="text-base"
                                                                        >
                                                                            <div className="css-1dbjc4n r-1awozwy r-18u37iz">
                                                                                <div
                                                                                    className="css-1dbjc4n"
                                                                                    data-testid="icon-online"
                                                                                    style={{ height: 8, width: 8 }}
                                                                                >
                                                                                    <svg
                                                                                        width={8}
                                                                                        height={8}
                                                                                        viewBox="0 0 8 8"
                                                                                        fill="#5E5862"
                                                                                    >
                                                                                        <circle
                                                                                            cx={117}
                                                                                            cy={143}
                                                                                            r={4}
                                                                                            transform="translate(-113 -139)"
                                                                                            fill="#83BE69"
                                                                                            fillRule="evenodd"
                                                                                        />
                                                                                    </svg>
                                                                                </div>
                                                                                <div className="css-1dbjc4n r-1wbh5a2 r-1777fci r-1jkjb">
                                                                                    <div
                                                                                        dir="auto"
                                                                                        className="css-901oao r-homxoj r-320f6p r-7cikom r-1s2g6fe r-t9a87b"
                                                                                    >
                                                                                        {e.username}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="css-1dbjc4n r-1g2xzmm" style={{ paddingBottom: 4 }} onClick={handlePopupSell}>
                                                            <div className="css-1dbjc4n r-1g2xzmm" data-testid="total-amount">
                                                                <div className="css-1dbjc4n r-1awozwy r-18u37iz r-17grq5a r-1g2xzmm">
                                                                    <div className="css-1dbjc4n r-1wbh5a2 r-1pyaxff r-1g2xzmm">
                                                                        <div
                                                                            dir="auto"
                                                                            className="css-901oao r-1mdqdya r-320f6p r-ubezar r-oxtfae r-135wba7"
                                                                        >
                                                                            {t("Total amount:")}
                                                                        </div>
                                                                    </div>
                                                                    <div className="css-1dbjc4n r-1wbh5a2 r-1pyaxff r-1g2xzmm">
                                                                        <div
                                                                            dir="auto"
                                                                            className="css-901oao r-z4f626 r-320f6p r-ubezar r-vw2c0b r-oxtfae r-135wba7"
                                                                        >
                                                                            {type == 0 ? `${truncate(value2 * (e.amount_exchange_usd * e.amount_exchange_vnd - (e.amount_exchange_usd * e.amount_exchange_vnd) / 100 * e.percent))}` : value1 / (e.amount_exchange_usd * e.amount_exchange_vnd - (e.amount_exchange_usd * e.amount_exchange_vnd) / 100 * e.percent)}
                                                                            {/* {truncate(value2 * (e.amount_exchange_usd * e.amount_exchange_vnd - (e.amount_exchange_usd * e.amount_exchange_vnd) / 100 * e.percent))} */}

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="css-1dbjc4n r-1g2xzmm" style={{ paddingBottom: 4 }} onClick={handlePopupSell}>
                                                            <div className="css-1dbjc4n r-1g2xzmm" data-testid="completion-time">
                                                                <div className="css-1dbjc4n r-1awozwy r-18u37iz r-17grq5a r-1g2xzmm">
                                                                    <div className="css-1dbjc4n r-1wbh5a2 r-1pyaxff r-1g2xzmm">
                                                                        <div
                                                                            dir="auto"
                                                                            className="css-901oao r-1mdqdya r-320f6p r-1b43r93 r-uz4sb0 r-rjixqe"
                                                                        >
                                                                            {t("Completion time:")}
                                                                        </div>
                                                                    </div>
                                                                    <div className="css-1dbjc4n r-1wbh5a2 r-1pyaxff r-1g2xzmm">
                                                                        <div
                                                                            dir="auto"
                                                                            className="css-901oao r-z4f626 r-320f6p r-1b43r93 r-uz4sb0 r-rjixqe"
                                                                        >
                                                                            ~0s
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div onClick={handlePopupSell} className="css-1dbjc4n r-z2wwpe r-1udh08x buynow">
                                                        <div className="css-18t94o4 css-1dbjc4n r-1loqt21 r-1otgn73 r-1i6wzkk r-lrvibr" style={{ transitionDuration: "0s" }}>
                                                            <div className="css-1dbjc4n r-1awozwy r-k200y r-z2wwpe r-18u37iz r-1777fci r-5njf8e"
                                                                style={{
                                                                    backgroundColor: "rgb(8, 121, 114)",
                                                                    borderWidth: 0,
                                                                    minHeight: 40,
                                                                    padding: "8px 16.1px"
                                                                }}
                                                            >
                                                                <div className="css-901oao r-jwli3a r-320f6p r-ubezar r-oxtfae r-135wba7"
                                                                    style={{ paddingTop: 0, paddingBottom: 0 }} >
                                                                    {t("Sell now")}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )
                            })}
                        </>
                        }
                    </div>

                    <div className="css-1dbjc4n r-1g2xzmm" style={{ paddingBottom: '16px' }}>
                        <div className="coin2 css-1dbjc4n r-1g2xzmm">
                            <a href="/btc/ae/sell" className="change-trade-type text-white" >{t("Looking to sell")} {slug?.toUpperCase()}?</a>
                            <a href="#" onClick={backClick2} className='text-white quaylai'>{t("Back")}</a>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
