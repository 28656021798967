import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { ShowKyc } from 'redux/reducer/kycLevelReducer';
import { useTranslate } from '../../core/translate';



export default function VerifiedKyc() {
    let { t } = useTranslate()
    const history = useHistory()
    const { profile } = useSelector(state => state.user)
    const verified = profile?.data?.data?.verified
    const kyc2Status = profile?.data?.data?.kyc2_status
    const content_cancel = profile?.data?.data?.content_cancel
    const dispatch = useDispatch()
    // console.log(profile?.data?.data?.verified == 1 && profile?.data?.data?.kyc_confirm != 2 &&  profile?.data?.data?.kyc2_status != 2);
    useEffect(() => {
        let tab = t("KYC")
        document.title = tab
        return () => {
            document.title = "Swap tobe"
        }
    }, [t]);

    return (
     
        <div className='verifyKyc'>
            <div className="container">
                <div className="verifyKyc-title">
                    <h3>Personal Verification</h3>
                </div>
                <div className="verifyKyc-main">
                    <div className="content">
                    {verified == 3 ? <div style={{ color: 'red', fontWeight:'700'}}>Has been rejected, please re-upload </div> : ''}

                        <div className='title'>
                            <p>Step 1</p>
                        </div>
                        <div className='text'>
                           <div>Personal information</div>
                           <div>Government-issued ID</div>
                           <div>Face recognition</div>
                        </div>
                        <div className='input'>
                           <div>
                            <strong>Limit</strong>
                            <p>Trade up to $10.000 per day</p>
                           </div>
                         {verified == 1 ?   <button  style={{opacity:'0.9', cursor:"no-drop"}} >Verified</button> :  verified == 2? <button style={{background:"#a5a24e",cursor:"no-drop"}}>{t("Pending")}</button> : <button style={{ cursor:'pointer'}} onClick={() => history.push('kyc-basic')}>{t("Start Now")}</button>  }
                         
                        </div>
                    </div>
                    <div className="content">
                    {kyc2Status == 3 ?    <div style={{color: 'red', fontWeight:'700'}}>{content_cancel ? `Reason: ${content_cancel}`  : "Has been rejected, please re-upload"}</div> : ''}

                        <div className='title'>
                            <p>Step 2</p>
                        </div>
                        <div className='text'>

                           <div>Bank information</div>
                        </div>
                        <div className='input'>
                           <div>
                            <strong>Limit</strong>
                            <p>Trade up to $50.000 per day</p>
                           </div>
                           {profile?.data?.data?.verified != 1 && profile?.data?.data?.kyc_confirm != 2 &&  profile?.data?.data?.kyc2_status != 2 && <button  style={{cursor:"no-drop", opacity:"0.5"}}>{t("Start Now")}</button>  }

                           {profile?.data?.data?.verified == 1 && profile?.data?.data?.kyc_confirm != 2 &&  profile?.data?.data?.kyc2_status != 2 ? <button  onClick={() => dispatch(ShowKyc(true))}>{t("Start Now")}</button> :'' }
                                                                    {profile?.data?.data?.kyc_confirm == 2 && <button style={{cursor:"no-drop"}}>{t("Verified")}</button> }
                                                                {profile?.data?.data?.kyc2_status == 2 && <button style={{background:'#a5a24e',cursor:"no-drop"}}>{t("Pending")}</button> }
                        </div>
                    </div>
                    <div className="content">
                        <div className='title'>
                            <p>Step 3</p>
                        </div>
                        <div className='text'>
                           <div>Peer cerification</div>
                           <div>Review time: 10 days</div>
                        </div>
                        <div className='input'>
                           <div>
                            <strong>Limit</strong>
                            <p>Unlimited transactions</p>
                           </div>
                            <button style={{opacity:'0.5', cursor:"no-drop"}} disabled={true}>Start Now</button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}
