import { PRICECONVERT, PRICECONVERTREALTIME } from "../type"

let initState = {
    priceconvert: [],
    priceconvertreattime: []

}


export default function coinConverReducer(state = initState, action) {
    switch (action.type) {

        case PRICECONVERT:
            return {
                ...state,
                priceconvert: [action.payload]
            }
        case PRICECONVERTREALTIME:
            state?.priceconvert?.forEach((element) => {
                if (action.payload.symbol == element.symbol) {
                    element.lastPrice = action.payload.bestAsk
                }
            })
            return {
                ...state,

            }
        default:
            return state;
    }
}