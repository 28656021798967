import { fixedNumber } from 'core/hook/fixedNumber'
import { formatMoney } from 'core/hook/formarMoney'
import React, { useState } from 'react'
import { useEffect } from 'react'
import reactDom from 'react-dom'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { toggleShadow } from 'redux/action/toggleShadowActive'
import { useTranslate } from '../core/translate'

import { port } from '../port'
import { createAction } from '../redux/action/authAction'

import { COIN } from '../redux/type'
import ApexChart from './chart/ApexCharts'



export default function PopupCoin() {
    let { pricecoin } = useSelector(state => state.coin)
    let { vndusd } = useSelector(state => state.home)

    const dispatch = useDispatch()

    let { t } = useTranslate()

    let { popupcoin } = useSelector(state => state.auth)

    function removeCOIN() {
        dispatch(createAction(COIN, false))
    }


    let [VND, setVND] = useState(false)

    function price() {
        setVND(!VND)
    }

    function truncate(value, precision) {
        var step = Math.pow(10, precision || 0);
        var temp = Math.trunc(step * value);

        var a = temp / step;
        return a.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    const shadowLayer = useSelector(state => state.shadowLayer)
    let { flag } = shadowLayer

    function getvalue(e) {

        localStorage.setItem('slug', e?.name)
        dispatch(toggleShadow(!flag))
        removeCOIN()

    }

    return reactDom.createPortal(
        <>

            <div data-focusable="true" tabIndex={0} className={`${popupcoin ? "active popup css-1dbjc4n r-1awozwy r-1rr9as1 r-1quu1zo r-g6znpw r-9vs1z3" : " popup css-1dbjc4n r-1awozwy r-1rr9as1 r-1quu1zo r-g6znpw r-9vs1z3"}`}>
                <div className="container" style={{ zIndex: 202 }} >
                    {flag &&
                        <div className="popup_list css-1dbjc4n r-jrkz34 r-1kovgwo r-13qz1uu" style={{ zIndex: '300' }}>
                            <div className="popup_scroll css-1dbjc4n r-6koalj r-vvm88l r-13qz1uu" style={{ maxHeight: '490px', overflow: 'auto' }}>
                                <div className="greenliner css-1dbjc4n r-6koalj r-12j2k7l">
                                    <div className="css-1dbjc4n r-13awgt0">
                                        <div className="css-1dbjc4n r-1awozwy  r-adb5am r-6koalj r-12j2k7l r-1xtkspr r-noizy9" style={{ paddingLeft: '3.2rem' }}>
                                            <div className="text-white css-901oao r-1mdqdya r-320f6p r-1d34j3o r-vw2c0b r-oxtfae r-e57hv0 r-tsynxw r-3s2u2q">{t("Coin name")}</div>
                                        </div>
                                    </div>
                                    <div className=" css-1dbjc4n r-13awgt0" style={{ alignItems: "center" }}>
                                        <div className=" css-1dbjc4n r-1awozwy  r-adb5am r-6koalj r-12j2k7l r-1xtkspr r-noizy9">
                                            <div className=" css-1dbjc4n" data-testid="sort-one-day-change">
                                                <div className="css-1dbjc4n r-1awozwy r-13awgt0 r-18u37iz">
                                                    <div className=" css-1dbjc4n r-1wbh5a2 r-1777fci r-1d4mawv">
                                                        <div className="text-white css-901oao r-1mdqdya r-320f6p r-1d34j3o r-vw2c0b r-oxtfae r-e57hv0 r-tsynxw r-3s2u2q">{t("24h Change")}</div>
                                                    </div>
                                                    <div className="css-1dbjc4n" data-testid="icon-sort" style={{ height: '14px', width: '14px' }}>
                                                        <svg width={14} height={14} viewBox="0 0 18 32" fill='#ffff'>
                                                            <path d="M18.272 19.424q0 .48-.32.8l-8 8q-.352.352-.8.352t-.8-.352l-8-8q-.352-.32-.352-.8t.352-.8.8-.352h16q.448 0 .8.352t.32.8zm0-6.848q0 .448-.32.8t-.8.352h-16q-.48 0-.8-.352t-.352-.8.352-.8l8-8q.32-.352.8-.352t.8.352l8 8q.32.32.32.8z" />
                                                        </svg>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="css-1dbjc4n" style={{ flex: '0.8 1 0%' }}>
                                        <div className="css-1dbjc4n r-1awozwy  r-adb5am r-6koalj r-12j2k7l r-1xtkspr r-17s6mgv r-noizy9">
                                            <div className="css-1dbjc4n  r-1vsr5n r-4ee5ir r-1it3c9n r-5sfk15 r-iwgiqz r-flagb3" style={{ borderColor: "#ffff" }}>
                                                <div onClick={price} className="text-white css-901oao r-320f6p r-ongcgc r-1it3c9n r-oxtfae r-u6qrkm r-tsynxw r-3s2u2q">{t("Price")} {VND ? "(VND)" : '(USD)'}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="css-1dbjc4n" style={{ flex: '1.2 1 0%' }}>
                                        <div className="css-1dbjc4n r-1awozwy  r-adb5am r-6koalj r-12j2k7l r-1xtkspr r-17s6mgv r-noizy9">
                                            <div className="css-1dbjc4n" >
                                                <div className="css-1dbjc4n r-1awozwy r-13awgt0 r-18u37iz">
                                                    <div className="css-1dbjc4n r-1wbh5a2 r-1777fci r-1d4mawv">
                                                        <div className="text-white css-901oao r-1mdqdya r-320f6p r-1d34j3o r-vw2c0b r-oxtfae r-e57hv0 r-tsynxw r-3s2u2q">{t("24h Volume")}</div>
                                                    </div>
                                                    <div className="css-1dbjc4n" data-testid="icon-sort" style={{ height: '14px', width: '14px' }}>
                                                        <svg width={14} height={14} viewBox="0 0 18 32" fill='#ffff'>
                                                            <path d="M18.272 19.424q0 .48-.32.8l-8 8q-.352.352-.8.352t-.8-.352l-8-8q-.352-.32-.352-.8t.352-.8.8-.352h16q.448 0 .8.352t.32.8zm0-6.848q0 .448-.32.8t-.8.352h-16q-.48 0-.8-.352t-.352-.8.352-.8l8-8q.32-.352.8-.352t.8.352l8 8q.32.32.32.8z" />
                                                        </svg>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="css-1dbjc4n" style={{ flex: '0.9 1 0%' }}>
                                        <div className="css-1dbjc4n r-1awozwy  r-adb5am r-6koalj r-12j2k7l r-1xtkspr r-noizy9">
                                            <div className="text-white css-901oao r-1mdqdya r-320f6p r-1d34j3o r-vw2c0b r-oxtfae r-e57hv0 r-tsynxw r-3s2u2q">{t("Last 7 days")}</div>
                                        </div>
                                    </div>
                                </div>
                                {

                                    pricecoin?.map((e, i) => {
                                        if (e?.name != "STF" && e?.p2p == 1)
                                            return (
                                                <NavLink key={i} to={e.name.toLowerCase()} className="css-1dbjc4n r-6koalj r-12j2k7l" data-testid="coin-item-xrp" onClick={() => getvalue(e)}>
                                                    <div className="css-1dbjc4n r-13awgt0" data-testid="coin-name-data">
                                                        <div className="css-1dbjc4n r-adb5am r-zd7jnc r-5kkj8d r-13awgt0 r-crgep1 r-noizy9" style={{ paddingLeft: '3.2rem' }}>
                                                            <div className="css-1dbjc4n r-1awozwy r-13awgt0 r-18u37iz">
                                                                <div className="css-1dbjc4n" data-testid="icon-xrp" style={{ height: '24px', width: '24px' }}>
                                                                    {e?.name == "BPAY" ? <img src={`${port}images/BOS.png`} alt="" /> : <img src={`${port}images/${e.name}.png`} alt="" />}

                                                                </div>
                                                                <div className="css-1dbjc4n r-1wbh5a2 r-1777fci r-1jkjb">
                                                                    <div className="css-1dbjc4n r-6koalj r-vvm88l">
                                                                        <div dir="auto" className="css-901oao r-poiln3 r-g41cf7 r-6y1zac" data-testid="coin-name" >{e.token_key}</div>
                                                                        <div dir="auto" className="css-901oao r-1mdqdya r-poiln3 r-1d34j3o r-1xj7jgt" style={{ textTransform: "uppercase" }}>{e.name}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="css-1dbjc4n r-13awgt0" data-testid="one-day-change">
                                                        <div className="css-1dbjc4n r-adb5am r-zd7jnc r-5kkj8d r-13awgt0 r-crgep1 r-noizy9">
                                                            <div dir="auto" className="css-901oao r-8bscmv r-6koalj r-12j2k7l r-nifnve r-g41cf7 r-e57hv0 r-3twk1y r-3s2u2q" style={{ margin: 'auto' }}>
                                                                <div className="css-1dbjc4n r-xoduu5">
                                                                    {
                                                                        e.percent > 0 ? <span className="css-901oao css-16my406 r-8bscmv" style={{ color: "green" }}>{e.percent}</span> : <span className="css-901oao css-16my406 r-8bscmv" >{e.percent}</span>
                                                                    }

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="css-1dbjc4n" data-testid="coin-price" style={{ flex: '0.8 1 0%' }}>
                                                        <div className="css-1dbjc4n r-adb5am r-zd7jnc r-5kkj8d r-13awgt0 r-crgep1 r-noizy9">
                                                            <div dir="auto" className="css-901oao r-6koalj r-12j2k7l r-nifnve r-g41cf7 r-e57hv0 r-1jj8364 r-3twk1y r-3s2u2q">{VND ? `${truncate(e.price * vndusd, 0)} VNĐ` : `$ ${formatMoney(String(fixedNumber(e.price, 6)))}`}</div>
                                                        </div>
                                                    </div>
                                                    <div className="css-1dbjc4n" data-testid="one-day-volume" style={{ flex: '1.2 1 0%' }}>
                                                        <div className="css-1dbjc4n r-adb5am r-zd7jnc r-5kkj8d r-13awgt0 r-crgep1 r-noizy9">
                                                            <div dir="auto" className="css-901oao r-6koalj r-12j2k7l r-nifnve r-g41cf7 r-e57hv0 r-1jj8364 r-3twk1y r-3s2u2q">{`${truncate(e.volume, 10)}`}</div>
                                                        </div>
                                                    </div>
                                                    <div className="css-1dbjc4n" data-testid="coin-chart" style={{ flex: '0.9 1 0%' }}>
                                                        <div className="css-1dbjc4n r-adb5am r-zd7jnc r-5kkj8d r-13awgt0 r-crgep1 r-noizy9">
                                                            <div className="css-1dbjc4n" style={{ height: '34px' }}>
                                                                <div className="css-1dbjc4n r-13awgt0">
                                                                    < ApexChart id={i} symbol={`${e.name}USDT`} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </NavLink>
                                            )


                                    })
                                }

                            </div>
                        </div>}

                    {flag &&
                        <div className=" respopup_list css-1dbjc4n r-1awozwy r-13awgt0 r-1777fci  r-13qz1uu" style={{ zIndex: '300', maxHeight: '490px', overflow: 'auto' }}>
                            <div className="css-1dbjc4n r-1awozwy r-14lw9ot r-1rr9as1 r-1quu1zo  r-9vs1z3" style={{ width: '100%' }}>
                                <div className="greenliner css-1dbjc4n r-1qnjcxv r-zd7jnc r-rs85l0 r-6koalj r-12j2k7l r-1xtkspr r-13qz1uu" style={{ backgroundColor: "#087972" }}>
                                    <div className="css-1dbjc4n r-1awozwy r-6koalj r-13awgt0 r-12j2k7l r-1maoe3r">
                                        <div className="text-white css-901oao r-1mdqdya r-1d34j3o r-vw2c0b r-1xj7jgt r-tsynxw r-3s2u2q">
                                            {t("Coin name")}
                                        </div>
                                    </div>
                                    <div className="css-1dbjc4n r-obd0qt r-6koalj r-13awgt0 r-vvm88l r-1777fci r-noizy9">
                                        <div className="css-1dbjc4n  r-1vsr5n r-4ee5ir r-iwgiqz r-flagb3" style={{ borderColor: " #ffff" }}>
                                            <div onClick={price} className="text-white giavnd css-901oao css-bfa6kz r-z4f626 r-1loqt21 r-320f6p r-1enofrn r-1wudzfh r-14yzgew r-tsynxw">
                                                {t("Price")} {VND ? "(VND)" : '(USDT)'}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {pricecoin?.map((e, i) => {
                                    if (e?.name != "STF" && e?.p2p == 1)
                                        return (
                                            <NavLink key={i} to={e.name.toLowerCase()} className="respopup css-1dbjc4n r-150rngu r-eqz5dr r-16y2uox r-1wbh5a2 r-11yh6sk r-1rnoaur r-1sncvnh r-13qz1uu" onClick={() => getvalue(e)}>
                                                <div className="css-1dbjc4n">
                                                    <div className="css-1dbjc4n">
                                                        <div data-focusable="true" tabIndex={0} className="css-1dbjc4n r-1vsr5n r-1nv0oa9 r-zd7jnc r-5kkj8d r-6koalj r-12j2k7l r-1509vjm" data-testid="coin-item-btc">
                                                            <div className="css-1dbjc4n r-1awozwy r-6koalj r-13awgt0 r-12j2k7l r-e57hv0 r-1maoe3r">
                                                                <div className="css-1dbjc4n r-1awozwy r-18u37iz">
                                                                    <div className="css-1dbjc4n" data-testid="icon-btc" style={{ height: 24, width: 24 }}>
                                                                        <img src={`${port}images/${e.name}.png`} alt="" />
                                                                    </div>
                                                                    <div className="css-1dbjc4n r-1wbh5a2 r-1777fci r-1jkjb">
                                                                        <div className="css-1dbjc4n r-6koalj r-vvm88l">
                                                                            <div dir="auto" className="css-901oao r-poiln3 r-g41cf7 r-6y1zac">
                                                                                {e.token_key}
                                                                            </div>
                                                                            <div dir="auto" className="css-901oao r-1mdqdya r-poiln3 r-1d34j3o r-1xj7jgt">
                                                                                {e.name}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="css-1dbjc4n r-obd0qt r-6koalj r-13awgt0 r-vvm88l r-1777fci r-noizy9">
                                                                <div dir="auto" className="css-901oao r-poiln3 r-g41cf7 r-1it3c9n r-6y1zac">
                                                                    {VND ? `${truncate(e.price * vndusd, 0)} VNĐ` : `$ ${formatMoney(String(fixedNumber(e.price, 6)))}`}
                                                                </div>
                                                                <div className="css-1dbjc4n">
                                                                    <div dir="auto" className="css-901oao r-1rf7ul2 r-poiln3 r-1d34j3o r-1xj7jgt" style={{ color: 'red' }}>
                                                                        {e.changePercent}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </NavLink>
                                        )


                                })}


                            </div>
                        </div>}
                    {/* <div className={`${popupcoin ? "active overlay" : "overlay"}`} onClick={() => removeCOIN()} ></div> */}

                </div>

            </div>

        </>,
        document.getElementById('root2')



    )
}
