import { useSelector } from "react-redux";
import { Redirect, Route } from "react-router";
// import useAuth from "./useAuth";

export default function PrivateRouter(props) {
    let user = JSON.parse(localStorage.getItem('user'))

    if (user?.axiologin?.id == 1) {
        return <Route  {...props} />
    }


    return <Redirect to="/p2p" />
}