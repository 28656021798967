import React from 'react';

import { useTranslate } from 'core/translate';
import { useDispatch, useSelector } from 'react-redux';
import Submit from './Submit';
import { ErrorMessage } from 'core/Message';
import { MinimumWidthdraw } from 'page/Vi/component/MinimumWidthdraw';
import { useState } from 'react';
import { GetWalletSlug } from 'page/Vi/component/MinimumWidthdraw';
import Content from './Content';
import ContentUser from './ContentUser';
import { Spin } from 'antd';
import { getwalletcoin } from '../../../core/GetWalletCoin'
import covertCoin from 'core/CovertCoin';
import SubmitUser from './SubmitUser';
import { ShowLoadding } from 'redux/reducer/loaddingReducer';

function Withdraw({ setMenuflag, Menuflag, setMenu, menu }) {
    let dispatch = useDispatch()
    let { t } = useTranslate()
    let { pricecoin } = useSelector(state => state.coin)
    let { symbol, getwallet } = useSelector(state => state.user)
    let { dataWithdrawCoin } = useSelector(state => state.withdraw)
    let [valueCoin, setValueCoin] = useState('')
    let [noteMessage, setNoteMessage] = useState()
    let [toAddress, setToAddress] = useState()
    let [loadding, setLoadding] = useState(false)
    let coin = covertCoin(dataWithdrawCoin?.name, dataWithdrawCoin?.type)

    const handleSetMenu = (index) => {
        dispatch(ShowLoadding(true))
        setMenu(index)
    }

    function handleMaxCoin() {
        setValueCoin(getwalletcoin(coin, getwallet))
    }

    function handleChange(e) {
        let value = e.target.value
        setToAddress(value)

    }
    function handleChangeCoin(e) {
        let value = e.target.value
        let value1 = ""
        let dotIndex = value.indexOf(".")

        for (let index = 0; index < value.length; index++) {
            if (value[index].match(/[0-9]/))
                value1 += value[index]
            if (index === dotIndex)
                value1 += "."
        }
        setValueCoin(value1)
    }
    const handleComfirm = () => {
        let Miniwallet = MinimumWidthdraw(pricecoin, dataWithdrawCoin?.name, getwallet?.data?.data, t)
        if (dataWithdrawCoin?.name == "STF" ) {
            ErrorMessage("The system temporarily locks this function", t)
        }
        else if (dataWithdrawCoin?.name == "SWB") {
            ErrorMessage("", t)
        }
        else if (valueCoin > GetWalletSlug(dataWithdrawCoin?.name, dataWithdrawCoin?.type, getwallet?.data?.data)) {
            ErrorMessage("Insufficient available balance", t)
        }
        else if (valueCoin && toAddress.length > 0) {
            if (Miniwallet) {
                setMenuflag("confirm")
                setLoadding(true)
                setTimeout(() => {
                    setLoadding(false)
                }, 2000)
            }
        }
        else {
            ErrorMessage("Please write full information", t)
        }
    }

    function handleComfirmUser() {
        if (dataWithdrawCoin?.name == "STF" && toAddress < 1) {
            ErrorMessage("The number of coins must be greater than 1", t)
        }
        else if (valueCoin > 0 && toAddress.length > 0) {
            setMenuflag("confirmUser")
        } else {
            ErrorMessage("Please write full information", t)
        }

    }

    return (
        <>
            {loadding ?
                <div className="example" style={{ width: '100%' }}>
                    <Spin />
                </div>
                :
                <>
                    {Menuflag == '' ? <>
                        <div className='depositvnd-title withdrawcoin-title'>
                            <p className={`${menu == 1 ? 'active' : ''}`} onClick={() => handleSetMenu(1)} >Wallet {symbol}</p>
                            <p className={`${menu == 2 ? 'active' : ''}`} onClick={() => handleSetMenu(2)}>Aliases</p>
                        </div>
                        {menu == 1 &&
                            <Content symbol={symbol} handleChange={handleChange} handleChangeCoin={handleChangeCoin} handleComfirm={handleComfirm} handleMaxCoin={handleMaxCoin} valueCoin={valueCoin} coin={coin} />}
                        {menu == 2 &&
                            <ContentUser symbol={symbol} handleChange={handleChange} handleChangeCoin={handleChangeCoin} handleComfirmUser={handleComfirmUser} valueCoin={valueCoin} setNoteMessage={setNoteMessage} />}
                    </>
                        : ''}
                    {Menuflag == "confirm" &&
                        <Submit toAddress={toAddress} valueCoin={valueCoin} slug={dataWithdrawCoin?.name} type={dataWithdrawCoin?.type} getwallet={getwallet?.data?.data} setMenuflag={setMenuflag} Menuflag={Menuflag} />
                    }
                    {Menuflag == "confirmUser" &&
                        <SubmitUser UserCoin={valueCoin} username={toAddress} slug={dataWithdrawCoin?.name} type={dataWithdrawCoin?.type} note={noteMessage} setMenuflag={setMenuflag} />
                    }
                </>
            }
        </>

    );
}

export default Withdraw;