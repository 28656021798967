import { createAction } from "../redux/action/authAction"
import { GOOGLE, LOGIN } from "../redux/type"


const responseGoogle = (res) => {

    return dispatch => {
        dispatch(createAction(LOGIN, true))
        dispatch(createAction(GOOGLE, res))
    }

}

export default responseGoogle