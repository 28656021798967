import React from "react";
function ParentF1(props) {

  const element = props.array?.map((item, index) => {

    return (
      <li onClick={() => props?.func(item.id, item.username, item.email)}>
        <a href="#" title="Htbavi" className="text-dark">{`${item.username} ( Email: ${item.email})`}</a>
        <ul><ParentF1  array={item.children} /></ul>
      </li>
    );
  });
  return <>{element}</>;
}
export default ParentF1;
