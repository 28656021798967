import Footer from 'Components/Footer'
import ShadowLayer from 'Components/ShadowLayer'
import React, { useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { Redirect } from 'react-router'
import { toggleShadow } from 'redux/action/toggleShadowActive'
import Token from 'assets/img/svg/token.svg'
import token from 'assets/img/svg/smarchain.svg'

import ImgToken from 'assets/img/svg/img-token.svg'
import BgToken from 'assets/img/svg/bg-token.svg'
import ShowMoreText from 'react-show-more-text'



export default function Home() {

    let { axiologin } = useSelector(state => state.auth)
    const shadowLayer = useSelector(state => state.shadowLayer)
    let { flag } = shadowLayer
    let dispatch = useDispatch()
    function handlePopup() {
        dispatch(toggleShadow(!flag))
    }
    const [active, setActive] = useState(false)
    return (
        <>
            {axiologin && <Redirect to='/p2p' />}
            <div style={{ overflow: 'hidden' }}>
                <header>
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-6 col-md-4 logo" style={{ display: 'flex', alignItems: 'center' }}>
                                <a href="#" title="Cp mercury">
                                    <img loading="lazy" className="light" src="img/logoswapcolorful.png" />
                                    <img loading="lazy" className="dark" src="img/logoswapcolorful.png" />
                                </a>
                            </div>
                            <div className="col-sm-6 col-md-8 main-menu">
                                <div className={`${active ? 'menu-icon active' : "menu-icon"} `} onClick={() => setActive(!active)} style={{ top: '5px' }}>
                                    <span className="top" />
                                    <span className="middle" />
                                    <span className="bottom" />
                                </div>
                                <nav style={{ float: 'right' }} className='--web'>
                                    <ul>
                                        <li style={{ borderBottom: 'none' }}> <a style={{ color: '#fff', padding: '4px 20px', background: 'rgb(10 113 122)', WebkitBorderRadius: '4px', borderRadius: '4px', marginLeft: '30px', marginTop: '5px', fontSize: '14px', fontWeight: 'bold' }} className="nav-btn onepage_login" href="/login" >Login</a></li>
                                    </ul>
                                </nav>
                                {active && <nav className={`onepage --res`} style={{ float: 'right' }}>
                                    <ul>
                                        <li ><a className='onepage_item' href="/p2p" style={{ color: '#000' }}>Exchange</a></li>
                                        <li><a className='onepage_item' href="#coin" style={{ color: '#000' }}>Coin</a></li>
                                        <li><a className='onepage_item' href="#roadmap" style={{ color: '#000' }}>roadmap</a></li>
                                        <li><a className='onepage_item' href="#press" style={{ color: '#000' }}> App</a></li>
                                        <li><a href="#faq" style={{ fontWeight: "initial", fontSize: '14px', color: '#fff' }} >Faq</a></li>
                                        <li className="onepage_moblie onepage_item" style={{ color: '#000' }}><a href="/login" style={{ color: '#fff', fontWeight: 'bold' }}> LOGIN</a></li>
                                    </ul>
                                </nav>}

                                <nav className={`onepage --web`} style={{ float: 'right' }}>
                                    <ul>
                                        <li ><a href="/p2p" style={{ fontWeight: "initial", fontSize: '14px', color: '#fff' }}>Exchange</a></li>
                                        <li><a href="#coin" style={{ fontWeight: "initial", fontSize: '14px', color: '#fff' }}>Coin</a></li>
                                        <li><a href="#roadmap" style={{ fontWeight: "initial", fontSize: '14px', color: '#fff' }}>roadmap</a></li>
                                        <li><a href="#press" style={{ fontWeight: "initial", fontSize: '14px', color: '#fff' }}> App</a></li>
                                        <li><a href="#faq" style={{ fontWeight: "initial", fontSize: '14px', color: '#fff' }} >Faq</a></li>

                                        <li className="onepage_moblie " ><a href="/login"> LOGIN</a></li>
                                    </ul>
                                </nav>

                            </div>
                        </div>
                    </div>
                </header>
                {/*Header End*/}
                {/* Content Section Start */}
                <div className="midd-container">
                    {/* Hero Section Start */}
                    <div className="hero-main mercury-layout white-sec" >
                        <div className="container">
                            <div className="row align-items-center flex-row-reverse">
                                <div className="col-sm-12 col-md-6" data-wow-delay="0.5s">
                                    <div><img loading="lazy" src="img/hinh home tobe.png" alt="" /></div>

                                </div>
                                <div className="col-sm-12 col-md-6">
                                    <h1 style={{ color: '#fff', fontSize: '28px' }}>Cryptocurrency Wallet - Buy/Sell Bitcoin, Ethereum and Altcoins</h1>
                                    <p className="lead">
                                        SwapTobe is a multi-industry eco-system that helps to deepen the application of cryptocurrencies to each business and as an alternative to traditional exchanges.
                                    </p>
                                    <div className="hero-btns">
                                        <a href="https://swaptobe.com/signup" target="_blank" className="--btn text-white" >SIGN UP NOW</a>
                                        {/* <a href="#press" className="btn btn3" style={{ marginLeft: '5px', color: "#000" }}>DOWNLOAD APP</a> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Hero Section End */}
                    {/*About Start */}
                    <div className="about-section p-tb mercury-layout" id="about">
                        <div className="container">
                            <div className="row flex-row-reverse align-items-center">

                                <div style={{ textAlign: 'center' }}>

                                    {/* <h2 className="section-heading">About Swap tobe</h2> */}
                                    <h4 style={{ color: '#4BBD99' }}>Why to choose Swap tobe?</h4>
                                    <h5 style={{ color: '#007E95' }}>
                                        Swap tobe is a pioneer multifunctional wallet in the field of Blockchain and digital asset storage!
                                    </h5>
                                    <p style={{ color: 'rgb(0 0 0 / 55%)' }}>
                                        Users can send, receive, and exchange their electronic money conveniently and easily.
                                        Swap tobe meets all the needs of the community of token users as well as other
                                        cryptocurrencies.
                                    </p>
                                    <div className="button-wrapper">
                                        <a className="btn" href="https://swaptobe.com/whitepaper.pdf">WHITE PAPER</a>


                                        <a onClick={handlePopup} className="watch-link"  >
                                            <i className="fas fa-play" />
                                            <span><strong>Watch video</strong>What and How it work</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*About end */}
                    {/* Benefits Start */}
                    <div className="benefitshome">
                        <div className="container">
                            <div className="benefitshome-main">
                                <div className="benefitshome-content">
                                    <div className="benefitshome--card">
                                        <div><img loading="lazy" src="img/Vector.png" alt="" /></div>
                                        <span>Safe and Secure</span>
                                        <p>Advanced security features, such as 2-step verification.</p>
                                    </div>
                                    <div className="benefitshome--card">
                                        <div><img loading="lazy" src="img/instant.png" alt="" /></div>
                                        <span>Instant Exchange</span>
                                        <p>The time to send and receive currency of Swap tobe only takes from 3 to 5 minutes.</p>
                                    </div>
                                    <div className="benefitshome--card">
                                        <div><img loading="lazy" src="img/strong.png" alt="" /></div>
                                        <span>Strong Network</span>
                                        <p>Swap tobe is designed to resist the change of data once the data is accepted by the network, there is no way to change it.</p>
                                    </div>
                                </div>
                                <div className="benefitshome-content --top">
                                    <div className="benefitshome--card">
                                        <div><img loading="lazy" src="img/moblie.png" alt="" /></div>
                                        <span>Mobile Apps</span>
                                        <p>Swap tobe is compatible with iOS, Android, and Website operating systems.</p>
                                    </div>
                                    <div className="benefitshome--card">
                                        <div><img loading="lazy" src="img/world.png" alt="" /></div>
                                        <span>World Coverage</span>
                                        <p>The World Economic Forum’ predicts that 10% of global GDP will be stored on Blockchain by 2025.</p>
                                    </div>
                                    <div className="benefitshome--card">
                                        <div><img loading="lazy" src="img/margin.png" alt="" /></div>
                                        <span>Margin Trading</span>
                                        <p>The option to use x2 leverage can be used on all cryptocurrency transactions.</p>
                                    </div>
                                </div>
                                <div className="benefitshome-bg"></div>
                                <div className="benefitshome-text">
                                    <div className="benefitshome-text--content">
                                        <h4>Benefits of Using Our Solution</h4>
                                        <p>Advanced security features, intuitive user interface and easy navigation.Easy to pair web wallet with mobile device by scanning QR code.Buy and sell directly through Swap tobe your.</p>
                                    </div>
                                    <div className='benefitshome-text--img'><img loading="lazy" src="img/Group.png" alt="" /></div>
                                </div>

                            </div>
                        </div>
                    </div>


                    <div className='ecosystem' style={{ background:`url(${BgToken})`, backgroundSize:'cover', backgroundRepeat:'no-repeat' }}>
                        <img src={ImgToken} alt="" />

                        <div className="container">
                            <div className="ecosystem_main">
                    
                            <div className='ecosystem_content' >
                                <div className='ecosystem_content-img'>
                                <div>
                                    <img src={Token} alt="" />

                                    <div>    TOBE COIN is the coin used in TOBE CHAIN - a multi-industry eco-system that helps to deepen the application of cryptocurrencies to each business and as an alternative to traditional exchanges.</div>
                                </div>
                              
                                </div>
                                <div className='ecosystem_content-img'>
                                    <div>
                                        <div>Name</div>
                                        <strong>Tobe Coin</strong>
                                    </div>
                                    <div>
                                        <div>Type</div>
                                        <strong>Private BlockChain (Coin Mineble - Mainnet)</strong>
                                    </div>
                                    <div>
                                        <div>Symbol</div>
                                        <strong>TBC</strong>
                                    </div>
                                    <div>
                                        <div>Platform</div>
                                        <strong>Tobe Chain</strong>
                                    </div>
                                    <div>
                                        <div>General release</div>
                                        <strong>66.000.000</strong>
                                    </div>
                                    <div>
                                        <div>Explorer</div>
                                        <strong>www.tobescan.com</strong>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                    {/* Token Sale start */}
                    <div className='tokennew' style={{ background:`url(${BgToken})` }} id="coin">
                        <div className="container">
                            <div className="tokennew_table">
                                <h3>TBC Coin Allocation</h3>
                                <img loading="lazy" src={`${token}`} alt="" />
                            </div>
                        </div>
                    </div>
                    {/* Currency End */}
                    {/* The Roadmap  start*/}

                    <div className='roadmapnew' id='roadmap'>
                        <div className="container">
                            <div className="text-center">
                                <h4 className="section-heading" style={{ color: "#4BBD99", fontWeight: 'bold' }}>Road Map</h4>
                            </div>
                            <div className="sub-txt text-center">
                                <p style={{ color: "#929493" }}>The history of the path and the development process of the Blockchain Tobe</p>
                            </div>
                            <div className="roadmapnew-main">
                                <div className="roadmapnew-main-item" style={{ boxShadow: '0 3px 5px rgb(0 0 0 / 30%)' }}>
                                    <h6>5th September 2020</h6>
                                    <div>
                                        <img loading="lazy" src="img/road map.png" alt="" />
                                    </div>
                                    <div className='dess'>
                                        <span>PLAN FROM IDEA </span>
                                        <p>Initial planning from the idea of creating a blockchain platform and a coin exchange.</p>
                                    </div>
                                    <div className='arrow' style={{ background: '#3E917A' }}>
                                        <img loading="lazy" src="img/Arrow-roadmap.png" alt="" />
                                    </div>
                                </div>
                                <div className="roadmapnew-main-item" style={{ boxShadow: '0 3px 5px rgb(0 0 0 / 30%)' }}>
                                    <h6>11th November 2021</h6>
                                    <div>
                                        <img loading="lazy" src="img/road map2.png" alt="" />
                                    </div>
                                    <div className='dess'>
                                        <span>LAUNCHING SWAPTOBE </span>
                                        <p>Launch of SwapTobe crypto exchange and related ecosystems.</p>
                                    </div>
                                    <div className='arrow' style={{ background: '##06A479' }}>
                                        <img loading="lazy" src="img/Arrow-roadmap.png" alt="" />
                                    </div>
                                </div>
                                
                                <div className="roadmapnew-main-item" style={{ boxShadow: '0 3px 5px rgb(0 0 0 / 30%)' }}>
                                    <h6>9th September 2022</h6>
                                    <div>
                                        <img loading="lazy" src="img/road map4.png" alt="" />
                                    </div>
                                    <div className='dess'>
                                        <span>OPERATING COIN TOBE</span>
                                        <p>Operate brand new coin "TobeCoin" and blockchain application in Shopping Online, Farm, Edu, Real estate,...</p>
                                    </div>
                                    <div className='arrow' style={{ background: '#B9BC2F' }}>
                                        <img loading="lazy" src="img/Arrow-roadmap.png" alt="" />
                                    </div>
                                </div>
                                <div className="roadmapnew-main-item" style={{ boxShadow: '0 3px 5px rgb(0 0 0 / 30%)' }}>
                                    <h6>1st January 2024</h6>
                                    <div>
                                        <img loading="lazy" src="img/road map5.png" alt="" />
                                    </div>
                                    <div className='dess'>
                                        <span>LISTING ON STOCK EXCHANGE</span>
                                        <p>Make a step-by-step plan to go to the stock exchange.</p>
                                    </div>
                                    <div className='arrow' style={{ background: '#FFA71D' }}>
                                        <img loading="lazy" src="img/Arrow-roadmap.png" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mobileapp-section p-tb black-bg white-sec" id="press">
                        <div className="container">
                            <div className="row align-items-center flex-row-reverse">
                                <div className="col-lg-5">
                                    <div className="iphone-img">
                                        <img loading="lazy" src="img/mockup dt android xanh.png" alt="" />
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <h3 className="section-heading">Swaptobe Apps </h3>
                                    <span style={{ fontSize: '22px' }}>Trading whenever and wherever you are!</span>
                                    <div style={{ margin: '30px 0' }}>
                                        <p style={{ fontSize: '18px', fontFamily: 'Lato, sans-serif', fontWeight: '300', fontStyle: 'normal' }}>
                                            Swap tobe is compatible with iOS, Android, Website,
                                            intuitive user interface and easy navigation. Easily pair your web wallet with a mobile
                                            device by scanning a QR code
                                        </p>
                                        <p style={{ fontSize: '18px', fontFamily: 'Lato, sans-serif', fontWeight: '300', fontStyle: 'normal' }}>Click to download app store for ios operating system, click download google play for
                                            android OS</p>
                                    </div>
                                    <div className="button-wrapper">
                                        <a href="https://testflight.apple.com/join/ysHX5JJP" target="_blank" className="apple-btn">
                                            <img loading="lazy" src="img/appstore.png" alt="Download on the app store" title="Download on the app store" />
                                        </a>
                                        <a href="https://play.google.com/store/apps/details?id=com.companyname.swaptobe" target="_blank" className="google-btn">
                                            <img loading="lazy" src="img/googleplay.png" alt="Download on the google play" title="Download on the google play" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div >
                <section className="faq-part skyblue pt-100" id='faq'>

                    <div className="container">
                        <div className="faq-bg"></div>

                        <div className="row">
                            <div className="col-md-12 wow fadeInUp">
                                <div className="section-heading  pb-65">
                                    <label className="sub-heading" style={{ fontSize: '36px', color: '#4BBD99' }}>Faqs</label>
                                    <h4 className="heading-title " style={{ fontWeight: '100', color: '#007E95' }}>Frequently Asked questions</h4>
                                    <p className="heading-des"></p>
                                </div>
                            </div>
                        </div>

                        <div className='faqnew' >

                            <div className="row">
                                <div className="col-md-12 wow fadeInUp faqnew-titles">
                                    <ul className="nav nav-tabs Frequently-tabs pb-55 faqnew-title" id="myTab" role="tablist">
                                        <li>
                                            <a className="active" data-toggle="tab" href="#general" role="tab">general</a>
                                        </li>
                                        <li>
                                            <a data-toggle="tab" href="#ico" role="tab">KYC &amp; MINING</a>
                                        </li>
                                        <li>
                                            <a data-toggle="tab" href="#Tokens" role="tab">BLOCKCHIAN</a>
                                        </li>
                                        <li>
                                            <a data-toggle="tab" href="#client" role="tab">OTHERS</a>
                                        </li>
                                        {/* <li>
                                            <a data-toggle="tab" href="#legal" role="tab">legal</a>
                                        </li> */}
                                    </ul>
                                </div>
                            </div>
                            <div className="row" style={{ justifyContent: 'space-between' }}>
                                <div className="col-md-12 wow fadeInUp">
                                    <div className="tab-content" id="myTabContent">
                                        <div className="tab-pane fade show active" id="general" role="tabpanel">
                                            <div className="row" style={{ gap: '20px', justifyContent: 'space-between' }}>
                                                <div className="faq-tabs col-md-5  pb-65">
                                                    <div className="faq-tab">
                                                        <a href="faq.html" className="qus-title" >What is Swaptobe? </a>
                                                        <p className="qus-des pt-10">SwapTobe is a Decentralized Coin Wallet which combines P2P trading platform to help the startup community reach out to investors from the Swaptobe community in more than 40 countries. The users community can both store coins and trade coins into fiat currency with the lowest fees. Swaptobe is one of 12 ecosystems of Tobe Chain.</p>
                                                    </div>
                                                </div>
                                                <div className="faq-tabs col-md-5  pb-65">
                                                    <div className="faq-tab">
                                                        <a href="faq.html" className="qus-title" >What is Tobechain?</a>
                                                        <p className="qus-des pt-10">
                                                        Tobechain is an Eco-friendly Blockchain built to enable scalable, user-friendly apps for the world. 
	Tobechain is built based on an eco-friendly and has easy way to connect and create a sustainable Blockchain network to allow everyone to scale globally, and also meet future technologies such as Web 3.0, the best support for Metaverse, and other platforms in the future.
</p>
                                                    </div>
                                                </div>
                                                <div className="faq-tabs col-md-5  pb-65">
                                                    <div className="faq-tab">
                                                        <a href="faq.html" className="qus-title" >What is Tobe Coin (TBC)?</a>
                                                        <p className="qus-des pt-10">
                                                    Tobe Coin is the currency used in Tobe Chain - a multi-industry ecosystem that helps to realize practical application of cryptocurrencies in many fields of currency, real estate, agriculture and digital transformation for businesses.</p>
                                                    </div>
                                                </div>
                                                <div className="faq-tabs col-md-5  pb-65">
                                                    <div className="faq-tab">
                                                        <a href="faq.html" className="qus-title" >How many ecosystems in Tobechain?</a>
                                                        <p className="qus-des pt-10">
                                                        There are 12 ecosystems of Tobechain: Swaptobe, Extobe, Tobe Meta, Tobe Game, Tobe Real, Tobe coin, Tobe Care, Tobe Solution, Tobe Sonet, Tobe Ecom, Tobe Farm, and Tobe NFT.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tab-pane fade" id="ico" role="tabpanel">
                                            <div className="row" style={{ gap: '20px', justifyContent: 'space-between' }}>
                                                <div className="faq-tabs col-md-5  pb-65">
                                                    <div className="faq-tab">
                                                        <a href="faq.html" className="qus-title" >Is Swaptobe App available on Android and IOS?</a>
                                                        <p className="qus-des pt-10">1.Register with an Cryptocurrency Exchange. To participate in an ICO you need cryptocurrencies, usually Ether....
                                                        You can install Swaptobe application on both Appstore and Google Play. 
For Google Play store: You just need to input “Swaptobe” at search box and install. 
For Appstore store: You have to input “TestFlight” at search box and install. Then, open TestFlight and you will see Swaptobe application in there.
</p>
                                                    </div>
                                                </div>
                                                <div className="faq-tabs col-md-5  pb-65">
                                                    <div className="faq-tab">
                                                        <a href="faq.html" className="qus-title" >What documents do users need for KYC level 1 on Swaptobe?</a>
                                                        <p className="qus-des pt-10">In order to use Swaptobe app, users need to verify the account step 1 with personal information, pictures of government-issued ID card (2 sides), and a full face photo.</p>
                                                    </div>
                                                </div>
                                                <div className="faq-tabs col-md-5  pb-65">
                                                    <div className="faq-tab">
                                                        <a href="faq.html" className="qus-title" >How to mine TBC (tobe coin) and extend trade capability on Swaptobe?</a>
                                                        <p className="qus-des pt-10">
                                                        To mine TBC (tobe coin) and extend trade capability up to $50,000/day, users have to perform KYC level 2 on Swaptobe. Documents that are accepted for KYC level 2: 
                                                        <br></br>
•	Bank statements
<br></br>
•	Utility bills (The documents that you provide should NOT be older than 03 months)
<br></br>
•	Cable TV/house, phone line bills (with stamp)
<br></br>
•	Tax returns
<br></br>
•	Council tax bills
<br></br>
•	Government-issued certification of residence, etc.
</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tab-pane fade" id="Tokens" role="tabpanel">
                                            <div className="row" style={{ gap: '20px', justifyContent: 'space-between' }}>
                                                <div className="faq-tabs col-md-5  pb-65">
                                                    <div className="faq-tab">
                                                        <a href="faq.html" className="qus-title" >What is a Blockchain Explorer?</a>
                                                        <p className="qus-des pt-10">A blockchain explorer is a web application for blockchains, that allows users to know details of activities happening on that particular blockchain, including the transaction status. Just like it is with internet explorers, it enables users to browse specific blockchains. This means that you can only use a bitcoin block explorer to browse bitcoin transactions. You cannot use it to browse Ethereum or Litecoin transactions.</p>
                                                    </div>
                                                </div>
                                                <div className="faq-tabs col-md-5  pb-65">
                                                    <div className="faq-tab">
                                                        <a href="faq.html" className="qus-title" >Common Crypto Terms: </a>
                                                        <p className="qus-des pt-10">
                                                        <ShowMoreText lines={5}
                                        more="Show more"
                                        less="Show less"
                                        className="content-css showmore"
                                        anchorClass="my-anchor-css-class"
                                        width='1000'
                                        truncatedEndingComponent={"... "}
                                    
                                    >
                                         <br></br>
                                                        •	Address: A string of letters and numbers which bitcoins can be sent to and from. A bitcoin address can be shared publicly, and like sending a message to an email address, a bitcoin address can be provided to others that wish to send you bitcoin. 
                                                        <br></br>
•	Bitcoin: The first global, decentralized currency.
<br></br>
•	Bits: A sub-unit of one bitcoin. There are 1,000,000 bits in one bitcoin.
<br></br>
•	Block: A collection of Bitcoin transactions that have occurred during a period of time (typically about 10 minutes). If the blockchain is thought of as a ledger book, a block is like one page from the book.
<br></br>
•	Blockchain: The authoritative record of every Bitcoin transaction that has ever occurred.
<br></br>
•	BTC: An abbreviation for the bitcoin currency.
<br></br>
•	Cryptocurrency: A type of currency that uses cryptography instead of a central bank to provide security and verify transactions. Bitcoin is the first cryptocurrency.
<br></br>
•	Cryptography: In the context of Bitcoin, cryptography is the use of mathematics to secure information. Cryptography is used to create and secure wallets, sign transactions, and verify the blockchain.
<br></br>
•	Private Key: A string of letters and numbers that can be used to spend bitcoins associated with a specific Bitcoin address.
<br></br>
•	Public Key: A string of letters and numbers that is derived from a private key. A public key allows one to receive bitcoins.
<br></br>
•	Centralized: Organized such that one or more parties are in control of a service.
<br></br>
•	Decentralized: Without a central authority or controlling party. Bitcoin is a decentralized network since no company, government, or individual is in control of it.
<br></br>
•	Encryption: The use of cryptography to encode a message such that only the intended recipient(s) can decode it. Bitcoin uses encryption to protect wallets from unauthorized access. 
<br></br>
•	Cold Wallet: A Bitcoin wallet that is in cold storage (not connected to the internet).
<br></br>
•	Hot Wallet: A Bitcoin wallet that resides on a device that is connected to the internet. A wallet installed on a desktop computer or smartphone is usually a hot wallet.
<br></br>
•	Hash: 1) A unique identifier of a Bitcoin transaction. 2) A mathematical function that Bitcoin miners perform on blocks to make the network secure.
<br></br>
•	Miner: A computer or group of computers that add new transactions to blocks and verify blocks created by other miners. Miners collect transaction fees and are rewarded with new bitcoins for their services.
<br></br>
•	Proof of Work: A piece of data that requires a significant amount of computation to generate but requires a minimal amount of computation to be verified as being correct. Bitcoin uses proof of work to generate new blocks.
<br></br>
•	Transaction: An entry in the blockchain that describes a transfer of bitcoins from address to another. Bitcoin transactions may contain several inputs and outputs.
<br></br>
•	Transaction Fee: Also known as a "miner's" fee, a transaction fee is an amount of bitcoin included in each transaction that is collected by miners. This is to encourage miners to add the transaction to a block. A typical bitcoin fee amount is 0.0001 BTC. 

                                                  
                                    </ShowMoreText>
                                                           
</p>
                                                    </div>
                                                </div>
                                                <div className="faq-tabs col-md-5  pb-65">
                                                    <div className="faq-tab">
                                                        <a href="faq.html" className="qus-title">What is Bitcoin?</a>
                                                        <p className="qus-des pt-10">Bitcoin is a decentralized digital currency, it is not backed by any single country or bank and is usable anywhere in the world by anyone with a computer or smartphone and access to the Internet. To read up more on what Bitcoin is you can take a look at these websites.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tab-pane fade" id="client" role="tabpanel">
                                            <div className="row" style={{ gap: '20px', justifyContent: 'space-between' }}>
                                                <div className="faq-tabs col-md-5  pb-65">
                                                    <div className="faq-tab">
                                                        <a href="faq.html" className="qus-title" >What is Ethereum (ETH)? </a>
                                                        <p className="qus-des pt-10">Ethereum is an open-source, public, blockchain-based distributed computing platform featuring smart contract (scripting) functionality (enables developers to build and deploy decentralized applications.) (…) It is listed under the code ETH and traded on cryptocurrency exchanges. It is also used to pay for transaction fees and computational services on the Ethereum network.” – Wikipedia
                                                        <br></br>
                                                        <br></br>
What is the difference between ETH and BTC?
On Remitano, both are crypto-currencies that can be traded at the same way. Thus there is no difference from that perspective. On a technical level, ETH and BTC have a few different points. Ethereum managed to fix disadvantages from Bitcoin such as high transaction fee and slow block confirmation. Bitcoin’s average block time is about 10 minutes, while Ethereum’s aims to be 12 seconds. 
</p>
                                                    </div>
                                                </div>
                                                <div className="faq-tabs col-md-5 pb-65">
                                                    <div className="faq-tab">
                                                    <p className="qus-des pt-10">
                                                       
                                                        <a href="faq.html" className="qus-title" >What is Tether (USDT)? </a>
                                                                        <ShowMoreText lines={5}
                                        more="Show more"
                                        less="Show less"
                                        className="content-css showmore"
                                        anchorClass="my-anchor-css-class"
                                        width='1000'
                                        truncatedEndingComponent={"... "}>
                                                        USDT is a cryptocurrency asset issued on the Bitcoin blockchain via the Omni Layer Protocol. Each USDT unit is backed by a U.S Dollar held in the reserves of the Tether Limited and can be redeemed through the Tether Platform. USDT can be transferred, stored, spent, just like bitcoins or any other cryptocurrency, users can transact and store tethers with any Omni Layer enabled wallet like Ambisafe, Holy Transaction or Omni Wallet.
USDT and other Tether currencies were created to facilitate the transfer of national currencies, to provide users with a stable alternative to Bitcoin and to provide an alternative for exchange and wallet audits which are currently unreliable. USDT provides an alternative to Proof of Solvency methods by introducing a Proof of Reserves Process.
In the Tether Proof of Reserves system, the amount of USDT in circulations can be easily checked on the Bitcoin blockchain via the tools provided at Omnichest.info, while the corresponding total amount of USD held reserves is proved by publishing the bank balance and undergoing periodic audits by professionals.
Use Case for Individuals
USDT offers a solution for a wide range of individual investors according to their whitepaper. From day traders; to long term investors looking to store their Bitcoins securely; to tech¬ savvy shoppers looking to avoid credit card fees or maintain their privacy; to philosophical users looking to change the world; to those looking to remit payments globally more effectively; to those in third world countries looking for access to financial services for the first time; to developers looking to create new technologies; to all those who have found many uses for Bitcoin. The main universal applications are as follow:
•	Transact in USD/fiat value, pseudo ¬anonymously, without any middlemen/intermediaries

•	Cold store USD/fiat value by securing one’s own private keys

•	Avoid the risk of storing fiat on exchanges ¬ move crypto¬ fiat in and out of exchanges easily

•	Avoid having to open a fiat bank account to store fiat value

•	Easily enhance applications that work with bitcoin to also support tether

•	Anything one can do with Bitcoin as an individual one can also do with tether

To conclude, USDT with the help of Bitcoin Blockchain strives to provide users with a price-stable asset for national currencies in a safe and transparent manner.

</ShowMoreText>
</p>

                                                    </div>
                                                </div>
                                                {/* <div className="faq-tabs col-md-5 pb-65">
                                                    <div className="faq-tab">
                                                        <a href="faq.html" className="qus-title" >How do I benefit from the ICO Token?</a>
                                                        <p className="qus-des pt-10">
                                                            The main advantage that ICOs give investors is their liquidity. ICOs give investors the opportunity to make significant profits quickly. Almost everyone and everyone can invest in an ICO. You can live anywhere, but if you want, with a few clicks you will make a profit from the ICO.</p>
                                                    </div>
                                                </div>
                                                <div className="faq-tabs col-md-5 pb-65">
                                                    <div className="faq-tab">
                                                        <a href="faq.html" className="qus-title" >What is Ico Crypto?</a>
                                                        <p className="qus-des pt-10">An initial coin offering (ICO) is the cryptocurrency industry’s equivalent to an initial public offering (IPO). A company looking to raise money to create a new coin, app, or service launches an ICO as a way to raise funds.</p>
                                                    </div>
                                                </div> */}
                                            </div>
                                        </div>
                                        <div className="tab-pane fade" id="legal" role="tabpanel">
                                            <div className="row" style={{ gap: '20px', justifyContent: 'space-between' }}>
                                                <div className="faq-tabs col-md-5 pb-65">
                                                    <div className="faq-tab">
                                                        <a href="faq.html" className="qus-title" >What cryptocurrencies can I use to purchase? </a>
                                                        <p className="qus-des pt-10">
                                                            The ICO accepts Ether, USDT .. as the buying currency and ICO tokens.</p>
                                                    </div>
                                                </div>
                                                <div className="faq-tabs col-md-5 pb-65">
                                                    <div className="faq-tab">
                                                        <a href="faq.html" className="qus-title">How do I benefit from the ICO Token?</a>
                                                        <p className="qus-des pt-10">
                                                            The main advantage that ICOs give investors is their liquidity. ICOs give investors the opportunity to make significant profits quickly. Almost everyone and everyone can invest in an ICO. You can live anywhere, but if you want, with a few clicks you will make a profit from the ICO.</p>
                                                    </div>
                                                </div>
                                                <div className="faq-tabs col-md-5 pb-65">
                                                    <div className="faq-tab">
                                                        <a href="faq.html" className="qus-title" >What is Ico Crypto?</a>
                                                        <p className="qus-des pt-10">An initial coin offering (ICO) is the cryptocurrency industry’s equivalent to an initial public offering (IPO). A company looking to raise money to create a new coin, app, or service launches an ICO as a way to raise funds.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* Content Section End */}
                <div className="clear" />
                {/*footer Start*/}

                <Footer />

            </div >
            <div className="open-button1"><a href="https://t.me/swaptobeglobal" target="_blank"><img className="tl_main_logo" xlinkHref="https://telegram.org//img/t_logo.svg?1" src="/img/t_logo.png?1" srcSet="https://telegram.org//img/t_logo.svg?1 2x, https://telegram.org//img/t_logo.svg?1" alt="Telegram logo" width={50} height={50} /></a></div>
            {flag &&
                <div className="popup-video">
                    <div className="iframe_wrap">
                        <iframe width="100%" height="100%" src="https://www.youtube.com/embed/BufAkfqDsLo?autoplay=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        <div className="close" onClick={() => dispatch(toggleShadow(!flag))}><i className="fas fa-times"></i></div>
                    </div>
                </div>}
            <ShadowLayer />
        </>

    )
}
