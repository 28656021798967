import CreateApi from "api/CreateApi"
import reduxToolkit from "../../core/reduxToolkit"

const initialState = {
    getNotify: '',
    reatTimeNotify:[]

}

export const HandleGetNotify = (symbol) => {
    return async (dispatch) => {
        try {
            let res = await CreateApi.CreateApiGETToken('api/note/list-public-note', true)
            dispatch(GetNotify(res?.data?.data))
        } catch (error) {
        }

    }
}




let { reducer, action, TYPE } = reduxToolkit({
    name: 'notify',
    initialState,
    reducers: {
     
        GetNotify: function (state, action) {
            return {
                ...state,
                getNotify: action.payload,
            }
        },

        ReatTimeNotify: function (state, action) {
            return {
                ...state,
                reatTimeNotify: action.payload,
            }
        },


    }
})

export default reducer
export const DEPOSIT = TYPE
export const { GetNotify,ReatTimeNotify } = action