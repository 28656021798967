import React, { useRef, useState } from 'react'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { Spin } from 'antd';

import { GetReq12Character } from 'redux/action/orderAction'
import SubmitCharacterTurn from './SubmitCharacterTurn'
import { useTranslate } from 'core/translate'
import { CopyToClipboard } from 'react-copy-to-clipboard'

export default function SecretPassphrase() {

    let [loadding, setLoadding] = useState(false)
    let { list12characters } = useSelector(state => state.other)
    let { profile } = useSelector(state => state.user)
    let { t } = useTranslate()
    const dispatch = useDispatch()
    useEffect(() => {
        setLoadding(true)

        dispatch(GetReq12Character(setLoadding))
    }, [])
    const [active, setActive] = useState(false)
    const handleActice = () => {
        setActive(true)
    }
    let [valueCopy, setValueCopy] = useState(false)
    let [verify, setVerify] = useState(false)
    function handleActiveCopy() {
        setVerify(true)
        setTimeout(() => {
            setVerify(false)
        }, 1500)
    }

    let copyCharacter = String(list12characters?.data).replace(/,/g, ' ')

    return (
        <>
            {profile?.data?.data?.characters == 0 &&
                <>
                    {active ? "" :

                        <div>

                            <div className="css-1dbjc4n r-1wbh5a2 r-xd6kpl r-i023vh r-1g2xzmm" style={{ marginTop: '20px', display: 'flex', alignItems: 'center' }}>
                                <div className="css-1dbjc4n" data-testid="referral-link">
                                    <div data-focusable="true" tabIndex={0} className="css-1dbjc4n r-1loqt21 r-1otgn73 r-1i6wzkk r-lrvibr" data-testid="https://SwapToBe.com/btc/vn/offers/381715-mua-bitcoin-gi%C3%A1-t%E1%BB%91t-v%E1%BB%9Bi-VND-t%E1%BA%A1i-Vi%E1%BB%87t-Nam-qua-fiat-wallet-t%E1%BB%AB-ryanhiga25?ref=3126657" style={{ transitionDuration: '0s' }}>
                                        <div className="css-1dbjc4n r-z2wwpe r-1udh08x">
                                            <div role="button" aria-busy="false" data-focusable="true" tabIndex={0} className="css-18t94o4 css-1dbjc4n r-1loqt21 r-1otgn73 r-1i6wzkk r-lrvibr" style={{ transitionDuration: '0s' }}>
                                                <div className="css-1dbjc4n r-1awozwy r-k200y r-z2wwpe r-18u37iz r-1777fci r-5njf8e" style={{ backgroundColor: 'rgb(94, 88, 98)', borderWidth: 0, minHeight: 40, padding: '8px 16.1px', borderRadius: '4px' }}>
                                                    <div className="css-1dbjc4n" data-testid="icon-clone" style={{ height: 24, width: 24, marginRight: '10px' }}>
                                                        <svg width={24} height={24} viewBox="0 0 32 32" fill="#FFFFFF">
                                                            <path d="M29.728 29.152V9.728q0-.256-.192-.416t-.384-.16H9.728q-.256 0-.416.16t-.16.416v19.424q0 .224.16.384t.416.192h19.424q.224 0 .384-.192t.192-.384zM32 9.728v19.424q0 1.184-.832 2.016T29.152 32H9.728q-1.184 0-2.016-.832t-.864-2.016V9.728q0-1.184.864-2.048t2.016-.832h19.424q1.184 0 2.016.832T32 9.728zm-6.848-6.88v2.88h-2.304v-2.88q0-.224-.16-.384t-.416-.192H2.848q-.224 0-.384.192t-.192.384v19.424q0 .256.192.416t.384.16h2.88v2.304h-2.88q-1.152 0-2.016-.864T0 22.272V2.848Q0 1.664.832.832T2.848 0h19.424q1.184 0 2.048.832t.832 2.016z" />
                                                        </svg>
                                                    </div>
                                                    <CopyToClipboard text={`${copyCharacter}`}
                                                        onCopy={() => setValueCopy({ copied: true })}>
                                                        <button onClick={handleActiveCopy} dir="auto" className="css-901oao r-jwli3a r-320f6p r-ubezar r-oxtfae r-135wba7 r-1jkjb r-rjfia" style={{ backgroundColor: "initial" }}>
                                                            {t("Copy characters")}
                                                            {verify ? <img src="/img/Copy.png" alt="" style={{ width: '24px', marginLeft: '10px' }} /> : ""}

                                                        </button>
                                                    </CopyToClipboard>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="SecretPassphrase" style={{ color: '#000' }}>
                                {loadding ? <div className="example" style={{ margin: '0' }}>
                                    <Spin />
                                </div> : <>
                                    {list12characters?.data?.map((e, i) => {

                                        return (
                                            <>

                                                <div className="SecretPassphrase_btn" key={i} >{i + 1}. {e}</div>


                                            </>

                                        )
                                    })}
                                </>
                                }
                            </div>
                            <div style={{ marginTop: '30px', display: 'flex', justifyContent: 'center' }}>
                                <div onClick={handleActice} className="SecretPassphrase_btn" style={{ color: "#fff", fontWeight: "initial", width: '100px', backgroundColor: '#087972' }}>{t("Next")}</div>
                            </div>
                        </div>}


                    {active &&

                        <SubmitCharacterTurn />}
                </>
            }


        </>
    )
}
