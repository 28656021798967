import { HOMELIST } from "redux/type";

let initState = {
    buyadlist: [],
    selladlist: [],
    vndusd: JSON.parse(localStorage.getItem('vndusd')) || [],
    exchange: [],
    transactionform: []



}


export default function authReducer(state = initState, action) {

    switch (action.type) {
        case HOMELIST:
            localStorage.setItem('exchange', action.payload?.exchange[0]?.data?.data[0]?.raito)
            localStorage.setItem("vndusd", action.payload.vnd[0]?.data?.data[0]?.raito)

            return {
                ...state,
                selladlist: action.payload.sell[0],
                buyadlist: action.payload.buy[0],
                vndusd: action.payload.vnd[0]?.data?.data[0]?.raito,
                exchange: action.payload?.exchange[0]?.data?.data[0]?.raito,
                transactionform: action.payload.transactionform[0]?.data?.data
            }

        default:
            return state;
    }
}