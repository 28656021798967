import React from 'react'

export default function CoinApply() {
    return (
        <div className='coinapply'>
            <div className='container'>
                <div>
                    <ul>
                        <li><span>1</span></li>
                        <li>2</li>
                        <li>3</li>
                    </ul>
                </div>
            </div>
        </div>
    )
}
