import React, { useState } from 'react';
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import blue from "@material-ui/core/colors/blue";
import { useTranslate } from 'core/translate';
import { makeStyles } from '@mui/styles';
import { ErrorMessage } from '../../../core/Message'
import { accpect, arrayPassPort, NoAccpect, proof } from './Data';
import Icon from '../../../../src/assets/img/svg/icon.svg'
import IconErr from '../../../../src/assets/img/svg/iconError.svg'
import Submit from './Submit';

import Proof from 'assets/img/svg/proof.svg'
import avatar from 'assets/img/svg/avatar.svg'
import CCCD from 'assets/img/svg/CCCD.svg'

const useStyles = makeStyles({
    input: {
        display: "none"
    },
    button: {
        color: blue[900],
        margin: 10
    },
    submit:{
        background: "#1d6060",
        width:'150px',
        height:'40px',
        borderRadius:'15px',
        fontWeight:'700'
    },
    img:{
        maxWidth:"200px",
    },
    text: {
        color:"#275645",
        fontWeight:'700',
        textTransform:'uppercase',
        fontFamily:"sans-serif"
        
    },
    boxShadow: {
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        minHeight:'150px',
        borderRadius:'15px',
        width:'100%',
        padding:"5px",
        boxShadow:"rgba(0,0,0,0.1) 0px 2px 2px",
   
        "&:hover":{
            boxShadow:"rgba(0,0,0,0.24) 0px 3px 8px",
        }
    },
    boxText:{
        padding:"10px"
    },
    center:{
        display:'flex',
        justifyContent:'center',
        alignItems:'center'
    },
    flex:{
        display:'flex',
        alignItems:'center',
        marginBottom:'5px'
    },
    mainButton:{
        display:'flex',
        gap:"20px",
        justifyContent: "space-evenly",
        flexWrap: "wrap"
    }

});
function UploadImg({image,setImage,setNext, valueInput}) {
    const classes = useStyles();
    const { t } = useTranslate()
 


    function CheckImg(e) {
        if (e.target.files[0].type === "image/jpeg" || e.target.files[0].type === "image/png") {
            return true
        } else {
            return false
        }
    }
   
    const handleChange = (e) => {
        let name = e.target.name
        const check = CheckImg(e)
        if(check){
            setImage({...image, [name]: e.target.files[0]})
        }else
        ErrorMessage("Only accept jpg,png,svg image files under 5m", t)

    }
    
    

    const renderInitialState = (data) => {
        return (
            <>
                <CardContent  style={{display:'flex', flexDirection:'column', gap:"30px"}}>
                    {data?.map((e) => {
                        return (
                    <Grid container justify="center" alignItems="center" >
                    <input
                        accept="image/*"
                        className={classes.input}
                        id={`contained-button-file-${e?.name}`}
                        multiple
                        type="file"
                        onChange={handleChange}
                        name={e?.name}
                    />
                     <div className={classes?.boxShadow}>
                        <label htmlFor={`contained-button-file-${e?.name}`} className={classes.img} >
                            {image[e?.name] ? <div className='ImageVerification_img' style={{ textAlign: 'center' }}>
                            <img className={classes.img} src={URL?.createObjectURL(image[e?.name])} style={{ maxWidth: '100%',maxHeight:'100%'  }}/>
                </div> :
                <>
                     <p className={classes.text } style={{     textAlign:'center', margin:0}}>{e?.text}</p>
                        <img src="/img/upload.jpg" alt="" style={{ maxWidth: '150px' }} />
               </>
                            }
                        </label>
                    </div>
                 
              
            </Grid>
                        )
                    })}
                </CardContent>
            </>
        )
    }
    return (
        <>
         <div className="item">
                            <span style={{textAlign:'center'}}>Upload</span>
                            <div className={classes.boxText}>
                            <div style={{marginBottom:'10px'}}>
                                   We accept
                            </div >
                            {accpect?.map((e) => {
                                return (
                                    <div className={classes.flex}>
                                <img src={Icon} alt="" style={{width:"20px", marginBottom:'5px', marginRight:"5px"}} />
                                <p style={{margin:0, textTransform:'initial', fontWeight:500}}> {e?.text}</p> </div>
                                )
                            })}
                             <div style={{marginBottom:'10px'}}>
                                   We don't accept
                            </div >
                            {NoAccpect?.map((e) => {
                                return (
                                    <div className={classes.flex}>
                                <img src={IconErr} alt="" style={{width:"20px", marginBottom:'5px', marginRight:"5px"}} />
                                <p style={{margin:0, textTransform:'initial', fontWeight:500}}> {e?.text}</p> </div>
                                )
                            })}
                     
                            </div>
                        </div>
          
            <div className='depositvnd-input checkvnd-content' style={{ justifyContent: 'center', boxShadow: '0px 1px 3px 0px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 2px 1px -1px rgb(0 0 0 / 12%);' }}>
          
                <div>
                    <p className={classes.text}>Passport/ID/CCCD Photo</p>
                    <img src={CCCD} alt="" />
                    {renderInitialState(arrayPassPort)}
                </div>
                <div>
                    <p className={classes.text}>Proof (receipt) Photo (3 months back)</p>
                <img src={Proof} alt="" />
                    {renderInitialState(proof)}
                </div>
          
            </div>
           <div className={classes.mainButton}>
            <button className={classes.submit} onClick={() => setNext(false)} style={{background:'brown'}}>Back</button>
            <Submit valueInput={valueInput} image={image}/>

           </div>
        </>
    );
}

export default UploadImg;