import axios from 'axios'
import { port } from '../port'

export default {
    login: (data) => {
        return axios(`${port}api/user/login`, {
            method: 'POST',
            data: data
        })
    },
    signup: (data) => {
        return axios(`${port}api/user/signup`, {
            method: 'POST',
            data: data
        })
    },
    verifyEmail: (TokenUser) => {
        return axios(`${port}api/user/verifyEmail/${TokenUser}`, {
            method: 'GET',
        })
    },
    generateOTPToken: (token) => {
        return axios(`${port}api/user/generateOTPToken`, {
            headers: {
                'Authorization': 'Bearer ' + token
            },
            method: 'POST',
        })
    },
    turnOn2fa: (token, data) => {
        return axios(`${port}api/user/turnOn2FA`, {
            headers: {
                'Authorization': 'Bearer ' + token
            },
            method: 'POST',
            data: {

                "otp": data
            }
        })
    },
    turnOff2fa: (token, data) => {
        return axios(`${port}api/user/turnOff2FA`, {
            headers: {
                'Authorization': 'Bearer ' + token
            },
            method: 'POST',
            data: {

                "otp": data
            }
        })
    },

    uploadKyc: (frontImage, backImage, sefierImage, userid) => {
        const data = new FormData()
        data.append('photo', frontImage)
        data.append('photo', backImage)
        data.append('photo', sefierImage)
        data.append('userid ', userid)
        return axios(`${port}api/uploadKyc`, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
            method: 'POST',
            data,

        })
    },
    getProfile: (token) => {
        return axios(`${port}api/user/getProfile`, {
            headers: {
                'Authorization': 'Bearer ' + token
            },
            method: 'POST',
        })
    },
    getWallet: (token) => {
        return axios(`${port}api/user/getWallet`, {
            headers: {
                'Authorization': 'Bearer ' + token
            },
            method: 'POST',
        })
    },

    country: () => {
        return axios(`${port}api/user/getCountry`, {
            method: 'GET',
        })
    },
    exchange: () => {
        return axios(`${port}api/p2p/exchange`, {
            method: 'GET',
        })
    },
    addBuyP2P: (token, name, amountExchange, amountCoin, amountMaximum, id, tokenRecaptcha, value2fa) => {
        return axios(`${port}api/p2p/addBuyP2P`, {
            headers: {
                'Authorization': 'Bearer ' + token
            },
            method: 'POST',
            data: {

                "symbol": name,
                "amountExchange": amountExchange,
                "amountCoin": amountCoin,
                "amountMaximum": amountMaximum,
                "typeExchange": id,
                "tokenRecaptcha": tokenRecaptcha,
                "otp": value2fa
            }
        })
    },
    addSellP2P: (token, name, amountExchange, amountCoin, amountMaximum, id, tokenRecaptcha, value2fa) => {

        return axios(`${port}api/p2p/addSellP2P`, {
            headers: {
                'Authorization': 'Bearer ' + token
            },
            method: 'POST',
            data: {
                "symbol": name,
                "amountExchange": amountExchange,
                "amountCoin": amountCoin,
                "amountMaximum": amountMaximum,
                "typeExchange": id,
                "tokenRecaptcha": tokenRecaptcha,
                "otp": value2fa
            }
        })
    },
    buyP2Padlist: (token, amountCoin, id, symbol, typeExchange, tokenRecaptcha, value2fa) => {
        return axios(`${port}api/p2p/buyP2Padlist`, {
            headers: {
                'Authorization': 'Bearer ' + token
            },
            method: 'POST',
            data: {

                "amountCoin": amountCoin,
                "idP2P": id,
                "symbol": symbol,
                "typeExchange": typeExchange,
                "tokenRecaptcha": tokenRecaptcha,
                "otp": value2fa
            }
        })
    },
    sellP2Padlist: (token, amountCoin, id, symbol, typeExchange, tokenRecaptcha, value2fa) => {
        return axios(`${port}api/p2p/sellP2Padlist`, {
            headers: {
                'Authorization': 'Bearer ' + token
            },
            method: 'POST',
            data: {

                "amountCoin": amountCoin,
                "idP2P": id,
                "symbol": symbol,
                "typeExchange": typeExchange,
                "tokenRecaptcha": tokenRecaptcha,
                "otp": value2fa
            }
        })
    },
    buysell: () => {
        return axios(`${port}api/p2p/exchange/buysell`, {
            method: 'GET',
        })
    },
    vndusd: () => {
        return axios(`${port}api/p2p/exchange/vndusd`, {
            method: 'GET',
        })
    },

    //API lấy hình thức giao dịch
    getTransactionForm: () => {
        return axios(`${port}api/p2p/getTransactionForm`, {
            method: 'GET',
        })
    },





    buyadlist: (name, page) => {

        return axios(`${port}api/p2p/buyadlist`, {
            method: 'POST',
            data: {
                "limit": 5,
                "page": page,
                "symbol": name

            }
        })
    },
    selladlist: (name, page) => {

        return axios(`${port}api/p2p/selladlist`, {
            method: 'POST',
            data: {
                "limit": 5,
                "page": page,
                "symbol": name

            }
        })
    },
    buypackage: (token, value, id, symbol, amount, tokenRecaptcha, value2fa) => {
        return axios(`${port}api/user/buypackage`, {
            headers: {
                'Authorization': 'Bearer ' + token
            },
            method: 'POST',
            data: {
                "month": value,
                "idPrice": id,
                "symbol": symbol,
                "amount": amount,
                "tokenRecaptcha": tokenRecaptcha,
                "otp": value2fa
            }

        })
    },
    ledding: (token, value, symbol, tokenRecaptcha, value2fa) => {
        return axios(`${port}api/user/ledding`, {
            headers: {
                'Authorization': 'Bearer ' + token
            },
            method: 'POST',
            data: {
                "amount": value,
                "symbol": symbol,
                "tokenRecaptcha": tokenRecaptcha,
                "otp": value2fa
            }

        })
    },
    createWallet: (token, symbol) => {
        return axios(`${port}api/user/createWallet`, {
            headers: {
                'Authorization': 'Bearer ' + token
            },
            method: 'POST',
            data: {
                "symbol": symbol,
            }
        })
    },
    createWalletERC20: (token, symbol) => {
        return axios(`${port}api/user/createWalletERC20`, {
            headers: {
                'Authorization': 'Bearer ' + token
            },
            method: 'POST',
            data: {
                "symbol": symbol,
            }
        })
    },
    updateWalletDepositTRC20: (token, symbol) => {

        return axios(`${port}api/user/updateWalletDepositTRC20`, {
            headers: {
                'Authorization': 'Bearer ' + token
            },
            method: 'POST',
            data: {
                "symbol": symbol,
            }

        })
    },
    //chuyển tiền user
    transferToAddress: (token, symbol, amount, to_address, tokenRecaptcha, value2fa, typeWithdrawCoin) => {

        return axios(`${port}api/user/transferToAddress `, {
            headers: {
                'Authorization': 'Bearer ' + token
            },
            method: 'POST',
            data: {
                "symbol": symbol,
                "amount": amount,
                "to_address": to_address,
                "tokenRecaptcha": tokenRecaptcha,
                "otp": value2fa,
                'type': typeWithdrawCoin

            }

        })
    },

    transferToUserName: (token, symbol, amount, username, note, tokenRecaptcha, value2fa) => {
        return axios(`${port}api/user/transferToUserName`, {
            headers: {
                'Authorization': 'Bearer ' + token
            },
            method: 'POST',
            data: {
                "symbol": symbol,
                "amount": amount,
                "userName": username,
                "tokenRecaptcha": tokenRecaptcha,
                "otp": value2fa,
                "note": note
            }


        })
    },

    //convert coin
    convertcoin: (token, symbolForm, symbolTo, coin, tokenRecaptcha, value2fa, Type) => {


        return axios(`${port}api/v2/convertcoin`, {
            headers: {
                'Authorization': 'Bearer ' + token
            },
            method: 'POST',
            data: {
                "symbolForm": symbolForm,
                "symbolTo": symbolTo,
                "amountForm": coin,
                "tokenRecaptcha": tokenRecaptcha,
                "otp": value2fa,
                'type': Type
            }

        })
    },
    convertcoinExchange: (token, symbolForm, symbolTo, coin, amountExchange, tokenRecaptcha, value2fa, Type) => {
        console.log(tokenRecaptcha);


        return axios(`${port}api/v2/convertcoinExchange`, {
            headers: {
                'Authorization': 'Bearer ' + token
            },
            method: 'POST',
            data: {
                "symbolForm": symbolForm,
                "symbolTo": symbolTo,
                "amountForm": coin,
                "amountExchange": amountExchange,
                "tokenRecaptcha": tokenRecaptcha,
                "otp": value2fa,
                'type': Type

            }

        })
    },
    // swap
    convertbusd: (token, symbolbusd, symbolusdt, coin) => {


        return axios(`${port}api/user/convertbusd`, {
            headers: {
                'Authorization': 'Bearer ' + token
            },
            method: 'POST',
            data: {
                "symbolForm": symbolbusd,
                "symbolTo": symbolusdt,
                "amountForm": coin,

            }

        })
    },
    //sendmailforgetpassword
    sendmailforgetpassword: (email) => {
        return axios(`${port}api/user/sendmailforgetpassword`, {
            method: 'POST',
            data: {
                "email": email,
            }

        })
    },
    //forgetpassword
    forgetpassword: (token, data) => {


        return axios(`${port}api/user/forgetpassword`, {
            headers: {
                'Authorization': 'Bearer ' + token
            },
            method: 'POST',
            data,


        })
    },
    //update nap
    depositUSDTTRC20: (token,) => {
        return axios(`${port}api/user/depositUSDTTRC20`, {
            headers: {
                'Authorization': 'Bearer ' + token
            },
            method: 'POST',
        })
    },
    //trades

    selladlistuser: (token, page) => {
        return axios(`${port}api/p2p/selladlistuser`, {
            headers: {
                'Authorization': 'Bearer ' + token
            },
            method: 'POST',
            data: {
                "limit": "10",
                "page": page
            }

        })
    },
    buyadlistuser: (token, page) => {
        return axios(`${port}api/p2p/buyadlistuser`, {
            headers: {
                'Authorization': 'Bearer ' + token
            },
            method: 'POST',
            data: {
                "limit": "10",
                "page": page
            }

        })
    },
    buyadlistuserpendding: (token, page) => {
        return axios(`${port}api/p2p/buyadlistuserpendding`, {
            headers: {
                'Authorization': 'Bearer ' + token
            },
            method: 'POST',
            data: {
                "limit": "10",
                "page": page
            }

        })
    },
    selladlistuserpendding: (token, page) => {
        return axios(`${port}api/p2p/selladlistuserpendding`, {
            headers: {
                'Authorization': 'Bearer ' + token
            },
            method: 'POST',
            data: {
                "limit": "10",
                "page": page
            }

        })
    },

    createApiToken: (url, token, data) => {
        return axios(url, {
            headers: {
                'Authorization': 'Bearer ' + token
            },
            method: 'POST',
            data

        })
    },
    createApiUser: (url, data) => {
        return axios(url, {

            method: 'POST',
            data

        })
    },
    //news
    news: () => {
        return axios(`${port}api/user/getnews`, {
            method: 'GET',
        })
    },
  

    //getbanking
    getbanking: () => {
        return axios(`${port}api/user/getbanking`, {
            method: 'GET',
        })
    },
    //checkTransactionDepositVnd
    checkTransactionDepositVnd: (token) => {
        return axios(`${port}api/user/checkTransactionDepositVnd`, {
            headers: {
                'Authorization': 'Bearer ' + token
            },
            method: 'POST',
        })
    },

   
    //history nap
    historydepositvnd: (token, page) => {
        return axios(`${port}api/user/historydepositvnd`, {
            headers: {
                'Authorization': 'Bearer ' + token
            },
            method: 'POST',
            data: {
                "limit": "5",
                "page": page
            }

        })
    },
    //get list banking
    getlistbankinguser: (token, page) => {
        return axios(`${port}api/user/getlistbankinguser`, {
            headers: {
                'Authorization': 'Bearer ' + token
            },
            method: 'POST',
            data: {
                "limit": "5",
                "page": page
            }

        })
    },
    //History Swap
    historyswap: (token, limit, page, symbolForm, symbolTo) => {
        return axios(`${port}api/user/historyswap`, {
            headers: {
                'Authorization': 'Bearer ' + token
            },
            method: 'POST',
            data: {
                "limit": limit,
                "symbolForm": symbolForm,
                "symbolTo": symbolTo,
                "page": page,
            }

        })
    },
    //History Lending
    historylendding: (token, page) => {
        return axios(`${port}api/user/historylendding`, {
            headers: {
                'Authorization': 'Bearer ' + token
            },
            method: 'POST',
            data: {
                "limit": "5",
                "page": page
            }

        })
    },
    //history lending time
    historylenddingtime: (token, limit, page) => {
        return axios(`${port}api/user/historylenddingtime`, {
            headers: {
                'Authorization': 'Bearer ' + token
            },
            method: 'POST',
            data: {
                "limit": limit,
                "page": page
            }

        })
    },
    // //History Staking
    historystaking: (token, page) => {
        return axios(`${port}api/user/historystaking`, {
            headers: {
                'Authorization': 'Bearer ' + token
            },
            method: 'POST',
            data: {
                "limit": "5",
                "page": page
            }

        })
    },

    //Interest staking
    historyinterestpackage: (token, page, name) => {
        return axios(`${port}api/user/historyinterestpackage`, {
            headers: {
                'Authorization': 'Bearer ' + token
            },
            method: 'POST',
            data: {
                "limit": "5",
                "page": page,
            }
        })
    },
    //HistoryCommission staking
    getHistoryCommission: (token, page) => {
        return axios(`${port}api/v2/getHistoryCommission`, {
            headers: {
                'Authorization': 'Bearer ' + token
            },
            method: 'POST',
            data: {
                "limit": "5",
                "page": page,

            }

        })
    },
    // //historywidthdraw  
    gethistorywidthdraw: (token, page, symbol) => {
        return axios(`${port}api/user/gethistorywidthdraw`, {
            headers: {
                'Authorization': 'Bearer ' + token
            },
            method: 'POST',
            data: {
                "limit": "5",
                "page": page,
                "symbol": symbol
            }

        })
    },

    historywidthdraw: (token, page, symbol, address) => {

        return axios(`${port}api/user/historywidthdraw`, {
            headers: {
                'Authorization': 'Bearer ' + token
            },
            method: 'POST',
            data: {
                "limit": "5",
                "page": page,
                "symbol": symbol,
                "address": address
            }

        })
    },
    //danh sach coin TRC20

    gettrc20: () => {
        return axios(`${port}api/user/gettrc20`, {
            method: 'GET',
        })
    },
    //history lai lending
    interestlending: (token, page, symbol) => {

        return axios(`${port}api/history/interestlending`, {
            headers: {
                'Authorization': 'Bearer ' + token
            },
            method: 'POST',
            data: {
                "limit": "5",
                "page": page,
                "symbol": symbol
            }

        })
    },
    //history desposit coin
    historydepositcoin: (token, page, symbol) => {
        return axios(`${port}api/history/deposit`, {
            headers: {
                'Authorization': 'Bearer ' + token
            },
            method: 'POST',
            data: {
                "symbol": symbol,
                "limit": 5,
                "page": page
            }
        })
    },
    //history desposit coin
    historytranfer: (token, page, symbol) => {
        return axios(`${port}api/user/historytransfer`, {
            headers: {
                'Authorization': 'Bearer ' + token
            },
            method: 'POST',
            data: {
                "symbol": symbol,
                "limit": 5,
                "page": page
            }
        })
    },

    //getnotification
    getnotification: (token, limit, page) => {

        return axios(`${port}api/user/getnotification`, {
            headers: {
                'Authorization': 'Bearer ' + token
            },
            method: 'POST',
            data: {
                "limit": limit,
                "page": page
            }


        })
    },
    //updatePassword
    updatepassword: (token, data) => {

        return axios(`${port}api/user/updatepassword`, {
            headers: {
                'Authorization': 'Bearer ' + token
            },
            method: 'POST',
            data: data


        })
    },

    //getParent
    getParent: (token) => {
        const data = {
            idUser: token
        }
        return axios(`${port}api/user/parent`, {
            data,
            method: "POST",
        });
    },


    //Phí rút 

    getfee: (name) => {

        return axios(`${port}api/v2/getfee`, {

            method: 'POST',
            data: {
                "name": name

            }


        })
    },


    getfeeERC20: (name) => {

        return axios(`${port}api/user/getexchange`, {

            method: 'POST',
            data: {
                "name": name

            }


        })
    },

    //ref signup
    createRefSigup: (url) => {
        return axios(url, {
            method: 'POST',
        })
    },

    //send main 
    sendmail: (data) => {
        return axios(`${port}api/user/sendmail`, {

            method: 'POST',
            data: {
                "email": data
            }


        })
    },

    //getListPackage  staking
    getListPackage: () => {
        return axios(`${port}api/user/getListPackage`, {
            method: 'POST',
        })
    },
    //character

}