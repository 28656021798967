import { Spin } from 'antd'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslate } from '../core/translate'
import { axiosApi } from '../redux/action/authAction'

export default function Popup2fa({ userName, password }) {
    let [value, setValue] = useState()
    let { t } = useTranslate()
    let [error, setError] = useState()
    function inputChange(e) {

        let text = e.target.value
        let value = ""


        for (let index = 0; index < text.length; index++) {
            if (text[index].match(/[0-9]/))
                value += text[index]

        }
        if (value == "") {
            setError("Không được để trống")
        } else {
            setError()
        }
        setValue(value)


    }
    let [loadding, setLoadding] = useState(false)
    let [tokenR, setTokenR] = useState()

    const dispatch = useDispatch()
    function submit(e) {
        setLoadding(true)
        e.preventDefault()
        let form = {
            userName, password, otp: value
        }
        dispatch(axiosApi(form, t, setLoadding, setTokenR))
    }
    return (
        <>

            <form style={{ zIndex: 1001, position: 'fixed', top: '50%', left: '50%', transform: "translate(-50%,-50%)", width: '100%' }}>
                <div>
                    <div className="container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <div style={{ width: '580px', background: "#fff", color: '#000', borderRadius: '4px', height: '280px', display: "flex", flexDirection: 'column' }}>
                            <div className="authenticatormain_title" style={{ textAlign: 'center', background: '#4BBD99', borderTopLeftRadius: '4px', borderTopRightRadius: '4px', padding: '20px' }}>
                                <p style={{ margin: 0, fontFamily: 'Arial', fontSize: '16px', color: "#fff" }}>{t("Code")} 2FA *</p>

                            </div>
                            <div style={{ padding: "30px 20px", textAlign: 'center' }}>
                                <div style={{ marginBottom: '10px' }}><span style={{ fontSize: '16px', fontFamily: 'Arial' }}>{t("Insert code")} 2FA</span></div>
                                <div className="authenticatormain_input" >
                                    <input type="text" placeholder="Code 2FA" onChange={inputChange} value={value} style={{ textAlign: 'center' }} />
                                </div>
                                {error ? <div style={{ marginTop: '10px' }}><span className='text-danger'>{error}</span></div> : ''}
                                <div className="authenticatormain_button" >
                                    {loadding ? <div className="example">
                                        <Spin />
                                    </div> :
                                        <button onClick={submit} style={{ width: '100%', color: '#fff', background: '#4BBD99', padding: '8px', borderRadius: '4px', fontSize: '16px', marginTop: '20px', fontFamily: "Arial" }}>{t('Submit')}</button>}
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </form>
        </>
    )
}
