import React, { useEffect, useState } from 'react'
import useFormValidate from '../core/useFormValidate'
import { useDispatch } from 'react-redux';
import { axiosApi } from '../redux/action/authAction';
import { useSelector } from 'react-redux';
import { NavLink, Redirect } from 'react-router-dom';
import { useTranslate } from '../core/translate';
import { Spin } from 'antd';
import "antd/dist/antd.css";
import Popup2fa from './Popup2fa';
import ShadowLayer from './ShadowLayer';

import { toggleShadow } from '../redux/action/toggleShadowActive';
import ReCAPTCHA from 'react-google-recaptcha';
import { useRef } from 'react';
import CreateApi from 'api/CreateApi';



export default function LoginApi() {
    const { loginError, axiologin, status } = useSelector(state => state.auth)

    let [loadding, setLoadding] = useState(false)
    let reRef = useRef()
    const [token, setStateToken] = useState('')


    let { t } = useTranslate()
    useEffect(() => {
        let tab = t("Login | swaptobe, swaptube")
        document.title = tab
        return () => {
            document.title = t("Swap tobe")
        }

    }, [t]);



    const dispatch = useDispatch()

    const shadowLayer = useSelector(state => state.shadowLayer)

    const { flag } = shadowLayer


    let [userName, setUserName] = useState('')
    let [active, setActive] = useState(false)
    let [error, setError] = useState()
    let [errPass, setErrorPass] = useState()
    async function inputChange(e) {
        let userName = e.target.value
        if (userName == '') {
            setError('không được để trống')
        } else {
            setError()
        }
        setUserName(userName)

    }
    let [password, setPassWord] = useState()
    function inputChangePass(e) {
        let value = e.target.value
        if (value == '') {
            setErrorPass('không được để trống')

        } else {
            setErrorPass()
        }
        setPassWord(value)
    }

    let [errRe, setErrRe] = useState("")
    let [tokenR, setTokenR] = useState()
    function ErroRepcatcha(token) {
        if (token) {
            setTokenR(token)
            setErrRe("")
        }

    }
    async function _login(e) {
        e.preventDefault();
        if (tokenR) {
            setLoadding(true)
            try {
                let res = await CreateApi.CreateApiSS(`api/user/checkuser2fa`, { userName })
                if (res?.data?.status == true) {
                    setActive(true)
                    setLoadding(false)
                    dispatch(toggleShadow(!flag))
                }
            } catch (error) {
                setActive(false)
                let form = {
                    userName, password
                }
                dispatch(axiosApi(form, t, setLoadding, setTokenR))
            }
        } 
        else {
            setErrRe("Please enter ReCAPTCHA")
        }
    }
    if (axiologin) {
        return <Redirect to='/p2p' />
    }


    return (
        <>
            {flag ?
                <>
                    <div style={{ position: 'relative' }}>
                        <Popup2fa userName={userName} password={password} />
                        <ShadowLayer />
                    </div> </> : ''}
            <div loading="lazy" className="newlogins">
                <div className="container">
                    <div className="newlogins-logo">
                        <img loading="lazy" src="img/logoswapcolorful.png" alt="" />
                    </div>
                    <form action="#" id="myForm">
                        <div className="row newlogin">
                            <div className="col-lg-6 col-md-12 newlogin-main">
                                <div className="newlogin-title">
                                    <h3>{t("Sign in")}</h3>
                                    {
                                        status === false ? <h4 style={{ color: 'red', textAlign: 'center', marginBottom: 20 }} >{t(loginError)}</h4> : ""
                                    }
                                </div>
                                <div className="newlogin-input">
                                    <p>{t("Enter your username")}</p>
                                    <input className="input-login" type="text" name='userName' onChange={inputChange} />
                                    {error ? <span style={{ marginTop: '12px' }} className="text-danger">{error}</span> : ''}
                                </div>
                                <div className="newlogin-input">
                                    <p>{t("Enter your password")}</p>
                                    <input className="input-login" type="password" name='password' onChange={inputChangePass} />
                                    {errPass ? <span style={{ marginTop: '12px' }} className="text-danger">{errPass}</span> : ''}
                                </div>
                                <ReCAPTCHA
                                    ref={reRef}
                                    sitekey="6LdflhodAAAAAJXLVHcko0p8_6QYYTXFTaOwPUA4"
                                    onChange={async (token) => { ErroRepcatcha(token) }}
                                    onExpired={(e) => {
                                        setStateToken("");
                                    }}
                                />
                                {errRe && <p className=" errorLogin" style={{ marginTop: '0' }}>{errRe}</p>}
                                {loadding ? <div className="example">
                                    <Spin />
                                </div> :
                                    <button className='submit-newlogin' type="submit" onClick={_login} >LOGIN</button>
                                }
                                <div className="link">
                                    <NavLink to="/verification-password">{t("Forgot password")}?</NavLink>
                                    <NavLink to="/SignUp">{t("Don't have an account? Sign Up")}</NavLink>
                                </div>

                            </div>
                        </div>
                    </form>
                </div>
            </div>






        </>
    )
}
