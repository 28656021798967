import CreateApi from "api/CreateApi"
import reduxToolkit from "../../core/reduxToolkit"

const initialState = {
    valueListting: [],

}








let { reducer, action, TYPE } = reduxToolkit({
    name: 'listting',
    initialState,
    reducers: {

        ValueListting: function (state, action) {

            return {
                ...state,
                valueListting: action.payload
            }
        },

    }
})

export default reducer
export const WITHDRAW = TYPE
export const { ValueListting } = action