import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { Redirect, useHistory, useParams } from 'react-router-dom';

import { useTranslate } from '../../core/translate';
import { verifyemail } from '../../redux/action/authAction';


export default function VerifyEmail() {
    let history = useHistory()
    const dispatch = useDispatch()
    let { slug } = useParams()
    let { t } = useTranslate()
    const token = slug
    const { loginError, axiologin, status } = useSelector(state => state.auth)

    useEffect(() => {
        dispatch(verifyemail(token, t))
    }, [])

    if (axiologin) {
        return <Redirect to='/p2p' />
    }
    return (
        <>




        </>

    )
}
