import covertCoin from 'core/CovertCoin'
import { ErrorMessage } from 'core/Message'

export const filterPricecoin = (pricecoin, slug, getwallet) => {
    let name
    let Feegas
    let blockchainWallet
    let feeWidthdraw
    let minimumWidthdraw
    pricecoin?.filter((e) => {
        if (slug == e?.name) {
            feeWidthdraw = e?.fee_widthdraw
            minimumWidthdraw = e?.minimum_widthdraw
            name = covertCoin(e?.blockchain, 'PAYMENT')
            blockchainWallet = getwallet?.[`${name.toLowerCase()}_balance`]
            if (blockchainWallet > e?.fee_widthdraw) {
                Feegas = true
                return Feegas
            }
            else {
                Feegas = false
                return Feegas
            }


        }
    })
    return { Feegas, blockchainWallet, name, feeWidthdraw, minimumWidthdraw }
}

export const GetWalletSlug = (slug, type, getwallet) => {
    let coverCoinName = covertCoin(slug, type)

    let getWalletCoins = getwallet?.[`${coverCoinName.toLowerCase()}_balance`]

    return getWalletCoins
}
export const MinimumWidthdraw = (pricecoin, slug, getwallet, t) => {

    let { Feegas, name } = filterPricecoin(pricecoin, slug, getwallet)


    if (Feegas == false) {
        ErrorMessage('Fee gas is not enough', t)
    }
    return Feegas

}

export const RenderFeeGas = (pricecoin, slug, getwallet, t) => {

    let { blockchainWallet, name } = filterPricecoin(pricecoin, slug, getwallet)
    if (name === slug) {
        return (
            <div></div>
        )
    } else

        return (
            <div>
                <div>
                    {t(`Surplus`)} {name}: {blockchainWallet || 0}
                </div>
            </div>
        )
}

export const GetMinimumWidthdraw = (pricecoin, slug) => {
    let { minimumWidthdraw } = filterPricecoin(pricecoin, slug)

    return minimumWidthdraw

}