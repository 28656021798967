import React, { useEffect, useState } from 'react'

export default function Transfer() {

    let [time, setTime] = useState(900)
    let day = parseInt(time / 60 / 60 / 24)
    let hours = parseInt(time / 60 / 60 - day * 24)
    let minute = parseInt(time / 60 - (day * 24 + hours) * 60)
    let seconds = time % 60



    useEffect(() => {
        const timeInterval = setTimeout(() => {
            setTime(time - 1)

        }, 1000)
        if (time === 0) {
            clearInterval(timeInterval)
        }

    })
    return (
        <>
            <div className="transfer">
                <div className="container">
                    <div className="transfer_top">
                        <div className="transfer_top-left">
                            <h4>Mua USDT</h4>
                        </div>
                        <div className="transfer_top-right">
                            <div className="transfer_text">
                                <p>Thời gian tạo: <span>2021-07-15 14:57:26</span></p>
                            </div>
                            <div className="transfer_text">
                                <p>Số lệnh: <span>20248897726878982144</span></p>
                            </div>
                        </div>
                    </div>
                    <div className="transfer_center">
                        <div className="transfer_center-top">
                            <div className="amount">
                                <p>Số lương: </p>
                                <span style={{ fontSize: '20px', color: "green", fontWeight: '700' }}>5.000.000d</span>
                            </div>
                            <div className="price">
                                <p style={{ marginBottom: "7px" }}>Giá </p>
                                <span>23,433.00 VND</span>
                            </div>
                            <div className="amount2">
                                <p style={{ marginBottom: "7px" }}>Số lương: </p>
                                <span>213.37 USDT</span>
                            </div>
                        </div>
                        <div className="transfer_center-main">
                            <div className="transfer_des">
                                <p>Vui lòng xác nhận là bạn đã chuyển tiền thành công cho người bán bằng những phương thức thanh toán dưới đây</p>
                            </div>
                            <div className="transfercenter-content">
                                <div className="row">
                                    <div className=" col-md-4" style={{ borderRight: '1px solid rgb(174, 180, 188)' }}>
                                        <span style={{ fontWeight: 500 }}>Chuyển khoản ngân hàng</span>
                                    </div>
                                    <div className="transfercenter-infos col-md-6">
                                        <div className="transfercenter-info">
                                            <p>Tên</p>
                                            <span style={{ textTransform: "uppercase" }}>Trần Ngọc Tiến</span>
                                        </div>

                                        <div className="transfercenter-info">
                                            <p>Tên tài khoản ngân hàng / số thẻ</p>
                                            <span >123456789</span>
                                        </div>
                                        <div className="transfercenter-info">
                                            <p>Tên ngân hàng</p>
                                            <span>vietcombank</span>
                                        </div>
                                        <div className="transfercenter-info">
                                            <p>Chi nhánh</p>
                                            <span>vietcombank</span>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="transferbottom" >
                                <div className="transferbottom-list">
                                    <div className="transferbottom-list-item">
                                        <p style={{ fontSize: '20px', color: 'black' }}>Thanh toán cần được thực hiện trong: <span style={{ color: 'red' }}>{minute < 10 ? `0${minute}` : minute} : {seconds < 10 ? `0${seconds}` : seconds}</span></p>
                                        <p>Hãy thanh toán trong vòng 15 phút, nếu không lệch sẽ bị huỷ</p>
                                    </div>
                                    <div className="transferbottom-list-button">
                                        <a className="transferbottom-input">Đã chuyển tiền, tiếp theo</a>
                                        <a >Huỷ lệnh</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
