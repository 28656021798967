import User from "api/User"
import { createA } from "redux/action/userAction"
import { Createwalleterc } from "redux/reducer/depositReducer"
import { Createwallettrc } from "redux/reducer/depositReducer"
import { Createwallet } from "redux/reducer/depositReducer"
import { SymbolHistory } from "redux/reducer/withdrawReducer"
import { TypeWithdrawCoin } from "redux/reducer/withdrawReducer"
import { createHistorywidthdrawAdress } from "../../../redux/action/userAction"


const napTRC = async (token, page, coin, dispatch) => {

    try {
        let res = await User.updateWalletDepositTRC20(token, coin)
        dispatch(Createwallettrc(res))
        dispatch(createHistorywidthdrawAdress(token, page, coin, res?.data?.data))
    } catch (error) {
    }

}
const napCoin = async (token, page, coin, dispatch) => {
    try {

        if (coin != "USDT" && coin != 'SHIB' && coin != 'BNB' && coin != 'WIN') {
            let res = await User.createWallet(token, coin)
            if (res) {
                dispatch(createHistorywidthdrawAdress(token, page, coin, res?.data?.data?.address))
                dispatch(Createwallet(res))
            }
        }
    } catch (error) {
    }
}




export const gethistory = async (token, page, coin, type, dispatch, symbol) => {
    if (type == 'PAYMENT') {
        napCoin(token, page, coin, dispatch, symbol)
    }
    else {
        napTRC(token, page, coin, dispatch)
    }

}


const napUSDT = async (token, page, coin, dispatch) => {
    try {
        let res = await User.createWallet(token, `${coin}`)
        if (res) {
            dispatch(Createwallet(res))
            dispatch(createHistorywidthdrawAdress(token, page, `USDT`, res?.data?.data?.address))
        }
    } catch (error) {
    }

}
const napUSDTBEP20 = async (token, page, coin, dispatch) => {
    try {
        let res = await User.createWallet(token, `${coin}`)
        if (res) {
            dispatch(Createwallet(res))
            dispatch(createHistorywidthdrawAdress(token, page, `${coin}`, res?.data?.data?.address))
        }
    } catch (error) {
    }

}

const napUSDTERC = async (token, page, coin, dispatch) => {
    try {
        let res = await User.createWalletERC20(token, coin)
        dispatch(Createwalleterc(res))
        if (res) {
            dispatch(createHistorywidthdrawAdress(token, page, coin, res?.data?.data))
        }
    } catch (error) {
    }
}

export const gethistoryusdt = (token, page, symbol, dispatch) => {
    if (symbol == 'USDT.TRC20') {
        napUSDT(token, page, `${symbol}`, dispatch)
        dispatch(TypeWithdrawCoin('TRC20'))

    }
    else if (symbol == 'USDT.BEP20') {
        napUSDTBEP20(token, page, symbol, dispatch)
        dispatch(TypeWithdrawCoin('BEP20'))

    }
    else {
        napUSDTERC(token, page, `${symbol}`, dispatch)
        dispatch(TypeWithdrawCoin('ERC20'))

    }

}
export const gethistoryWin = async (token, page, symbol, dispatch) => {
    if (symbol == 'WIN.BEP20') {
        let res = await User.createWallet(token, 'WIN.BEP20')
        if (res) {
            dispatch(TypeWithdrawCoin('BEP20'))
            dispatch(createHistorywidthdrawAdress(token, page, 'WIN.BEP20', res?.data?.data?.address))
        }

    }


}

export const gethistoryshib = async (token, page, symbol, dispatch) => {
    if (symbol == 'SHIB.BEP20') {
        let res = await User.createWallet(token, 'SHIB.BEP20')
        if (res) {
            dispatch(TypeWithdrawCoin('BEP20'))
            dispatch(createHistorywidthdrawAdress(token, page, 'SHIB.BEP20', res?.data?.data?.address))
        }

    }
    else {
        let res = await User.createWallet(token, 'SHIB')
        if (res) {
            dispatch(TypeWithdrawCoin('SHIB'))
            dispatch(createHistorywidthdrawAdress(token, page, 'SHIB', res?.data?.data?.address))
        }

    }

}
export const gethistoryBnb = async (token, page, symbol, dispatch) => {
    if (symbol == 'BNB') {
        let res = await User.createWallet(token, 'BNB')
        if (res) {
            dispatch(TypeWithdrawCoin('BNB'))
            dispatch(createHistorywidthdrawAdress(token, page, 'BNB', res?.data?.data?.address))
        }

    } else if (symbol == 'BNB.BSC') {
        let res = await User.createWallet(token, 'BNB.BSC')
        if (res) {
            dispatch(TypeWithdrawCoin('BSC'))
            dispatch(createHistorywidthdrawAdress(token, page, 'BNB.BSC', res?.data?.data?.address))
        }

    }
    else {
        let res = await User.createWallet(token, 'BNB.ERC20')
        if (res) {
            dispatch(TypeWithdrawCoin('ERC20'))
            dispatch(createHistorywidthdrawAdress(token, page, 'BNB.ERC20', res?.data?.data.address))
        }

    }

}
// -----------------------------------USDT-------------------------------------------?


