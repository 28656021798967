import { useEffect } from "react"
import { NavLink, useParams, useRouteMatch } from "react-router-dom"
import { useTranslate } from "../../core/translate"


export default function Lending({ children }) {
    let { t } = useTranslate()
    useEffect(() => {

        document.title = "Lendding"
        return () => {
            document.title = "Swap tobe"
        }

    }, [t]);
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
            {children}
        </>
    )
}