import axios from 'axios'
import { port } from '../port'
export default {
    searchBuyNow: (value, type, slug) => {
        return axios(`${port}api/p2p/sreachBuyNow`, {

            method: 'POST',
            data: {
                "amount": value,
                "type": type,
                "symbol": slug
            }

        })
    },
    sreachSellNow: (value2, type, slug) => {
        return axios(`${port}api/p2p/sreachSellNow`, {

            method: 'POST',
            data: {
                "amount": value2,
                "type": type,
                "symbol": slug
            }

        })
    },
}