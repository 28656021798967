import { Spin } from 'antd';
import covertCoin from 'core/CovertCoin';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useTranslate } from '../../../core/translate';
import { createHistorySwap } from '../../../redux/action/userAction';


function HistorySwap({ typeF, typeT }) {
    let [loadding, setLoadding] = useState(false)
    let token = JSON.parse(localStorage.getItem('user'))
    let { symbolfrom, symbolto, totalhistoryswap } = useSelector(state => state.user)
    let { t } = useTranslate()
    let { pricecoin } = useSelector(state => state.coin)

    const formatDateTime = (str, char) => {
        let strSplit = str.split(char)
        let value1 = `${Number(strSplit[0]) < 10 ? `0${strSplit[0]}` : strSplit[0]}`
        let value2 = `${Number(strSplit[1]) < 10 ? `${char}0${strSplit[1]}` : `${char}${strSplit[1]}`}`
        let value3 = `${Number(strSplit[2]) < 10 ? `${char}0${strSplit[2]}` : `${char}${strSplit[2]}`}`

        return value1 + value2 + value3
    }
    const dispatch = useDispatch()
    let { historyswap } = useSelector(state => state.user)
    function truncate(value, precision) {
        var step = Math.pow(10, precision || 0);
        var temp = Math.trunc(step * value);

        var a = temp / step;
        return a.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    let test = (name1) => pricecoin?.data?.data.filter((e) => {
        let value
        if (e?.name == name1) {
            value = {
                price: e?.price,
                name: e?.name
            }
            return value
        }
    })


    let [limit, setLimit] = useState(100)
    useEffect(() => {
        if (historyswap == false) dispatch(createHistorySwap(token?.axiologin?.token, limit, 1, covertCoin(symbolfrom?.toUpperCase(), typeF), covertCoin(symbolto?.toUpperCase(), typeT)))

    }, [symbolfrom, symbolto])
    useEffect(() => {
        setLoadding(true)

        if (limit <= totalhistoryswap) {
            dispatch(createHistorySwap(token?.axiologin?.token, limit, 1, covertCoin(symbolfrom?.toUpperCase(), typeF), covertCoin(symbolto?.toUpperCase(), typeT)))
        }
        if (limit == totalhistoryswap) {
            setLoadding(false)
        }
        setLoadding(false)

    }, [limit])
    function handleScroll(e) {

        const { scrollTop, clientHeight, scrollHeight } = e.currentTarget
        if (scrollHeight - scrollTop === clientHeight) {
            setLimit(totalhistoryswap)
        }

    }

    return (
        <>
            <>
                <div className="historyswap" style={{ color: "#000" }}>
                    <div className="title">{t("History Swap")}</div>

                    <div onScroll={handleScroll} style={{ maxHeight: '500px', overflow: 'auto', marginBottom: '30px' }}>
                        {historyswap?.data?.data?.array?.map((e, i) => {

                            if (e?.coin_key == `${covertCoin(symbolfrom?.toUpperCase(), typeF)}` && e?.wallet == `${covertCoin(symbolto?.toUpperCase(), typeT)}`) {

                                return (
                                    <div className="closed-item">
                                        <div>
                                            <div className="item-block code-trade" style={{ marginBottom: "1em" }}>
                                                <p >{t('Command pair')}: <span>  {symbolfrom} / {symbolto}</span></p>
                                            </div>
                                            <div className="item-block amount-btc" style={{ marginBottom: "1em" }}>
                                                <p>{t("Amount:")}<span> <strong>{e?.amount}</strong> {symbolfrom}</span>  </p>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="item-block value-btc" style={{ marginBottom: "1em" }}>
                                                <p>{t('Receive')}: <span> <strong>{truncate(e?.wallet_amount, 6).replace(/,/g, '')}</strong> {symbolto}</span></p>
                                            </div>

                                            <div className="item-block value-trade" style={{ marginBottom: "1em" }}>
                                                <p>{t("Price change")}: <span className="info"><strong>{truncate(e?.rate, 8).replace(/,/g, '')}</strong> </span><span> {symbolto} / {symbolfrom}</span></p>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="item-block status" style={{ marginBottom: "1em" }}>
                                                <p>{t("Status: ")}<span style={{ backgroundColor: 'green', color: '#fff', padding: '10px', borderRadius: '4px' }}>{t("Accomplished")}</span></p>
                                            </div>
                                            <div className="item-block value-btc" style={{ justifyContent: 'flex-end', marginBottom: "1em" }}>
                                                <p >{t('Time')}: <span>  {formatDateTime(e?.created_at.substring(0, 9), "/")} {formatDateTime(e?.created_at.substring(10, 17), ":")}</span></p>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }



                        })}
                        {loadding ? <div className="example">
                            <Spin />
                        </div> : ""}

                    </div>


                </div>


            </>
        </>
    )
}
export default React.memo(HistorySwap)