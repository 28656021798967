import React from 'react'
import CheckBoxGroup from './CheckBoxGroup'
import Input from './Input'
import RadioButton from './RadioButton'

export default function FormikControl(props) {
    const { control, ...rest } = props

    switch (control) {
        case 'input':
            return <Input {...rest} />
        case "radio":
            return <RadioButton {...rest} />
        case "checkbox":
            return <CheckBoxGroup {...rest} />

        default:
            return null
    }

}
