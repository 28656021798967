import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Typography from "@mui/material/Typography";
import { Form, Input,Button } from "antd";
import Info from "./components/Info";
import Context from "./components/Context";
import Time from "./components/Time";

const steps = [
  "Step 1",
  "Step 2",
  "Step 3",
];

const style = {
  display:'flex',
  justifyContent:'center',
  marginTop:'30px',
  boxShadow:'0px 10px 28px rgba(0,0,0,0.12)',
  borderRadius:'20px',
  '@media(max-width:991px)':{
    flexDirection:'column'
  }

}
export default function Listting() {

  const [activeStep, setActiveStep] = React.useState(0);

  const handleReset = () => {
    setActiveStep(0);
  };


  return (
    <Box  sx={{ display: "flex", flexWrap: "wrap", justifyContent:'center', padding:'50px 0' }}>
    <Box sx={{ width: "100%" , maxWidth:'100%',  }}>
      <div className="container">
        <div className="listting-main">
   
    <Box component='h3'  sx={{ mt: 2, mb: 1, color:"#4BBD99", textAlign:'center', fontWeight:'bold' }}>
    Cooperation & Investment Invitation
    </Box >
    <Box style={{maxWidth:'700px', margin:'0 auto'}}>
      <Stepper activeStep={activeStep}>
        {steps.map((label) => {
          const labelProps = {};
          return (
            <Step key={label} >
              <StepLabel {...labelProps} >{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      </Box>
      {activeStep === steps.length ? (
        <React.Fragment>
          <Typography sx={{ mt: 2, mb: 1 }} style={{textAlign:'center', color:'#000'}}>
            All steps completed - you&apos;re finished
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Box sx={{ flex: "1 1 auto" }} />
            <Button onClick={handleReset}>Reset</Button>
          </Box>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Box sx={style}>
            <div style={{flex:'1', background:'linear-gradient(180deg,#4BBD99 0%, #017c91 100%', padding:'40px', borderRadius:'19px'}}>
              <div  style={{fontSize:'16px'}}>
                  Cooperators and Investors are always welcomed to fill out this application form if you would like to work with us and have a long-term benefits in the future. If you have previously filled out the application form for your project, please refrain from applying unless there are significant updates or improvements that you would like to bring to our attention, which may be detailed in the “Project Updates” section below. Any submissions with substantially duplicative information will be DENIED. Please be aware that we receive a large volume of applications and appreciate your patience. While we endeavor to process them as quickly as possible, we are unable to provide any direct feedback on your application if it does not pass our initial review based on the current stage of your project is at.
                  <div style={{color:'#faa51a', fontWeight:'bold'}}>Email: info@egtechgroup.us</div>
              </div>
            </div>
            {activeStep == 0 && 
          <Info setActiveStep={setActiveStep } activeStep={activeStep} />}
            {activeStep == 1 && 
           <Context setActiveStep={setActiveStep } activeStep={activeStep} />}
              {activeStep == 2 && 
           <Time setActiveStep={setActiveStep } activeStep={activeStep} />}
        </Box>

        </React.Fragment>
      )}
      </div>
      </div>
    </Box>
    </Box>
  );
}
