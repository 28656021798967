import React, { useEffect, useState } from 'react'
import { AreaChart, LineChart, XAxis, YAxis, Line, Area } from 'recharts'
import './chart.scss'


export default function ApexCharts(props) {
    let [trade, setTrade] = useState([])

    // useEffect(() => {
    //     if (props.symbol === "USDTUSDT") {
    //         var string = "BUSDUSDT"
    //         socket.emit('chart', string)
    //         socket.on(string, (res) => {
    //             setTrade(Object.entries(res))
    //         })
    //     } else {
    //         socket.emit('chart', props.symbol)
    //         socket.on(props.symbol, (res) => {
    //             setTrade(Object.entries(res))
    //         })
    //     }
    // }, [])
    const data = [
        {
            name: 'Page A',
            uv: 1,
            pv: 2400,
            amt: 1,
        },
        {
            name: 'Page B',
            uv: 2,
            pv: 1398,
            amt: 1,
        },
        {
            name: 'Page C',
            uv: 3,
            pv: 9800,
            amt: 1,
        },
        {
            name: 'Page D',
            uv: 9,
            pv: 3908,
            amt: 1,
        },
        {
            name: 'Page E',
            uv: 20,
            pv: 4800,
            amt: 1,
        },
        {
            name: 'Page F',
            uv: 10,
            pv: 3800,
            amt: 1,
        },
        {
            name: 'Page G',
            uv: 30,
            pv: 4300,
            amt: 1,
        },
    ];


    return (
        <>

            {/* <AreaChart width={500} height={50} data={data} >
                {props.id === 0 ? <Area dataKey="pv" stroke="#07AD87" strokeWidth="5px" fill="#8884d8" />
                    : props.id === 1 ?
                        <Area dataKey="uv" stroke="#07AD87" strokeWidth="5px" fill="#8884d8" />
                        : props.id === 2 ?
                            <Area dataKey="uv" stroke="#07AD87" strokeWidth="5px" fill="#8884d8" />
                            : ''
                }


            </AreaChart> */}

            <AreaChart width={9900} height={200} data={trade} >
                {/* {id === 2 ? <Area dataKey="1.close" fill="none" /> : <Area dataKey="1.close" stroke="#07AD87" strokeWidth="5px" fill="#8884d8" />} */}
                <Area dataKey="1.close" stroke="#07AD87" strokeWidth="5px" fill="#8884d8" />
            </AreaChart>


        </>
    )
}
