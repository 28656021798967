
import React, { useState } from 'react';

import Coin from './Coin';
import History from './History';

function Deposit() {
    return (
        <div className='deposit'>
            <div className="container">

                <div className="row" style={{    justifyContent: "center",
    gap: "50px"}}>
                    <div className="col-md-6 deposit-main" style={{maxWidth:"450px"}}>
                        <Coin />
                    </div>
                    <div className="col-md-6 dhistory" style={{maxWidth:"450px"}}>
                        <History />
                    </div>

                </div>
            </div>
        </div>
    );
}

export default Deposit;