import React from 'react';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useDispatch, useSelector } from 'react-redux';
import { SuccessMessage } from 'core/Message';
import User from 'api/User';
import { port } from 'port';
import { createGetlistbankinguser } from 'redux/action/userAction';
import { useTranslate } from 'core/translate';
import { ErrorMessage } from 'core/Message';
import { ShowLoadding } from 'redux/reducer/loaddingReducer';
import { Spin } from 'antd';

function DataBanking({ HanleActive, active }) {
    const dispatch = useDispatch()
    const { t } = useTranslate()
    const token = JSON.parse(localStorage.getItem("user"))
    const { getlistbankinguser } = useSelector(state => state.user)
    const { showLoadding } = useSelector(state => state.loadding)

    async function deleletGetlistbankinguser(id) {
        dispatch(ShowLoadding(id))
        try {
            let res = await User.createApiToken(`${port}api/user/deleteBankingUser`, token?.axiologin?.token
                , { id })
            SuccessMessage('Successful delete', t)
            dispatch(createGetlistbankinguser(token?.axiologin?.token, 1))
        } catch (error) {
            ErrorMessage(error?.response?.data?.message, t)
        }
        dispatch(ShowLoadding(false))

    }
    return (
        <>
            {getlistbankinguser?.data?.data?.array?.map((e, i) => {
                return (
                    <div className={`${active == i + 1 ? 'withdrawvnd-content active' : 'withdrawvnd-content'}`} onClick={() => HanleActive(e, i)} key={i}>
                        <div>
                            {e.name_banking === "MBBank" && <img src="/img/MBBank.png" alt="" />}
                            {e.name_banking === "Sacombank" && <img src="/img/sacombank 15x50.png" alt="" />}
                            {e.name_banking === "Vietcombank" && <svg data-name="Layer 1" viewBox="0 0 32 32" fill="#5E5862" width={50} height={50}><defs><linearGradient id="bank-vietcombank_svg__a" x1="27.92" y1="20.35" x2="2.78" y2="2.74" gradientUnits="userSpaceOnUse"><stop offset={0} stopColor="#006840" /><stop offset="0.4" stopColor="#007a48" /><stop offset={1} stopColor="#86c440" /></linearGradient><linearGradient id="bank-vietcombank_svg__b" x1="12.15" y1="0.91" x2="27.03" y2="11.32" gradientUnits="userSpaceOnUse"><stop offset={0} stopColor="#006840" /><stop offset="0.3" stopColor="#007a48" /><stop offset={1} stopColor="#86c440" /></linearGradient></defs><path d="M11.59 1.06c-2.22.45-3 1.45-2.95 2.9.11 2 2.34 5.9 3.41 7.62a47 47 0 0 0 6.52 8.24c2 2 3.23 2.39 5.36-.51a45.27 45.27 0 0 0 4.92-7.72 33.78 33.78 0 0 0 1.73-4.32c.56-1.85.36-3.5-1.46-4.71a4.68 4.68 0 0 1 2.63 4.17 14.86 14.86 0 0 1-.75 4.85 54.88 54.88 0 0 1-3.72 9.77 56.22 56.22 0 0 1-4.15 7.11c-3.29 4.52-6.45 3.06-10.15 0a38.71 38.71 0 0 1-6.53-7.09A54.62 54.62 0 0 1 1 11.57C-.71 7.35.64 5.18 2.8 3.81a23.62 23.62 0 0 1 8.79-2.75z" fillRule="evenodd" fill="url(#bank-vietcombank_svg__a)" /><path d="M11.51 10.69c3.43-.52 12.33-1.07 15.45.55a1.73 1.73 0 0 1 1 2.1c.32-.56.62-1.14.92-1.75a33.78 33.78 0 0 0 1.73-4.32c.56-1.85.36-3.5-1.46-4.71A12.25 12.25 0 0 0 27 1.74a32.79 32.79 0 0 0-6.64-1c-.95-.07-1.94-.06-2.95 0a43.33 43.33 0 0 0-5.78.38c-2.22.45-3 1.45-2.95 2.9.05 1.66 1.69 4.73 2.83 6.67z" fillRule="evenodd" fill="url(#bank-vietcombank_svg__b)" /></svg>}
                        </div>
                        <div className='withdrawvnd-content-text'>
                            <span style={{ fontWeight: 'bold' }}>
                                {e.name_banking}
                            </span>
                            <span>
                                {e.owner_banking?.toUpperCase()}
                            </span>
                            <span>
                                {e.number_banking}
                            </span>
                        </div>

                        <div onClick={() => deleletGetlistbankinguser(e?.id)}>
                            {showLoadding == e?.id ? <div className="example" style={{ padding: '0 19px' }}>
                                <Spin />
                            </div> :
                                <DeleteForeverIcon sx={{ width: '50px', height: '50px' }} />}
                        </div>
                    </div>
                )
            })}

        </>
    );
}

export default DataBanking;