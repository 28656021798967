import { message, Spin } from 'antd'
import CreateApi from 'api/CreateApi'
import React, { useState }  from 'react'
import { useDispatch } from 'react-redux'
import { getprofile, wallet } from 'redux/action/userAction'
import { HandleGetHistory } from 'redux/reducer/buytbcReducer'







export default function Submit({valueInput }) {
    const dispatch = useDispatch()
    const token = JSON.parse(localStorage.getItem('user'))
    const [loading, setLoading] = useState(false)
    const check = () => {
        let err = {}
        for(let key in valueInput){
            if(!valueInput[key]){
                 return  err= key
            }
        }
    }
 
    const submit = async() => {
        let    error = check()
        setLoading(true)
        if(!error){
            try {
                let res = await CreateApi?.CreateApiS('api/blockico/buy-block-tbc',{
                    amountTokenTBC: valueInput?.amount,
                    symbol:valueInput?.symbol
                },true)
            message.success({content:res?.data?.message, duration:5})
            dispatch(wallet(token?.axiologin?.token))
            dispatch(getprofile(token.axiologin.token))
            dispatch(HandleGetHistory(1,setLoading))
            } catch (error) {
                message.error({content:`${error?.response?.data?.message}`, duration:5})
            }
        }else{
        message.error({content:'Please fill in all the information', duration:5})
        }
        setLoading(false)
      
    }

    return (
    <>
        {loading ? 
         <div style={{alignItems: "center"}}>
             <button disabled style={{opacity:'0.5'}}>  <Spin /></button>
         </div>
        : 
        <div style={{alignItems: "center"}}>
            <button onClick={submit}>
            Reserve TBC
            </button>
        </div> }
    </>
      

 
    )
}
