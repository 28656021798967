import { Spin } from 'antd';
import axios from 'axios';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { useRef, useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha';
import { useHistory } from 'react-router';
import Swal from 'sweetalert2';

import * as yup from 'yup';
import { SuccessMessage } from '../../core/Message';
import { useTranslate } from '../../core/translate';
import { port } from '../../port';
import FormikControl from './components/FormikControl';
export default function ListingApplication() {
    let { t } = useTranslate()
    let [valueP, setValueP] = useState('')
    let [valueP2, setValueP2] = useState('')
    let [img, setImg] = useState('')
    let [img2, setImg2] = useState('')
    const [token, setStateToken] = useState('')
    let [errCaptcha, setErrCaptcha] = useState('')
    let reRef = useRef()
    let reCaptcha = useRef()
    const radioOptions = [
        { key: "Yes - I have updates since the last application that I would like to share", value: "yes1" },
        { key: "No - This is my first listing application", value: "No1" },
        { key: 'Is different: ', value: '  ' },

    ]
    const radioOptions2 = [
        { key: "Yes", value: "yes" },
        { key: "No", value: "No" },
    ]

    const radioBoxs = [
        { key: 'CEO', value: 'CEO', },
        { key: 'Founder/Co-Founder', value: 'Founder/Co-Founder' },
        { key: 'Is different: ', value: ' ' },

    ]

    function handlChange(e) {
        let value = e.target.value
        setValueP(value)

    }
    function handlChange2(e) {
        let value = e.target.value
        setValueP2(value)

    }
    let [loadding, setLoadding] = useState(false)
    function VerificationImg(event) {

        setLoadding(true)
        let test = setTimeout(() => {
            setLoadding(false)
        }, 2000)
        if (event.target.files[0].type === "image/jpeg" || event.target.files[0].type === "image/png") {
            setImg("file2", event.target.files[0]);
            setImg(event.target.files[0])

        }
        else if (event.target.files[0].type === "application/pdf" && event.target.files[0].size < 5000000) {
            setImg("file2", event.target.files[0]);
            setImg(event.target.files[0])

        }
        else {

            // setTimeout(() => {
            //     window.location.reload()
            // }, 1000);
            Swal.fire({
                title: 'Chỉ nhận file Image jpg,png,pdf,svg dưới 5m',
                icon: 'error',
                timer: 1000
            })

        }


    }

    function VerificationImg2(event) {
        setLoadding(true)
        let test = setTimeout(() => {
            setLoadding(false)
        }, 2000)
        if (event.target.files[0].type === "image/jpeg" || event.target.files[0].type === "image/png") {
            setImg2("file2", event.target.files[0]);
            setImg2(event.target.files[0])

        }
        else if (event.target.files[0].type === "application/pdf" && event.target.files[0].size < 5000000) {
            setImg2("file2", event.target.files[0]);
            setImg2(event.target.files[0])
        }
        else {

            Swal.fire({
                title: 'Chỉ nhận file Image jpg,png,pdf,svg dưới 5m',
                icon: 'error',
                timer: 1000
            })
        }

    }

    const inititalValues = {
        file: null,
        file2: null,
        email: '',
        fullname: '',
        company: '',
        position: '',
        project_name: '',
        token_fullname: '',
        token_application: '',
        radioOption2: '',
        token_website: '',
        advertisement: '',
        tokenRecaptcha: "",


    }

    const validationSchema = yup.object({
        email: yup
            .string()
            .email()
            .required(t("Not be empty"))
            .min(2, "Enter at least 2 characters"),
        fullname: yup
            .string()
            .trim("The contact name cannot include leading and trailing spaces")
            .required(t("Not be empty"))
            .min(2, "Enter at least 2 characters")
            .max(16, "Cannot enter more than 16 characters")
            .strict(true),
        company: yup
            .string()
            .trim("The contact name cannot include leading and trailing spaces")
            .required(t("Not be empty"))
            .min(2, "Enter at least 2 characters")
            .max(500, "Cannot enter more than 16 characters")
            .strict(true),

        position: yup.string().required(t("Not be empty")),

        project_name: yup
            .string()
            .trim("The contact name cannot include leading and trailing spaces")
            .required(t("Not be empty"))
            .min(2, "Enter at least 2 characters")
            .max(30, "Cannot enter more than 16 characters")
            .strict(true),
        token_fullname: yup
            .string()
            .trim("The contact name cannot include leading and trailing spaces")
            .required(t("Not be empty"))
            .min(2, "Enter at least 2 characters")
            .max(16, "Cannot enter more than 16 characters")
            .strict(true),
        token_website: yup
            .string()
            .trim("The contact name cannot include leading and trailing spaces")
            .required(t("Not be empty"))
            .min(2, "Enter at least 2 characters")
            .max(16, "Cannot enter more than 16 characters")
            .strict(true),
        advertisement: yup
            .string()
            .trim("The contact name cannot include leading and trailing spaces")
            .required(t("Not be empty"))
            .min(2, "Enter at least 2 characters")
            .max(30, "Cannot enter more than 16 characters")
            .strict(true),
        token_application: yup.string().required(t("Not be empty")),
        token_jurisdiction: yup.string().required(t("Not be empty")),



    })
    let history = useHistory()
    let [error, setError] = useState("")
    async function onSubmit(values) {


        if (token == '') {
            setErrCaptcha(' Vui lòng nhập recaptcha!')
        }
        else {
            setErrCaptcha('')
            try {
                if (values.position == " ") {
                    values.position = valueP

                }
                else if (values.token_application == "  ") {
                    values.token_application = valueP2

                }
                const data = new FormData();
                data.append("photo", img);
                data.append("photo", img2);
                data.append("email", values.email);
                data.append("fullname", values.fullname);
                data.append("company", values.company);
                data.append("position", values.position);
                data.append("project_name", values.project_name);
                data.append("token_fullname", values.token_fullname);
                data.append("token_website", values.token_website);
                data.append("advertisement", values.advertisement);
                data.append("token_application", values.token_application);
                data.append("token_jurisdiction", values.token_jurisdiction);
                data.append("tokenRecaptcha", token);
                const res = await axios({
                    url: `${port}api/uploadListing`,
                    method: `POST`,
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                    data,
                });
                if (res) {
                    SuccessMessage('Verify your Listing is successful!', t)
                    history.push('/p2p')
                }


            } catch (error) {
                ErrorMessage(error?.response?.data?.message, t)
            }
        }
    }


    return (
        <div style={{ background: "linear-gradient(to right, rgb(8, 121, 114), rgb(39, 19, 58))" }}>
            <div className="listingApplication">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="listingApplication-content">
                                <h2>Listing Application Form</h2>
                                <div className="listingApplication-content_des">
                                    <div>
                                        This is the application form for token/coin listing on Swaptobe. Please note that the information you provide to us may be made public in the future in accordance with our data protection policy. A copy of your responses will be emailed to the address that you provide. Before filling out this form, please read the following guidelines:
                                        <a href="https://www.youtube.com/channel/UC9jBj0BDr7vQq6WzsJGBBSw" target='_blank' style={{ color: 'blue' }}>https://www.youtube.com/channel/UC9jBj0BDr7vQq6WzsJGBBSw </a>
                                        . If you have previously filled out the application form for your project, please refrain from applying again unless there are significant updates or improvements that you would like to bring to our attention, which may be detailed in the “Project Updates” section below. Any submissions with substantially duplicative information will be DENIED. Please be aware that we receive a large volume of applications and appreciate your patience. While we endeavor to process them as quickly as possible, we are unable to provide any direct feedback on your application if it does not pass our initial review based on the current stage your project is at.
                                    </div>
                                </div>

                            </div>

                            <div className="listingApplication-content">
                                <Formik

                                    initialValues={inititalValues}
                                    validationSchema={validationSchema}
                                    onSubmit={onSubmit}>
                                    {formik => (


                                        <div className="custom-radio">

                                            <Form>
                                                <FormikControl
                                                    control='input'
                                                    type="email"
                                                    lable="Email"
                                                    name="email"
                                                />
                                                <FormikControl
                                                    control='input'
                                                    type="text"
                                                    lable="1. Please provide your full name. *"
                                                    name="fullname"
                                                />
                                                <FormikControl
                                                    control='input'
                                                    type="text"
                                                    lable="2. Please provide the name of your Company (the “Company”). *"
                                                    name="company"
                                                />
                                                <FormikControl
                                                    control='radio'
                                                    lable=" 3. Please confirm your position with this Project. *"
                                                    name="position"
                                                    options={radioBoxs} />
                                                <Field className="custom-radios" id="position" name="position" value={valueP} onChange={handlChange} />


                                                <FormikControl
                                                    control='input'
                                                    type="text"
                                                    lable="4. Please provide the project name. *"
                                                    name="project_name"
                                                />

                                                <FormikControl
                                                    control='input'
                                                    type="text"
                                                    lable="5. Please provide the token/coin full name. *"
                                                    name="token_fullname"
                                                />
                                                <FormikControl
                                                    control='input'
                                                    type="text"
                                                    lable="6. Please provide the project's official website. *"
                                                    name="token_website"
                                                />

                                                <div className='form-group'>
                                                    <div className="uploadGroup">
                                                        <label htmlFor="file">7. Please provide a copy of the Swap tobe Non-Disclosure Agreement (“NDA”) signed by the Founder/CEO of the Company. </label>
                                                        {loadding ? <div className="example" style={{ margin: '0' }}>
                                                            <Spin />
                                                        </div> :
                                                            <div>

                                                                <label for="upload-photo" className="uploadGroup_title">Thêm tệp...</label>
                                                                <input type="file" name="file" id="upload-photo" onChange={VerificationImg} />

                                                                <ErrorMessage name="file">
                                                                    {(mes) => (
                                                                        <p className="text-danger" style={{ color: 'red' }}> {mes}</p>
                                                                    )}
                                                                </ErrorMessage>
                                                            </div>}
                                                    </div>
                                                </div>
                                                {
                                                    img ?
                                                        img?.type === "application/pdf" ?
                                                            <div>
                                                                <div style={{ marginLeft: '15px', color: '#000' }}>{img?.name}</div>
                                                                <img src="img/pdf.jpg"
                                                                    alt={img}
                                                                    className="img-thumbnail mt-2"
                                                                    height={200}
                                                                    width={200} />
                                                            </div> :
                                                            <img src={URL?.createObjectURL(img)}
                                                                alt={img}
                                                                className="img-thumbnail mt-2"
                                                                height={200}
                                                                width={200} /> : ''}
                                                {img ? '' : <p className="text-danger" style={{ color: 'red' }}> {error}</p>}
                                                <FormikControl
                                                    control='input'
                                                    type="text"
                                                    lable="8. Please provide a one sentence pitch for your project (the ' Project') (e.g. Ripple: enterprise payment settlement network ) *"
                                                    name="advertisement"
                                                />


                                                <FormikControl
                                                    control='radio'
                                                    lable="9. Have you previously submitted a listing application for the token/coin? *"
                                                    name="token_application"
                                                    options={radioOptions} />
                                                <Field className="custom-radios" id="token_application" name="token_application" value={valueP2} onChange={handlChange2} />
                                                <FormikControl
                                                    control='radio'
                                                    lable="10. Is the token/coin a security or otherwise a regulated product or instrument under U.S. federal securities law or the laws of any other (non-U.S.) jurisdiction(s)? *"
                                                    name="token_jurisdiction"
                                                    options={radioOptions2} />


                                                <div className='form-group'>
                                                    <div className="uploadGroup">
                                                        <label htmlFor="file2">11. Please attach the legal opinion documents advising or stating that the token/coin is not a security or otherwise a regulated product or instrument under applicable laws.</label>
                                                        {loadding ? <div className="example" style={{ margin: '0' }}>
                                                            <Spin />
                                                        </div>
                                                            :
                                                            <div>
                                                                <label for="upload-photo2" className="uploadGroup_title">Thêm tệp...</label>
                                                                <input type="file" name="file2" id="upload-photo2" onChange={VerificationImg2} />


                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                                {img2 ?
                                                    img2?.type === "application/pdf" ?
                                                        <div>
                                                            <img src="img/pdf.jpg"
                                                                alt={img2}
                                                                className="img-thumbnail mt-2"
                                                                height={200}
                                                                width={200} />
                                                        </div> :
                                                        <div>
                                                            <img src={URL?.createObjectURL(img2)}
                                                                alt={img2}
                                                                className="img-thumbnail mt-2"
                                                                height={200}
                                                                width={200} />
                                                        </div> : ''}



                                                {img2 ? '' : <p className="text-danger" style={{ color: 'red' }}> {error}</p>}

                                                <ReCAPTCHA

                                                    ref={reRef}
                                                    sitekey="6LcSjv8cAAAAAFi5PvAn_V8i5BhzD9FML2V-upQZ"
                                                    ref={reCaptcha}
                                                    onChange={async (token) => {

                                                        setStateToken(token)
                                                    }}
                                                    onExpired={(e) => {

                                                        setStateToken("");
                                                    }}
                                                />
                                                {errCaptcha ? <p className='alert alert-danger btn-danger'>{errCaptcha}</p> : ''}

                                                <button type="submit" className="ListingApplication_submit">{t("Completed")}</button>

                                            </Form>

                                        </div>


                                    )}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}






