import React, { useEffect, useState } from 'react'
import { Redirect } from "react-router";
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom';


export default function ConfirmPhone() {
    let { google, axiologin } = useSelector(state => state.auth)
    let [border, setBorder] = useState(1)
    function handlBorder(index) {
        setBorder(index)
    }
    let [none, setNone] = useState(false)
    function remove(index) {
        if (index) {
            setNone(index)
        }
        else {
            setNone(false)
        }
    }
    if (!axiologin && !google) {
        return <Redirect to='/form' />
    }
    return (

        <>
            <div id="scrollable-body">
                <div className="container">
                    <div className="confirmphone css-1dbjc4n r-1jj8364 r-lchren r-1ge9hsw r-1qhn6m8 r-i023vh r-13qz1uu r-1g2xzmm">
                        <div className="css-1dbjc4n r-1g2xzmm" >
                            <div className=' css-1dbjc4n r-1nct366 r-8d5o6e r-mm0ijv r-ms8t9i r-1slz7xr r-kls4rr r-bnwqim' style={none || google || axiologin ? { display: "none" } : { display: "block" }}>
                                <div className="css-901oao r-z4f626 r-320f6p r-1b43r93 r-uz4sb0 r-rjixqe" >
                                    Vui lòng đăng kí hoặc đăng nhập để tiếp tục
                                </div>
                                <div className="css-1dbjc4n r-1loqt21 r-icoktb r-d9fdf6 r-5njf8e r-u8s1d r-zchlnj r-ipm5af r-1otgn73" >
                                    <div className="css-901oao r-z4f626 r-320f6p r-1x35g6 r-vw2c0b r-oxtfae r-37tt59" onClick={() => remove(1)}>
                                        ×
                                    </div>
                                </div>
                            </div>
                            <div className=' css-1dbjc4n r-1nct366 r-8d5o6e r-mm0ijv r-ms8t9i r-1slz7xr r-kls4rr r-bnwqim' style={none === 2 ? { display: "none" } : { display: "block" }} >
                                <div className="css-901oao r-z4f626 r-320f6p r-1b43r93 r-uz4sb0 r-rjixqe">
                                    Cần xác minh số điện thoại để tiếp tục
                                </div>
                                <div className="css-1dbjc4n r-1loqt21 r-icoktb r-d9fdf6 r-5njf8e r-u8s1d r-zchlnj r-ipm5af r-1otgn73">
                                    <div className="css-901oao r-z4f626 r-320f6p r-1x35g6 r-vw2c0b r-oxtfae r-37tt59" onClick={() => remove(2)}>
                                        ×
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="css-1dbjc4n r-1g2xzmm" data-testid="flash-access-devices">
                            <div className="css-1dbjc4n r-eqz5dr r-912u2z r-1g2xzmm" />
                        </div>
                    </div>
                    <div className="css-1dbjc4n r-11ict49" data-testid="main">
                        <div
                            className="css-1dbjc4n r-1jj8364 r-lchren r-1ge9hsw r-1qhn6m8 r-i023vh r-13qz1uu r-1g2xzmm"
                            data-testid="trade-create"
                        >
                            <div className="css-1dbjc4n r-1g2xzmm">
                                <div className="css-1dbjc4n r-1g2xzmm">
                                    <div className="css-1dbjc4n r-eqz5dr r-912u2z r-1g2xzmm">
                                        <div className="css-1dbjc4n r-1l7z4oj r-1g2xzmm">
                                            <div className="react-phone-number-input">
                                                <div className="react-phone-number-input__row">
                                                    <div className="react-phone-number-input__country react-phone-number-input__country--native">
                                                        <div className="react-phone-number-input__icon">
                                                            <img
                                                                alt="VN"
                                                                className="react-phone-number-input__icon-image"
                                                                src="https://lipis.github.io/flag-icon-css/flags/4x3/vn.svg"
                                                            />
                                                        </div>
                                                        <select className="react-phone-number-input__country-select">
                                                            <option value="VN">Vietnam</option>
                                                        </select>
                                                        <div className="react-phone-number-input__country-select-arrow" />
                                                    </div>
                                                    <input type="tel" placeholder="Nhập số điện thoại"
                                                        className="react-phone-number-input__input react-phone-number-input__phone react-phone-number-input__input--style profile-phone-number-input"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="css-1dbjc4n r-1l7z4oj r-1g2xzmm">
                                            <div className="css-1dbjc4n r-eqz5dr r-1g2xzmm" style={{ marginBottom: "-8px" }}>
                                                <div className="css-1dbjc4n r-1g2xzmm" style={{ paddingBottom: 8 }}>
                                                    <div className="css-901oao r-homxoj r-320f6p r-7cikom r-vw2c0b r-1s2g6fe r-t9a87b">
                                                        Gửi mã xác minh qua:
                                                    </div>
                                                </div>
                                                <div
                                                    className="css-1dbjc4n r-1g2xzmm"
                                                    style={{ paddingBottom: 8 }}
                                                >
                                                    <div className="css-1dbjc4n">
                                                        <div className="css-1dbjc4n r-18u37iz r-1w6e6rj r-1h0z5md r-912u2z r-xzortm">
                                                            <div className={`${border === 1 ? 'bordercolor css-1dbjc4n r-1awozwy r-14lw9ot r-l61ric r-z2wwpe r-1phboty r-rs99b7 r-1loqt21 r-1ifxtd0 r-88pszg r-1pn2ns4 r-5njf8e r-bnwqim r-1otgn73' : 'css-1dbjc4n r-1awozwy r-14lw9ot r-l61ric r-z2wwpe r-1phboty r-rs99b7 r-1loqt21 r-1ifxtd0 r-88pszg r-1pn2ns4 r-5njf8e r-bnwqim r-1otgn73'}`} onClick={() => handlBorder(1)} >
                                                                <div className=" css-901oao r-1814a8r r-320f6p r-1b43r93 r-vw2c0b r-uz4sb0 r-rjixqe">
                                                                    Tin nhắn
                                                                </div>
                                                                <div className={`${border === 1 ? '' : 'none'} css-1dbjc4n r-u8s1d r-1beiz5u r-1wrvin1`}>
                                                                    <div className="css-1dbjc4n" style={{ height: 14, width: 14 }}>
                                                                        <svg
                                                                            width={14}
                                                                            height={14}
                                                                            viewBox="0 0 24 24"
                                                                            fill="#5E5862"
                                                                        >
                                                                            <defs>
                                                                                <circle
                                                                                    id="ok-filled-circled_svg__a"
                                                                                    cx={11}
                                                                                    cy={11}
                                                                                    r="10.5"
                                                                                />
                                                                                <path
                                                                                    id="ok-filled-circled_svg__b"
                                                                                    d="M9.5 15.125l-3.75-3.75 1.192-1.125L9.5 12.762l5.557-5.512 1.193 1.185z"
                                                                                />
                                                                            </defs>
                                                                            <g
                                                                                transform="translate(1 1)"
                                                                                fill="none"
                                                                                fillRule="evenodd"
                                                                            >
                                                                                <use
                                                                                    fill="#299D82"
                                                                                    xlinkHref="#ok-filled-circled_svg__a"
                                                                                />
                                                                                <use
                                                                                    fill="#FFF"
                                                                                    xlinkHref="#ok-filled-circled_svg__b"
                                                                                />
                                                                            </g>
                                                                        </svg>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className={`${border === 2 ? 'bordercolor css-1dbjc4n r-1awozwy r-14lw9ot r-l61ric r-z2wwpe r-1phboty r-rs99b7 r-1loqt21 r-1ifxtd0 r-88pszg r-1pn2ns4 r-5njf8e r-bnwqim r-1otgn73' : 'css-1dbjc4n r-1awozwy r-14lw9ot r-l61ric r-z2wwpe r-1phboty r-rs99b7 r-1loqt21 r-1ifxtd0 r-88pszg r-1pn2ns4 r-5njf8e r-bnwqim r-1otgn73'}`} onClick={() => handlBorder(2)}>
                                                                <div className="css-901oao r-1814a8r r-320f6p r-1b43r93 r-vw2c0b r-uz4sb0 r-rjixqe">
                                                                    Gọi điện
                                                                </div>
                                                                <div className={`${border === 2 ? '' : 'none'} css-1dbjc4n r-u8s1d r-1beiz5u r-1wrvin1`}>
                                                                    <div className="css-1dbjc4n" style={{ height: 14, width: 14 }}>
                                                                        <svg
                                                                            width={14}
                                                                            height={14}
                                                                            viewBox="0 0 24 24"
                                                                            fill="#5E5862"
                                                                        >
                                                                            <defs>
                                                                                <circle
                                                                                    id="ok-filled-circled_svg__a"
                                                                                    cx={11}
                                                                                    cy={11}
                                                                                    r="10.5"
                                                                                />
                                                                                <path
                                                                                    id="ok-filled-circled_svg__b"
                                                                                    d="M9.5 15.125l-3.75-3.75 1.192-1.125L9.5 12.762l5.557-5.512 1.193 1.185z"
                                                                                />
                                                                            </defs>
                                                                            <g
                                                                                transform="translate(1 1)"
                                                                                fill="none"
                                                                                fillRule="evenodd"
                                                                            >
                                                                                <use
                                                                                    fill="#299D82"
                                                                                    xlinkHref="#ok-filled-circled_svg__a"
                                                                                />
                                                                                <use
                                                                                    fill="#FFF"
                                                                                    xlinkHref="#ok-filled-circled_svg__b"
                                                                                />
                                                                            </g>
                                                                        </svg>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className={`${border === 3 ? 'bordercolor css-1dbjc4n r-1awozwy r-14lw9ot r-l61ric r-z2wwpe r-1phboty r-rs99b7 r-1loqt21 r-1ifxtd0 r-88pszg r-1pn2ns4 r-5njf8e r-bnwqim r-1otgn73' : 'css-1dbjc4n r-1awozwy r-14lw9ot r-l61ric r-z2wwpe r-1phboty r-rs99b7 r-1loqt21 r-1ifxtd0 r-88pszg r-1pn2ns4 r-5njf8e r-bnwqim r-1otgn73'}`} onClick={() => handlBorder(3)}>
                                                                <div className="css-901oao r-1814a8r r-320f6p r-1b43r93 r-vw2c0b r-uz4sb0 r-rjixqe">
                                                                    WhatsApp
                                                                </div>
                                                                <div className={`${border === 3 ? '' : 'none'} css-1dbjc4n r-u8s1d r-1beiz5u r-1wrvin1`}>
                                                                    <div className="css-1dbjc4n" style={{ height: 14, width: 14 }}>
                                                                        <svg
                                                                            width={14}
                                                                            height={14}
                                                                            viewBox="0 0 24 24"
                                                                            fill="#5E5862"
                                                                        >
                                                                            <defs>
                                                                                <circle
                                                                                    id="ok-filled-circled_svg__a"
                                                                                    cx={11}
                                                                                    cy={11}
                                                                                    r="10.5"
                                                                                />
                                                                                <path
                                                                                    id="ok-filled-circled_svg__b"
                                                                                    d="M9.5 15.125l-3.75-3.75 1.192-1.125L9.5 12.762l5.557-5.512 1.193 1.185z"
                                                                                />
                                                                            </defs>
                                                                            <g
                                                                                transform="translate(1 1)"
                                                                                fill="none"
                                                                                fillRule="evenodd"
                                                                            >
                                                                                <use
                                                                                    fill="#299D82"
                                                                                    xlinkHref="#ok-filled-circled_svg__a"
                                                                                />
                                                                                <use
                                                                                    fill="#FFF"
                                                                                    xlinkHref="#ok-filled-circled_svg__b"
                                                                                />
                                                                            </g>
                                                                        </svg>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className={`${border === 4 ? 'bordercolor css-1dbjc4n r-1awozwy r-14lw9ot r-l61ric r-z2wwpe r-1phboty r-rs99b7 r-1loqt21 r-1ifxtd0 r-88pszg r-1pn2ns4 r-5njf8e r-bnwqim r-1otgn73' : 'css-1dbjc4n r-1awozwy r-14lw9ot r-l61ric r-z2wwpe r-1phboty r-rs99b7 r-1loqt21 r-1ifxtd0 r-88pszg r-1pn2ns4 r-5njf8e r-bnwqim r-1otgn73'}`} onClick={() => handlBorder(4)}>
                                                                <div className="css-901oao r-1814a8r r-320f6p r-1b43r93 r-vw2c0b r-uz4sb0 r-rjixqe">
                                                                    Telegram
                                                                </div>
                                                                <div className={`${border === 4 ? '' : 'none'} css-1dbjc4n r-u8s1d r-1beiz5u r-1wrvin1`}>
                                                                    <div className="css-1dbjc4n" style={{ height: 14, width: 14 }}>
                                                                        <svg
                                                                            width={14}
                                                                            height={14}
                                                                            viewBox="0 0 24 24"
                                                                            fill="#5E5862"
                                                                        >
                                                                            <defs>
                                                                                <circle
                                                                                    id="ok-filled-circled_svg__a"
                                                                                    cx={11}
                                                                                    cy={11}
                                                                                    r="10.5"
                                                                                />
                                                                                <path
                                                                                    id="ok-filled-circled_svg__b"
                                                                                    d="M9.5 15.125l-3.75-3.75 1.192-1.125L9.5 12.762l5.557-5.512 1.193 1.185z"
                                                                                />
                                                                            </defs>
                                                                            <g
                                                                                transform="translate(1 1)"
                                                                                fill="none"
                                                                                fillRule="evenodd"
                                                                            >
                                                                                <use
                                                                                    fill="#299D82"
                                                                                    xlinkHref="#ok-filled-circled_svg__a"
                                                                                />
                                                                                <use
                                                                                    fill="#FFF"
                                                                                    xlinkHref="#ok-filled-circled_svg__b"
                                                                                />
                                                                            </g>
                                                                        </svg>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className={`${border === 5 ? 'bordercolor css-1dbjc4n r-1awozwy r-14lw9ot r-l61ric r-z2wwpe r-1phboty r-rs99b7 r-1loqt21 r-1ifxtd0 r-88pszg r-1pn2ns4 r-5njf8e r-bnwqim r-1otgn73' : 'css-1dbjc4n r-1awozwy r-14lw9ot r-l61ric r-z2wwpe r-1phboty r-rs99b7 r-1loqt21 r-1ifxtd0 r-88pszg r-1pn2ns4 r-5njf8e r-bnwqim r-1otgn73'}`} onClick={() => handlBorder(5)}>
                                                                <div className="css-901oao r-1814a8r r-320f6p r-1b43r93 r-vw2c0b r-uz4sb0 r-rjixqe">
                                                                    Zalo
                                                                </div>
                                                                <div className={`${border === 5 ? '' : 'none'} css-1dbjc4n r-u8s1d r-1beiz5u r-1wrvin1`}>
                                                                    <div className="css-1dbjc4n" style={{ height: 14, width: 14 }}>
                                                                        <svg
                                                                            width={14}
                                                                            height={14}
                                                                            viewBox="0 0 24 24"
                                                                            fill="#5E5862"
                                                                        >
                                                                            <defs>
                                                                                <circle
                                                                                    id="ok-filled-circled_svg__a"
                                                                                    cx={11}
                                                                                    cy={11}
                                                                                    r="10.5"
                                                                                />
                                                                                <path
                                                                                    id="ok-filled-circled_svg__b"
                                                                                    d="M9.5 15.125l-3.75-3.75 1.192-1.125L9.5 12.762l5.557-5.512 1.193 1.185z"
                                                                                />
                                                                            </defs>
                                                                            <g
                                                                                transform="translate(1 1)"
                                                                                fill="none"
                                                                                fillRule="evenodd"
                                                                            >
                                                                                <use
                                                                                    fill="#299D82"
                                                                                    xlinkHref="#ok-filled-circled_svg__a"
                                                                                />
                                                                                <use
                                                                                    fill="#FFF"
                                                                                    xlinkHref="#ok-filled-circled_svg__b"
                                                                                />
                                                                            </g>
                                                                        </svg>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="css-1dbjc4n r-1l7z4oj r-1g2xzmm">
                                            <Link to='/chuyen-khoan' type="button" className="btn-add-phone btn greenBoder btn-block btn-md" >
                                                Tiếp tục
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}
