import { Box } from '@mui/material'
import covertCoin from 'core/CovertCoin'
import { fixedMoney } from 'core/hook/fixedMoney'
import { fixedNumber } from 'core/hook/fixedNumber'
import { formatMoney } from 'core/hook/formarMoney'
import { useTranslate } from 'core/translate'
import React, { useRef, useState } from 'react'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'

const box = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    "@media (max-width:768px)": {
        alignItems: 'start'
    }
}
const div = {
    fontSize: '18px'
}

export default function TotalWallet() {
    let { t } = useTranslate()
    let [tototalcoin, setTototalcoin] = useState()
    let { vndusd } = useSelector(state => state.home)
    const { getwallet } = useSelector(state => state.user)
    let { pricecoin } = useSelector(state => state.coin)

    useEffect(() => {
        let nameSTF = "STF"
        let priceSTF
        let getName
        let getPrice
        let name
        let nameCoin
        let total = 0
        pricecoin.forEach((e, i) => {
            getName = e?.name
            name = covertCoin(e?.name, e?.type)
            nameCoin = getwallet?.data?.data?.[`${name.toLowerCase()}_balance`]

            if (getName == e?.name) {
                getPrice = e?.price
            }
            if (nameSTF == e?.name) priceSTF = e?.price


            if (nameCoin == undefined) {
                return 0
            } else if (name != "STF_TRC20") {
                total = total + (nameCoin * getPrice)
            }


            return total, priceSTF
        })
        let VND
        if (getwallet?.data?.data?.vnd_balance == undefined) {
            VND = 0
        } else {
            VND = getwallet?.data?.data?.vnd_balance / vndusd
        }
        let STF
         if( priceSTF)  STF = getwallet?.data?.data?.stf_trc20_balance == undefined ? 0 : getwallet?.data?.data?.stf_trc20_balance * priceSTF
         else STF = 0
        setTototalcoin(total + STF + VND)
    }, [pricecoin])
    return (
        <>
            <div className="wallet-banner">
                <div className='wallet-banner-item'>
                    <p>Estimated assets value</p>
                    <strong>{formatMoney(String(fixedNumber(tototalcoin, 2)))} $</strong>
                    <span>≈ {formatMoney(String(fixedNumber(tototalcoin * vndusd, 2)))} VND</span>

                </div>
                {/* <Box sx={box} className='wallet-banner-item'>
                    {!user ? '' :
                        <>
                            <Box style={{ fontFamily: 'IBM Plex Sans', fontWeight: '300', fontSize: '18px' }}>{t("Bonus STF")}</Box>
                            <Box sx={div} >{getwallet?.data?.data.stf_bonus_balance == undefined ? 0 : formatMoney(String(fixedMoney(getwallet?.data?.data.stf_bonus_balance)))}  </Box>
                        </>}
                </Box> */}

                <div className='wallet-banner-item'>
                    <div style={{ fontFamily: 'IBM Plex Sans', fontWeight: '300' }}>{t("Get started to buy and sell crypto")}</div>
                    <div className='banner-input' >
                        <NavLink to='p2p'>{t("BUY NOW")}</NavLink>
                        <NavLink to='p2p'>{t("SELL NOW")}</NavLink>
                    </div>
                </div>
            </div>
          


        </>
    )
}
