import React from 'react';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import { useSelector } from 'react-redux';
import { MenuItem, Select, TextField } from '@mui/material';
import { useTranslate } from 'core/translate';
import { useState } from 'react';
import { formatMoney } from 'core/hook/formarMoney';
import RecaptchaF2A from 'Components/RecaptchaF2A';
import Submit from './Submit';

function Deposit() {
    const { getbaking } = useSelector(state => state.user)
    let { t } = useTranslate()
    let [amount, setAmount] = useState('')
    let [idBanking, setIdBanking] = useState()
    const HandleBanking = (event) => {
        setIdBanking(event.target.value)
    }
    const handleChange = (e) => {
        let value = e.target.value
        let value1 = ""
        let dotIndex = value.indexOf(".")
        for (let index = 0; index < value.length; index++) {
            if (value[index].match(/[0-9]/))
                value1 += value[index]
            if (index === dotIndex)
                value1 += "."
        }
        setAmount(value1)
    }

    const selectBanking = () => {
        return (
            <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth variant="filled">
                    <Select
                        labelId="demo-simple-select-disabled-label"
                        id="demo-simple-select-disabled"
                        value={idBanking}
                        onChange={HandleBanking}
                    >
                        {getbaking?.data?.data?.map((e, i) => {
                            return (
                                <MenuItem key={i} value={e?.id} >{e?.name_banking} </MenuItem>
                            )
                        })}
                    </Select>
                </FormControl>
            </Box>
        )
    }


    return (
        <>
            <div className='depositvnd-title'>
                <p>Deposit VND</p>
            </div>
            <div className='depositvnd-input'>
                <p>Send money from the bank</p>
                {selectBanking()}
            </div>
            <div className='depositvnd-input'>
                <p>Amount</p>
                <TextField fullWidth id="filled-basic" variant="filled" value={formatMoney(String(amount))} onChange={handleChange} style={{ fontWeight: '500' }} />
            </div>

            <div className='depositvnd-notify'>
                <img src="/img/!.png" alt="" />
                <p style={{ color: '#757575' }}>
                    {t("Depositing VND is free. SwapToBe does not collect VND from users, however, you will be subject to bank transfer fees.")}
                </p>
            </div>
            <div >
                <RecaptchaF2A />
            </div>
            <div className="depositvnd-submit">
                <Submit amount={amount} idBanking={idBanking} />
            </div>
        </>

    );
}

export default Deposit;