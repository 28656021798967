import { Pagination, Spin } from 'antd';
import { formatDateTime } from 'core/hook/formatDateTime';
import { truncate } from 'core/hook/truncate';
import { useTranslate } from 'core/translate';
import React from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createGethistorywidthdraw } from 'redux/action/userAction';

function HistoryWithdrawVnd() {
    let token = JSON.parse(localStorage.getItem('user'))
    let { t } = useTranslate()
    let { gethistorywidthdraw } = useSelector(state => state.user)
    let [loadding, setLoadding] = useState(false)

    const dispatch = useDispatch()

    function handlePageHistoryRut(page) {
        dispatch(createGethistorywidthdraw(token?.axiologin?.token, page, "VND", setLoadding, t))
    }

    const list = gethistorywidthdraw?.data?.data?.array?.map((e, i) => {
        let stg
        if (e.status == 0) {
            stg = <>
                <a className='text text-danger'>{t("Canceled")}</a>
                <p className='text text-danger'>{t("Reason: ")} {t("User canceled")}</p>
            </>
        }
        else if (e.status == 2) {
            stg = <>
                <a className='text text-warning'>{t("Pending")}</a>

            </>
        }
        else if (e.type_admin == 1) {
            stg = <>
                <a className='text text-success'>{t("Success")}</a>
            </>
        } else {
            stg = ''
        }
        return (
            <div className="dhistory-content" key={i}>
                <div>
                    <p>{t("Receiver:")}  {e?.bank_owner?.toUpperCase()}</p>
                    <p>{t("withdrawal code")}: {e?.hash}</p>
                    <p>{formatDateTime(e?.created_at.substring(0, 9), "/")} {formatDateTime(e?.created_at.substring(10, 17), ":")}</p>
                </div>
                <div className='historyVnd-item'>
                    <p>- {truncate(e?.amount)} VND</p>
                    <p>{stg}</p>
                </div>

            </div>
        )
    })

    return (
        <>
            {loadding ? <div className="example" style={{ width: '100%', textAlign: 'center' }}> <Spin /></div> :
                <>
                    <div className='dhistory-title'>
                        <p>History VND</p>
                    </div>
                    {gethistorywidthdraw?.data?.data?.total == 0 ? <div className="content" style={{ background: '#fff', padding: '20px' }}>
                        <div className="interest-list">
                            <div style={{ color: "#000", fontSize: '18px', fontStyle: 'italic', textAlign: 'center' }}>
                                {t("No transactions yet")}
                            </div>
                        </div>
                    </div> :
                        <>
                            {list}
                        </>}
                </>
            }
            <Pagination
                style={{ marginTop: '20px' }}
                defaultPageSize={5} //default size of page
                onChange={handlePageHistoryRut}
                defaultCurrent={1}
                total={gethistorywidthdraw?.data?.data?.total} //total number of card data available
            />
        </>
    );
}

export default HistoryWithdrawVnd;