import { Spin } from 'antd';
import { useTranslate } from 'core/translate';
import { GetMinimumWidthdraw } from 'page/Vi/component/MinimumWidthdraw';
import React, { useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import QRCode from 'react-qr-code';
import { useSelector } from 'react-redux';
import DepositCoin from './DepositCoin';


function Coin() {
    let [size, setSize] = useState(175)
    let [loadding, setLoadding] = useState(false)
    let [active, setActive] = useState(false)
    let { symbol } = useSelector(state => state.user)
    let { createwallet, createwalleterc, createwallettrc } = useSelector(state => state.deposit)
    let [valueCopy, setValueCopy] = useState('')
    let { t } = useTranslate()
    let { pricecoin } = useSelector(state => state.coin)
    let minimumWidthdraw = GetMinimumWidthdraw(pricecoin, symbol)

    function handleActiveCopy() {
        setActive(true)
        setTimeout(() => {
            setActive(false)
        }, 1500)
    }
    if (!createwallet && !createwalleterc && !createwallettrc) return <div div className="example" style={{ margin: '0', padding: '5px', background: '#fff', border: 'none' }}>
        <Spin />
    </div>
    return (
        <>
            <div className="deposit-title">
                <p className='qrcode-title'>Wallet {symbol} </p>
            </div>

            <DepositCoin setLoadding={setLoadding} loadding={loadding} />

            <div>
                {loadding ?
                    <div div className="example" style={{ margin: '0', padding: '5px', background: '#fff', border: 'none' }}>
                        <Spin />
                    </div> : <>
                        <div className='deposit-content'>
                            {createwallet && <QRCode size={size} value={`${createwallet?.data?.data.address}`} />}
                            {createwalleterc && <QRCode size={size} value={`${createwalleterc?.data?.data}`} />}
                            {createwallettrc && <QRCode size={size} value={`${createwallettrc?.data?.data}`} />}

                            {createwallet && <p style={{ color: '#2A5872', fontWeight: '700' }}>{createwallet?.data?.data.address}</p>}
                            {createwalleterc && <p style={{ color: '#2A5872', fontWeight: '700' }}>{createwalleterc?.data?.data}</p>}
                            {createwallettrc && <p style={{ color: '#2A5872', fontWeight: '700' }}>{createwallettrc?.data?.data}</p>}

                            {createwallet && <CopyToClipboard text={`${createwallet?.data?.data.address}`}
                                onCopy={() => setValueCopy({ copied: true })}>
                                <button onClick={handleActiveCopy} dir="auto" style={{ fontFamily: 'Arial' }}>
                                    Copy Address
                                    {active ? <img src="/img/Copy.png" alt="" style={{ width: '24px', marginLeft: '10px' }} /> : ""}
                                </button>
                            </CopyToClipboard>}

                            {createwalleterc && <CopyToClipboard text={`${createwalleterc?.data?.data}`}
                                onCopy={() => setValueCopy({ copied: true })}>
                                <button onClick={handleActiveCopy} dir="auto" style={{ fontFamily: 'Arial' }}>
                                    Copy Address
                                    {active ? <img src="/img/Copy.png" alt="" style={{ width: '24px', marginLeft: '10px' }} /> : ""}
                                </button>
                            </CopyToClipboard>}
                            {createwallettrc && <CopyToClipboard text={`${createwallettrc?.data?.data}`}
                                onCopy={() => setValueCopy({ copied: true })}>
                                <button onClick={handleActiveCopy} dir="auto" style={{ fontFamily: 'Arial' }}>
                                    Copy Address
                                    {active ? <img src="/img/Copy.png" alt="" style={{ width: '24px', marginLeft: '10px' }} /> : ""}
                                </button>
                            </CopyToClipboard>}
                        </div>
                    </>}

                <div className="mt-2" >
                    {createwallettrc &&
                        <>
                            <div className='deposit-notify'>
                                <img src="/img/!.png" alt="" />
                                <p style={{ color: '#757575' }}>
                                    {t('You must keep a minimum of 20 TRX in your wallet to secure enough gas fees for trading TRC20 tokens.')}
                                </p>
                            </div>
                            <div className='deposit-notify'>
                                <img src="/img/!.png" alt="" />
                                <p style={{ color: '#757575' }}>
                                    <span >{t("You have to deposit minimum")} <strong>{minimumWidthdraw} {symbol?.substring(0, 4)}</strong> {t("to be credited")}</span>
                                </p>
                            </div>
                            <div className='deposit-notify'>
                                <img src="/img/!.png" alt="" />
                                <p style={{ color: '#757575' }}>
                                    <span >{t("This deposit address only accepts")} <strong> {symbol?.substring(0, 4)}.</strong> {t("Do not send other coins to it.")}</span>
                                </p>
                            </div>

                        </>
                    }
                    {createwallet || createwalleterc ?
                        <>
                            {symbol == "USDT" ?
                                <>
                                    <div className='deposit-notify'>
                                        <img src="/img/!.png" alt="" />
                                        <p style={{ color: '#757575' }}>
                                            {t("You have to deposit at least 5 USDT to be credited. Any deposit that is less than 5 USDT will not be refunded")}
                                        </p>
                                    </div>

                                    <div className='deposit-notify'>
                                        <img src="/img/!.png" alt="" />
                                        <p style={{ color: '#757575' }}>
                                            {t("This deposit address only accepts")} {symbol?.substring(0, 4)}. {t("Do not send other coins to it.")}
                                        </p>
                                    </div>
                                </> :
                                <>
                                    <div className='deposit-notify'>
                                        <img src="/img/!.png" alt="" />
                                        <p style={{ color: '#757575' }}>
                                            <span >{t("You have to deposit minimum")} <strong> {minimumWidthdraw}$ </strong> {t("(accompanied by the amount of coins to be converted)")} </span>
                                        </p>
                                    </div>
                                    <div className='deposit-notify'>
                                        <img src="/img/!.png" alt="" />
                                        <p style={{ color: '#757575' }}>
                                            <span >{t("Only ETH or ERC20 tokens, the minimum deposit amount must be")} <strong> 50$</strong> </span>
                                        </p>
                                    </div>
                                    <div className='deposit-notify'>
                                        <img src="/img/!.png" alt="" />
                                        <p style={{ color: '#757575' }}>
                                            <span >{t("This deposit address only accepts")} <strong> {symbol == "USDT.TRC20" || symbol == "USDT.ERC20" ? symbol?.substring(0, 4) : symbol}.</strong> {t("Do not send other coins to it.")}</span>
                                        </p>
                                    </div>

                                </>}
                        </>
                        : ''}


                </div>
            </div>
        </>
    );
}

export default Coin;