import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory } from 'react-router';
import { NavLink } from 'react-router-dom';
import { useTranslate } from '../../../core/translate';
import useFormValidate from '../../../core/useFormValidate';
import { getprofile } from '../../../redux/action/userAction';




export default function UserProfile() {
    const dispatch = useDispatch()
    let { t } = useTranslate()
    useEffect(() => {
        let tab = t("Update profile")
        document.title = tab
        return () => {

            document.title = t("Setting")
        }
    }, [t]);
    let history = useHistory()
    let { axiologin } = useSelector(state => state.auth)

    let { form, error, inputChange, check } = useFormValidate({
        username: axiologin?.username,
        email: axiologin?.email,
        phone: ''
    }, {
        rule: {
            name: {
                required: true,

            },

            cmnd: {
                required: true,
            },

            phone: {
                required: true,

            },
            address: {
                required: true,
            }

        },
    })

    let token = JSON.parse(localStorage.getItem('user'))
    useEffect(async () => {
        if (axiologin) {
            dispatch(getprofile(token.axiologin.token))
        }
    }, [])

    const { profile } = useSelector(state => state.user)

    const verified = profile?.data?.data?.verified

    async function _login() {

        let error = check();

        if (Object.keys(error).length === 0) {
            // dispatch(createA(USERINFORMATION, true))
            // if (verified == null) {
            //     history.push('/kyc-basic')
            // }


        }
    }
    return (
        <>
            <div className='Verification' style={{ backgroundColor: "#fff", color: '#000', width: '100%', padding: 0 }}>
                <div id="main-wrapper" className=''>
                    <div className="authincation section-padding">
                        <div className="container h-100 Verification_moblie">
                            <div className="row justify-content-center h-100 align-items-center" style={{ margin: '20px 0' }} >
                                <div className="col-xl-8 col-md-12" style={{ padding: '0' }}>

                                    <div className="auth-form card">
                                        <div className="card-header justify-content-center">
                                            <h4 className="card-title">{t("User information")}</h4>
                                        </div>
                                        <div className="card-body">
                                            <form method="post" name="myform" className="signin_validate" action="https://demo.themefisher.com/tradio/otp-1.html">
                                                <div className="form-group">
                                                    <label>{t("Username ")}</label>
                                                    <input type='text' name='username' value={form.username} disabled="disabled" onChange={inputChange}
                                                        className="form-control"
                                                        placeholder={t("Username ")} />

                                                </div>
                                                {error.username && <p className=" errorLogin">{error.username}</p>}
                                                <div className="form-group">
                                                    <label>{t("Email")}</label>
                                                    <input type='text' name='username' value={form.email} disabled="disabled" onChange={inputChange}
                                                        className="form-control"
                                                        placeholder={t("Email")} />

                                                </div>
                                                {error.email && <p className=" errorLogin">{error.email}</p>}
                                                <div className="form-group">
                                                    <label>{t("Name")}</label>
                                                    <input type='text' name='name' value={form.name} onChange={inputChange}
                                                        className="form-control"
                                                        placeholder={t("Name")} />

                                                </div>
                                                {error.name && <p className=" errorLogin">{error.name}</p>}
                                                <div className="form-group">
                                                    <label>{t("Identity Card / ID Card / Passport")}</label>
                                                    <input
                                                        onChange={inputChange}
                                                        type="text"
                                                        className="form-control"
                                                        placeholder={`${t("Identity Card / ID Card / Passport")}`}
                                                        name="cmnd"
                                                        value={form.cmnd}
                                                    />

                                                </div>
                                                {error.cmnd && <p className=" errorLogin">{error.cmnd}</p>}
                                                <div className="form-group">
                                                    <label>{t("Phone number")}</label>
                                                    <input
                                                        onChange={inputChange}
                                                        type="text"
                                                        className="form-control"
                                                        placeholder={`${t("Phone number")}`}
                                                        name="phone"
                                                        value={form.phone}
                                                    />

                                                </div>
                                                {error.phone && <p className=" errorLogin">{error.phone}</p>}
                                                <div className="form-group">
                                                    <label>{t("Address")}</label>
                                                    <input
                                                        onChange={inputChange}
                                                        type="text"
                                                        className="form-control"
                                                        placeholder={t("Address")}
                                                        name="address"
                                                        value={form.address}
                                                    />

                                                </div>
                                                {error.address && <p className=" errorLogin">{error.address}</p>}

                                                <div className="form-group" style={{ display: 'flex' }}>
                                                    <label style={{ marginRight: '5px' }}>{t("Account Status:")}</label>
                                                    {verified == null ? <td><strong className="text-green">{t("Not submitted")}</strong> <NavLink to="kyc-basic" style={{ color: 'blue', marginLeft: '5px', fontWeight: 'bolder' }} > {t("Update")} </NavLink></td> : ''}
                                                    {verified == 1 ? <td><strong className="text-green" >{t("Verified")}</strong></td> : ''}
                                                    {verified == 2 ? <td><strong className="text-green">{t("Wait for Admin to approve")}</strong></td> : ''}
                                                    {verified == 3 ? <> <td><strong className="text-green" style={{ color: 'red' }}>{t('Was admin rejected')}</strong>  <NavLink to="kyc-basic" style={{ color: 'blue', marginLeft: '5px', fontWeight: 'bolder' }} > {t("Update again")} </NavLink></td>  </> : ''}

                                                </div>

                                                <div className="text-center">
                                                    <a className="btn btn-success btn-block" onClick={_login} style={{ color: '#fff' }}>
                                                        {t("Update")}
                                                    </a>
                                                </div>
                                            </form>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
