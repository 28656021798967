import { Pagination, Spin } from 'antd';
import RecaptchaF2A from 'Components/RecaptchaF2A';
import { ErrorMessage } from 'core/Message';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import { useTranslate } from '../../../core/translate';
import { port } from '../../../port';
import { createGetPricecoin } from '../../../redux/action/coinAction';
import { createInterestlending, createLending, createNapCoinERC } from '../../../redux/action/userAction';
import { createNapCoin, createNapCoinTRC } from '../../../redux/action/userAction';



function LendingActive() {
    // let { getcoin } = useSelector(state => state.coin)
    let { t } = useTranslate()
    let [loadding, setLoadding] = useState(false)
    let { redirect, gettrc20, interestlending } = useSelector(state => state.user)
    const { profile } = useSelector(state => state.user)

    let { axiologin } = useSelector(state => state.auth)
    const verified = profile?.data?.data?.verified
    const twofa = profile?.data?.data?.enabled_twofa

    let { tokenRe, value2fa } = useSelector(state => state.other)
    let [symbol, setSymbol] = useState("BTC")
    let token = JSON.parse(localStorage.getItem('user'))
    let dispatch = useDispatch()
    let { pricecoin } = useSelector(state => state.coin)
    const formatDateTime = (str, char) => {
        let strSplit = str.split(char)
        let value1 = `${Number(strSplit[0]) < 10 ? `0${strSplit[0]}` : strSplit[0]}`
        let value2 = `${Number(strSplit[1]) < 10 ? `${char}0${strSplit[1]}` : `${char}${strSplit[1]}`}`
        let value3 = `${Number(strSplit[2]) < 10 ? `${char}0${strSplit[2]}` : `${char}${strSplit[2]}`}`

        return value1 + value2 + value3
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        dispatch(createGetPricecoin())
    }, [])

    useEffect(() => {
        if (symbol == "BPAY_TRC20") {
            dispatch(createInterestlending(token?.axiologin?.token, 1, symbol?.substring(0, 4)))

        } else if (symbol == "WIN_TRC20" || symbol == "NFT_TRC20" || symbol == "BOS_TRC20") {
            dispatch(createInterestlending(token?.axiologin?.token, 1, symbol?.substring(0, 3)))
        } else {
            dispatch(createInterestlending(token?.axiologin?.token, 1, symbol))
        }

    }, [symbol])

    useEffect(() => {
        let el = document.querySelector('.btc-container');
        let caret = document.querySelector("#show-btc")
        if (caret) {
            caret.addEventListener('click', function () {
                el.classList.toggle("disabled")
            });
        }

    }, [])


    let [active, setActive] = useState(false)
    function handleActive() {
        setActive(!active)
    }
    let defaultPrice = 0
    let [value, setValue] = useState()
    function handleChange(e) {
        let value = e.target.value
        setValue(value)
    }
    let [price, setPrice] = useState(0)
    function handleGetCoin(e) {
        setSymbol(e.name)
        setActive(false)
        setPrice(e.price)
        dispatch(createNapCoin(token?.axiologin?.token, `${e.name}`, t, setLoadding))
        if (e.name == "USDT") {
            dispatch(createNapCoin(token?.axiologin?.token, "USDT.TRC20", t, setLoadding))
            dispatch(createNapCoinERC(token?.axiologin?.token, "USDT.ERC20", t, setLoadding))
        }
    }


    function handleGetCoin2(e) {

        setSymbol(`${e.symbol}_TRC20`)
        setActive(false)
        setPrice(e.price)
        dispatch(createNapCoinTRC(token?.axiologin?.token, `${e.symbol}_TRC20`, t, setLoadding))
    }
    const history = useHistory()
    function handleSubmid() {
        if (!axiologin) {
            history.push('/form')
        }
        else if (tokenRe.length > 0 || value2fa) {
            if (verified == null) {
                ErrorMessage("User has not verified KYC !", t)
            } else if (verified == 2) {
                ErrorMessage("KYC is pending, please wait!", t)
            }
            else if (verified == 3) {
                ErrorMessage("KYC has been declined, please check again!", t)

            } else {

                if (symbol == "USDT") {
                    dispatch(createLending(token?.axiologin?.token, value, "USDT.TRC20", t, setLoadding, tokenRe, value2fa,))
                } else {
                    dispatch(createLending(token?.axiologin?.token, value, symbol, t, setLoadding, tokenRe, value2fa,))
                }

            }
        } else {
            if (twofa == 0 && tokenRe == '') {
                ErrorMessage("Please enter ReCAPTCHA", t)

            } else {
                ErrorMessage("Please enter  2FA", t)

            }
        }



    }
    function handleInterestlending(page) {

        if (symbol == "BPAY_TRC20") {
            dispatch(createInterestlending(token?.axiologin?.token, page, symbol?.substring(0, 4)))

        } else if (symbol == "WIN_TRC20" || symbol == "NFT_TRC20" || symbol == "BOS_TRC20") {
            dispatch(createInterestlending(token?.axiologin?.token, page, symbol?.substring(0, 3)))
        } else {
            dispatch(createInterestlending(token?.axiologin?.token, page, symbol))
        }
    }



    if (redirect) {
        return <Redirect to='/p2p' />
    }

    return (
        <div className="lendings" style={{ backgroundImage: "linear-gradient(to right,#087972,#27133a)", display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
            <div className="lending main-container" style={{ margin: '50px auto 0 auto' }}>
                <div className="header space-between ">
                    <div className="center">SWAPTOBE</div>

                    <div className="center-abs">
                        {/* {!symbol ? 'Vui lòng chọn coin' : symbol} */}
                        {symbol == "WIN_TRC20" || symbol == "NFT_TRC20" || symbol == "BOS_TRC20" ?
                            symbol?.substring(0, 3) :
                            symbol == "BPAY_TRC20" ?
                                symbol?.substring(0, 4) :
                                symbol
                        }
                    </div>
                    <div className="center" id="show-btc" onClick={handleActive} style={{ cursor: 'pointer' }}><i className="fas fa-caret-down"></i></div>
                </div>
                <div className="content color-bg-T">
                    <div className="container">
                        <div>
                            {symbol == "WIN_TRC20" || symbol == "NFT_TRC20" || symbol == "BOS_TRC20" ?
                                <input type="text" placeholder={`Amount ${symbol?.substring(0, 3)}`} onChange={(e) => handleChange(e)} value={value} style={{ padding: '8px 10px' }} /> :
                                symbol == "BPAY_TRC20" ?
                                    <input type="text" placeholder={`${t("Amount")} ${symbol?.substring(0, 4)}`} onChange={(e) => handleChange(e)} value={value} style={{ padding: '8px 10px' }} /> :
                                    <input type="text" placeholder={`${t("Amount")} ${symbol?.substring(0, 4)}`} onChange={(e) => handleChange(e)} value={value} style={{ padding: '8px 10px' }} />
                            }
                        </div>
                        {/* <div>~{price / value} {symbol}</div> */}
                        {/* <div>~ {!value ? "0" : price / value} {symbol}</div> */}
                        {axiologin &&
                            <div style={{ marginBottom: '-20px' }}> <RecaptchaF2A /> </div>}

                        {loadding ? <div className="example">
                            <Spin />
                        </div> :
                            <div>
                                <button onClick={handleSubmid} style={{ marginTop: '0', background: "#4BBD99" }}>{t("Accept")}</button>
                            </div>
                        }
                    </div>
                </div>


                <div className={`${active ? "btc-container  " : 'btc-container  disabled'}`}>
                    {pricecoin?.map((e, i) => {
                        if (e?.name != 'STF' && e?.lendding == 1)
                            return (
                                <div key={i} className="btc-item">
                                    <div className="img">
                                        <img src={`${port}${e.image}`} alt="" />
                                    </div>
                                    <div className="btc">{e.name}</div>
                                    <div className="btn" onClick={() => handleGetCoin(e, i)} style={{ padding: '5px' }}>
                                        <button className="color-bg-T">{t("Choose")}</button>
                                    </div>
                                </div>
                            )
                    })
                    }



                </div>
            </div>
            {
                interestlending?.data?.data?.total == 0 ?
                    <div className="history-interest">
                        <div style={{
                            background: 'linear-gradient(90.17deg, #4BBD99 1.71%, #007C91 97.5%)',
                            borderRadius: '15px'
                        }}>
                            <div className="header">
                                <div className="header-column">
                                    <div>{t("Profit history lending")}</div>
                                </div>

                            </div>
                            <div className="content">
                                <div className="interest-list">
                                    <div style={{ color: "#000", paddingTop: '20px', fontSize: '18px', fontStyle: 'italic', textAlign: 'center' }}>
                                        {t("No transactions yet")}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <div className="history-interest">
                        <div style={{
                            background: 'linear-gradient(90.17deg, #4BBD99 1.71%, #007C91 97.5%)',
                            borderRadius: '15px'
                        }}>
                            <div className="header " >
                                <div className="header-column">
                                    <div>{t("Profit history Tobe Credit")}</div>
                                    <div className="row-top">
                                        {symbol == "WIN_TRC20" || symbol == "NFT_TRC20" || symbol == "BOS_TRC20" ?
                                            <img src={`${port}images/${symbol?.substring(0, 3)}.png`} alt="" /> :
                                            symbol == "BPAY_TRC20" ?
                                                <img src={`${port}images/BOS.png`} alt="" /> :
                                                <img src={`${port}images/${symbol}.png`} alt="" />
                                        }

                                        <div>
                                            {symbol == "WIN_TRC20" || symbol == "NFT_TRC20" || symbol == "BOS_TRC20" ?
                                                symbol?.substring(0, 3) :
                                                symbol == "BPAY_TRC20" ?
                                                    symbol?.substring(0, 4) :
                                                    symbol
                                            }

                                        </div>
                                    </div>

                                </div>

                            </div>

                            <div className="content">
                                {/* <div className="time box-shadow-light center"><span>10/08/2021 10:30:29</span></div> */}
                                <div className="interest-list">

                                    {interestlending?.data?.data?.array?.map((e, i) => {

                                        return (
                                            <div className="box-shadow-light color-grey-bold">
                                                <div>{t("Return interest:")} <span className="color-orange">{(e?.amount / 100) * 0.67} USD</span></div>
                                                <div>{t("Interest returned every day on:")} 00:00 AM</div>
                                                <div>{t("Interest returned every day:")} 0.67%</div>
                                                <div className="color-grey-semi mr-top-5">{formatDateTime(e?.created_at.substring(0, 9), "/")} {formatDateTime(e?.created_at.substring(9, 16), ":")}</div>

                                            </div>
                                        )
                                    })}

                                    <Pagination className="center"
                                        style={{ paddingBottom: '20px', marginTop: '20px' }}
                                        defaultCurrent={1}
                                        defaultPageSize={5} //default size of page
                                        onChange={handleInterestlending}
                                        total={interestlending?.data?.data?.total} //total number of card data available
                                    />




                                </div>
                            </div>
                        </div>



                    </div >
            }
        </div >
    );
}

export default LendingActive;