import User from "../../api/User"
import { port } from "../../port"
import { DATANEWS, REFERRAL } from "../type"

import * as api from "api/CreateApi"
import { LIST12CHARACTERS } from "redux/type"
import { CHARACTERSTURN } from "redux/type"
import { LIST12CHARACTERSRAMDOM } from "redux/type"
import { reaload } from "func"
import { SuccessMessage } from "core/Message"
import { ErrorMessage } from "core/Message"

export function createOther(type, payload) {
    return {
        type,
        payload
    }
}

export function createNews() {


    return async (dispatch) => {
        let res = await User.news()
        if (res) {
            dispatch(createOther(DATANEWS, res))
        }
    }

}
export function createRefSignUp() {


    return async (dispatch) => {

        let res = await User.createRefSigup(`${port}api/v2/getref`)
        if (res) {
            dispatch(createOther(REFERRAL, res?.data?.data))
        }
    }

}


export function GetReq12Character(setLoadding) {


    return async (dispatch) => {
        try {
            let { data } = await api.req12Character()
            dispatch(createOther(LIST12CHARACTERS, data))


        } catch (error) {

        }
        setLoadding(false)


    }

}



