import { useTranslate } from 'core/translate';
import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';

function Support() {
    const [query, setQuery] = useState("");
    const { t } = useTranslate()

    const array = ['Swaptobe là gì? Giới thiệu về Swaptobe', t('Instructions for depositing money into your Swaptobe account!'), 'Hướng dẫn rút tiền từ tài khoản Swaptobe']

    return (
        <>

            <div className='wallet'>
                <div className="container">
                    <div className="wallet-banner">
                        <div className='wallet-banner-item' style={{ width: '100%', flex: '1' }}>
                            <div style={{ fontFamily: 'IBM Plex Sans', fontWeight: '300' }}>{t("Get started to buy and sell crypto")}</div>

                        </div>
                        <div className='wallet-banner-item' style={{ position: 'relative', width: '100%', flex: '1' }}>
                            <div className='banner-input' >
                                <input type="text" onChange={(e) => setQuery(e.target.value.toLowerCase())} style={{ width: '95%', padding: '0 40px' }} />
                                <img src="/img/search.png" alt="" style={{ position: 'absolute', top: '50%', left: '6%', transform: 'translate(-50%,-50%)', width: '25px' }} />
                            </div>
                        </div>
                    </div>
                </div>
            </div >
            <div className='support'>
                <div className="container">
                    <div className="support-main">
                        <ul className="list">
                            {array.filter((array) =>
                                array.toLowerCase().includes(query)
                            ).map((user, i) => (
                                <li className="listItem" key={i}>
                                    <div>
                                        <p style={{ fontFamily: 'IBM Plex Sans', color: '#4BBD99', fontWeight: 'bold', fontSize: '16px' }}>{i + 1}. {t(`${user}`)}</p>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>

            </div>
        </>
    );
}

export default Support;