import { fixedNumber } from 'core/hook/fixedNumber';
import { formatMoney } from 'core/hook/formarMoney';



export const getwalletcoin = (coin, getwallet) => {
    let wallet = getwallet?.data?.data?.[`${coin.toLowerCase()}_balance`]

    if (wallet == undefined) {

        return 0
    } else {
        if (coin == "STF_TRC20" || coin == "USDT") {
            wallet = wallet
        } else {
            wallet = wallet
        }

    }
    return wallet

}
export const FormatGetwalletcoin = (coin, getwallet) => {
    let wallet = getwallet?.data?.data?.[`${coin.toLowerCase()}_balance`]

    if (wallet == undefined) {

        return 0
    } else {
        if (coin == "STF_TRC20" || coin == "USDT") {
            wallet = wallet
        } else {
            wallet = wallet
        }

    }
    return wallet

}