import React, { useEffect } from 'react'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { HandleCallMinning } from 'redux/reducer/minningReducer'
import { obj } from './helper'
import { ProgressBar } from 'react-bootstrap'



export default function Matrix({setOpen,setActive}) {
    const dispatch = useDispatch()
    const {matrix} = useSelector(state => state.minning)
    const timeOut = (matrix?.time * 10)- 20
    const user = JSON.parse(localStorage.getItem('user'))?.axiologin
    const [state, setState] = useState([])
    const [Id, setId] = useState(0)
    const [randomObj, setRandomObj] = useState()
    const [time,setTime] = useState(0)
    const [render,setRender] = useState(0)

    let arr = Object.keys(obj);
    let randomNumber = Math.random();
    let index = Math.floor(randomNumber * arr.length )
    let randomKey = arr[index];
useEffect(() => {
        dispatch(HandleCallMinning(setOpen,setActive))
},[])
useEffect(() => {
    let timeApi 
    if(time != 100){
        timeApi=  setTimeout(() => {
            setTime(time+1)
        }, timeOut);
    }
},[time])

useEffect(() =>  {
    let test
    if(time != 100){
        setId(Id+1)
        test =setTimeout(() => {
            state.push(arr[index]+obj[randomKey])
            setRender(render+1)
        }, 3500)
    }else{
        clearTimeout(test)
    }
},[render])
useEffect(() => {
    let timeOut
    clearTimeout(timeOut)
    timeOut= setTimeout(() => {
        setRandomObj(arr[index].split('.'))
    }, 300)
},[randomObj])



    return (
       <div className='matrix'>
         <div>
             <div>Hello: {user?.username}</div>
                 <div>key: .{randomObj}</div>
                 <div>They system in processing data....</div>
        {state?.map((e,i) => {
            return (
               
                    <div key={i}>~.{e}</div>
              
            )
        })}
          </div>
        <div>
            <ProgressBar now={`${time}`} label={`${Math.floor(time)}%`} variant='success' animated  style={{minHeight:"20px", margin: 0}}/>
            </div>
       </div>
    )
}
