export const accpect = [
    {
        text: "Bank statements",
    },
    {
        text: "Utility bills(The documents that you provide shouldn't be older than 3 months)",
    },
    {
        text:'Cable TV/house phone line bills',
    },
    {
        text:'Tax returns',
    },
    {
        text:'Council tax bills',
    },
    {
        text:'Government-issued certifications of residence, etc',
    },
]

export const NoAccpect=[
    {
        text: "Screenshots",
    },
    {
        text: "Moblie phone bills",
    },
    {
        text: "Medical bills",
    },
    {
        text: "Receipts for purchases",
    },
    {
        text: "Insurance statements",
    },
]

export const arrayPassPort = [
    {
        name: "img1",
        text:"Front"
    },
    {
        name: "img2",
        text:"Backside"
    },
]


export const proof = [
    {
        name: "proof",
        text:"Front"
    },
    
]