

import { AUTH_LOGIN_ERROR, AUTH_LOGIN_STATUS, AXIOSLOGIN, BICANCE, DATANEWS, GOOGLE, LANG, LOCKED, LOGIN, MESSENGER, NAV, POPUPBUY, POPUPSELL, REMOVE, SELECT, TEST, TEST2, VERIFYEMAIL } from '../type'
import { COIN } from '../type'

let user = JSON.parse(localStorage.getItem('user')) || ''

let initState = JSON.parse(localStorage.getItem('user')) || {
    show: '',
    test: false,
    test2: false,
    nav: true,
    select: false,
    messenger: false,
    popupbuy: false,
    popupsell: false,
    popupLogin: false,
    axiologin: '',
    loginError: "",
    status: false,
    // google: JSON.parse(localStorage.getItem('user')) || '',

    popupcoin: '',
    verifyemail: '',






}

export default function authReducer(state = initState, action) {
    switch (action.type) {
        case LOGIN: {
            state.popupLogin = action.payload
            return {
                ...state,
            }
        }

        case REMOVE:
            return {
                ...state,
                show: ''
            }
        case TEST:
            return {
                ...state,
                test: action.payload
            }
        case TEST2:
            return {
                ...state,
                test2: action.payload
            }
        case LANG:
            return {
                ...state,
                show: 'show'
            }
        case NAV:
            return {
                ...state,
                nav: action.payload
            }
        case SELECT:
            state.select = action.payload
            return {
                ...state
            }
        case MESSENGER:
            state.messenger = action.payload
            return {
                ...state,
            }
        case POPUPBUY:
            return {
                ...state,
                popupbuy: action.payload
            }
        case POPUPSELL:

            return {
                ...state,
                popupsell: action.payload
            }
        case AXIOSLOGIN:
            localStorage.setItem('user', JSON.stringify({
                axiologin: action.payload,
                popupLogin: true,
            }))
            return {
                ...state,
                axiologin: action.payload,
                popupLogin: true,

            }
        case VERIFYEMAIL:
            localStorage.setItem('user', JSON.stringify({
                axiologin: action.payload,
                popupLogin: true,
            }))
            return {
                ...state,
                axiologin: action.payload,
                popupLogin: true,
                verifyemail: true

            }
        case AUTH_LOGIN_ERROR:

            return {
                ...state,
                loginError: action.payload
            }
        case AUTH_LOGIN_STATUS:

            return {
                ...state,
                status: action.payload
            }
        // case GOOGLE:
        //     localStorage.setItem('user', JSON.stringify({
        //         google: action.payload,
        //         popupLogin: true,
        //     }))

        //     return {
        //         ...state,
        //         google: action.payload,
        //         popupLogin: true,

        //     }

        case COIN:

            return {
                ...state,
                popupcoin: action.payload,
            }
        //News



        default:
            return state;
    }
}